<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-google-circles-communities"
      :text="cText.text"
  />
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbGroupDetails',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute && (this.cGroupIsCurrentPlayer) && !this.cIsDataPage
    },
    cGroupIsCurrentPlayer () {
      return this.$store.getters['groups/selected/group']?.membership_state !== 'none'
    },
    cTarget () {
      return this.cShowBtn ? `/groups/${this.$store.getters['groups/selected/group'].id}#details` : ''
    },
    cIsDataPage () {
      return this.$route.hash === '#details'
    }
  }
}
</script>

<style scoped>

</style>
