<template>
  <eins-starmenu-btn
      v-bind="$attrs"
      @click="onClick"
  >
    <template
        v-if="$scopedSlots.icon"
        #icon
    >
      <slot name="icon"></slot>
    </template>
    <template v-if="$scopedSlots.default">
      <slot></slot>
    </template>
  </eins-starmenu-btn>
</template>

<script>
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpb',
  components: {
    EinsStarmenuBtn
  },
  props: {
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped>

</style>
