<template>
  <eins-layout-list
      #default="{ item }"
      :items="deals"
  >
    <slot
        name="item"
        :item="item"
    >
      <eins-deal-list-item
          :deal="item"
      />
    </slot>
    <div style="position:fixed; bottom: 56px;right:4px;">
      <slot name="switcher"></slot>
    </div>
  </eins-layout-list>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsDealListItem from '@/components/deal/EinsDealListItem'

export default {
  name: 'EinsDealList',
  components: {
    EinsDealListItem,
    EinsLayoutList
  },
  props: {
    deals: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>
</style>
