<template>
  <v-card
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-row
        no-gutters
    >
      <v-col
          cols="4"
          class="pr-2"
      >
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <slot name="image" />
      </v-col>
      <v-col
          cols="1"
      >
        <v-row
            no-gutters
            class="text-center fill-height flex-column"
            align-items="space-around"
            justify="space-around"
        >
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <slot name="icons" />
        </v-row>
      </v-col>
      <v-col
          cols="7"
          style="position: relative;"
      >
        <v-row
            class="flex-column fill-height flex-nowrap"
            no-gutters
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        >
          <v-col class="flex-grow-0">
            <v-card-title class="body-1 pt-0 pb-2">
              <div style="max-width:100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                <!-- eslint-disable-next-line vue/html-self-closing -->
                <slot name="title" />
              </div>
            </v-card-title>
            <v-card-subtitle class="subtitle-2 pb-3">
              <div style="max-width:100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                <!-- eslint-disable-next-line vue/html-self-closing -->
                <slot name="subtitle" />
              </div>
            </v-card-subtitle>
          </v-col>
          <v-col>
            <v-card-text
                class="font-weight-light pt-0 pb-0"
                style="word-break: break-word; height: 100%;"
            >
              <!-- eslint-disable-next-line vue/html-self-closing -->
              <slot />
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
        class="highlight"
        :style="cStyle"
    ></div>
  </v-card>
</template>

<script>
export default {
  name: 'EinsLayoutListItem',
  props: {
    highlight: {
      type: [ String, Array ],
      default: null
    }
  },
  computed: {
    cStyle () {
      const result = {}

      const colors = (() => Array.isArray(this.highlight) ? this.highlight : [this.highlight])()
      if (colors.length === 1) {
        result.backgroundColor = `${colors[0]}  !important`
      } else if (colors.length > 1) {
        let gradient = 'linear-gradient(0, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))'
        const width = 100 / colors.length

        gradient = 'linear-gradient(0'
        for (const color of colors) {
          gradient += `, ${color} ${width}%`
        }
        gradient += ') !important'

        result.backgroundImage = gradient
      } else {
        result.backgroundColor = 'rgba(0,0,0,0) !important'
      }

      return result
    }
  }
}
</script>

<style scoped lang="scss">
  $activityIndicatorWidth: 0.3em ;

  .v-card {
    position: relative;
    overflow: hidden;

    & > .highlight {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: $activityIndicatorWidth;
    }
  }
</style>
