<template>
  <v-row dense>
    <v-col :class="disabled ? 'grey--text' : ''">
      {{ cLabel }}
    </v-col>
    <v-col class="flex-grow-0">
      <v-switch
          v-model="inputValue"
          class="mt-0"
          hide-details
          :color="disabled ? 'grey' : null"
          :disabled="disabled"
          :loading="loading"
      />
    </v-col>
    <v-col
        cols="12"
        class="text-caption grey--text text--darken-1"
    >
      {{ cExplanation }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EinsDealDataRequireApplicationBlock',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    inputValue: false
  }),
  computed: {
    cDealId () {
      return this.$store.getters['deals/selected/deal']?.id
    },
    cCurrentValue () {
      return this.$store.getters['deals/selected/deal']?.require_application
    },
    cLabel () {
      return this.$t('deal.update.requireApplication.label')
    },
    cExplanation () {
      return this.$t(`deal.update.requireApplication.explanation`)
    }
  },
  watch: {
    cCurrentValue: {
      immediate: true,
      handler: 'onChangeCurrentValue'
    },
    inputValue: {
      handler: 'onChangeInputValue'
    }
  },
  methods: {
    onChangeCurrentValue () {
      this.setCurrentValue()
    },
    onChangeInputValue (value) {
      if (value !== this.cCurrentValue) {
        this.$nextTick().then(() => this.setCurrentValue())
        this.updateGroup()
      }
    },
    setCurrentValue () {
      this.inputValue = this.cCurrentValue
    },
    updateGroup () {
      this.loading = true
      this.$http.$api.patch(`deals/${this.cDealId}`, {
        require_application: !this.cCurrentValue
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.$store.dispatch('deals/selected/update'))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
