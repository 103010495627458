<template>
  <v-list>
    <eins-blockage-list-element
        v-for="blockage in blockages"
        :key="blockage.id"
        :blockage="blockage"
    />
  </v-list>
</template>

<script>
import EinsBlockageListElement from '@/components/blockage/EinsBlockageListElement'

export default {
  name: 'EinsBlockageList',
  components: {
    EinsBlockageListElement
  },
  props: {
    blockages: {
      type: Array,
      required: true
    }
  }
}
</script>
