export default {
  'flow': {
    'application': {
      'name': 'Bewerbung',
      'description': 'Bitte bewerbe Dich für den @.lower:(terms.deal)'
    },
    'direct': {
      'name': 'Tauschen',
      'description': 'Du kannst den @.lower:(terms.deal) direkt tauschen'
    }
  },
  'status': {
    'open': {
      'owner': '@.lower:(terms.players) könne sich für den @.lower:(terms.deal) bewerben',
      'foreigner': 'Du kannst Dich für den @.lower:(terms.deal) bewerben.',
      'applicant': 'Bitte warte, bis der Besitzer auf Deine Bewerbung antwortet'
    },
    'open-direct': {
      'owner': '@.lower:(terms.players) können den @.lower:(terms.deal) dirket tauschen',
      'foreigner': 'Du kannst den @.lower:(terms.deal) sofort erhalten'
    },
    'assigned': {
      'owner': 'Dein @.lower:(terms.deal) wartet auf die Bestätigung des Tausches',
      'assignee': '\'Du kannst den @.lower:(terms.deal) tauschen',
      'foreigner': 'Du kannst Dich für den @.lower:(terms.deal) bewerben.',
      'applicant': 'Der @.lower:(terms.deal) wurde mit einem anderen Spieler getauscht'
    },
    'canceled': {
      'owner': 'Du hast den @.lower(terms.deal) abbrechen.',
      'foreigner': 'Der @.lower(terms.deal) wurde abgebrochen.'
    },
    'bought': {
      'owner': 'Der @.lower:(terms.deal) wurde getauscht.',
      'assignee': '@:(deal.status.done.owner)',
      'applicant': 'Der @.lower:(terms.deal) wurde mit jemand anderem getauscht.'
    }
  },
  'type': {
    'product': '@.lower:(terms.product)',
    'service': '@.lower:(terms.service)'
  },
  'description': '@:(terms.description)',
  'details': {
    'time': {
      'title': 'Zeit: {0} h / @:(terms.stars) (hours) & @:(terms.points) (minutes)',
      'sub-title': '@:(terms.stars) (hours) für diese @.lower:(terms.deal) entspricht ungefähr dieser Zeit.'
    },
    'flow': {
      'title': 'Handhabung: {0}'
    },
    'id': {
      'title': 'ID #{0}',
      'sub-title': 'Dies ist die Identifikationsnummer für @.lower:(terms.deal)'
    },
    'stars': {
      'title': 'Bu bezahltst {0} @:(terms.stars)',
      'sub-title': 'Das sind ungefähr {0} hours.'
    },
    'points': {
      'title': 'Bu bezahltst {0} @:(terms.points)',
      'sub-title': 'Das sind ungefähr {0} minutes.'
    },
    'status': {
      'title': '@:(terms.deal) Status'
    },
    'group': {
      'sub-title': 'Der @.lower:(terms.deal) wird angeboten von dem this@.lower:(terms.group)'
    },
    'player': {
      'sub-title': 'Der @.lower:(terms.deal) wird angeboten von dem @.lower:(terms.player)'
    },
    'type': {
      'sub-title': 'Der @.lower:(terms.deal) ist ein {0}'
    }
  },
  'finished': {
    'player': 'Der @.lower:(terms.player) hat den @.lower:(terms.deal) erhalten.',
    'group': 'Das @.lower:(terms.group) hat den @.lower:(terms.deal) erhalten.'
  },
  create: {
    title: {
      label: 'Titel',
      required: 'Bitte geben Sie einen Titel für der @.lower:(terms.deal) ein',
      min: 'Der Titel muss eine Länge von 3 Zeichen oder länger haben.'
    },
    titlePicture: {
      title: '@:(terms.deal) Bild',
      cancel: 'Abbrechen',
      submit: 'Bild nutzen',
      required: 'Bitte gebe ein Titelbild für Deinen @.lower:(terms.deal) an'
    },
    description: {
      label: '@.lower:(terms.deal) Details',
      required: 'Please provide a description for your @.lower:(terms.deal) ',
      min: 'Die Beschreibung muss mindestens {0} Wörter lang sein.',
      max: 'Die Beschreibung darf eine Länge von {0} Zeichen nicht überschreiten.'
    },
    value: {
      label: 'Preis'
    },
    scope: {
      dialogTitle: 'Gehört der @:(terms.deal) zu einem @:(terms.group)?',
      listCaption: 'Nur für @:(terms.group) Mitglieder',
      voidOption: 'keine',
      currentValueLabel: '@:(terms.group)',
      currentValueHintEmpty: 'Jeder kann Deinen @.lower:(terms.deal) sehen.',
      currentValueHintSimple: 'Der @.lower:(terms.deal) gehört zur ausgewählten @.lower:(terms.group). Nur Mitglieder können darauf zugreifen.',
      currentValueHintExtended: 'Der @.lower:(terms.deal) gehört zu dieser @.lower:(terms.group). Weitere Optionen finden Sie unten.',
      submit: 'Auswahl bestätigen'
    },
    public: {
      label: 'Öffentlich',
      explanationTrue: 'Jeder kann das @.lower:(terms.deal) sehen',
      explanationFalse: 'Nur Mitglieder können den @.lower:(terms.deal) sehen.',
      explanationDisabled: 'Diese Option ist nur verfügbar,wenn \'@:(deal.update.isGroupDeal.label)\' aktiv ist.'
    },
    isGroupDeal: {
      label: 'als @.lower:(terms.group) anbieten',
      explanationTrue: 'Biete den @.lower:(terms.deal) als gewähltes @.lower:(terms.group) an, jedes Mitglied bekommt einen Anteil der @.lower:(terms.points) .',
      explanationFalse: 'Biete den @.lower:(terms.deal) selbst an, nur Gruppenmitglieder können ihn sehen.'
    },
    location: {
      addressTitle: '@.lower:(terms.deal) Adresseingabe',
      addressSubmit: 'Speichern',
      required: 'Bitte wählen Sie eine Position auf der Karte oder geben Sie eine Adresse an.'
    },
    requireApplication: {
      label: 'Bewerbung nötig',
      explanation: 'Wenn gewählt, müssen die @.lower:(terms.players) sich auf den @.lower:(terms.deal) bewerben'
    }
  },
  update: {
    title: {
      dialogTitle: 'Titel des @.lower:(terms.deal) anpassen',
      actions: {
        submit: 'Titel speichern'
      },
      success: {
        title: 'Geschafft!',
        text: 'Der Titel dws @.lower:(terms.deal) wurde geändert von \'{0}\' in \'{1}\'.'
      },
      title: {
        label: 'Neuer Title',
        required: 'Bitte gebe einen neuen Titel ein',
        min: 'Der Titel muss eine Länge von mindestens 3 Zeichen haben',
        unchanged: 'Der neue Titel unterscheidet sich nicht vom bisherigen Titel.'
      }
    },
    description: {
      dialogTitle: '@.lower:(terms.description) @.lower:(terms.deal) ändern',
      actions: {
        submit: '@.lower:(terms.description) ändern'
      },
      success: {
        title: 'Geschafft!',
        text: 'TDie @.lower:(terms.description) Deines @.lower:(terms.deal) wurde aktualisiert.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'Die Länge der @.lower:(terms.description) darf 500 Zeichen nicht überschreiten.'
      }
    },
    picture: {
      dialogTitle: 'Bild hochladen',
      success: {
        title: 'Geschafft!',
        text: 'Dein @.lower:(terms.quests) Titelbild wurde gesetzt.'
      },
      actions: {
        submit: 'UpHochladenload',
        setPicture: 'Bild speichern'
      }
    },
    reward: {
      dialogTitle: 'Preis des @:(terms.deal) anpassen',
      currentValueLabel: 'Preis',
      actions: {
        submit: 'Preis ändern'
      },
      success: {
        title: 'Geschafft!',
        text: 'Der Preis für Ihr @:(terms.deal) wurde aktualisiert.'
      }
    },
    location: {
      address: {
        dialogTitle: 'Adresse angeben',
        success: {
          title: 'Addresse aktuallisiert',
          text: 'Die @.lower:(terms.deal)-Adresse wurde aktualisiert, wir werden nun die Kartenposition neu berechnen.'
        },
        actions: {
          submit: 'Addresse aktuallisiert'
        }
      },
      position: {
        success: {
          title: 'Position aktuallisiert',
          text: 'Die @.lower:(terms.deal)-Position wurde aktualisiert, wir berechnen nun die Adresse neu.'
        }
      }
    },
    scope: {
      dialogTitle: 'Gehört der @:(terms.deal) zu einem @:(terms.group)?',
      listCaption: 'Nur für @:(terms.group) Mitglieder',
      voidOption: 'keine',
      currentValueLabel: '@:(terms.group)',
      currentValueHintEmpty: 'Jeder kann Deinen @.lower:(terms.deal) sehen.',
      currentValueHintSimple: 'Der @.lower:(terms.deal) gehört zur ausgewählten @.lower:(terms.group). Nur Mitglieder können darauf zugreifen.',
      currentValueHintExtended: 'Der @.lower:(terms.deal) gehört zu dieser @.lower:(terms.group). Weitere Optionen finden Sie unten.',
      actions: {
        submit: 'Auswahl bestätigen'
      },
      success: {
        title: 'Geschafft!',
        text: 'Die Gruppenzuweisung für Ihr @:(terms.deal) wurde aktualisiert.'
      }
    },
    isGroupDeal: {
      label: 'als @.lower:(terms.group) anbieten',
      explanationTrue: 'Biete den @.lower:(terms.deal) als gewähltes @.lower:(terms.group) an, jedes Mitglied bekommt einen Anteil der @.lower:(terms.points) .',
      explanationFalse: 'Biete den @.lower:(terms.deal) selbst an, nur Gruppenmitglieder können ihn sehen.'
    },
    public: {
      label: 'Öffentlich',
      explanationTrue: 'Jeder kann das @.lower:(terms.deal) sehen',
      explanationFalse: 'Nur Mitglieder können den @.lower:(terms.deal) sehen.',
      explanationDisabled: 'Diese Option ist nur verfügbar,wenn \'@:(deal.update.isGroupDeal.label)\' aktiv ist.'
    },
    requireApplication: {
      label: 'Bewerbung benötigt',
      explanation: 'Wenn gesetzt, müssen @.lower:(terms.players) sich auf den @.lower:(terms.deal) bewerben.'
    }
  }
}
