<template>
  <div>
    <div class="px-3">
      <eins-player-data-name-block />
      <v-row
          align="center"
          class="flex-nowrap"
          style="max-width: 100%;"
      >
        <v-col cols="4">
          <eins-player-data-picture-block style="aspect-ratio: 1;" />
        </v-col>
        <v-col>
          <v-list flat>
            <eins-player-data-language-block />
            <eins-player-data-gps-block />
          </v-list>
        </v-col>
      </v-row>
      <eins-player-data-description-block />
    </div>
    <v-divider class="mt-3 mb-1" />
    <v-expansion-panels
        tile
    >
      <eins-player-data-notification-block />
      <eins-player-data-talents-block />
      <eins-player-data-email-block />
    </v-expansion-panels>
    <div class="px-3 mt-3">
      <eins-player-data-password-block />
    </div>
  </div>
</template>

<script>
import EinsPlayerDataDescriptionBlock from '@/components/player/edit/EinsPlayerDataDescriptionBlock'
import EinsPlayerDataEmailBlock from '@/components/player/edit/EinsPlayerDataEmailBlock'
import EinsPlayerDataGpsBlock from '@/components/player/edit/EinsPlayerDataGpsBlock'
import EinsPlayerDataLanguageBlock from '@/components/player/edit/EinsPlayerDataLanguageBlock'
import EinsPlayerDataNameBlock from '@/components/player/edit/EinsPlayerDataNameBlock'
import EinsPlayerDataNotificationBlock from '@/components/player/edit/EinsPlayerDataNotificationBlock'
import EinsPlayerDataPasswordBlock from '@/components/player/edit/EinsPlayerDataPasswordBlock'
import EinsPlayerDataPictureBlock from '@/components/player/edit/EinsPlayerDataPictureBlock'
import EinsPlayerDataTalentsBlock from '@/components/player/edit/EinsPlayerDataTalentsBlock'

export default {
  name: 'EinsMyProfile',
  components: {
    EinsPlayerDataDescriptionBlock,
    EinsPlayerDataPictureBlock,
    EinsPlayerDataGpsBlock,
    EinsPlayerDataLanguageBlock,
    EinsPlayerDataEmailBlock,
    EinsPlayerDataNameBlock,
    EinsPlayerDataNotificationBlock,
    EinsPlayerDataPasswordBlock,
    EinsPlayerDataTalentsBlock
  },
  mixins: [
  ],
  props: {
  },
  data: () => ({
  })
}
</script>

<style scoped>
</style>
