export const EinsSocialShareFacebook = {
  text: 'Facebook'
}

export const EinsSocialShareTwitter = {
  text: 'Twitter'
}

export const EinsSocialShareWhatsapp = {
  text: 'Whatsapp'
}

export const EinsSocialShareLinkedin = {
  text: 'Linked.in'
}

export const EinsSocialSharePinterest = {
  text: 'Pinterest'
}
