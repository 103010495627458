export default {
  'description': '@:(terms.description)',
  'details': {
    'medal': {
      'title': '@.lower:(terms.player) received the \'{0}\' medal'
    },
    'player': {
      'sub-title': 'This  @.lower:(terms.player) launched the @.lower:(terms.quest).'
    },
    'group': {
      'sub-title': 'The @.lower:(terms.group), that @.lower:(terms.quest) belongs to.'
    },
    'stars': {
      'title': 'You will receive {0} @.lower:(terms.stars)',
      'sub-title': 'This is equal to {0} hours.'
    },
    'points': {
      'title': 'You will receive {0} @.lower:(terms.points)',
      'sub-title': 'This is equal to {0} minutes.'
    },
    'time': {
      'title': 'Time: {0} h / @.lower:(terms.stars) (hours) & @.lower:(terms.points) (minutes)',
      'sub-title': '@.lower:(terms.stars) for this @.lower:(terms.quest) equal to approximately this time.'
    },
    'quest-id': {
      'title': 'ID #{0}',
      'sub-title': 'This is the identification number for this @.lower:(terms.quest)'
    },
    'rating': {
      'title': 'Rating',
      'up-votes': 'Likes: {0}',
      'down-votes': 'Notify: {0}'
    },
    'status': {
      'title': '@:(terms.quest) status'
    }
  },
  'finished': {
    'quest': 'proofed the @.lower:(terms.quest)'
  }
}
