export default {
  status: {
    open: {
      owner: '@.lower:(terms.players) können sich für dieses @.lower:(terms.quest) bewerben',
      foreigner: 'Du kannst Dich für die @.lower:(terms.quest) bewerben'
    },
    processing: {
      owner: 'Die @.lower:(terms.quest) läuft gerade',
      applicant: 'Die @.lower:(terms.quest) wird von einem anderen Bewerber bearbeitet',
      assignee: 'Du machst gerade diese @.lower:(terms.quest)'
    },
    assigned: {
      owner: 'TDie @.lower:(terms.quest) kann vom ausgesuchten Bewerber gestartet werden',
      assignee: 'Du kannst die @.lower:(terms.quest) beginnen',
      applicant: 'Ein anderer @.lower:(terms.player) wurde dieser @.lower:(terms.quest) zugewiesen'
    },
    canceled: {
      owner: 'Du hast die @.lower(terms.quest) abgebrochen.',
      foreigner: 'Die @.lower(terms.quest) wurde abgebrochen.'
    },
    done: {
      owner: 'Die @.lower:(terms.quest) ist bereit, um bestätigt zu werden.',
      assignee: '@:(quest.status.done.owner)',
      applicant: 'Die @.lower:(terms.quest) wurde von jemand anderem fertiggestellt.'
    },
    closed: {
      owner: 'Die @.lower:(terms.quest) wurde abgeschlossen.',
      assignee: '@:(quest.status.closed.owner)',
      applicant: 'Die @.lower:(terms.quest) wurde von jemand anderem abgeschlossen.'
    },
    'open-applied': {
      owner: 'Es gibt neue Bewerber für diese @.lower:(terms.quest)',
      applicant: 'Du bist ein Bewerber für diese @.lower:(terms.quest)'
    }
  },
  type: {
    common: {
      title: '@.lower:(terms.common)',
      text: 'Diese @.lower:(terms.quest) ist ein @.lower:(terms.common) @.lower:(terms.quest) für uns alle.'
    },
    private: {
      title: '@.lower:(terms.private)',
      text: 'Dies ist eine @.lower:(terms.private) @.lower:(terms.quest) für den @.lower:(terms.player)'
    },
    green: {
      title: '@.lower:(terms.future)',
      text: 'Diese @.lower:(terms.quest) ist für die @.lower:(terms.future)'
    },
    nature: {
      title: '@.lower:(terms.future)',
      text: 'Diese @.lower:(terms.quest) ist für die @.lower:(terms.future)'
    },
    future: {
      title: '@.lower:(terms.future)',
      text: 'Diese @.lower:(terms.quest) ist für die @.lower:(terms.future)'
    },
    undefined: {
      title: '@.lower:(terms.future)',
      text: 'Diese @.lower:(terms.quest) ist für die @.lower:(terms.future)'
    }
  },
  create: {
    title: {
      label: '@:(terms.quest) Titel',
      required: 'Bitte gebe einen Titel für Deine @.lower:(terms.quest) ein',
      min: 'Der Titel muss eine Länge von mindestens {0} Zeichen haben'
    },
    titlePicture: {
      title: '@:(terms.quest) Titelbild',
      cancel: 'Abbruch',
      submit: 'Titelbild festlegen',
      required: 'Bitte gib ein Titelbild für Deine @.lower:(terms.quest) an'
    },
    description: {
      label: '@:(terms.quest) Details',
      required: 'Bitte gib eine @.lower:(terms.description) für Deine @.lower:(terms.quest) an.',
      min: '@.lower:(terms.description) muss mindestens {0} Wörter lang sein.'
    },
    value: {
      label: 'Belohnen'
    },
    hideWhenFinished: {
      label: 'Ausblenden, wenn fertig',
      hint: 'Ausblenden aus der Chronik, wenn die @.lower:(terms.quest fertiggestellt wurde.'
    },
    location: {
      addressTitle: 'Lege eine @.lower:(terms.quest) Adresse fest',
      addressSubmit: 'Festlegen',
      required: 'Bitte wähle eine Position auf der Karte oder gebe eine Adresse an.'
    },
    scope: {
      dialogTitle: 'Wer kann sich für die @:(terms.quest) bewerben?',
      listCaption: 'Nur @:(terms.group) Mitglieder',
      voidOption: 'Alle',
      currentValueLabel: 'Auswahl',
      currentValueHint: 'Nur Mitglieder der ausgewählten @:(terms.group) können sich bewerben.' +
          ' Wenn kein @:(terms.group) ausgewählt ist, kann sich jeder bewerben.',
      submit: 'Auswahl ändern'
    }
  },
  update: {
    title: {
      dialogTitle: '@:(terms.quest)-Titel ändern',
      actions: {
        submit: 'Titel ändern'
      },
      success: {
        title: 'Gespeichert!',
        text: 'Der Titel Ihres @.lower:(terms.quest) wurde von „{0}“ in „{1}“ geändert.'
      },
      title: {
        label: 'Neuer Titel',
        required: 'Bitte gebe einen neuen Titel ein',
        min: 'Der Titel muss eine Länge von mindestens 3 Zeichen haben',
        unchanged: 'Der neue Titel unterscheidet sich nicht vom aktuellen Titel.'
      }
    },
    reward: {
      dialogTitle: '@:(terms.quest)-Belohnung ändern',
      currentValueLabel: 'Belohnung',
      actions: {
        submit: 'Belohnung ändern'
      },
      success: {
        title: 'Gespeichert!',
        text: 'Die Belohnung für Deine @:(terms.quest) wurde aktualisiert.'
      }
    },
    scope: {
      dialogTitle: 'Wer kann sich für die @:(terms.quest) bewerben?',
      listCaption: 'Nur @:(terms.group) Mitglieder',
      voidOption: 'Alle',
      currentValueLabel: 'Auswahl',
      currentValueHint: 'Nur Mitglieder der ausgewählten @:(terms.group) können sich bewerben.' +
          ' Wenn kein @:(terms.group) ausgewählt ist, kann sich jeder bewerben.',
      actions: {
        submit: 'Auswahl ändern'
      },
      success: {
        title: 'Gespeichert!',
        text: 'Der Geltungsbereich für Deine @:(terms.quest) wurde aktualisiert.'
      }
    },
    description: {
      dialogTitle: '@.lower:(terms.quest) @.lower:(terms.description) ändern',
      actions: {
        submit: '@.lower:(terms.description) ändern'
      },
      success: {
        title: 'Gespeichert!',
        text: 'Die @.lower:(terms.description) Deiner @.lower:(terms.quest) wurde aktualisiert.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'Die Länge von @.lower:(terms.description) darf 500 Zeichen nicht überschreiten.'
      }
    },
    picture: {
      dialogTitle: 'Bild hochladen',
      success: {
        title: 'Gespeichert!',
        text: 'Dein @.lower:(terms.quests) Titelbild wurde ersetzt.'
      },
      actions: {
        submit: 'Hochladen',
        setPicture: 'Bild ersetzen'
      }
    },
    location: {
      address: {
        dialogTitle: 'Adresse ersetzen',
        success: {
          title: 'Adresse aktualisiert',
          text: 'Die @.lower:(terms.quest)-Adresse wurde aktualisiert, wir werden nun die Kartenposition neu berechnen.'
        },
        actions: {
          submit: 'Adresse aktualisiert'
        }
      },
      position: {
        success: {
          title: 'Position aktualisiert',
          text: 'Die @.lower:(terms.quest)-Position wurde aktualisiert, wir berechnen nun die Adresse neu.'
        }
      }
    }
  }
}
