<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
          v-model="internalValue.email"
          autofocus
          :error="false"
          background-color="grey darken-4"
          outlined
          :label="cText['email.label']"
          :rules="[ valueExists ]"
          @input="onInput"
          @keypress.enter.prevent
          @keyup.enter.prevent="onKeyupEnter"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
          v-model="internalValue.password"
          :error="false"
          background-color="grey darken-4"
          outlined
          :label="cText['password.label']"
          :rules="[ valueExists ]"
          :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
          :type="passwordVisible ? 'password' : 'text'"
          @click:append="() => (passwordVisible = !passwordVisible)"
          @input="onInput"
          @keypress.enter.prevent
          @keyup.enter.prevent="onKeyupEnter"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  MxTranslatable
} from '@/mixins'

const getEmptyItem = () => ({
  email: '',
  password: ''
})

export default {
  name: 'EinsFormLogin',
  mixins: [
    MxTranslatable
  ],
  props: {
    value: {
      type: Object,
      default: getEmptyItem()
    }
  },
  data: () => ({
    passwordVisible: true,
    internalValue: {
      email: '',
      password: ''
    },
    $_mx_translatable: {
      paths: [
        'email.label',
        'password.label'
      ]
    }
  }),
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onKeyupEnter () {
      this.emitSubmit()
    },
    onChangeValue (val) {
      this.setValue(val)
    },
    onInput () {
      this.emitInput()
    },
    setValue (val) {
      this.internalValue.email = typeof val.email === 'string' ? val.email : ''
      this.internalValue.password = typeof val.password === 'string' ? val.password : ''
    },
    emitInput () {
      this.$emit('input', { ...this.internalValue })
    },
    emitSubmit () {
      this.$emit('submit', { ...this.internalValue })
    },
    reset () {
      this.internalValue.password = ''
      this.internalValue.email = ''
    },
    valueExists (val) {
      return !!val
    }
  }
}
</script>

<style scoped>

</style>
