<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }}</v-card-title>
      <!-- <v-card-text>
        <v-textarea
            v-model.trim="message"
            autofocus
            :label="cText['message.label']"
            :hint="cText['message.hint']"
            counter="75"
            rows="2"
        />
      </v-card-text> //-->
      <v-expand-transition>
        <v-alert
            :value="cShowError"
            dismissible
            tile
            dense
            type="error"
            @input="onClickHideErrorMessage"
        >
          <strong>Error!</strong><br />
          {{ cErrorText }}
        </v-alert>
      </v-expand-transition>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            color="primary"
            :disabled="cShowInsufficientBalanceError"
            outlined
            block
            @click="close"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <v-expand-transition>
          <v-layout
              v-if="!success"
              column
          >
            <v-flex>
              <v-btn
                  block
                  :disabled="cShowInsufficientBalanceError"
                  color="primary"
                  :loading="loading"
                  @click="onClickSubmit"
              >
                <v-icon>
                  {{ 'mdi-credit-card-check-outline' }}
                </v-icon>
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-flex>
            <v-flex class="mt-2">
              <v-btn
                  block
                  color="grey"
                  outlined
                  :disabled="loading"
                  @click="onClickCancel"
              >{{ cText['btn.cancel'] }}</v-btn>
            </v-flex>
          </v-layout>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import {
  Entity
} from '@/constants'
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgExchangeDeal',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    error: null,
    success: false,
    message: '',
    $_mx_translatable: {
      paths: [
        'title',
        'error.title',
        'btn.close',
        'btn.submit',
        'btn.cancel',
        'message.label',
        'message.hint'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogExchangeDeal']
      },
      set (value) {
        this.$store.commit('dialogExchangeDeal', value)
      }
    },
    cIsApplyingAsGroup () {
      return !!this.cUserAssignment?.group
    },
    cIsApplyingAsPlayer () {
      return this.cIsApplyingAsGroup === undefined
    },
    cUserIsAssignee () {
      return !!this.cUserAssignment
    },
    cUserAssignment () {
      return this.cDeal?.assignments
        .find(assignment => assignment.user.id === this.$store.getters['players/current/id'])
    },
    cShowInsufficientBalanceError () {
      return this.cIsApplyingAsPlayer && !this.cBalanceIsSufficient
    },
    cPlayerBalanceAvailable () {
      return this.$store.getters['players/current/timeAccount']?.balance_available
    },
    cBalanceAvailable () {
      return this.cDeal ? this.cDeal?.value : ''
    },
    cBalanceIsSufficient () {
      return this.cPlayerBalanceAvailable > this.cBalanceAvailable
    },
    cShowError () {
      return !!this.error || this.cShowInsufficientBalanceError
    },
    cRouteId () {
      return this.$route.params.id
    },
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        default:
          return null
      }
    },
    cDeal () {
      return this.$store.getters.dialogPayload('dialogExchangeDeal')?.deal
    },
    cEntity () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        default:
          return null
      }

      return result
    },
    cErrorText () {
      let result = ''

      if (this.error) {
        switch (this.error) {
          case 409:
            result = this.$t(`components.${this.$options.name}.error.409`, [ this.cEntity?.name ?? '' ])
            break
          default:
            result = this.$t(`components.${this.$options.name}.error.unknown`)
            break
        }
      } else if (this.cShowInsufficientBalanceError) {
        result = this.$t(`components.${this.$options.name}.error.balance`)
      }

      return result
    }
  },
  watch: {
    '$route.params.id' (value, oldValue) {
      if (value !== oldValue) {
        this.message = ''
      }
    },
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.success = false
          this.error = false
          this.message = ''
        } else {
          this.message = ''
        }
      }
    },
    onClickSubmit () {
      const assignment = this.cUserAssignment

      if (assignment) {
        let url = `/deals/${this.cDeal.id}/applications/${assignment.user.id}`
        if (assignment.group?.id) {
          url = `${url}/${assignment.group.id}`
        }
        this.$http.$api.patch(url, { confirmed: true })
          .then(() => {
            this.$store.dispatch('deals/selected/update')
            this.success = true
          })
          .catch((e) => {
            this.error = e.response?.status ?? true
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
        this.error = true
      }
    },
    onClickClose () {
      this.close()
    },
    onClickHideErrorMessage () {
      this.error = null
    },
    onClickCancel () {
      this.close()
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
