<template>
  <v-dialog
      fullscreen
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-row
        class="fill-height flex-column"
        style="position: relative;"
        no-gutters
    >
      <v-col>
        <eins-input-position
            v-if="showMap"
            v-model="newValue"
            :zoom="18"
            style="width: 100%;"
            height="100%"
        />
      </v-col>
      <v-btn
          fab
          small
          color="secondary"
          style="position: absolute; top: 0.5em; right: 0.5em; z-index: 400;"
          @click="onClickCancel"
      ><v-icon large>mdi-close</v-icon></v-btn>
      <v-btn
          fab
          color="primary"
          style="position: absolute; bottom: 1.5em; left: 50%; z-index: 400; transform: translateX(-50%);"
          @click="onClickSave"
      ><v-icon large>mdi-content-save-outline</v-icon></v-btn>
    </v-row>
  </v-dialog>
</template>

<script>
import EinsInputPosition from '@/components/input/EinsInputPosition'
import {
  Map
} from '@/constants'

export default {
  name: 'EinsInputLocationPositionDialog',
  components: {
    EinsInputPosition
  },
  props: {
    currentValue: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    showMap: false,
    mapObject: null,
    internalValue: false,
    newValue: {
      lat: null,
      lon: null
    }
  }),
  computed: {
    cSource () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy;&nbsp;<a class="copy" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      }
    },
    cUserPosition () {
      if (
        !isNaN(this.$store.getters['players/current/latitude']) &&
        !isNaN(this.$store.getters['players/current/longitude'])
      ) {
        return {
          latitude: this.$store.getters['players/current/latitude'],
          longitude: this.$store.getters['players/current/longitude']
        }
      }

      return null
    },
    cInitialPosition () {
      if (this.currentValue) {
        return this.currentValue
      } else if (this.cUserPosition) {
        return this.cUserPosition
      } else {
        return {
          latitude: Map.CENTER_DEFAULT_LAT,
          longitude: Map.CENTER_DEFAULT_LNG
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onUpdateValue'
    },
    cDisableDragging: {
      immediate: true,
      handler (value) {
        if (value && this.mapObject) {
          this.mapObject.dragging.disable()
        }
      }
    }
  },
  methods: {
    onMapReady (map) {
      this.mapObject = map

      map.on('move', (evt) => {
        const mapCenter = map.getCenter()
        this.setNewValue(mapCenter)
      })
    },
    onUpdateCenter (center) {
      this.setNewValue(center)
    },
    onUpdateValue (value) {
      this.setValue(value)
      this.reset()
    },
    onInputDialog (value) {
      this.setValue(value)
      this.emitInput()
    },
    onClickSave () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    setNewValue (center) {
      this.newValue = {
        lat: center.lat,
        lon: center.lng
      }
    },
    setValue (value) {
      this.internalValue = value
      this.$nextTick().then(() => {
        this.showMap = value
      })
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    submit () {
      this.$emit('submit', {
        latitude: this.newValue.lat,
        longitude: this.newValue.lon
      })
      this.closeDialog()
    },
    closeDialog () {
      this.internalValue = false
      this.emitInput()
      this.reset()
    },
    reset () {
      this.success = false
      this.newValue = {
        lat: this.cInitialPosition.latitude,
        lon: this.cInitialPosition.longitude
      }
    }
  }
}
</script>

<style scoped>
</style>
