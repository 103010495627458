<template>
  <eins-layout-view-detail>
    <v-slide-y-transition>
      <v-progress-linear
          v-show="cLoading"
          height="5"
          indeterminate
      />
    </v-slide-y-transition>
    <v-card
        ma-0
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <v-row
          class="flex-column flex-nowrap fill-height"
          style="overflow: hidden;"
          no-gutters
      >
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-card-title class="headline">
            <template v-if="cQuestType === 'private'">
              <v-icon size="36">mdi-map-marker-left</v-icon>
              <span class="ml-4">
                {{ cText['captions.private'] }}
              </span>
            </template>
            <template v-else-if="cQuestType === 'common'">
              <v-icon size="36">mdi-map-marker-star</v-icon>
              <span class="ml-4">
                {{ cText['captions.common'] }}
              </span>
            </template>
            <template v-else-if="cQuestType === 'nature' || cQuestType === 'green'">
              <v-icon size="36">mdi-map-marker-up</v-icon>
              <span class="ml-4">
                {{ cText['captions.nature'] }}
              </span>
            </template>
          </v-card-title>
        </v-col>
        <v-col style="overflow-y: auto;">
          <v-card-text>
            <v-form
                ref="form"
                v-model="formValid"
            >
              <eins-quest-create-form
                  v-model="formData"
                  :quest-type="cQuestType"
              />
            </v-form>
          </v-card-text>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-card-actions>
            <v-btn
                depressed
                :disabled="saving"
                @click="onClickCancel"
            >
              <v-icon>mdi-cancel</v-icon>
              {{ cText['buttons.cancel.label'] }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                depressed
                :loading="saving"
                @click="onClickSubmit"
            >
              <v-icon>mdi-content-save</v-icon>
              {{ cText['buttons.submit.label'] }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <!-- Cancel-Alert -->
    <v-dialog
        :value="alert.cancel"
    >
      <v-card class="grey darken-1">
        <v-card-title class="headline grey darken-2">
          <v-icon>mdi-alert-box</v-icon>
          <span class="ml-2">
            {{ cText['alerts.cancel.caption'] }}
          </span>
        </v-card-title>
        <v-card-text class="pa-3">
          {{ cText['alerts.cancel.text'] }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              @click="onClickCancelConfirmed"
          >
            <v-icon>mdi-cancel</v-icon>
            {{ cText['buttons.cancel.label'] }}
          </v-btn>
          <v-btn
              color="primary"
              depressed
              @click="onClickBack"
          >
            <v-icon>mdi-backup-restore</v-icon>
            {{ cText['buttons.back.label'] }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsQuestCreateForm from '@/components/quest/EinsQuestCreateForm'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import { QuestType, Membership } from '@/constants'

const validTypes = [
  QuestType.COMMON,
  QuestType.NATURE,
  QuestType.GREEN,
  QuestType.PRIVATE
]

export default {
  name: 'ViewQuestCreate',
  components: {
    EinsLayoutViewDetail,
    EinsQuestCreateForm
  },
  mixins: [MxTranslatable],
  data: () => ({
    formData: null,
    formValid: false,
    saving: false,
    playerGroups: null,
    alert: {
      cancel: false
    },
    $_mx_translatable: {
      paths: [
        'captions.private',
        'captions.common',
        'captions.nature',
        'alerts.cancel.caption',
        'alerts.cancel.text',
        'buttons.submit.label',
        'buttons.cancel.label',
        'buttons.back.label'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cGroups () {
      return this.playerGroups || []
    },
    cQuestType () {
      return this.$route.query.type ?? 'private'
    },
    cDisabled () {
      return !this.formValid || !this.formData?.picture || this.cBalanceIsNotAvailable
    },
    cBalanceAvailable () {
      return this.$store.getters['players/current/timeAccount']?.balance_available
    },
    cBalanceIsNotAvailable () {
      return this.cQuestType === 'private' && (this.cBalanceAvailable < 1)
    }
  },
  beforeRouteEnter (to, from, next) {
    if (validTypes.includes(to.query.type)) {
      next(vm => {
        if (to.query.player_group_id) {
          vm.setGroupId(to.query.player_group_id)
        } else {
          vm.getPlayerGroups()
            .then(result => {
              vm.loadTemplate(to.query.template)
            })
        }
      })
    } else {
      next(`${to.path}?type=${validTypes[0]}`)
    }
  },
  created () {
    this.getPlayerGroups()
  },
  methods: {
    /*
    setGroupId (groupId) {
      this.formData = { 'player_group_id': groupId }
    }, */
    setGroupId (groupId) {
      return this.$store.dispatch('groups/selected/load', groupId)
        .then(result => {
          this.$set(this, 'formData', {
            longitude: result.data.longitude,
            latitude: result.data.latitude,
            player_group_id: groupId
          })
          return result.data
        })
    },
    getValidMembership () {
      let result
      switch (this.groupRole) {
        case null:
          result = null
          break
        case 'member':
          result = Membership.MEMBER
          break
        case 'admin':
          result = Membership.ADMIN
          break
        case 'none':
          result = Membership.NONE
          break
        case 'owner':
          result = Membership.OWNER
          break
      }
      return result
    },
    getPlayerGroups () {
      return this.$http.$api.get('/groups', { params: {
        player_id: this.$store.getters['players/current/id'],
        player_role: this.getValidMembership(),
        no_distance: true
      } })
        .then((response) => {
          this.$set(this.$data, 'playerGroups', response.data?.groups ?? [])
          return this.playerGroups
        })
    },
    loadTemplate (id) {
      return this.$store.dispatch('quests/selected/load', id)
        .then(result => {
          this.$set(this, 'formData', this.prepareData(result.data))
          return result.data
        })
    },
    prepareData (data) {
      let payload = data
      delete payload.id
      delete payload.title_picture_feed
      delete payload.title_picture_full
      delete payload.created_at
      delete payload.updated_at
      payload.picture = null

      // Ist Template ein eigenes Quest?
      // Value check
      if (this.cQuestType === 'private') {
        if (payload.value > this.cBalanceAvailable) {
          payload.value = this.cBalanceAvailable
        }
      }
      // Gruppenzugehörigkeit Check
      if (payload.player_group_id) {
        payload.player_group_id = this.cGroups?.find(group => group.id === payload.player_group_id)?.id ?? null
      }

      return payload
    },
    onScroll (e) {
    },
    cLoading () {
    },
    onClickSubmit () {
      const formValid = this.$refs.form.validate()
      if (formValid) {
        this.submit()
      }
    },
    submit () {
      this.saving = true
      const payload = {
        ...this.formData
      }
      delete payload.picture
      let questId
      this.$http.$api.post('/quests', payload)
        .then((result) => {
          // return: bild hochladen
          const picture = this.formData.picture
          questId = result.data.id
          return this.$http.$api.put('/quests/' + questId + '/picture', picture, { headers: {
            'Content-Type': picture.type
          } })
        })
        .then(() => {
          this.$router.push('/quests/' + questId)
          return true
        })
        .catch(() => {
          // console.log(er)
        })
        .finally(() => {
          this.saving = false
        })
    },
    onClickCancel () {
      this.alert.cancel = true
    },
    onClickBack () {
      this.alert.cancel = false
    },
    onClickCancelConfirmed () {
      this.$router.push('/quests')
    }
  }
}
</script>

<style scoped>

</style>
