<template>
  <v-dialog
      v-model="open"
      persistent
  >
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }}</v-card-title>
      <v-card-text v-if="initializing">
        <v-row>
          <v-spacer />
          <v-col class="flex-grow-0 flex-shrink-1">
            <v-progress-circular
                indeterminate
            />
          </v-col>
          <v-spacer />
        </v-row>
      </v-card-text>
      <template v-else-if="!cCanDelete">
        <v-alert
            type="error"
            tile
        >
          <strong>{{ cText['cantLeave.title'] }}</strong><br />
          {{ cText['cantLeave.text'] }}
        </v-alert>
        <v-card-actions>
          <v-btn
              block
              color="grey"
              outlined
              :disabled="loading"
              @click="onClickCancel"
          >Ok</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          {{ cText.text }}
        </v-card-text>
        <v-expand-transition>
          <v-alert
              :value="cShowError"
              dismissible
              tile
              dense
              type="error"
              @input="onClickHideErrorMessage"
          >
            <strong>Error!</strong><br />
            {{ cErrorText }}
          </v-alert>
        </v-expand-transition>
        <v-card-actions>
          <eins-btn-timed
              v-if="success"
              color="primary"
              outlined
              block
              @click="close"
          >
            {{ cText['btn.close'] }}
          </eins-btn-timed>
          <v-expand-transition>
            <v-layout
                v-if="!success"
                column
            >
              <v-flex>
                <v-btn
                    block
                    color="primary"
                    :loading="loading"
                    @click="onClickSubmit"
                >
                  {{ cText['btn.submit'] }}
                </v-btn>
              </v-flex>
              <v-flex class="mt-2">
                <v-btn
                    block
                    color="grey"
                    outlined
                    :disabled="loading"
                    @click="onClickCancel"
                >{{ cText['btn.cancel'] }}</v-btn>
              </v-flex>
            </v-layout>
          </v-expand-transition>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'
import {
  HttpStatus,
  Membership
} from '@/constants'

export default {
  name: 'EinsStarmenuDlgCloseAccount',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    initializing: true,
    loading: false,
    error: null,
    success: false,
    ownedGroups: null,
    $_mx_translatable: {
      paths: [
        'title',
        'text',
        'cantLeave.title',
        'cantLeave.text',
        'error.title',
        'btn.close',
        'btn.submit',
        'btn.cancel'
      ]
    }
  }),
  computed: {
    cCanDelete () {
      return Array.isArray(this.ownedGroups) && this.ownedGroups.length === 0
    },
    cErrorText () {
      let result = ''

      if (this.error) {
        switch (this.error) {
          default:
            result = this.$t(`components.${this.$options.name}.error.unknown`)
            break
        }
      }

      return result
    },
    cShowError () {
      return !!this.error
    },
    open: {
      get () {
        return this.$store.getters['dialogCloseAccount']
      },
      set (value) {
        this.$store.commit('dialogCloseAccount', value)
      }
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.success = false
          this.error = false
          this.initializing = true

          return this.$http.$api.get('/groups', { params: {
            player_id: this.$store.getters['players/current/id'],
            player_role: Membership.OWNER,
            no_distance: true
          } })
            .then((response) => {
              this.$set(this.$data, 'ownedGroups', response.data?.groups ?? [])
              return this.ownedGroups
            })
            .finally(() => {
              this.initializing = false
            })
        } else {
          this.ownedGroups = null
        }
      }
    },
    onClickClose () {
      this.close()
    },
    onClickHideErrorMessage () {
      this.error = null
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    close () {
      this.open = false
    },
    submit () {
      this.loading = true
      this.error = false

      return this.$http.$api.delete(`/players/${this.$store.getters['players/current/id']}`)
        .then(() => {
          this.success = true
          return this.success
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status === HttpStatus.GONE
          ) {
            return Promise.resolve()
          } else {
            this.error = e.response?.status ?? true
            return e
          }
        })
        .then(() => {
          this.$http.$api.axios.defaults.headers.common.Authorisation = null
          this.$store.commit('players/current/invalidate')
          this.open = false
          return true
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel () {
      this.close()
    }
  }
}
</script>

<style scoped>
</style>
