<template>
  <v-row
      no-gutters
      style="line-height: 0.6; text-align: center;"
      :style="cContainerStyle"
  >
    <div
        v-for="(point, idx) in cPoints"
        :key="idx"
        class="pa-0"
        style="padding: 0"
    >
      <v-icon
          v-if="value >= point"
          style="font-size: 10px; margin:0"
          :color="iconColor"
      >mdi-square</v-icon>
      <v-icon
          v-else
          style="font-size: 10px;"
          :color="iconColor"
      >mdi-square-outline</v-icon>
    </div>
    <v-row
        v-if="cShowBonusCaption"
        class="pt-2 text-right"
    >
      <v-col class="text-subtitle-2">
        <label :style="`color:${textColor}`">
          - {{ cBonus }} Points
        </label>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { MxHasModel } from '@/mixins'

export default {
  name: 'EinsTimePoints',
  mixins: [
    MxHasModel
  ],
  props: {
    points: {
      type: [ String, Number ],
      default: 60
    },
    perRow: {
      type: [ String, Number ],
      default: null
    },
    shrink: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ],
      default: 0
    },
    wrap: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: undefined
    },
    textColor: {
      type: String,
      default: 'black'
    },
    bonusCaption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cShowBonusCaption () {
      return this.bonusCaption
    },
    cBonus () {
      return this.value
    },
    cColStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow)) {
        result['width'] = `${100 / perRow}%`
        result['flexBasis'] = 'unset'
        result['textAlign'] = 'left'
      }

      return result
    },
    cContainerStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow) && this.shrink) {
        result['width'] = `${perRow}em`
      }

      return result
    },
    cOverflow () {
      return this.value >= this.points
    },
    cPoints () {
      const result = []
      for (let i = 1; i <= this.points; i++) {
        result.push(i)
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
