export default {
  labels: {
    title: '@.lower:(terms.points)',
    overlayTitle: 'Price in @.lower:(terms.points)',
    overlaySubTitle: '1 @.lower:(terms.point) ~ 1,- €',
    point: '@.lower:(terms.point)',
    points: '@.lower:(terms.points) ',
    equal: '=',
    minute: 'minute',
    minutes: 'minutes',
    star: '@.lower:(terms.star)',
    stars: '@.lower:(terms.stars)',
    and: 'and',
    BalanceTitle: '@.lower:(terms.points) you get for this @.lower:(terms.deal)'
  }
}
