<template>
  <v-dialog
      v-model="dialog"
      scrollable
  >
    <template #activator="{ on }">
      <v-sheet
          v-on="on"
      >
        <v-alert
            class="ma-0 mt-2"
            type="info"
            v-bind="$attrs"
            v-on="$listeners"
        >
          <template #prepend>
            <v-icon
                left
                color="primary"
            >
              {{ icon }}
            </v-icon>
          </template>
          {{ text }}
        </v-alert>
      </v-sheet>
    </template>
    <v-card
        style="position: relative;"
        class="detail-content"
    >
      <v-btn
          icon
          style="position: absolute; top: 0; right: 0;"
          @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        <div style="word-break: break-word;">
          <v-icon left>{{ icon }}</v-icon>
          {{ text }}
        </div>
      </v-card-title>
      <v-card-text>
        {{ textLong }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EinsLayoutItemDetailAlert',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    textLong: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
