<template>
  <v-dialog>
    <template #activator="{ on }">
      <slot
          name="activator"
          :on="on"
      ></slot>
    </template>
    <v-card>
      <eins-api-image
          #default="{ src }"
          :src="cTitlePicture"
      >
        <v-img
            :src="src"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            aspect-ratio="1"
        >
          <v-btn
              style="position: absolute; right: 0.25em; top: 0.25em;"
              icon
              large
              :to="cDetailLink.url"
          >
            <v-icon>{{ cDetailLink.icon }}</v-icon>
          </v-btn>
          <v-card-title v-text="cApplicantGroup ? cApplicantGroup.title : cApplicant.name" />
          <v-card-text style="max-height: 100px; overflow-y: auto;">
            {{ application.application_text }}
          </v-card-text>
        </v-img>
      </eins-api-image>
      <v-card-actions>
        <v-row
            dense
            class="flex-nowrap"
        >
          <v-col>
            <v-btn
                outlined
                block
                color="primary"
                @click.stop.prevent="onClickStartConversation"
            ><v-icon>mdi-message-text</v-icon></v-btn>
          </v-col>
          <v-col v-if="cHasAcceptAction">
            <v-btn
                outlined
                block
                color="primary"
                :disabled="cDisableAcceptAction"
                :loading="loadingAccept"
                @click.stop.prevent="onClickAccept"
            ><v-icon>mdi-lock-open-outline</v-icon></v-btn>
          </v-col>
          <v-col v-if="cHasRejectAction">
            <v-btn
                outlined
                block
                color="primary"
                :disabled="cDisableRejectAction"
                :loading="loadingReject"
                @click.stop.prevent="onClickReject"
            ><v-icon>mdi-minus-circle</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  startConversationWith
} from '@/utils/chat'
import {
  ApplicationState,
  Entity
} from '@/constants'
import {
  RegRouteDeal,
  RegRouteGroup,
  RegRoutePlayer,
  RegRouteQuest
} from '@/utils/regex'
import EinsApiImage from '@/components/EinsApiImage'

export default {
  name: 'EinsApplicationDetailsDialog',
  components: {
    EinsApiImage
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    disableAcceptAction: {
      type: Boolean,
      default: false
    },
    hideAcceptAction: {
      type: Boolean,
      default: false
    },
    showRejectAction: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingAccept: false,
    loadingReject: false
  }),
  computed: {
    cTitlePicture () {
      return this.cApplicantGroup ? this.cApplicantGroup.title_picture_feed : this.cApplicant.title_picture_feed
    },
    cIsAccepted () {
      return this.application?.state === ApplicationState.ACCEPTED ||
          this.application?.state === ApplicationState.CONFIRMED
    },
    cIsHandled () {
      return this.application?.state !== ApplicationState.UNHANDLED
    },
    cDisableAcceptAction () {
      return this.disableAcceptAction && !this.loadingReject
    },
    cDisableRejectAction () {
      return this.disableRejectAction && !this.loadingAccept
    },
    cHasAcceptAction () {
      return !this.hideAcceptAction && !this.cIsHandled
    },
    cHasRejectAction () {
      return this.showRejectAction && !this.cIsHandled
    },
    cIcon () {
      switch (this.application?.state) {
        case ApplicationState.REJECTED:
          return 'mdi-close-box-outline'
        case ApplicationState.ACCEPTED:
        case ApplicationState.CONFIRMED:
          return 'mdi-lock-open-outline'
        case ApplicationState.UNHANDLED:
        default:
          return 'mdi-human-greeting'
      }
    },
    cDetailLink () {
      if (this.cApplicantGroup) {
        return { url: `/groups/${this.cApplicantGroup.id}`, icon: 'mdi-google-circles-communities' }
      } else {
        return { url: `/players/${this.cApplicant.id}`, icon: 'mdi-account' }
      }
    },
    cApplicant () {
      return this.application?.applicant ?? null
    },
    cApplicantGroup () {
      return this.application?.applicant_group ?? null
    },
    cArea () {
      const name = this.$route.name

      if (RegRoutePlayer.test(name)) {
        return Entity.PLAYER
      } else if (RegRouteDeal.test(name)) {
        return Entity.DEAL
      } else if (RegRouteQuest.test(name)) {
        return Entity.QUEST
      } else if (RegRouteGroup.test(name)) {
        return Entity.GROUP
      } else {
        return null
      }
    },
    cSubject () {
      switch (this.cArea) {
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal']
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest']
        case Entity.GROUP:
          return this.$store.getters['groups/selected/group']
        default:
          return null
      }
    }
  },
  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    onClickAccept () {
      this.acceptApplication()
    },
    onClickReject () {
      this.rejectApplication()
    },
    startConversation () {
      if (this.cApplicant) {
        startConversationWith(this.cApplicant.id)
      }
    },
    rejectApplication () {
      if (
        this.cApplicant &&
        this.cSubject
      ) {
        this.loadingReject = true
        let fragment
        switch (this.cArea) {
          case Entity.DEAL:
            fragment = 'deals'
            break
          case Entity.QUEST:
            fragment = 'quests'
            break
          case Entity.GROUP:
            fragment = 'groups'
            break
          default:
            return null
        }
        let route = `/${fragment}/${this.cSubject.id}/applications/${this.cApplicant.id}`

        if (this.cApplicantGroup) {
          route = `${route}/${this.cApplicantGroup.id}`
        }

        return this.$http.$api.patch(route, {
          rejected: true
        })
          .then(() => Promise.all([
            this.$store.dispatch(`${fragment}/selected/applications/update`),
            this.$store.dispatch(`${fragment}/selected/update`)
          ]))
          .finally(() => {
            this.loadingReject = false
          })
      } else {
        return Promise.reject(new Error('Missing requirements to accept application'))
      }
    },
    acceptApplication () {
      if (
        this.cApplicant &&
          this.cSubject
      ) {
        this.loadingAccept = true
        let fragment
        switch (this.cArea) {
          case Entity.DEAL:
            fragment = 'deals'
            break
          case Entity.QUEST:
            fragment = 'quests'
            break
          case Entity.GROUP:
            fragment = 'groups'
            break
          default:
            return null
        }
        let route = `/${fragment}/${this.cSubject.id}/applications/${this.cApplicant.id}`

        if (this.cApplicantGroup) {
          route = `${route}/${this.cApplicantGroup.id}`
        }

        return this.$http.$api.patch(route, {
          accepted: true
        })
          .then(() => Promise.all([
            this.$store.dispatch(`${fragment}/selected/applications/loadAll`),
            this.$store.dispatch(`${fragment}/selected/update`)
          ]))
          .finally(() => {
            this.loadingAccept = false
          })
      } else {
        return Promise.reject(new Error('Missing requirements to accept application'))
      }
    }
  }
}
</script>

<style scoped>

</style>
