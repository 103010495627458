// @vue/component
/**
 * Helper for redirection of classes and attributes to child components.
 * @mixin
 */
export const MxPropRedirection = {
  data () {
    return {
      $_mx_prop_redirection: {
        blockedEvents: [],
        blockedAttributes: []
      }
    }
  },
  computed: {
    cListeners () {
      const result = this.$listeners

      // .$data needed since '$' - prefixed data can not be accessed the usual way
      for (const listenerName of this.$data.$_mx_prop_redirection.blockedEvents) {
        if (result.hasOwnProperty(listenerName)) {
          delete result[listenerName]
        }
      }

      return result
    },
    cAttributes () {
      const result = this.$attrs

      // .$data needed since '$' - prefixed data can not be accessed the usual way
      for (const attrName of this.$data.$_mx_prop_redirection.blockedAttributes) {
        if (result.hasOwnProperty(attrName)) {
          delete result[attrName]
        }
      }

      return result
    }
  }
}
