<template>
  <v-expansion-panel @change="onCollapse">
    <v-expansion-panel-header ripple>
      <i18n
          path="player.update.talents.label"
          tag="strong"
      />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="text-body-1">
      <v-chip-group
          v-if="cHasTalents"
          v-model="selectedTalentId"
          active-class="primary--text"
          column
      >
        <v-chip
            v-for="talent in cTalents"
            :key="talent.id"
            dense
            label
            ripple
            :value="talent.id"
        >
          {{ talent.name }}
        </v-chip>
      </v-chip-group>
      <div
          v-else
          class="text-body-2"
      >
        <i18n
            path="player.update.talents.noTalents"
            tag="em"
        />
      </div>
      <v-alert
          v-if="cHasSelection && cSelection.description"
          transition="expand-transition"
          color="grey darken-3"
          tile
      >
        {{ cSelection.description }}
      </v-alert>
      <v-alert
          dismissible
          style="position: relative;"
          text
          tile
          transition="expand-transition"
          type="success"
          :value="cShowDeletedMessage"
          @input="onDismissDeletedMessage"
      >
        <div>{{ cDeletedMessage }}</div>
        <v-progress-linear
            style="position: absolute; top: 0; left: 0; width: 100%;"
            color="success"
            :value="deletedMessageProgress"
        />
      </v-alert>
      <v-expand-transition
          v-for="action in cActions"
          :key="action.key"
      >
        <v-btn
            block
            small
            text
            v-bind="action.btn"
            @click="action.onClick"
        >
          {{ action.label }}
        </v-btn>
      </v-expand-transition>
      <eins-player-data-talent-dialog
          v-model="talentDialog"
          :talent-id="selectedTalentId"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EinsPlayerDataTalentDialog from '@/components/player/edit/EinsPlayerDataTalentDialog'

const DELETED_MESSAGE_DURATION = 20000

export default {
  name: 'EinsPlayerDataTalentsBlock',
  components: { EinsPlayerDataTalentDialog },
  data: () => ({
    selectedTalentId: null,
    talentDialog: false,
    deleting: false,
    deletedTalent: false,
    deletedMessageProgress: 0
  }),
  computed: {
    cHasTalents () {
      return this.cTalents.length > 0
    },
    cTalents () {
      return this.$store.getters['players/current/talents']
    },
    cHasSelection () {
      return this.cSelection !== null
    },
    cSelection () {
      return this.cTalents.find(talent => this.selectedTalentId === talent.id) ?? null
    },
    cShowDeletedMessage () {
      return !!this.deletedTalent
    },
    cDeletedMessage () {
      if (this.deletedTalent) {
        return this.$t('player.update.talents.deletedMessage.text', this.deletedTalent)
      }

      return false
    },
    cActions () {
      const result = []

      if (this.cHasSelection) {
        result.push({
          key: 'edit',
          btn: {},
          label: this.$t('player.update.talents.actions.edit', this.cSelection),
          onClick: () => this.onClickEditTalent()
        })
        result.push({
          key: 'delete',
          btn: {
            loading: this.deleting
          },
          label: this.$t('player.update.talents.actions.delete', this.cSelection),
          onClick: () => this.onClickDeleteTalent()
        })
      }

      result.push({
        key: 'new',
        btn: { color: 'primary' },
        label: this.$t('player.update.talents.actions.new'),
        onClick: () => this.onClickAddTalent()
      })

      return result
    }
  },
  methods: {
    onCollapse () {
      this.selectedTalentId = null
      this.deletedTalent = false
    },
    onClickAddTalent () {
      this.selectedTalentId = null
      this.showTalentDialog()
    },
    onClickEditTalent () {
      this.showTalentDialog()
    },
    onClickDeleteTalent () {
      this.deleteSelectedTalent()
    },
    onDismissDeletedMessage () {
      this.dismissDeletedMessage()
    },
    dismissDeletedMessage () {
      this.deletedTalent = false
    },
    updatePlayerData () {
      return this.$store.dispatch('players/current/update')
    },
    deleteSelectedTalent () {
      const talentToDelete = JSON.parse(JSON.stringify(this.cSelection))

      this.deleting = true
      this.$http.$api.delete(`user-talents/${talentToDelete.id}`)
        .then(() => this.updatePlayerData())
        .then(() => {
          this.deletedMessageProgress = 0
          this.deletedTalent = talentToDelete
          const start = Date.now()
          const interval = setInterval(() => {
            if (this.deletedMessageProgress >= 100) {
              clearInterval(interval)
              this.dismissDeletedMessage()
            } else {
              const diff = Date.now() - start
              if (diff >= DELETED_MESSAGE_DURATION) {
                this.deletedMessageProgress = 100
              } else {
                this.deletedMessageProgress = Math.round(100 * diff / DELETED_MESSAGE_DURATION)
              }
            }
          }, DELETED_MESSAGE_DURATION / 100)
        })
        .finally(() => {
          this.selectedTalentId = null
          this.deleting = false
        })
    },
    showTalentDialog () {
      return new Promise(resolve => {
        this.talentDialog = true
        const unwatch = this.$watch('talentDialog', value => {
          if (!value) {
            unwatch()
            resolve()
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
