<template>
  <v-row
      class="flex-column flex-nowrap fill-height"
      style="overflow: hidden;"
      no-gutters
  >
    <v-form
        v-model="formValid"
    >
      <v-col
          cols="12"
          class="headline ma-1"
      >
        <v-icon>mdi-google-circles-group</v-icon>
        <span class="ml-2">{{ cText['captions.caption'] }}</span>
      </v-col>
      <v-col
          cols="12"
          class="pa-1 pb-0 ma-1 mb-0"
      >
        <v-text-field
            v-model="group.title"
            dense
            outlined
            :label="cText['fields.name.label']"
            :prepend-icon="group.title.length >= 4 && group.title.length < 31 ? 'mdi-google-circles-communities' : 'mdi-checkbox-blank-circle-outline'"
            :placeholder="cText['fields.name.placeholder']"
            :rules="[ rules.groupTitleValid ]"
            @input="emitInput"
        />
        <!-- Informations Group-Types -->
        <v-overlay
            :value="info > 0"
            absolute
            class="pa-2"
        >
          <div class="rounded green pa-3 text-center">
            <span class="block">
              <v-icon>mdi-information-outline</v-icon>
              {{ cGroupTypeInfo }}
            </span>
            <br>
            <v-btn @click="info = 0">OK</v-btn>
          </div>
        </v-overlay>
        <!-- select all Group-Types change [ 1 ] to [ 1,2,3,4 ]  -->
        <div
            v-for="type in [ 1 ]"
            :key="'grp_' + type"
        >
          <v-col
              cols="12"
              class="pt-1 text-center"
          >
            <v-btn
                :disabled="cDisabled"
                color="primary"
                style="width:86%"
                @click="setGroupType(type)"
            >{{ cText['buttons.groupType' + type + '.label'] }}</v-btn>
            <v-icon
                class="ml-2"
                @click="info = type"
            >
              mdi-information-outline
            </v-icon>
          </v-col>
        </div>
      </v-col>
    </v-form>
  </v-row>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import { eMailValid, groupTitleValid } from '@/utils/inputRules'

const getEmptyItem = () => ({
  title: '',
  business_email: '',
  type: null
})

export default {
  name: 'EinsGroupCreateFormStep1',
  mixins: [
    MxTranslatable
  ],
  props: {
    value: {
      type: Object,
      default: getEmptyItem
    },
    onSetGroupType: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    group: getEmptyItem(),
    fieldInfo: false,
    info: 0,
    formValid: false,
    rules: {
      eMailValid,
      groupTitleValid
    },
    $_mx_translatable: {
      paths: [
        'captions.caption',
        'fields.name.label',
        'fields.name.placeholder',
        'fields.eMail.label',
        'fields.eMail.placeholder',
        'fields.eMail.hint',
        'captions.selectGroupType',
        'buttons.groupType1.label',
        'buttons.groupType1.info'
      ]
    }
  }),
  computed: {
    cGroupTypeInfo () {
      return this.cText['buttons.groupType' + this.info + '.info']
    },
    cGroupTypeName () {
      return this.cText['buttons.groupType' + this.selectedGroupType + '.label']
    },
    cEMailValid () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(String(this.group.business_email).toLowerCase())
    },
    cEMailHint () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (pattern.test(String(this.group.business_email).toLowerCase())) {
        return ''
      } else {
        return this.cText['fields.eMail.hint']
      }
    },
    cDisabled () {
      return !(
        this.formValid
      )
    }
  },
  watch: {
    value: 'setValue'
  },
  created () {
    this.setValue()
  },
  methods: {
    setGroupType (type) {
      this.group.type = type
      this.emitInput()
    },
    setValue () {
      const emptyItem = getEmptyItem()
      this.$set(this.$data, 'group', {
        ...emptyItem,
        ...this.value
      })
    },
    emitInput () {
      this.$emit('input', {
        ...this.group
      })
      this.onSetGroupType(this.group.type)
    }
  }
}
</script>

<style scoped>

</style>
