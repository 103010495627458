<template>
  <v-item-group
      mandatory
      :value="internalValue"
      @change="onInput"
  >
    <template v-for="option in cOptions">
      <v-item
          #default="{ active, toggle }"
          :key="option.item.value"
          v-bind="option.item"
      >
        <div>
          <div
              v-if="option.caption"
              class="text-overline text-center mb-2 mt-4"
              :class="{ 'primary--text': internalValue !== null }"
          >
            {{ option.caption }}
          </div>
          <v-btn
              block
              :color="active ? 'primary' : null"
              :value="option.value"
              v-bind="option.btn"
              @click="toggle"
          >
            <v-row
                no-gutters
                style="max-width: 100%; width: 100%;"
                justify="center"
                align="center"
                class="flex-nowrap"
            >
              <div v-if="option.icon">
                <v-icon left>{{ option.icon }}</v-icon>
              </div>
              <div>
                {{ option.label }}
              </div>
            </v-row>
          </v-btn>
        </div>
      </v-item>
    </template>
  </v-item-group>
</template>

<script>
import {
  Membership
} from '@/constants'

export default {
  name: 'EinsInputGroupScope',
  props: {
    value: {
      required: true,
      validator: value => value === null || typeof value === 'string'
    },
    membershipState: {
      type: Array,
      default: () => []
    },
    listCaption: {
      type: String,
      default: null
    },
    voidOption: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    internalValue: null
  }),
  computed: {
    cAllowedMembershipStates () {
      const validStates = Object.values(Membership)

      return this.membershipState.filter(state => validStates.includes(state))
    },
    cFilterMembershipState () {
      return this.cAllowedMembershipStates.length > 0
    },
    cGroups () {
      const groups = this.$store.getters['players/current/groups/items']

      return this.cFilterMembershipState
        ? groups.filter(group => this.cAllowedMembershipStates.includes(group.membership_state_player))
        : groups
    },
    cListCaption () {
      return this.listCaption ? this.listCaption : this.$t('input.groupScope.default.listCaption')
    },
    cOptions () {
      const result = []

      for (const group of this.cGroups) {
        result.push({
          label: group.title,
          icon: group.group_type.icon,
          item: {
            value: group.id,
            class: 'group-list-item'
          },
          btn: {
            tile: true
          }
        })
      }

      if (result.length > 0) {
        result[0].caption = this.cListCaption
        result[0].btn.tile = false
        if (result.length > 1) {
          result[0].btn.style = {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }

          const lastIndex = result.length - 1
          result[lastIndex].btn.tile = false
          result[lastIndex].btn.style = {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }
        }
      }

      result.unshift({
        label: this.voidOption?.label ?? this.$t('input.groupScope.default.voidItemLabel'),
        icon: this.voidOption?.icon ?? null,
        item: {
          value: null
        },
        btn: {}
      })

      return result
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  created () {
    this.fetchPlayerGroups()
  },
  methods: {
    onInput (value) {
      this.internalValue = value
      this.emitInput()
    },
    onChangeValue (value) {
      if (this.cOptions.some(option => option.item.value === value)) {
        this.internalValue = value
      } else {
        this.internalValue = null
        this.emitInput()
      }
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    fetchPlayerGroups () {
      return this.$store.dispatch('players/current/groups/getData')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
.v-btn {
  min-height: 36px;
  height: auto !important;
  $padding: calc((36px - 20px) / 2);
  padding-top: $padding !important;
  padding-bottom: $padding !important;
  ::v-deep .v-btn__content {
    max-width: 100%;
    word-break: break-all;
    white-space: normal;
  }
}

.group-list-item + .group-list-item {
  padding-top: 1px;
}
</style>
