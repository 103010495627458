<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-plus-box"
      color="purple"
      @click="onClick"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuAbAddContent',
  components: { EinsStarmenuAb },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cShowBtn () {
      return this.cBelongsToRoute && this.$store.getters['players/current/groups']
        .includes(this.cGroup?.id)
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogAddContent')
    }
  }
}
</script>

<style scoped>

</style>
