<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-message-text"
      :text="cText.text"
  />
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbGroupConversation',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cPlayerIsMember () {
      return (this.$store.getters['players/current/groups'] ?? []).includes(this.$store.getters['groups/selected/group']?.id)
    },
    cShowBtn () {
      return this.cBelongsToRoute && this.cPlayerIsMember
    },
    cTarget () {
      const id = this.$store.getters['groups/selected/group']?.chat_conversation_id
      return this.cShowBtn ? `/conversations/${id}` : ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
