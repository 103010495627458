<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column fill-height"
        no-gutters
    >
      <v-col class="pa-0 flex-grow-0 flex-shrink-1 text-center text--primary">
        <div class="text-h6 group pa-2">
          <v-icon
              color="white"
              style="font-size: 1.25em;"
          >{{ cGroupType.icon }}</v-icon>
          <span class="ml-2">{{ cGroup.title }}</span>
        </div>
        <div class="text-subtitle-2">
          ID: {{ cGroup.id }}
        </div>
        <div class="text--primary text-h6 mb-0 mt-4">
          <v-icon
              color="green"
              style="font-size: 1.25em;"
          >{{ cCaption.icon }}</v-icon>
          <strong class="text--primary ml-2">{{ cCaption.title }}</strong>
        </div>
        <v-alert
            text
            tile
            type="success"
            transition="expand-transition"
            :value="success"
        >
          <div class="text-h6">
            {{ cText['success.title'] }}
          </div>
          <div class="text-subtitle-1">
            {{ cText['success.text'] }}
          </div>
        </v-alert>
        <v-alert
            text
            tile
            dense
            type="error"
            transition="expand-transition"
            :value="error"
        >
          {{ cErrorMessage }}
        </v-alert>
      </v-col>
      <v-col
          v-if="!success"
          class="pa-3 flex-grow-1 flex-shrink-0 overflow-y-auto"
          style="border-top:1px solid var(--v-group-base)"
      >
        <div
            class="text-subtitle-2 mb-0 mt-4 text-center"
            v-html="cInfoText"
        ></div>
        <v-col
            cols="11"
            class="title  pl-2 ma-1 mt-5"
        >
          <v-text-field
              v-model="group.business_email"
              dense
              outlined
              :label="cText['fields.eMail.label']"
              append-outer-icon="mdi-information-outline"
              :hint="cError"
              :rules="[ rules.eMailValid ]"
              :placeholder="cText['fields.eMail.placeholder']"
              :prepend-icon="cEMailValid ? 'mdi-at' : 'mdi-checkbox-blank-circle-outline'"
              @click:append-outer="() => (fieldInfo = !fieldInfo)"
          />
        </v-col>
        <!-- Info E-Mail Field -->
        <v-overlay
            :value="fieldInfo"
            absolute
            class="pa-2"
        >
          <div class="rounded green pa-3 text-center">
            <span class="block">
              <v-icon>mdi-information-outline</v-icon>
              {{ cText['fields.eMail.hint'] }}
            </span>
            <br>
            <v-btn @click="fieldInfo = false">OK</v-btn>
          </div>
        </v-overlay>
        <v-col
            cols="11"
            class="title  pl-1 ma-1 mt-1"
        >
          <v-text-field
              v-model="group.contact"
              dense
              outlined
              :label="cText['fields.contact.label']"
              append-outer-icon="mdi-information-outline"
              :hint="cError"
              :placeholder="cText['fields.contact.placeholder']"
              :prepend-icon="cContactValid ? 'mdi-account-tie' : 'mdi-checkbox-blank-circle-outline'"
              @click:append-outer="() => (fieldContactInfo = !fieldContactInfo)"
          />
        </v-col>
        <!-- Info Contact Field -->
        <v-overlay
            :value="fieldContactInfo"
            absolute
            class="pa-2"
        >
          <div class="rounded green pa-3 text-center">
            <span class="block">
              <v-icon>mdi-information-outline</v-icon>
              {{ cText['fields.contact.hint'] }}
            </span>
            <br>
            <v-btn @click="fieldContactInfo = false">OK</v-btn>
          </div>
        </v-overlay>
        <v-btn
            color="primary"
            outlined
            class="col-12"
            :disabled="!cEMailValid || !cContactValid"
            :loading="loading"
            @click="onClickSubmit"
        >
          <v-icon>mdi-check-decagram-outline</v-icon>
          <span class="ml-2">{{ cText['button.submit'] }}</span>
        </v-btn>
        <!--
        <v-btn
            color="grey"
            outlined
            class="col-12 mt-2"
        >
          <v-icon>mdi-information-outline</v-icon>
          <span class="ml-2">{{ cText['button.info'] }}</span>
        </v-btn>
        -->
      </v-col>
      <v-col
          v-else
          class="pa-3"
      >
        <v-btn
            color="grey"
            outlined
            class="col-12 mt-2"
            @click="onClickBack"
        >
          <v-icon>mdi-arrow-left</v-icon>
          <span class="ml-2">{{ cText['button.back'] }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import { eMailValid } from '@/utils/inputRules'
const getEmptyItem = () => ({
  title: '',
  business_email: '',
  contact: ''
})

export default {
  name: 'ViewGroupVerify',
  components: {
    EinsLayoutViewDetail
  },
  mixins: [MxTranslatable],
  data: () => ({
    group: getEmptyItem(),
    success: false,
    error: false,
    loading: false,
    rules: {
      eMailValid
    },
    fieldInfo: false,
    fieldContactInfo: false,
    $_mx_translatable: {
      paths: [
        'captions.caption',
        'info.title',
        'info.text',
        'fields.eMail.hint',
        'fields.eMail.placeholder',
        'fields.eMail.label',
        'fields.contact.hint',
        'fields.contact.placeholder',
        'fields.contact.label',
        'button.submit',
        'button.info',
        'success.title',
        'success.text',
        'button.back'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cLoading () {
      return this.$store.getters['groups/selected/loading']
    },
    cError () {
      return this.$store.getters['groups/selected/error']
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cGroupId () {
      return this.cGroup?.id
    },
    cCaption () {
      return { icon: 'mdi-check-decagram-outline', title: this.cText['captions.caption'] }
    },
    cInfoText () {
      return this.cText['info.text']
    },
    cGroupType () {
      return this.cGroup?.group_type
    },
    cGroupTypeTitle () {
      return this.cGroupType?.license_name
    },
    cEMailValid () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(String(this.group?.business_email).toLowerCase())
    },
    cEMailHint () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (pattern.test(String(this.group?.business_email).toLowerCase())) {
        return ''
      } else {
        return this.cText['fields.eMail.hint']
      }
    },
    cContactValid () {
      return this.group.contact && this.group.contact.length >= 4 && this.group.contact.length < 31
    },
    cContactHint () {
      if ((String(this.group?.contact ?? '').toLowerCase())) {
        return ''
      } else {
        return this.cText['fields.contact.hint']
      }
    },
    cErrorMessage () {
      // const message = this.error?.message
      const text = this.error?.errors?.business_email?.[0]?.message ?? ''
      return text
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadGroup(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadGroup(to.params.id)
    }
    next()
  },
  created () {
    this.group = this.cGroup
  },
  methods: {
    loadGroup (id) {
      return this.$store.dispatch('groups/selected/load', id)
    },
    onClickBack () {
      this.$router.push(`/groups/${this.cGroupId}/edit`)
    },
    onClickSubmit () {
      this.submit()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(`groups/${this.cGroupId}`, {
        'business_email': this.group.business_email,
        'contact_information': this.group.contact
      })
        .then(() => {
          this.success = true
        })
        .catch(error => {
          this.error = error.response?.data || true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
