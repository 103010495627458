<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-logout-variant</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbLeaveGroup',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cCurrentUserId () {
      return this.$store.getters['players/current/id']
    },
    cEntity () {
      return this.$store.getters['groups/selected/group']
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
          this.cUserIsMember
    },
    cUserIsOwner () {
      return this.cEntity?.owner_id === this.cCurrentUserId
    },
    cUserIsMember () {
      const groups = this.$store.getters['players/current/groups'] ?? []

      return groups.includes(this.cEntity?.id)
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogLeaveGroup')
    }
  }
}
</script>

<style scoped>
</style>
