export const colorEinsPrimary = '#FF5D5B'

export const colorEinsDeals = '#E6E600'
export const colorEinsDealsOff = '#A5A500'

export const colorEinsQuests = '#3333ff'
export const colorEinsQuestsOff = '#003366'

export const colorEinsPlayers = '#fb61fb'
export const colorEinsPlayersOff = '#ae17b0'

export const colorEinsGroups = '#cc3333'
export const colorEinsGroupsOff = '#b23219'

export const colorEinsBackground = '#000000'
