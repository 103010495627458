<template>
  <eins-group-list-item
      v-if="application.applicant_group"
      :group="application.applicant_group"
      :to="false"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template #indicator3>
      <v-icon color="primary">{{ cIcon }}</v-icon>
    </template>
    <template #content>
      <eins-application-list-item-content
          :application="application"
          :disable-accept-action="disableAcceptAction"
          :hide-accept-action="hideAcceptAction"
          @load:accept="$emit('load:accept', $event)"
      />
    </template>
  </eins-group-list-item>
  <eins-player-list-item
      v-else
      :player="application.applicant"
      :to="false"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template #indicator3>
      <v-icon color="primary">{{ cIcon }}</v-icon>
    </template>
    <template #content>
      <eins-application-list-item-content
          :application="application"
          :disable-accept-action="disableAcceptAction"
          :hide-accept-action="hideAcceptAction"
          :show-reject-action="showRejectAction"
          @load:accept="$emit('load:accept', false)"
      />
    </template>
  </eins-player-list-item>
</template>

<script>
import EinsPlayerListItem from '@/components/player/EinsPlayerListItem'
import EinsGroupListItem from '@/components/group/EinsGroupListItem'
import EinsApplicationListItemContent from '@/components/application/EinsApplicationListItemContent'

import {
  ApplicationState
} from '@/constants'

export default {
  name: 'EinsApplicationListItem',
  components: {
    EinsApplicationListItemContent,
    EinsGroupListItem,
    EinsPlayerListItem
  },
  props: {
    application: {
      type: Object,
      default: () => ({}),
      required: true
    },
    disableAcceptAction: {
      type: Boolean,
      default: false
    },
    hideAcceptAction: {
      type: Boolean,
      default: false
    },
    showRejectAction: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  }),
  computed: {
    cIsAccepted () {
      return this.application?.state === ApplicationState.ACCEPTED ||
          this.application?.state === ApplicationState.CONFIRMED
    },
    cIcon () {
      switch (this.application?.state) {
        case ApplicationState.REJECTED:
          return 'mdi-close-box-outline'
        case ApplicationState.ACCEPTED:
        case ApplicationState.CONFIRMED:
          return 'mdi-lock-open-outline'
        case ApplicationState.UNHANDLED:
        default:
          return 'mdi-human-greeting'
      }
    }
  }
}
</script>
