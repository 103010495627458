<template>
  <eins-edit-description-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      api-param-name="description"
      :current-value="cGroupDescription"
      :title="cText.title"
      :dialog-title="cText.dialogTitle"
      :input-label="cText.inputLabel"
      :submit-label="cText.submitLabel"
      :rule-message="cRuleMessages"
      :success-message="cSuccessMessage"
  />
</template>

<script>
import EinsEditDescriptionBlock from '@/components/form/edit/EinsEditDescriptionBlock'

export default {
  name: 'EinsGroupDataDescriptionBlock',
  components: {
    EinsEditDescriptionBlock
  },
  data: () => ({
  }),
  computed: {
    cApiPath () {
      return `groups/${this.cGroupId}`
    },
    cGroupId () {
      return this.$store.getters['groups/selected/group']?.id
    },
    cGroupDescription () {
      return this.$store.getters['groups/selected/group']?.description ?? ''
    },
    cText () {
      return {
        title: this.$t('group.update.description.description.label'),
        dialogTitle: this.$t('group.update.description.dialogTitle'),
        inputLabel: this.$t('group.update.description.description.label'),
        submitLabel: this.$t('group.update.description.actions.submit')
      }
    },
    cSuccessMessage () {
      return {
        title: this.$t('group.update.description.success.title'),
        text: this.$t('group.update.description.success.text')
      }
    },
    cRuleMessages () {
      return {
        max: this.$t('group.update.description.description.max')
      }
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('groups/selected/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
