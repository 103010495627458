<template>
  <div>
    <slot>
      <div v-if="max">
        <i18n path="input.timeValue.maxValue">
          <strong>{{ cInputMaxValueFormatted }}</strong>
        </i18n>
      </div>
    </slot>
    <slot name="balance">
      <v-row
          v-if="capWithUserBalance"
          class="grey--text"
      >
        <v-col><i18n path="input.timeValue.availableBalance" /></v-col>
        <v-col
            class="flex-grow-0 text-no-wrap"
            :class="!cUserAvailableBalanceExceeded || 'error--text'"
        >
          <strong>{{ cUserAvailableBalanceFormatted }}</strong>
        </v-col>
      </v-row>
    </slot>
    <v-row>
      <v-col>
        {{ label }}
      </v-col>
      <v-col class="col-grow-0">
        <v-text-field
            v-model="internalValue.input"
            :autofocus="autofocus"
            type="number"
            dense
            flat
            hide-details
            :append-outer-icon="cIcon"
            reverse
            solo
            @input="onInput"
        />
      </v-col>
    </v-row>
    <v-slider
        v-model="internalValue.input"
        :min="cInputMinValue"
        :max="cSliderMax"
        :rules="cRules"
        @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: 'EinsInputTimeValue',
  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    sliderMax: {
      type: Number,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    starMode: {
      type: Boolean,
      default: false
    },
    capWithUserBalance: {
      type: Boolean,
      default: false
    },
    userBalanceUnlock: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: null
    }
  },
  data: () => ({
    internalValue: {
      input: 0,
      real: 0
    }
  }),
  computed: {
    cUserAvailableBalance () {
      let balanceAvailable = this.$store.getters['players/current/timeAccount'].balance_available
      balanceAvailable = balanceAvailable + this.cUserBalanceUnlock

      return balanceAvailable
    },
    cUserAvailableBalanceInputValue () {
      return this.starMode
        ? Math.floor(this.cUserAvailableBalance / 60)
        : this.cUserAvailableBalance
    },
    cUserAvailableBalanceFormatted () {
      return this.$tc(
        `timeValue.${this.starMode ? 'stars' : 'points'}Value`,
        this.cUserAvailableBalanceInputValue
      )
    },
    cUserLockedBalance () {
      return this.$store.getters['players/current/timeAccount'].balance_locked
    },
    cUserBalanceUnlock () {
      return Math.min(this.cUserLockedBalance, this.userBalanceUnlock)
    },
    cUserAvailableBalanceExceeded () {
      return this.cUserAvailableBalance < this.internalValue.real
    },
    cInputMaxValue () {
      return this.starMode ? Math.round(this.max / 60) : this.max
    },
    cInputMinValue () {
      let min = this.min ?? 0
      min = this.starMode ? Math.round(this.min / 60) : this.min
      return Math.min(min, this.cInputMaxValue)
    },
    cInputMaxValueFormatted () {
      return this.$tc(
        `timeValue.${this.starMode ? 'stars' : 'points'}Value`,
        this.cInputMaxValue
      )
    },
    cInputMinValueFormatted () {
      return this.$tc(
        `timeValue.${this.starMode ? 'stars' : 'points'}Value`,
        this.cInputMinValue
      )
    },
    cSliderMax () {
      const max = this.max
        ? this.max
        : (this.sliderMax ?? 120)

      return this.starMode ? Math.round(max / 60) : max
    },
    cRules () {
      const result = []

      if (this.capWithUserBalance) {
        result.push(
          value => !value ||
            value <= this.cUserAvailableBalanceInputValue ||
            this.$t('input.timeValue.rules.balance', [this.cUserAvailableBalanceFormatted])
        )
      }

      if (this.max) {
        result.push(
          value => !value ||
            value <= this.cInputMaxValue ||
            this.$t('input.timeValue.rules.max', [this.cInputMaxValueFormatted])
        )
      }
      if (this.min) {
        result.push(
          value => isNaN(value) ||
            value >= this.cInputMinValue ||
            this.$t('input.timeValue.rules.min', [this.cInputMinValueFormatted])
        )
      }

      return result
    },
    cIcon () {
      return this.starMode ? 'mdi-star' : 'mdi-brightness-1'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.applyValue(value)
    },
    onInput (value) {
      this.applyInputValue(value)
        .then(() => this.emitInput())
    },
    applyValue (value) {
      this.internalValue.real = this.filterMinMaxRealValue(value)
      this.recalculateInputInternalValue()
    },
    applyInputValue (value) {
      return this.$nextTick()
        .then(() => {
          this.internalValue.input = this.filterMinMaxInputValue(value)
          this.recalculateRealInternalValue()
        })
    },
    filterMinMaxRealValue (value) {
      const max = this.max ? this.max : Infinity
      const min = this.min ? this.min : 0
      return Math.min(Math.max(value, min), max)
    },
    filterMinMaxInputValue (value) {
      const max = this.max
        ? this.cInputMaxValue
        : Infinity
      const min = this.max
        ? this.cInputMinValue
        : Infinity
      return Math.min(Math.max(value, min), max)
    },
    recalculateInputInternalValue () {
      if (this.starMode) {
        this.internalValue.input = Math.round(this.internalValue.real / 60)
        if (this.internalValue.input * 60 !== this.internalValue.real) {
          this.emitInput()
        }
      } else {
        this.internalValue.input = this.internalValue.real
      }
    },
    recalculateRealInternalValue () {
      this.internalValue.real = this.starMode
        ? this.internalValue.input * 60
        : this.internalValue.input
    },
    emitInput () {
      this.$emit('input', this.internalValue.real)
    }
  }
}
</script>

<style scoped>

</style>
