<template>
  <eins-timeline-list
      :quests="cQuests"
  />
</template>

<script>
import EinsTimelineList from '@/components/timeline/EinsTimelineList'
import { calculateQuestScore } from '@/utils/questScoring.js'

export default {
  name: 'ViewTimeline',
  components: {
    EinsTimelineList
  },
  data () {
    return {
      textFilter: ''
    }
  },
  computed: {
    cClosedPlayerQuest () {
      return this.$store.getters[`questsTimeline/list`] ?? []
    },

    cQuests () {
      const textFilterLc = this.textFilter.toLowerCase()
      let cQuests = this.cClosedPlayerQuest // .concat(this.cClosedGroupQuest)
      const idScoreMap = new Map()

      for (const quest of cQuests) {
        const score = calculateQuestScore(quest) // calc score
        idScoreMap.set(quest.id, score) // remember score <-> quest.id relation
      }

      // Filter and sort the quest
      cQuests = cQuests.filter(el => {
        return this.textFilter?.length === 0 || el.title.toLowerCase().includes(textFilterLc) // not used yet
      })
        .sort((q1, q2) => idScoreMap.get(q2.id) - idScoreMap.get(q1.id)) // compare the remembered score of both quests

      return cQuests
    },
    cLoading () {
      return this.$store.getters['players/selected/loading'] ||
          this.$store.getters[`players/selected/questsFinished/listLoading`]
    },
    cError () {
      return this.$store.getters['players/selected/error']
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    },
    cPlayerIsAdmin () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []
      return adminGroups.includes(this.cPlayer.id)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.clearCache()
      vm.loadQuestList()
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.clearCache()
      this.loadQuestList()
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
    },
    clearCache () {
      // Pointless for now
      return Promise.resolve()
    },
    loadQuestList () {
      return this.$store.dispatch(`questsTimeline/getData`)
    }
  }
}
</script>

<style scoped>

</style>
