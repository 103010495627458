<script>
import {
  HttpStatus
} from '@/constants'

export default {
  name: 'WidgetChangeGroupOwnerProvider',
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: {
      group: false,
      admins: false,
      submit: false
    },
    group: null,
    admins: null,
    selection: null,
    success: false,
    error: false
  }),
  computed: {
    cDisableSelection () {
      return this.success || this.loading.submit
    },
    cDisableSubmit () {
      return this.cSelectedPlayer === null || this.success || this.loading.submit
    },
    cLoading () {
      return this.loading.group || this.loading.admins
    },
    cErrorText () {
      let errorType = 'unknown'

      if (this.error.response) {
        switch (this.error.response.status) {
          case HttpStatus.FORBIDDEN:
            errorType = 'forbidden'
            break
        }
      }

      return this.$t(`group.changeOwnership.error.${errorType}`)
    },
    cGroupTitle () {
      return this.group?.title ?? ''
    },
    cAvailableAdmins () {
      return this.admins
        ? this.admins.filter(admin => admin.id !== this.$store.getters['players/current/id'])
        : []
    },
    cSelectedPlayer () {
      return this.admins && this.selection
        ? this.admins.find(admin => admin.id === this.selection)
        : null
    },
    cSelectedPlayerName () {
      return this.cSelectedPlayer ? this.cSelectedPlayer.name : ''
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler: 'onChangeGroupId'
    }
  },
  methods: {
    onChangeSelection (value) {
      this.selection = value
    },
    onClickSubmit () {
      return this.submit()
    },
    onChangeGroupId (value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData()
      }
    },
    submit () {
      this.loading.submit = true
      this.error = false
      return this.$http.$api.patch(`/groups/${this.groupId}`, { owner_id: this.selection })
        .then(() => {
          this.success = true
        })
        .catch(err => {
          this.error = err
        })
        .finally(() => {
          this.loading.submit = false
        })
    },
    fetchData () {
      return Promise.all([
        this.fetchGroup(),
        this.fetchAdmins()
      ])
    },
    fetchGroup () {
      this.loading.group = true
      return this.$http.$api.get(`/groups/${this.groupId}`)
        .then(response => {
          this.group = response.data
        })
        .finally(() => {
          this.loading.group = false
        })
    },
    fetchAdmins () {
      this.loading.admins = true
      const query = new URLSearchParams()
      query.set('group_id', this.groupId)
      query.set('player_role', 'admin')
      query.set('no_distance', '1')
      return this.$http.$api.get(`/players?${query}`)
        .then(response => {
          this.admins = response.data.players
        })
        .finally(() => {
          this.loading.admins = false
        })
    }
  },
  render () {
    return this.$scopedSlots.default({
      availableAdmins: this.cAvailableAdmins,
      disableSelection: this.cDisableSelection,
      disableSubmit: this.cDisableSubmit,
      errorText: this.cErrorText,
      error: this.error,
      groupTitle: this.cGroupTitle,
      group: this.group,
      loading: this.loading,
      onChangeSelection: this.onChangeSelection,
      onClickSubmit: this.onClickSubmit,
      selectedPlayerName: this.cSelectedPlayerName,
      selectedPlayer: this.cSelectedPlayer,
      selection: this.selection,
      success: this.success
    })
  }
}
</script>

<style scoped>

</style>
