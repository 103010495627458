<template>
  <eins-edit-description-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      :current-value="cUserDescription"
      :dialog-title="cText.dialogTitle"
      :input-label="cText.inputLabel"
      :submit-label="cText.submitLabel"
      :rule-message="cRuleMessages"
      :success-message="cSuccessMessage"
  />
</template>

<script>
import EinsEditDescriptionBlock from '@/components/form/edit/EinsEditDescriptionBlock'

export default {
  name: 'EinsPlayerDataDescriptionBlock',
  components: {
    EinsEditDescriptionBlock
  },
  data: () => ({
  }),
  computed: {
    cApiPath () {
      return `players/${this.cUserId}`
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cUserDescription () {
      return this.$store.getters['players/current/about']
    },
    cText () {
      return {
        dialogTitle: this.$t('player.update.description.dialogTitle'),
        inputLabel: this.$t('player.update.description.description.label'),
        submitLabel: this.$t('player.update.description.actions.submit')
      }
    },
    cSuccessMessage () {
      return {
        title: this.$t('player.update.description.success.title'),
        text: this.$t('player.update.description.success.text')
      }
    },
    cDescriptionRules () {
      return [
        value => value.trim().length < 500 || this.$t('player.update.description.description.max')
      ]
    },
    cRuleMessages () {
      return {
        max: this.$t('player.update.description.description.max')
      }
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('players/current/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
