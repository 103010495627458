import Vue from 'vue'

import { VueHttp } from '@/Http'
import { HttpHeader } from '@/constants'

const currentUrl = new URL(window.location)
const url = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : `${currentUrl.protocol}//${currentUrl.hostname}:8000/api/`

export const http = new VueHttp({
  instances: {
    api: {
      defaults: {
        baseURL: url,
        headers: {
          common: {
            [HttpHeader.AUTHORISATION]: null
          }
        }
      }
    }
  }
})

http.$api.axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(new Error('Network error!'))
    } else {
      if (
        error.config.method === 'delete' &&
        error.response.status === 410
      ) {
        return Promise.resolve(error.response)
      } else {
        return Promise.reject(error)
      }
    }
  }
)

Vue.use(VueHttp)
