<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-camera"
      @click="onClick"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'
import {
  QuestState
} from '@/constants'

export default {
  name: 'EinsStarmenuAbFinishQuest',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    },
    cShowBtn () {
      const currentPlayerId = this.$store.getters['players/current/id']

      return this.cBelongsToRoute &&
        this.cQuest.assignee === currentPlayerId &&
        this.cQuest.state === QuestState.STARTED
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogFinishQuest', {
        quest: this.$store.getters['quests/selected/quest']
      })
        .then(() => this.$store.dispatch('quests/selected/update'))
    }
  }
}
</script>

<style scoped>
</style>
