<template>
  <v-expansion-panel>
    <v-expansion-panel-header ripple>
      <i18n
          path="player.update.email.label"
          tag="strong"
      />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="text-body-1">
      <v-row class="text-body-2">
        <v-col><i18n path="player.update.email.currentEmail.label" /></v-col>
        <v-col class="flex-grow-0">
          {{ cUserEmail }}
        </v-col>
      </v-row>
      <div
          v-if="!cUserHasVerifiedEmail"
          class="text-caption"
      >
        <em><i18n path="player.update.email.currentEmail.unverifiedHint" /></em>
      </div>
      <template v-if="cUserHasNewEmail">
        <v-row class="text-body-2 mt-2">
          <v-col><i18n path="player.update.email.newEmail.label" /></v-col>
          <v-col class="flex-grow-0">
            {{ cUserNewEmail }}
          </v-col>
        </v-row>
        <em class="text-caption">
          <i18n path="player.update.email.newEmail.verificationNeededHint" />
        </em>
      </template>
      <v-alert
          type="success"
          text
          tile
          transition="expand-transition"
          :value="verificationMail.timeout"
      >
        <div class="font-weight-bold">
          <i18n path="player.update.email.verificationLinkSend.title" />
        </div>
        <i18n path="player.update.email.verificationLinkSend.text" />
        <v-progress-linear
            style="position: absolute; top: 0; left: 0; width: 100%;"
            color="success"
            :value="verificationMail.timeoutProgress"
        />
      </v-alert>
      <v-expand-transition
          v-for="button in cButtons"
          :key="button.key"
      >
        <v-btn
            text
            small
            block
            v-bind="button.btn"
            @click="button.onClick"
        >{{ button.label }}</v-btn>
      </v-expand-transition>
      <eins-player-email-change-dialog v-model="emailDialog" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EinsPlayerEmailChangeDialog from '@/components/player/edit/EinsPlayerEmailChangeDialog'

const VERIFICATION_MAIL_TIMEOUT = 10000

export default {
  name: 'EinsPlayerDataEmailBlock',
  components: { EinsPlayerEmailChangeDialog },
  data: () => ({
    collapsed: true,
    emailDialog: false,
    verificationMail: {
      loading: false,
      timeout: false,
      timeoutProgress: 0
    },
    update: {
      loading: false
    }
  }),
  computed: {
    cUserEmail () {
      return this.$store.getters['players/current/email']
    },
    cUserHasVerifiedEmail () {
      return this.$store.getters['players/current/hasVerifiedEmail']
    },
    cUserNewEmail () {
      return this.$store.getters['players/current/newEmail']
    },
    cUserHasNewEmail () {
      return !!this.cUserNewEmail
    },
    cButtons () {
      const result = []

      if (!this.cUserHasVerifiedEmail || this.cUserNewEmail) {
        result.push({
          key: 'verify',
          label: this.$t('player.update.email.buttons.resendVerification'),
          onClick: () => this.onClickResendVerificationMail(),
          btn: {
            loading: this.verificationMail.loading,
            disabled: this.verificationMail.timeout
          }
        })
        result.push({
          key: 'check',
          label: this.$t('player.update.email.buttons.checkStatus'),
          onClick: () => this.onClickCheckVerificationStatus(),
          btn: {
            loading: this.update.loading
          }
        })
      }

      result.push({
        key: 'change',
        label: this.$t('player.update.email.buttons.change'),
        onClick: () => this.onClickChangeEmail(),
        btn: {
          color: 'primary',
          disabled: this.update.loading
        }
      })

      return result
    }
  },
  methods: {
    onClickCheckVerificationStatus () {
      this.updateUserData()
    },
    onClickChangeEmail () {
      this.showEmailChangeDialog()
    },
    onClickResendVerificationMail () {
      this.resendVerificationEmail()
    },
    onClickToggleCollapsed () {
      this.toggleCollapsed()
    },
    showEmailChangeDialog () {
      return new Promise(resolve => {
        this.emailDialog = true
        const unwatch = this.$watch('emailDialog', value => {
          if (!value) {
            unwatch()
            resolve()
          }
        })
      })
    },
    updateUserData () {
      this.update.loading = true
      this.$store.dispatch('players/current/update')
        .finally(() => {
          this.update.loading = false
        })
    },
    resendVerificationEmail () {
      this.verificationMail.loading = true
      this.$http.$api.post('email-verification/players', {
        email: this.cUserHasNewEmail ? this.cUserNewEmail : this.cUserEmail,
        verify_new_email: this.cUserHasNewEmail
      })
        .then(() => {
          this.verificationMail.timeout = true
          this.verificationMail.timeoutProgress = 0
          const start = Date.now()
          const interval = setInterval(() => {
            if (this.verificationMail.timeoutProgress >= 100) {
              this.verificationMail.timeout = false
              clearInterval(interval)
            } else {
              const diff = Date.now() - start
              if (diff >= VERIFICATION_MAIL_TIMEOUT) {
                this.verificationMail.timeoutProgress = 100
              } else {
                this.verificationMail.timeoutProgress = Math.round(100 * (diff / VERIFICATION_MAIL_TIMEOUT))
              }
            }
          }, VERIFICATION_MAIL_TIMEOUT / 100)
        })
        .finally(() => {
          this.verificationMail.loading = false
        })
    },
    toggleCollapsed () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
