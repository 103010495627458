<template>
  <div class="text-center">
    <h2>Testseite</h2>
  </div>
</template>

<script>
export default {
  name: 'ViewTest',
  components: { },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

</style>
