export default {
  filter: {
    scope: {
      contacts: {
        title: 'Nur meine Kontakts'
      },
      groups: {
        title: 'Nur meine @.lower:(terms.group)'
      }
    }
  }
}
