<template>
  <eins-layout-view-detail
      class="my-profile-page"
      style="background-color:transparent !important;height:100%"
  >
    <template v-if="!cError && cPlayer && cDataView">
      <eins-player-detail-basic-data
          :player="cPlayer"
      />
    </template>
    <template v-else-if="!cError && cPlayer && !cDataView">
      <eins-player-detail-content
          :player="cPlayer"
      />
    </template>
    <eins-error-not-found
        v-else-if="cError === 404"
    >
      <i18n path="views.ViewPlayerDetail.thePlayer" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>
      </template>
    </eins-error-not-found>
    <eins-error-forbidden
        v-else-if="cError === 403"
    >
      <i18n path="views.ViewPlayerDetail.thePlayerLc" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>!
      </template>
    </eins-error-forbidden>
  </eins-layout-view-detail>
</template>

<script>
import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsPlayerDetailBasicData from '@/components/player/EinsPlayerDetailBasicData'
import EinsPlayerDetailContent from '@/components/player/EinsPlayerDetailContent'

export default {
  name: 'ViewPlayerDetail',
  components: {
    EinsPlayerDetailContent,
    EinsErrorForbidden,
    EinsErrorNotFound,
    EinsPlayerDetailBasicData,
    EinsLayoutViewDetail
  },
  computed: {
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      return this.$store.getters['players/selected/loading']
    },
    cError () {
      return this.$store.getters['players/selected/error']
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    },
    cOwnProfile () {
      return this.cPlayer && this.$store.getters['players/current/id'] === this.cPlayer.id
    },
    cDataView () {
      return this.$route.hash === '#details'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadPlayer(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadPlayer(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadPlayer(this.$route.params.id)
    },
    loadPlayer (id) {
      return this.$store.dispatch('players/selected/load', id)
    }
  }
}
</script>

<style scoped>
</style>
