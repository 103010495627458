<template>
  <eins-edit-location-block
      :api-url="cApiUrl"
      :after-update="updateGroup"
      :aspect-ratio="aspectRatio"
      :dialog-title-address="cDialogTitle.address"
      :submit-label-address="cSubmitLabel.address"
      :success-message-address="cSuccessMessage.address"
      :success-message-position="cSuccessMessage.position"
      :entity="cGroup"
      :height="height"
  />
</template>

<script>
import EinsEditLocationBlock from '@/components/form/edit/EinsEditLocationBlock'

export default {
  name: 'EinsGroupDataLocationBlock',
  components: {
    EinsEditLocationBlock
  },
  props: {
    aspectRatio: {
      type: String,
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
  }),
  computed: {
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cApiUrl () {
      return `groups/${this.cGroupId}`
    },
    cGroupId () {
      return this.cGroup?.id
    },
    cDialogTitle () {
      return {
        address: this.$t('group.update.location.address.dialogTitle')
      }
    },
    cSubmitLabel () {
      return {
        address: this.$t('group.update.location.address.actions.submit')
      }
    },
    cSuccessMessage () {
      return {
        address: {
          title: this.$t('group.update.location.address.success.title'),
          text: this.$t('group.update.location.address.success.text')
        },
        position: {
          title: this.$t('group.update.location.position.success.title'),
          text: this.$t('group.update.location.position.success.text')
        }
      }
    }
  },
  methods: {
    updateGroup () {
      return this.$store.dispatch('groups/selected/update')
    }
  }
}
</script>

<style scoped>

</style>
