<template>
  <v-card>
    <v-card-title
        class="text-center text-uppercase d-block"
        :class="error === false ? 'success--text' : 'error--text'"
    >
      <template v-if="error === false">
        <template v-if="loading">
          {{ cText['captions.loading'] }}
        </template>
        <template v-else-if="verified">
          {{ cText['captions.verified'] }}
        </template>
      </template>
      <template v-else>
        {{ cText['captions.error'] }}
      </template>
    </v-card-title>
    <v-card-text v-if="cMode === modes.MODE_VERIFY">
      <v-row
          align="center"
          justify="center"
          align-content="center"
          class="flex-column flex-nowrap"
      >
        <v-col
            class="flex-grow-0"
            style="width: auto; position: relative;"
        >
          <v-progress-circular
              :color="error === false ? 'success  lighten-3' : 'error'"
              :indeterminate="loading"
              :value="loading ? 0 : 100"
              size="64"
              width="1"
          />
          <template v-if="!loading">
            <v-icon
                v-if="error === false"
                color="success"
                style="position: absolute; left: 50%; top: 50%; transform: translate(-52%, -48%);"
                size="64"
            >
              mdi-check
            </v-icon>
            <v-icon
                v-else
                color="error"
                style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"
                size="64"
            >
              mdi-close
            </v-icon>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <template v-else>
      <v-card-title>{{ cText['captions.resend'] }}</v-card-title>
      <v-alert
          v-if="requested"
          text
          tile
          type="success"
          class="ma-0"
      >
        {{ cText['captions.again1'] }} '<strong>{{ form.data.email }}</strong>'{{ cText['captions.again2'] }}
      </v-alert>
      <v-card-text v-else>
        <v-form
            ref="form"
            v-model="form.valid"
        >
          <v-text-field
              v-model="form.data.email"
              autofocus
              label="EMail"
              prepend-icon="mdi-at"
              :rules="form.rules.email"
              validate-on-blur
              @keypress.enter.prevent
              @keyup.enter="onKeyupEnterEmail"
          />
        </v-form>
        <v-btn
            block
            text
            color="primary"
            class="mt-2"
            :loading="loading"
            @click="onClickRequestVerificationMail"
        >
          <v-icon left>mdi-email-outline</v-icon>
          {{ cText['button.send'] }}
        </v-btn>
      </v-card-text>
    </template>
    <v-alert
        v-if="error"
        text
        tile
        type="error"
        class="ma-0"
    >
      {{ cError }}
      <v-btn
          v-if="!hideRequestButton && (error === 400 || error === 401)"
          text
          small
          outlined
          color="primary"
          @click="onClickGoToRequestEmail"
      >
        {{ cText['button.request'] }}
      </v-btn>
    </v-alert>
    <v-card-actions>
      <v-btn
          v-if="finishAction === 'back'"
          block
          :disabled="loading"
          text
          @click="onClickGoToLogin"
      >
        <v-icon left>mdi-chevron-left</v-icon>
        {{ cText['button.back'] }}
      </v-btn>
      <template v-if="finishAction === 'close'">
        <eins-btn-timed
            v-if="verified"
            block
            :disabled="loading"
            text
            @click="onClickClose"
        >
          {{ cText['button.close'] }}
        </eins-btn-timed>
        <v-btn
            v-else
            block
            :disabled="loading"
            text
            @click="onClickClose"
        >
          {{ cText['button.close'] }}
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  isEmail,
  isNotEmpty
} from '@/utils/inputRules'
import {
  AuthModes,
  HttpStatus
} from '@/constants'
import { MxTranslatable } from '@/mixins'
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

const MODE_VERIFY = 'mode-verify'
const MODE_REQUEST = 'mode-request'

export default {
  name: 'AuthModuleVerifyEmail',
  components: { EinsBtnTimed },
  mixins: [
    MxTranslatable
  ],
  props: {
    finishAction: {
      type: String,
      default: 'back',
      validator: value => ['back', 'close'].includes(value)
    },
    hideRequestButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    verified: false,
    requested: false,
    error: false,
    form: {
      data: {
        email: ''
      },
      valid: false,
      rules: {
        email: [
          isNotEmpty,
          isEmail
        ]
      }
    },
    modes: {
      MODE_REQUEST,
      MODE_VERIFY
    },
    $_mx_translatable: {
      paths: [
        'captions.loading',
        'captions.verified',
        'captions.error',
        'captions.resend',
        'captions.again1',
        'captions.again2',
        'button.send',
        'button.request',
        'button.back',
        'button.close',
        'errors.conflict',
        'errors.badRequest',
        'errors.unknown'
      ]
    }
  }),
  computed: {
    cApiLink () {
      const link = (this.$route.query.link ?? '').trim()
      return link.length > 0 ? link : null
    },
    cMode () {
      if (this.cApiLink !== null) {
        return MODE_VERIFY
      } else {
        return MODE_REQUEST
      }
    },
    cError () {
      switch (this.error) {
        case HttpStatus.CONFLICT:
          return this.cText['errors.conflict']
        case HttpStatus.UNAUTHORIZED:
        case HttpStatus.BAD_REQUEST:
          return this.cText['errors.badRequest']
        default:
          return this.cText['errors.unknown']
      }
    }
  },
  watch: {
    cApiLink: {
      immediate: true,
      handler: 'verifyEmail'
    },
    loading: {
      immediate: true,
      handler (value) {
        this.$emit('update:loading', value)
      }
    }
  },
  mounted () {
    const email = (this.$route.query.email ?? '').trim()
    if (email.length > 0) {
      this.form.data.email = email
    }
  },
  methods: {
    onClickClose () {
      this.emitCloseEvent()
    },
    onClickGoToLogin () {
      this.goToLogin()
    },
    onClickGoToRequestEmail () {
      this.error = false
      this.loading = false
      this.requested = false
      this.goToRequestEmail()
    },
    onKeyupEnterEmail () {
      this.requestVerificationEmail()
    },
    onChangeApiLink () {
      if (this.cApiLink !== null) {
        this.verifyEmail()
      }
    },
    onClickRequestVerificationMail () {
      this.requestVerificationEmail()
    },
    goToRequestEmail () {
      this.$router.push({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.EMAIL_VERIFICATION
        }
      })
    },
    goToLogin () {
      this.$router.push({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.LOGIN
        }
      })
    },
    verifyEmail () {
      if (this.cApiLink) {
        this.loading = true
        this.$http.put(this.cApiLink)
          .then(() => {
            this.verified = true
          })
          .catch(err => {
            if (err.response) {
              this.error = err.response.status
            } else {
              this.error = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    requestVerificationEmail () {
      if (this.$refs.form && this.$refs.form.validate()) {
        this.loading = true
        this.error = false

        this.$http.$api.post('/email-verification/players', {
          email: this.form.data.email
        })
          .then(() => {
            this.requested = true
          })
          .catch(err => {
            if (err.response) {
              this.error = err.response.status
            } else {
              this.error = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    emitCloseEvent () {
      this.$emit('click:close')
    }
  }
}
</script>

<style scoped>
</style>
