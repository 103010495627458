var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._b({attrs:{"items":_vm.cSelectOptions,"label":_vm.cLabel,"value":_vm.selection},on:{"input":_vm.onInput},scopedSlots:_vm._u([(!_vm.selection)?{key:"prepend-inner",fn:function(){return [_c('v-list-item-avatar',[_c('v-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.cAvatarIcon))])],1)],1)]},proxy:true}:null,{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.cAvatarIcon))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.cNoDataText)+" ")])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[(item.avatar)?_c('eins-api-image',{attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var src = ref.src;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":src}})],1)]}}],null,true)}):_c('v-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.cAvatarIcon))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[(item.avatar)?_c('eins-api-image',{attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var src = ref.src;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":src}})],1)]}}],null,true)}):_c('v-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.cAvatarIcon))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}],null,true)},'v-select',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }