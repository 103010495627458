<template>
  <v-row
      align="center"
      style="height: 100%;"
  >
    <v-spacer />
    <v-col>
      <div class="text-h1 text-center font-weight-light">
        <i18n path="error.forbidden.title" />
      </div>
      <v-alert
          class="mt-3"
          type="error"
          outlined
          text
      >
        <i18n path="error.forbidden.description">
          <slot></slot>
          <slot name="id"></slot>
        </i18n>
      </v-alert>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
export default {
  name: 'EinsErrorForbidden'
}
</script>

<style scoped>

</style>
