import Vue from 'vue'
import Vuex from 'vuex'

import {
  app
} from '@/plugins/store/app'
import {
  conversations
} from '@/plugins/store/chat'
import {
  groups
} from '@/plugins/store/groups'
import {
  quests
} from '@/plugins/store/quests'
import {
  questsTimeline
} from '@/plugins/store/questsTimeline'
import {
  players
} from '@/plugins/store/players'
import {
  deals
} from '@/plugins/store/deals'
import {
  businessSectors
} from '@/plugins/store/businessSectors'
import {
  groupTypes
} from '@/plugins/store/groupTypes'
import {
  stats
} from '@/plugins/store/stats'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    conversations,
    deals,
    groups,
    players,
    quests,
    questsTimeline,
    businessSectors,
    groupTypes,
    stats
  }
})
