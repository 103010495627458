export const EinsStarmenuDlgApplication = {
  title: 'Apply',
  message: {
    label: 'Message',
    hint: 'Optional'
  },
  applyAs: {
    label: 'Apply as',
    hintGroup: 'You will apply in the name of the selected @.lower:(terms.group) ',
    hintMyself: 'You will apply in your name, and not in the name of a @.lower:(terms.group) ',
    myself: 'Myself'
  },
  error: {
    title: 'Something went wrong!',
    '409': 'You already applied for \'{0}\'!',
    unknown: 'An unknown error occured!',
    balance: 'The balance at your @.lower:(terms.players) account is not enough to apply for this @.lower:(terms.deal)'

  },
  btn: {
    submit: 'Apply',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgJoined = {
  text: 'Success!',
  btn: {
    close: 'Close'
  }
}

export const EinsStarmenuDlgLeft = {
  text: 'We are sad to see you leave. Goodbye!',
  btn: {
    close: 'Close'
  }
}

export const EinsStarmenuDlgLeaveGroup = {
  title: 'Leaving the @.lower:(terms.group)  \'{0}\'',
  text: 'Are you sure you want to leave? ' +
      'You will probably loose access to @.lower:(terms.quests) s and @.lower:(terms.deals) in the @.lower:(terms.group) .',
  cantLeave: {
    title: 'You can\'t leave just now!',
    text: 'You are the leader of the @.lower:(terms.group) . Please hand over that leadership before you leave!'
  },
  success: {
    title: 'Goodbye!',
    text: 'We are sad to see you leave!'
  },
  btn: {
    close: 'Close',
    submit: 'Leave',
    ok: 'Ok'
  }
}

export const EinsStarmenuDlgError = {
  text: 'Something went wrong!',
  btn: {
    close: 'Close'
  }
}

export const EinsStarmenuDlgDenunciation = {
  title: 'Report \'{0}\'',
  error: {
    title: 'Something went wrong!',
    unknown: 'An unknown error happened!'
  },
  btn: {
    submit: 'Report',
    cancel: 'Nevermind!',
    close: 'Close'
  }
}

export const EinsStarmenuDlgCloseAccount = {
  title: 'Delete permanently @.lower:(terms.player) account',
  text: 'Your account will be delete.' +
    ' @.lower:(terms.player) will no longer be able to see or find you anymore.' +
    ' However, your @.lower:(terms.quests) and @.lower:(terms.deals) will remain.',
  error: {
    title: 'Something went wrong!',
    unknown: 'An unknown error happened!'
  },
  cantLeave: {
    title: 'You can\'t leave just now!',
    text: 'You are the leader of one or more @.lower:(terms.groups) s. Please hand over that leadership or close the @.lower:(terms.group) (s) before you leave!'
  },
  btn: {
    submit: 'Delete Account',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgShare = {
  title: 'Share...'
}

export const EinsStarmenuDlgRejectDeal = {
  title: 'Withdraw from @.lower:(terms.deal) ',
  btn: {
    submit: 'Withdraw',
    cancel: 'I changed my mind',
    close: 'Close',
    info: 'Are you sure to reject it',
    error: 'Error!'
  },
  error: {
    '404': 'The @.lower:(terms.deal)  was not found',
    '403': 'You are not allowed to delete the @.lower:(terms.deal) ',
    409: 'This @.lower:(terms.deal) you already got',
    title: 'This is a error',
    default: 'Something went wrong!',
    unknown: 'An unknown error happened!'
  }
}

export const EinsStarmenuDlgCancelDeal = {
  title: 'Cancel @.lower:(terms.deal) ',
  text: 'You can not undo this action!',
  success: 'The @.lower:(terms.deal) has been deleted!',
  error: {
    '404': 'The @.lower:(terms.deal) was not found',
    '403': 'You are not allowed to delete the @.lower:(terms.deal) ',
    default: 'Something went wrong!'
  },
  btn: {
    submit: 'Cancel @.lower:(terms.deal) ',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}
export const EinsStarmenuDlgLinkDeal = {
  title: '@.lower:(terms.deal) cash in',
  text: 'Click on the @.lower:(terms.deal) code to copy it to the clipboard.',
  success: 'The @.lower:(terms.deal) code has been copied to the clipboard.',
  btn: {
    submit: 'Redeem @.lower:(terms.deal) now',
    cancel: 'Redeem @.lower:(terms.deal) later',
    close: 'Close'
  }
}

export const EinsStarmenuDlgCancelQuest = {
  title: 'Cancel @.lower:(terms.quest) ',
  text: 'You can not undo this action!',
  success: 'The @.lower:(terms.quest) has been deleted!',
  error: {
    '404': 'The @.lower:(terms.quest) was not found',
    '403': 'You are not allowed to delete the @.lower:(terms.quest)',
    default: 'Something went wrong!'
  },
  btn: {
    submit: 'Cancel @.lower:(terms.quest) ',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgFinishQuest = {
  title: 'Finish @.lower:(terms.quest) ',
  text: 'Inform \'{0}\' that you finished the @.lower:(terms.quest)! Please make a photo for \'{0}\'',
  success: 'The @.lower:(terms.quest)  is now marked as finished, \'{0}\' has been informed!',
  error: {
    '404': 'The @.lower:(terms.quest)  was not found',
    '403': 'You are not allowed to delete the @.lower:(terms.quest) ',
    default: 'Something went wrong!',
    picture: 'The picture could not be saved.'
  },
  noPicture: {
    text: 'Are you sure you want to continue without a picture? @.lower:(terms.player) will not see what you achieved!',
    btn: 'Im sure!'
  },
  btn: {
    submit: 'Finish @.lower:(terms.quest) ',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgGetDeal = {
  title: 'Get',
  message: {
    label: 'Message',
    hint: 'Optional'
  },
  applyAs: {
    label: 'Get as',
    hintGroup: 'You will get the @.lower:(terms.deal) in the name of the selected @.lower:(terms.group) ',
    hintMyself: 'You will get the @.lower:(terms.deal) in your name, and not in the name of a @.lower:(terms.group) ',
    myself: 'Myself'
  },
  error: {
    title: 'Something went wrong!',
    '409': 'You already get \'{0}\' this @.lower:(terms.deal)!',
    unknown: 'An unknown error happened!',
    balance: 'The balance at your @.lower:(terms.players) account is not enough to get this @.lower:(terms.deal)'
  },
  btn: {
    submit: 'Get',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgExchangeDeal = {
  title: 'Exchange',
  message: {
    label: 'Message',
    hint: 'Optional'
  },
  error: {
    title: 'Something went wrong!',
    '409': 'You already get \'{0}\' this @.lower:(terms.deal)!',
    unknown: 'An unknown error happened!',
    balance: 'The balance at your @.lower:(terms.players) account is not enough to exchange this @.lower:(terms.deal)'
  },
  btn: {
    submit: '  Exchange @.lower:(terms.deal)',
    cancel: 'I changed my mind',
    close: 'Close'
  }
}

export const EinsStarmenuDlgQrCode = {
  title: 'QR-Code',
  text1: 'Scan the QR Code with the 1APP QR reader',
  text2: 'Scan the QR Code with your phone to to get directly to this',
  close: 'Close'
}
