import Vue from 'vue'
import App from '@/App.vue'
import Auth from '@/Auth'
import Splash from '@/Splash'
import { getRedirectResult, OAuthProvider } from 'firebase/auth'
// CSS/SCSS
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css'
import 'vue-advanced-cropper/dist/style.css'
import '@/styles/main.scss'

import { fcm, firebase, i18n, router, store, vuetify } from '@/plugins'
import '@/plugins/constants'
import '@/plugins/leaflet'
import '@/plugins/http'
import { EventNames, HttpHeader, Model, StorageKeys } from '@/constants'

import './registerServiceWorker'
import { isUuid } from '@/utils/api'

if (window.location.search) {
  const query = {}
  for (const [key, value] of new URLSearchParams(
    window.location.search.slice(1)
  )) {
    query[key] = value
  }

  router.replace({
    name: router.currentRoute.name,
    query
  })
}

Vue.config.productionTip = false

new Vue({
  fcm,
  firebase,
  router,
  store,
  vuetify,
  i18n,
  data: () => ({
    loading: true,
    refreshing: false
  }),
  computed: {
    cAuthenticated () {
      const id = this.$store.getters['players/current/id']
      return isUuid(id) && id !== null
    }
  },
  created () {
    this.loading = true
    document.addEventListener('visibilitychange', this.onVisibilityChange)
    this.startup().finally(() => {
      this.loading = false
    })
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    startup () {
      return new Promise((resolve, reject) => {
        this.$firebase.onReady(() => {
          this.$firebase.auth.useDeviceLanguage()
        })
        this.$fcm.addMessageListener(this.onFirebaseMessage.bind(this))
        const apiToken = localStorage.getItem(StorageKeys.API_TOKEN) ?? null

        if (apiToken) {
          this.$http.$api.axios.defaults.headers.common[HttpHeader.AUTHORISATION] = `Bearer ${apiToken}`
          this.updatePlayerData()
            .then(() => {
              resolve()
            })
            .catch((err) => {
              localStorage.removeItem(StorageKeys.API_TOKEN)
              delete this.$http.$api.axios.defaults.headers.common[HttpHeader.AUTHORISATION]
              reject(err)
            })
        } else {
          this.$firebase.onReady(() => {
            this.processOauthRedirectionResult().finally(() => resolve())
          })
        }
      })
    },
    // Will be registered from `TheMessagingPermissionDialog` once permission is granted
    onFirebaseMessage (msg) {
      // attach via mixin MxListensToFcmEvents.onFcmMessage
      this.$emit(EventNames.FCM_MESSAGE, {
        msg,
        playerUpdate: this.updatePlayerData()
      })

      if (msg.tap) {
        this.navigateToNotificationTarget(msg)
      }
    },
    navigateToNotificationTarget (notification) {
      const subjectType = notification.subject_type ?? null
      const subjectId = notification.subject_id ?? null
      const type = notification.type

      if (subjectType !== null && subjectId !== null) {
        switch (subjectType) {
          case Model.CONVERSATION:
            this.$router.push({
              name: 'conversation-details',
              params: {
                id: subjectId
              }
            })
            break
          case Model.QUEST:
            this.$router.push({
              name: 'quest-detail',
              params: {
                id: subjectId
              }
            })
            break
          case Model.QUESTREACTION:
            this.$router.push({
              name: 'quest-detail',
              params: {
                id: subjectId
              }
            })
            break
          case Model.DEAL:
            this.$router.push({
              name: 'deal-detail',
              params: {
                id: subjectId
              }
            })
            break
          case Model.FRIENDSHIP:
            this.$router.push({
              name: 'player-detail',
              params: {
                id: subjectId
              }
            })
            break
          case Model.APPLICATION:
            const applicationSubjectId = notification.application_subject_id
            let targetSuffix = 'detail'
            if (type === Model.APPLICATION_CREATED) {
              targetSuffix = 'applications'
            } else if (type === Model.APPLICATION_ACCEPTED) {
              targetSuffix = 'detail'
            }
            switch (notification.application_subject_type) {
              case Model.DEAL: // do smth with deal
                this.$router.push({
                  name: `deal-${targetSuffix}`,
                  params: {
                    id: applicationSubjectId
                  }
                })
                break
              case Model.QUEST: // do smth with quest
                this.$router.push({
                  name: `quest-${targetSuffix}`,
                  params: {
                    id: applicationSubjectId
                  }
                })
                break
              case Model.PLAYERGROUPMEMBERSHIP: // do smth with group-membership
                this.$router.push({
                  name: `group-${targetSuffix}`,
                  params: {
                    id: applicationSubjectId
                  }
                })
                break
            }
            break
        }
      }
    },
    processOauthRedirectionResult () {
      return (
        getRedirectResult(this.$firebase.auth)
          .then((result) => {
            const credentials = OAuthProvider.credentialFromResult(result)

            if (credentials) {
              var token = credentials.accessToken
              const provider = this.$firebase.getLoginProviderNameById(
                credentials.providerId
              )
              if (provider === 'apple') {
                token = credentials.idToken
              }
              if (provider) {
                this.loading = true
                this.error = false
                return this.$store.dispatch('players/current/login', {
                  'oauth-token': token,
                  'oauth-provider': provider
                })
              } else {
                return Promise.resolve()
              }
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch((error) => {
          })
      )
    },
    onVisibilityChange () {
      this.$store.commit(
        'setVisibilityState',
        document.visibilityState === 'visible'
      )
    },
    updatePlayerData () {
      return Promise.all([
        this.$store.dispatch('players/current/update'),
        this.$store.dispatch('players/current/notifications/loadLatest')
      ])
    }
  },
  render (h) {
    if (!this.loading) {
      return this.cAuthenticated ? h(App) : h(Auth)
    } else {
      return h(Splash)
    }
  }
}).$mount('#app')
