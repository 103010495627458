<template>
  <v-card
      class="pa-0 ma-0"
      @click="onClickNotification"
  >
    <div
        style="display: block; width: 100%; max-width: 100%;margin:0;"
        :style="cIsNew ? 'border:1px solid red;' : 'border:1px solid grey;'"
    >
      <v-card-text class="pa-0 ma-0">
        <v-row
            no-gutters
            class="pa-0 fill-height"
        >
          <v-col
              cols="3"
              style="padding:2px;"
          >
            <eins-api-image
                #default="{ src }"
                :src="cImage"
            >
              <v-img
                  :src="src"
                  aspect-ratio="1"
                  height="100%"
                  class="text-center d-flex align-center"
              >
                <template>
                  <v-icon
                      v-show="cSubjectTypeIcon"
                      size="35"
                      color="primary"
                  >
                    {{ cSubjectTypeIcon }}
                  </v-icon>
                  <v-icon
                      v-show="cNotificationTypeIcon"
                      size="35"
                      color="primary"
                  >
                    {{ cNotificationTypeIcon }}
                  </v-icon>
                  <v-img
                      v-show="cNotificationTypeImage"
                      class="v-icon"
                      :src="cNotificationTypeImage"
                      style="width:35px;height:30px;"
                  ></v-img>
                </template>
              </v-img>
            </eins-api-image>
          </v-col>
          <v-col
              cols="9"
              class="pa-1"
          >
            <v-row no-gutters>
              <v-col
                  class="px-1"
                  @click="goToSubject"
              >
                <span
                    class="subtitle-2 d-block"
                >{{ cNotificationSubjectTitle }}
                </span>
              </v-col>
              <!-- wenn zwei buttons angezeigt werden sollen-->
              <v-col
                  v-if="cButtonCount > 1"
                  cols="3"
                  class="d-flex align-end pr-2 align-content-center"
              >
                <!-- button blend out because not working dialog exchange deal at notification
                <v-btn
                    v-show="buttons.accept"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickAcceptBtn"
                >
                  <div>
                    <v-icon>mdi-credit-card-check-outline</v-icon><br>
                    <span style="font-size:0.6em">
                      {{ $t(`notifications.actions.accept`) }}
                    </span>
                  </div>
                </v-btn> -->
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                  class="px-1"
                  @click="goToSubject"
              >
                <span class="caption d-block">{{ cNotificationText }}</span>
                <span
                    class="caption"
                    style="font-size:0.7em !important"
                >{{ cTimeDiff }}</span>
              </v-col>
              <v-col
                  v-if="cButtonCount > 0"
                  cols="3"
                  class="d-flex align-end pr-2 align-content-center"
              >
                <v-btn
                    v-show="buttons.applicants"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickApplicantsBtn"
                >
                  <div>
                    <v-icon>mdi-human-greeting</v-icon><br>
                    <span style="font-size:0.5em">{{ $t(`notifications.actions.applicants`) }}</span>
                  </div>
                </v-btn>
                <!-- button blend out because not working dialog reject deal at notification
                <v-btn
                    v-show="buttons.reject"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickRejectBtn"
                >
                  <div>
                    <v-icon>mdi-window-close</v-icon><br>
                    <span style="font-size:0.6em">{{ $t(`notifications.actions.reject`) }}</span>
                  </div>
                </v-btn> -->
                <v-btn
                    v-show="buttons.start"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickStartBtn"
                >
                  <div>
                    <v-icon>mdi-map-marker-star</v-icon><br>
                    <span style="font-size:0.8em">{{ $t(`notifications.actions.start`) }}</span>
                  </div>
                </v-btn>
                <v-btn
                    v-show="buttons.finish"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickFinishBtn"
                >
                  <div>
                    <v-icon>mdi-finish</v-icon><br>
                    <span style="font-size:0.8em">{{ $t(`notifications.actions.finish`) }}</span>
                  </div>
                </v-btn>
                <v-btn
                    v-show="buttons.approve"
                    outlined
                    depressed
                    tile
                    color="primary"
                    elevation="2"
                    class="action_btn"
                    :disabled="cActionDisabled"
                    @click="onClickApproveBtn"
                >
                  <div>
                    <v-icon>mdi-crown</v-icon><br>
                    <span style="font-size:0.5em">{{ $t(`notifications.actions.approve`) }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
export default {
  name: 'EinsNotificationListItem',
  components: {
    EinsApiImage
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    subjectPfad: '',
    subjectId: null,
    applicantId: null,
    notificationTypeIcon: '',
    notificationTypeImage: '',
    notificationText: '',
    showSubjectAllowed: true,
    actionAllowed: true,
    buttons: {
      applicants: false,
      accept: false,
      start: false,
      finish: false,
      approve: false,
      reject: false,
      acceptReject: false
    }
  }),
  computed: {
    cButtonCount () {
      let count = 0
      for (const button in this.buttons) {
        if (this.buttons[button] === true) {
          count++
        }
      }
      return count
    },
    cActionDisabled () {
      return !this.actionAllowed
    },
    cIsNew () {
      return this.notification.read_at === null
    },
    cNotificationSubject () {
      if (this.cSubjectIsFriendship) {
        return this.notification.subject.user
      } else
      if (this.cSubjectIsApplication) {
        return this.notification.subject.subject
      } else
      if (this.cSubjectIsGroup) {
        return this.notification.subject_type === 'App\\Models\\PlayerGroupMembership'
          ? this.notification.subject.group
          : this.notification.subject
      } else
      if (this.cSubjectIsDeal) {
        return this.notification.subject.subject
      } else
      if (this.cSubjectIsQuest) {
        return this.notification.subject
      } else {
        return this.notification.subject.subject
      }
    },
    cNotificationSubjectTitle () {
      if (this.cSubjectIsFriendship) {
        return this.cNotificationSubject.name
      } else {
        return this.cNotificationSubject.title
      }
    },
    cNotificationType () {
      return this.notification.type
    },
    cNotificationText () {
      return this.notificationText
    },
    cTimeDiff () {
      let now = new Date()
      let diff = 0
      let s = 0
      let m = 0
      let result = ''
      let updated = new Date(this.notification.updated_at)
      if (updated.getDate() !== now.getDate()) {
        result = 'at ' + updated.toLocaleDateString()
      } else {
        diff = (now.getHours() * 60 + now.getMinutes()) - (updated.getHours() * 60 + updated.getMinutes())
        result = diff + 'Minutes ago'
        if (diff > 59) {
          s = parseInt(diff / 60)
          m = diff - (s * 60)
          result = s + ' hours'
          if (m > 0) {
            result += ' and ' + m + ' Minutes '
          }
          result += ' ago'
        }
      }
      return result
    },
    cImage () {
      return this.cNotificationSubject.title_picture_feed || ''
    },
    cNotificationTypeIcon () {
      return this.notificationTypeIcon
    },
    cNotificationTypeImage () {
      return this.notificationTypeImage
    },
    cSubjectState () {
      return this.notification.subject.state
    },
    cIsGroupApplication () {
      return this.cSubjectIsApplication && this.cIsGroup
    },
    cIsGroup () {
      return this.notification.subject_type === 'App\\Models\\PlayerGroupMembership' || this.notification.subject.subject_type === 'App\\Models\\PlayerGroup'
    },
    cSubjectIsGroup () {
      return this.cIsGroupApplication || this.cIsGroup
    },
    cIsQuestApplication () {
      return this.cSubjectIsApplication && this.cIsQuest
    },
    cIsQuest () {
      return this.notification.subject_type === 'App\\Models\\Quest' || this.notification.subject.subject_type === 'App\\Models\\Quest'
    },
    cSubjectIsQuest () {
      return this.cIsQuestApplication || this.cIsQuest
    },
    cIsDealApplication () {
      return this.cSubjectIsApplication && this.cIsDeal
    },
    cIsDeal () {
      return this.notification.subject_type === 'App\\Models\\Deal' || this.notification.subject.subject_type === 'App\\Models\\Deal'
    },
    cSubjectIsDeal () {
      return this.cIsDealApplication || this.cIsDeal
    },
    cSubjectIsApplication () {
      return this.notification.subject_type === 'App\\Models\\Application'
    },
    cSubjectIsFriendship () {
      return this.notification.subject_type === 'App\\Models\\Friendship'
    },
    cSubjectTypeIcon () {
      let res = ''
      if (this.cSubjectIsGroup) {
        res = 'mdi-google-circles-communities'
      } else if (this.cSubjectIsDeal) {
        res = 'mdi-cart'
      } else if (this.cSubjectIsQuest) {
        res = 'mdi-map-marker'
      } else if (this.cSubjectIsFriendship) {
        res = 'mdi-account'
      }
      return res
    }
  },
  watch: {
    notification: {
      immediate: true,
      handler: 'initActions'
    }
  },
  created () {
    this.initActions()
  },
  methods: {
    initActions () {
      this.subjectId = this.cNotificationSubject.id
      this.applicantId = this.notification.subject.applicant?.id || 0
      if (this.cSubjectIsGroup) {
        // Gruppenbewerber
        this.subjectPfad = '/groups'
        const applicant = this.notification.subject.applicant?.name || ''
        switch (this.cNotificationType) {
          case 'App\\Notifications\\Application\\ApplicationCreatedNotification':
            this.notificationTypeIcon = 'mdi-human-greeting'
            this.notificationText = applicant + this.$t(`notifications.labels.group.applied`)
            this.buttons.applicants = true
            break
          case 'App\\Notifications\\Application\\ApplicationAcceptedNotification':
            this.notificationTypeIcon = 'mdi-wallet-membership'
            this.notificationText = this.$t(`notifications.labels.group.member`)
            break
          case 'App\\Notifications\\Application\\ApplicationRejectedNotification':
            this.notificationTypeIcon = 'mdi-minus-circle'
            this.showSubjectAllowed = false
            this.notificationText = this.$t(`notifications.labels.group.notMember`)
            break
          case 'App\\Notifications\\PlayerGroup\\PlayerGroupMembershipPromotedNotification':
            this.notificationTypeIcon = 'mdi-account-supervisor'
            this.notificationText = this.$t(`notifications.labels.group.admin`)
            break
          case 'App\\Notifications\\PlayerGroup\\PlayerGroupMembershipDemotedNotification':
            this.notificationTypeIcon = 'mdi-wallet-membership'
            this.showSubjectAllowed = false
            this.notificationText = this.$t(`notifications.labels.group.downMember`)
            break
        }
      } else if (this.cSubjectIsDeal) {
        this.subjectPfad = '/deals'
        switch (this.cNotificationType) {
          case 'App\\Notifications\\Application\\ApplicationCreatedNotification':
            const applicant = this.notification.subject.applicant?.name || ''
            this.notificationTypeIcon = 'mdi-human-greeting'
            this.notificationText = applicant + this.$t(`notifications.labels.deal.applied`)
            this.buttons.applicants = true
            break
          case 'App\\Notifications\\Application\\ApplicationAcceptedNotification':
            this.notificationTypeIcon = 'mdi-lock-open-outline'
            this.notificationText = this.$t(`notifications.labels.deal.assigned`)
            this.actionAllowed = this.cSubjectState === 'accepted'
            this.buttons.reject = true
            this.buttons.accept = true
            break
          case 'App\\Notifications\\Application\\ApplicationConfirmedNotification':
            this.notificationTypeIcon = 'mdi-cart-check'
            this.notificationText = this.notification.subject.subject.deal_transactions[0].customer.name + this.$t(`notifications.labels.deal.finished`)
            break
          case 'App\\Notifications\\Application\\ApplicationWithdrawnNotification':
            this.notificationTypeIcon = 'mdi-close-box-outline'
            this.notificationText = this.notification.subject.applicant?.name + this.$t(`notifications.labels.deal.withdrawn`)
            this.buttons.applicants = true
            break
        }
      } else if (this.cSubjectIsQuest) {
        this.subjectPfad = '/quests'
        switch (this.cNotificationType) {
          case 'App\\Notifications\\Application\\ApplicationCreatedNotification':
            const applicant = this.notification.subject.applicant?.name || ''
            this.notificationTypeIcon = 'mdi-human-greeting'
            this.notificationText = applicant + this.$t(`notifications.labels.quest.applied`)
            this.buttons.applicants = true
            break
          case 'App\\Notifications\\Application\\ApplicationAcceptedNotification':
            this.notificationTypeIcon = 'mdi-lock-open-variant'
            this.notificationText = this.$t(`notifications.labels.quest.assigned`)
            this.actionAllowed = this.cSubjectState === 'accepted'
            this.buttons.start = true
            break
          case 'App\\Notifications\\Application\\ApplicationRejectedNotification':
            this.notificationTypeIcon = 'mdi-close'
            this.showSubjectAllowed = false
            this.notificationText = this.notification.subject.subject.player?.name || '' + this.$t(`notifications.labels.quest.rejected`)
            break
          case 'App\\Notifications\\Quest\\QuestStartedNotification':
            const assignee = this.notification.subject.processing.assignee?.name || ''
            this.notificationTypeIcon = 'mdi-run'
            this.notificationText = assignee + this.$t(`notifications.labels.quest.started`)
            // this.buttons.finish = true
            break
          case 'App\\Notifications\\Quest\\QuestFinishedNotification':
            this.notificationTypeIcon = 'mdi-camera'
            this.notificationText = this.notification.subject.processing.assignee?.name + this.$t(`notifications.labels.quest.finished`)
            this.actionAllowed = this.cSubjectState === 'finished'
            this.buttons.approve = true
            break
          case 'App\\Notifications\\Quest\\QuestApprovedNotification':
            this.notificationTypeIcon = 'mdi-crown'
            this.notificationText = this.$t(`notifications.labels.quest.approved`)
            break
        }
      } else if (this.cSubjectIsFriendship) {
        this.subjectPfad = '/players'
        switch (this.cNotificationType) {
          case 'App\\Notifications\\Friendship\\FriendshipNewFriendNotification':
            this.notificationTypeImage = require('@/assets/img/icons/friendship/friend.png')
            this.notificationText = this.$t(`notifications.labels.friendship.newFriend`)
            break
          case 'App\\Notifications\\Friendship\\FriendshipNewFollowerNotification':
            this.notificationTypeImage = require('@/assets/img/icons/friendship/fan.png')
            this.notificationText = this.$t(`notifications.labels.friendship.newFollower`)
            break
        }
      }
    },
    onClickNotification () {
      this.markNotificationRead()
    },
    goToSubject () {
      if (this.showSubjectAllowed) {
        this.loading = true
        this.$router.push(`${this.subjectPfad}/${this.cNotificationSubject.id}`)
          .finally(() => {
            this.loading = false
            this.afterRunAction()
          })
      }
    },
    markNotificationRead () {
      if (this.cIsNew) {
        this.loading = true
        return this.$http.$api.patch(`notifications/${this.notification.id}`)
          .then(() => Promise.all([
            this.$store.dispatch('players/current/notifications/updateNew'),
            this.$store.dispatch('players/current/update')
          ]))
          .finally(() => {
            this.loading = true
          })
      } else {
        return Promise.resolve()
      }
    },
    onClickAcceptBtn () {
      this.loading = true
      this.$store.dispatch('openDialogExchangeDeal', {
        deal: this.cNotificationSubject
      })
        .finally(() => {
          this.loading = false
          this.$router.push(`${this.subjectPfad}/${this.cNotificationSubject.id}`)
          this.afterRunAction()
        })
    },
    onClickApproveBtn () {
      this.loading = true
      this.$http.$api.patch(`${this.subjectPfad}/${this.subjectId}/processing`, { approved: true })
        .finally(() => {
          this.loading = false
          this.$router.push(`${this.subjectPfad}/${this.cNotificationSubject.id}`)
          this.afterRunAction()
        })
    },
    onClickApplicantsBtn () {
      this.loading = true
      this.$router.push(`${this.subjectPfad}/${this.subjectId}/applications`)
        .finally(() => {
          this.loading = false
          this.afterRunAction()
        })
    },
    onClickStartBtn () {
      this.loading = true
      this.$http.$api.patch(`${this.subjectPfad}/${this.subjectId}/processing`, { started: true })
        .finally(() => {
          this.loading = false
          this.$router.push(`${this.subjectPfad}/${this.cNotificationSubject.id}`)
          this.afterRunAction()
        })
    },
    onClickRejectBtn () {
      this.loading = true
      this.$store.dispatch('openDialogRejectDeal', {
        deal: this.cNotificationSubject
      })
        .finally(() => this.$store.dispatch('deals/selected/update'))
      this.loading = false
      this.$router.push(`${this.subjectPfad}/${this.cNotificationSubject.id}`)
      this.afterRunAction()
    },
    onClickFinishBtn () {
      this.loading = true
      this.$store.dispatch('openDialogFinishQuest', {
        quest: this.cNotificationSubject
      })
        .finally(() => {
          this.loading = false
          this.afterRunAction()
        })
    },
    afterRunAction () {
      this.markNotificationRead()
        .then(() => {
          return this.$store.dispatch('players/current/notifications/updateNotification', this.notification.id)
        })
      this.$store.commit('closeNotificationsPane')
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.v-badge.blink::v-deep {
  .v-badge__badge {
    animation-name: blink-notification-badge;
    animation-duration: 0.75s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

@keyframes blink-notification-badge {
  from { opacity: 0; }
  to { opacity: 1; }
}

.action_btn {
  width:90% !important;
  height:50px !important;
  border-radius:5px !important;
}
</style>
