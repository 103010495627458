<template>
  <eins-layout-view-detail>
    <template v-if="!cError && cGroup && cBasicView">
      <eins-group-detail-basic-data
          :group="cGroup"
      />
    </template>
    <template v-else-if="!cError && cGroup && !cBasicView">
      <eins-group-detail-member-data
          :group="cGroup"
      />
    </template>
    <eins-error-not-found
        v-else-if="cError === 404"
    >
      <i18n path="views.ViewGroupDetail.theGroup" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>
      </template>
    </eins-error-not-found>
    <eins-error-forbidden
        v-else-if="error === 403"
    >
      <i18n path="views.ViewGroupDetail.theGroupLc" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>!
      </template>
    </eins-error-forbidden>
  </eins-layout-view-detail>
</template>

<script>
import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsGroupDetailMemberData from '@/components/group/EinsGroupDetailMemberData'
import EinsGroupDetailBasicData from '@/components/group/EinsGroupDetailBasicData'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'

import {
  Membership
} from '@/constants'

export default {
  name: 'ViewGroupDetail',
  components: {
    EinsErrorForbidden,
    EinsErrorNotFound,
    EinsLayoutViewDetail,
    EinsGroupDetailBasicData,
    EinsGroupDetailMemberData
  },
  data: () => ({
    loading: false,
    group: null,
    error: false
  }),
  computed: {
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      return this.$store.getters['groups/selected/loading']
    },
    cError () {
      return this.$store.getters['groups/selected/error']
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cBasicView () {
      return this.cGroup?.membership_state === Membership.NONE ||
        this.$route.hash === '#details'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadGroup(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadGroup(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadGroup(this.$route.params.id)
    },
    loadGroup (id) {
      return this.$store.dispatch('groups/selected/load', id)
    }
  }
}
</script>

<style scoped>

</style>
