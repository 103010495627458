<template>
  <v-text-field
      ref="input"
      solo
      hide-details
      dense
      full-width
      :style="`border:2px solid ${cColor} !important`"
      :value="internalValue"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @input="onInput"
  >
    <template #label>
      <v-icon
          left
          :color="cColor"
      >
        mdi-magnify
      </v-icon>
      <span :style="`color:${cColor} !important`">{{ label }}</span>
    </template>
    <template #prepend-inner>
      <!-- eslint-disable-next-line -->
      <slot name="icon" />
    </template>
    <template #append>
      <v-dialog
          v-if="cShowFilter"
          v-model="showFilterDialog"
      >
        <template #activator="{ on }">
          <v-badge
              dot
              :value="!cAllSelected"
          >
            <v-icon
                :color="cColor"
                @click.stop.prevent="on.click"
            >mdi-filter-variant</v-icon>
          </v-badge>
        </template>
        <div
            class="detail-content"
            :style="`border:1px solid ${cColor} !important`"
        >
          <v-list
              class="pa-0"
          >
            <v-list-item-group
                v-model="filterValueInternal"
                multiple
            >
              <v-list-item
                  v-for="option in cFilterOptions"
                  :key="option.value"
                  #default="{ active }"
                  :disabled="option.disabled"
                  :value="option.value"
                  @click="onClickListItem"
              >
                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                      color="primary"
                  />
                </v-list-item-action>
                <slot
                    name="filter-icon"
                    v-bind="option"
                >
                  <v-list-item-icon v-if="option.icon">
                    <v-icon
                        left
                        v-text="option.icon"
                    />
                  </v-list-item-icon>
                </slot>
                <v-list-item-content>
                  <v-list-item-title
                      :class="{ 'font-weight-bold': option.strong }"
                  >
                    {{ option.title }}
                  </v-list-item-title>
                  <v-list-item-title v-if="option.subTitle">
                    {{ option.subTitle }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-expand-transition>
            <v-progress-linear
                v-if="cShowFilterDialogTimeout"
                :value="filterDialogTimeout.progress"
                color="primary"
                height="1"
            />
          </v-expand-transition>
          <v-row class="no-gutters filter-actions align-content-sm-space-around">
            <v-btn
                color="grey"
                elevation="2"
                class="w-100 ma-1"
                outlined
                @click="onClickCloseDialog"
            >
              Abbrechen
            </v-btn>
            <v-spacer />
            <v-btn
                right
                color="primary"
                elevation="2"
                class="w-100 ma-1"
                outlined
                @click="onClickSetFilter"
            >
              OK
            </v-btn>
          </v-row>
        </div>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import {
  MxHasModel,
  MxPropRedirection,
  MxVuetifyInputWrapper
} from '@/mixins'
import {
  objectHasOwnProperty
} from '@/utils/object'

const VALUE_ALL = '__all__'
const FILTER_DIALOG_TIMEOUT_DURATION = 2000

export default {
  name: 'EinsInputToolbarExtension',
  mixins: [
    MxHasModel,
    MxPropRedirection,
    MxVuetifyInputWrapper
  ],
  props: {
    label: {
      type: String,
      default: ''
    },
    objectType: {
      type: String,
      default: ''
    },
    withoutFilter: {
      type: Boolean,
      default: false
    },
    filterOptions: {
      type: Array,
      default: () => [],
      validator: value => Array.isArray(value) &&
          value.every(el => objectHasOwnProperty(el, 'title') && objectHasOwnProperty(el, 'value'))
    },
    filterValue: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    filterValueInternal: [VALUE_ALL],
    showFilterDialog: false,
    focus: false,
    filterDialogTimeout: {
      interval: null,
      progress: 0
    }
  }),
  computed: {
    cShowFilter () {
      return !this.withoutFilter && this.filterOptions.length > 0
    },
    cShowFilterDialogTimeout () {
      return this.filterDialogTimeout.interval !== null
    },
    cAll () {
      return {
        strong: true,
        title: this.$t('searchBar.filter.all.title'),
        value: VALUE_ALL
      }
    },
    cAllSelected () {
      return this.filterOptions.every(option => (
        !option.invertedAll &&
        this.filterValueInternal.includes(option.value)
      ) || (
        option.invertedAll &&
        !this.filterValueInternal.includes(option.value)
      ))
    },
    cFilterOptions () {
      return [
        this.cAll,
        ...this.filterOptions.map(option => ({
          ...option,
          disabled: option.disabled ||
            (
              this.filterValueInternal.length === 1 &&
              this.filterValueInternal.includes(option.value)
            )
        }))
      ]
    },
    cColor () {
      if (this.objectType === 'quest') {
        return this.focus ? 'var(--v-quest-base)' : 'var(--v-questoff-base)'
      } else if (this.objectType === 'group') {
        return this.focus ? 'var(--v-group-base)' : 'var(--v-groupoff-base)'
      } else if (this.objectType === 'deal') {
        return this.focus ? 'var(--v-deal-base)' : 'var(--v-dealoff-base)'
      } else if (this.objectType === 'player') {
        return this.focus ? 'var(--v-player-base)' : 'var(--v-playeroff-base)'
      } else {
        return 'grey'
      }
    }
  },
  watch: {
    filterOptions: {
      immediate: true,
      handler: 'onChangeFilterOptions'
    },
    filterValueInternal: {
      immediate: true,
      handler: 'onChangeFilterValueInternal'
    },
    showFilterDialog: {
      immediate: true,
      handler: 'onChangeShowFilterDialog'
    },
    filterValue: {
      immediate: true,
      handler: 'onChangeFilterValue'
    }
  },
  methods: {
    onFocusIn () {
      this.focus = true
    },
    onFocusOut () {
      this.focus = false
    },
    onClickListItem () {
      // this.triggerCloseDialogTimeout()
    },
    onClickSetFilter () {
      this.emitFilterUpdate()
      this.showFilterDialog = false
    },
    onClickCloseDialog () {
      this.showFilterDialog = false
    },
    onChangeShowFilterDialog (value, oldValue) {
      this.clearCloseDialogTimeout()
      if (!value && oldValue) {
        this.emitFilterUpdate()
      }
      this.$refs.input.blur()
    },
    onChangeFilterOptions () {
      if (
        this.filterValueInternal.includes(VALUE_ALL) ||
        this.filterValueInternal.length === 0
      ) {
        this.selectAll()
      }
    },
    onChangeFilterValueInternal (value, oldValue) {
      if (value.includes(VALUE_ALL)) {
        if (
          !Array.isArray(oldValue) ||
          !oldValue.includes(VALUE_ALL)
        ) {
          this.selectAll()
        }

        if (!this.cAllSelected) {
          this.filterValueInternal.splice(this.filterValueInternal.indexOf(VALUE_ALL), 1)
        }
      } else if (this.cAllSelected) {
        this.filterValueInternal.push(VALUE_ALL)
      }
    },
    onChangeFilterValue () {
      if (this.filterValue.length > 0) {
        this.filterValueInternal = [...this.filterValue]
      } else {
        this.selectAll()
      }
    },
    selectAll () {
      for (const option of this.cFilterOptions) {
        if (!this.filterValueInternal.includes(option.value) && !option.invertedAll) {
          this.filterValueInternal.push(option.value)
        } else if (this.filterValueInternal.includes(option.value) && option.invertedAll) {
          this.filterValueInternal.splice(this.filterValueInternal.indexOf(option.value), 1)
        }
      }
    },
    emitFilterUpdate () {
      this.$emit('update:filter', this.cAllSelected ? [] : [...this.filterValueInternal])
    },
    clearCloseDialogTimeout () {
      if (this.filterDialogTimeout.interval) {
        clearInterval(this.filterDialogTimeout.interval)
        this.filterDialogTimeout.interval = null
        this.filterDialogTimeout.progress = 0
      }
    },
    triggerCloseDialogTimeout () {
      this.clearCloseDialogTimeout()
      const start = Date.now()
      this.filterDialogTimeout.interval = setInterval(() => {
        if (this.filterDialogTimeout.progress === 100) {
          this.showFilterDialog = false
          this.$nextTick().then(() => this.clearCloseDialogTimeout())
        } else {
          const diff = Date.now() - start
          if (diff >= FILTER_DIALOG_TIMEOUT_DURATION) {
            this.filterDialogTimeout.progress = 100
          } else {
            this.filterDialogTimeout.progress = 100 * diff / FILTER_DIALOG_TIMEOUT_DURATION
          }
        }
      }, FILTER_DIALOG_TIMEOUT_DURATION / 100)
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
.v-list-item--dense ::v-deep {
  .v-list-item__action {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
.filter-actions {
}
.v-list-item--link::before {
  background-color: transparent;
}
</style>
