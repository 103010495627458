<template>
  <div
      ref="content"
      v-scroll:#main-content-scroll="onScroll"
      class="detail-content"
      style="height:100%;"
  >
    <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
      <v-slide-y-transition>
        <v-progress-linear
            v-show="cLoading"
            height="5"
            indeterminate
        />
      </v-slide-y-transition>
    </div>
    <eins-chat-list
        :chats="cChats"
    />
    <v-alert
        outlined
        type="warning"
        dense
        text
        class="mx-2"
        :value="cListEnd"
    >
      End reached
    </v-alert>
    <router-view />
  </div>
</template>

<script>
import EinsChatList from '@/components/chat/EinsChatList'
import { EventBus } from '@/eventBus.js'

export default {
  name: 'ViewChatList',
  components: { EinsChatList },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.updateChatList()
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.updateChatList()
    next()
  },
  data: () => ({
    lastPosition: 0
  }),
  computed: {
    cChats () {
      let textFilterLc = this.cTextFilter.toLowerCase()
      return this.$store.getters['conversations/list']
        .filter((el) => el.latest_message &&
          (
            textFilterLc.length === 0 ||
            el.contacts.reduce(
              (acc, contact) => acc || contact.name.toLowerCase().includes(textFilterLc),
              false
            ) ||
            el.latest_message.body.toLowerCase().includes(textFilterLc)
          )
        )
    },
    cTextFilter () {
      return this.$store.getters['conversations/textFilter'] ?? ''
    },
    cListEnd () {
      return this.$store.getters['conversations/listEnd']
    },
    cLoading () {
      return this.$store.getters['conversations/listLoading']
    }
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
    EventBus.$on('changeTextFilter', (myVal) => {
      this.textFilter = myVal
    })
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.updateChatList()
    },
    onScroll (e) {
      if (!this.cLoading && this.$refs.content && !this.cListEnd) {
        const distanceLeft = this.$refs.content.clientHeight - (e.target.scrollTop + e.target.clientHeight)
        // only load more on scroll down
        if (distanceLeft < 250 && this.lastPosition < e.target.scrollTop) {
          this.$store.dispatch('conversations/loadMore')
        }

        this.lastPosition = e.target.scrollTop
      }
    },
    updateChatList () {
      this.$store.dispatch('conversations/update')
    },
    ChangeTextFilter (value) {
      this.textFilter = value
    }
  }
}
</script>

<style scoped>
</style>
