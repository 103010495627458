export default {
  'private': {
    'name': '@.lower:(terms.private)',
    'caption': '@.lower:(terms.player) received the "@.lower:(terms.private)" medal',
    'caption_get': 'You received the "@.lower:(terms.private)" medal',
    'description': 'Has done a @.lower:(terms.private) @.lower:(terms.quest)'
  },
  'common': {
    'name': '@.lower:(terms.common)',
    'caption': '@.lower:(terms.player) received the "@.lower:(terms.common)" medal',
    'caption_get': 'You received the "@.lower:(terms.common)" medal',
    'description': 'Has done a @.lower:(terms.common) @.lower:(terms.quest)'
  },
  'green': {
    'name': '@.lower:(terms.future)',
    'caption': '@.lower:(terms.player) received the "@.lower:(terms.future)" medal',
    'caption_get': 'You received the "@.lower:(terms.future)" medal',
    'description': 'Has done a @.lower:(terms.future) @.lower:(terms.quest)'
  },
  '4': {
    'name': 'Supporter',
    'caption': '@.lower:(terms.player) received the supporter medal',
    'caption_get': 'You support the 1APP',
    'description': 'You are a supporter!'
  },
  '5': {
    'name': 'newmedal',
    'caption': '@.lower:(terms.player) received the "new" medal',
    'caption_get': 'You get the new medal',
    'description': 'You are new medal!'
  }
}
