import Vue from 'vue'

import {
  http
} from '@/plugins/http'
import {
  i18n
} from '@/plugins/i18n'

const typeIsTranslated = key => i18n.te(`groupTypes.${key}.title`)
const typeTitleTranslated = key => i18n.t(`groupTypes.${key}.title`)
const typeDescriptionTranslated = key => i18n.t(`groupTypes.${key}.description`)
const typeServicePackageTranslated = key => i18n.t(`groupTypes.${key}.ServicePackage`)

export const groupTypes = {
  namespaced: true,
  state: {
    result: null,
    loading: false
  },
  getters: {
    list: state => (state.result?.types ?? [])
      .filter(el => typeIsTranslated(el.key))
      .map(el => ({
        ...el,
        title: typeTitleTranslated(el.key),
        description: typeDescriptionTranslated(el.key),
        ServicePackage: typeServicePackageTranslated(el.key)
      })),
    sector: (state, getters) => key => getters.list.find(el => el.key === key),
    item: (state, getters) => key => getters.list.find(el => el.key === key),
    istLoading: state => state.loading
  },
  mutations: {
    invalidate (state) {
      Vue.set(state, 'result', null)
    },
    setResult (state, result) {
      Vue.set(state, 'result', result)
    }
  },
  actions: {
    getData ({ state, commit }) {
      state.loading = true
      return http.$api.get('/group-types')
        .then(response => {
          commit('setResult', response.data)
          return response
        })
        .catch(() => {
          commit('invalidate')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ state, dispatch }) {
      return dispatch('getData')
    }
  }
}
