<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>
        <i18n path="player.block.dialog.title">
          <span class="text-no-wrap">'{{ playerName }}'</span>
        </i18n>
      </v-card-title>
      <v-card-text v-if="!success">
        {{ cText }}
      </v-card-text>
      <v-alert
          type="error"
          tile
          text
          :value="!!cError"
      >
        {{ cError }}
      </v-alert>
      <v-alert
          type="success"
          tile
          text
          :value="success"
      >
        {{ cSuccessMessage }}
      </v-alert>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            outlined
            block
            @click="onClickClose"
        >
          {{ cButton.close }}
        </eins-btn-timed>
        <template v-else>
          <v-row
              dense
              class="flex-column"
          >
            <v-col>
              <v-btn
                  outlined
                  block
                  @click="onClickCancel"
              >
                {{ cButton.cancel }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  outlined
                  color="primary"
                  block
                  :loading="loading"
                  @click="onClickSubmit"
              >
                {{ cButton.submit }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgBlockPlayer',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    playerName: null,
    playerId: null,
    loading: false,
    success: false,
    error: false,
    $_mx_translatable: {
      paths: [
        'title',
        'text',
        'success',
        'error.404',
        'error.403',
        'error.default',
        'btn.submit',
        'btn.cancel',
        'btn.close'
      ]
    }
  }),
  computed: {
    cError () {
      const t = error => this.$t(`player.block.dialog.state.error.${error}`)

      switch (this.error) {
        case false: return false
        default: return t('default')
      }
    },
    open: {
      get () {
        return this.$store.getters['dialogBlockPlayer']
      },
      set (value) {
        this.$store.commit('dialogBlockPlayer', value)
      }
    },
    cText () {
      return this.$t(`player.block.dialog.text`)
    },
    cButton () {
      const t = btnName => this.$t(`player.block.dialog.buttons.${btnName}`)

      return {
        cancel: t('cancel'),
        submit: t('submit'),
        close: t('close')
      }
    },
    cSuccessMessage () {
      return this.$t(`player.block.dialog.state.success`)
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    }
  },
  watch: {
    open: 'onOpen',
    cPlayer: 'onChangePlayer'
  },
  methods: {
    onOpen () {
      this.success = false
      this.error = false
      this.loading = false
    },
    onClickClose () {
      this.close()
    },
    onClickCancel () {
      this.close()
    },
    onClickSubmit () {
      this.blockPlayer()
    },
    onChangePlayer (value) {
      if (value) {
        this.playerName = value.name
        this.playerId = value.id
      }
    },
    close () {
      this.open = false
    },
    blockPlayer () {
      this.loading = true
      this.error = false
      return this.$http.$api.post(`blockages`, {
        user_id: this.playerId,
        scopes: ['*']
      })
        .then(() => {
          this.success = true
          this.$router.replace({
            name: 'player-list'
          })
          this.$store.commit('players/removeFromCache', this.playerId)
          this.$store.commit('players/selected/removeFromRingBuffer', this.playerId)
        })
        .catch((err) => {
          this.error = err?.status ?? true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
</style>
