import auth from '@/locales/de/auth'
import notifications from '@/locales/de/notifications'
import businessSectors from '@/locales/de/businessSectors'
import common from '@/locales/de/common'
import countries from '@/locales/de/countries'
import components from '@/locales/de/components'
import deal from '@/locales/de/deal'
import denunciationTypes from '@/locales/de/denunciationTypes'
import error from '@/locales/de/error'
import friendship from '@/locales/de/friendship'
import group from './group'
import groupTypes from '@/locales/de/groupTypes'
import input from '@/locales/de/input'
import inputRules from '@/locales/de/inputRules'
import locales from '@/locales/de/locales'
import medals from '@/locales/de/medals'
import msc from '@/locales/de/msc'
import player from '@/locales/de/player'
import quest from '@/locales/de/quest'
import searchBar from '@/locales/de/searchBar'
import timeValue from '@/locales/de/timeValue'
import views from '@/locales/de/views'

export const de = {
  auth,
  notifications,
  businessSectors,
  common,
  countries,
  components,
  deal,
  denunciationTypes,
  error,
  friendship,
  group,
  groupTypes,
  input,
  inputRules,
  locales,
  medals,
  msc,
  player,
  quest,
  searchBar,
  timeValue,
  views,
  'terms': {
    'chat': 'Chat',
    'group': 'Feld',
    'groups': 'Felder',
    'player': 'Player',
    'players': 'Players',
    'quest': 'Challenge',
    'quests': 'Challenges',
    'deal': 'Deal',
    'deals': 'Deals',
    'medal': 'Medalie',
    'medals': 'Medalien',
    'description': 'Beschreibung',
    'private': 'private',
    'common': 'gemeinschaftlichen',
    'nature': 'zukunft',
    'green': 'zukunft',
    'future': 'zukunft',
    'stars': 'Sterne',
    'star': 'Stern',
    'point': 'Punkt',
    'points': 'Punkte',
    'service': 'Service',
    'product': 'Produkt'
  },
  'time': {
    'day': '{n} Tag | {n} Tage',
    'hour': '{n} Stunde | {n} Stunden',
    'minute': '{n} Minute | {n} Minuten'
  },
  'mainmenu': {
    'eins-locale-select': {
      'label': 'Sprache wählen'
    },
    'logout': {
      'label': 'Abmelden'
    },
    'main-page': {
      'label': 'Startseite'
    },
    'my-profile': {
      'label': 'Mein Profil'
    },
    'available-stars': {
      'label': 'Verfügbare Sterne'
    },
    'impress': {
      'label': 'Impressum'
    },
    'scanner': {
      'label': '1APP QR-Code Scanner'
    },
    '1-indepedent': {
      'label': '1INDEPENDENT 6020 AD'
    }
  }
}
