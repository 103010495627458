export const DiOpacity = (el, { value, oldValue, modifiers }) => {
  if (value !== oldValue) {
    let opacity

    if (modifiers.toggle) {
      opacity = value ? '1' : '0'
    } else {
      // expect value in 0-100%
      opacity = (Math.round(value) % 100) / 100
    }

    el.style.opacity = opacity
  }
}
