<template>
  <eins-layout-list
      #default="{ item }"
      :items="groups"
  >
    <slot
        name="item"
        :item="item"
    >
      <eins-group-list-item
          :group="item"
      />
    </slot>
    <div style="position:fixed; bottom: 56px;right:4px;">
      <slot name="switcher"></slot>
    </div>
  </eins-layout-list>
</template>

<script>
import EinsGroupListItem from '@/components/group/EinsGroupListItem'
import EinsLayoutList from '@/components/layout/EinsLayoutList'

export default {
  name: 'EinsGroupList',
  components: {
    EinsLayoutList,
    EinsGroupListItem
  },
  props: {
    groups: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>

</style>
