<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-textarea
          ref="textarea"
          v-model="internalValue"
          :label="cLabel"
          lines="3"
          :rules="rules"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...$listeners, ...on }"
          @input="onInput"
      />
    </template>
    <v-card
        class="d-flex flex-column"
        style="background-color: black !important"
        max-height="100%"
        tile
    >
      <v-toolbar
          v-if="label"
          class="flex flex-grow-0"
          dark
          color="grey darken-2"
      >
        <v-toolbar-title>
          {{ label }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
            icon
            dark
            @click="onClickClose"
        >
          <v-icon>mdi-content-save-all</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet
          ref="inputContainer"
          v-resize="onResize"
          class="flex flex-grow-1 mt-0 pt-0"
      >
        <v-textarea
            v-model="internalValue"
            class="mt-0 pt-0"
            autofocus
            solo
            :height="inputHeight"
            :hide-details="!hasErrorState"
            :rules="rules"
            flat
            no-resize
            @input="onInput"
        />
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EinsInputTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    internalValue: '',
    formValid: false,
    tempValue: '',
    dialog: false,
    inputHeight: 1,
    hasErrorState: false
  }),
  computed: {
    cLabel () {
      return this.label
    },
    cDialogCaption () {
      return this.dialogCaption
    },
    cCancelLabel () {
      return this.cancelButton
    },
    cConfirmLabel () {
      return this.confirmButton
    },
    cDisabled () {
      return !this.formValid
    }
  },
  watch: {
    value: {
      handler: 'onChangeValue',
      immediate: true
    },
    hasErrorState: 'onResize'
  },
  methods: {
    onClickClose () {
      this.close()
    },
    onChangeValue () {
      this.applyValue()
    },
    onInput () {
      this.emitInput()
    },
    applyValue () {
      this.internalValue = this.value
    },
    onResize () {
      this.$nextTick().then(() => {
        if (this.$refs.inputContainer) {
          this.inputHeight = this.$refs.inputContainer.$el.clientHeight - 5
          if (this.hasErrorState) {
            this.inputHeight = this.inputHeight - 50
          }
        }
      })
    },
    emitInput () {
      if (this.$refs.textarea) {
        this.hasErrorState = this.$refs.textarea.hasError
      }
      this.$emit('input', this.internalValue)
    },
    close () {
      this.dialog = false
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.dialog-input ::v-deep {
  .v-input__control {
    height: 100%;

    .v-input__slot {
      flex: 1;
    }
    .v-input__details {
      flex: none;
    }
  }
}
</style>
