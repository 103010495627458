<template>
  <v-sheet
      v-show="cOpen"
      ref="overlay"
      rounded="false"
      class="detail-content"
  >
    <eins-notification-list
        v-if="cOpen"
        class="fill-height"
        :notifications="cNotifications"
        style="overflow: visible;"
    />
  </v-sheet>
</template>

<script>
import EinsNotificationList from '@/components/notification/EinsNotificationList'

export default {
  name: 'EinsNotificationPane',
  components: {
    EinsNotificationList
  },
  data: () => ({
    size: {
      w: 10,
      h: 10
    }
  }),
  computed: {
    cOpen: {
      get () {
        return this.$store.getters.notificationsPaneOpen
      },
      set (value) {
        this.$store.commit('setNotificationsPaneState', value)
      }
    },
    cNotifications () {
      return (this.$store.getters['players/current/notifications/list'] || [])
        .filter(notification => notification.weight < 3)
    }
  },
  watch: {
    cOpen: {
      handler: 'calcOverlaySize'
    }
  },
  methods: {
    calcOverlaySize () {
      const overlay = this.$refs.overlay
      if (overlay) {
        this.size.w = overlay.$el.clientWidth
        this.size.h = overlay.$el.clientHeight
      } else {
        this.size.w = 10
        this.size.h = 10
      }
    }
  }
}
</script>

<style scoped>

</style>
