export default {
  ViewQuestDetail: {
    theQuest: '@.lower:(terms.quest)',
    theQuestLc: '@.lower:(terms.quest)'
  },
  ViewGroupDetail: {
    theGroup: '@.lower:(terms.group)',
    theGroupLc: '@.lower:(terms.group)'
  },
  ViewChatDetail: {
    theChat: '@.lower:(terms.chat)',
    theChatLc: '@.lower:(terms.chat)'
  },
  ViewPlayerDetail: {
    thePlayer: '@.lower:(terms.player)',
    thePlayerLc: '@.lower:(terms.player)'
  },
  ViewDealDetail: {
    theDeal: '@.lower:(terms.deal)',
    theDealLc: '@.lower:(terms.deal)'
  },
  ViewPlayerDeals: {
    textFilter: {
      label: '@.lower:(terms.player) @.lower:(terms.deals)'
    }
  },
  ViewPlayerFinishedDeals: {
    textFilter: {
      label: 'Getauschte @.lower:(terms.player) @.lower:(terms.deals)'
    }
  },
  ViewPlayerQuestClosed: {
    textFilter: {
      label: 'Gemeisterte @.lower:(terms.player) @.lower:(terms.quests)'
    }
  },
  ViewPlayerQuests: {
    textFilter: {
      label: ' @.lower:(terms.player) @.lower:(terms.quests)'
    }
  },
  ViewPlayerGroups: {
    textFilter: {
      label: '@.lower:(terms.player) @.lower:(terms.groups)'
    }
  },
  ViewPlayerContacts: {
    textFilter: {
      label: 'Meine Kontakte'
    }
  },
  ViewPlayerBlockages: {
    textFilter: {
      label: 'Meine blockierten @.lower:(terms.players)'
    }
  },
  ViewQuestApplications: {
    textFilter: {
      label: '@.lower:(terms.quest) Bewerbungen'
    }
  },
  ViewGroupDeals: {
    textFilter: {
      label: 'Suche @.lower:(terms.group) @.lower:(terms.deals)'
    }
  },
  ViewGroupFinishedDeals: {
    textFilter: {
      label: 'Getauschte @.lower:(terms.group) @.lower:(terms.deals)'
    }
  },
  ViewGroupApplications: {
    textFilter: {
      label: '@.lower:(terms.group) Bewerbungen'
    }
  },
  ViewDealApplications: {
    textFilter: {
      label: 'Bewerbungen für diesen @.lower:(terms.deal)'
    }
  },
  ViewGroupMembers: {
    textFilter: {
      label: 'Mitglieder des @.lower:(terms.group)'
    }
  },
  ViewGroupQuests: {
    textFilter: {
      label: '@.lower:(terms.quests) von diesem @.lower:(terms.group)'
    }
  },
  ViewGroupFinishedQuests: {
    textFilter: {
      label: 'Gemeisterte @.lower:(terms.quests) des @.lower:(terms.group)'
    }
  },
  ViewQuestCreate: {
    captions: {
      private: '@.lower:(terms.private) @.lower:(terms.quest)',
      common: 'Erstelle @.lower:(terms.common) @.lower:(terms.quest)',
      nature: 'Erstelle @.lower:(terms.nature) @.lower:(terms.quest)'
    },
    buttons: {
      submit: {
        label: 'Senden @.lower:(terms.quest)'
      },
      cancel: {
        label: 'Abbrechen'
      },
      back: {
        label: 'zurück'
      }
    },
    alerts: {
      cancel: {
        caption: 'Du willst abbrechen?',
        text: 'Alle nicht gespeicherten Daten gehen verloren.'
      }
    }
  },
  ViewMyProfile: {
    caption: 'Aktuallisierte Dein Profil'
  },
  ViewDealCreate: {
    captions: {
      caption: 'Erstelle deinen @.lower:(terms.deal)',
      captionService: 'Erstelle den @.lower:(terms.service)',
      captionProduct: 'Erstelle das @.lower:(terms.product)'
    },
    buttons: {
      submit: {
        label: '@.lower:(terms.deal) anbeiten'
      },
      cancel: {
        label: 'Abbrechen'
      },
      back: {
        label: 'zurück'
      }
    },
    alerts: {
      cancel: {
        caption: 'Bist Du sicher?',
        text: 'Alle nicht gespeicherten Daten gehen verloren.'
      }
    }
  },
  ViewDealEdit: {
    captions: {
      service: 'Bearbeiten @.lower:(terms.service)',
      product: 'Bearbeiten @.lower:(terms.product)'
    }
  },
  ViewGroupCreate: {
    buttons: {
      back: {
        label: 'Zurück'
      },
      cancel: {
        label: 'Abbruch @.lower:(terms.group) gründen'
      },
      choose: {
        label: 'Daten hinzufügen'
      },
      finish: {
        label: 'Abschließen'
      },
      submit: {
        label: 'Erstellen'
      },
      request: {
        label: 'Nachfrage'
      }
    }
  },
  ViewGroupEdit: {
    captions: {
      caption: 'Bearbeite Deine @.lower:(terms.group)'
    },
    info: {
      title: 'Dieine @.lower:(terms.group) wurde erstellt.',
      text: 'Auf dieser Seite können alle in der App sichtbaren @.lower:(terms.group) Daten bearbeitet und angepasst werden.\n' +
        '\n' +
        'Vervollständige einfach die @.lower:(terms.group)-Daten jetzt oder später. Je besser die @.lower:(terms.group) beschrieben ist, desto interessanter ist sie.\n' +
        '\n' +
        'Der Standort wird in der App angezeigt und sollte dort sein, wo sich das @.lower:(terms.group) befindet.'
    }
  },
  ViewGroupVerify: {
    captions: {
      caption: 'RECHTSFORM verifizieren'
    },
    info: {
      text: 'Wenn Ihr  @.lower:(terms.group) eine juristische Person darstellt, muss es verifiziert werden.' +
          '<br>' +
          'Juristische Personen sind beispielsweise:' +
          '<br>' +
          '- Unternehmen' +
          '<br>' +
          '- gemeinnützige Vereine' +
          '<br>' +
          '- Gemeinden/Behörden' +
          '<br>' +
          '- Schulen & Universitäten' +
          '<br>' +
          '- Verbände / Kammern' +
          '<br><br>' +
          'Bürgerinitiativen oder @.lower:(terms.groups) für gemeinsame Hobbies' +
          '<br>' +
          'müssen nicht in 1APP verifiziert werden.'
    },
    fields: {
      eMail: {
        hint: 'Diese @.lower:(terms.group) eMail sollte verifiziert werden, um die @.lower:(terms.group) zu starten. Und wird nach Verifizierung zur Kommunikation mit der icigai1 GmbH verwendet.',
        placeholder: 'Deine eindeutige E-Mail-Adresse für die @.lower:(terms.group)',
        label: 'Füge die Bestätigungs-E-Mail-Adresse @.lower:(terms.group) hinzu'
      },
      contact: {
        label: '@.lower:(terms.group) Kontaktperson hinzufügen',
        placeholder: 'Der Name des Ansprechpartners für die icigai1 GmbH',
        hint: 'Der Ansprechpartner für Rückfragen und zur Kommunikation mit der icigai1 GmbH'
      }
    },
    button: {
      submit: 'VERIFIZIERUNGSPROZESS STARTEN',
      info: 'INFORMATION',
      back: 'Zurück zur @.lower:(terms.group) Seite'
    },
    success: {
      title: 'Erfolg',
      text: 'TDie Daten wurden erfolgreich gespeichert!'
    }
  },
  ViewQuestEdit: {
    captions: {
      private: '@.lower:(terms.private) @.lower:(terms.quest) anpassen',
      common: '@.lower:(terms.common) @.lower:(terms.quest) anpassen',
      nature: '@.lower:(terms.future) @.lower:(terms.quest) anpassen'
    }
  },
  ViewTimeBalances: {
    caption: 'Verfügbare @.proper:(terms.stars)',
    year: 'Jahre',
    player: 'Alle 1-@.proper:(terms.players)',
    my: 'Mein 1-@.proper:(terms.player)',
    day: 'Tage',
    star: '@.proper:(terms.stars)',
    point: '@.proper:(terms.points)'
  },
  TheMessagingPermissionDialog: {
    PushNotifications: {
      title: 'Mitteilungen',
      text: 'Zulassen, dass wir Push-Benachrichtigungen senden, wenn jemand eine neue Nachricht sendet?',
      remember: 'Erinnere dich an meine Wahl',
      request: 'Token konnte nicht abgerufen werden!',
      permission: 'Berechtigung abgelehnt, Einstellungen prüfen!',
      store: 'Das Token konnte nicht gespeichert werden!',
      allow: 'Erlauben',
      notallow: 'Ablehnen'
    }
  },
  ViewInformations: {
    button: {
      agree: 'einverstanden',
      disagree: 'ablehnen'
    },
    checkDataProtectionRegulations: {
      label: 'Datenschutzbestimmungen',
      dialog: {
        title: 'Bitte lesen und akzeptieren Sie die Datenschutzbestimmungen'
      }
    },
    acceptAll: {
      label: 'Akzeptieren'
    },
    caption: 'Informationen zu 1APP',
    dataProtection: {
      activator: {
        label: 'Datenschutz'
      },
      dialog: {
        title: 'Datenschutz',
        text: 'Grundlegendes\n' +
            'Diese Datenschutzerklärung dient der Information von Kunden und Nutzern der Angebote der icigai1 GmbH\n' +
            'Art, Umfang und Zweck der Erhebung und Verwendung personenbezogener Daten\n' +
            'informiert von "icigai1 GmbH".\n' +
            'Grundsätzlich hat die icigai1 GmbH den Betrieb der App "1" an einen Dienstleister\n' +
            'aushändigen. Das icigai1 hat die App konzipiert und programmiert - unser Dienstleister XGE1\n' +
                'UG betreibt die App und ist spezialisiert auf Videospiele und Webanwendungen\n' +
            'Host.\n' +
            'Es ist vertraglich vereinbart, dass die XGE1 UG die Daten verwendet, um\n' +
            '1. XGE1 UG nimmt in unserem Auftrag die Spieleingaben und Daten von App „1“ entgegen\n' +
            'gegen und verwendet sie, um das Spiel "1" am Laufen zu halten .\n' +
            '2. XGE1 UG verwendet die Daten des Spiels,\n' +
            'A. um das Gameplay zu analysieren\n' +
            'B. um das Gameplay zu verbessern\n' +
            'C. um  @.lower:(terms.group)er für Lizenzinhaber (juristische Personen) auszuwerten\n' +
            'bereitstellen\n' +
            'ich. Dabei werden keine Daten über den üblichen Spielverlauf hinaus erhoben\n' +
            'angehoben\n' +
            'ii. Auswertungen beziehen sich nur auf die Daten, die ein einzelner  @.lower:(terms.player)\n' +
             'auch einzeln auf dem  @.lower:(terms.group) zu sehen hat\n' +
             'iii. Diese öffentlich sichtbaren Daten werden auf Anfrage des Kunden zur Verfügung gestellt\n' +
            'nach bestimmten Aspekten zusammengefasst und bewertet\n' +
            'Zur Leistung der XGE1 UG für die icigai1 GmbH gehört auch, dass die\n' +
            'XGE1 UG ist für den eigentlichen Spielbetrieb und die Sicherung der\n' +
            'verantwortlichen Daten übernehmen.\n' +
             'Die icigai1 GmbH erhält von der XGE1 UG den laufenden Spielbetrieb\n' +
            'unser berechtigtes Interesse (siehe Art. 6 Abs. 1 lit. f. DSGVO) nur notwendige Daten\n' +
            'sind zur Erfüllung der vertraglich vereinbarten Leistungen für unsere Kunden.\n' +
            '\n' +
            'icigai1GmbH nimmt Ihren Datenschutz sehr ernst und behandelt ihn\n' +
            'personenbezogene Daten vertraulich und entsprechend der gesetzlichen Vorschriften.\n' +
            'Wir speichern alle Daten unserer Kunden an einem geheimen Ort, an dem von\n' +
            'Aus Sicherheitsgründen dürfen nur die Mitarbeiter der icigai1 GmbH auf diese Daten zugreifen\n' +
            'müssen zum Beispiel Support oder Abrechnung für unsere Kunden durchführen.\n' +
             '„Dieser Zugriff unterliegt einer ständigen Managementkontrolle. Zur Sicherheit\n' +
            'und um den permanenten Zugriff auf diese sensiblen Daten zu gewährleisten, beauftragt die\n' +
            'Die icigai1 GmbH beauftragt regelmäßig externe Dienstleister und Fachleute, die unsere\n' +
            'nutzen Sicherheitssysteme testen, um Schwachstellen zu identifizieren und zu beheben.\n' +
            'Aufgrund neuer Technologien und der ständigen Weiterentwicklung unserer Angebote,\n' +
             '„Diese Datenschutzerklärung muss laufend angepasst werden. Es sollte hier\n' +
             'Bei Änderungen müssen Sie der Datenschutzerklärung erneut zustimmen. Dies\n' +
            'erfolgt nicht automatisch, Sie werden in diesem Fall von uns entsprechend informiert.\n' +
              'Definitionen verwendeter Begriffe (z. B. "personenbezogene Daten" oder "Verarbeitung")\n' +
             '„Sie finden sich in Art. 4 DSGVO.\n' +
            '\n' +
            'Zugangsdaten\n' +
            'Wir, die icigai1 GmbH, erheben Daten aufgrund unseres berechtigten Interesses (siehe Art.\n' +
            '6 Abs. 1 lit. F. DSGVO) Daten über Zugriffe und Eingaben auf die App und speichern diese als „Kunden-Logfiles“ auf dem App-Server.\n' +
            'Die folgenden Daten werden bei der Nutzung unseres Online-Angebots automatisiert\n' +
            'angemeldet:\n' +
            '• Besuchte Seiten/Unterseiten\n' +
            '• Zugriffszeit\n' +
            '• Menge der gesendeten Daten in Bytes\n' +
            '• Verwendeter Browser/Framework\n' +
            '• Verwendetes Betriebssystem\n' +
            '• Verwendete IP-Adresse\n' +
            '\n' +
            'Diese Logfiles werden für maximal 60 Tage gespeichert und danach gelöscht. the\n' +
            'Die Daten werden aus Sicherheitsgründen gespeichert, z.B. B. abu-Fälle klärense\n' +
            'Wenn Daten aus Beweisgründen aufbewahrt werden müssen, sind sie esprotected from the\n' +
            'Löschung, außer bis der Vorfall endgültig gelöst ist.\n' +
            '\n' +
            'Reichweitenmessung & Cookies\n' +
            'Die App „1“ verwendet Cookies zur pseudonymisierten Reichweitenmessung, die zither\n' +
            'von unserem Server oder dem Server Dritter an die Nutzung übermitteltr\'s browser\n' +
            'Wille. Cookies sind kleine Dateien, die auf Ihrem Endgerät gespeichert werden\n' +
            'gerettet werden. Ihr Gerät greift auf diese Dateien zu. Durch die Verwendung von Cookieses\n' +
            'erhöht die Benutzerfreundlichkeit und Sicherheit dieser Website.\n' +
            'Gängige Geräte bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Icht is\n' +
            'garantiert nicht, dass Sie auf alle Funktionen von t zugreifen könnenhis website without restrictions\n' +
            'zugreifen, wenn Sie die entsprechenden Einstellungen vornehmen.\n' +
            '\n' +
            'Erhebung und Verarbeitung personenbezogener Daten\n' +
            'Die icigai1 GmbH erhebt, nutzt und gibt Ihre personenbezogenen Daten nur weiter\n' +
            'wenn dies gesetzlich erlaubt ist oder Sie in die Datenerhebung einwilligenta. as\n' +
            'Personenbezogene Daten sind alle Informationen, die dazu dienen, Ihre Person zu identifizierenerson\n' +
            'zu bestimmen und welche zu Ihnen zurückverfolgt werden können - beispielsweise Ihre\n' +
            'Name, Ihre E-Mail-Adresse und Telefonnummer.\n' +
            'Sie können unsere Online-Angebote/Webseiten auch besuchen, ohne eine information about your identity\n' +
            'Mensch zu machen. Wir speichern jedoch, um unser Online-Angebot zu verbessern\n' +
            '(ohne direkten Personenbezug) Ihre Zugriffsdaten auf diese Website. To this\n' +
            'Zu den Zugriffsdaten gehören z.B. B. die von Ihnen angeforderte Datei oder Ihr Namer Internet\n' +
            'Anbieter. Durch die Anonymisierung der Daten sind Rückschlüsse auf Sie möglichur person are not possible\n' +
            'nichts anderes möglich .\n' +
            '\n' +
            '\n' +
            'Umgang mit Kontaktinformationen\n' +
            'Nehmen Sie mit uns als Websitebetreiber über die angebotene Kontaktmöglichkeit opti aufons\n' +
            'Verbindung werden Ihre Angaben gespeichert, damit diese verarbeitet werden könnenand processed\n' +
            'Beantwortung Ihrer Anfrage verwendet werden. ohne Ihre Zustimmung\n' +
            'diese Daten werden nicht an Dritte weitergegeben.\n' +
            'Kontaktdaten werden solange gespeichert, wie der Vertrag besteht.\n' +
            'Eine 1App-Feldlizenz wird immer für die Dauer von 12 Monaten abgeschlossen. Comes\n' +
            'es gibt keine Kündigung, diese Lizenz verlängert sich stillschweigend nach the expiry of the 12th\n' +
            'Monate um weitere 12 Monate. Zu Beginn der neuen 12-monatigen license period\n' +
            'wird eine neue Lizenzgebühr erhoben. Dazu verwenden wir die Daten von\n' +
            'Lizenzinhaber und die Kontaktperson, um Rechnungen erneut auszustellen und\n' +
            'Um Zahlungen verarbeiten/zuordnen zu können.\n' +
            'Löschung von Kontaktdaten\n' +
            'Kündigt ein Kunde seine Lizenz zum Ende der 12-Monats-Kontract period, the\n' +
            'Daten werden nach 8 Wochen aus unserem System gelöscht.\n' +
            'Während dieser Zeit hat ein Kunde jederzeit das Recht\n' +
            '1. Fordern Sie die sofortige Löschung der Daten an\n' +
            '2. Die verlustfreie Reaktivierung seiner Lizenz - sowie seiner Lizenzdata\n' +
            'beantragen\n' +
            'Die icigai1 GmbH behält sich das Recht vor, Lizenzen wegen grober Viola zu entziehentions of the\n' +
            'Spielregeln und in diesem Zusammenhang auch  @.lower:(terms.group)er und Daten des Kunden abbrechenrs\n' +
            'aus der App zu entfernen.\n' +
            '\n' +
            '\n' +
            'Benutzerrechte\n' +
            'Als Benutzer haben Sie das Recht, kostenlose Informationen über sich anzufordern\n' +
            'welche personenbezogenen Daten über Sie gespeichert wurden. Du hast auch dieses\n' +
            'Recht auf Berichtigung unrichtiger Daten und auf Ihre personenbezogenen Datena erased\n' +
            'Daten. Gegebenenfalls können Sie auch Ihr Recht auf Datenübertragbarkeit geltend machenlity .\n' +
            'Wenn Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie\n' +
            'support@icigai1.de jederzeit und/oder eine Beschwerde bei der competent authority\n' +
            'der Aufsichtsbehörde vorlegen.\n' +
            'Selbstinitiiertes Löschen von Daten\n' +
            'Sofern Ihre Anfrage nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z.g.\n' +
            'Vorratsdatenspeicherung) kollidieren, haben Sie das Recht auf Löschung Ihrer Datend\n' +
            'persönliche Daten. Wenn das Löschen nicht durchgeführt werden kann, weil die\n' +
            'Soweit Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkungction of the\n' +
            'Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht verwendet for other purposes\n' +
            'verarbeitet.\n' +
            'Widerspruchsrecht\n' +
            'Nutzer dieser Website können von ihrem Widerspruchsrecht Gebrauch machen und\n' +
            'der Verarbeitung Ihrer personenbezogenen Daten jederzeit widersprechen.\n' +
            'Wenn Sie eine Korrektur wünschen, blockieren Sier persönlich Mailadresse\n' +
            '\n' +
            'Jena, July 6, 2022\n' +
            '',
        buttons: 'ok'
      }
    },
    dataProcessing: {
      activator: {
        label: 'Datenverarbeitung'
      },
      label: 'Datenverarbeitungsinformationen',
      dialog: {
        title: 'Datenverarbeitung',
        caption: 'Bitte lese und akzeptiere die Informationen zur Datenverarbeitung',
        text: 'Dokumentation der Verarbeitungstätigkeit für das Spiel "1APP"\n' +
            'Angaben zum Verantwortlichen\n' +
            '\n' +
            'Verantwortliche Stelle (gem. Art. 4 Nr. 7 DSGVO)\n' +
            'XGE1 UG, Frohngasse 1a, 07745 Jena\n' +
            '\n' +
            'Gesetzlicher Vertreter (= Geschäftsführung)\n' +
            'Michael Dietz, Frongasse 1, 07745 Jena | 015787234290\n' +
            '\n' +
            'Datenschutzbeauftragter\n' +
            '„Michael Dietz, Rudolstädterstr. 68a, 07745 Jena | 015787234290\n' +
            'Grundlegende Informationen zur Verarbeitung\n' +
            '\n' +
            'Bezeichnung der Verarbeitungstätigkeit:\n' +
            'Bereitstellung und Auswertung der 1 Spieldaten\n' +
            '\n' +
            'Zuständiger Ansprechpartner (inkl. Fachabteilung, Telefonnummer und E-Mail-Adresse):\n' +
             '„Michael Dietz, Rudolstädterstr. 68a, 07745 Jena | 015787234290\n' +
            '\n' +
            'Status:\n' +
            '-\tAnwendung aktiv verfügbar\n' +
            '\n' +
            'Art der Verarbeitung:\n' +
            '-\tHosting, Bereitstellung und Auswertung des Spiels "1"\n' +
            ' \n' +
            'Allgemeine Datenschutzanforderungen DSGVO\n' +
            '\n' +
            'Verwendungszweck\n' +
            'Verarbeitungstätigkeit "Auswertung"\n' +
            'Verfolgen Sie den Zweck von "1" und verbessern Sie seine Wirkung in der Realität weiter\n' +
            '\n' +
            '„Rechtmäßigkeit der Verarbeitung, Art. 6 DSGVO\n' +
            'Kunst. 6 abs. 1 lit. a, Kunst. 7\n' +
            '\n' +
            '„Besteht ein hohes Risiko für die Rechte und Freiheiten natürlicher Personen nach Art. 35?\n' +
            'Die eigene E-Mail-Adresse wird als einzige direkt personenbezogene Information nur dazu verwendet, die Identität des  @.lower:(terms.player)s beim Einloggen zweifelsfrei festzustellen. Im Spiel werden keine personenbezogenen Daten abgefragt, d.h. auch keine eingesehen/erfasst. Die Erfassung und Veröffentlichung von Informationen in 1 erfolgt vollkommen freiwillig und nur durch die  @.lower:(terms.player) von 1. Der Personenbezug kann nicht durch  @.lower:(terms.player) oder Außenstehende innerhalb des Spiels erfolgen. Aus heutiger Sicht ist das Risiko als gering einzuschätzen.\n' +
            '\n' +
            'Sammlung der Daten\n' +
            'betroffener Personenkreis\n' +
            'Spieler in 1\n' +
            '\n' +
            'Art der gespeicherten Daten oder Datenkategorien:\n' +
            'Spieldaten von 1\n' +
            '\n' +
            'Herkunft der Daten\n' +
            'Freiwillige Bereitstellung bei Registrierung und während des Spiels durch die  @.lower:(terms.player) von 1\n' +
            '„Empfänger oder Kategorien von Empfängern, denen die Daten mitgeteilt werden können\n“' +
            '\n' +
            'Interne Empfänger (innerhalb der verantwortlichen Stelle)\n' +
            '-\tAbteilung "1-Spiel Datenauswertung"\n' +
            '-\tAbteilung "1-Simulation"\n' +
            '-\tAbteilung "1-Forschung"\n' +
            '\n' +
            'Externe Empfänger und Dritte:\n' +
            '-\ticigai1 GmbH für Kundendaten von Lizenznehmern\n' +
            '\n' +
            'Standardfristen für das Löschen der Daten\n' +
            'Speicherdauer\n' +
            '-\tActive Player Credentials: Persistent (nichts wird automatisch gelöscht)\n' +
            '-\tInaktive  @.lower:(terms.player)anmeldungen: Löschung nach 730 Tagen\n' +
            '-\tLogindaten von ausgeloggten  @.lower:(terms.player)n: Löschung nach 30 Tagen\n' +
            '\n' +
            '-\tSpieldaten selbst werden auf unbestimmte Zeit aufbewahrt und nicht gelöscht\n' +
            '„Angemessenheitsprüfung techn. und org. Maßnahmen (TOM)\n' +
            '\n' +
            '„Allgemeine Beschreibung der technischen und organisatorischen Maßnahmen (Art. 30 Abs. 1 lit. g, Art. 32 Abs. 1 DSGVO)\n“' +
            '\n' +
            '-\tDa wir und die  @.lower:(terms.player) davon nicht profitieren konnten, fragen wir nicht nach echten Vor- und Nachnamen.\n' +
            '-\tE-Mail-Adresse wird nicht an Spiel "1" übertragen.\n' +
            '-\tSomit sind alle  @.lower:(terms.player) in "1" pseudonymisiert und können nicht auf eine Person zurückgeführt werden\n' +
            '-\tSpieldaten sind in unseren Datenbanken verschlüsselt, sodass selbst Personen, die an unseren Datenbanken arbeiten, keine Spielinhalte lesen können\n' +
            '-\tWenn unsere Datenbanken gehackt / Daten gestohlen werden, können Außenstehende das System nicht entschlüsseln, da die Daten in verschlüsselter Form nicht lesbar sind.\n' +
            '-\tDas System mit Datenbanken unterliegt automatisierten Prüfmechanismen, die alle 5 Minuten die Funktionsfähigkeit der Sicherheitsmechanismen testen.\n' +
            '-\tBei Ausfällen (Hackerangriffe, Überlast etc.) wird ein Systemalarm ausgelöst, der die Verantwortlichen umgehend per Smartphone informiert\n' +
            '-\tIn Ausnahmefällen kann die Datenbank sofort in einen Schutzmodus versetzt werden, der die Erreichbarkeit des Servers deaktiviert\n' +
            '\n' +
            'Freiwillige Angaben\n' +
            '\n' +
            'Die Datenverarbeitung dient dem Zweck des Spiels.\n' +
            '\n' +
            '1 funktioniert als Spiel durch die gegenseitige, kooperative Interaktion der  @.lower:(terms.player) in einem handlungsbezogenen Kontext. Kooperation besteht aus einer Abfolge von Aktion und Reaktion, die in „1“.  @.lower:(terms.player)isch in einem virtuellen Raum umgesetzt wird \n' +
            '\n' +
            'Alle von den  @.lower:(terms.player)n in 1 eingegebenen Informationen werden verwendet, um die Chancen einer regelmäßigen Spielteilnahme innerhalb des Spiels im Hinblick auf das Spielziel zu erhöhen.\n' +
            '\n' +
            'Zu diesem Zweck werden gemäß den 1App-Regeln Vorgaben für Inhalte im Spiel für  @.lower:(terms.player) so erlassen, dass sie einen für  @.lower:(terms.player) nachvollziehbaren und sinnvollen Zusammenhang im Hinblick auf das Spielziel ergeben.\n' +
            '\n' +
             '„Die durch freiwillige Angaben etwa durch „Künstliche Intelligenz“ zustande gekommene Art der spielspezifischen Nutzung von Spieldaten, Algorithmen oder Datenbankerweiterungen und deren Ausgabe/Nutzung für das Spiel werden laufend weiterentwickelt und unterliegen dem Produktgeheimnis. \n' +
            '\n' +
            'Neben der Verwendung freiwilliger Angaben für den Spielablauf kann es in besonderen Fällen auch vorkommen, dass Beamte / Polizei / Staatsanwaltschaft o.ä. auf uns zukommen, um uns um Hilfe bei der Verfolgung von Straftaten zu bitten.\n' +
            '\n' +
             '„Das Wohl und die Freiheit der Menschen steht – auch bei uns – über allem. Wenn wir daher bei der Rechtsverfolgung behilflich sein können, werden wir\n' +
            'ein Amtsträger oder Datenschutzbeauftragter Zugriff auf unsere Systeme in einem Umfang, der für die Erfüllung der Angelegenheit erforderlich ist, um Straftaten oder Einschränkungen der Rechte und Freiheiten natürlicher Personen abzuwenden.\n' +
            '\n' +
             '„Eine solche Zugangsgewährung kann schriftlich bei uns beantragt werden. Der Zugang wird nur im Einzelfall gewährt. Externe Dritte haben keinen dauerhaften Zugriff auf die Daten.\n' +
            '\n' +
            'Stellungnahme des Datenschutzbeauftragten\n' +
            '\n' +
            'Prüfung durch den Datenschutzbeauftragten\n' +
            'Findet am 19.05.2021 statt\n' +
            'Besteht weiterer Handlungsbedarf?\n' +
            'Vorerst nicht\n' +
            'Maßnahmen öffnen\n' +
            'nein\n' +
            'Dokumentationsdatum\n' +
            '19.05.2022\n' +
            'Bewertung durch Management\n' +
            'Findet am 19.05.2022 statt\n' +
            'Datum, Unterschrift\n' +
            '\n“\n' +
            '\n',
        button: 'ok'
      }
    },
    gamePlayRules: {
      activator: {
        label: 'Spielregeln (EULA)'
      },
      checkDataPlayerRules: {
        label: '1APP-Spielregeln (EULA)'
      },
      dialog: {
        title: 'Spielregeln (EULA)',
        caption: 'Bitte lese und akzeptiere die 1APP-Spielregeln (EULA)',
        text: '1APP - Spielregeln\n' +
            'Spielregeln 1 app\n' +
            'Prosa\n' +
            '\n' +
            '1APP zielt darauf ab, die Welt zu einem besseren Ort zu machen.\n' +
            'Wir verfolgen keine politischen Ansichten oder Gewinnvorstellungen.\n' +
            'Menschen sollten als Menschen mit anderen Menschen spielen, sich gegenseitig herausfordern und die Welt Stück für Stück ein bisschen besser machen.\n' +
            'Du kannst Herausforderungen von anderen  @.lower:(terms.player)n erfüllen und umgekehrt auch Herausforderungen für andere  @.lower:(terms.player) frei erstellen.\n' +
            'Grundsätzlich sind wir davon überzeugt, dass es eine bessere Welt nur geben kann, wenn alle Menschen Spaß haben, gleichberechtigt sind, sich beteiligen können, einander respektieren und zusammenarbeiten.\n' +
            'Richtig, du spielst 1APP, wenn du rausgehst, meisterst Herausforderungen an vielen verschiedenen Orten und erstellst auch selbst Herausforderungen für andere.\n' +
            'Alles, was Ihrer Meinung nach Spaß macht oder dazu beiträgt, eine bessere Welt zu schaffen, kann in 1APP zu einem  @.lower:(terms.group), einer Herausforderung oder einem Geschäft werden.\n' +
            'Alles, was anderen Menschen oder der Natur schadet, gehört nicht in 1APP.\n' +
            'Deshalb respektieren Sie andere  @.lower:(terms.player), ihre Eigentums- und Persönlichkeitsrechte und arbeiten Sie mit und nicht gegen andere  @.lower:(terms.player).\n' +
            'In 1APP gibt es zwar keine Strafen, aber wenn Sie 1APP verwenden, um anderen  @.lower:(terms.player)n Schaden zuzufügen, können Sie im Spiel öffentlich gewarnt und sogar gesperrt werden.\n' +
            'In 1APP gehört alles, was Menschen legal tun sollten, getan haben und anbieten können. Demonstrationen, Religion und Politik gehören nicht in 1APP. Parteien, politische Aktionen oder Wahlkämpfe sind daher vom Spiel ausgeschlossen.\n' +
            'Natürlich ist auch alles, was in deinem Land illegal ist, vom Spiel ausgeschlossen.\n' +
             '„Da viele unserer  @.lower:(terms.player) nicht volljährig sind, ist alles, was nicht für Minderjährige geeignet ist, in 1APP generell verboten. Dazu gehören Bilder, Texte und Beschreibungen – vor allem aber Alkohol, Drogen aller Art und Rauschmittel, die unter die Jugendschutzbestimmungen fallen. Koffein ist die zulässige Obergrenze.\n' +
            'Zuletzt: Wir spielen 1APP mit Punkten und Sternen statt mit Geld. Herausforderungen und Angebote, die Sie anbieten, erhalten ihren Wert in Punkten. Alles, was mit Geld zu tun hat, findet außerhalb von 1APP statt. Das Veröffentlichen oder Akzeptieren eines Angebots oder das Abschließen einer Herausforderung verleiht im Spiel keine Rechte auf Barzahlungen.\n' +
            '„Last but not least: Wir sind kein gewöhnliches soziales Netzwerk, das stillschweigend zuschaut, was darauf passiert. Wir beschäftigen Content Manager, die alle Inhalte regelmäßig prüfen (müssen). Jeder Verdacht auf ein Verbrechen, jede Beleidigung und jeder Inhalt, den Sie gegen die Spielregeln und/oder gegen das Gesetz Ihres Landes verstoßen, wird den Behörden gemeldet.\n' +
            '\n' +
            '\n' +
            'Eigene Profilseite\n' +
            '\n' +
            'Um 1APP spielen zu können, müssen Sie 1APP aus Ihrem Google Play Store oder Apple i-Store herunterladen.\n' +
            '„Wir erstellen Ihr  @.lower:(terms.player)konto für Sie. Diese können Sie auf Ihrer Profilseite nach Ihren Wünschen anpassen.\n' +
            'Wenn Sie ein Profilbild ändern, befolgen Sie unsere Spielregeln und verwenden Sie keine Bilder, die Ihnen nicht gehören.\n' +
            'Dein Profilbild muss nicht von dir sein, aber es sollte auch nicht von anderen Personen sein.\n' +
            '„Du kannst auch einen Text schreiben, in dem du etwas über dich erzählst. Es ist völlig in Ordnung, sich selbst als den besten, hübschesten und klügsten  @.lower:(terms.player) zu bezeichnen, aber es ist verboten, hier irgendetwas Beleidigendes oder Herabwürdigendes über andere zu schreiben.\n' +
            'andere  @.lower:(terms.player)\n' +
            '\n' +
            '1APP ist ein Online-Multiplayer-Rollenspiel. Das bedeutet, dass auch alle anderen Spiele von Leuten wie dir kontrolliert werden.\n' +
            'Also sei immer nett!😊 \n' +
            'Belästige andere  @.lower:(terms.player) nicht.\n' +
            '\n' +
            'Felder\n' +
            '\n' +
            'Felder sind öffentliche Plätze und Plätze, aber auch Unternehmen, Vereine und Einrichtungen der öffentlichen Verwaltung.\n' +
            '„Felder sind da, um Herausforderungen und Geschäfte zu platzieren. Andere  @.lower:(terms.player), die an diesem  @.lower:(terms.group) interessiert sind, können dann alle Herausforderungen und @.lower:(terms.deal)s auf dem  @.lower:(terms.group) sehen.\n' +
            'Falls Sie ein eigenes  @.lower:(terms.group) anlegen, muss Ihr  @.lower:(terms.group) der richtigen Rechtsform entsprechen.\n' +
            'Das bedeutet, dass du zum Beispiel eine Firma als Handelsfeld im Spiel erstellen musst.\n' +
            '1APP bietet dafür jede Art von  @.lower:(terms.group)typ:\n' +
            '-\tFelder ohne Rechtsform\n' +
            '(für öffentliche Plätze, Freundesgruppen,  @.lower:(terms.group)er für Hobbys, Familien)\n' +
            '-\tFirmenfelder\n' +
            '-\tFelder für Clubs\n' +
            '-\tFelder für öffentliche Verwaltungen\n' +
            'Zum Beispiel eine echte Firma als Freiraum im Spiel ohne Rechtsform zu gründen verstößt gegen die Spielregeln.\n' +
            'Sobald Sie ein  @.lower:(terms.group) erstellt haben, können Sie die Informationen im  @.lower:(terms.group) bearbeiten.\n' +
            '-\tWählen Sie ein Bild aus, das Ihnen / Ihrer Firma / Ihrem Verein gehört\n' +
            'o\tWir raten davon ab, Personen oder Gesichter im Bild eines  @.lower:(terms.group)es zu verwenden\n' +
            '-\tIm Text können Sie das  @.lower:(terms.group) beschreiben - also Ihre Firma / Ihren Verein oder Ihr Projekt. Auch hier gilt: Übertrieben schöne Texte über Ihr Fachgebiet sind ok, - es ist verboten, in diesem Text herablassend oder beleidigend über andere zu schreiben.\n' +
            'Danach kannst du einen Punkt auf der Karte auswählen, der zu deinem  @.lower:(terms.group) gehört.\n' +
            '-\tBitte wählen Sie nur Punkte auf der Karte aus, die wirklich mit Ihrem  @.lower:(terms.group) zu tun haben.\n' +
            '-\tPlatzieren Sie die Punkte auf der Karte nicht auf Grundstücken oder Orten, die sich im Privatbesitz anderer befinden\n' +
            'Jedes  @.lower:(terms.group) hat einen zugehörigen  @.lower:(terms.group)nchat, in dem Sie allen Mitgliedern des  @.lower:(terms.group)es gleichzeitig schreiben können.\n' +
            '-\tEs ist verboten, andere  @.lower:(terms.player) in diesen Chats öffentlich zu beleidigen oder herablassend über sie zu schreiben\n' +
            '-\tBitte seien Sie freundlich, wenn etwas nicht geklappt hat oder Sie unzufrieden sind\n' +
            '\n' +
            'Herausforderung\n' +
            '\n' +
            'In Herausforderungen kannst du anderen  @.lower:(terms.player)n eine Aufgabe geben, um sie auf unterschiedliche Weise herauszufordern.\n' +
            '\n' +
            'Zunächst kannst du zwischen 3 Arten von Herausforderungen wählen:\n' +
            '-\tRote Herausforderungen sind Dinge, von denen Sie privat profitieren sollten\n' +
            '-\tnutzen unserer globalen Gesellschaft\n' +
            '-\tGrüne Herausforderungen sind Dinge, die der Natur, der Zukunft und unseren Kindern zugutekommen\n' +
            'Bitte wählen Sie hier immer die richtige Kategorie aus.\n' +
            '\n' +
             '„Im Titel oder in der Beschreibung der @.lower:(terms.quest) dürfen keine illegalen Aktivitäten beschrieben werden. Auch der Inhalt der @.lower:(terms.quest) sollte mit der Grundidee des Spiels „Creating a better world“ vereinbar sein.\n' +
            'Herausforderungen sollten keine Beleidigungen enthalten oder die Leistungen anderer Personen verunglimpfen.\n' +
            'Die Herausforderung sollte so grafisch wie möglich im Bild beschrieben werden.\n' +
            '-\tVerwende nur Bilder, die dir gehören\n' +
            '-\tDas Bild darf keine Persönlichkeitsrechte verletzen\n' +
            '-\tDas Bild darf keine Gewalt, politische Botschaften oder irgendetwas anderes enthalten, das andere  @.lower:(terms.player) verärgern könnte\n' +
            '-\tDie Herausforderung darf nicht gegen geltendes Recht verstoßen\n' +
            'o\tBitte orientieren Sie sich immer an Ihrem gesunden Menschenverstand und den örtlichen Gesetzen und Vorschriften des Landes, in dem Sie 1APP spielen\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Sterne / Punkte / Prämien\n' +
            '\n' +
            'Im Spiel verdienen  @.lower:(terms.player) Punkte und Sterne, indem sie Herausforderungen meistern.\n' +
            'Wie viele Sterne eine Herausforderung enthält, entscheidet der  @.lower:(terms.player), der die Herausforderung erstellt.\n' +
            '„Aufgaben, die jeder problemlos erledigen kann, enthalten einen Stern. Wenn eine Herausforderung länger als eine Stunde dauert, sollten Sie für jede weitere volle Stunde einen weiteren Stern hinzufügen.\n' +
            'Je schwieriger die Aufgabe, desto mehr Sterne sollten Sie pro Stunde hinzufügen.\n' +
            '1 Stern pro Stunde - Leichte Aufgabe\n' +
            '2 Sterne pro Stunde - Aufgaben, die Vorkenntnisse erfordern\n' +
            '3 Sterne pro Stunde - Aufgaben, die Erfahrung erfordern\n' +
            '4 Sterne pro Stunde - Aufgaben, die eine professionelle Schulung erfordern\n' +
            '5 Sterne pro Stunde - Aufgaben mit hohem Schwierigkeitsgrad für Profis\n' +
            '6+ Sterne pro Stunde - Für echte, außergewöhnliche Heldentaten\n' +
            'Beim Erstellen der @.lower:(terms.quest) entscheidest du, wie viele Sterne die @.lower:(terms.quest) verdient. Wir vertrauen Ihnen, andere  @.lower:(terms.player) vertrauen Ihnen. Geben Sie lieber ein paar Sterne zu viel als zu wenig, denn:\n' +
            'Sterne für blaue Herausforderungen stammen von 1 Community-Konto.\n' +
            'Sterne für grüne Herausforderungen stammen vom 1-Future-Konto.\n' +
            'Nur für rote Herausforderungen müssen Sie Ihre eigenen Sterne verwenden.\n' +
            'Sie können unbegrenzt blaue oder grüne Herausforderungen festlegen\n' +
            '\n' +
            '\n' +
            '„Ein Stern enthält 60 Punkte. 24 Sterne werden als weißer Stern dargestellt. 365 weiße Sterne werden als schwarze Sterne angezeigt.\n' +
            '„Im Gegenzug enthalten @.lower:(terms.deal)s auch Punkte. Wenn Sie also in Herausforderungen Sterne verdient haben, können Sie diese gegen Angebote eintauschen.\n' +
            'Angebote werden in Punkten beschrieben.\n' +
            '1 Punkt entspricht 5 € Kaufkraft im Jahr 2020.\n' +
            '\n' +
            'Angebot\n' +
            'Angebote sind Angebote, die Sie entweder als  @.lower:(terms.group) oder als  @.lower:(terms.player) abgeben können.\n' +
            '\n' +
            '„Ein @.lower:(terms.deal)-Bild darf keine Waren oder Dienstleistungen darstellen, die gesetzlich verboten sind. Auch dürfen Bilder von @.lower:(terms.deal)s keine Persönlichkeitsrechte verletzen oder Dinge zeigen, die das sind\n' +
            'Report\n' +
            '\n' +
            '„Wir können nicht alle  @.lower:(terms.player) und Prozesse im Spiel kontrollieren. Um das Spiel im Sinne dieser Regeln zu halten, brauchen wir Ihre Hilfe. Über die Meldefunktion können Sie uns  @.lower:(terms.group)er,  @.lower:(terms.player), @.lower:(terms.quest)s oder @.lower:(terms.deal)s melden, wenn Inhalte gegen die Spielregeln verstoßen. Es wäre nett von Ihnen, wenn Sie uns kurz sagen könnten, was mit diesem Inhalt nicht stimmt, damit wir ihn richtig kategorisieren können. Denken Sie daran: Wir sind weit weg und kennen Ihre lokalen Besonderheiten nicht.\n' +
            'Ernstes Spiel\n' +
            '1 App ist ein Spiel mit ernsthaftem, gesellschaftsrelevantem Hintergrund.\n' +
            '1 Du spielst nicht gegen den Computer, sondern mit anderen Leuten.\n' +
            '1App allein reicht nicht - Sie brauchen andere  @.lower:(terms.player), um 1APP spielen zu können.\n' +
            'Als Gegenleistung für unseren kostenlosen Service, der es Ihnen ermöglicht, viel zu tun, verlangen wir von Ihnen, dass Sie die Regeln befolgen und andere 1APP-Spieler mit Respekt behandeln.\n' +
            '\n' +
            'Distanz \n' +
            'Wir distanzieren uns ausdrücklich von extremistischen Überzeugungen, egal ob links, rechts, liberal, sozialistisch oder kapitalistisch - wir lehnen alles ab, was in irgendeiner Weise dazu dient, Menschen in  @.lower:(terms.group)n einzuteilen, zu verunglimpfen, zu unterdrücken, auszunutzen oder zu benachteiligen.\n ' +
            'Wir lehnen Gewalt, Krieg, Waffen und alles, was in irgendeiner Weise direkt oder indirekt damit zusammenhängt, ab.\n' +
            '„Wir lehnen auch alles ab, was bestimmte Personen in irgendeiner Weise bewertet. Für uns hat jeder Mensch einen festen, unveränderlichen Wert: 1. Alle Inhalte, die in irgendeiner Weise darauf abzielen, diesen Wert einzelner Personen herabzusetzen oder aufzuwerten, verstoßen gegen die Spielregeln. Bei 1APP ist niemand mehr wert als Sie. Sie und alle  @.lower:(terms.player) um Sie herum haben die gleichen Rechte. Es gibt keinen Unterschied zwischen den  @.lower:(terms.player)n.\n' +
            '„Die hier aufgeführten Ge- und Verbote erheben keinen Anspruch auf Vollständigkeit. Wenn etwas gesetzlich verboten ist, verstößt es auch gegen die Spielregeln von 1APP. Wenn Menschen verurteilt oder diskriminiert werden, verstößt das gegen die Spielregeln. Wenn Sie anderen  @.lower:(terms.player)n oder Personen Schaden zufügen, können Sie sich nicht auf das Spiel oder fehlende Regeln darin berufen. Ihr Staat und das für Sie geltende Recht bleiben für Sie die oberste rechtliche Instanz.\n' +
            '„Wir werden wie Facebook und Co. nicht tatenlos zusehen, wenn Spielregeln und/oder Gesetze bewusst verletzt werden. Wir gehen aktiv, schnell und hart gegen alles vor, was unseren  @.lower:(terms.player)n oder anderen Menschen schadet. Wir melden Hass, Hassreden, Gewalt, Hacker, Trolle und extremistische  @.lower:(terms.group)n sofort.\n' +
            'Bitte helfen Sie uns und melden Sie fragwürdige 1APP-Inhalte mit der Nummer des  @.lower:(terms.player)s, des  @.lower:(terms.group)es, der Herausforderung oder des Angebots sofort an Alert@xge1.de .\n' +
            'Zustimmen, teilnehmen, Verbote und Konsequenzen\n' +
            'Wenn Sie "1APP" verwenden, stimmen Sie den Regeln und Funktionen von "1APP" zu. „1APP“ folgt Funktionalitäten und Spielregeln, an die alle  @.lower:(terms.player) gebunden sind. Das Spiel hat ein definiertes Spielziel, auf das alle  @.lower:(terms.player) gemeinsam hinarbeiten.\n' +
            '"1APP" soll die Welt zu einem besseren Ort machen - und zwar nicht nur für unsere  @.lower:(terms.player), sondern auch für alle anderen Menschen und die Natur.\n' +
            '\n' +
            'Rechtsstatus\n' +
            '1APP ist eine virtuelle, digitale "ideale" Welt ohne Geld, Schuld, Unternehmen, Herrscher und Unrecht. Da 1APP jedoch nur ein Spiel ist, sind diese Dinge im wahrsten Sinne des Gesetzes irrelevant.\n' +
            '„Die Teilnahme am Spiel bei 1APP entbindet Sie nicht von den bestehenden rechtlichen Rahmenbedingungen. Ihre üblichen gesetzlichen Rechte und Pflichten bleiben unberührt.\n' +
            '„1APP ist ein Spiel für Menschen. Juristische Personen können sich nicht als  @.lower:(terms.player) in 1APP registrieren – Juristische Personen werden zu  @.lower:(terms.group)ern im Spiel. Somit sind alle  @.lower:(terms.player) in 1APP Privatpersonen.\n' +
            'Das bedeutet, dass 1APP als Spiel innerhalb des deutschen Zivilrechts stattfindet.\n' +
            'Die Organisationen hinter 1APP, icigai1 GmbH und XGE1 UG, distanzieren sich ausdrücklich von allen Inhalten im 1APP-Spiel, mit Ausnahme derjenigen, die wir als  @.lower:(terms.player) selbst zu 1APP bringen.\n' +
            '„Kostenlose Inhalte“ im Sinne von @.lower:(terms.quest)s, @.lower:(terms.deal)s und sonstigen Inhalten, die freiwillig von  @.lower:(terms.player)n gepostet wurden, werden vom Betreiber XGE1 UG (haftungsbeschränkt) nicht beeinflusst oder verändert.\n' +
            'Daher können wir Inhalte und ein tatsächlich profitables, positives und sinnvolles Gameplay nicht garantieren, wie es von uns beabsichtigt ist.\n' +
            'Wir können auch nicht garantieren, dass andere  @.lower:(terms.player) sich immer an die Spielregeln, geltenden Gesetze und Vorschriften halten.\n' +
            'Wir versuchen alle Mittel und Möglichkeiten zu nutzen, um den Inhalt "legal" und konform mit den Spielregeln zu halten.\n' +
            'Dazu blockieren wir Inhalte, die  @.lower:(terms.player) uns jederzeit melden können.\n' +
            'Um dies zu gewährleisten, sind wir auf Ihre Hilfe und die Ihrer Mitspieler angewiesen: Sie können gerne alles melden, was Ihnen merkwürdig vorkommt.\n' +
            '\n' +
            'Haftung\n' +
            'Haftung, Schuld und Strafe gibt es im 1APP-Spiel nicht.\n' +
            '„Bei Schäden, die in der realen Welt eintreten, gelten die gesetzlichen Rahmenbedingungen, z.B. gemäß §823/1 BGB. Die Person, die den Schaden verursacht hat, haftet dafür.\n' +
            'Im Schadensfall, zum Beispiel während oder infolge einer Anfechtung, ist in Deutschland der Verursacher verpflichtet, alles wieder herzustellen, als wäre nie etwas gewesen.\n' +
            'Als Spieleanbieter und -betreiber haften wir nur für von uns verursachte Schäden.\n' +
            'Sie müssen sich gegen Schäden versichern, die im Spiel auftreten.\n' +
            'Bei @.lower:(terms.quest)s in 1APP ist in der Regel auch der Ersteller einer @.lower:(terms.quest) als "Gefahrverursacher" anzusehen, was andere  @.lower:(terms.player) nicht grundsätzlich von der Mithaftung im Schadensfall befreit.\n' +
            'Generell lässt sich sagen, dass jeder, der in der Freizeit das Haus verlässt oder mit anderen Menschen zusammenarbeitet, sich beruflich oder ehrenamtlich engagiert, immer mindestens eine private Haftpflichtversicherung, besser noch eine private Unfallversicherung haben sollte.\n' +
            'Jedes Versicherungsbüro in Ihrer Nähe berät Sie gerne zu diesem Thema.\n' +
            '\n' +
            'Datenschutz \n' +
            'Um Ihre persönlichen, persönlichen Daten zu schützen, sind sie nicht in 1APP enthalten. Das bedeutet, dass Sie beim Einloggen nur noch Ihre wahre Identität bestätigen müssen – und innerhalb von 1APP vollkommen anonym bleiben können. Sie bestätigen nur, dass Sie sich am Eingang des Spiels befinden.\n' +
            '„Wir haben uns entschieden, Ihre Anschrift und Ihren Wohnort nicht als Pflichtangaben bei der Registrierung zu verlangen. Denn wenn wir diese Daten nicht haben, kann sie uns niemand stehlen.\n' +
            '„Sie können sich mit Ihrer E-Mail-Adresse bei 1APP anmelden. Es liegt in Ihrer Verantwortung sicherzustellen, dass Ihr Passwort sicher ist.\n' +
            'Wenn etwas schief geht, Sie Ihr Passwort vergessen oder eine spezielle Aktion in 1APP bestätigen möchten, benötigen Sie Zugriff auf die E-Mail-Adresse, mit der Sie sich in 1APP registriert haben.\n' +
            'Generell werden Ihre personenbezogenen Daten und die dazugehörigen Spieldaten nur dazu verwendet, das Spiel „1APP“ für alle  @.lower:(terms.player) spielbar zu machen. Wir verwenden die Daten für nichts anderes. Wir werden Ihre Daten nicht ohne Ihre Zustimmung weitergeben oder gar verkaufen.\n' +
            'Unsere Datenschutzerklärung und unsere Datenverarbeitungsrichtlinie enthalten nähere Informationen zu diesem Thema.\n' +
            '\n' +
            'Altersfreigabe\n' +
            '1APP ist ohne Altersfreigabe spielbar (FSK 0) Wenn du jünger als 18 bist, solltest du unbedingt (wie bei allen Apps) deine Eltern fragen, ob sie damit einverstanden sind, dass du 1APP spielst. Wir würden uns sehr freuen, wenn du dich schon in jungen Jahren auf @.lower:(terms.quest)s einlassen würdest - aber bitte besprich das sowie alles andere mit deinen Eltern.\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Sichtbarkeit / Datenschutz \n' +
            '\n' +
             '„Beachten Sie, dass alles, was Sie im Spiel posten, von jedem  @.lower:(terms.player) gesehen werden kann. Alle Spieldaten sind für alle  @.lower:(terms.player) sichtbar. Es gibt keine Geheimnisse in 1APP.\n' +
            'Abgeschlossene @.lower:(terms.deal)s sind standardmäßig nur für unsere Administratoren eines  @.lower:(terms.group)es sichtbar. Nur Sie können Angebote sehen, die Sie gekauft haben. Mit einem  @.lower:(terms.group) verbundene Angebote, Chats und Herausforderungen sind nur für Mitglieder des  @.lower:(terms.group)es sichtbar. Da aber jeder  @.lower:(terms.player) in jedem Bereich Mitglied werden kann, musst du damit rechnen, dass potenziell jeder  @.lower:(terms.player) deine Texte sehen kann.\n' +
            '„Wir bitten Sie daher dringend, nur „unkritische“ Informationen in die „1APP“-App einzuspeisen. Sie wissen, was für Sie und andere kritisch ist und was nicht.\n' +
            '„Darüber hinaus sollten Sie keine Informationen in das Spiel eingeben, die die Eigentumsrechte oder Freiheiten Dritter verletzen oder einschränken. Fragwürdige Herausforderungen, die Sie nicht formuliert, aber dann erfüllt haben, landen auf Ihrer Profilseite und bleiben dort dauerhaft. Melden Sie sich also nicht für Herausforderungen an, die Ihrem Ruf im Spiel/in der Gesellschaft schaden könnten.\n' +
            '-\tBitte posten Sie keine Fotos von Personen, die dies nicht ausdrücklich verlangen und Ihnen ihre Zustimmung gegeben haben\n' +
            '-\tBitte posten Sie keine Bilder, die bestimmte Personen identifizieren (außer sich selbst / wenn Sie möchten)\n' +
            '-\tWir raten Ihnen außerdem davon ab, Bilder von sich in 1APP zu posten - da dies anderen  @.lower:(terms.player)n dauerhafte Rückschlüsse auf Sie ermöglichen könnte.\n' +
            'o\tIn 1 App bist du anderen  @.lower:(terms.player)n gleichgestellt und anonym\n' +
            'o\tMit einem Foto von dir gibst du einen großen Teil dieser Anonymität auf, was später zu Nachteilen führen kann, die andere  @.lower:(terms.player) nicht haben\n' +
            '„Du bekommst die Kontrolle über Herausforderungen und @.lower:(terms.deal)s, die von anderen  @.lower:(terms.player)n noch nicht bemerkt wurden. Von Ihnen oder anderen  @.lower:(terms.player)n abgeschlossene Herausforderungen bleiben Teil des Spiels.\n' +
            '„Eine nachträgliche inhaltliche Änderung von @.lower:(terms.quest)s oder @.lower:(terms.deal)s ist nicht möglich. In besonderen Fällen ist für eine nachträgliche Änderung ein schriftlicher Antrag auf inhaltliche Änderung bei uns einzureichen.\n' +
            'Diese Anfragen werden unter Support@XGE1.de angenommen. Ein Anspruch auf nachträgliche Änderungen besteht nicht. Nur  @.lower:(terms.player), die direkt mit dem zu ändernden Datensatz verbunden sind, können Änderungen anfordern.\n' +
            '„Meldet ein Dritter, der nicht am Datensatz beteiligt ist, Verletzungen seiner Rechte oder Freiheiten, leiten wir diese Meldung an die am Datensatz beteiligten Akteure weiter. Gegebenenfalls muss ein an dem Datensatz beteiligter  @.lower:(terms.player) dann eine Datenänderungsanfrage an uns richten.\n' +
            '\n' +
            '\n' +
            'Verbote in 1APP ausdrücken\n' +
            '1.\tDu erstellst nur ein  @.lower:(terms.player)konto für dich und kein zweites\n' +
            '2.\tSie erstellen nur ein  @.lower:(terms.group) pro juristischer Person (Unternehmen, Verein usw.).\n' +
            '3.\tDu wirst niemals ohne ausdrückliche Erlaubnis im Namen eines anderen Menschen handeln\n' +
            '4.\tSie werden keine Anmeldeinformationen suchen oder auf ein Konto zugreifen, das jemand anderem gehört\n' +
            '5.\tSie verbreiten keine politischen oder extremistischen Meinungen\n' +
            '6.\tSie werden keine nicht genehmigten kommerziellen Mitteilungen (z. B. Spam) auf 1APP posten\n' +
            '7.\tSie werden keine Inhalte posten, die: Hassreden enthalten, bedrohlich oder pornographisch sind, zu Gewalt aufrufen oder Nacktheit oder Gewalt enthalten\n' +
            '8.\tSie werden keine Mitgliederinhalte oder -informationen sammeln oder anderweitig mit automatisierten Mitteln (wie Bots, Robotern, Spinnen oder Scrapern) auf 1APP zugreifen, es sei denn, Sie haben unsere vorherige Genehmigung\n' +
            '9.\tSie werden auf 1APP keine illegalen strukturierten Verkäufe wie Pyramidensysteme durchführen\n' +
            '10.\tSie werden keine Viren oder anderen schädlichen Code hochladen\n' +
            '11.\tSie werden andere  @.lower:(terms.player) oder  @.lower:(terms.group)n von  @.lower:(terms.player)n nicht schikanieren, einschüchtern, belästigen oder schikanieren\n' +
            '12.\tSie werden 1APP nicht verwenden, um rechtswidrige, betrügerische, böswillige oder diskriminierende Aktivitäten auszuführen\n' +
            '13.\tSie werden keine Maßnahmen ergreifen, die das ordnungsgemäße Funktionieren oder Erscheinungsbild von 1APP blockieren, überlasten oder beeinträchtigen könnten\n' +
            '14.\tSie werden Verstöße gegen diese Regeln weder unterstützen noch fördern und diese im besten Fall umgehend an support@xge1.de melden \n' +
            '15.\tAls "Leader" eines  @.lower:(terms.group)es machen Sie wahrheitsgemäß Angaben zur Rechtsform und den damit verbundenen abgefragten Informationen bzw. geben diese beim Anlegen eines  @.lower:(terms.group)es wahrheitsgemäß ein\n' +
            '16.\tSie werden Ihr Field nicht verwenden, um informelle Vorteile durch Käufe von "Field Leader" über die Vorteile des Community-Kontos an Personen oder Unternehmen zu führen, die nicht 1APP spielen - oder ihre eigene Field-Lizenz haben. Alles, was Sie als Leder für Ihr  @.lower:(terms.group) verwenden, kann nur von Ihrem  @.lower:(terms.group) oder dem Lizenzinhaber des  @.lower:(terms.group)es verwendet werden.\n' +
            '17.\tSie werden keine Daten anderer  @.lower:(terms.player) kommerzialisieren, egal ob Sie ein kostenloser  @.lower:(terms.player), ein Verein oder ein bezahlter Firmenkunde sind.\n' +
            '18.\tSie dürfen in 1APP erhaltene Informationen nicht außerhalb des Spiels verwenden, um gewinnorientierte Angebote an Mitspieler oder  @.lower:(terms.group)er außerhalb von 1APP zu richten\n' +
            '19.\tWenn Sie kommerzielle/ermäßigte Angebote anbieten möchten, benötigen Sie ein  @.lower:(terms.group) mit einer kommerziellen Lizenz.\n' +
            '20.\tSie werden @.lower:(terms.quest)s nicht nutzen, um Schwarzarbeit oder niedrige Löhne im realen Arbeitsleben anzubieten oder zu fördern\n' +
            '21.\tSie werden während einer Herausforderung nicht gegen geltendes Recht verstoßen\n' +
            '22.\tSie werden andere  @.lower:(terms.player) nicht zu Rechts- oder Ordnungswidrigkeiten anstiften\n' +
            ' \n' +
            '\n' +
            '23.\tSie werden offensichtliche potenzielle Straftaten, die in 1APP gepostet wurden, sofort zuerst der Polizei melden und dann an support@XGE1.de \n' +
            '24.\tSie melden jeden Rechtsverstoß im Zusammenhang mit 1APP\n' +
            '25.\tDu gibst anderen  @.lower:(terms.player)n im Spiel keinen Alkohol, Zigaretten, Drogen, Betäubungsmittel, Medikamente oder ähnliches.\n' +
            '26.\tInsbesondere beachten Sie das Jugendschutzgesetz stets vollumfänglich\n' +
            '27.\tDu rufst keine Selbstjustiz auf oder führst sie selbst mit Hilfe durch\n' +
            '28.\tSie werden 1APP nicht verwenden, um Steuern für private oder Unternehmensgewinne zu unterschlagen, zu verbergen oder zu hinterziehen\n' +
            '29.\tWenn Sie als verantwortlicher "Führer" auf  @.lower:(terms.group)ern agieren, werden Sie ohne Rücksprache und Ankündigung keine Handlungen vornehmen, die direkte finanzielle, rechtliche oder steuerliche Auswirkungen auf die  @.lower:(terms.group)mitglieder haben\n' +
            '30.\tSie werden 1APP nicht verwenden, um Verwaltungsaufgaben des öffentlichen Dienstes auszuführen\n' +
            '31.\tSie werden 1APP nicht verwenden, um Verwaltungsaufgaben mit Bürgern als Beamter einer Behörde zu erledigen\n' +
            '32.\tIn der 1APP-App steht kein  @.lower:(terms.player) über einem anderen  @.lower:(terms.player). Ihr realer Rang und die damit verbundene Souveränität über andere Personen sind nicht auf das Spiel übertragbar\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Kontobezogene Sperren und Sicherheit \n' +
            '1.\tDu wirst kein Profil für jemand anderen erstellen.\n' +
            '2.\tSie erstellen nur ein persönliches Konto.\n' +
            '3.\tSie werden kein  @.lower:(terms.group) für ein Unternehmen, einen Verein oder eine andere juristische Person eröffnen, es sei denn, dies wurde ausdrücklich vereinbart und von der Geschäftsführung/Eigentümer/Verwaltungsrat oder dem jeweiligen Verantwortlichen des Unternehmens/Vereins/der juristischen Person genehmigt. \n' +
            'a.\tSie erstellen kein  @.lower:(terms.group) für Unternehmen oder Vereinigungen, bei denen Sie ein externer Dritter sind. Wenn Sie nicht für dieses Unternehmen arbeiten, dürfen Sie kein  @.lower:(terms.group) für dieses Unternehmen erstellen.\n' +
            'b.\tWenn Sie beispielsweise einen Außendienst für eine Firma erstellen und dann die Firma verlassen, übertragen Sie jede Führungsfunktion des Außendiensts auf andere/verbleibende Mitarbeiter des Außendiensts.\n' +
            'c.\tSie werden kein Rechtsträgerfeld als Leiter führen, wenn die für das Unternehmen/Verein/Rechtsträger verantwortlichen Schlüsselpersonen dies nicht wünschen. Auf Wunsch übertragen Sie Ihre Führungsfunktion in die Geschäftsführung des Unternehmens oder den Vorstand eines Vereins (gilt auch für alle anderen Rechtsformen)\n' +
            '4.\tWenn Sie als Leader ein  @.lower:(terms.group) anführen, werden Sie das  @.lower:(terms.group) unter Berücksichtigung aller gesetzlichen Bestimmungen und der 1APP-Spielregeln anführen.\n' +
            'a.\tSie veröffentlichen keine politischen oder radikalen Inhalte in der Beschreibung des  @.lower:(terms.group)es\n' +
            'b.\tDasselbe gilt für das Bild des  @.lower:(terms.group)es\n' +
            'c.\tWenn ein  @.lower:(terms.player) gegen ein Gesetz oder die Spielregeln innerhalb des von Ihnen angegebenen Bereichs verstößt, können Sie die Mitgliedschaft des  @.lower:(terms.player)s in Ihrem Bereich beenden. Inhalte, die vom betreffenden  @.lower:(terms.player) auf dem Spielfeld nicht abgeschlossen wurden, verfallen automatisch.\n' +
            'd.\tAls  @.lower:(terms.group)leiter sind Sie für das  @.lower:(terms.group) verantwortlich und was darin passiert - wir - als XGE1 UG, hat keinen Einfluss auf den Inhalt oder Ihr Handeln.\n' +
            'd.\tSollten entgegen den Spielregeln sogar strafrechtlich relevante Inhalte in Ihrem Bereich auftauchen, haben Sie die Pflicht, dies zunächst den Behörden wie der Polizei - und dann uns zu melden.\n' +
            '5.\tWenn Ihr  @.lower:(terms.player)konto gesperrt ist, erstellen Sie ohne Erlaubnis kein weiteres Konto.\n' +
            '6.\tSie werden 1APP nicht verwenden, wenn Sie unter 16 Jahre alt sind.\n' +
            '7.\tSie werden Ihr Passwort (oder Ihren geheimen Schlüssel) nicht weitergeben, anderen den Zugriff auf Ihr Konto gestatten oder irgendetwas anderes tun, was die Sicherheit Ihres Kontos gefährden könnte.\n' +
            '8.\tSie werden Ihr Konto an niemanden übertragen, ohne vorher unsere schriftliche Genehmigung eingeholt zu haben.\n' +
            '9.\tWenn Sie einen  @.lower:(terms.player)namen oder eine ähnliche Bezeichnung für Ihr Konto oder Ihre Seite wählen, behalten wir uns das Recht vor, diesen zu ändern, wenn wir dies für erforderlich halten (z. B. wenn der Markeninhaber eine Beschwerde über einen nicht übereinstimmenden  @.lower:(terms.player)namen hat an den echten Namen eines Mitglieds).\n' +
            ' \n' +
            'Schutz der Rechte anderer\n' +
            '1.\tWir respektieren die Rechte anderer und erwarten dasselbe von Ihnen.\n' +
            '2.\tSie werden 1APP nicht verwenden, um andere  @.lower:(terms.player), andere Personen, Institutionen oder Unternehmen zu schädigen, zu beleidigen, zu diskriminieren oder ähnliches.\n' +
            '3.\tWenn Ihnen 1 App nicht gefällt, löschen Sie 1 App. Sie werden auch unseren Mitarbeitern oder Dienstleistern keinen Schaden zufügen, sie beleidigen oder diskreditieren.\n' +
            '4.\tSie kopieren keine Funktionen, die Sie in 1 App sehen und verwenden, und verwenden sie in Ihren eigenen/anderen Produkten.\n' +
            '5.\tBilder und Texte, die Sie auf "1APP" hochladen, gehören rechtlich dem Webseitenbetreiber XGE1. Sie behalten sich jedoch die Befugnis vor, zu entscheiden, was mit diesen Informationen in Zukunft geschehen soll. Daten, die bereits fest mit dem Spielverlauf verflochten sind, können eventuell nachträglich nicht mehr gelöscht werden. Überlegen Sie sich also vorher genau, welche Informationen Sie übermitteln.\n' +
            '6.\tSie werden keine Inhalte auf 1APP posten oder Aktionen auf 1APP ausführen, die die Rechte einer anderen Person oder das Gesetz verletzen.\n' +
            '7.\tWir können alle Inhalte oder Informationen, die Sie auf 1APP gepostet haben, entfernen, wenn wir glauben, dass sie gegen diese Erklärung oder unsere Richtlinien verstoßen.\n' +
            '8.\tWenn wir Ihre Inhalte wegen Verletzung des Urheberrechts einer anderen Person entfernen und Sie der Meinung sind, dass es sich um einen Fehler handelt, geben wir Ihnen die Möglichkeit, darauf zu reagieren. Wenn Sie wiederholt die geistigen Eigentumsrechte anderer verletzen, können wir Ihr Konto sperren.\n' +
            '9.\tDu und deine  @.lower:(terms.group)er, die du erstellst, dürfen nicht so erscheinen, als ob du oder dein  @.lower:(terms.group) Einfluss auf übergeordnete Spielabläufe außerhalb des  @.lower:(terms.group)es oder deines Accounts hättest.\n' +
            '10.\tWenn Sie Informationen von  @.lower:(terms.player)n zur späteren kommerziellen Nutzung sammeln, werden Sie:\n' +
            'a.\tVerifizieren Sie ein  @.lower:(terms.group) für Ihr Fachgebiet von der icigai1 GmbH\n' +
            'b.\tSteuern Sie Ihre Aktivitäten über dieses  @.lower:(terms.group)\n' +
            'c.\tDie geltenden Datenschutzbestimmungen einhalten\n' +
            '11.\tSie werden auf 1APP keine Identitäts- oder sensiblen Finanzinformationen von irgendjemandem anfordern oder posten.\n' +
            '12.\tAlle eingeräumten Rechte zur Nutzung von 1APP können von uns jederzeit ohne Vorankündigung widerrufen werden.\n' +
            '13.\tSie werden Ihre Lizenz, unseren Code, unsere APIs oder Tools an niemanden verkaufen, übertragen oder unterlizenzieren.\n' +
            '14.\tSie werden Ihre Beziehung zu 1APP oder den Organisationen dahinter gegenüber anderen nicht falsch darstellen.\n' +
            '15.\tWir können eine Pressemitteilung herausgeben, in der unsere Beziehung zu Ihnen beschrieben wird, wenn Sie uns dazu Anlass geben.\n' +
            '16.\tSie werden alle geltenden Gesetze einhalten.\n' +
            '17.\tSie gewähren uns alle erforderlichen Rechte, damit 1APP auf allen Geräten funktioniert, einschließlich des Rechts, die Inhalte und Informationen, die Sie uns zur Verfügung stellen, in Nachrichtenflüsse, Zeitpläne und Berichte über  @.lower:(terms.player)aktionen im Spiel zu integrieren.\n' +
            '\n' +
            '\n' +
            '\n' +
            'Folgen von Verstößen \n' +
            'Verstöße werden in verschiedenen Eskalationsstufen behandelt:\n' +
            '1.\tWarnung wegen Verstoßes gegen die Spielregeln\n' +
            'a.\tDer  @.lower:(terms.player) wird schriftlich ermahnt, bestimmte Aktionen in Zukunft zu unterlassen oder einzustellen oder Inhalte zu entfernen\n' +
            'b.\tEine erste, zweite und dritte Ermahnung folgt\n' +
            'i.\tDie Abmahnungen erfolgen im Abstand von 7 Tagen, wenn der  @.lower:(terms.player) der Aufforderung zur Anpassung des Inhalts nicht nachgekommen ist.\n' +
            '2.\tWarnung wegen Verstoßes gegen die Spielregeln\n' +
            'a.\tWenn der  @.lower:(terms.player) die illegale Aktion nicht unterlässt und/oder sich weiterhin unterwirft, erhält er eine Verwarnung\n' +
            'b.\tNach der dritten Mahnung wird das  @.lower:(terms.player)konto für einen Monat gesperrt\n' +
            'c.\tFalls nach der dritten Abmahnung in einer ähnlichen Angelegenheit eine weitere Abmahnung fällig ist (nach einer weiteren 3-maligen vergeblichen Abmahnung), wird das  @.lower:(terms.player)konto für 6 Monate gesperrt.\n' +
            'd.\tWenn nach einer vierten Abmahnung in einer ähnlichen Angelegenheit eine weitere Abmahnung fällig ist (nach einer weiteren 3-maligen vergeblichen Abmahnung), wird das  @.lower:(terms.player)konto für 12 Monate gesperrt.\n' +
            'e.\tWenn nach einer fünften Abmahnung in einer ähnlichen Angelegenheit eine weitere Abmahnung fällig ist (nach einer weiteren 3-maligen vergeblichen Abmahnung), wird das  @.lower:(terms.player)konto auf unbestimmte Zeit gesperrt .\n' +
            'f.\tWährend einer Sperrung können  @.lower:(terms.player) die Reaktivierung des Kontos unter support@xge1.de beantragen.\n' +
            'g.\tAnträge auf (vorzeitige) Reaktivierung werden individuell geprüft und nicht öffentlich entschieden.\n' +
            '3.\tErmahnung und Abmahnung bei Verstößen gegen geltendes Recht\n' +
            'a.\tWenn Inhalte in der App offensichtlich rechtswidrig sind, erhält der  @.lower:(terms.player) von uns eine Abmahnung mit sofortiger Handlungsaufforderung in Form der Inhaltsänderung\n' +
            'b.\tFalls der  @.lower:(terms.player) dieser Aufforderung nicht sofort nachkommt, erfolgt eine sofortige Verwarnung. Wir können dann je nach Bedrohungslage das Intervall zwischen der ersten, zweiten und dritten Warnung auf bis zu 60 Sekunden ohne Vorwarnung reduzieren.\n' +
            '4.\t1-3 gilt auch für  @.lower:(terms.player), die in ihrer Eigenschaft als Leiter eines  @.lower:(terms.group)es das  @.lower:(terms.group) nutzen, um illegale oder gegen die Spielregeln verstoßende Inhalte zu posten\n' +
            '5.\tVerstöße in  @.lower:(terms.group)ern werden sofort dem Lizenzinhaber gemeldet.\n' +
            '6.\tFelder, die rechtswidrige oder gegen die Spielregeln verstoßende Inhalte trotz Abmahnung und Abmahnung nicht einstellen oder löschen, werden von uns deaktiviert oder direkt gelöscht.\n' +
            '7.\tSchäden, die uns durch Ihre rechtswidrige oder regelwidrige Nutzung entstehen, werden gerichtlich geltend gemacht.\n' +
            '\n' +
            '\n' +
            'Änderungen\n' +
            'Sofern wir aus rechtlichen oder administrativen Gründen keine Änderung vornehmen oder eine ungenaue Aussage in der Satzung, dem Handbuch oder den Nutzungsbedingungen korrigieren wollen, geben wir Ihnen Gelegenheit, die Änderungen an dieser Erklärung zu überprüfen.\n' +
            'Ihre fortgesetzte Nutzung von 1APP nach Änderungen unserer Satzung oder unseres Handbuchs bedeutet auch, dass Sie unsere neuen/geänderten Bedingungen akzeptieren.\n' +
            '\n' +
            'Beendigung\n' +
            '„Wenn Sie gegen die hier aufgeführten Regeln verstoßen, uns anderweitig rechtliche Risiken verschaffen, den Betreibergesellschaften, Mitarbeitern oder unseren Partnern aktiv Schaden zufügen, können wir die Bereitstellung von 1APP an Sie ganz oder teilweise einstellen. Dies gilt auch, wenn im Rahmen Ihrer beruflichen Tätigkeit schädliche Handlungen gegen uns vorgenommen wurden.\n' +
            'In diesem Fall werden Sie per E-Mail und in 1APP informiert oder gewarnt.\n' +
            'Falls Ihnen das passiert ist, senden Sie Ihre E-Mail an support@xge1.de .\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            ' \n' +
            'Streitigkeiten\n' +
            'Sie werden alle Ansprüche, Klagegründe oder Streitigkeiten (Ansprüche), die Sie gegen uns haben, die sich aus oder im Zusammenhang mit dieser Erklärung oder XGE1 UG oder 1APP ergeben, ausschließlich vor dem für Jena (Deutschland) zuständigen Amtsgericht beilegen. und Sie stimmen zu, sich bei Gerichtsverfahren über solche Ansprüche der persönlichen Zuständigkeit dieser Gerichte zu unterwerfen.\n' +
            'Diese Erklärung und alle Ansprüche, die zwischen Ihnen und uns entstehen können, unterliegen dem Recht der Bundesrepublik Deutschland.\n' +
            '„Wenn jemand wegen Ihrer Handlungen, Ihrer Inhalte oder Ihrer Informationen auf 1APP einen Anspruch gegen uns erhebt, werden Sie uns für alle Schäden, Verluste und Ausgaben (einschließlich angemessener Anwalts- und Rechtskosten) schadlos halten. Obwohl wir Regeln für das Verhalten von  @.lower:(terms.player)n bereitstellen, kontrollieren oder steuern wir die Aktionen von  @.lower:(terms.player)n auf 1APP nicht und sind nicht verantwortlich für die Inhalte oder Informationen, die  @.lower:(terms.player) auf 1APP übertragen oder teilen.\n' +
            'Wir sind nicht verantwortlich für unanständige, unangemessene, obszöne, rechtswidrige oder anderweitig anstößige Inhalte oder Informationen, auf die Sie möglicherweise auf 1APP stoßen. Wir sind nicht verantwortlich für das Verhalten von 1APP-Spielern, weder online noch außerhalb des Internets.\n' +
            '„Wir bemühen uns, 1APP fehlerfrei und sicher zu betreiben, die Nutzung von 1APP erfolgt jedoch auf eigene Gefahr. Wir stellen 1APP "wie besehen" ohne jegliche ausdrückliche oder stillschweigende Gewährleistung zur Verfügung, einschließlich, aber nicht beschränkt auf die stillschweigenden Gewährleistungen der Marktgängigkeit, Eignung für einen bestimmten Zweck und Nichtverletzung.\n' +
            'Wir garantieren nicht, dass 1APP immer sicher, sicher oder fehlerfrei ist oder dass 1APP immer ohne Unterbrechungen, Verzögerungen oder Mängel funktioniert.\n' +
            '1APP ist nicht verantwortlich für die Handlungen, Inhalte, Informationen oder Daten Dritter und Sie entbinden uns von jeglicher Haftung für Ansprüche und Schäden jeglicher Art, bekannt oder unbekannt, die sich aus oder im Zusammenhang mit Streitigkeiten mit Dritten ergeben.\n' +
            'Wir haften Ihnen gegenüber nicht für Gewinne, Verluste oder andere Folgeschäden, spezielle, indirekte oder zufällige Schäden, die sich aus oder im Zusammenhang mit dieser Erklärung oder 1APP ergeben, selbst wenn wir auf die Möglichkeit solcher Schäden hingewiesen wurden.\n ' +
            'Anwendbare Gesetze erlauben möglicherweise keine Beschränkung oder keinen Haftungsausschluss für zufällige Folgeschäden, sodass die obige Beschränkung oder der vorstehende Ausschluss möglicherweise nicht auf Sie zutrifft. In diesen Fällen ist die Haftung von 1APP auf den gesetzlich maximal zulässigen Umfang beschränkt.\n' +
            '\n' +
            'Definitionen\n' +
            '1APP beinhaltet alle Funktionen und Leistungsangebote, die wir über unsere App "1APP" bereitstellen. Die Begriffe „Plattform“, „App“ und „Website“ beziehen sich auf eine Reihe von Anwendungsprogrammierschnittstellen (APIs) und Diensten (z. B. Inhalte), über die andere, z. B. App-Entwickler und Website-Betreiber, Daten von 1APP abrufen oder bereitstellen uns mit Daten.\n' +
            '„Mit „Informationen“ meinen wir Fakten und andere Informationen über Sie, einschließlich Aktionen, die von  @.lower:(terms.player)n und Nicht-Spielern durchgeführt werden, die mit 1APP interagieren. "Inhalt" bedeutet alles, was Sie oder andere  @.lower:(terms.player) auf 1APP posten, das nicht unter die Definition von "Informationen" fällt.\n' +
            'Mit "Daten" oder "Spielerdaten" oder "Spielerdaten" meinen wir alle Daten, einschließlich  @.lower:(terms.player)inhalte oder -informationen, auf die Sie oder Dritte von 1APP aus zugreifen können.\n' +
            'Mit "Posten" und "Teilen" meinen wir das Posten von Inhalten auf 1APP oder das anderweitige Bereitstellen von Herausforderungen über ein Gerät, das 1APP verwendet.\n' +
            '„Nichts in dieser Erklärung soll uns daran hindern, das Gesetz einzuhalten. Diese Erklärung gewährt Dritten keinerlei Eigentums- oder Titelrechte. Wir behalten uns alle Rechte vor, die Ihnen nicht ausdrücklich gewährt werden. Sie werden alle anwendbaren Gesetze einhalten, wenn Sie 1APP verwenden oder darauf zugreifen.\n' +
            '\n“ \n' +
            '\n',
        button: 'ok'
      }
    },
    impress: {
      activator: {
        label: 'Impressum'
      },
      dialog: {
        title: 'Impressum',
        text: 'icigai1 GmbH' +
            '<br>' +
          'Vertreten durch den Geschäftsführer:' +
            '<br>' +
          'Jan Oertel' +
            '<br>' +
          'Eintragung:' +
          'Amtsgericht Jena | HRB 51438' +
            '<br>' +
          'Kontakt\n' +
            '<br>' +
          'icigai 1 GmbH' +
            '<br>' +
          'Moritz-von-Rohr-Str. 1a' +
            '<br>' +
          '07745 Jena' +
            '<br>' +
          'Mail support@icigai1.com' +
            '<br>' +
          'Web www.icigai1.com',
        button: 'ok'
      }
    }
  }
}
