<template>
  <v-card class="detail-content">
    <v-card-title>
      {{ $t('components.AppConfirmPassword.captions.reset') }}
    </v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="form.valid"
      >
        <template v-if="cRequestReset">
          <v-text-field
              v-if="!emailRequested"
              ref="emailField"
              v-model="form.email"
              autofocus
              :label="$t('components.AppConfirmPassword.label.eMail')"
              prepend-icon="mdi-at"
              :rules="form.rules.email"
              validate-on-blur
              @keypress.enter.prevent
              @keyup.enter="onKeyupEmail"
          />
        </template>
        <eins-form-password-reset
            v-else
            v-model="form.data"
            @submit="onSubmitForm"
        />
      </v-form>
    </v-card-text>
    <v-expand-transition>
      <v-alert
          v-if="error !== false"
          class="mb-0"
          type="error"
          tile
          text
          dense
      >
        <template v-if="error === 422">
          {{ $t('components.AppConfirmPassword.error.422') }}
        </template>
        <template v-else-if="error === 429">
          {{ $t('components.AppConfirmPassword.error.429') }}
        </template>
        <template v-else>
          {{ $t('components.AppConfirmPassword.error.else') }}
        </template>
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <v-alert
          v-if="emailRequested && error === false"
          class="mb-0"
          type="success"
          tile
          text
          dense
      >
        {{ $t('components.AppConfirmPassword.alert.link') }} '<strong>{{ form.email }}</strong>'
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <v-alert
          v-if="passwordReset && error === false"
          class="mb-0"
          type="success"
          tile
          text
          dense
      >
        {{ $t('components.AppConfirmPassword.alert.answer') }}
        <v-btn
            color="primary"
            :disabled="loading"
            outlined
            block
            @click="onClickBack"
        >
          {{ $t('components.AppConfirmPassword.buttons.login.label') }}
        </v-btn>
      </v-alert>
    </v-expand-transition>
    <v-card-actions v-if="!emailRequested || error">
      <template
          v-if="!passwordReset"
      >
        <slot name="cancel">
          <v-btn
              color="error"
              :disabled="loading"
              text
              @click="onClickCancel"
          >
            {{ $t('components.AppConfirmPassword.buttons.cancel.label') }}
          </v-btn>
        </slot>
        <v-spacer />
        <slot name="confirm">
          <v-btn
              color="success"
              :loading="loading"
              text
              @click="onClickSubmit"
          >
            {{ $t('components.AppConfirmPassword.buttons.set.label') }}
          </v-btn>
        </slot>
      </template>
    </v-card-actions>
    <v-card-actions v-else>
      <slot name="back">
        <v-btn
            color="primary"
            :disabled="loading"
            text
            block
            @click="onClickBack"
        >
          {{ $t('components.AppConfirmPassword.buttons.login.label') }}
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import EinsFormPasswordReset from '@/components/auth/EinsFormPasswordReset'
import { AuthModes } from '@/constants'
import { isEmail, isNotEmpty } from '@/utils/inputRules'

export default {
  name: 'EinsModulePasswordReset',
  components: {
    EinsFormPasswordReset
  },
  data: () => ({
    loading: false,
    error: false,
    emailRequested: false,
    passwordReset: false,
    form: {
      email: '',
      data: {},
      valid: false,
      rules: {
        email: [
          isNotEmpty,
          isEmail
        ]
      }
    }
  }),
  computed: {
    cApiLink () {
      return this.$route.query.link ?? null
    },
    cRequestReset () {
      return this.cApiLink === null
    }
  },
  mounted () {
    const email = (this.$route.query.email ?? '').trim()
    if (email.length > 0) {
      this.form.email = email
    }
  },
  methods: {
    onClickSubmit () {
      this.submit()
    },
    onSubmitForm () {
      this.submit()
    },
    onClickReset () {
    },
    onClickCancel () {
      this.cancel()
    },
    onClickBack () {
      this.cancel()
    },
    onKeyupEmail () {
      this.requestResetEmail()
        .catch(err => this.catchRequestError(err))
    },
    reset () {
      this.$refs.form.resetValidation()
      this.form.email = ''
      this.form.data.password = ''
      this.form.data.password_confirmation = ''
    },
    cancel () {
      this.$router.replace({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.LOGIN
        }
      })
    },
    submit () {
      if (this.cRequestReset) {
        this.requestResetEmail()
          .catch(err => this.catchRequestError(err))
      } else if (this.cApiLink) {
        this.changePassword()
      }
    },
    changePassword () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.error = false
        return this.$http.put(this.cApiLink, {
          ...this.form.data
        })
          .then(() => {
            this.passwordReset = true
          })
          .catch(err => this.catchRequestError(err))
          .finally(() => {
            this.loading = false
          })
      } else {
        return Promise.reject(new Error('Form is not valid!'))
      }
    },
    requestResetEmail () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.error = false
        return this.$http.$api.post('/password-reset', {
          email: this.form.email
        })
          .then(() => {
            this.emailRequested = true
          })
          .catch(err => this.catchRequestError(err))
          .finally(() => {
            this.loading = false
          })
      } else {
        return Promise.reject(new Error('Form is not valid!'))
      }
    },
    catchRequestError (err) {
      if (err.message !== 'Form is not valid!') {
        if (err.response) {
          switch (err.response.status) {
            case 422:
            case 429:
              this.error = err.response.status
              break
            default:
              throw err
          }
        } else {
          throw err
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
