<template>
  <v-row
      class="flex-column"
  >
    <!-- One message per row -->
    <v-col
        v-for="message in messages"
        :key="message.id"
    >
      <ein-chat-message-list-row
          :user="user"
          :message="message"
      />
    </v-col>
  </v-row>
</template>

<script>
import EinChatMessageListRow from '@/components/chat/EinChatMessageListRow'
export default {
  name: 'EinChatMessageList',
  components: { EinChatMessageListRow },
  props: {
    messages: {
      type: Array,
      required: true,
      default: () => []
    },
    user: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>

<style scoped>

</style>
