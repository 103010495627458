export default {
  reset: {
    label: 'Reset'
  },
  submit: {
    label: 'Login'
  },
  error: {
    invalid: 'Your password is invalid!',
    rate: 'Too many attempts, please try again later.',
    email: 'Your @.lower:(terms.player) email is not registered',
    unknown: 'An unknown error happened, please try again later.'
  },
  resetPassword: {
    label: 'Reset @.lower:(terms.player) password'
  },
  Google: {
    label: 'Login with Google'
  },
  Apple: {
    label: 'Login with Apple'
  },
  Register: {
    label: 'REGISTER NEW @.lower:(terms.player)'
  }
}
