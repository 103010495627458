export default {
  forbidden: {
    title: 'Not Allowed!',
    description: 'You are not allowed to view {0} with the ID {1}!'
  },
  notFound: {
    title: 'Not Found!',
    description: '{0} with the ID {1} does not exist!'
  }
}
