export default {
  advertisement: {
    title: 'Werbung, Öffentlichkeitsarbeit',
    description: ''
  },
  agribusiness: {
    title: 'Landwirtschaft, Forstwirtschaft, Gartenbau',
    description: ''
  },
  consumer: {
    title: 'Konsum- und Gebrauchsgüter',
    description: ''
  },
  environment: {
    title: 'Naturschutz',
    description: ''
  },
  foodstuff: {
    title: 'Lebensmittel, Nahrungs- / Genussmittelherstellung',
    description: ''
  },
  health: {
    title: 'Gesundheit, Soziales',
    description: ''
  },
  housing: {
    title: 'Immobilien, Bau, Architektur',
    description: ''
  },
  media: {
    title: 'Medien, Informationsdienste',
    description: ''
  },
  other: {
    title: 'Sonstige',
    description: ''
  },
  public: {
    title: 'öffentlich',
    description: ''
  },
  science: {
    title: 'Wissenschaft, Forschung, Entwicklung',
    description: ''
  },
  service_consulting: {
    title: 'Management, Beratung, Recht, Steuern',
    description: ''
  },
  service_education: {
    title: 'Bildung, Erziehung, Unterricht',
    description: ''
  },
  service_finance: {
    title: 'Banken, Finanzdienstleistungen, Versicherungen',
    description: ''
  },
  service_general: {
    title: 'Sicherheits-, Reinigungs-, Reparatur- und weitere Dienstleistungen',
    description: ''
  },
  service_grid: {
    title: 'Abfallwirtschaft, Energieversorgung, Wasserversorgung',
    description: ''
  },
  service_transport: {
    title: 'Logistik, Transport, Verkehr',
    description: ''
  },
  service_work: {
    title: 'Arbeitnehmerüberlassung, Zeitarbeit, Arbeitsvermittlung',
    description: ''
  },
  tec_automotive: {
    title: 'Fahrzeugbau, Fahrzeuginstandhaltung',
    description: ''
  },
  tec_aviation: {
    title: 'Luftfahrttechnik, Raumfahrttechnik',
    description: ''
  },
  tec_biochem: {
    title: 'Chemie, Pharma, Biotechnologie',
    description: ''
  },
  tec_it: {
    title: 'IT, Computer, Telekommunikation',
    description: ''
  },
  tec_med: {
    title: 'Elektro, Feinmechanik, Optik, Medizintechnik',
    description: ''
  },
  tec_metal: {
    title: 'Maschinenbau, Anlagenbau, Metallbau',
    description: ''
  },
  tec_print: {
    title: 'Papier, Druck, Verpackung',
    description: ''
  },
  tec_resources: {
    title: 'Rohstoffgewinnung, Rohstoffaufbereitung, Rohstoffverarbeitung',
    description: ''
  },
  tourism: {
    title: 'Hotel, Gastronomie, Tourismus',
    description: ''
  },
  trade: {
    title: 'Einzelhandel, Großhandel, Außenhandel',
    description: ''
  }
}
