export default {
  general: {
    title: 'General',
    description: 'General'
  },
  imposter: {
    title: 'Imposter',
    description: 'The @:(terms.player) is an imposter'
  },
  scam: {
    title: 'Scam',
    description: 'This is Scam'
  },
  name: {
    title: 'Name',
    description: 'Inappropriate name'
  },
  harassment: {
    title: 'Harassment',
    description: 'The person is harassing me'
  },
  'inappropriate-content': {
    title: 'Inappropriate content',
    description: 'The provided content is not appropriate'
  }
}
