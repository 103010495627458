<template>
  <v-app>
    <v-container
        class="overflow-y-auto"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <v-app-bar
          top
          app
          color="secondary"
          tag="header"
          class="four-color-gradient-shadow-bottom"
          style="height:4px"
      ></v-app-bar>
      <v-row
          class="fill-height column"
          align="center"
          justify="center"
      >
        <v-col style="height:100%;padding:0;padding-top:20px">
          <v-scroll-x-transition mode="out-in">
            <eins-module-password-reset v-if="cResetPassword" />
            <eins-module-verify-email v-else-if="cVerifyEmail" />
            <eins-module-register v-else-if="cRegister" />
            <eins-module-login
                v-else
                :mail="cQueryMail"
            />
          </v-scroll-x-transition>
        </v-col>
      </v-row>
      <v-app-bar
          bottom
          app
          color="secondary"
          tag="footer"
          class="four-color-gradient-shadow-top"
          style="height:4px"
      ></v-app-bar>
    </v-container>
  </v-app>
</template>

<script>
import EinsModuleLogin from '@/components/auth/EinsModuleLogin'
import EinsModulePasswordReset from '@/components/auth/EinsModulePasswordReset'
import EinsModuleVerifyEmail from '@/components/auth/EinsModuleVerifyEmail'
import EinsModuleRegister from '@/components/auth/EinsModuleRegister'

import {
  AuthModes
} from '@/constants'

export default {
  name: 'Auth',
  components: {
    EinsModuleRegister,
    EinsModuleLogin,
    EinsModulePasswordReset,
    EinsModuleVerifyEmail
  },
  computed: {
    cMode () {
      return this.$route.query.auth ?? AuthModes.LOGIN
    },
    cQueryMail () {
      return this.$route.query.email || ''
    },
    cResetPassword () {
      return this.cMode === AuthModes.PASSWORD_RESET
    },
    cVerifyEmail () {
      return this.cMode === AuthModes.EMAIL_VERIFICATION
    },
    cRegister () {
      return this.cMode === AuthModes.REGISTER
    },
    cLogin () {
      return this.cMode === AuthModes.LOGIN ||
          !(this.cResetPassword || this.cVerifyEmail || this.cRegister) // fall back to login if no other is active
    }
  },
  methods: {
    onClickResetPassword () {
      this.goToResetPassword()
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
