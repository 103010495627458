var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({attrs:{"label":_vm.cLabel,"items":_vm.cLocales,"value":_vm.internalValue},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"tile":"","height":"auto"}},[_c('eins-locale-flag',{attrs:{"locale":item}})],1),_c('eins-locale-name',{attrs:{"locale":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var native = ref.native;
var localized = ref.localized;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(localized)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(native)+" ")])],1)]}}],null,true)})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"tile":"","height":"auto"}},[_c('eins-locale-flag',{attrs:{"locale":item}})],1),_c('eins-locale-name',{attrs:{"locale":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var localized = ref.localized;
return [_vm._v(" "+_vm._s(localized)+" ")]}}],null,true)})]}}])},'v-select',_vm.cAttributes,false),_vm.cListeners))}
var staticRenderFns = []

export { render, staticRenderFns }