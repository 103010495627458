<template>
  <!--
  min height is important, otherwise the component gets sticky
  sometimes and refuses to detect visibility change
  -->
  <v-lazy
      v-model="isVisible"
      style="min-height: 1px;"
  >
    <slot
        :src="objectUrl"
        :loading="loading"
        :error="error"
    ></slot>
  </v-lazy>
</template>

<script>
export default {
  name: 'EinsApiImage',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data: () => ({
    objectUrl: null,
    loading: false,
    error: false,
    isVisible: false,
    srcChanged: false
  }),
  watch: {
    src: {
      immediate: true,
      handler: 'onChangeSrc'
    },
    isVisible: {
      immediate: true,
      handler: 'onChangeVisibility'
    }
  },
  methods: {
    onChangeVisibility (value, oldValue) {
      if (value) {
        if (this.srcChanged) {
          this.loadImage()
        }
      }
    },
    onChangeSrc (value, oldValue) {
      if (value && value !== oldValue) {
        if (this.isVisible) {
          this.loadImage()
        } else {
          this.srcChanged = true
        }
      }
    },
    loadImage () {
      this.loading = true
      this.error = false
      this.revokeImage()
      this.$http.$api.get(this.src, { responseType: 'arraybuffer' })
        .then(response => {
          let blob = new Blob(
            [response.data],
            { type: response.headers['content-type'] }
          )
          this.objectUrl = URL.createObjectURL(blob)
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    revokeImage () {
      if (this.objectUrl) {
        URL.revokeObjectURL(this.objectUrl)
      }
    }
  }
}
</script>

<style scoped>

</style>
