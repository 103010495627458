<template>
  <eins-layout-item-detail-simple
      :blocks="cBlocks"
      @oncontainerscroll="onListScroll"
  >
    <template #image>
      <v-row
          no-gutters
          class="flex-nowrap image-overlay"
      >
        <v-col
            class="overflow-hidden flex-grow-1 flex-shrink-0"
            :style="cImageStyle"
        >
          <eins-api-image
              #default="{ src }"
              :src="cPicture"
          >
            <v-img
                :src="src"
                style="aspect-ratio:1;"
            >
              <template #placeholder>
                <v-skeleton-loader
                    tile
                    type="image"
                />
              </template>
              <eins-layout-image-overlay
                  v-show="cShowImageOverlay"
                  left
              >
                <template #title>
                  <div
                      v-if="player"
                      class="pa-1 player text-truncate"
                  >
                    {{ player.name }}
                  </div>
                </template>
              </eins-layout-image-overlay>
            </v-img>
          </eins-api-image>
        </v-col>
        <v-col
            v-show="cShowImageOverlay"
            class="playeroff text-center items-center flex-grow-0 flex-shrink-1"
            style="min-width:50px; padding-top:40px;"
        >
          <template>
            <div
                v-if="player"
                class="text-truncate"
            >
              <template v-if="cOwnProfile">
                <v-row
                    align="center"
                    no-gutters
                >
                  <v-col
                      class="flex-grow-0 flex-shrink-1"
                  >
                    <v-icon
                        left
                    >mdi-star</v-icon>
                    <small
                        class="primary--text"
                    >{{ $n(cBalance.hours, 'magnitude') }}</small>
                  </v-col>
                </v-row>
                <v-row
                    align="center"
                    class="mt-2"
                    no-gutters
                >
                  <eins-icon-friendship
                      state="friend"
                      class="mt-2"
                      width="24"
                  />
                  <span class="ml-2 mt-2">{{ cContacts.friends }}</span>
                  <eins-icon-friendship
                      state="idol"
                      class="mt-2"
                      width="24"
                  />
                  <span class="ml-2 mt-2">{{ cContacts.idols }}</span>
                  <eins-icon-friendship
                      state="fan"
                      class="mt-2"
                      width="24"
                      style="display: inline-block;"
                  />
                  <span class="ml-2 mt-2 ">{{ cContacts.followers }}</span>
                </v-row>
                <v-row
                    style="text-align: center"
                    class="mt-2"
                >
                  <v-col>
                    <v-icon>mdi-google-circles-communities</v-icon>
                    <span class="ml-1">{{ cNumGroups }}</span>
                  </v-col>
                  <v-col>
                    <v-icon>mdi-star-circle</v-icon>
                    <span class="ml-1">{{ cMedalsCount }}</span>
                  </v-col>
                  <v-col>
                    <v-icon>mdi-heart</v-icon>
                    <span class="ml-1">{{ $n(cLikes, 'magnitude') }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row
                    align="center"
                    no-gutters
                >
                  <v-col class="flex-grow-0 flex-shrink-1">
                    <eins-icon-friendship
                        :state="cUserFriendshipState"
                        width="2.5em"
                        height="2.5em"
                    />
                  </v-col>
                  <v-col class="pl-2 text-center flex-grow-0 flex-shrink-1">
                    <v-icon>mdi-star-circle</v-icon><br />
                    {{ cMedalsCount }}
                  </v-col>
                </v-row>
                <div class="mt-3 ml-1">
                  <v-icon left>mdi-account</v-icon>{{ cCommon.friends }} ({{ cContacts.friends }})
                </div>
                <div class="mb-1 mt-1 ml-1">
                  <v-icon left>mdi-google-circles-communities</v-icon>{{ cCommon.groups }} ({{ cNumGroups }})
                </div>
                <v-row
                    style="text-align: center"
                    class="flex-nowrap"
                >
                  <v-col>
                    <v-icon>mdi-map-marker</v-icon>
                    <span class="ml-1">{{ cQuests.offering }}</span>
                  </v-col>
                  <v-col>
                    <v-icon>mdi-cart</v-icon>
                    <span class="ml-1">{{ cDeals }}</span>
                  </v-col>
                  <v-col>
                    <v-icon>mdi-heart</v-icon>
                    <span class="ml-1">{{ $n(cLikes, 'magnitude') }}</span>
                  </v-col>
                </v-row>
              </template>
            </div>
          </template>
        </v-col>
        <v-col
            v-show="!cShowImageOverlay"
            class="flex-grow-1 pl-2 text-h6"
        >
          <v-row no-gutters>
            <v-col cols="12">
              {{ player.name }}
            </v-col>
            <v-col cols="12">
              <template v-if="cOwnProfile">
                <!-- OWN PROFILE VIEW -->
                <v-row
                    no-gutters
                >
                  <v-col style="border-right:1px groove grey">
                    <v-icon
                        width="24"
                    >mdi-star</v-icon>
                    <small
                        class="primary--text"
                    >{{ $n(cBalance.hours, 'magnitude') }}</small>
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <eins-icon-friendship
                        state="friend"
                        width="24"
                        class="ma-1 ml-2"
                    />
                    <strong>
                      {{ cContacts.friends }}
                    </strong>
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <eins-icon-friendship
                        state="idol"
                        width="24"
                        class="ma-1 ml-2"
                    />
                    <strong>
                      {{ cContacts.idols }}
                    </strong>
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <eins-icon-friendship
                        state="fan"
                        width="24"
                        class="ma-1 ml-2"
                    />
                    <strong>
                      {{ cContacts.followers }}
                    </strong>
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-google-circles-communities</v-icon><br />{{ cNumGroups }}
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-star-circle</v-icon><br />{{ cMedalsCount }}
                  </v-col>
                  <v-col class="text-center">
                    <v-icon>mdi-heart</v-icon><br />{{ $n(cLikes, 'magnitude') }}
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <!-- FOREIGN VIEW -->
                <v-row
                    align="center"
                    no-gutters
                >
                  <v-col
                      class="flex-grow-0 flex-shrink-1"
                      style="border-right:1px groove grey"
                  >
                    <eins-icon-friendship
                        :state="cUserFriendshipState"
                        width="2.5em"
                        height="2.5em"
                    />
                  </v-col>
                  <v-col
                      class="text-center flex-grow-0 flex-shrink-1"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-star-circle</v-icon><br />
                    {{ cMedalsCount }}
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon left>mdi-account</v-icon><br />{{ cCommon.friends }} ({{ cContacts.friends }})
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon left>mdi-google-circles-communities</v-icon><br />{{ cCommon.groups }} ({{ cNumGroups }})
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-map-marker</v-icon><br />{{ cQuests.offering }}
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-cart</v-icon><br />{{ cDeals }}
                  </v-col>
                  <v-col
                      class="text-center"
                      style="border-right:1px groove grey"
                  >
                    <v-icon>mdi-heart</v-icon><br />{{ $n(cLikes, 'magnitude') }}
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template #block-about>
      <div
          ref="aboutContainer"
          class="pa-3"
      >
        <div
            v-if="player"
            class="headline"
        >
          {{ player.name }}
        </div>
        <div
            v-if="player"
            class="overline"
        >
          player-ID: #{{ player.id }}
        </div>
        <div v-if="cOwnProfile">
          <v-divider class="mb-1 mt-1" />
          <v-row no-gutters>
            <v-col class="text-center flex-grow-0 pr-2">
              <v-icon large>mdi-star</v-icon>
              <div
                  style="line-height: 1;"
                  class="mt-1"
              >
                {{ $n(cBalance.days, 'magnitude') }}<br />
                days
              </div>
            </v-col>
            <v-col>
              <eins-time-stars
                  per-row="8"
                  stars="24"
                  shrink
                  style="font-size: 24px;"
                  :value="cBalance.hoursCapped * 60"
                  wrap
              />
            </v-col>
          </v-row>
          <v-row
              no-gutters
              align="center"
              class="mt-2 mb-2"
          >
            <v-col class="flex-grow-0">
              <v-icon
                  large
                  left
              >mdi-clock-outline</v-icon>
            </v-col>
            <v-col class="pl-1">
              {{ $tc(`time.day`, [ cBalance.days ]) }} / &nbsp;
              {{ $tc(`time.hour`, [ Math.floor(cBalance.hoursCapped) ]) }} / &nbsp;
              {{ $tc(`time.minute`, [ cBalance.minutesCapped ]) }}
            </v-col>
          </v-row>
          <v-divider class="mb-1" />
        </div>
        <div>
          <p
              v-for="(paragraph, idx) in cAbout"
              :key="idx"
          >
            {{ paragraph }}
          </p>
        </div>
        <div class="headline">
          Talents
        </div>
        <v-chip-group
            v-model="selectedTalent"
            column
            active-class="primary--text"
        >
          <v-chip
              v-for="talent in cTalents"
              :key="talent.id"
              label
              :value="talent.id"
              @click="onClickTalent"
          >
            {{ talent.name }}
          </v-chip>
        </v-chip-group>
        <div
            v-if="cSelectedTalent"
            ref="belowTalentDescription"
        >
          {{ cSelectedTalent.description }}
        </div>
      </div>
    </template>

    <template #block-awards>
      <div
          v-for="medal in cMedals"
          :key="medal.key"
      >
        <v-row
            v-if="medal.value > 0"
            class="ma-2"
            dense
        >
          <v-col cols="2">
            <v-badge
                inline
                :content="medal.value"
            >
              <eins-icon-medal
                  :type="medal.key"
                  width="36px"
              />
            </v-badge>
          </v-col>
          <v-col
              cols="9"
              class="pa-2 ml-3"
          >
            <div class="subtitle-2">
              <i18n :path="`medals.${medal.key}.caption_get`" />
            </div>
            <div
                class="caption"
                style="color:silver"
            >
              <i18n :path="`medals.${medal.key}.description`" />
            </div>
          </v-col>
        </v-row>
      </div>
    </template>

    <template #block-details>
      <eins-layout-detail-list
          object-type="player"
          :items="cDetails"
      >
        <template #icon="{ item }">
          <eins-icon-friendship
              v-if="item.key === 'friendship'"
              :state="cUserFriendshipState"
          />
          <eins-icon-friendship
              v-else-if="item.key === 'friendOwn'"
              state="friend"
          />
          <eins-icon-friendship
              v-else-if="item.key === 'followerOwn'"
              state="fan"
          />
          <eins-icon-friendship
              v-else-if="item.key === 'idolOwn'"
              state="idol"
          />
        </template>
        <template #title="{ item }">
          <i18n
              v-if="item.key === 'quests'"
              :path="`components.EinsPlayerDetail.details.quest.title`"
          >
            <span>
              <v-icon> mdi-human-greeting</v-icon> {{ cQuests.offering }} / <v-icon>mdi-crown</v-icon> {{ cQuests.closed }}
            </span>
          </i18n>
          <i18n
              v-else-if="item.key === 'quests-own'"
              :path="`components.EinsPlayerDetail.details.quest-own.title`"
          >
            <span>
              <v-icon>mdi-lock-open-variant</v-icon> {{ cQuests.assigned }}
              /<v-icon>mdi-run</v-icon> {{ cQuests.started }}
              /<v-icon>mdi-camera</v-icon> {{ cQuests.finished }}
            </span>
          </i18n>
          <i18n
              v-else-if="item.key === 'groups' && cOwnProfile"
              :path="`components.EinsPlayerDetail.details.group-own.title`"
          >
            <span>{{ cNumGroups }}</span>
            <span>
              <v-icon>mdi-account-supervisor</v-icon> {{ cNumGroupsAdmin }}
            </span>
            <span>
              <v-icon>mdi-wallet-membership</v-icon> {{ cNumGroups - cNumGroupsAdmin }}
            </span>
          </i18n>
          <i18n
              v-else-if="item.key === 'offeringOwn'"
              :path="`components.EinsPlayerDetail.details.offered-own.title`"
          >
            <span>
              <v-icon>mdi-map-marker</v-icon>{{ cQuests.offering }}
              / <v-icon>mdi-cart</v-icon>{{ cDeals }}
            </span>
          </i18n>
        </template>
      </eins-layout-detail-list>
    </template>

    <!-- <template #tab-persons></template> -->
  </eins-layout-item-detail-simple>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsIconMedal from '@/components/icons/EinsIconMedal'
import EinsLayoutDetailList from '@/components/layout/EinsLayoutDetailList'
import EinsLayoutImageOverlay from '@/components/layout/EinsLayoutImageOverlay'
import EinsLayoutItemDetailSimple from '@/components/layout/EinsLayoutItemDetailSimple'
import EinsTimeStars from '@/components/time/EinsTimeStars'

import {
  MxTranslatable
} from '@/mixins'
import {
  minutesPerDay,
  minutesPerHour
} from '@/utils/time'

const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-player.gif`

export default {
  name: 'EinsPlayerDetailBasicData',
  components: {
    EinsApiImage,
    EinsLayoutDetailList,
    EinsIconMedal,
    EinsIconFriendship,
    EinsTimeStars,
    EinsLayoutImageOverlay,
    EinsLayoutItemDetailSimple
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    player: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    imageUrl: `https://picsum.photos/375/200`,
    tab: '',
    selectedTalent: '',
    imageWidth: 100,
    aboutDialog: false
  }),
  computed: {
    cPlayer () {
      return this.player || {}
    },
    cPicture () {
      if (this.player) {
        return this.player.title_picture_feed ?? genericPicture1
      } else {
        return null
      }
    },
    cDetails () {
      const result = []
      const t = (path, params = []) => this.$t(`components.EinsPlayerDetail.${path}`, params)

      result.push({
        key: 'id',
        icon: 'mdi-account',
        title: `#${this.player?.id}`,
        subtitle: t('details.id.sub-title')
      })

      if (!this.cOwnProfile && ['none', 'fan', 'friend', 'idol'].includes(this.cUserFriendshipState)) {
        result.push({
          key: 'friendship',
          title: this.$t(`friendship.state.${this.cUserFriendshipState}.title`),
          subtitle: this.$t(`friendship.state.${this.cUserFriendshipState}.text`)
        })
      }

      if (
        this.cOwnProfile && (
          this.cQuests.offering > 0 ||
              this.cDeals > 0
        )
      ) {
        result.push({
          key: 'offeringOwn',
          icon: 'mdi-account-box-outline',
          title: '', // set in template
          subtitle: t('details.offered-own.sub-title')
        })
      }

      if (this.cOwnProfile) {
        if (this.cQuests.applied > 0) {
          result.push({
            key: 'appliedOwn',
            icon: 'mdi-account-clock',
            title: '', // set in template
            subtitle: t('details.applied-own.sub-title')
          })
        }

        result.push({
          key: 'quests-own',
          icon: 'mdi-map-marker',
          subtitle: t(`details.quest-own.sub-title`)
        })

        if (this.cQuests.closed > 0) {
          result.push({
            key: 'quests-done-own',
            icon: 'mdi-crown',
            title: t(`details.quest-done-own.title`, [ this.cQuests.closed ]),
            subtitle: t(`details.quest-done-own.sub-title`)
          })
        }
      } else {
        result.push({
          key: 'quests',
          icon: 'mdi-map-marker',
          subtitle: t(`details.quest.sub-title`)
        })
      }

      if (this.cDeals > 0 && !this.cOwnProfile) {
        result.push({
          key: 'deals',
          icon: 'mdi-cart',
          title: t('details.deal.title', [ this.cDeals ]),
          subtitle: t(`details.deal${this.cOwnProfile ? '-own' : ''}.sub-title`)
        })
      }

      if (this.cLikes > 0) {
        result.push({
          key: 'likes',
          icon: 'mdi-heart',
          title: t('details.likes.title', [ this.cLikes ]),
          subtitle: t('details.likes.sub-title')
        })
      }

      if (this.cNumMedals > 0) {
        result.push({
          key: 'medals',
          icon: 'mdi-star-circle',
          title: t('details.medal.title', [ this.cNumMedals ]),
          subtitle: t(`details.medal${this.cOwnProfile ? '-own' : ''}.sub-title`)
        })
      }

      if (this.cNumGroups > 0) {
        result.push({
          key: 'groups',
          icon: 'mdi-google-circles-communities',
          title: t(`details.group${this.cOwnProfile ? '-own' : ''}.title`, [ this.cNumGroups, this.cCommon.groups ]),
          subtitle: t(`details.group${this.cOwnProfile ? '-own' : ''}.sub-title`)
        })
      }

      if (this.cOwnProfile) {
        if (this.cContacts.friends > 0) {
          result.push({
            key: 'friendOwn',
            icon: 'mdi-account',
            title: t('details.friend-own.title', [ this.cContacts.friends ]),
            subtitle: t('details.friend-own.sub-title')
          })
        }
        if (this.cContacts.idols > 0) {
          result.push({
            key: 'idolOwn',
            icon: 'mdi-account',
            title: t('details.idol-own.title', [ this.cContacts.idols ]),
            subtitle: t('details.idol-own.sub-title')
          })
        }
        if (this.cContacts.followers > 0) {
          result.push({
            key: 'followerOwn',
            icon: 'mdi-account',
            title: t('details.follower-own.title', [ this.cContacts.followers ]),
            subtitle: t('details.follower-own.sub-title')
          })
        }
      } else if (this.cContacts.friends > 0) {
        result.push({
          key: 'friends',
          icon: 'mdi-account',
          title: t('details.friend.title', [ this.cContacts.friends, this.cCommon.friends ]),
          subtitle: t('details.friend.sub-title')
        })
      }

      return result
    },
    cBlocks () {
      const result = [{
        value: 'about',
        icon: 'mdi-note-text'
      }, {
        value: 'awards',
        icon: 'mdi-star-circle'
      }]

      if (this.cOwnProfile) {
        result.push({
          value: 'details',
          icon: 'mdi-account'
        })
      } else {
        result.push({
          value: 'details',
          icon: 'mdi-account'
        })
      }

      return result
    },
    cUserFriendshipState () {
      return this.player?.friendship_state ?? 'none'
    },
    cOwnProfile () {
      return this.player && this.$store.getters['players/current/id'] === this.player.id
    },
    cTalents () {
      return this.player?.talents ?? []
    },
    cBalance () {
      const raw = this.player?.time_account?.balance_available ?? 0
      const days = Math.floor(raw / minutesPerDay)
      const hoursCapped = (raw - (days * minutesPerDay)) / minutesPerHour
      const hours = Math.floor(raw / 60)
      const minutesCapped = Math.floor(raw % 60)

      return {
        raw,
        days,
        hours,
        hoursCapped,
        minutesCapped
      }
    },
    cAbout () {
      return (this.player?.about || '')
        .split('\n')
        .map(el => el.trim())
        .filter(el => el.length > 0)
    },
    cContacts () {
      return {
        friends: this.player?.friends?.length ?? 0,
        idols: this.player?.idols?.length ?? 0,
        followers: this.player?.fans?.length ?? 0
      }
    },
    cNumGroups () {
      return this.player?.groups?.length ?? 0
    },
    cNumGroupsMember () {
      const allGroups = this.player?.groups ?? []
      const adminGroups = this.player?.groups_admin ?? []

      return allGroups.filter(el => !adminGroups.includes(el)).length
    },
    cNumGroupsAdmin () {
      return this.player?.groups_admin?.length ?? 0
    },
    cCommon () {
      const currentPlayerGroups = this.$store.getters['players/current/groups'] ?? []
      const currentPlayerFriends = this.$store.getters['players/current/friends'] ?? []
      return {
        groups: (this.player?.groups ?? [])
          .filter(el => currentPlayerGroups.includes(el)).length,
        friends: (this.player?.friends ?? [])
          .filter(el => currentPlayerFriends.includes(el)).length
      }
    },
    cNumMedals () {
      return this.cMedals.reduce((acc, el) => acc + el.value, 0)
    },
    cLikes () {
      return this.player?.quests_solved_likes ?? 0
    },
    cDeals () {
      return this.player?.deals_open?.length ?? 0
    },
    cQuests () {
      return {
        closed: this.player?.quests_solved?.length ?? 0,
        finished: this.player?.quests_finished?.length ?? 0,
        offering: this.player?.quests_open?.length ?? 0,
        assigned: this.cOwnProfile ? this.player?.quests_assigned?.length ?? 0 : null,
        started: this.cOwnProfile ? this.player?.quests_started?.length ?? 0 : null
      }
    },
    cSelectedTalent () {
      return this.cTalents.find(el => el.id === this.selectedTalent)
    },
    cMedals () {
      const result = []
      // const medals = this.player?.quests_solved_medals ?? {}
      //  const medals = this.player?.medals ?? []
      /*
    const medals = {
            'common': 20,
            'private': 2
          }
    * */
      const medals = {
        'common': this.player?.medals.filter(medal => medal.type === 'common').length,
        'private': this.player?.medals.filter(medal => medal.type === 'private').length,
        'green': this.player?.medals.filter(medal => medal.type === 'green').length,
        '4': this.player?.medals.filter(medal => medal.type === '4').length,
        '5': this.player?.medals.filter(medal => medal.type === '5').length,
        '6': this.player?.medals.filter(medal => medal.type === '6').length,
        '7': this.player?.medals.filter(medal => medal.type === '7').length,
        '8': this.player?.medals.filter(medal => medal.type === '8').length,
        '9': this.player?.medals.filter(medal => medal.type === '9').length,
        '10': this.player?.medals.filter(medal => medal.type === '10').length,
        '11': this.player?.medals.filter(medal => medal.type === '11').length,
        '12': this.player?.medals.filter(medal => medal.type === '12').length,
        '13': this.player?.medals.filter(medal => medal.type === '13').length,
        '14': this.player?.medals.filter(medal => medal.type === '14').length,
        '15': this.player?.medals.filter(medal => medal.type === '15').length
      }
      for (const key in medals) {
        if (Object.prototype.hasOwnProperty.call(medals, key)) {
          result.push({
            key,
            value: medals[key]
          })
        }
      }
      result.sort((a, b) => {
        const diff = b.value - a.value

        if (diff === 0) {
          return a.key.localeCompare(b.key)
        } else {
          return diff
        }
      })

      return result
    },
    cMedalsCount () {
      return this.player?.medals.length
    },
    cShowImageOverlay () {
      return this.imageWidth > 30
    },
    cImageStyle () {
      return 'max-width:' + this.imageWidth + '%'
    }
  },
  methods: {
    onListScroll (arg) {
      if (arg < this.imageWidth) {
        this.imageWidth = 20
        // this.imageWidth = arg < 20 || isNaN(arg) ? 20 : arg
      }
    },
    onClickTalent () {
      this.scrollToTalentDescription()
    },
    scrollToTalentDescription () {
      this.$nextTick()
        .then((vm) => {
          if (
            vm.$refs.aboutContainer &&
                vm.$refs.belowTalentDescription
          ) {
            vm.$vuetify.goTo(vm.$refs.belowTalentDescription, {
              container: vm.$refs.aboutContainer.parentNode
            })
          }
        })
    },
    goToDetailsTab () {
      this.tab = 'details'
    }
  }
}
</script>

<style scoped lang="scss">
.image-overlay {
  border:3px solid var(--v-player-base);
}
</style>
