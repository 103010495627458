<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column fill-height"
        no-gutters
    >
      <v-col class="pa-3 flex-grow-0">
        <div class="text-h5 mb-0">
          <v-icon
              style="font-size: 1.25em;"
              left
          >{{ cCaption.icon }}</v-icon>
          {{ cCaption.title }}
        </div>
        <div
            v-if="cGroupType"
            class="text-subtitle-1"
        >
          <v-icon
              style="font-size: 1.25em;"
              left
          >{{ cGroupType.icon }}</v-icon>
          {{ cGroupTypeTitle }}
        </div>
      </v-col>
      <v-col
          class="overflow-y-auto pa-3"
      >
        <eins-group-data-name-block />
        <v-row
            align="center"
            class="flex-nowrap"
            style="max-width: 100%;"
        >
          <v-col cols="4">
            <eins-group-data-picture-block
                class="mt-3"
                style="aspect-ratio: 1;"
            />
          </v-col>
          <v-col>
            <div>
              <v-tooltip
                  bottom
                  max-width="50%"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <i18n
                        class="font-weight-bold text-overline"
                        tag="div"
                        path="group.update.contactInformation.title"
                    />
                    <v-row
                        align="center"
                        no-gutters
                    >
                      <v-col class="flex-grow-0">
                        <v-icon left>mdi-receipt</v-icon>
                      </v-col>
                      <v-col class="text-caption">
                        <div v-if="cContactInformation">
                          {{ cContactInformation }}
                        </div>
                        <div v-else>
                          <i18n
                              path="group.update.contactInformation.empty"
                              tag="em"
                              class="font-weight-light grey--text text--darken-1"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <i18n path="group.update.contactInformation.explanation" />
              </v-tooltip>
            </div>
            <div>
              <v-tooltip
                  bottom
                  max-width="50%"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <i18n
                        class="font-weight-bold text-overline"
                        tag="div"
                        path="group.update.email.title"
                    />
                    <v-row
                        align="center"
                        no-gutters
                    >
                      <v-col class="flex-grow-0">
                        <v-icon left>mdi-email-lock</v-icon>
                      </v-col>
                      <v-col class="text-caption">
                        <div v-if="cEmail">
                          {{ cEmail }}
                        </div>
                        <div v-else>
                          <i18n
                              path="group.update.email.empty"
                              tag="em"
                              class="font-weight-light grey--text text--darken-1"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>
                <i18n path="group.update.email.explanation" />
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <eins-group-data-description-block class="mt-3" />
        <eins-group-data-hidden-quests-block
            v-if="cCanHideQuests"
            class="mt-3"
        />
        <eins-group-data-location-block class="mt-3" />
      </v-col>
    </v-row>
    <v-overlay v-model="showCreatedInfo">
      <v-card
          outlined
          color="black"
          class="text-center"
      >
        <v-card-title>
          {{ cText['info.title'] }}
        </v-card-title>
        <v-card-text>
          {{ cText['info.text'] }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="primary"
              @click="hideCreatedInfo"
          >OK</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-overlay>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsGroupDataNameBlock from '@/components/group/edit/EinsGroupDataNameBlock'
import EinsGroupDataPictureBlock from '@/components/group/edit/EinsGroupDataPictureBlock'
import EinsGroupDataDescriptionBlock from '@/components/group/edit/EinsGroupDataDescriptionBlock'
import EinsGroupDataHiddenQuestsBlock from '@/components/group/edit/EinsGroupDataHiddenQuestsBlock'
import EinsGroupDataLocationBlock from '@/components/group/edit/EinsGroupDataLocationBlock'

export default {
  name: 'ViewGroupEdit',
  components: {
    EinsGroupDataLocationBlock,
    EinsGroupDataHiddenQuestsBlock,
    EinsGroupDataDescriptionBlock,
    EinsGroupDataPictureBlock,
    EinsGroupDataNameBlock,
    EinsLayoutViewDetail
  },
  mixins: [MxTranslatable],
  data: () => ({
    alert: {
      success: false,
      error: false
    },
    groupId: 0,
    formData: null,
    originData: null,
    formValid: false,
    showCreatedInfo: true,
    saving: false,
    $_mx_translatable: {
      paths: [
        'captions.caption',
        'info.title',
        'info.text'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cLoading () {
      return this.$store.getters['groups/selected/loading']
    },
    cError () {
      return this.$store.getters['groups/selected/error']
    },
    cContactInformation () {
      return this.cGroup?.contact_information
    },
    cEmail () {
      return this.cGroup?.email
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cCaption () {
      return { icon: 'mdi-account-multiple', title: this.cText['captions.caption'] }
    },
    cGroupType () {
      return this.cGroup?.group_type
    },
    cGroupTypeTitle () {
      return this.cGroupType?.license_name
    },
    cCanHideQuests () {
      return this.cGroupType?.can_hide_quests ?? false
    },
    cPosition () {
      return `${this.formData?.latitude} ${this.formData?.longitude}`
    },
    cPositionOriginal () {
      return `${this.originData?.latitude} ${this.originData?.longitude}`
    },
    cAddress () {
      return `${this.formData?.street} ${this.formData?.house_number}, ${this.formData?.postal_code} ${this.formData?.city} ${this.formData?.country}`
    },
    cAddressOriginal () {
      return `${this.originData?.street} ${this.originData?.house_number}, ${this.originData?.postal_code} ${this.originData?.city} ${this.originData?.country}`
    }
  },
  created () {
    this.showCreatedInfo = !!this.$route.query.justcreated
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchGroup(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.fetchGroup(to.params.id)
    }
    next()
  },
  methods: {
    hideCreatedInfo () {
      this.showCreatedInfo = false
    },
    fetchGroup (id) {
      return this.$store.dispatch('groups/selected/load', id)
    }
  }
}
</script>

<style scoped>

</style>
