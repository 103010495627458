var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('eins-layout-view-detail',[_c('v-card',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"auto"}},[_c('v-card-title',{staticClass:"text-center"},[_c('h4',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.caption'))+" ")])]),_c('v-card-text',[_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","block":"","color":"primary","justify":"space-around"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-database-lock")]),_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProtection.activator.label'))+" ")],1)]}}]),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"detail-content",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"auto"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProtection.dialog.title'))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProtection.dialog.buttons'))+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProtection.dialog.text'))+" ")])],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"tile":"","block":"","color":"primary","justify":"space-around"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-help-network-outline")]),_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProcessing.activator.label'))+" ")],1)]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',{staticClass:"detail-content",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"auto"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProcessing.dialog.title'))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProcessing.dialog.button'))+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.dataProcessing.dialog.text'))+" ")])],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"tile":"","block":"","color":"primary","justify":"space-around"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-presentation-play")]),_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.gamePlayRules.activator.label'))+" ")],1)]}}]),model:{value:(_vm.dialog3),callback:function ($$v) {_vm.dialog3=$$v},expression:"dialog3"}},[_c('v-card',{staticClass:"detail-content",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"auto"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.gamePlayRules.dialog.title'))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialog3 = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.gamePlayRules.dialog.button'))+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.gamePlayRules.dialog.text'))+" ")])],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"tile":"","block":"","color":"primary","justify":"space-around"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-image-text")]),_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.impress.activator.label'))+" ")],1)]}}]),model:{value:(_vm.dialog4),callback:function ($$v) {_vm.dialog4=$$v},expression:"dialog4"}},[_c('v-card',{staticClass:"detail-content",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"auto"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.impress.dialog.title'))+" ")])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.impress.dialog.text'))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialog4 = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.ViewInformations.impress.dialog.button'))+" ")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pl-4"},[_c('v-btn',{attrs:{"color":"grey","to":'/'}},[_vm._v("Cancel")])],1)],1),_c('v-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }