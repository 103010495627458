<template>
  <v-dialog
      v-model="dialog"
      scrollable
  >
    <template #activator="{ on }">
      <div
          class="row pa-2  mr-4"
          v-on="on"
      >
        <template
            v-for="(stat, index) in cStates"
            class="text-no-wrap text-center"
        >
          <div
              v-if="index > 0"
              :key="`str${index}`"
              class="col-1"
          >
            <v-icon
                size="32px"
                :color="stat.reached ? 'quest' : 'questoff'"
            >
              mdi-minus
            </v-icon>
          </div>
          <div
              :key="`state${index}`"
              class="col-1"
          >
            <v-icon
                size="32px"
                :color="stat.reached ? 'quest' : 'questoff'"
            >
              {{ stat.icon }}
            </v-icon>
          </div>
        </template>
      </div>
    </template>
    <v-card
        class="text-center action-dialog detail-content"
        style="position: relative;"
    >
      <v-btn
          icon
          style="position: absolute; top: 0; right: 0;"
          @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-2 text-h5 primary--text">
        {{ cCaption }}
      </v-card-text>
      <v-card-text class="pa-2">
        {{ cText }}
      </v-card-text>
      <v-card-text class="pa-2">
        {{ cTextLong }}
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="actions-btn"
            @click="closeDialog"
        ><i18n path="components.EinsQuestDetailStatesHeader.caption.Abort" /></v-btn>
        <v-spacer />
        <eins-starmenu-ab-apply
            v-if="state === 'apply'"
            class="actions-btn"
        />
        <eins-starmenu-dpb-applications
            v-if="state === 'allocate'"
            class="actions-btn"
        />
        <eins-starmenu-ab-start-quest
            v-if="state === 'start'"
            class="actions-btn"
        />
        <eins-starmenu-ab-finish-quest
            v-if="state === 'running'"
            class="actions-btn"
        />
        <eins-starmenu-ab-approve-quest
            v-if="state === 'completed'"
            class="actions-btn"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsStarmenuAbApply from '@/components/starmenu/EinsStarmenuAbApply'
import EinsStarmenuAbStartQuest from '@/components/starmenu/EinsStarmenuAbStartQuest'
import EinsStarmenuDpbApplications from '../starmenu/EinsStarmenuDpbApplications'
import EinsStarmenuAbFinishQuest from '../starmenu/EinsStarmenuAbFinishQuest'
import EinsStarmenuAbApproveQuest from '@/components/starmenu/EinsStarmenuAbApproveQuest'

const validTypes = [
  'apply',
  'wait',
  'allocate',
  'applicant',
  'start',
  'running',
  'completed',
  'completed_assignee',
  'closed'
]

export default {
  name: 'EinsQuestDetailStatesHeader',
  components: {
    EinsStarmenuAbApproveQuest,
    EinsStarmenuAbFinishQuest,
    EinsStarmenuDpbApplications,
    EinsStarmenuAbApply,
    EinsStarmenuAbStartQuest
  },
  props: {
    state: {
      type: String,
      default: null,
      validator: val => validTypes.indexOf(val) > -1
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    cStates () {
      return [
        { icon: this.isOwner ? 'mdi-map-marker-alert' : 'mdi-human-greeting', reached: this.cStateReached >= 1 },
        { icon: this.isOwner ? 'mdi-human-greeting' : 'mdi-account-clock', reached: this.cStateReached >= 2 },
        { icon: 'mdi-lock-open-variant', reached: this.cStateReached >= 3 },
        { icon: 'mdi-run', reached: this.cStateReached >= 4 },
        { icon: 'mdi-camera', reached: this.cStateReached >= 5 },
        { icon: 'mdi-crown', reached: this.cStateReached >= 6 }
      ]
    },
    cStateReached () {
      switch (this.state) {
        case 'apply':
          return 1
        case 'wait':
          return 1
        case 'allocate':
          return 2
        case 'applicant':
          return 2
        case 'start':
          return 3
        case 'start_owner':
          return 3
        case 'running':
          return 4
        case 'running_owner':
          return 4
        case 'completed':
          return 5
        case 'completed_assignee':
          return 5
        case 'closed':
          return 6
        default:
          return 0
      }
    },
    cCaption () {
      const key = `components.${this.$options.name}.caption.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cText () {
      const key = `components.${this.$options.name}.text.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cStateConfirmed () {
      return this.state === 'closed'
    },
    cTextLong () {
      const key = `components.${this.$options.name}.textLong.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.actions-btn {
  border: 1px solid red
}
.action-dialog{
}
</style>
