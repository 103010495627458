export default {
  ViewQuestDetail: {
    theQuest: 'The @.lower:(terms.quest)',
    theQuestLc: 'the @.lower:(terms.quest)'
  },
  ViewGroupDetail: {
    theGroup: 'The @.lower:(terms.group)',
    theGroupLc: 'the @.lower:(terms.group)'
  },
  ViewChatDetail: {
    theChat: 'The @.lower:(terms.chat)',
    theChatLc: 'the @.lower:(terms.chat)'
  },
  ViewPlayerDetail: {
    thePlayer: 'The @.lower:(terms.player)',
    thePlayerLc: 'the @.lower:(terms.player)'
  },
  ViewDealDetail: {
    theDeal: 'The @.lower:(terms.deal)',
    theDealLc: 'the @.lower:(terms.deal)'
  },
  ViewPlayerDeals: {
    textFilter: {
      label: '@.lower:(terms.player) @.lower:(terms.deals)'
    }
  },
  ViewPlayerFinishedDeals: {
    textFilter: {
      label: 'Finished @.lower:(terms.player) @.lower:(terms.deals)'
    }
  },
  ViewPlayerQuestClosed: {
    textFilter: {
      label: 'Mastered @.lower:(terms.player) @.lower:(terms.quests)'
    }
  },
  ViewPlayerQuests: {
    textFilter: {
      label: ' @.lower:(terms.player) @.lower:(terms.quests)'
    }
  },
  ViewPlayerGroups: {
    textFilter: {
      label: '@.lower:(terms.player) @.lower:(terms.groups)'
    }
  },
  ViewPlayerContacts: {
    textFilter: {
      label: 'My Contacts'
    }
  },
  ViewPlayerBlockages: {
    textFilter: {
      label: 'My blocked @.lower:(terms.players)'
    }
  },
  ViewQuestApplications: {
    textFilter: {
      label: '@.lower:(terms.quest) Applications'
    }
  },
  ViewGroupDeals: {
    textFilter: {
      label: 'Search @.lower:(terms.group) @.lower:(terms.deals)'
    }
  },
  ViewGroupFinishedDeals: {
    textFilter: {
      label: 'Search Finished @.lower:(terms.group) @.lower:(terms.deals)'
    }
  },
  ViewGroupApplications: {
    textFilter: {
      label: '@.lower:(terms.group) Applications'
    }
  },
  ViewDealApplications: {
    textFilter: {
      label: 'Applications for this @.lower:(terms.deal)'
    }
  },
  ViewGroupMembers: {
    textFilter: {
      label: 'Members of this @.lower:(terms.group)'
    }
  },
  ViewGroupQuests: {
    textFilter: {
      label: '@.lower:(terms.quests) of this @.lower:(terms.group)'
    }
  },
  ViewGroupFinishedQuests: {
    textFilter: {
      label: 'mastered @.lower:(terms.quests) @.lower:(terms.group)'
    }
  },
  ViewQuestCreate: {
    captions: {
      private: 'Create @.lower:(terms.private) @.lower:(terms.quest)',
      common: 'Create @.lower:(terms.common) @.lower:(terms.quest)',
      nature: 'Create @.lower:(terms.nature) @.lower:(terms.quest)'
    },
    buttons: {
      submit: {
        label: 'Send @.lower:(terms.quest)'
      },
      cancel: {
        label: 'Abort creation '
      },
      back: {
        label: 'go back'
      }
    },
    alerts: {
      cancel: {
        caption: 'Are you sure?',
        text: 'All unsaved data will be lost'
      }
    }
  },
  ViewMyProfile: {
    caption: 'update profile'
  },
  ViewDealCreate: {
    captions: {
      caption: 'Create your @.lower:(terms.deal)',
      captionService: 'Create @.lower:(terms.service)',
      captionProduct: 'Create @.lower:(terms.product)'
    },
    buttons: {
      submit: {
        label: 'Offer @.lower:(terms.deal)'
      },
      cancel: {
        label: 'Abort creation'
      },
      back: {
        label: 'go back'
      }
    },
    alerts: {
      cancel: {
        caption: 'Are you sure?',
        text: 'All unsaved data will be lost'
      }
    }
  },
  ViewDealEdit: {
    captions: {
      service: 'Edit @.lower:(terms.service)',
      product: 'Edit @.lower:(terms.product)'
    }
  },
  ViewGroupCreate: {
    buttons: {
      back: {
        label: 'Back'
      },
      cancel: {
        label: 'Chancel founding @.lower:(terms.group)'
      },
      choose: {
        label: 'Add Data'
      },
      finish: {
        label: 'Finish'
      },
      submit: {
        label: 'Create'
      },
      request: {
        label: 'Request'
      }
    }
  },
  ViewGroupEdit: {
    captions: {
      caption: 'Edit your @.lower:(terms.group)'
    },
    info: {
      title: 'Your your @.lower:(terms.group) has been created.',
      text: 'On this page, all @.lower:(terms.group) data that is visible in the app can be edited and adjusted.\n' +
        '\n' +
        'Simply complete the @.lower:(terms.group) data now or later. The better the @.lower:(terms.group) is described, the more interesting it is.\n' +
        '\n' +
        'The location is displayed in the app and should be where the playful @.lower:(terms.group) location is.'
    }
  },
  ViewGroupVerify: {
    captions: {
      caption: 'VERIFY LEGAL FORM'
    },
    info: {
      text: 'If your @.lower:(terms.group) represents a legal entity, it must be verified.' +
          '<br>' +
          'Legal entities are, for example:' +
          '<br>' +
          '- Companies' +
          '<br>' +
          '- Authorities' +
          '<br>' +
          '- Schools' +
          '<br>' +
          '- Universities' +
          '<br>' +
          '- Associations' +
          '<br><br>' +
          'Citizents`initiatives or @.lower:(terms.group)s for common hobbies' +
          '<br>' +
          'dont`t have to be verified in 1APP.'
    },
    fields: {
      eMail: {
        hint: 'This @.lower:(terms.group) eMail should verified to start the @.lower:(terms.group). And is used to communicate with icigai1 GmbH after verification.',
        placeholder: 'Your unique email for the @.lower:(terms.group)',
        label: 'Add @.lower:(terms.group) verification email address'
      },
      contact: {
        label: 'Add @.lower:(terms.group) contact person',
        placeholder: 'The name of your contact person for the icigai1 GmbH',
        hint: 'The contact person for queries and for communication with icigai1 GmbH'
      }
    },
    button: {
      submit: 'START VEROFICATION PROCESS',
      info: 'INFORMATION',
      back: 'BACK TO @.lower:(terms.group) PAGE'
    },
    success: {
      title: 'Success',
      text: 'The data was saved successfully!'
    }
  },
  ViewQuestEdit: {
    captions: {
      private: 'Edit @.lower:(terms.private) @.lower:(terms.quest)',
      common: 'Edit @.lower:(terms.common) @.lower:(terms.quest)',
      nature: 'Edit @.lower:(terms.future) @.lower:(terms.quest)'
    }
  },
  ViewTimeBalances: {
    caption: 'Available @.lower:(terms.stars)',
    year: 'Years',
    player: 'All 1-@.proper:(terms.players)',
    my: 'My 1-@.proper:(terms.player)',
    day: 'days',
    star: '@.lower:(terms.stars)',
    point: '@.lower:(terms.points)'
  },
  TheMessagingPermissionDialog: {
    PushNotifications: {
      title: 'Push Notifications',
      text: 'Allow that we send push notifications when someone sends a new Message to you?',
      remember: 'Remember my choice',
      request: 'Could not get token!',
      permission: 'Permission declined, check settings!',
      store: 'Could not store the token!',
      allow: 'Yes',
      notallow: 'No'
    }
  },
  ViewInformations: {
    button: {
      agree: 'agree',
      disagree: 'disagree'
    },
    checkDataProtectionRegulations: {
      label: 'data protection regulations',
      dialog: {
        title: 'Please read and accept the data protection regulations'
      }
    },
    acceptAll: {
      label: 'accept'
    },
    caption: 'Informations about 1APP',
    dataProtection: {
      activator: {
        label: 'Data Protection'
      },
      dialog: {
        title: 'Data Protection ',
        text: 'basic\n' +
            'This data protection declaration is intended to inform customers and users of the offers of icigai1 GmbH\n' +
            'the type, scope, purpose of collecting and using personal data\n' +
            'informed by "icigai1 GmbH".\n' +
            'Basically, the icigai1 GmbH has the operation of the app "1" to a service provider\n' +
            'hand over. The icigai1 designed and programmed the app - our service provider XGE1\n' +
            'UG operates the app and specializes in video games and web applications\n' +
            'host.\n' +
            'It is contractually agreed that XGE1 UG uses the data to\n' +
            '1. XGE1 UG takes the game inputs and data from app “1” on our behalf\n' +
            'against and uses them to keep the game "1" running .\n' +
            '2. XGE1 UG uses the data of the game,\n' +
            'a. to analyze gameplay\n' +
            'b. to improve gameplay\n' +
            'c. to evaluate @.lower:(terms.group)s for license owners (legal entities)\n' +
            'to provide\n' +
            'i. In doing so, no data beyond the usual course of the game is collected\n' +
            'raised\n' +
            'ii. Evaluations relate only to the data that a single @.lower:(terms.player)\n' +
            'can also be seen individually on the @.lower:(terms.group)\n' +
            'iii. This publicly visible data is made available at the request of the customer\n' +
            'summarized and evaluated according to certain aspects\n' +
            'The service provided by XGE1 UG for icigai1 GmbH also includes that the\n' +
            'XGE1 UG is responsible for the actual game operation and for securing the\n' +
            'data takes over.\n' +
            'The icigai1 GmbH receives from the XGE1 UG from the ongoing game operations\n' +
            'our legitimate interest (see Art. 6 Para. 1 lit. f. GDPR) only data that is necessary\n' +
            'are in order to fulfill the contractually agreed services for our customers.\n' +
            '\n' +
            'icigai1GmbH takes your data protection very seriously and treats it\n' +
            'personal data confidentially and in accordance with legal regulations.\n' +
            'We store all data from our customers in a secret place, to which from\n' +
            'For security reasons, only the employees of icigai1 GmbH may access this data\n' +
            'need, for example, to carry out support or billing for our customers.\n' +
            'This access is subject to constant management control. For safety\n' +
            'and to ensure permanent access to this sensitive data, commissions the\n' +
            'icigai1 GmbH regularly hires external service providers and professionals who use our\n' +
            'Test security systems to identify and fix vulnerabilities.\n' +
            'Due to new technologies and the constant further development of our offers,\n' +
            'this data protection declaration must be continuously adapted. It should be here\n' +
            'If there are any changes, you must agree to the data protection declaration again. This\n' +
            'does not happen automatically, you will be informed accordingly by us in this case.\n' +
            'Definitions of terms used (e.g. “personal data” or “processing”)\n' +
            'can be found in Art. 4 GDPR.\n' +
            '\n' +
            'access data\n' +
            'We, icigai1 GmbH, collect data based on our legitimate interest (see Art.\n' +
            '6 para. 1 lit. f. GDPR) data about accesses and entries on the app and save them as “customer log files” on the app server.\n' +
            'The following data is automated when using our online offer\n' +
            'logged:\n' +
            '• Pages/subpages visited\n' +
            '• Time of access\n' +
            '• Amount of data sent in bytes\n' +
            '• Browser / framework used\n' +
            '• Operating system used\n' +
            '• IP address used\n' +
            '\n' +
            'These log files are stored for a maximum of 60 days and then deleted. the\n' +
            'The data is stored for security reasons, e.g. B. clarify cases of abuse\n' +
            'to be able to If data have to be kept for reasons of proof, they are protected from the\n' +
            'Deletion except until the incident is finally resolved.\n' +
            '\n' +
            'Reach measurement & cookies\n' +
            'The app "1" uses cookies for pseudonymised range measurement, which either\n' +
            'transmitted from our server or the server of third parties to the user\'s browser\n' +
            'will. Cookies are small files that are stored on your end device\n' +
            'get saved. Your device accesses these files. Through the use of cookies\n' +
            'increases the user-friendliness and security of this website.\n' +
            'Common devices offer the setting option not to allow cookies. Note: It is\n' +
            'does not guarantee that you will be able to access all functions of this website without restrictions\n' +
            'access if you make the appropriate settings.\n' +
            '\n' +
            'Collection and processing of personal data\n' +
            'icigai1 GmbH only collects, uses and passes on your personal data\n' +
            'if this is permitted by law or if you consent to the collection of data. as\n' +
            'Personal data includes all information that serves to identify your person\n' +
            'to determine and which can be traced back to you - for example yours\n' +
            'Name, your email address and phone number.\n' +
            'You can also visit our online offers/websites without providing any information about your identity\n' +
            'to make person. However, we save to improve our online offer\n' +
            '(without direct personal reference) your access data to this website. To this\n' +
            'Access data include e.g. B. the file you requested or the name of your Internet\n' +
            'providers. Due to the anonymization of the data, conclusions about your person are not possible\n' +
            'not possible to do anything else .\n' +
            '\n' +
            '\n' +
            'Handling contact information\n' +
            'Take with us as the website operator through the offered contact options\n' +
            'connection, your details will be saved so that they can be processed and processed\n' +
            'answering your request can be used. without your consent\n' +
            'this data is not passed on to third parties.\n' +
            'Contact data is stored at as long as the contract exists.\n' +
            'A 1App @.lower:(terms.group) license is always concluded for a period of 12 months. Comes\n' +
            'there is no termination, this license will be tacitly renewed after the expiry of the 12th\n' +
            'months by another 12 months. At the beginning of the new 12-month license period\n' +
            'a new license fee will be charged. For this we use the data from\n' +
            'license holder and the contact person to issue invoices again and\n' +
            'To be able to process / assign payments.\n' +
            'Deletion of contact information\n' +
            'If a customer terminates his license at the end of the 12-month contract period, the\n' +
            'Data deleted from our system after 8 weeks.\n' +
            'During this period, a customer has the right at any time\n' +
            '1. Request the immediate deletion of the data\n' +
            '2. The lossless reactivation of his license - as well as his license data\n' +
            'apply for\n' +
            'icigai1 GmbH reserves the right to revoke licenses due to gross violations of the\n' +
            'cancel game rules and in this context also @.lower:(terms.group)s and data of customers\n' +
            'to remove from the app.\n' +
            '\n' +
            '\n' +
            'User Rights\n' +
            'As a user, you have the right to request free information about\n' +
            'what personal data has been stored about you. You also have this\n' +
            'Right to have inaccurate data corrected and to have your personal data erased\n' +
            'Data. If applicable, you can also exercise your right to data portability .\n' +
            'If you assume that your data has been processed unlawfully, you can\n' +
            'support@icigai1.de at any time and/or lodge a complaint with the competent authority\n' +
            'submit to supervisory authority.\n' +
            'Self- initiated deletion of data\n' +
            'If your request is not related to a legal obligation to store data (e.g.\n' +
            'Data retention) collides, you have the right to have your data deleted\n' +
            'personal data. If deletion cannot be carried out because the\n' +
            'If data is required for permissible legal purposes, there is a restriction of the\n' +
            'data processing. In this case the data will be blocked and not used for other purposes\n' +
            'processed.\n' +
            'Right to object\n' +
            'Users of this website can exercise their right to object and\n' +
            'object to the processing of your personal data at any time.\n' +
            'If you want a correction, blocking, deletion or information about your person\n' +
            'stored personal data or questions regarding the collection,\n' +
            'processing or use of your personal data or have given\n' +
            'If you would like to revoke consent, please contact the following e-mail address:\n' +
            'Support@icigai1.de\n' +
            'Disclosure of data to third parties and third-party providers\n' +
            'Personal data that has been stored by us is fundamentally processed by us\n' +
            'not passed on to third parties and / or third parties.\n' +
            'In cases where data transfer is necessary to protect rights or freedom\n' +
            'To protect uninvolved third parties, we provide public / court-appointed officials\n' +
            'an access with which the search and tracing of individual actions become possible.\n' +
            'Except in such exceptional cases, all customer and personal data remain\n' +
            'within icigai1 GmbH and will not be passed on to external third parties.\n' +
            '\n' +
            'Integration of services and content of third parties\n' +
            '"1" uses the Google font (/Font) " Roboto " as the text font.\n' +
            '\n' +
            '\n' +
            'Jena, July 6, 2022\n' +
            '',
        buttons: 'ok'
      }
    },
    dataProcessing: {
      activator: {
        label: 'Data Processing'
      },
      label: 'data processing information',
      dialog: {
        title: 'Data Processing',
        caption: 'Please read and accept the data processing information',
        text: 'Documentation of the processing activity for game "1"\n' +
            'Information about the person responsible\n' +
            '\n' +
            'Responsible body (according to Art. 4 No. 7 GDPR)\n' +
            'XGE1 UG, Frohngasse 1a, 07745 Jena\n' +
            '\n' +
            'Legal representative (= management)\n' +
            'Michael Dietz, Frongasse 1, 07745 Jena | 015787234290\n' +
            '\n' +
            'Data Protection Officer\n' +
            'Michael Dietz, Rudolstädter Str. 68a, 07745 Jena | 015787234290\n' +
            'Basic information on processing\n' +
            '\n' +
            'Designation of the processing activity:\n' +
            'Provision and evaluation of the 1 game data\n' +
            '\n' +
            'Responsible contact person (incl. specialist department, telephone number and e-mail address):\n' +
            'Michael Dietz, Rudolstädter Str. 68a, 07745 Jena | 015787234290\n' +
            '\n' +
            'Status:\n' +
            '-\tApplication actively available\n' +
            '\n' +
            'Type of processing:\n' +
            '-\tHosting, provision and evaluation of the game "1"\n' +
            ' \n' +
            'General data protection requirements GDPR\n' +
            '\n' +
            'Intended use\n' +
            'Processing activity "evaluation"\n' +
            'Pursue the purpose of "1" and further improve its effect in reality\n' +
            '\n' +
            'Lawfulness of processing, Art. 6 GDPR\n' +
            'Art. 6 para. 1 lit. a, Art. 7\n' +
            '\n' +
            'Is there a high risk for the rights and freedoms of natural persons under Art. 35?\n' +
            'Your own e-mail address, as the only directly personal information, is only used to unequivocally establish the identity of the @.lower:(terms.player) when logging in. No personal data is requested in the game, which means that none can be seen/recorded. The collection and publication of information in 1 is entirely voluntary and only by the @.lower:(terms.player)s of 1. The reference to the person cannot be made by @.lower:(terms.player)s or outsiders within the game. From today\'s perspective, the risk can be assessed as low.\n' +
            '\n' +
            'Collection of the  data\n' +
            'group of people affected\n' +
            'player in 1\n' +
            '\n' +
            'Type of stored data or data categories:\n' +
            'Game data from 1\n' +
            '\n' +
            'origin of the data\n' +
            'Voluntary provision upon registration and during the game by the @.lower:(terms.player)s from 1\n' +
            'Recipients or categories of recipients to whom the data may be communicated\n' +
            '\n' +
            'Internal recipients (within the responsible body)\n' +
            '-\tDepartment "1-Game Data Evaluation"\n' +
            '-\tDepartment "1-Simulation"\n' +
            '-\tDepartment "1-Research"\n' +
            '\n' +
            'External recipients and third parties:\n' +
            '-\ticigai1 GmbH for customer data from licensees\n' +
            '\n' +
            'Standard deadlines for deleting the data\n' +
            'storage duration\n' +
            '-\tActive @.lower:(terms.player) Credentials: Persistent (nothing is automatically deleted)\n' +
            '-\tInactive @.lower:(terms.player) logins: Deletion after 730 days\n' +
            '-\tLogin data of logged out @.lower:(terms.player)s: Deletion after 30 days\n' +
            '\n' +
            '-\tGame data itself will be retained indefinitely and will not be deleted\n' +
            'Appropriateness assessment techn. and org. Measures (TOM)\n' +
            '\n' +
            'General description of the technical and organizational measures (Art. 30 Para. 1 lit. g, Art. 32 Para. 1 GDPR)\n' +
            '\n' +
            '-\tSince we and the @.lower:(terms.player)s could not benefit from this, we do not ask for real first and last names.\n' +
            '-\tE-mail address is not transferred to game "1".\n' +
            '-\tThus, all @.lower:(terms.player)s are pseudonymized in "1" and cannot be traced back to a person\n' +
            '-\tGame data is encrypted in our databases, so even people working on our databases cannot read game content\n' +
            '-\tIf our databases are hacked / data is stolen from, outsiders will not be able to decode the system because the data is unreadable in encrypted form.\n' +
            '-\tThe system with databases is subject to automated checking mechanisms that test the functionality of the security mechanisms every 5 minutes.\n' +
            '-\tIn the event of failures (hacker attacks, overload, etc.), a system alarm is triggered that immediately informs those responsible via smartphone\n' +
            '-\tIn exceptional cases, the database can be immediately put into a protection mode that disables the availability of the server\n' +
            '\n' +
            'Voluntary information\n' +
            '\n' +
            'The processing of data is for the purpose of the game.\n' +
            '\n' +
            '1 works as a game through the mutual, cooperative interaction of the @.lower:(terms.player)s in an action-related context. Cooperation consists of a sequence of action and reaction, which is implemented playfully in a virtual space in “1”.\n' +
            '\n' +
            'All of the input information entered by the @.lower:(terms.player)s in 1 is used to increase the chances of regular game participation within the game in terms of the game goal.\n' +
            '\n' +
            'For this purpose, in accordance with the 1App rules, provisions for content in the game are issued for @.lower:(terms.player)s in such a way that they result in a comprehensible and meaningful connection for @.lower:(terms.player)s in terms of the game goal.\n' +
            '\n' +
            'The type of specific game data use that came about as a result of voluntary information, for example through "artificial intelligence", algorithms or database extensions and their output/use for the game are continuously being further developed and are subject to product secrecy.\n' +
            '\n' +
            'In addition to the use of voluntary information for the course of the game, it can also happen in special cases that public officials / police / public prosecutor or similar approach us to ask us to help in the prosecution of criminal offenses.\n' +
            '\n' +
            'The well-being and freedom of people is - also with us - above everything else. Therefore, if we can assist in legal prosecution, we will\n' +
            'a public official or data protection officer access to our systems to an extent that is necessary for the fulfillment of the matter in order to avert criminal offenses or restrictions on the rights and freedoms of natural persons.\n' +
            '\n' +
            'Such granting of access can be requested from us in writing. Access is only granted for individual cases. Outside third parties do not have permanent access to data.\n' +
            '\n' +
            'Opinion of the data protection officer\n' +
            '\n' +
            'Examination by the data protection officer\n' +
            'Takes place on 05/19/2021\n' +
            'Is there further need for action?\n' +
            'Not for now\n' +
            'Open Measures\n' +
            'no\n' +
            'Date of documentation\n' +
            '05/19/2021\n' +
            'Review by management\n' +
            'Takes place on 05/19/2021\n' +
            'Date, signature\n' +
            '\n“\n' +
            '\n',
        button: 'ok'
      }
    },
    gamePlayRules: {
      activator: {
        label: 'GamePlayRules (EULA)'
      },
      checkDataPlayerRules: {
        label: '1APP Game Rules (EULA)'
      },
      dialog: {
        title: 'GamePlayRules (EULA)',
        caption: 'Please read and accept 1APP Game Rules (EULA)',
        text: '1APP - rules of the game\n' +
            'Rules for using 1 app\n' +
            'prose\n' +
            '\n' +
            '1APP aims to make the world a better place.\n' +
            'We do not pursue any political views or ideas about making a profit.\n' +
            'People should play as people with other people, @.lower:(terms.quest) each other and make the world a little bit better bit by bit.\n' +
            'You can fulfill @.lower:(terms.quest)s from other @.lower:(terms.player)s and, conversely, you can also freely create @.lower:(terms.quest)s for other @.lower:(terms.player)s.\n' +
            'Basically, we are convinced that there can only be a better world if all people have fun, are equal, can participate, respect each other and work together.\n' +
            '"Right" you play 1APP when you go out, complete @.lower:(terms.quest)s in many different places and you also create @.lower:(terms.quest)s for others yourself.\n' +
            'Anything that you think is fun or helps create a better world can become a @.lower:(terms.group), @.lower:(terms.quest) or@.lower:(terms.deal) in 1APP.\n' +
            'Anything that hurts other people or nature does not belong in 1APP.\n' +
            'So respect other @.lower:(terms.player)s, their property and personal rights, and work with and not against other @.lower:(terms.player)s.\n' +
            'While there are no penalties in 1APP, if you use 1APP to harm other @.lower:(terms.player)s, you can be publicly warned in-game and even banned.\n' +
            'In 1APP belongs everything that people should legally do, have done and can offer. Demonstrations, religion and politics do not belong in 1APP. Parties, political actions or election campaigns are therefore excluded from the game.\n' +
            'Of course, everything that is illegal in your country is also excluded from the game.\n' +
            'Since many of our @.lower:(terms.player)s are not of legal age, anything that is not suitable for minors is generally prohibited in 1APP. This includes images, texts and descriptions - but above all alcohol, all types of drugs and intoxicants that fall under the youth protection regulations. Caffeine is the allowed upper limit.\n' +
            'Last: We play 1APP with points and stars instead of money. @.lower:(terms.quest)s and@.lower:(terms.deal)s you offer will get their value in points. Everything that has to do with money takes place outside of 1APP. Posting or accepting a@.lower:(terms.deal) or completing a @.lower:(terms.quest) does not confer in-game rights to cash payments.\n' +
            'Last but not least: We are not your usual social network that silently looks at what is happening on it. We employ content managers who (have to) check all content regularly. Any suspicion of a crime, any insult, and any content that you post contrary to the rules of the game and/or against the law in your country will be reported to the authorities.\n' +
            '\n' +
            '\n' +
            'Own profile page\n' +
            '\n' +
            'To be able to play 1APP, you need to download 1APP from your Google Play Store or Apple i-Store.\n' +
            'We create your @.lower:(terms.player) account for you. You can customize this according to your wishes on your profile page.\n' +
            'If you change a profile picture, follow our rules of the game and don\'t use pictures that don\'t belong to you.\n' +
            'Your profile picture doesn\'t have to be of you, but it shouldn\'t be of other people either.\n' +
            'You can also write a text in which you tell something about yourself. It\'s perfectly fine to describe yourself as the best, handsomest and smartest @.lower:(terms.player), but it\'s forbidden to write anything offensive or derogatory about others here.\n' +
            'other @.lower:(terms.player)s\n' +
            '\n' +
            '1APP is an online multiplayer role-playing game. This means that all other games are also controlled by people like you.\n' +
            'So always be kind!😊 \n' +
            'Don\'t harass other @.lower:(terms.player)s.\n' +
            '\n' +
            ' @.lower:(terms.group)s\n' +
            '\n' +
            ' @.lower:(terms.group)s are public places and squares, but also companies, associations and public administration institutions.\n' +
            ' @.lower:(terms.group)s are there to place @.lower:(terms.quest)s and@.lower:(terms.deal)s on. Other @.lower:(terms.player)s who are interested in that @.lower:(terms.group) can then see all the @.lower:(terms.quest)s and@.lower:(terms.deal)s on the @.lower:(terms.group).\n' +
            'If you create your own @.lower:(terms.group), your @.lower:(terms.group) must correspond to the correct legal form.\n' +
            'This means that you have to create a company as a commercial @.lower:(terms.group) in the game, for example.\n' +
            '1APP provides every kind of @.lower:(terms.group) type for this:\n' +
            '-\t @.lower:(terms.group)s without legal form\n' +
            '(for public places, @.lower:(terms.group)s of friends, @.lower:(terms.group)s for hobbies, families)\n' +
            '-\t @.lower:(terms.group)s for companies\n' +
            '-\t @.lower:(terms.group)s for clubs\n' +
            '-\t @.lower:(terms.group)s for public administrations\n' +
            'For example, creating a real company as a free space in the game without a legal form is against the rules of the game.\n' +
            'Once you have created a @.lower:(terms.group), you can then edit the information in the @.lower:(terms.group).\n' +
            '-\tChoose a picture that belongs to you / your company / your association\n' +
            'o\tWe do not recommend using people or faces in the image of a @.lower:(terms.group)\n' +
            '-\tIn the text you can describe the @.lower:(terms.group) - i.e. your company / association or project. The same applies here: Exaggeratedly beautiful texts about your @.lower:(terms.group) are ok, - it is forbidden to write condescendingly or insultingly about others in this text.\n' +
            'After that you can choose a point on the map that belongs to your @.lower:(terms.group).\n' +
            '-\tPlease only select points on the map that are really related to your @.lower:(terms.group).\n' +
            '-\tDo not place the points on the map on properties or locations that are privately owned by others\n' +
            'Each @.lower:(terms.group) has an associated @.lower:(terms.group) chat where you can write to all members of the @.lower:(terms.group) at the same time.\n' +
            '-\tIt is forbidden to publicly insult other @.lower:(terms.player)s in these chats or to write condescendingly about them\n' +
            '-\tPlease be friendly if something didn\'t work out or you\'re dissatisfied\n' +
            '\n' +
            '@.lower:(terms.quest)\n' +
            '\n' +
            'In @.lower:(terms.quest)s you can give other @.lower:(terms.player)s a task to @.lower:(terms.quest) them in different ways.\n' +
            '\n' +
            'First you can choose between 3 types of @.lower:(terms.quest)s:\n' +
            '-\tRed @.lower:(terms.quest)s are things that should benefit you privately\n' +
            '-\tbenefit our global society\n' +
            '-\tGreen @.lower:(terms.quest)s are things that benefit nature, the future and our children\n' +
            'Please always select the correct category here.\n' +
            '\n' +
            'No illegal activities may be described in the title or in the description of the @.lower:(terms.quest). The content of the @.lower:(terms.quest) should also be compatible with the basic idea of the game "creating a better world".\n' +
            '@.lower:(terms.quest)s should not contain insults or disparage other people\'s achievements.\n' +
            'The @.lower:(terms.quest) should be described as graphically as possible in the picture.\n' +
            '-\tOnly use images that you own\n' +
            '-\tThe image must not infringe any personal rights\n' +
            '-\tThe image must not contain violence, political messages or anything else that could upset other @.lower:(terms.player)s\n' +
            '-\tThe @.lower:(terms.quest) must not violate applicable law\n' +
            'o\tPlease always use your common sense and the local laws and regulations of the country in which you play 1APP as a guide\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Stars / Points / Rewards\n' +
            '\n' +
            'In the game, @.lower:(terms.player)s earn points and stars by completing @.lower:(terms.quest)s.\n' +
            'How many stars a @.lower:(terms.quest) contains is decided by the @.lower:(terms.player) who creates the @.lower:(terms.quest).\n' +
            'Tasks that anyone can easily complete contain a star. If a @.lower:(terms.quest) takes longer than an hour to complete, you should add another star for each additional full hour.\n' +
            'The harder the task, the more stars you should add per hour.\n' +
            '1 star per hour - Easy task\n' +
            '2 stars per hour - tasks that require knowing how to do\n' +
            '3 stars per hour - tasks that require experience\n' +
            '4 stars per hour - tasks that require professional training\n' +
            '5 stars per hour - high difficulty tasks for professionals\n' +
            '6+ stars per hour - For real, exceptional heroic deeds\n' +
            'creating the @.lower:(terms.quest), you decide how many stars the @.lower:(terms.quest) deserves. We trust you, other @.lower:(terms.player)s trust you. It\'s better to give a few stars too many than too few, because:\n' +
            'Stars for blue @.lower:(terms.quest)s come from 1 community account.\n' +
            'Stars for green @.lower:(terms.quest)s come from the 1-Future account.\n' +
            'Only for red @.lower:(terms.quest)s you have to use your own stars.\n' +
            'You can set unlimited blue or green @.lower:(terms.quest)s\n' +
            '\n' +
            '\n' +
            'A star contains 60 points. 24 stars are shown as a white star. 365 white stars are shown as black stars.\n' +
            'As a counterpart,@.lower:(terms.deal)s also contain points. So if you have earned stars in @.lower:(terms.quest)s, you can exchange them for@.lower:(terms.deal)s.\n' +
            'Deals are described in points.\n' +
            '1 point corresponds to €5 purchasing power in 2020.\n' +
            '\n' +
            'deal\n' +
            'Deals are offers that you can provide either as a @.lower:(terms.group) or as a @.lower:(terms.player).\n' +
            '\n' +
            'A@.lower:(terms.deal) image must not depict any goods or services that are prohibited by law. Also, pictures of@.lower:(terms.deal)s must not infringe any personal rights or show things that do not belong to you. Please only use your own pictures. Try to avoid people in the picture who have nothing to do with the@.lower:(terms.deal).\n' +
            '\n' +
            'You may not offer anything in the@.lower:(terms.deal) title or description that is adult, violates any applicable law, or offers illegal activities.\n' +
            '\n' +
            'You have the option to offer your@.lower:(terms.deal) freely - which means that every @.lower:(terms.player) and every @.lower:(terms.group) can click on it and buy it for points - or you can set that @.lower:(terms.group)s and @.lower:(terms.player)s have to apply to you for this@.lower:(terms.deal). In this case, @.lower:(terms.player)s will then send you a request for activation / possibly together with a short text.\n' +
            '\n' +
            'You can then decide whether this @.lower:(terms.player) may receive your offer. Please give your applicants feedback as soon as possible so that it is quickly clear whether the@.lower:(terms.deal) can be concluded or not.\n' +
            '\n' +
            'chat\n' +
            '\n' +
            'Here you can transmit texts to other @.lower:(terms.player)s or entire @.lower:(terms.group)s.\n' +
            '\n' +
            '-\tare forbidden\n' +
            'o\tSpam / advertising texts\n' +
            'o\tinsults\n' +
            'o\tverbal abuse\n' +
            'o\tdisparagements\n' +
            'o\tharassment\n' +
            'o\tembarrassing others\n' +
            'o\tsexism\n' +
            'o\tpolitics\n' +
            'o\tracism\n' +
            '\n' +
            'notification\n' +
            '\n' +
            'Here you will be notified by 1APP when there is something new in the game. If you apply for a @.lower:(terms.quest), for example, you will be shown here when you have been activated by the creator of the @.lower:(terms.quest).\n' +
            '\n' +
            'All reports from all areas arrive here:\n' +
            '\n' +
            '-\tNews on @.lower:(terms.group)s, eg applicants\n' +
            '-\tPlayer updates e.g. when someone follows you or is now your friend\n' +
            '-\tNews from @.lower:(terms.quest)s, e.g. applicants for your @.lower:(terms.quest)s, requests from controls\n' +
            '-\tDeal updates, such as when your@.lower:(terms.deal) has been redeemed by a @.lower:(terms.player) or someone is interested in one of your@.lower:(terms.deal)s - or when you\'ve been approved for a@.lower:(terms.deal).\n' +
            'So please check your notification regularly and decide on one of your interaction options so that the game can continue.\n' +
            '\n' +
            'timeline\n' +
            'Here you can see @.lower:(terms.quest)s that other @.lower:(terms.player)s have completed. You can give these so-called "proofs" a heart if you like what you see.\n' +
            '\n' +
            'report\n' +
            '\n' +
            'We could not control all @.lower:(terms.player)s and processes in the game. To keep the game within the spirit of these rules, we need your help. You can use the report function to report @.lower:(terms.group)s, @.lower:(terms.player)s, @.lower:(terms.quest)s or@.lower:(terms.deal)s to us if content violates the rules of the game. It would be nice of you if you could briefly tell us what\'s wrong with this content so we can categorize it properly. Keep in mind: we are far away and do not know your local peculiarities.\n' +
            'Serious game\n' +
            '1 App is a game with a serious, socially relevant background.\n' +
            '1 you don\'t play against the computer but with other people.\n' +
            '1App alone is not enough - you need other @.lower:(terms.player)s to be able to play 1APP.\n' +
            'In return for our free service, enabling you to do a lot, we require you to follow the rules and treat other 1APP @.lower:(terms.player)s with respect.\n' +
            '\n' +
            'distancing \n' +
            'We expressly distance ourselves from extremist beliefs, whether left, right, liberal, socialist or capitalist - we reject everything that in any way serves to divide people into @.lower:(terms.group)s, to disparage, to oppress, to take advantage of or to disadvantage .\n' +
            'We reject violence, war, weapons and everything that is in any way directly or indirectly related to it.\n' +
            'We also reject anything that evaluates certain people in any way. For us, every person has a fixed, unchangeable value: 1. All content that in any way aims to downgrade or upvalue this value of individual people is against the rules of the game. In 1APP no one is worth more than you. You and all @.lower:(terms.player)s around you have the same rights. There is no difference between @.lower:(terms.player)s.\n' +
            'The rules and prohibitions listed here do not claim to be complete. If something is prohibited by law, it is also against 1APP\'s game rules. When people are judged or discriminated against, it is against the rules of the game. If you cause damage to other @.lower:(terms.player)s or people, you cannot refer to the game or any missing rules in it. Your state and the law that applies to you remain the supreme legal authority for you.\n' +
            'We will not stand idly by like Facebook and Co. when the rules of the game and/or the law are knowingly violated. We take active, fast and tough action against anything that harms our @.lower:(terms.player)s or other people. We immediately report hatred, hate speech, violence, hackers, trolls and extremist @.lower:(terms.group)s.\n' +
            'Please help us and report questionable 1APP content with the number of the @.lower:(terms.player), the @.lower:(terms.group), the @.lower:(terms.quest) or the offer to Alert@xge1.de immediately .\n' +
            'Agree, participate, prohibitions and consequences\n' +
            'If you use "1APP", you agree to the rules and functionality of "1APP". "1APP" follows functionalities and rules of the game that all @.lower:(terms.player)s are bound by. The game has a defined game goal that all @.lower:(terms.player)s work towards together.\n' +
            '" 1APP" should make the world a better place - and not only for our @.lower:(terms.player)s, but also for all other people and nature.\n' +
            '\n' +
            'legal status\n' +
            '1APP is a virtual, digital "ideal" world without money, guilt, companies, rulers and injustice. However, since 1APP is just a game, these things are irrelevant in the true sense of the law.\n' +
            'Participation in the game at 1APP does not release you from the existing legal framework. Your usual legal rights and obligations remain unaffected.\n' +
            '1APP is a game for people. Legal entities cannot register as @.lower:(terms.player)s in 1APP- Legal entities become @.lower:(terms.group)s in the game. Thus, all @.lower:(terms.player)s in 1APP are private individuals.\n' +
            'This means that 1APP takes place as a game within German civil law.\n' +
            'The organizations behind 1APP, icigai1 GmbH and XGE1 UG, expressly distance themselves from all content in the 1APP game, except for those that we as @.lower:(terms.player)s bring to 1APP ourselves.\n' +
            '"Free content" in the sense of @.lower:(terms.quest)s,@.lower:(terms.deal)s and other content that was voluntarily posted by @.lower:(terms.player)s is not influenced or changed by the operator XGE1 UG (limited liability).\n' +
            'Therefore, we cannot guarantee content and an actually profitable, positive and meaningful gameplay, as it is intended by us.\n' +
            'We also cannot guarantee that other @.lower:(terms.player)s will always comply with the game rules, applicable laws, regulations.\n' +
            'We try to use all means and possibilities to keep the content "legal" and compliant with the rules of the game.\n' +
            'To do this, we block content that @.lower:(terms.player)s can report to us at any time.\n' +
            'In order to guarantee this, we depend on your help and that of your fellow @.lower:(terms.player)s: feel free to report anything that strikes you as odd.\n' +
            '\n' +
            'Liability\n' +
            'Liability, guilt and punishment do not exist in the 1APP game.\n' +
            'In the case of damage that occurs in the real world, the legal framework applies, e.g. according to §823/1 BGB. The person who caused the damage is liable for it.\n' +
            'In the event of damage occurring, for example, during or as a result of a @.lower:(terms.quest), in Germany the person who caused the damage is obliged to restore everything as if nothing had ever happened.\n' +
            'As a game provider and operator, we are only liable for damage caused by us.\n' +
            'You must insure yourself for damage that occurs in the game.\n' +
            'In the case of @.lower:(terms.quest)s in 1APP, the creator of a @.lower:(terms.quest) is usually also to be regarded as the "causer of the risk", which does not generally exempt other @.lower:(terms.player)s from joint liability in the event of damage.\n' +
            'In general, it can be said that anyone who leaves their home in their free time or works with other people, or who is professionally or voluntarily committed should always have at least private liability insurance, and even better private accident insurance.\n' +
            'Any insurance office in your area will be happy to advise you on this topic.\n' +
            '\n' +
            'privacy \n' +
            'To secure your personal, personal data, they are not included in 1APP. This means that you only have to confirm your true identity when you log in - and you can remain completely anonymous within 1APP. You only confirm that you are at the entrance to the game.\n' +
            'We have decided not to require your address and place of residence as mandatory information when registering. Because if we don\'t have this data - no one can steal it from us.\n' +
            'You can log into 1APP with your email address. It is your responsibility to ensure that your password is secure.\n' +
            'If something goes wrong, you forget your password or you want to confirm a special action in 1APP, you need access to the email address you used to register in 1APP.\n' +
            'In general, your personal data and the associated game data are only used to make the game "1APP" playable for all @.lower:(terms.player)s. We do not use the data for anything else. We will not pass on or even sell your data without your consent.\n' +
            'Our privacy policy and our data processing policy contain more detailed information on this topic.\n' +
            '\n' +
            'age rating\n' +
            '1APP can be played without age rating (FSK 0) If you are younger than 18, you should definitely (as with all apps ) ask your parents if they agree that you play 1APP. We would be very happy if you got involved in @.lower:(terms.quest)s at a young age - but please discuss this with your parents as well as everything else.\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Visibility / Privacy \n' +
            '\n' +
            'Be aware that anything you post in-game can be seen by any @.lower:(terms.player). All game data can be seen by all @.lower:(terms.player)s. There are no secrets in 1APP.\n' +
            'By default, closed@.lower:(terms.deal)s are only visible to our admins of a @.lower:(terms.group). Only you can see@.lower:(terms.deal)s that you have purchased. Offers, chats and @.lower:(terms.quest)s associated with a @.lower:(terms.group) are only visible to members of the @.lower:(terms.group). However, since every @.lower:(terms.player) can become a member in every @.lower:(terms.group), you have to reckon with the fact that potentially every @.lower:(terms.player) can see your texts.\n' +
            'We therefore urge you to only feed information into the "1APP" app that is "uncritical". You know what is critical and what is not critical for you and others.\n' +
            'Furthermore, you should not enter any information into the game that violates or restricts the property rights or freedoms of third parties. Questionable @.lower:(terms.quest)s that you did not formulate but then fulfill end up on your profile page and stay there permanently. So don\'t sign up for @.lower:(terms.quest)s that may hurt your reputation in game/society.\n' +
            '-\tPlease do not post photos of people who do not expressly request it and have given you their consent\n' +
            '-\tPlease do not post images that identify specific people (other than yourself / if you wish)\n' +
            '-\tWe also advise you not to post any pictures of yourself in 1APP - because this may allow other @.lower:(terms.player)s to draw permanent conclusions about you.\n' +
            'o\tIn 1 app you are equal to other @.lower:(terms.player)s and anonymous\n' +
            'o\tWith a photo of you, you give up a large part of this anonymity, which can later lead to disadvantages that other @.lower:(terms.player)s do not have\n' +
            'You get control over @.lower:(terms.quest)s and@.lower:(terms.deal)s that have not yet been noticed by other @.lower:(terms.player)s. @.lower:(terms.quest)s completed by you or other @.lower:(terms.player)s remain part of the gameplay.\n' +
            'It is not possible to subsequently change the content of @.lower:(terms.quest)s or@.lower:(terms.deal)s. In special cases, a written request for a content change must be submitted to us for a subsequent change.\n' +
            'These requests are accepted at Support@XGE1.de . There is no right to subsequent changes. Only @.lower:(terms.player)s directly associated with the record being changed can request changes.\n' +
            'If a third party who is not involved in the data set reports violations of his or her rights or freedoms, we will forward this report to the @.lower:(terms.player)s involved in the data set. If necessary, a @.lower:(terms.player) involved in the data set must then submit a data change request to us.\n' +
            '\n' +
            '\n' +
            'Express prohibitions in 1APP\n' +
            '1.\tYou will only create one @.lower:(terms.player) account for yourself and not a second one\n' +
            '2.\tYou will only create one @.lower:(terms.group) per legal entity (company, association, etc.).\n' +
            '3.\tYou will never act on behalf of another human without express permission\n' +
            '4.\tYou will not seek login information or access an account owned by someone else\n' +
            '5.\tYou will not spread political or extremist opinions\n' +
            '6.\tYou will not post any unauthorized commercial communications (such as spam) on 1APP\n' +
            '7.\tYou will not post content that: contains hate speech, is threatening or pornographic, incites violence, or contains nudity or violence\n' +
            '8.\tYou will not collect any Member Content or information or otherwise access 1APP using automated means (such as bots, robots, spiders or scrapers ) unless you have our prior permission\n' +
            '9.\tYou will not operate illegal structured sales, such as pyramid schemes, on 1APP\n' +
            '10.\tYou will not upload viruses or any other malicious code\n' +
            '11.\tYou will not bully, intimidate, harass or bully other @.lower:(terms.player)s or @.lower:(terms.group)s of @.lower:(terms.player)s\n' +
            '12.\tYou will not use 1APP to perform any unlawful, deceptive, malicious or discriminatory activity\n' +
            '13.\tYou will not take any action that could block, overburden, or impair the proper functioning or appearance of 1APP, such as a denial - of -service attack, or disrupt any site or other functionality of 1APP\n' +
            '14.\tYou will neither support nor encourage any violations of these rules and in the best case report them promptly to support@xge1.de\n' +
            '15.\tAs the "leader" of a @.lower:(terms.group), you will truthfully provide information about the legal form and the associated information requested, or enter this truthfully when creating a @.lower:(terms.group)\n' +
            '16.\tYou will not use your @.lower:(terms.group) to lead informal benefits through " @.lower:(terms.group) Leader" purchases through the Community Account Benefits to people or businesses who are not playing 1APP - or have their own @.lower:(terms.group) License. Everything you use as leather for your @.lower:(terms.group) can only be used by your @.lower:(terms.group) or the license holder of the @.lower:(terms.group).\n' +
            '17.\tYou will not commercialize any data of other @.lower:(terms.player)s, regardless of whether you are a free @.lower:(terms.player), club or paid corporate customer.\n' +
            '18.\tYou may not use information received in 1APP outside of the game to direct profit-oriented offers to fellow @.lower:(terms.player)s or @.lower:(terms.group)s outside of 1APP\n' +
            '19.\tIf you want to offer commercial/discounted offers, you need a @.lower:(terms.group) with a commercial license.\n' +
            '20.\tYou will not use @.lower:(terms.quest)s to offer or promote undeclared work or low wages in real working life\n' +
            '21.\tYou will not violate applicable law during a @.lower:(terms.quest)\n' +
            '22.\tYou will not incite other @.lower:(terms.player)s to commit any legal or administrative offenses\n' +
            ' \n' +
            '\n' +
            '23.\tYou will report obvious potential crimes that have been posted in 1APP immediately to the police first and then to support@XGE1.de  \n' +
            '24.\tYou will report any legal violation that arises in connection with 1APP\n' +
            '25.\tYou do not give alcohol, cigarettes, drugs, narcotics, medication or similar to other @.lower:(terms.player)s in the game.\n' +
            '26.\tIn particular, you always observe the Youth Protection Act to the full extent\n' +
            '27.\tYou do not call for vigilante justice or carry it out yourself with help\n' +
            '28.\tYou will not use 1APP to embezzle, conceal or evade taxes for private or corporate profits\n' +
            '29.\tIf you act as a responsible "leader" in @.lower:(terms.group)s, you will not carry out any actions without consultation and announcement that have direct financial, legal or tax effects on the @.lower:(terms.group) members\n' +
            '30.\tYou will not use 1APP to implement public service administrative tasks\n' +
            '31.\tYou will not use 1APP to conduct administrative tasks with citizens as an official of a public authority\n' +
            '32.\tIn 1APP App, no @.lower:(terms.player) is above another @.lower:(terms.player). Your real-world rank and associated sovereignty over other people are not transferrable to the game\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Account-related bans and security \n' +
            '1.\tYou will not create a profile for anyone else.\n' +
            '2.\tYou will create only one personal account.\n' +
            '3.\tYou will not open a @.lower:(terms.group) for a company, association or other legal entity unless this has been expressly agreed and approved by the management/owner/board of directors or the relevant responsible person of the company/association/legal entity.\n' +
            'a.\tYou will not create a @.lower:(terms.group) for companies or associations where you are an outside third party. If you do not work for this company, you are not allowed to create a @.lower:(terms.group) for this company.\n' +
            'b.\tIf you create a @.lower:(terms.group) for a company, for example, and then leave the company, you will transfer any leader function of the @.lower:(terms.group) to other/remaining employees of the @.lower:(terms.group).\n' +
            'c.\tYou will not lead a legal entity @.lower:(terms.group) as a leader if the key people responsible for the company/association/legal entity do not wish to do so. Upon request, you will transfer your leader function to the management of the company or the board of an association (also applies to all other legal forms)\n' +
            '4.\tIf you lead a @.lower:(terms.group) as a leader, you will lead the @.lower:(terms.group) taking into account all legal provisions and the 1APP rules of the game.\n' +
            'a.\tYou will not post any political or radical content in the description of the @.lower:(terms.group)\n' +
            'b.\tThe same applies to the image of the @.lower:(terms.group)\n' +
            'c.\tIf a @.lower:(terms.player) violates any law or the rules of the game within the space you specify, you may terminate the @.lower:(terms.player)\'s membership in your space. Content that has not been completed on the @.lower:(terms.group) by the @.lower:(terms.player) in question will automatically expire.\n' +
            'd.\tAs a @.lower:(terms.group) leader, you are responsible for the @.lower:(terms.group) and what happens in it - we - as XGE1 UG, will not influence content or your actions.\n' +
            'e.\tIf, contrary to the rules of the game, even criminally relevant content appears in your @.lower:(terms.group), you first have the obligation to report this to the public authorities such as the police - and then to us.\n' +
            '5.\tIf your @.lower:(terms.player) account is locked, you will not create another one without permission.\n' +
            '6.\tYou will not use 1APP if you are under the age of 16.\n' +
            '7.\tYou will not share your password (or secret key), allow anyone else to access your account, or do anything else that could compromise the security of your account.\n' +
            '8.\tYou will not transfer your account to anyone without first obtaining our written permission.\n' +
            '9.\tIf you choose a @.lower:(terms.player) name or similar designation for your account or page, we reserve the right to change it if we deem it necessary (for example, if the trademark owner has a complaint about a @.lower:(terms.player) name which does not correspond to a member\'s real name).\n' +
            ' \n' +
            'Protecting the Rights of Others\n' +
            '1.\tWe respect the rights of others and expect you to do the same.\n' +
            '2.\tYou will not use 1APP to harm, insult, discriminate or similar other @.lower:(terms.player)s, other people, institutions or companies.\n' +
            '3.\tIf you don\'t like 1 app, you will delete 1 app. You will also not harm, offend or discredit our employees or service providers.\n' +
            '4.\tYou will not copy functions that you see and use in 1 app and use them in your own / other products.\n' +
            '5.\tImages and texts that you upload to "1APP" legally belong to the website operator XGE1. However, you retain the authority to decide what to do with this information in the future. Data that is already firmly intertwined with the course of the game may not be able to be deleted afterwards. So please think carefully beforehand about what information you are sending.\n' +
            '6.\tYou will not post content on 1APP or perform any actions on 1APP that violate the rights of another person or the law.\n' +
            '7.\tWe may remove any content or information you have posted on 1APP if we believe it violates this Statement or our policies.\n' +
            '8.\tIf we remove your content for infringing on someone else\'s copyright and you believe it was in error, we will give you an opportunity to respond. If you repeatedly infringe the intellectual property rights of others, we may suspend your account.\n' +
            '9.\tYou and your @.lower:(terms.group)s that you create must not appear as if you or your @.lower:(terms.group) have any influence on higher-level game processes outside of the @.lower:(terms.group) or your account.\n' +
            '10.\tIf you collect information from @.lower:(terms.player)s for subsequent commercial use, you will:\n' +
            'a.\tPurchase a commercial license for your @.lower:(terms.group) from icigai1 GmbH\n' +
            'b.\tControl your activities through this @.lower:(terms.group)\n' +
            'c.\tAdhere to the applicable data protection regulations\n' +
            '11.\tYou will not request or post any identification or sensitive financial information from anyone on 1APP.\n' +
            '12.\tAll granted rights to use 1APP can be revoked by us at any time without notice.\n' +
            '13.\tYou will not sell, transfer, or sublicense your license, our code, APIs, or tools to anyone.\n' +
            '14.\tYou will not misrepresent your relationship with 1APP or the organizations behind it to others.\n' +
            '15.\tWe may issue a press release describing our relationship with you if you give us cause to do so.\n' +
            '16.\tYou will comply with all applicable laws.\n' +
            '17.\tYou grant us all rights necessary for 1APP to function on all devices, including the right to integrate the content and information you provide to us into message flows, timelines and reports about @.lower:(terms.player) actions in the game.\n' +
            '\n' +
            '\n' +
            '\n' +
            'consequences of violations \n' +
            'Violations are@.lower:(terms.deal)t with in different escalation levels:\n' +
            '1.\tWarning for breaking the rules of the game\n' +
            'a.\tThe @.lower:(terms.player) will be warned in writing to refrain from or stop certain actions in the future, or to remove content\n' +
            'b.\tA first, second and third admonition follows\n' +
            'i.\tThe warnings are issued at intervals of 7 days if the @.lower:(terms.player) has not complied with the request to adapt the content.\n' +
            '2.\tWarning for breaking the rules of the game\n' +
            'a.\tIf the @.lower:(terms.player) does not refrain from the illegal action and/or continues to submit, he will receive a warning\n' +
            'b.\tAfter the third warning, the @.lower:(terms.player) account will be blocked for one month\n' +
            'c.\tIf, after the third warning on a similar matter, another warning is due (after another previous 3-time warning in vain), the @.lower:(terms.player) account will be blocked for 6 months.\n' +
            'd.\tIf, after a fourth warning on a similar matter, another warning is due (after another previous 3-time warning in vain), the @.lower:(terms.player) account will be blocked for 12 months.\n' +
            'e.\tIf, after a fifth warning on a similar matter, another warning is due (after another previous 3-time warning in vain), the @.lower:(terms.player) account will be blocked for an indefinite period .\n' +
            'f.\tDuring a suspension, @.lower:(terms.player)s can request reactivation of the account at support@xge1.de .\n' +
            'g.\tApplications for (early) reactivation are examined individually and not decided publicly.\n' +
            '3.\tAdmonition and warning in the event of violations of applicable law\n' +
            'a.\tIf content in the app is obviously illegal, the @.lower:(terms.player) will receive a warning from us with an immediate call for action in the form of the content change\n' +
            'b.\tIf the @.lower:(terms.player) does not comply with this request immediately, an immediate warning will be issued. We can then reduce the interval between the first, second and third warning to up to 60 seconds without warning, depending on the threat situation.\n' +
            '4.\t1-3 also applies to @.lower:(terms.player)s who, in their capacity as leaders of a @.lower:(terms.group), use the @.lower:(terms.group) to post illegal content or content that violates the rules of the game\n' +
            '5.\tViolations in @.lower:(terms.group)s are immediately reported to the license holder.\n' +
            '6.\t @.lower:(terms.group)s that do not adjust or delete illegal content or content that violates the rules of the game, despite warnings and warnings, will be deactivated by us or deleted directly.\n' +
            '7.\tDamages that we incur as a result of your illegal or irregular use will be asserted in court.\n' +
            '\n' +
            '\n' +
            'changes\n' +
            'Unless we make a change for legal or administrative reasons, or to correct an inaccurate statement in the Articles of Incorporation, Manual or Terms of Service, we will give you an opportunity to review the changes to this Statement.\n' +
            'Your continued use of 1APP following changes to our Articles of Association or Manual will also mean your acceptance of our new/amended terms.\n' +
            '\n' +
            'termination\n' +
            'If you violate the rules listed here, otherwise create legal risks for us, actively cause damage to the operating companies, employees or our partners, we can stop providing 1APP to you in whole or in part. This also applies if harmful actions were taken against us in the course of your professional activities.\n' +
            'In this case, you will be informed or warned by e-mail and in 1APP.\n' +
            'If this happened to you, send your email to support@xge1.de .\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            ' \n' +
            'disputes\n' +
            'You will resolve any claim, cause of action or dispute (claim) you have against us arising out of or in connection with this Statement or XGE1 UG or 1APP exclusively in the local court having jurisdiction for Jena (Germany). and you agree to submit to the personal jurisdiction of such courts in any trial of any such claim.\n' +
            'This statement and any claims that may arise between you and us are governed by the laws of the Federal Republic of Germany.\n' +
            'If someone brings a claim against us regarding your actions, your content, or your information on 1APP, you will indemnify us for all damages, losses and expenses (including reasonable attorneys\' fees and legal costs). While we provide rules for @.lower:(terms.player) conduct, we do not control or direct the actions of @.lower:(terms.player)s on 1APP, and are not responsible for the content or information that @.lower:(terms.player)s transmit or share on 1APP.\n' +
            'We are not responsible for any indecent, inappropriate, obscene, unlawful or otherwise objectionable content or information that you may encounter on 1APP. We are not responsible for the behavior of 1APP @.lower:(terms.player)s, either online or off the internet.\n' +
            'We strive to keep 1APP operating error-free and secure, however use of 1APP is at your own risk. We provide 1APP "as is" without any warranties of any kind, either express or implied , including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.\n' +
            'We do not guarantee that 1APP will always be safe, secure, or error-free, or that 1APP will always function without interruptions, delays, or defects.\n' +
            '1APP is not responsible for the actions, content, information or data of third parties and you release us from any liability for claims and damages of any kind, known or unknown, arising out of or related to any dispute with third parties.\n' +
            'We will not be liable to you for any profit, loss or other consequential, special, indirect or incidental damages arising out of or in connection with this Statement or 1APP, even if we have been advised of the possibility of such damages.\n' +
            'Applicable laws may not allow the limitation or exclusion of liability for incidental consequential damages, so the above limitation or exclusion may not apply to you. In these cases, 1APP\'s liability is limited to the maximum extent permitted by law.\n' +
            '\n' +
            'definitions\n' +
            '1APP includes all functions and service offerings that we provide via our app "1APP". The term "platform", "app" and "website" refers to a set of application programming interfaces (APIs) and services (such as content) through which others, such as app developers and website operators, receive data from 1APP access or provide us with data.\n' +
            'By "information" we mean facts and other information about you, including actions taken by @.lower:(terms.player)s and non-players interacting with 1APP. "Content" means anything that you or other @.lower:(terms.player)s post on 1APP that is not covered by the definition of "Information".\n' +
            'By “data” or “player data” or “player data” we mean any data, including @.lower:(terms.player) content or information, that you or third parties can access from 1APP.\n' +
            'By "posting" and "sharing" we mean posting content to 1APP or otherwise making @.lower:(terms.quest)s available through a device using 1APP.\n' +
            'Nothing in this Statement shall prevent us from complying with the law. This statement does not grant any third party any right of ownership or title. We reserve all rights not expressly granted to you. You will comply with all applicable laws when using or accessing 1APP.\n' +
            '\n' +
            '\n“ \n' +
            '\n',
        button: 'ok'
      }
    },
    impress: {
      activator: {
        label: 'Impressum'
      },
      dialog: {
        title: 'Impressum',
        text: 'icigai1 GmbH \n' +
          '\n' +
          'Vertreten durch den Geschäftsführer:  \n\r' +
          'Jan Oertel\n\r' +
          '\n' +
          'Eintragung:  \n' +
          'Amtsgericht Jena | HRB 514382\n' +
          '\n' +
          'Kontakt\n' +
          '\n' +
          'icigai 1 GmbH\n' +
          'Moritz-von-Rohr-Str. 1a\n' +
          '\n' +
          '07745 Jena\n' +
          '\n' +
          'Mail                support@icigai1.com\n' +
          '\n' +
          'Web                www.icigai1.com',
        button: 'ok'
      }
    }
  }
}
