export default {
  state: {
    friend: {
      title: 'Freund',
      titleFilter: 'Freunde',
      text: 'Der @.lower:(terms.player) ist Dein Freund'
    },
    idol: {
      title: 'Idol',
      titleFilter: 'Idole',
      text: 'Der @.lower:(terms.player) ist Dein Idol'
    },
    fan: {
      title: 'Fan',
      titleFilter: 'Fans',
      text: 'Der @.lower:(terms.player) ist Dein Fan'
    },
    none: {
      title: 'nichts',
      titleFilter: 'unbekannt',
      text: 'Du hast keine Beziehung zu diesem @.lower:(terms.player)'
    }
  }
}
