export default {
  'description': '@:(terms.description)',
  'talents': 'Talente',
  'details': {
    'id': {
      'title': '#{0}',
      'sub-title': '@:(terms.player) ID'
    },
    'likes': {
      'title': '{0} Likes hat der @.lower:(terms.player) erhalten',
      'sub-title': 'Likes werden für abgeschlossene @.lower:(terms.quests) vergeben.'
    },
    'quest': {
      'title': '@:(terms.quests) {0}',
      'sub-title': '@:(terms.quests) angeboten / abgeschlosse vom @.lower:(terms.player)'
    },
    'quest-own': {
      'title': '@:(terms.quests): {0}',
      'sub-title': '@:(terms.quests) die vergeben / gestartet / fertig sind'
    },
    'quest-done-own': {
      'title': 'Completed @.lower:(terms.quests): {0}',
      'sub-title': 'Du hast bereits so viele abgeschlosse @:(terms.quests)'
    },
    'medal': {
      'title': '{0} @.lower:(terms.medals)',
      'sub-title': 'Die Anzahl von @.lower:(terms.medals) des @.lower:(terms.player) für abgeschlossene @.lower:(terms.quests) .'
    },
    'medal-own': {
      'sub-title': 'Die Anzahl der @.lower:(terms.medals) die Du für abgeschlossene @.lower:(terms.quests) bekommen hast.'
    },
    'deal': {
      'title': '{0} angebotene @.lower:(terms.deals)',
      'sub-title': 'Die Anzahl der angebotenen @.lower:(terms.deals) des @.lower:(terms.player).'
    },
    'deal-own': {
      'title': '{0} angebotene @.lower:(terms.deals)',
      'sub-title': 'Die Anzahl Deiner angebotenen @.lower:(terms.deals).'
    },
    'group': {
      'title': '@:(terms.groups): {1} gemeinsam mit Dir / ({0} alle )',
      'sub-title': 'Die Anzahl der @.lower:(terms.groups) gemeinsam Mitgliedschaft mit dem @.lower:(terms.player).'
    },
    'friend': {
      'title': 'Freunde: {1} gemeinsam mit Dir you ({0} alle )',
      'sub-title': 'Anzahl der @.lower:(terms.players) die mit euch befreundet sind @.lower:(terms.player).'
    },
    'group-own': {
      'title': '@:(terms.groups): {0} ({1} als Admin / {2} als Mitglied)',
      'sub-title': 'Anzahl der @.lower:(terms.groups) in denen Du Mitglied bist.'
    },
    'friend-own': {
      'title': 'Freunde: {0}',
      'sub-title': 'Anzahl der @.lower:(terms.players) die mit Dir befreundet sind.'
    },
    'idol-own': {
      'title': 'Idole: {0}',
      'sub-title': 'Anzahl der @.lower:(terms.players) die Idole für Dich sind.'
    },
    'follower-own': {
      'title': 'Anhänger: {0}',
      'sub-title': 'Anzahl der @.lower:(terms.players) die Dir folgen.'
    },
    'applied-own': {
      'title': 'auf @.lower:(terms.quests): {0} @.lower:(terms.deals) {0} beworben',
      'sub-title': 'Anzahl der @.lower:(terms.quests) / @.lower:(terms.deals) auf die Du Dich beworben hast.'
    },
    'offered-own': {
      'title': 'Angeboten: {0}',
      'sub-title': 'Anzahl der selbst angebotenen @:(terms.quests) / @.lower:(terms.deals).'
    }
  }
}
