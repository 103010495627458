<template>
  <div
      style="top:0; left:0; right:0; bottom:0; position:absolute; overflow: hidden;"
      class="d-flex flex-column"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EinsLayoutMap',
  props: {},
  data: () => ({
  })
}
</script>

<style
    scoped
    lang="scss"
>
</style>
