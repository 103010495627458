<template>
  <div></div>
</template>

<script>
export default {
  name: 'ViewConfirmEmail',
  components: {},
  computed: {
    cLoading () {
      return false
    }
  },
  methods: {
    onScroll (e) {
    }
  }
}
</script>

<style scoped>

</style>
