<template>
  <v-overlay
      absolute
      :value="value"
      @input="modelValue => $emit('input', modelValue)"
  >
    <v-progress-circular
        indeterminate
        size="120"
        width="1"
        color="primary"
        class="text-center"
    >
      <span class="grey--text font-weight-thin text-h5">
        <img
            width="70px"
            height="70px"
            src="./../../assets/img/icons/ui/loading.png"
        >
      </span>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'TheRefreshingOverlay',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
