<template>
  <v-select
      v-bind="$attrs"
      :items="cSelectOptions"
      :label="cLabel"
      :value="selection"
      @input="onInput"
  >
    <template
        v-if="!selection"
        #prepend-inner
    >
      <v-list-item-avatar>
        <v-avatar>
          <v-icon large>{{ cAvatarIcon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
    </template>
    <template #no-data>
      <v-list-item>
        <v-list-item-avatar>
          <v-avatar>
            <v-icon large>{{ cAvatarIcon }}</v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ cNoDataText }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar>
        <eins-api-image
            v-if="item.avatar"
            #default="{ src }"
            :src="item.avatar"
        >
          <v-avatar>
            <v-img :src="src" />
          </v-avatar>
        </eins-api-image>
        <v-avatar v-else>
          <v-icon large>{{ cAvatarIcon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #selection="{ item }">
      <v-list-item-avatar>
        <eins-api-image
            v-if="item.avatar"
            #default="{ src }"
            :src="item.avatar"
        >
          <v-avatar>
            <v-img :src="src" />
          </v-avatar>
        </eins-api-image>
        <v-avatar v-else>
          <v-icon large>{{ cAvatarIcon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'

export default {
  name: 'EinsSelectUser',
  components: { EinsApiImage },
  props: {
    users: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    noDataText: {
      type: String,
      default: null
    },
    avatarIcon: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    selection: null
  }),
  computed: {
    cSelectOptions () {
      const result = []

      if (this.users) {
        for (const user of this.users) {
          result.push({
            avatar: user.title_picture_feed,
            text: user.name,
            value: user.id
          })
        }
      }

      return result
    },
    cLabel () {
      return this.label ?? this.$t('player.select.label')
    },
    cAvatarIcon () {
      return this.avatarIcon ?? 'mdi-account'
    },
    cNoDataText () {
      return this.noDataText ?? this.$t('player.select.noData')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.selection = value
    },
    onInput (value) {
      this.selection = value
      this.emitInput()
    },
    emitInput () {
      this.$emit('input', this.selection)
    }
  }
}
</script>

<style scoped>
</style>
