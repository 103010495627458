<template>
  <widget-change-group-owner-provider
      #default="context"
      :group-id="groupId"
  >
    <v-card class="detail-content">
      <v-card-title><i18n path="group.changeOwnership.title" /></v-card-title>
      <v-expand-transition>
        <v-card-text>
          <v-skeleton-loader
              :loading="context.loading.group || context.loading.admins"
              type="text@3, list-item-avatar"
          >
            <i18n path="group.changeOwnership.text">
              <em>{{ context.groupTitle }}</em>
            </i18n>
            <eins-select-user
                avatar-icon="mdi-shield-star-outline"
                :disabled="context.disableSelection"
                hide-details
                :users="context.availableAdmins"
                :label="cText.selectLabel"
                :no-data-text="cText.noData"
                solo
                flat
                :value="context.selection"
                @input="context.onChangeSelection"
            />
          </v-skeleton-loader>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          v-if="context.success"
          type="success"
          text
          tile
          transition="expand-transition"
      >
        <i18n path="group.changeOwnership.success">
          <em>{{ context.groupTitle }}</em>
          <em>{{ context.selectedPlayerName }}</em>
        </i18n>
      </v-alert>
      <v-alert
          v-if="context.error"
          type="error"
          text
          tile
          transition="expand-transition"
      >
        {{ context.errorText }}
      </v-alert>
      <v-card-actions>
        <v-row
            v-if="!context.success"
            dense
            class="flex-column"
        >
          <v-col>
            <v-btn
                block
                color="primary"
                :disabled="context.disableSubmit"
                :loading="context.loading.submit"
                outlined
                text
                @click="context.onClickSubmit"
            >
              {{ cText.submitButton }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                block
                :disabled="context.loading.submit"
                :loading="context.loading.submit"
                outlined
                text
                @click="onClickClose"
            >
              {{ cText.cancelButton }}
            </v-btn>
          </v-col>
        </v-row>
        <eins-btn-timed
            v-if="context.success"
            block
            outlined
            text
            @click="onClickClose"
        >
          {{ cText.closeButton }}
        </eins-btn-timed>
      </v-card-actions>
    </v-card>
  </widget-change-group-owner-provider>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import EinsSelectUser from '@/components/input/EinsSelectUser'
import WidgetChangeGroupOwnerProvider from '@/components/group/WidgetChangeGroupOwnerProvider'

export default {
  name: 'WidgetChangeGroupOwner',
  components: {
    WidgetChangeGroupOwnerProvider,
    EinsSelectUser,
    EinsBtnTimed
  },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  computed: {
    cText () {
      return {
        selectLabel: this.$t('group.changeOwnership.select.label'),
        noData: this.$t('group.changeOwnership.select.noData'),
        cancelButton: this.$t('group.changeOwnership.buttons.cancel'),
        closeButton: this.$t('group.changeOwnership.buttons.close'),
        submitButton: this.$t('group.changeOwnership.buttons.submit')
      }
    }
  },
  methods: {
    onClickClose () {
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
