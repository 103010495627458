<template>
  <v-row
      class="flex-column"
      dense
  >
    <v-col
        v-for="option of cOptions"
        :key="option.key"
    >
      <v-checkbox
          v-model="options[option.key]"
          :label="option.title"
          :hint="option.description"
          :persistent-hint="option.description !== null"
          @change="onChangeOption"
      />
    </v-col>
  </v-row>
</template>

<script>
const knownTypes = [
  'email',
  'fcm'
]
const getEmptyItem = () => ({
  notification_types: []
})

export default {
  name: 'EinsFormNotificationType',
  props: {
    value: {
      type: Object,
      default: getEmptyItem()
    }
  },
  data () {
    const options = {}

    for (const type of knownTypes) {
      options[type] = false
    }

    return {
      options,
      internalValue: getEmptyItem()
    }
  },
  computed: {
    cOptions () {
      const result = []

      for (const type of knownTypes) {
        const tmp = {
          key: type,
          title: this.$t(`notifications.type.${type}.title`),
          description: null
        }

        if (this.$te(`notifications.type.${type}.description`)) {
          tmp.description = this.$t(`notifications.type.${type}.description`)
        }

        result.push(tmp)
      }

      return result
    }
  },
  watch: {
    'internalValue.notification_types': {
      immediate: true,
      handler: 'onChangeNotificationTypes'
    },
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.setValue(value)
    },
    onChangeNotificationTypes () {
      this.parseNotificationTypesField()
    },
    onChangeOption () {
      this.generateNotificationTypesField()
      this.emitInput()
    },
    setValue (val) {
      if (val === null || val === undefined) { return }
      const keys = Object.keys(this.internalValue)
      for (const key of keys) {
        if (
          Object.prototype.hasOwnProperty.call(val, key) &&
          Object.prototype.hasOwnProperty.call(this.internalValue, key)
        ) {
          this.internalValue[key] = val[key]
        }
      }
    },
    emitInput () {
      this.$emit('input', { ...this.internalValue })
    },
    parseNotificationTypesField () {
      const types = Array.isArray(this.internalValue.notification_types)
        ? this.internalValue.notification_types
        : this.internalValue.notification_types.split(',')

      for (const key in this.options) {
        if (Object.prototype.hasOwnProperty.call(this.options, key)) {
          this.options[key] = types.includes(key)
        }
      }
    },
    generateNotificationTypesField () {
      const newTypes = []

      for (const key in this.options) {
        if (Object.prototype.hasOwnProperty.call(this.options, key)) {
          if (this.options[key]) {
            newTypes.push(key)
          }
        }
      }

      this.internalValue.notification_types = newTypes
    }
  }
}
</script>

<style scoped>

</style>
