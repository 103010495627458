<template>
  <v-card
      style="height:calc(100% + 32px);margin-top:-16px;"
      class="register-container"
  >
    <div style="height:30%;">
      <!--  style="border-top:1px solid #FF5D5B;border-bottom:1px solid #FF5D5B"
      <img
          src="@/assets/img/register_bg.png"
          style="width:100%"
          alt=""
      >-->
    </div>
    <v-card-text style="height:60%">
      <v-form
          ref="form"
          v-model="form.valid"
      >
        <eins-form-login
            v-model="form.data"
            @submit="onSubmitForm"
        />
        <v-btn
            color="primary"
            class="mt-4"
            block
            :loading="loading"
            :disabled="!form.valid"
            @click="onClickSubmit"
        >{{ cText['submit.label'] }}</v-btn>
      </v-form>
      <v-expand-transition>
        <v-alert
            v-if="error"
            type="warning"
            text
            tile
            class="ma-0"
        >
          {{ cError }}
          <v-btn
              v-if="error === 401"
              outlined
              color="primary"
              @click="goToResetPassword"
          >
            {{ cText['resetPassword.label'] }}
          </v-btn>
        </v-alert>
      </v-expand-transition>
      <!--
      <v-btn
          block
          class="mt-2"
          @click="onClickOauthBtn('google')"
      >
        <v-icon
            color="yellow accent-4"
            left
        >mdi-google</v-icon>
        {{ cText['Google.label'] }}
      </v-btn>
      <v-btn
          block
          class="mt-2 black--text"
          color="white"
          @click="onClickOauthBtn('apple')"
      >
        <v-icon
            color="grey"
            left
        >mdi-apple</v-icon>
        {{ cText['Apple.label'] }}
      </v-btn>
      -->
    </v-card-text>
    <v-card-actions class="col pa-3 flex-grow-0 flex-shrink-1">
      <v-btn
          color="purple accent-3"
          block
          @click="onClickRegister"
      >
        {{ cText['Register.label'] }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EinsFormLogin from '@/components/auth/EinsFormLogin'
import {
  MxTranslatable
} from '@/mixins'
import {
  AuthModes,
  HttpStatus
} from '@/constants'
import { isEmail } from '@/utils/inputRules'

export default {
  name: 'EinsModuleLogin',
  components: {
    EinsFormLogin
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    mail: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    error: false,
    form: {
      data: {},
      valid: false
    },
    loading: false,
    $_mx_translatable: {
      paths: [
        'reset.label',
        'submit.label',
        'error.invalid',
        'error.rate',
        'error.email',
        'error.unknown',
        'Google.label',
        'Apple.label',
        'Register.label',
        'Login.label',
        'resetPassword.label'
      ]
    }
  }),
  computed: {
    cError () {
      if (this.error === false) {
        return false
      } else {
        switch (this.error) {
          case HttpStatus.UNAUTHORIZED:
            return this.cText['error.invalid']
          case HttpStatus.UNPROCESSABLE_ENTITY:
            return this.cText['error.email']
          case HttpStatus.TOO_MANY_REQUESTS:
            return this.cText['error.rate']
          default:
            return this.cText['error.unknown']
        }
      }
    }
  },
  created () {
    if (this.mail !== '') {
      this.form.data.email = this.mail
    }
  },
  methods: {
    onClickOauthBtn (provider) {
      this.$firebase.onReady(async () => {
        await this.$firebase.login(provider)
      })
    },
    onClickRegister () {
      this.goToRegister()
    },
    onClickReset () {
      this.reset()
    },
    onClickSubmit () {
      this.submit()
    },
    onSubmitForm () {
      this.submit()
    },
    goToResetPassword () {
      this.$router.push({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.PASSWORD_RESET,
          email: isEmail(this.form.data.email) === true ? this.form.data.email : ''
        }
      })
    },
    goToRegister () {
      this.$router.push({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.REGISTER,
          email: isEmail(this.form.data.email) === true ? this.form.data.email : ''
        }
      })
    },
    reset () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.form.data.email = ''
      this.form.data.password = ''
    },
    submit () {
      this.loading = true
      this.error = false
      this.$store.dispatch(
        'players/current/login',
        { ...this.form.data }
      )
        .catch(err => {
          if (!err.response) {
            this.error = true
          } else {
            this.error = err.response.status
          }
        })
        .finally(() => {
          this.$router.push('/')
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
