<template>
  <div>
    <v-btn
        outlined
        block
        @click="onClickChangePassword"
    >
      <div>
        <v-icon class="mr-1">mdi-lock-reset</v-icon>
        <i18n path="player.update.password.actions.update" />
      </div>
    </v-btn>
    <eins-player-data-password-dialog v-model="passwordDialog" />
    <v-btn
        outlined
        block
        class="mt-2"
        @click="onClickResetPassword"
    >
      <div>
        <v-icon class="mr-1">mdi-update</v-icon>
        <i18n path="player.update.password.actions.reset" />
      </div>
    </v-btn>
    <v-dialog v-model="resetDialog">
      <eins-module-password-reset email-requested>
        <template #cancel>
          <v-btn
              color="error"
              text
              @click="onClickCloseReset"
          >
            {{ $t('components.AppConfirmPassword.buttons.cancel.label') }}
          </v-btn>
        </template>
        <template #back>
          <v-btn
              color="primary"
              text
              block
              @click="onClickCloseReset"
          >
            {{ $t('components.AppConfirmPassword.buttons.login.label') }}
          </v-btn>
        </template>
      </eins-module-password-reset>
    </v-dialog>
  </div>
</template>

<script>
import EinsPlayerDataPasswordDialog from '@/components/player/edit/EinsPlayerDataPasswordDialog'
import EinsModulePasswordReset from '@/components/auth/EinsModulePasswordReset'

export default {
  name: 'EinsPlayerDataPasswordBlock',
  components: {
    EinsPlayerDataPasswordDialog,
    EinsModulePasswordReset
  },
  data: () => ({
    collapsed: true,
    passwordDialog: false,
    resetDialog: false
  }),
  computed: {
    cUserEmail () {
      return this.$store.getters['players/current/email']
    }
  },
  methods: {
    onClickChangePassword () {
      this.showPasswordChangeDialog()
    },
    showPasswordChangeDialog () {
      return new Promise(resolve => {
        this.passwordDialog = true
        const unwatch = this.$watch('passwordDialog', value => {
          if (!value) {
            unwatch()
            resolve()
          }
        })
      })
    },
    onClickResetPassword () {
      this.showPasswordResetDialog()
    },
    showPasswordResetDialog () {
      this.resetDialog = true
    },
    onClickCloseReset () {
      this.resetDialog = false
    },
    toggleCollapsed () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
