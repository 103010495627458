<template>
  <v-list
      dense
      class="pt-0 pb-0 mt-1"
  >
    <template
        v-for="item in items"
    >
      <v-list-item
          :key="item.key"
          :class="`list-item-${objectType}`"
          style="padding:0px;"
      >
        <slot
            name="image"
            :item="item"
        >
        </slot>
        <v-list-item-icon
            class="list-icon pa-0 ma-0 mr-2 pl-1"
            :class="objectType"
        >
          <slot
              name="icon"
              :item="item"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </slot>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <slot
                name="title"
                :item="item"
            >
              {{ item.title }}
            </slot>
          </v-list-item-title>
          <v-list-item-subtitle>
            <slot
                name="subtitle"
                :item="item"
            >
              {{ item.subtitle }}
            </slot>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'EinsLayoutDetailList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    objectType: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.list-image {
  border:2px solid var(--v-quest-base);
}
.list-item-deal {
    border:1px solid var(--v-dealoff-base) !important;
}
.list-icon{
  &.quest {
    background: var(--v-questoff-base);
    height:48px;width:48px;
  }
  &.deal {
    background: var(--v-dealoff-base) !important;
    height:48px;width:48px;
    padding-left:10px !important;
    text-align: center !important;
  }
  &.group {
    background: var(--v-groupoff-base) !important;
    height:48px;width:48px;
    padding-left:10px !important;
    text-align: center !important;
  }
  &.player {
    background: var(--v-playeroff-base) !important;
    height:48px;width:48px;
    padding-left:10px !important;
    text-align: center !important;
  }

}
</style>
