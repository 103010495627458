<template>
  <eins-input-toolbar-extension
      :label="cLabel"
      :value="cTextFilter"
      class="mt-3"
      @input="onChangeFilter"
  >
    <template #icon>
      <eins-icon-chat
          active
          width="26"
          class="mr-1"
      />
    </template>
  </eins-input-toolbar-extension>
</template>

<script>
import EinsIconChat from '@/components/icons/EinsIconChat'
import EinsInputToolbarExtension from '@/components/input/EinsInputToolbarExtension'
import {
  MxHasModel,
  MxPropRedirection,
  MxVuetifyInputWrapper
} from '@/mixins'

export default {
  name: 'ViewChatListToolbarExtension',
  components: {
    EinsIconChat,
    EinsInputToolbarExtension
  },
  mixins: [
    MxHasModel,
    MxPropRedirection,
    MxVuetifyInputWrapper
  ],

  computed: {
    cLabel () {
      return this.$t('terms.chat')
    },
    cTextFilter () {
      return this.$store.getters['conversations/textFilter'] ?? ''
    }
  },
  methods: {
    onChangeFilter (value) {
      this.$store.commit('conversations/textFilter', value)
    }
  }
}
</script>

<style scoped>
</style>
