import {
  createListModule,
  createRelatedEntityListModule,
  createSelectedEntityModule
} from '@/plugins/store/factories'
import { QuestState } from '@/constants'

const basePath = '/quests'

export const quests = createListModule({
  basePath,
  baseEntity: 'quests',
  getParams: () => ({ state: QuestState.OPEN }),
  modules: {
    selected: createSelectedEntityModule({
      basePath,
      getterName: 'quest',
      modules: {
        applications: createRelatedEntityListModule({
          getParams: (getters) => ({
            no_distance: true
          }),
          getPath: (getters) => `/quests/${getters['quests/selected/quest'].id}/applications`,
          baseEntity: 'applications'
        })
      }
    })
  }
})
