export default {
  captions: {
    caption: 'Update own profile',
    talents: 'Talents',
    saving: 'Your @.lower:(terms.player) profile update is saved'
  },
  fields: {
    name: {
      label: ' @.lower:(terms.player) name',
      overlay: 'Please choose a new  @.lower:(terms.player) Name'
    },
    gps: {
      label: 'GPS'
    },
    language: {
      label: 'Language',
      overlay: 'Please select a  @.lower:(terms.player) Language'
    },
    notification: {
      weight: 'level',
      type: 'type'
    },
    description: {
      label: 'Description',
      overlay: 'Please write a new  @.lower:(terms.player) Description'
    },
    newEMail: {
      label: 'new e-mail adress'
    },
    eMail: {
      label: 'e-mail adress',
      placeholder: 'e-Mail adress',
      hint: 'Please enter a correct e-mail address',
      overlay: {
        label: 'Please choose a new  @.lower:(terms.player) e-Mail',
        text: 'Please enter your eMail and sent the request. \n' +
          'We will send you an email with a link to reset your eMail',
        buttons: {
          confirm: {
            label: 'Send E-Mail reset'
          }
        }
      }
    }
  },
  buttons: {
    reset: {
      label: 'reset \n  password'
    },
    setNewPassword: {
      label: 'set password'
    },
    addTalent: {
      label: 'Add'
    },
    eMail: {
      label: 'reset \n Email'
    },
    image: {
      label: ''
    }
  },
  dialogs: {
    resetPassword: {
      caption: 'You need a new Password?',
      text: 'Please enter your eMail to the position and sent the password request. \n' +
        'We will send you a email with a link to reset your password',
      buttons: {
        cancel: 'Cancel',
        reset: 'Send password reset'
      }
    },
    setPassword: {
      caption: 'You need a new Password?',
      text: 'Please enter the current Password, new Password and confirm this.',
      buttons: {
        cancel: 'Cancel',
        submit: 'Send password'
      }
    },
    addTalent: {
      caption: 'Add talent',
      subtitle: 'What is your talent and why do you like to use it?',
      fields: {
        name: {
          label: 'Talent name',
          overlay: 'Please choose a new  @.lower:(terms.player) Name'
        },
        description: {
          label: 'Description'
        }
      },
      buttons: {
        cancel: {
          label: 'Cancel'
        },
        submit: {
          label: 'Add'
        }
      }
    }
  },
  overlay: {
    buttons: {
      cancel: {
        label: 'Cancel'
      },
      delete: {
        label: 'Delete'
      },
      confirm: {
        label: 'OK'
      }
    }
  },
  overlays: {
    image: {
      title: 'Please select an Image',
      subtitle: '',
      preview: 'Preview',
      placeholder: 'Pick a  @.lower:(terms.player) image',
      buttons: {
        upload: {
          label: 'Upload Image'
        }
      }
    }
  }
}
