export const EinsStarmenuDpbGroupMembers = {
  text: 'Mitgliederliste'
}

export const EinsStarmenuDpbGroupDetails = {
  text: 'Daten'
}

export const EinsStarmenuDpbApplications = {
  text: 'Bewerberliste'
}

export const EinsStarmenuDpbPlayerGroups = {
  text: '@.lower:(terms.groups)'
}

export const EinsStarmenuDpbDeals = {
  text: '@.lower:(terms.deals)'
}

export const EinsStarmenuDpbDealsFinished = {
  text: 'Getauscht'
}

export const EinsStarmenuDpbDealsClosed = {
  text: 'abgeschlossene @.lower:(terms.deals)'
}

export const EinsStarmenuDpbDealsGroup = {
  text: '@.lower:(terms.deals) @.lower:(terms.group)'
}

export const EinsStarmenuDpbQuestsGroup = {
  text: '@.lower:(terms.quest) @.lower:(terms.group)'
}

export const EinsStarmenuDpbQuests = {
  text: '@.lower:(terms.quest)'
}

export const EinsStarmenuDpbQuestsSolved = {
  text: 'Beweise'
}

export const EinsStarmenuDpbMyGroups = {
  text: 'Meine @.lower:(terms.groups)'
}

export const EinsStarmenuDpbGroupConversation = {
  text: 'Chat'
}

export const EinsStarmenuDpbMyDeals = {
  text: 'Meine @.lower:(terms.deals)'
}

export const EinsStarmenuDpbQuestTemplates = {
  text: '@.lower:(terms.quest) Vorlagen'
}

export const EinsStarmenuDpbMyQuests = {
  text: 'Meine @.lower:(terms.quests)'
}

export const EinsStarmenuDpbPlayerBlockages = {
  text: 'Geblockte @.lower:(terms.players)'
}

export const EinsStarmenuDpbPlayerContacts = {
  text: 'Meine Kontakte'
}

export const EinsStarmenuDpbPlayerData = {
  text: 'Daten '
}
export const EinsStarmenuDpbDirectMessage = {
  text: 'Nachrichten'
}

export const EinsStarmenuDpbDealTemplates = {
  text: '@.lower:(terms.deals) Vorlagen'
}

export const EinsStarmenuDpbDetails = {
  text: 'Details'
}

export const EinsDealListItemPlayerDeal = {
  btn: {
    application: 'Bewerber',
    list: 'Liste',
    chat: 'Chat'
  }
}
