import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'

// @vue/component
export const MxViewEntityDetail = {
  components: {
    EinsErrorForbidden,
    EinsErrorNotFound,
    EinsLayoutViewDetail
  },
  data: () => ({
    loading: false,
    entity: null,
    error: false,
    $_mx_view_entity_detail: {
      url: null
    }
  }),
  computed: {
    $_mx_view_entity_detail_loadEntityData_cEntityId () {
      return this.$route.params.id ?? 0
    },
    cEntityId () {
      return this.$_mx_view_entity_detail_loadEntityData_cEntityId
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadEntityData(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadEntityData(to.params.id)
    }
    next()
  },
  methods: {
    $_mx_view_entity_detail_loadEntityData (id) {
      this.loading = true
      this.error = false
      const promise = this.$http.$api.get(this.$data.$_mx_view_entity_detail.url.replace('{id}', id))
      promise
        .then(response => {
          this.$set(this.$data, 'entity', response.data)
        })
        .catch(err => {
          if (err.response) {
            this.error = err.response.status
          } else {
            this.error = true
          }
        })
        .finally(() => {
          this.loading = false
        })

      return promise
    },
    loadEntityData (id) {
      this.$_mx_view_entity_detail_loadEntityData(id)
    }
  }
}
