import * as EinsStarmenuAb from '@/locales/en/components/EinsStarmenuAb'
import * as EinsStarmenuEb from '@/locales/en/components/EinsStarmenuEb'
import * as EinsStarmenuDpb from '@/locales/en/components/EinsStarmenuDpb'
import * as EinsStarmenuDlg from '@/locales/en/components/EinsStarmenuDlg'
import * as EinsSocialShare from '@/locales/en/components/EinsSocialShare'
import EinsGroupDetail from '@/locales/en/components/EinsGroupDetail'
import EinsFormLogin from '@/locales/en/components/EinsFormLogin'
import EinsPlayerDetail from '@/locales/en/components/EinsPlayerDetail'
import EinsQuestDetail from '@/locales/en/components/EinsQuestDetail'
import EinsMyProfile from '@/locales/en/components/EinsMyProfile'
import EinsModuleLogin from '@/locales/en/components/EinsModuleLogin'
import EinsGroupCreateFormStep1 from '@/locales/en/components/EinsGroupCreateForm/EinsGroupCreateFormStep1'

import EinsLocationInput from '@/locales/en/components/EinsLocationInput'
import EinsPointsInput from '@/locales/en/components/EinsPointsInput'

import AppRegister from '@/locales/en/components/AppRegister'
import EinsModuleRegister from '@/locales/en/components/EinsModuleRegister'
import AppConfirmPassword from '@/locales/en/components/AppConfirmPassword'
import AuthModuleVerifyEmail from '@/locales/en/components/AuthModuleVerifyEmail'

export default {
  AppRegister,
  AuthModuleVerifyEmail,
  EinsModuleRegister,
  AppConfirmPassword,
  ...EinsStarmenuAb,
  ...EinsStarmenuEb,
  ...EinsStarmenuDlg,
  ...EinsStarmenuDpb,
  ...EinsSocialShare,
  EinsFormLogin,
  EinsGroupDetail,
  EinsGroupCreateFormStep1,
  EinsModuleLogin,
  EinsPlayerDetail,
  EinsQuestDetail,
  EinsMyProfile,
  EinsLocationInput,
  EinsPointsInput,

  'EinsGroupDetailsAlert': {
    'text': {
      'stranger': 'You can join this @.lower:(terms.group).',
      'applicant': 'You are an applicant.',
      'applicant_rejected': 'Your application was declined.',
      'member': 'You are a Member of this @.lower:(terms.group).',
      'admin': 'You are now an admin.',
      'quest': 'There are new @:(terms.quests) available',
      'deal': 'There are new @:(terms.deals) available.',
      'addquest': 'Got some time? Make a new @:(terms.quest)!',
      'adddeal': 'Make a new @:(terms.deal).'
    },
    'textLong': {
      'stranger': 'To join this @.lower:(terms.group) go to the mask and click join. Then an application will be send to the admin.',
      'applicant': 'You have applied for this @.lower:(terms.group). Please wait for the admins response.',
      'applicant_rejected': 'Your access to the @.lower:(terms.group) was declined. If you have any questions, please talk to the admin.',
      'member': 'You are now a Member of this @.lower:(terms.group). Start getting active!',
      'admin': 'You are now an admin of this @.lower:(terms.group).',
      'quest': 'There are new @.lower:(terms.quests) for you. Make sure to check them out!',
      'deal': 'Make sure to check them out before they are gone!',
      'addquest': 'Start making a new @.lower:(terms.quest), only takes a few minutes!',
      'adddeal': 'Make a new @.lower:(terms.deal) so members can activate it.'
    }
  },
  'EinsDealDetailStatesHeader': {
    'caption': {
      'open': 'open',
      'available': 'available',
      'completed': 'completed',
      'apply': 'apply',
      'assign': 'assign',
      'applied': 'applied',
      'assigned': 'assigned',
      'accepted': 'accepted',
      'closed': 'bought',
      'Abort': 'Back'
    },
    'text': {
      'open': 'There has no @.lower:(terms.player) applied for @.lower:(terms.deal).',
      'available': 'You can get this @.lower:(terms.deal).',
      'completed': 'You completed the @.lower:(terms.deal).',
      'apply': 'You can apply for this @.lower:(terms.deal).',
      'assign': 'You can release the @.lower:(terms.deal) to the applicants.',
      'applied': 'You are an applicant for this @.lower:(terms.deal).',
      'assigned': 'You have assigned the @.lower:(terms.deal) to the applicant.',
      'accepted': 'You can get the @.lower:(terms.deal) now.',
      'closed': 'The @.lower:(terms.deal) is closed.'
    },
    'textLong': {
      'open': 'You can edit this @.lower:(terms.deal) to get applicants or you can withdraw it.',
      'available': 'You can get this @.lower:(terms.deal) right away.',
      'completed': 'You have finished the @.lower:(terms.deal).',
      'apply': 'Apply for this @.lower:(terms.deal) and wait for the @.lower:(terms.player) to accept your request or get back to you.',
      'assign': 'By releasing the @.lower:(terms.deal), applicants are able to confirm it.',
      'applied': 'You have already applied for this @.lower:(terms.deal). Wait for the @.lower:(terms.player) to accept your request or get back to you.',
      'assigned': 'The @.lower:(terms.deal) has been assigned. Please wait for the applicants response.',
      'accepted': 'Your application for the @.lower:(terms.deal) was accepted. Decide whether you want the @.lower:(terms.deal) or not. If rejected, your application will be deleted.',
      'closed': 'The @.lower:(terms.deal) has been finished.'
    }
  },
  'EinsQuestDetailStatesHeader': {
    'caption': {
      'apply': 'Apply',
      'wait': 'Wait',
      'allocate': 'You have applicants. Check',
      'applicant': 'You are an applicant',
      'start': 'start',
      'start_owner': '@.lower:(terms.quest) can started',
      'running': 'running',
      'running_owner': 'in progress',
      'completed': 'completed',
      'completed_assignee': 'await confirmation',
      'closed': 'Proofed',
      'Abort': 'Back'
    },
    'text': {
      'apply': 'You can apply for this @.lower:(terms.quest).',
      'wait': 'There is no applicant for this  @.lower:(terms.quest).',
      'allocate': 'You have got new applicants for the @.lower:(terms.quest)',
      'applicant': 'You have applied for the @.lower:(terms.quest)',
      'start': 'The @.lower:(terms.quest) is ready to go. You are free to start now!.',
      'start_owner': 'The @.lower:(terms.quest) can stared by the assigned @.lower:(terms.player).',
      'running': 'The @.lower:(terms.quest) has just started',
      'running_owner': 'The @.lower:(terms.quest) is in progress. Ask the @.lower:(terms.player) for more informations',
      'completed': 'The @.lower:(terms.quest) is finished.',
      'completed_assignee': 'The @.lower:(terms.quest) wait for confirmation',
      'closed': 'This @.lower:(terms.quest) is complete.  It´s now displayed as proofed.'
    },
    'textLong': {
      'apply': ' It can be done by going to the mask and clicking "apply". For more information, contact the owner via chat',
      'wait': 'There is no applicant for this  @.lower:(terms.quest). Please check the @.lower:(terms.quest) for mistakes or additional information',
      'applicant': 'Please wait till your application is accepted. If you want, you can also chat with the @.lower:(terms.player). You will find the @.lower:(terms.quest) on the List "My @.lower:(terms.quest)"',
      'allocate': 'Please check on these new applications for the @.lower:(terms.quest). You are free to accept or decline them.',
      'start': 'The @.lower:(terms.quest) is ready to be completed by you!',
      'start_owner': 'The @.lower:(terms.quest) is on hold till the assigned @.lower:(terms.player) has started it.',
      'running': 'You have started the @.lower:(terms.quest). Get ready to master it!',
      'running_owner': 'The @.lower:(terms.quest) is in progress. You should wait till the @.lower:(terms.player) will finish the @.lower:(terms.quest) or chat with you. You can´t do anything ',
      'completed': 'The @.lower:(terms.player) has finished the @.lower:(terms.quest). Please confirm the completion.',
      'completed_assignee': 'The @.lower:(terms.quest) is finished from you. Please wait till it is confirmed from the owner',
      'closed': 'Congratulations!'
    }
  },
  'EinsLayoutExpandableText': {
    'more': 'More...'
  },
  chat: {
    unknownPlayer: 'Unknown @.lower:(terms.player)'
  }
}
