<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-face-agent"
      to="/deals/create?type=service"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuAbCreateDealService',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
