export default {
  captions: {
    caption: 'Create New @.lower:(terms.group)',
    page3: 'Request @.lower:(terms.group)'
  },
  fields: {
    name: {
      label: '@.lower:(terms.group) name',
      placeholder: 'Add specific @.lower:(terms.group) name'
    },
    eMail: {
      label: 'Add @.lower:(terms.group) eMail',
      placeholder: 'Your unique email for the@.lower:(terms.group)',
      hint: 'This @.lower:(terms.group) eMail should verified to start the@.lower:(terms.group). And is used to communicate with icigai1 GmbH if yuo need a license.'
    },
    contact: {
      label: 'Add @.lower:(terms.group) contact person',
      placeholder: 'The name of your contact person for the icigai1 GmbH',
      hint: 'The contact person for queries and for communication with icigai1 GmbH outside the App1'
    }
  },
  labels: {
    licenceName: {
      label: 'Type'
    },
    description: {
      label: '@.lower:(terms.group) details'
    },
    BuyDeals: {
      label: 'no trading limits'
    },
    NoDeals: {
      label: 'no @.lower:(terms.deals)'
    },
    GroupMembers: {
      label: '@.lower:(terms.group) Members allowed'
    },
    Leaders: {
      label: '@.lower:(terms.group) Admins allowed'
    },
    Closed: {
      label: 'new Members should apply'
    },
    Open: {
      label: 'new Members can join'
    },
    ActiveContent: {
      label: 'support content management by icigai1'
    },
    SelfContent: {
      label: 'only self content management by @.lower:(terms.group) members '
    },
    Support: {
      label: 'include Supporttickets for 12 months.'
    },
    Calls: {
      label: 'include Hotline Calls for 12 months.'
    }
  },
  buttons: {
    groupType1: {
      label: 'No legal form / private',
      info: '@.lower:(terms.players) gather on @.lower:(terms.group) to post @.lower:(terms.quests) and @.lower:(terms.deals) for each other.  As the creator of the @.lower:(terms.group), you are also the leader of the @.lower:(terms.group).  You can also appoint other @.lower:(terms.players) to be the leader of the @.lower:(terms.group) after creating it.'
    }
  }
}
