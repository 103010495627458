<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-delete-forever</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  HttpStatus,
  QuestState
} from '@/constants'
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
export default {
  name: 'EinsStarmenuAbCancelQuest',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute &&
          this.cStateIsOpen &&
          this.cPlayerIsOwner &&
          this.cHasNoApplications
    },
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    },
    cPlayerIsOwner () {
      return this.cQuest?.user_id === this.$store.getters['players/current/id']
    },
    cStateIsOpen () {
      return this.cQuest?.state === QuestState.OPEN
    },
    cHasNoApplications () {
      return this.cQuest?.applicants.length === 0
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogCancelQuest')
        .then(() => this.$http.$api.head(`quests/${this.cQuest?.id}`))
        .catch(() => {
          this.$router.replace('/quests')
        })
        .then(response => {
          if (response.status >= HttpStatus.BAD_REQUEST) {
            this.$router.replace('/quests')
          } else {
            this.$store.dispatch('quests/selected/update')
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
