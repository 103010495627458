<template>
  <div
      style="height: 100%;padding-top:56px;padding-bottom:56px;"
      class="d-flex flex-column"
  >
    <div
        class="d-flex flex-column  detail-content"
        style="
         position:absolute;
         width:100%;
         height: calc(100% - 120px);"
    >
      <slot name="abort"></slot>
      <div class="flex-shrink-1">
        <slot
            name="title"
            style="padding-right: 3.25em;"
        >
          <v-row
              class="pa-3"
              @click="onClickCreator"
          >
            <v-col cols="3">
              <eins-api-image
                  #default="{ src }"
                  :src="cCreatorAvatar"
                  width="150px"
              >
                <v-img
                    :src="src"
                >
                  <template #placeholder>
                    <v-skeleton-loader
                        tile
                        type="image"
                    />
                  </template>
                </v-img>
              </eins-api-image>
            </v-col>
            <v-col
                cols="9"
            >
              <div class="text-white text-h5">
                {{ cCreatorName }}
              </div>
              <div class="text-white text-subtitle">
                {{ cCreatedAt }}
              </div>
            </v-col>
          </v-row>
        </slot>
      </div>
      <div
          class="flex-grow-1"
          style="overflow:auto;"
      >
        <eins-api-image
            #default="{ src }"
            :src="cSrc"
        >
          <v-img
              :src="src"
              height="100%"
              style="aspect-ratio:1;"
          >
            <template #placeholder>
              <v-skeleton-loader
                  tile
                  type="image"
              />
            </template>
          </v-img>
        </eins-api-image>
        <v-btn
            v-if="prev"
            elevation="2"
            fab
            rounded
            style="position:absolute; bottom:15%;left:10px;"
            @click="onClickPrev"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
            v-if="next"
            elevation="2"
            fab
            rounded
            style="position:absolute; bottom:15%;right:10px;"
            @click="onClickNext"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EinsApiImage from '../EinsApiImage'
export default {
  name: 'EinsGalleryImage',
  components: {
    EinsApiImage
  },
  props: {
    picture: {
      type: Object,
      required: true
    },
    next: {
      type: Boolean,
      default: false
    },
    prev: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cPicture () {
      return this.picture || {}
    },
    cCreatorAvatar () {
      return this.cPlayer?.title_picture_feed || ''
    },
    cCreatorName () {
      return this.cPlayer?.name || ''
    },
    cPlayer () {
      return this.cPicture?.creator || {}
    },
    cCreatedAt () {
      const date = this.cPicture?.created_at || ''
      if (date !== '') {
        const created = new Date(date)
        return created.toUTCString()
      } else {
        return ''
      }
    },
    cObjectIcon () {
      return this.picture || {}
    },
    cObjectTitle () {
      return this.picture || {}
    },
    cSrc () {
      return this.cPicture?.pictureThumb || ''
    }
  },
  methods: {
    onClickPrev () {
      this.$emit('click-prev')
    },
    onClickNext () {
      this.$emit('click-next')
    },
    onClickCreator () {
      this.$router.push({
        name: 'player-detail',
        params: {
          id: this.cPlayer.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
