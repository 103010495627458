<template>
  <v-icon v-bind="$attrs">
    {{ cIcon }}
  </v-icon>
</template>

<script>
import { DealType } from '@/constants'

export default {
  name: 'EinsIconDealType',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    cIcon () {
      if (this.type === DealType.SERVICE) {
        return 'mdi-face-agent'
      } else {
        return 'mdi-basket'
      }
    }
  }
}
</script>

<style scoped>
</style>
