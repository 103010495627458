<template>
  <div
      style="height: 100%; overflow: hidden;"
      class="d-flex flex-column"
  >
    <v-btn
        fab
        absolute
        depressed
        elevation="0"
        style="right: -0.75em; top: -0.75em;"
        color="secondary"
        @click="$router.go(-1)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="flex-shrink-1">
      <slot
          name="title"
          style="padding-right: 3.25em;"
      >
        <v-row class="pa-3">
          <v-col cols="2">
            <v-icon
                size="42px"
                :color="cObjectClass[0]"
            >
              {{ cObjectIcon }}
            </v-icon>
          </v-col>
          <v-col
              cols="10"
              :class="cObjectClass[1]"
          >
            <div class="text-white text-h5 mt-1">
              {{ cObjectTitle }}
            </div>
          </v-col>
        </v-row>
      </slot>
      <slot
          name="button"
      >
        <template v-if="useUpload && isMemberOrFree">
          <v-row
              no-gutters
              class="text-center flex-center justify-center mt-2"
          >
            <eins-image-upload-input @upload="onClickUpload" />
          </v-row>
          <v-row
              v-if="!success"
              no-gutters
              class="text-h6 flex-center justify-center mt-n7"
          >
            = 2 Points
          </v-row>
          <v-alert
              v-else
              v-model="success"
              class="ma-0"
              type="success"
          >
            {{ $t('group.update.picture.success.bonus') }}
          </v-alert>
        </template>
      </slot>
    </div>
    <v-divider />
    <div
        style="position: relative;overflow-y: auto;"
        class="flex-grow-1"
    >
      <v-row
          no-gutters
          class="pa-1"
      >
        <v-col
            v-for="(pic, index) in cPictures"
            :key="'pic' + index"
            cols="6"
        >
          <eins-gallery-picture
              :picture="pic"
              :index="index"
              @on-click="onClickImage"
          />
        </v-col>
      </v-row>
    </div>
    <v-dialog
        v-model="cImageSelected"
        fullscreen
        class="pa-2"
    >
      <eins-gallery-picture-fullscreen
          v-if="cImageSelected"
          :picture="cShowObject"
          :next="cNextAllowed"
          :prev="cPrevAllowed"
          @click-next="onClickNext"
          @click-prev="onClickPrev"
      >
        <template #abort>
          <v-btn
              fab
              absolute
              depressed
              elevation="0"
              style="right: -0.75em;top:-0.75em; "
              color="secondary"
              @click="clearSelected"
          >

            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </eins-gallery-picture-fullscreen>
    </v-dialog>
  </div>
</template>

<script>
import {
  Icon
} from '@/constants'

import EinsImageUploadInput from '@/components/input/EinsImageUploadInput'
import EinsGalleryPicture from './EinsGalleryPicture'
import EinsGalleryPictureFullscreen from './EinsGalleryPictureFullscreen'

export default {
  name: 'EinsObjectImageCollection',
  components: {
    EinsGalleryPicture,
    EinsImageUploadInput,
    EinsGalleryPictureFullscreen
  },
  props: {
    objectType: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      required: true
    },
    useUpload: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pictures: [],
    selected: null,
    picture: null,
    preview: null,
    internalValue: null,
    overlay: false,
    loading: false,
    success: false,
    error: false
  }),
  computed: {
    cObject () {
      return this.object
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cObjectIcon () {
      switch (this.objectType) {
        case 'group': return Icon.GROUP
        case 'player': return Icon.PLAYER
        case 'quest': return Icon.QUEST
        case 'deal': return Icon.DEAL
        default: return null
      }
    },
    cObjectClass () {
      return [this.objectType, this.objectType + 'off']
    },
    cObjectTitle () {
      return this.cObject.title
    },
    cPictures () {
      return this.pictures?.pictures ?? []
    },
    cImageSelected () {
      return this.selected !== null
    },
    cShowObject () {
      return this.cPictures[this.selected]
    },
    cNextAllowed () {
      return this.selected < this.cPictures?.length - 1
    },
    cPrevAllowed () {
      return this.selected > 0
    },
    isMemberOrFree () {
      const metaType = this.cObject?.group_type?.meta_type_key ?? null
      return (
          this.cObject.members?.includes(this.cUserId) ||
          metaType === 'FREE'
      ) || false
    }
  },
  created () {
    this.fetchGallery()
  },
  methods: {
    fetchGallery () {
      this.loading = true
      return this.$http.$api.get(`/groups/${this.cObject.id}/gallery`)
        .then(response => {
          this.pictures = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClickOpenUpload () {
      this.overlay = true
    },
    onClickUpload (file) {
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
        this.$http.$api.post(`/groups/${this.cObject.id}/gallery`, formData)
          .then(() => {
            this.success = true
            this.error = false
            this.fetchGallery()
          })
          .catch(er => {
            if (!er.response) {
              this.error = true
            } else {
              this.error = er.response.status
            }
          })
      }
    },
    onClickImage (index) {
      this.selected = index
    },
    onClickNext () {
      this.selected++
    },
    onClickPrev () {
      this.selected--
    },
    clearSelected () {
      this.selected = null
    }
  }
}
</script>

<style scoped>

</style>
