import { HttpStatus } from '@/constants'

export default {
  deleted: {
    name: 'Deleted @.lower:(terms.player)'
  },
  block: {
    button: 'Block @.lower:(terms.player)',
    dialog: {
      title: 'Block @.lower:(terms.player) \'{0}\'',
      text: 'Blocking the @.lower:(terms.player) means you can´t see anything from this @.lower:(terms.player). No @.lower:(terms.players) profile, no @.lower:(terms.deal), no @.lower:(terms.quest) and you can not chat wiht this @.lower:(terms.player). Only the @.lower:(terms.group) activities you can see.',
      buttons: {
        cancel: 'Cancel',
        submit: 'Block @.lower:(terms.player)',
        close: 'Close'
      },
      state: {
        success: 'The @.lower:(terms.player) has ben blocked. You can revoke the blockage in your profile settings.',
        error: {
          default: 'Something wet wrong...'
        }
      }
    },
    list: {
      listElement: {
        createdAt: 'Blocked on: {0}'
      },
      unblockDialog: {
        title: 'Revoke blockage',
        text: 'When you revoke the blockage you can fully interact with the @.lower:(terms.player) {0} as he can with you. You can block this @.lower:(terms.player) again anytime you want.',
        buttons: {
          cancel: 'Cancel',
          submit: 'Unblock',
          close: 'Close'
        },
        state: {
          success: 'The @.lower:(terms.player)\'s block {0} was successfully canceled. You can now fully interact again.',
          error: {
            [HttpStatus.FORBIDDEN]: 'You are not allowed to revoke this blockage.',
            [HttpStatus.CONFLICT]: 'The blockage has been revoked already.',
            default: 'An unknown error happened.'
          }
        }
      }
    },
    attribution: {
      isBlocked: 'You have blocked this @.lower:(terms.player)',
      hasBlocked: 'The @.lower:(terms.player) has blocked you'
    }
  },
  select: {
    label: '@.lower:(terms.player)',
    noData: 'No players found'
  },
  update: {
    picture: {
      dialogTitle: 'Upload picture',
      success: {
        title: 'Success!',
        text: 'Your profile picture was set.'
      },
      actions: {
        submit: 'Upload',
        setPicture: 'Set picture'
      }
    },
    gps: {
      title: 'Live position',
      subTitlePositive: 'Live position is enabled',
      subTitleNegative: 'Live position is disabled'
    },
    language: {
      label: 'App language',
      dialogTitle: 'Change app language',
      actions: {
        submit: 'Change language',
        cancel: 'Cancel',
        close: 'Close'
      },
      success: {
        title: 'Success!',
        text: 'Your app language has been changed to \'{0}\'.'
      },
      name: {
        label: 'New name',
        required: 'Please enter your new name',
        min: 'Your name needs to have a length of at least 3',
        unchanged: 'Your new name is not different from your current name.'
      },
      labelTypes: 'Receive notifications via:'
    },
    name: {
      dialogTitle: 'Change my name',
      actions: {
        submit: 'Change name'
      },
      success: {
        title: 'Success!',
        text: 'Your @.lower:(terms.player) name was changed from \'{0}\' to \'{1}\' bes sure to tell your friends.'
      },
      name: {
        label: 'New name',
        required: 'Please enter your new name',
        min: 'Your name needs to have a length of at least 3',
        unchanged: 'Your new name is not different from your current name.'
      }
    },
    description: {
      dialogTitle: 'Change my @.lower:(terms.description)',
      actions: {
        submit: 'Change @.lower:(terms.description)'
      },
      success: {
        title: 'Success!',
        text: 'Your description has been updated.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'The length of your @.lower:(terms.description) must not exceed 500 letters.'
      }
    },
    notifications: {
      label: 'My notification settings',
      actions: {
        change: 'Change'
      },
      labelTypes: 'Receive notifications via:'
    },
    password: {
      dialogTitle: 'Change password',
      actions: {
        update: 'Change password',
        submit: 'Change password',
        cancel: 'Cancel',
        reset: 'get new password',
        close: 'Close'
      },
      success: 'Your password was changed successfully.',
      form: {
        currentPassword: {
          label: 'Current password',
          required: 'Please enter your current password',
          mismatch: 'The entered password doesnt match your current password'
        },
        password: {
          label: 'New password',
          required: 'Please enter a new password',
          min: 'The length of your new password must be at least 10 characters'
        },
        passwordConfirmation: {
          label: 'New password (confirmation)',
          required: 'Please confirm your new password',
          mismatch: 'The confirmation doesnt match the new password'
        }
      }
    },
    talents: {
      label: 'My Talents',
      dialogTitleCreate: 'Add new talent',
      dialogTitleEdit: 'Update talent \'{name}\'',
      actions: {
        new: 'Add talent',
        edit: 'Edit talent \'{name}\'',
        delete: 'Delete talent \'{name}\'',
        update: 'Update',
        create: 'Create',
        cancel: 'Cancel',
        close: 'Close'
      },
      form: {
        name: {
          label: 'Name',
          required: 'Please choose a name',
          min: 'The name should have a length of 3 letters at least'
        },
        description: {
          label: 'Description',
          placeholder: 'What is special about your talent and how can you put it to use?',
          max: 'Please enter a maximum of 150 characters'
        }
      },
      noTalents: 'You didnt enter talents so far.',
      deletedMessage: {
        text: 'The talent \'{name}\' has been removed.'
      },
      successMessage: {
        title: 'Success',
        textCreated: 'The talent \'{name}\' has been created.',
        textUpdated: 'The talent \'{name}\' has been updated.'
      }
    },
    email: {
      label: 'My Email address',
      dialogTitle: 'Change email address',
      dialogText: 'After changing your email address you need to validate it to become active.',
      newEmail: {
        label: 'New Email',
        hint: 'Please enter your new email address.',
        errorValid: 'The entered value is not a valid email address',
        errorRequired: 'Please enter the new email address',
        errorUnique: 'The email you entered is already in use.',
        verificationNeededHint: 'Verify this address in order to use it.'
      },
      currentEmail: {
        label: 'Current email',
        unverifiedHint: 'Your email is not verified jet!'
      },
      password: {
        label: 'Current Password',
        errorPassword: 'You entered an invalid password.',
        errorRequired: 'Please enter your password'
      },
      success: {
        title: 'Success!',
        text: 'Please verify your new address by clicking the link un the email we sent you.' +
          ' It will only be used after you verified it!'
      },
      verificationLinkSend: {
        title: 'Link was send!',
        text: 'The email with the verification link was send successfully.' +
          ' Please wait a few minutes, if it doesnt arrive immediately and check your spam folder!'
      },
      buttons: {
        change: 'Update email address',
        checkStatus: 'Check verification status',
        resendVerification: 'Send new verification link',
        submit: 'Change email',
        cancel: 'Cancel',
        close: 'Close'
      }
    }
  }
}
