<template>
  <v-row
      align="baseline"
      class="text-h6 flex-nowrap"
      justify="space-between"
  >
    <v-col>
      {{ currentValue }}
    </v-col>
    <v-col class="flex-grow-0">
      <v-btn
          icon
          @click="onClickChange"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-col>
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text v-if="!success">
          <v-form
              ref="form"
              v-model="form.valid"
              :disabled="loading"
          >
            <v-text-field
                v-model="form.name"
                autofocus
                :label="inputLabel"
                :rules="cNameRules"
                @focus="onFocusTextField"
                @input="onInputName"
            />
          </v-form>
        </v-card-text>
        <v-alert
            text
            tile
            type="success"
            transition="expand-transition"
            :value="success"
        >
          <slot
              name="success-message"
              :old-value="form.change.old"
              :new-value="form.change.new"
          ></slot>
        </v-alert>
        <v-alert
            text
            tile
            dense
            type="warning"
            transition="expand-transition"
            :value="cShowNameUnchangedWarning"
        >
          <slot name="unchanged-message"></slot>
        </v-alert>
        <v-expand-transition>
          <v-card-actions
              v-if="!success"
              class="flex-column"
          >
            <v-btn
                block
                color="primary"
                :disabled="!form.valid || cNameIsUnchanged"
                :loading="loading"
                @click="onClickSubmit"
            >{{ submitLabel }}</v-btn>
            <v-btn
                block
                class="mx-0 mt-2"
                outlined
                :disabled="loading"
                @click="onClickCancel"
            ><i18n path="msc.actions.cancel" /></v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <eins-btn-timed
                block
                color="primary"
                outlined
                :disabled="loading"
                @click="onClickClose"
            >
              <i18n path="msc.actions.close" />
            </eins-btn-timed>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

import {
  objectHasOwnProperty
} from '@/utils/object'

export default {
  name: 'EinsEditNameBlock',
  components: {
    EinsBtnTimed
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiParamName: {
      type: String,
      default: 'name'
    },
    apiPath: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    ruleMessage: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'required') &&
          typeof value.required === 'string' &&
          objectHasOwnProperty(value, 'min') &&
          typeof value.min === 'string'
    },
    submitLabel: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    form: {
      dirty: false,
      valid: false,
      name: '',
      nameFirstFocus: false,
      change: {
        old: '',
        new: ''
      }
    }
  }),
  computed: {
    cNameRules () {
      return [
        value => !!value || this.ruleMessage.required,
        value => !value || value.trim().length > 2 || this.ruleMessage.min
      ]
    },
    cShowNameUnchangedWarning () {
      return this.form.dirty && this.cNameIsUnchanged
    },
    cNameIsUnchanged () {
      return this.form.name === this.currentValue
    }
  },
  methods: {
    onClickChange () {
      this.openDialog()
    },
    onFocusTextField (evt) {
      if (this.form.nameFirstFocus) {
        this.form.nameFirstFocus = false
        evt.target.select()
      }
    },
    onInputName () {
      this.markFormDirty()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiPath, {
        [this.apiParamName]: this.form.name
      })
        .then(() => {
          this.success = true
          this.form.dirty = false
          this.form.change.old = this.currentValue
          this.form.change.new = this.form.name
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    markFormDirty () {
      this.form.dirty = true
    },
    openDialog () {
      this.dialog = true
      this.reset()
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.success = false
      this.form.dirty = false
      this.form.name = this.currentValue
      this.form.nameFirstFocus = true
      this.form.change.old = ''
      this.form.change.new = ''
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
