/* global FirebasePlugin */
import {
  FCM_PERMISSION_DENIED,
  FCM_PERMISSION_GRANTED
} from '@/constants/StorageKeys'
import { getToken, onMessage } from 'firebase/messaging'

export const useCordova = () =>
  Object.prototype.hasOwnProperty.call(window, 'cordova')

export class VueFcm {
  #app = null;
  #messageListeners = []

  static install (Vue, options = {}) {
    Vue.mixin({
      beforeCreate () {
        if (!this.$parent) {
          this._fcmRoot = this
          this._fcm =
            this.$options.fcm instanceof VueFcm
              ? this.$options.fcm
              : null
        } else {
          this._fcmRoot = (this.$parent && this.$parent._fcmRoot) || this
        }
      },
      created () {
        if (!this.$parent && this._fcm) {
          this._fcm.init(this, options)
        }
      }
    })
    Object.defineProperty(Vue.prototype, '$fcm', {
      get () {
        return this._fcmRoot._fcm
      }
    })
  }

  #onMessageReceived (message) {
    this.#app.$root.onFirebaseMessage(message)
    for (const handler of this.#messageListeners) {
      handler(message)
    }
  }

  init (vm, options) {
    if (typeof vm.$options.render === 'function') {
      this.#app = vm
    }
    this.listen()
  }

  listen (success, error) {
    if (useCordova()) {
      document.addEventListener('deviceready', () => {
        FirebasePlugin.onMessageReceived(this.#onMessageReceived.bind(this), error)
      })
    } else if (
      typeof onMessage === 'function' &&
      this.#app?.$firebase?.messaging
    ) {
      onMessage(
        this.#app?.$firebase?.messaging,
        this.#onMessageReceived.bind(this)
      )
    }
  }

  hasPermission () {
    if (!useCordova()) {
      return Promise.resolve(
        Notification.permission === FCM_PERMISSION_GRANTED
      )
    } else {
      return new Promise((resolve, reject) => {
        FirebasePlugin.hasPermission(
          (hasPermission) => resolve(hasPermission),
          () => resolve(false)
        )
      })
    }
  }

  requestPermission () {
    return new Promise((resolve, reject) => {
      if (useCordova()) {
        FirebasePlugin.grantPermission((hasPermission) => {
          if (hasPermission) {
            resolve(true)
          } else {
            reject(new Error('Permission not granted'))
          }
        })
      } else {
        if (Notification.permission === FCM_PERMISSION_GRANTED) {
          resolve(true)
        } else if (Notification.permission !== FCM_PERMISSION_DENIED) {
          Notification.requestPermission().then((permission) => {
            if (permission === FCM_PERMISSION_GRANTED) {
              resolve(true)
            } else {
              reject(new Error('Permission not granted'))
            }
          })
        } else {
          reject(new Error('Permission not granted'))
        }
      }
    })
  }

  getToken () {
    if (useCordova()) {
      return new Promise((resolve, reject) => {
        FirebasePlugin.getToken(
          (token) => resolve(token),
          (error) => reject(error)
        )
      })
    } else {
      return getToken(this.#app.$firebase.messaging)
    }
  }

  addMessageListener (handler) {
    if (!this.#messageListeners.includes(handler)) {
      this.#messageListeners.push(handler)
    }
  }

  removeMessageListener (handler) {
    const idx = this.#messageListeners.indexOf(handler)
    if (idx > -1) {
      this.#messageListeners.splice(idx, 1)
    }
  }
}
