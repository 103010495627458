<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      icon="mdi-message-text"
      :text="cText.text"
      @click="onClick"
  />
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'
import {
  RegRouteDeal,
  RegRouteGroup,
  RegRoutePlayer,
  RegRouteQuest
} from '@/utils/regex'
import {
  startConversationWith
} from '@/utils/chat'
import {
  Entity
} from '@/constants'

export default {
  name: 'EinsStarmenuDpbDirectMessage',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cArea () {
      // which area is the app in, groups, deals, quests or players?
      const name = this.$route.name

      if (RegRoutePlayer.test(name)) {
        return Entity.PLAYER
      } else if (RegRouteDeal.test(name)) {
        return Entity.DEAL
      } else if (RegRouteQuest.test(name)) {
        return Entity.QUEST
      } else if (RegRouteGroup.test(name)) {
        return Entity.GROUP
      } else {
        return null
      }
    },
    cShowBtn () {
      return this.cBelongsToRoute && !this.cIsCurrentPlayer && this.cRelatedPlayerId
    },
    cRelatedPlayerId () {
      switch (this.cArea) {
        case Entity.PLAYER:
          return this.$store.getters['players/selected/player']?.id
        case Entity.GROUP:
          return this.$store.getters['groups/selected/group']?.owner_id
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal']?.player?.id
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest']?.player?.id
        default:
          return false
      }
    },
    cIsCurrentPlayer () {
      return this.$store.getters['players/current/id'] === this.cRelatedPlayerId
    }
  },
  methods: {
    onClick () {
      return startConversationWith(this.cRelatedPlayerId)
    }
  }
}
</script>

<style scoped>
</style>
