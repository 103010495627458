<template>
  <eins-layout-item-detail-simple
      :blocks="cBlocks"
      @oncontainerscroll="onListScroll"
  >
    <template #information>
      <eins-group-details-alert
          v-if="group"
          class="mb-0"
          style="padding-right: 3.25em;"
          dense
          outlined
          :type="cAlertType"
          :value="true"
      />
    </template>
    <template #image>
      <v-row
          no-gutters
          class="flex-nowrap image-overlay"
      >
        <v-col
            class="overflow-hidden flex-grow-1 flex-shrink-0"
            :style="cImageStyle"
        >
          <eins-api-image
              #default="{ src }"
              :src="cPicture"
          >
            <!-- aspect-ratio="1.875"-->
            <v-img
                style="aspect-ratio:1"
                :src="src"
                @click="onClickImage"
            >
              <template #placeholder>
                <v-skeleton-loader
                    tile
                    type="image"
                />
              </template>
            </v-img>
          </eins-api-image>
          <eins-layout-image-overlay
              v-show="cShowImageOverlay"
              :left="true"
              :top="false"
          >
            <template #title>
            </template>
          </eins-layout-image-overlay>
        </v-col>
        <v-col
            v-show="cShowImageOverlay"
            class="groupoff text-center items-center flex-grow-0 flex-shrink-1"
            style="min-width:50px"
        >
          <div>
            <v-icon
                v-if="cGroupType"
            >
              {{ cGroupType.icon }}
            </v-icon>
          </div>

          <div class="mt-2">
            {{ cMeta.members }}
            <v-icon>mdi-account-multiple
            </v-icon>
          </div>
          <div>
            {{ cMeta.quests }}
            <v-icon>
              mdi-map-marker
            </v-icon>
          </div>

          <div>
            {{ cMeta.deals }}
            <v-icon>
              mdi-cart
            </v-icon>
          </div>
          <div
              class="text-h6 text-no-wrap text-left"
              style="margin-top:20px;height:110px;"
          >
            <div style="transform: rotate(90deg);">
              <span class="text-truncate">
                {{ group.title }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
            v-show="!cShowImageOverlay"
            class="flex-grow-1"
        >
          <template>
            <v-row class="pa-2 no-gutters">
              <v-col
                  v-if="group"
                  cols="12"
                  class="groupoff text-truncate pa-1"
              >
                <v-icon
                    v-if="cGroupType"
                    left
                >{{ cGroupType.icon }}</v-icon>{{ group.title }}
              </v-col>
              <v-col
                  cols="4"
              >
                <v-icon
                    left
                >mdi-account-multiple</v-icon>{{ cMeta.members }}
              </v-col>
              <v-col
                  cols="4"
              >
                <v-icon
                    left
                >mdi-map-marker</v-icon>{{ cMeta.quests }}
              </v-col>
              <v-col
                  cols="4"
              >
                <v-icon
                    left
                >mdi-cart</v-icon>{{ cMeta.deals }}
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </template>
    <template #title>
      <div
          v-if="group"
          class="text-truncate"
      >
        <v-icon
            v-if="cGroupType"
            left
        >{{ cGroupType.icon }}</v-icon>{{ group.title }}
      </div>
    </template>
    <template #block-description>
      <div class="row pa-3">
        <span class="ml-4 text-h6 col col-10">{{ group.title }}</span>
        <div class="ml-4 pa-3 col-11 text-pre-line">
          {{ group.description }}
        </div>
      </div>
    </template>

    <template #block-details>
      <eins-layout-detail-list
          object-type="group"
          class="pt-3"
          :items="cDetailList"
      />
    </template>

    <template #block-map>
      <div style="position:relative;height:200px;">
        <eins-map-detail-position
            #popup
            :position="cPosition"
            style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;"
        >
          <eins-map-address-popup
              :alt-text="group.title"
              v-bind="cAddress"
          />
        </eins-map-detail-position>
      </div>
    </template>
  </eins-layout-item-detail-simple>
</template>

<script>
import EinsGroupDetailsAlert from '@/components/group/EinsGroupDetailsAlert'
import EinsLayoutDetailList from '@/components/layout/EinsLayoutDetailList'
import EinsMapAddressPopup from '@/components/map/EinsMapAddressPopup'
import EinsMapDetailPosition from '@/components/map/EinsMapDetailPosition'
import EinsApiImage from '@/components/EinsApiImage'
import EinsLayoutItemDetailSimple from '@/components/layout/EinsLayoutItemDetailSimple'

import {
  MxTranslatable
} from '@/mixins'
import {
  floorToMagnitude
} from '@/utils/number'
import {
  Membership
} from '@/constants'
import EinsLayoutImageOverlay from '@/components/layout/EinsLayoutImageOverlay'
import { googleMapsLinkUrl } from '@/utils/geo'

const genericPicture2 = `${location.protocol}//${location.host}/img/placeholder/blind-group.gif`

export default {
  name: 'EinsGroupDetailBasicData',
  components: {
    EinsLayoutImageOverlay,
    EinsLayoutItemDetailSimple,
    EinsApiImage,
    EinsLayoutDetailList,
    EinsMapAddressPopup,
    EinsGroupDetailsAlert,
    EinsMapDetailPosition
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    group: {
      type: Object,
      default: null
    },
    groupId: {
      type: [ Number, String ],
      default: 0
    }
  },
  data: () => ({
    imageUrl: `https://picsum.photos/375/200`,
    tab: null,
    detailDialog: false,
    imageWidth: 100,
    $_mx_translatable: {
      paths: [
      ],
      params: {
      }
    }
  }),
  computed: {
    cBlocks () {
      const result = []

      result.push({
        value: 'description',
        icon: 'mdi-note-text'
      })
      if (this.cHasValidPosition) {
        result.push({
          value: 'map',
          icon: 'mdi-map'
        })
      }
      result.push({
        value: 'details',
        icon: 'mdi-google-circles-communities'
      })
      return result
    },
    cHasValidPosition () {
      return this.cPosition.lat !== null &&
            !isNaN(this.cPosition.lat) &&
            this.cPosition.lon !== null &&
            !isNaN(this.cPosition.lon)
    },
    cAlertType () {
      const membership = this.group?.membership_state

      if (membership === Membership.NONE) {
        const applicants = this.group?.applicants ?? []
        const applicantsRejected = this.group?.applicants_rejected ?? []
        if (applicants.includes(this.$store.getters['players/current/id'])) {
          return applicantsRejected.includes(this.$store.getters['players/current/id'])
            ? 'applicant_rejected'
            : 'applicant'
        } else {
          return 'stranger'
        }
      } else if (membership === Membership.MEMBER) {
        if (this.cMeta.quests > 0) {
          return 'quest'
        } else if (this.cMeta.deals > 0) {
          return 'deal'
        } else {
          return 'member'
        }
      } else if (membership === Membership.ADMIN) {
        if (this.cMeta.quests === 0) {
          return 'addquest'
        } else if (this.cMeta.deals === 0) {
          return 'adddeal'
        } else {
          return 'admin'
        }
      } else {
        return ''
      }
    },
    cPicture () {
      if (this.group) {
        return this.group.title_picture_full ?? genericPicture2
      } else {
        return null
      }
    },
    cShowImageOverlay () {
      return this.imageWidth > 30
    },
    cImageStyle () {
      return 'max-width:' + this.imageWidth + '%'
    },
    cAddress () {
      const result = {
        street: this.group?.street?.trim() ?? '',
        number: this.group?.house_number?.trim() ?? '',
        zip: this.group?.postal_code?.trim() ?? '',
        city: this.group?.city?.trim() ?? '',
        country: this.group?.country?.trim() ?? ''
      }

      result.navLink = googleMapsLinkUrl(this.cPosition.lat, this.cPosition.lon)

      return result
    },
    cMeta () {
      const result = {
        members: floorToMagnitude(this.group?.members?.length ?? 0),
        quests: floorToMagnitude(this.group?.quests_open?.length ?? 0),
        questsDone: floorToMagnitude(this.group?.quests_solved?.length ?? 0),
        deals: floorToMagnitude(this.group?.deals_open?.length ?? 0)
      }

      for (const prop in result) {
        if (Object.prototype.hasOwnProperty.call(result, prop)) {
          const value = result[prop]
          result[prop] = this.$n(value, 'compact')

          if (value > 1000) {
            result[prop] = result[prop] + '+'
          }
        }
      }

      return result
    },
    cGroupId () {
      return this.group?.id ?? null
    },
    cPosition () {
      let mapCenter = {}
      if (
        !isNaN(this.group?.latitude) &&
            !isNaN(this.group?.longitude)
      ) {
        mapCenter.lat = this.group.latitude
        mapCenter.lon = this.group.longitude
      } else {
        mapCenter.lat = null
        mapCenter.lon = null
      }

      return mapCenter
    },
    cGroupType () {
      const data = this.group?.group_type ?? null
      const key = data.key
      return data
        ? {
          ...data,
          title: this.$t(`groupTypes.${key}.title`),
          description: this.$t(`groupTypes.${key}.description`)
        }
        : null
    },
    cDetailList () {
      const result = []
      const t = (path, params = []) => this.$t(`components.EinsGroupDetail.${path}`, params)
      const membership = this.group?.membership_state ?? Membership.NONE
      result.push({
        key: 'membership',
        icon: 'mdi-wallet-membership',
        title: t('details.membership.title'),
        subtitle: t('details.membership.sub-title-' + membership)
      })

      if (this.cGroupType) {
        result.push({
          key: 'group-type',
          icon: this.cGroupType?.icon ?? '',
          title: this.cGroupType?.title ?? '',
          subtitle: this.cGroupType?.description ?? ''
        })
      }

      result.push({
        key: 'group-id',
        icon: 'mdi-google-circles-communities',
        title: t('details.group-id.title', [ this.group?.id ]),
        subtitle: t('details.group-id.sub-title')
      })

      result.push({
        key: 'group-members',
        icon: 'mdi-account-multiple',
        title: t('details.members.title', [ this.group?.members?.length ?? 0 ]),
        subtitle: t('details.members.sub-title')
      })

      result.push({
        key: 'group-quests',
        icon: 'mdi-map-marker',
        title: t('details.quests.title', [ this.group?.quests_open?.length ?? 0 ]),
        subtitle: t('details.quests.sub-title')
      })
      result.push({
        key: 'group-deals',
        icon: 'mdi-cart',
        title: t('details.deals.title', [ this.group?.deals_open?.length ?? 0 ]),
        subtitle: t('details.deals.sub-title')
      })

      result.push({
        key: 'group-quests-done',
        icon: 'mdi-crown',
        title: t('details.questsDone.title', [ this.group?.quests_solved?.length ?? 0 ]),
        subtitle: t('details.questsDone.sub-title')
      })

      return result
    }
  },
  methods: {
    onClickImage () {
      this.$router.push(`/groups/${this.group.id}/images`)
    },
    onListScroll (arg) {
      if (arg < this.imageWidth) {
        this.imageWidth = 20
        // this.imageWidth = arg < 20 || isNaN(arg) ? 20 : arg
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-overlay {
  border:3px solid var(--v-group-base);
}
</style>
