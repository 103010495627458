<!-- Close the component if state is set to 'closed' -->
<template>
  <eins-quest-list-item
      :quest="quest"
  >
    <template #indicator3>
      <v-spacer />
      <v-col v-if="cApplicationLength">
        <v-icon color="white">{{ cActionIndicatorIcon }}</v-icon>
      </v-col>
      <v-spacer />
    </template>
    <template
        v-if="cUserIsParticipant"
        #content
    >
      <v-row
          v-if="cApplicationLength"
          dense
          class="fill-height"
          align-content="end"
      >
        <v-col>
          <!-- Check if state is open and act accordingly -->
          <v-btn
              v-if="quest.state === 'open'"
              color="primary"
              outlined
              block
              height="60px"
          >
            <router-link
                :to="!cUserIsAssignee ? `/quests/${quest.id}/applications` : `/players/${cPlayerId}/quests`"
                class="mt-2 text-capitalize"
            >
              <v-icon>{{ 'mdi-human-greeting' }}</v-icon>
              <p
                  class="mb-1"
                  style="font-size:10px"
              >{{ 'Applications' }}</p>
              <p
                  class="mt-n1"
                  style="font-size:10px"
              >{{ 'List' }}</p>
            </router-link>
          </v-btn>
          <template
              v-else-if="quest.state === 'closed'"
          >
            <div class="mt-2 text-capitalize">
              {{ quest.description }}
            </div>
          </template>
          <v-btn
              v-else
              color="primary"
              outlined
              block
              height="60px"
              @click.stop.prevent="onClickStartConversation"
          >
            <div class="mt-2 text-capitalize">
              <v-icon>mdi-forum</v-icon>
              <p class="mt-1"> chat</p>
            </div>
          </v-btn>
        </v-col>
        <!-- Check if the state is opened already-->
        <v-col v-show="quest.state !== 'open'">
          <v-btn
              v-show="cShowBtnStart"
              v-if="cUserIsAssignee"
              color="primary"
              outlined
              block
              height="60px"
              :loading="isLoading"
              @click.stop.prevent="onClickStarted"
          >
            <div class="pt-1 text-capitalize">
              <v-icon>{{ cIconState.icon }}</v-icon>
              <p
                  class="mb-1"
                  style="font-size:10px"
              >{{ cIconState.text }}</p>
              <p
                  class="mt-n1"
                  style="font-size:10px"
              >{{ cIconState['sub-text'] }}</p>
            </div>
          </v-btn>
          <!-- Check if the state is finished -->
          <v-btn
              v-show="cShowBtnFinish"
              v-if="cUserIsAssignee"
              color="primary"
              outlined
              block
              height="60px"
              :loading="isLoading"
              @click.stop.prevent="onClickFinished"
          >
            <div class="pt-1 text-capitalize">
              <v-icon>{{ cIconState.icon }}</v-icon>
              <p
                  class="mb-1"
                  style="font-size:10px"
              >{{ cIconState.text }}</p>
              <p
                  class="mt-n1"
                  style="font-size:10px"
              >{{ cIconState['sub-text'] }}</p>
            </div>
          </v-btn>
          <!-- Check if the state is started -->
          <v-btn
              v-show="cShowBtnApprove"
              v-if="!cUserIsAssignee"
              color="primary"
              outlined
              block
              height="60px"
              :loading="isLoading"
              @click.stop.prevent="onClickApproved"
          >
            <div class="pt-1 text-capitalize">
              <v-icon>{{ cIconState.icon }}</v-icon>
              <p
                  class="mb-1"
                  style="font-size:10px"
              >{{ cIconState.text }}</p>
              <p
                  class="mt-n1"
                  style="font-size:10px"
              >{{ cIconState['sub-text'] }}</p>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </eins-quest-list-item>
</template>

<script>
import EinsQuestListItem from '@/components/quest/EinsQuestListItem'
import { startConversationWith } from '@/utils/chat'
import { QuestState } from '@/constants'

export default {
  name: 'EinsQuestListItemPlayerQuests',
  components: {
    EinsQuestListItem
  },
  props: {
    quest: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isLoading: false
  }),

  computed: {
    cQuestState () {
      return this.quest?.state
    },
    cIsApplicationVisible () { return this.cQuestState === 'open' && this.cUserIsAssignee },
    cQuest () {
      return this.quest
    },
    cActionIndicatorIcon () {
      if (
        (this.cQuestState === QuestState.FINISHED && this.cUserIsAssignee) ||
        (
          (
            this.cQuestState === QuestState.STARTED ||
            this.cQuestState === QuestState.ASSIGNED
          ) &&
          this.cUserIsOwner
        )
      ) {
        return 'mdi-account-clock'
      } else {
        return this.cIcon.icon
      }
    },
    cPlayerId () {
      let currentPlayerId = this.$store.getters['players/current/id']
      return currentPlayerId
    },
    cQuestIsAssigned () {
      return this.cQuestState === QuestState.ASSIGNED
    },
    cShowBtnStart () {
      return this.cQuestIsAssigned && this.cUserIsAssignee
    },
    cQuestIsStarted () {
      return this.cQuestState === QuestState.STARTED
    },
    cShowBtnFinish () {
      return this.cQuestIsStarted && this.cUserIsAssignee
    },
    cQuestIsFinished () {
      return this.cQuestState === QuestState.FINISHED
    },
    cShowBtnApprove () {
      return this.cQuestIsFinished && this.cUserIsOwner
    },
    cUserIsParticipant () {
      return this.cUserIsOwner || this.cUserIsAssignee
    },
    cUserIsAssignee () {
      return this.cPlayerId === this.quest?.processing?.user_id
    },
    cUserIsOwner () {
      return this.cPlayerId === this.quest?.user_id
    },
    cShowBtnUser () {
      return this.cPlayerId === this.quest?.processing?.user_id
    },
    cOpponentId () {
      return this.quest?.processing?.user_id === this.cPlayerId ? this.quest?.user_id : this.quest?.processing?.user_id
    },
    cApplicationLength () {
      return this.quest?.applications_count > 0
    },
    cIconState () {
      switch (this.cQuestState) {
        case 'open':
          return { icon: 'mdi-human-greeting', text: 'Application', 'sub-text': 'list' }
        case 'assigned':
          return { icon: 'mdi-run', text: 'Start', 'sub-text': 'quest' }
        case 'started':
          return { icon: 'mdi-camera', text: 'Finish', 'sub-text': 'quest' }
        case 'finished' :
          return { icon: 'mdi-crown', text: 'Confirm', 'sub-text': 'quest' }
        default: return { icon: 'mdi-human-greeting', text: 'Application', 'sub-text': 'list' }
      }
    },
    cIcon () {
      switch (this.cQuestState) {
        case 'open':
          return { icon: 'mdi-human-greeting' }
        case 'assigned':
          return { icon: 'mdi-lock-open-variant' }
        case 'started':
          return { icon: 'mdi-run' }
        case 'finished' :
          return { icon: 'mdi-camera' }
        case 'closed' :
          return { icon: 'mdi-crown' }
        default: return { icon: 'mdi-human-greeting' }
      }
    }
  },

  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    startConversation () {
      return startConversationWith(this.cPlayerId)
    },
    onClickStarted () {
      this.isLoading = true
      this.$http.$api.patch(`quests/${this.quest?.id}/processing`, {
        started: true
      })
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('players/selected/questsRelated/loadAll'),
            this.cIcon
          ])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClickFinished () {
      this.isLoading = true

      this.$store.dispatch('quests/selected/load', this.quest?.id)
        .then(() => this.$store.dispatch('openDialogFinishQuest', {
          quest: this.cQuest
        }))
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('players/selected/questsRelated/loadAll'),
            this.cIcon
          ])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClickApproved () {
      this.isLoading = true
      this.$http.$api.patch(`quests/${this.quest?.id}/processing`, {
        approved: true
      })
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('quests/selected/update'),
            this.$store.dispatch('players/selected/questsRelated/loadAll'),
            this.cIcon
          ])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
