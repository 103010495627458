<template>
  <l-map
      :center="centerInternal"
      :zoom="zoomInternal"
      zoom-snap="0.25"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
  >
    <l-tile-layer
        :attribution="cSource.attribution"
        :url="cSource.url"
    />
    <l-marker
        v-if="cShowMarker"
        :lat-lng="markerInternal"
        @add="onAddMarker"
    >
      <l-popup
          v-if="cPopupPopulated"
          :options="options.popup"
      >
        <!-- eslint-disable-next-line -->
        <slot name="popup" />
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup
} from 'vue2-leaflet'

export default {
  name: 'EinsMapDetailPosition',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  props: {
    position: {
      type: Object,
      required: true,
      validator: val => !isNaN(val.lon) && !isNaN(val.lat)
    },
    hideMarker: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    zoomInternal: 17,
    centerInternal: [0, 0],
    markerInternal: [0, 0],
    showMarker: false,
    options: {
      popup: {
        closeOnClick: false,
        autoClose: false,
        keepInView: true,
        closeButton: false,
        closeOnEscapeKey: false
      }
    },
    centerUpdateTimeout: null
  }),
  computed: {
    cSource () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }
    },
    cPopupPopulated () {
      return !!this.$scopedSlots.popup
    },
    cShowMarker () {
      return this.showMarker && !this.hideMarker
    }
  },
  watch: {
    position: {
      handler: 'setPosition',
      immediate: true,
      deep: true
    }
  },
  methods: {
    onAddMarker (e) {
      this.$nextTick()
        .then(() => {
          e.target.openPopup()
        })
    },
    zoomUpdated (zoom) {
      this.zoomInternal = zoom
      this.emitZoom()
    },
    emitZoom () {
      this.$emit('update:zoom', this.zoomInternal)
    },
    centerUpdated (center) {
      if (this.centerUpdateTimeout !== null) {
        clearTimeout(this.centerUpdateTimeout)
      }

      this.centerUpdateTimeout = setTimeout(() => {
        this.centerInternal = [center.lat, center.lng]
        this.emitCenter()
        this.centerUpdateTimeout = null
      }, 300)
    },
    emitCenter () {
      this.$emit('update:center', this.centerInternal)
    },
    setPosition (newVal, oldVal) {
      this.centerInternal = [newVal.lat, newVal.lon]
      this.markerInternal = [newVal.lat, newVal.lon]

      this.showMarker = true
    }
  }
}
</script>

<style scoped>

</style>
