<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }}</v-card-title>
      <v-card-text v-if="cPromoCode">
        {{ cText.text }}
      </v-card-text>
      <v-alert
          type="error"
          tile
          text
          :value="!!cError"
      >
        {{ cError }}
      </v-alert>
      <v-alert
          type="success"
          tile
          text
          :value="success"
      >
        {{ cText.success }}
      </v-alert>
      <v-card-actions>
        <template>
          <v-row
              dense
              class="flex-column"
          >
            <v-col
                v-if="cPromoCode"
                class="mb-8"
            >
              <v-btn
                  color="green"
                  outlined
                  block
                  @click="onClickCopyToClipboard"
              >
                {{ cPromoCode }}
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                  outlined
                  block
                  @click="onClickCancel"
              >
                {{ cText['btn.cancel'] }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  outlined
                  color="primary"
                  block
                  :loading="loading"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgLinkDeal',
  components: {},
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    success: false,
    error: false,
    $_mx_translatable: {
      paths: [
        'title',
        'text',
        'success',
        'error.404',
        'error.403',
        'error.default',
        'btn.submit',
        'btn.cancel',
        'btn.close'
      ]
    }
  }),
  computed: {
    cError () {
      switch (this.error) {
        case 404: return this.cText['error.404']
        case 403: return this.cText['error.403']
        case false: return false
        default: return this.cText['error.default']
      }
    },
    open: {
      get () {
        return this.$store.getters['dialogLinkDeal']
      },
      set (value) {
        this.$store.commit('dialogLinkDeal', value)
      }
    },
    cDealTitle () {
      return this.cDeal ? `'${this.cDeal.title}'` : ''
    },
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cLink () {
      return this.cDeal?.link || ''
    },
    cPromoCode () {
      return this.cDeal?.promo_code || ''
    }
  },
  watch: {
    open: 'onOpen'
  },
  created () {
  },
  methods: {
    onOpen () {
      this.success = false
      this.error = false
      this.loading = false
    },
    onClickClose () {
      this.close()
    },
    onClickCancel () {
      this.close()
    },
    onClickSubmit () {
      this.linkDeal()
    },
    async onClickCopyToClipboard () {
      this.loading = true
      try {
        await navigator.clipboard.writeText(this.cPromoCode)
        this.success = true
      } catch {
        this.success = false
        this.error = true
      }
      this.loading = false
    },
    close () {
      this.open = false
    },
    linkDeal () {
      this.loading = true
      /*
      this.$router.push({
        name: 'link',
        params: {
          source: this.cLink
        }
      })
       */
      window.open(this.cLink, '_blank')
      this.loading = false
      this.close()
    }
  }
}
</script>

<style scoped>
</style>
