<template>
  <div>
    <v-text-field
        v-model="deal.title"
        dense
        outlined
        :label="cLabel.title"
        :rules="cRules.title"
        @input="emitInput"
    />
    <eins-image-input
        aspect-ratio="1"
        :title="cLabel.titlePicture.title"
        :cancel-label="cLabel.titlePicture.cancel"
        :send-label="cLabel.titlePicture.submit"
        :rules="cRules.titlePicture"
        @select="onSelectFile"
    />
    <eins-input-textarea
        v-model="deal.description"
        :label="cLabel.description"
        :rules="cRules.description"
        class="mt-3"
        dense
        outlined
        @input="emitInput"
    />
    <eins-input-time-value
        v-model="deal.value"
        :label="cLabel.value"
        :max="480"
        :min="1"
        @input="emitInput"
    />
    <eins-input-group-scope-dialog
        v-model="deal.player_group_id"
        :current-value-label="cLabel.scope.currentValueLabel"
        :dialog-title="cLabel.scope.dialogTitle"
        :void-option="cLabel.scope.voidOption"
        :list-caption="cLabel.scope.listCaption"
        :submit-label="cLabel.scope.submit"
        @input="emitInput"
    />
    <v-row
        v-if="cCanBecomeGroupDeal"
        dense
    >
      <v-col>
        {{ cLabel.isGroupDeal.label }}
      </v-col>
      <v-col class="flex-grow-0">
        <v-switch
            v-model="deal.is_group_deal"
            class="mt-0"
            hide-details
            @input="emitInput"
        />
      </v-col>
      <v-col
          cols="12"
          class="text-caption grey--text text--darken-1"
      >
        {{ cLabel.isGroupDeal.explanation }}
      </v-col>
    </v-row>
    <v-row
        v-if="cIsGroupDeal"
        dense
    >
      <v-col>
        {{ cLabel.public.label }}
      </v-col>
      <v-col class="flex-grow-0">
        <v-switch
            v-model="deal.public"
            class="mt-0"
            hide-details
            @input="emitInput"
        />
      </v-col>
      <v-col
          cols="12"
          class="text-caption grey--text text--darken-1"
      >
        {{ cLabel.public.explanation }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        {{ cLabel.requireApplication.label }}
      </v-col>
      <v-col class="flex-grow-0">
        <v-switch
            v-model="deal.require_application"
            class="mt-0"
            hide-details
            @input="emitInput"
        />
      </v-col>
      <v-col
          cols="12"
          class="text-caption grey--text text--darken-1"
      >
        {{ cLabel.requireApplication.explanation }}
      </v-col>
    </v-row>
    <eins-input-location
        v-model="dealLocation"
        :dialog-title-address="cLabel.location.addressTitle"
        :submit-label-address="cLabel.location.addressSubmit"
        :rules="cRules.location"
        @input="emitInput"
    />
  </div>
</template>

<script>
import EinsImageInput from '@/components/input/EinsImageInput'
import EinsInputTextarea from '@/components/input/EinsInputTextarea'
import EinsInputTimeValue from '@/components/input/EinsInputTimeValue'
import EinsInputGroupScopeDialog from '@/components/input/EinsInputGroupScopeDialog'
import EinsInputLocation from '@/components/input/location/EinsInputLocation'
import { isValidGeolocation } from '@/utils/geo'
import { objectHasOwnProperty } from '@/utils/object'

export default {
  name: 'EinsDealCreateForm',
  components: {
    EinsInputLocation,
    EinsInputGroupScopeDialog,
    EinsInputTimeValue,
    EinsInputTextarea,
    EinsImageInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    dealType: {
      type: String,
      default: 'service'
    }
  },
  data: () => ({
    deal: {
      title: '',
      picture: null,
      description: '',
      templateName: '',
      value: 1,
      player_group_id: null,
      public: false,
      is_group_deal: false,
      type: '',
      latitude: null,
      longitude: null,
      street: '',
      house_number: '',
      postal_code: '',
      require_application: false,
      city: '',
      country: 'DE'
    }
  }),
  computed: {
    cLabel () {
      const t = path => this.$t(`deal.create.${path}`)

      return {
        title: t('title.label'),
        titlePicture: {
          title: t('titlePicture.title'),
          cancel: t('titlePicture.cancel'),
          submit: t('titlePicture.submit')
        },
        description: t('description.label'),
        value: t('value.label'),
        location: {
          addressTitle: t('location.addressTitle'),
          addressSubmit: t('location.addressSubmit')
        },
        scope: {
          currentValueLabel: t('scope.currentValueLabel'),
          dialogTitle: t('scope.dialogTitle'),
          listCaption: t('scope.listCaption'),
          voidOption: {
            label: t('scope.voidOption')
          },
          submit: t('scope.submit')
        },
        isGroupDeal: {
          label: t('isGroupDeal.label'),
          explanation: this.deal.is_group_deal
            ? t('isGroupDeal.explanationTrue')
            : t('isGroupDeal.explanationFalse')
        },
        public: {
          label: t('public.label'),
          explanation: this.deal.public
            ? t('public.explanationTrue')
            : t('public.explanationFalse')
        },
        requireApplication: {
          label: t('requireApplication.label'),
          explanation: t('requireApplication.explanation')
        }
      }
    },
    cRules () {
      const t = (path, payload = []) => this.$t(`deal.create.${path}`, payload)

      return {
        title: [
          val => !!val || t('title.required'),
          val => !val || val.length > 2 || t('title.min')
        ],
        titlePicture: [
          value => !!value || t('titlePicture.required')
        ],
        description: [
          value => !!value || t('description.required'),
          value => !value ||
              value.split(/ |\n/g).filter(part => !!part).length >= 5 ||
              t('description.min', [5]),
          value => !value || value.length <= 1000 || t('description.max', [1000])
        ],
        location: [
          value => {
            const isValidPosition = isValidGeolocation({
              lat: value.latitude,
              lon: value.longitude
            })
            const hasAddress = value &&
                value.street &&
                value.houseNumber &&
                value.city &&
                value.zip &&
                value.country

            return isValidPosition || hasAddress || t('location.required')
          }
        ]
      }
    },
    cCanBecomeGroupDeal () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []

      return adminGroups.includes(this.deal.player_group_id)
    },
    cIsGroupDeal () {
      return this.cCanBecomeGroupDeal && this.deal.is_group_deal
    },
    dealLocation: {
      get () {
        return {
          latitude: this.deal.latitude,
          longitude: this.deal.longitude,
          street: this.deal.street,
          houseNumber: this.deal.house_number,
          zipCode: this.deal.postal_code,
          city: this.deal.city,
          country: this.deal.country
        }
      },
      set (val) {
        this.deal.latitude = val.latitude
        this.deal.longitude = val.longitude
        this.deal.street = val.street
        this.deal.house_number = val.houseNumber
        this.deal.postal_code = val.zipCode
        this.deal.city = val.city
        this.deal.country = val.country
      }
    }
  },
  watch: {
    value: {
      handler: 'setValue',
      deep: true,
      immediate: true
    },
    dealType: {
      handler: 'onChangeType',
      immediate: true
    },
    cIsGroupDeal: {
      handler: 'onChangeIsGroupDeal',
      immediate: true
    },
    'deal.player_group_id': {
      handler: 'onChangePlayerGroupId',
      immediate: true
    }
  },
  created () {
    this.fetchPlayerGroups()
  },
  methods: {
    onChangeType (value) {
      this.deal.type = value
    },
    onChangeIsGroupDeal (value) {
      if (!value) {
        this.deal.public = this.deal.player_group_id === null
      }
    },
    onSelectFile (file) {
      this.deal.picture = file
      this.emitInput()
    },
    setValue (value) {
      for (const key in this.deal) {
        if (key !== 'type' && value && objectHasOwnProperty(value, key)) {
          if (key === 'player_group_id' && value[key] !== null) {
            try {
              this.deal[key] = this.$store.getters['players/current/groups'].includes(value[key]) ? value[key] : null
            } catch (e) {
              this.deal[key] = null
            }
          } else {
            this.deal[key] = value[key]
          }
        }
      }
    },
    emitInput () {
      this.$emit('input', {
        ...this.deal
      })
    },
    onChangePlayerGroupId (value) {
      if (value === null) {
        this.deal.is_group_deal = false
        this.deal.public = true
      } else {
        if (!this.cCanBecomeGroupDeal) {
          this.deal.is_group_deal = false
        }
      }
    },
    fetchPlayerGroups () {
      return this.$store.dispatch('players/current/groups/getData')
    }
  }
}
</script>

<style scoped>
</style>
