import axios from 'axios'

const setAxiosDefaults = (instance, defaults) => {
  for (const key in defaults) {
    if (Object.prototype.hasOwnProperty.call(defaults, key) && key !== 'headers') {
      instance.defaults[key] = defaults[key]
    }
  }

  if (typeof defaults.headers === 'object') {
    for (const group in defaults.headers) {
      if (Object.prototype.hasOwnProperty.call(defaults.headers, group)) {
        for (const headerName in defaults.headers[group]) {
          if (Object.prototype.hasOwnProperty.call(defaults.headers[group], headerName)) {
            instance.defaults.headers[group][headerName] = defaults.headers[group][headerName]
          }
        }
      }
    }
  }
}

export class VueHttp {
  static installed = false
  static baseInstance = null

  static install (Vue, options = {}) {
    if (this.baseInstance === null) {
      this.baseInstance = new this(options)
    }

    Object.defineProperty(Vue.prototype, '$http', {
      configurable: false,
      value: this.baseInstance,
      writable: false
    })

    this.installed = true
  }

  #axios

  constructor (options = {}) {
    if (VueHttp.baseInstance === null) {
      VueHttp.baseInstance = this
    }

    if (
      typeof options.axios === 'object' &&
      options.axios instanceof axios.Axios
    ) {
      this.#axios = options.axios
    } else {
      this.#axios = axios.create()
    }

    if (typeof options.defaults === 'object') {
      setAxiosDefaults(this.#axios, options.defaults)
    }

    if (typeof options.instances === 'object') {
      for (const key in options.instances) {
        if (
          Object.prototype.hasOwnProperty.call(options.instances, key) &&
          typeof options.instances[key] === 'object'
        ) {
          const instanceKey = `$${key}`
          Object.defineProperty(this, instanceKey, {
            configurable: false,
            value: new VueHttp(options.instances[key]),
            writable: false
          })
        }
      }
    }
  }

  get axios () {
    return this.#axios
  }

  request (url, config = {}) {
    return this.axios.request(url, config)
  }

  get (url, config = {}) {
    return this.axios.get(url, config)
  }

  delete (url, config = {}) {
    return this.axios.delete(url, config)
  }

  head (url, config = {}) {
    return this.axios.head(url, config)
  }

  options (url, config = {}) {
    return this.axios.options(url, config)
  }

  post (url, data = {}, config = {}) {
    return this.axios.post(url, data, config)
  }

  put (url, data = {}, config = {}) {
    return this.axios.put(url, data, config)
  }

  patch (url, data = {}, config = {}) {
    return this.axios.patch(url, data, config)
  }
}
