import { render, staticRenderFns } from "./EinsPlayerDetailBasicData.vue?vue&type=template&id=2fbadd5d&scoped=true&"
import script from "./EinsPlayerDetailBasicData.vue?vue&type=script&lang=js&"
export * from "./EinsPlayerDetailBasicData.vue?vue&type=script&lang=js&"
import style0 from "./EinsPlayerDetailBasicData.vue?vue&type=style&index=0&id=2fbadd5d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbadd5d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBadge,VChip,VChipGroup,VCol,VDivider,VIcon,VImg,VRow,VSkeletonLoader})
