<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
  >
    <router-link
        :to="`/`"
        style="text-decoration: none; color: inherit;"
    >
      <v-icon
          left
          color="primary"
      >mdi-page-first</v-icon>
      {{ cText.text }}
    </router-link>
  </eins-starmenu-ab>
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'

import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbMainpage',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute
    }
  }

}
</script>

<style scoped>

</style>
