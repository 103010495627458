export default {
  'private': {
    'name': '@.lower:(terms.private)',
    'caption': '@.lower:(terms.player) hat die "@.lower:(terms.player)" Medaillie erhalten',
    'caption_get': 'Du hast die Medaille "@.lower:(terms.private)" erhalten',
    'description': 'Hat eine @.lower:(terms.private) @.lower:(terms.quest) durchgeführt'
  },
  'common': {
    'name': '@.lower:(terms.common)',
    'caption': '@.lower:(terms.player)  hat die "@.lower:(terms.common)" Medaillie erhalten',
    'caption_get': 'Du hast die Medaille "@.lower:(terms.common)" erhalten',
    'description': 'Hat eine @.lower:(terms.common) @.lower:(terms.quest) durchgeführt'
  },
  'green': {
    'name': '@.lower:(terms.future)',
    'caption': '@.lower:(terms.player)  hat die "@.lower:(terms.future)" Medaillie erhalten',
    'caption_get': 'Du hast die Medaille "@.lower:(terms.future)" erhalten',
    'description': 'Hat eine @.lower:(terms.future) @.lower:(terms.quest) durchgeführt'
  },
  '4': {
    'name': 'Supporter',
    'caption': '@.lower:(terms.player) erhielt die Fördermedaille',
    'caption_get': 'Du unterstützt die 1APP',
    'description': 'Du bist ein Unterstützer!'
  },
  '5': {
    'name': 'newmedal',
    'caption': '@.lower:(terms.player) received the "new" medal',
    'caption_get': 'You get the new medal',
    'description': 'You are new medal!'
  }
}
