export default {
  labels: {
    title: 'Standort festlegen',
    subtitle: ''
  },
  fields: {
    street: {
      label: 'Strasse',
      placeholder: 'Strasse eingeben'
    },
    houseNumber: {
      label: 'Hausnummer',
      placeholder: 'Hausnummer eingeben'
    },
    zipCode: {
      label: 'PLZ',
      placeholder: 'PLZ eingeben'
    },
    city: {
      label: 'Ort',
      placeholder: 'Ort eingeben'
    },
    country: {
      label: 'Land',
      placeholder: 'Land eingebeny'
    }
  },
  buttons: {
    confirm: {
      label: 'Adresse festlegen'
    },
    viaGps: {
      label: 'via GPS'
    },
    manually: {
      label: 'manuell auf der Karte'
    },
    cancel: {
      label: 'Abbrechen'
    }
  },
  messages: {
    success: {
      label: 'Die Geolokalisierung wurde erfolgreich bestimmt'
    }
  }
}
