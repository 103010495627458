<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }}</v-card-title>
      <v-card-text>
        {{ cText.text }}
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            outlined
            block
            :run="open"
            @click="onClickClose"
        >
          {{ cText['btn.close'] }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgError',
  mixins: [ MxTranslatable ],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'text',
        'btn.close'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogError']
      },
      set (value) {
        this.$store.commit('dialogError', value)
      }
    }
  },
  methods: {
    onClickClose () {
      this.close()
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped>

</style>
