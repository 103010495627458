<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-thumb-down</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import {
  Entity
} from '@/constants'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbDislike',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cQuest () {
      if (this.cArea === Entity.QUEST) {
        return this.$store.getters['quests/selected/quest'] ?? null
      } else {
        return null
      }
    },
    cQuestState () { return this.quest?.state },
    cReaction () { return this.quest?.player_reaction },
    cShowBtn () {
      const currentPlayerId = this.$store.getters['players/current/id']
      return this.cBelongsToRoute &&
        this.cQuest !== null &&
        this.cQuestState === closed &&
        this.$store.getters['quests/selected/quest']?.player.id !== currentPlayerId &&
        this.$store.getters['quests/selected/quest']?.processing?.user_id !== currentPlayerId &&
        this.cReaction === null
    }
  },
  methods: {
    onClick () {
      if (this.cQuest !== null) {
        this.$http.$api.post(`/quests/${this.cQuest.id}/reactions`, {
          value: false
        })
          .then(this.$store.dispatch('quests/selected/update'))
      }
    }
  }
}
</script>

<style scoped>
</style>
