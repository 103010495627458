<template>
  <v-row dense>
    <v-col cols="12">
      <eins-text-field-password
          v-model="internalValue.password"
          :label="$t('components.AppConfirmPassword.fields.password.label')"
          allow-plain
          :rules="rules.password"
          show-icon
          error-count="5"
          @input="onInput"
          @keypress.enter.prevent
          @keyup.enter="onKeyupEnter"
      />
    </v-col>
    <v-col cols="12">
      <eins-text-field-password
          v-model="internalValue.password_confirmation"
          :label="$t('components.AppConfirmPassword.fields.passwordConfirmation.label')"
          show-icon
          allow-plain
          validate-on-blur
          :rules="rules.password_confirmation"
          @input="onInput"
          @keypress.enter.prevent
          @keyup.enter="onKeyupEnter"
      />
    </v-col>
  </v-row>
</template>

<script>
import EinsTextFieldPassword from '@/components/input/EinsTextFieldPassword'
import {
  hasMinLength,
  isNotEmpty
} from '@/utils/inputRules'
import { Password } from '@/constants'

const getEmptyItem = () => ({
  password: '',
  password_confirmation: ''
})

export default {
  name: 'EinsFormPasswordReset',
  components: {
    EinsTextFieldPassword
  },
  props: {
    value: {
      type: Object,
      default: getEmptyItem()
    }
  },
  data () {
    return {
      internalValue: getEmptyItem(),
      rules: {
        password: [
          isNotEmpty,
          hasMinLength(Password.MIN_LENGTH)
        ],
        password_confirmation: [
          val => val === this.internalValue.password || this.$t('components.AppConfirmPassword.hints.passwordFalse')
        ]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'setValue'
    }
  },
  methods: {
    onInput () {
      this.emitInput()
    },
    onKeyupEnter () {
      this.$emit('submit')
    },
    setValue () {
      this.internalValue.password = this.value?.password ?? ''
      this.internalValue.password_confirmation = this.value?.password_confirmation ?? ''
    },
    emitInput () {
      this.$emit('input', {
        ...this.internalValue
      })
    },
    passwordsMatch (val) {
      return this.internalValue.password === val
    }
  }
}
</script>

<style scoped>

</style>
