import {
  createListModule,
  createSelectedEntityModule,
  createRelatedEntityListModule
} from '@/plugins/store/factories'
import {
  ApplicationState,
  DealState,
  QuestState
} from '@/constants'

const basePath = '/groups'

export const groups = createListModule({
  basePath,
  baseEntity: 'groups',
  modules: {
    selected: createSelectedEntityModule({
      basePath,
      getterName: 'group',
      modules: {
        members: createRelatedEntityListModule({
          getParams: (getters) => ({
            group_id: getters['groups/selected/group'].id,
            no_distance: true
          }),
          getPath: () => '/players',
          baseEntity: 'players'
        }),
        applications: createRelatedEntityListModule({
          getParams: (getters) => ({
            no_distance: true,
            [ApplicationState.ACCEPTED]: false,
            [ApplicationState.CONFIRMED]: false
          }),
          getPath: (getters) => `/groups/${getters['groups/selected/group'].id}/applications`,
          baseEntity: 'applications'
        }),
        deals: createRelatedEntityListModule({
          getParams: (getters) => ({
            participant_group_id: getters['groups/selected/group'].id,
            state: DealState.OPEN,
            no_distance: true
          }),
          getPath: () => `/deals`,
          baseEntity: 'deals'
        }),
        dealsFinished: createRelatedEntityListModule({
          getParams: (getters) => ({
            participant_group_id: getters['groups/selected/group'].id,
            state: DealState.ENDED,
            no_distance: true
          }),
          getPath: () => `/deals`,
          baseEntity: 'deals'
        }),
        quests: createRelatedEntityListModule({
          getParams: (getters) => ({
            group_id: getters['groups/selected/group'].id,
            state: QuestState.OPEN,
            no_distance: true
          }),
          getPath: () => `/quests`,
          baseEntity: 'quests'
        }),
        questsFinished: createRelatedEntityListModule({
          getParams: (getters) => ({
            group_id: getters['groups/selected/group'].id,
            state: QuestState.CLOSED,
            no_distance: true
          }),
          getPath: () => `/quests`,
          baseEntity: 'quests'
        })
      }
    })
  }
})
