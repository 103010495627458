export default {
  caption: 'Benachrichtigungs-Feed',
  type: {
    fcm: {
      title: 'Push-Notifikation'
    },
    email: {
      title: 'Email-Notifikation'
    }
  },
  labels: {
    group: {
      applied: ' hat sich für die @.lower:(terms.group) beworben. Nimm die Bewerbung an oder lehne sie ab',
      joined: ' ist der @.lower:(terms.group) beigetreten',
      member: 'Du bist jetzt Mitglied dieser @.lower:(terms.group)',
      notMember: 'Du bist kein Mitglied diese @.lower:(terms.group)',
      admin: 'Du bist zu einem Administrator dieser @.lower:(terms.group) befördert worden',
      downMember: 'Du bist zu einem Mitglied dieser @.lower:(terms.group) degradiert worden'
    },
    deal: {
      applied: ' hat sich für die @.lower:(terms.deal) beworben',
      assigned: 'Dir wurde der @.lower:(terms.deal) zugewiesen und Du kannst ihn jetzt erhalten!',
      finished: ' beendete das @.lower:(terms.deal). Herzlichen Glückwunsch!',
      withdrawn: ' hat das @.lower:(terms.deal) abgelehnt. Wähle einen anderen Spieler',
      friend: ' ein Freund hat @.lower:(terms.deal) angeboten. prüfe es bitte',
      group: ' ein @.lower:(terms.group) hat den @.lower:(terms.deal). Schau es Dir an.'
    },
    quest: {
      applied: 'auf Deine @.lower:(terms.quest) beworben hat. Nehme die Bewerbung an oder lehne sie ab',
      assigned: 'Du wurdest ausgewählt. Du kannst die @.lower:(terms.quest) jetzt starten!',
      rejected: 'Du wurdest leider nicht ausgewählt. Schaue einfach nach der nächsten @.lower:(terms.quest)',
      started: ' hat die @.lower:(terms.quest) gestartet',
      finished: ' hat die @.lower:(terms.quest) erfüllt und wartet auf Deine Bestätigung.',
      approved: 'Congratulations! Du hast die @.lower:(terms.quest) gemeistert',
      friend: 'Ein Freund braucht Unterstützung für diese @.lower:(terms.quest)',
      group: 'Ein @.lower:(terms.group) benötigt Unterstützung für diese @.lower:(terms.quest)'
    },
    friendship: {
      newFriend: 'Ihr seid jetzt Freunde.',
      newFollower: 'Du hast einen neuen Follower.'
    }
  },
  actions: {
    start: 'Straten',
    finish: 'Abschließen',
    applicants: 'Bewerber',
    approve: 'Zugewiesen',
    accept: 'Getauscht',
    reject: 'Abgelehnt'
  },
  weight: {
    1: {
      title: 'All2',
      description: 'Erhalte alle Benachrichtigungen'
    },
    2: {
      title: 'Wichtig',
      description: 'Erhalte nur Benachrichtigungen die Dich betreffen'
    },
    3: {
      title: 'Keine',
      description: 'Keine Benachrichtigungen erhalten.'
    }
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen'
  }
}
