<template>
  <eins-input-toolbar-extension
      v-model="textFilter"
      class="mt-3"
      object-type="deal"
      :label="cLabel"
      :filter-options="cFilterOptions"
      :filter-value="cCurrentDealFilter"
      @input="onChangeTextFilter"
      @update:filter="onUpdateFilter"
  >
    <template #icon>
      <eins-icon-deals
          active
          width="26"
          class="mr-1"
      />
    </template>
  </eins-input-toolbar-extension>
</template>

<script>
import EinsIconDeals from '@/components/icons/EinsIconDeals'
import EinsInputToolbarExtension from '@/components/input/EinsInputToolbarExtension'
import {
  DealType,
  Filter
} from '@/constants'
import {
  arrayContentIsIdentical
} from '@/utils/object'

const DEAL_TYPES = [DealType.PRODUCT, DealType.SERVICE]

export default {
  name: 'ViewDealListToolbarExtension',
  components: {
    EinsInputToolbarExtension,
    EinsIconDeals
  },
  data: () => ({
    textFilter: ''
  }),
  computed: {
    cCurrentFilter () {
      return this.$store.getters['deals/filter']
    },
    cCurrentDealFilter () {
      return []
        .concat((this.cCurrentFilter.scopes ?? []).map(scope => `scope.${scope}`))
        .concat((this.cCurrentFilter.types ?? []).map(type => `type.${type}`))
    },
    cLabel () {
      return this.$t('terms.deals')
    },
    cFilterOptions () {
      const result = []

      for (const type of DEAL_TYPES) {
        result.push({
          title: this.$t(`deal.type.${type}`),
          value: `type.${type}`
        })
      }
      for (const scope of Filter.SCOPES) {
        result.push({
          title: this.$t(`common.filter.scope.${scope}.title`),
          value: `scope.${scope}`,
          invertedAll: true
        })
      }

      for (const option of result) {
        switch (option.value) {
          case 'scope.contacts':
            option.icon = 'mdi-account-switch-outline'
            break
          case 'scope.groups':
            option.icon = 'mdi-google-circles-communities'
            break
          case 'type.product':
            option.icon = 'mdi-basket'
            break
          case 'type.service':
            option.icon = 'mdi-face-agent'
            break
        }
      }

      return result
    }
  },
  mounted () {
    this.textFilter = this.$store.getters['deals/search']
  },
  methods: {
    onChangeTextFilter () {
      this.$store.dispatch('deals/searchDelayed', this.textFilter)
    },
    onUpdateFilter (value) {
      if (!arrayContentIsIdentical(value, this.cCurrentDealFilter)) {
        let scopes = value
          .filter(option => option.includes('scope.'))
          .map(option => option.replace('scope.', ''))
        scopes = scopes.length > 0 ? scopes : null

        let types = value
          .filter(option => option.includes('type.'))
          .map(option => option.replace('type.', ''))
        types = types.length > 0 ? types : null
        this.setFilter({ scopes, types })
      }
    },
    setFilter (filter) {
      this.$store.dispatch('deals/filter', filter)
    }
  }
}
</script>

<style scoped>
</style>
