// @vue/component
export const MxMenuIcon = {
  props: {
    active: {
      type: [Boolean, String],
      default: false
    },
    notification: {
      type: [Boolean, Number],
      default: false
    }
  }
}
