<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-close-box-outline"
      @click="onClick"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'
import {
  DealState
} from '@/constants'

export default {
  name: 'EinsStarmenuAbRejectDeal',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cShowBtn () {
      const currentPlayerId = this.$store.getters['players/current/id']

      return this.cBelongsToRoute &&
        this.cDeal?.state === DealState.ASSIGNED &&
        this.cDeal?.assignments?.some(assignment => assignment.user.id === currentPlayerId)
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogRejectDeal', {
        deal: this.$store.getters['deals/selected/deal']
      })
        .finally(() => this.$store.dispatch('deals/selected/update'))
    }
  }
}
</script>

<style scoped>

</style>
