import colors from 'vuetify/es5/util/colors'

import {
  colorEinsDeals,
  colorEinsDealsOff,
  colorEinsPrimary,
  colorEinsQuests,
  colorEinsQuestsOff,
  colorEinsPlayers,
  colorEinsPlayersOff,
  colorEinsGroups,
  colorEinsGroupsOff,
  colorEinsBackground
} from '@/plugins/themes/constants'

export const dark = {
  primary: colorEinsPrimary,
  secondary: colors.shades.black,
  background: colorEinsBackground,
  accent: colors.indigo.base,
  error: colors.pink.base,
  warning: colors.orange.base,
  info: colorEinsPrimary,
  success: colors.lightGreen.base,
  quest: colorEinsQuests,
  questoff: colorEinsQuestsOff,
  deal: colorEinsDeals,
  dealoff: colorEinsDealsOff,
  group: colorEinsGroups,
  groupoff: colorEinsGroupsOff,
  player: colorEinsPlayers,
  playeroff: colorEinsPlayersOff
}
