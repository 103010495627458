<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-account"
      :text="cText.text"
  >
    <template #icon>
      <v-icon
          left
          color="primary"
      >mdi-database</v-icon>
      <div v-if="cOwnProfile">
        <v-icon
            left
            color="white"
        >mdi-account-box</v-icon>
      </div>
      <div v-if="!cOwnProfile">
        <v-icon
            left
            color="primary"
        >mdi-account</v-icon>
      </div>
    </template>
  </eins-starmenu-btn>
</template>

<script>
import { RegRoutePlayer } from '@/utils/regex'
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbPlayerData',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute &&
        // only on player routes
        RegRoutePlayer.test(this.$route.name) &&
      !this.cIsDataPage
    },
    cOnListRoute () {
      // Current route is the player list
      return this.$route.name === 'player-data'
    },
    cTarget () {
      const playerId = this.$route.params.id ?? this.$store.getters['players/current/id']

      return this.cShowBtn ? `/players/${playerId}#details` : ''
    },
    cOwnProfile () {
      return this.$route.params.id === this.$store.getters['players/current/id']
    },
    cIsDataPage () {
      return this.$route.hash === '#details'
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
