<template>
  <eins-input-toolbar-extension
      v-model="textFilter"
      class="mt-3"
      object-type="group"
      :label="cLabel"
      :filter-options="cFilterOptions"
      :filter-value="cCurrentMetaTypeFilter"
      @input="onChangeTextFilter"
      @update:filter="onUpdateFilter"
  >
    <template #icon>
      <eins-icon-groups
          active
          width="26"
          class="mr-1"
      />
    </template>
  </eins-input-toolbar-extension>
</template>

<script>
import EinsInputToolbarExtension from '@/components/input/EinsInputToolbarExtension'
import EinsIconGroups from '@/components/icons/EinsIconGroups'
import { arrayContentIsIdentical } from '@/utils/object'

const GROUP_TYPE_ICON = {
  NGO: 'mdi-flag',
  COM: 'mdi-store',
  FREE: 'mdi-lightbulb-on',
  PUBLIC: 'mdi-bank'
}

export default {
  name: 'ViewGroupListToolbarExtension',
  components: {
    EinsInputToolbarExtension,
    EinsIconGroups
  },
  data: () => ({
    textFilter: ''
  }),
  computed: {
    cCurrentFilter () {
      return this.$store.getters['groups/filter']
    },
    cCurrentMetaTypeFilter () {
      return this.cCurrentFilter.meta_type ?? []
    },
    cLabel () {
      return this.$t('terms.group')
    },
    cFilterOptions () {
      return this.$store.getters['groupTypes/list']
        .map(type => type.meta_type_key)
        .filter((key, idx, arr) => idx === arr.indexOf(key))
        .map(key => ({
          title: this.$t(`groupTypes.metaType.${key}.label`),
          value: key,
          icon: GROUP_TYPE_ICON[key]
        }))
    }
  },
  mounted () {
    this.textFilter = this.$store.getters['groups/search']
  },
  methods: {
    onChangeTextFilter () {
      this.$store.dispatch('groups/searchDelayed', this.textFilter)
    },
    onUpdateFilter (value) {
      if (!arrayContentIsIdentical(value, this.cCurrentMetaTypeFilter)) {
        this.setFilter('meta_type', value)
      }
    },
    setFilter (key, value) {
      if (Array.isArray(value) && value.length > 0) {
        this.$store.dispatch('groups/filter', { [key]: [...value] })
      } else {
        this.$store.dispatch('groups/filter', { [key]: null })
      }
    }
  }
}
</script>

<style scoped>

</style>
