import { FriendshipState } from '../../../constants'

export const EinsStarmenuAbApply = {
  text: 'Bewerben'
}

export const EinsStarmenuAbChangeGroupOwner = {
  text: 'Transfer ownership'
}

export const EinsStarmenuAbCopy = {
  text: 'Daten kopieren'
}

export const EinsStarmenuAbGetDeal = {
  text: 'Bekomme den @.lower:(terms.deal) '
}

export const EinsStarmenuAbAddContent = {
  text: 'Inhalt',
  addProduct: 'hinzufügen @.lower:(terms.product)',
  addService: 'hinzufügen @.lower:(terms.service)',
  addCommon: 'hinzufügen @.lower:(terms.common) @.lower:(terms.quest)',
  addGreen: 'hinzufügen @.lower:(terms.future) @.lower:(terms.quest)',
  addPrivate: 'hinzufügen @.lower:(terms.private) @.lower:(terms.quest)'
}

export const EinsStarmenuAbJoinGroup = {
  text: 'Trette dem @.lower:(terms.group) bei'
}

export const EinsStarmenuAbLeaveGroup = {
  text: 'Beende die Mitgliedschaft '
}

export const EinsStarmenuAbDenounce = {
  text: 'Melden'
}
export const EinsStarmenuAbHonorDeal = {
  text: '@.lower:(terms.deal) Link'
}
export const EinsStarmenuAbCloseAccount = {
  text: 'Löschen der @.lower:(terms.player) Daten'
}

export const EinsStarmenuAbFoundGroup = {
  text: 'Erstelle ein @.lower:(terms.group) '
}

export const EinsStarmenuAbFollow = {
  text: {
    [FriendshipState.NONE]: 'hinzufügen als idol',
    [FriendshipState.IDOL]: 'Entferne das idol',
    [FriendshipState.FAN]: 'Werdet Freunde',
    [FriendshipState.FRIEND]: 'Zurückstufen zu Followern',
    [FriendshipState.ME]: 'keine'
  }
}

export const EinsStarmenuAbCreateDealProduct = {
  text: 'Erstelle @.lower:(terms.product)'
}

export const EinsStarmenuAbCreateDealService = {
  text: 'Erstelle @.lower:(terms.service)'
}

export const EinsStarmenuAbCreateQuestPrivate = {
  text: 'Erstelle eine @.lower:(terms.pirvate) @.lower:(terms.quest) '
}

export const EinsStarmenuAbCreateQuestCommon = {
  text: 'Erstelle eine @.lower:(terms.common) @.lower:(terms.quest) '
}

export const EinsStarmenuAbCreateQuestFuture = {
  text: 'Erstelle eine @.lower:(terms.future) @.lower:(terms.quest) '
}

export const EinsStarmenuAbShare = {
  text: 'teile'
}

export const EinsStarmenuAbDislike = {
  text: 'Abneigung'
}

export const EinsStarmenuAbStartQuest = {
  text: 'Starte die @.lower:(terms.quest) '
}

export const EinsStarmenuAbMainpage = {
  text: 'Hauptseite'
}

export const EinsStarmenuAbFinishQuest = {
  text: 'Erfülle @.lower:(terms.quest) '
}

export const EinsStarmenuAbApproveQuest = {
  text: 'Bewillige @.lower:(terms.quest) '
}

export const EinsStarmenuAbExchangeDeal = {
  text: 'Tausche @.lower:(terms.deal) '
}

export const EinsStarmenuAbRejectDeal = {
  text: 'Lehne den @.lower:(terms.deal) ab'
}

export const EinsStarmenuAbCancelDeal = {
  text: 'Breche den @.lower:(terms.deal) ab'
}

export const EinsStarmenuAbCancelQuest = {
  text: 'Breche die @.lower:(terms.quest) ab'
}

export const EinsStarmenuAbQrCode = {
  text: 'QR-Code'
}

export const EinsStarmenuAbDeleteGroup = {
  text: 'Schließe und lösche das @.lower:(terms.group)'
}
