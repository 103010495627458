export default {
  priv: {
    title: 'Initiative',
    description: 'Free space for private projects, initiatives, friends, families and neighborhoods. No legal form',
    ServicePackage: 'no additional requirements or verification needed',
    price: ''
  },
  ngo: {
    title: '1NGO Association',
    description: '@.lower:(terms.group) for clubs, sports clubs, NGOs, aid organizations and foundations. Free of charge, legal entity is a registered organization.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1NGO) including the following service-package',
    price: ''
  },
  edu: {
    title: '1EDU Education',
    description: '@.lower:(terms.group) for schools, kindergartens, educational institutions, universities and colleges. Chargeable. Legal entity is a registered educational institution.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1EDU) including the following service-package',
    price: ''
  },
  gov: {
    title: '1GOV Government',
    description: '@.lower:(terms.group) for public administration institutions, ministries, cities, districts, federal states, states and public law institutions. Chargeable. Legal entity is a public body',
    ServicePackage: 'This kind of @.lower:(terms.group) (1GOV) including the following service-package',
    price: ''
  },
  com_a: {
    title: '1COM-A Startup',
    description: '@.lower:(terms.group) for startups, small companies up to ~ 20 employees, self-employed. Chargeable. Legal entity is a company.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1COM-A) including the following service-package',
    price: ''
  },
  com_b: {
    title: '1COM-B Business',
    description: '@.lower:(terms.group) for medium-sized companies with 50 or more employees. Chargeable. Legal entity is a company.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1COM-B) including the following service-package',
    price: ''
  },
  com_c: {
    title: '1COM-C Large Business',
    description: '@.lower:(terms.group) for large companies. Chargeable. Legal entity is a company.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1COM-C) including the following service-package',
    price: ''
  },
  cus: {
    title: '1CUS Customer',
    description: '@.lower:(terms.group) for companies that want to offer their customers a separate field in order to publish offers from the company. Chargeable. Legal entity is a company.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1CUS) including the following service-package',
    price: ''
  },
  inf: {
    title: '1INF Information',
    description: '@.lower:(terms.group) for companies that want to provide an extra field in order to send information of all kinds to customers, suppliers, employees or various other players. Chargeable. Legal entity is a company.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1INF) including the following service-package',
    price: ''
  },
  b2b: {
    title: '1B2B Business',
    description: '@.lower:(terms.group) for companies that want to offer their business partners an extra field in order to handle business 1APP activities.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1B2B) including the following service-package',
    price: '9999€'
  },
  metaType: {
    NGO: {
      label: 'NGO'
    },
    COM: {
      label: 'COM'
    },
    FREE: {
      label: 'FREE'
    },
    PUBLIC: {
      label: 'PUBLIC'
    }
  }
}
