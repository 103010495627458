<template>
  <eins-layout-list
      #default="{ item }"
      :items="chats"
  >
    <eins-chat-list-item
        :chat="item"
    />
  </eins-layout-list>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList' // this ends here, no further nesting
import EinsChatListItem from '@/components/chat/EinsChatListItem'

export default {
  name: 'EinsChatList',
  components: {
    EinsChatListItem,
    EinsLayoutList
  },
  props: {
    chats: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>
</style>
