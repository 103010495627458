<template>
  <v-card>
    <v-row
        class="quest flex-nowrap"
        no-gutters
        @click="onClickContributor"
    >
      <v-col
          cols="1"
      >
        <eins-icon-friendship
            :state="cContributorFriendshipState"
            width="24"
        />
        <div style="display:none">
          <eins-icon-medal
              :type="cMedal"
          />
        </div>
      </v-col>
      <v-col
          cols="1"
      >
        <eins-api-image
            v-if="cUserContributor.title_picture_feed"
            #default="{ src }"
            :src="cUserContributor.title_picture_feed"
        >
          <v-img
              #placeholder
              aspect-ratio="1"
              :src="src"
              width="4em"
          >
            <v-skeleton-loader
                tile
                type="image"
                height="100%"
            />
          </v-img>
        </eins-api-image>
        <div
            v-else
            style="aspect-ratio: 1; height: 100%;"
        ></div>
      </v-col>
      <v-col
          cols="10"
          style="line-height: 1.25; overflow-x: hidden;"
      >
        <div class="pl-1 text-truncate">
          <strong>{{ cTitle }}</strong>
        </div>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        class="flex-nowrap image-overlay"
    >
      <v-col
          class="overflow-hidden flex-grow-1 flex-shrink-0"
      >
        <eins-api-image
            #default="{ src }"
            :src="cPicture"
        >
          <v-img
              aspect-ratio="1.15"
              :src="src"
              width="100%"
              @click="onClickImage"
          >
            <template #placeholder>
              <v-skeleton-loader
                  tile
                  type="image"
                  height="100%"
              />
            </template>
            <eins-layout-image-overlay
                left
            >
              <template #title>
                <div
                    class="pa-0 ma-0 "
                    style="font-size:14px"
                >
                  {{ cDateDone }}
                </div>
              </template>
            </eins-layout-image-overlay>
            <v-icon
                v-if="cAllowReacting"
                style="position: absolute; bottom: 50%; left: 50%; transform: translate(-50%, -50%);"
                color="red"
                large
                :loading="isLoading"
                @click.stop="onClickLike"
            >{{ cLikeIcon }}</v-icon>
          </v-img>
        </eins-api-image>
      </v-col>
      <v-col
          class="quest text-center items-center flex-grow-0 flex-shrink-1"
          style="min-width:50px"
      >
        <v-icon
            class="mt-n12"
            size="24px"
        >mdi-crown</v-icon>
        <eins-icon-medal
            :type="cMedal"
            class="mx-auto"
            width="30px"
        />
        <eins-icon-quest-type
            :type="quest.type"
            width="22px"
        />
        <div v-if="cLikeCount">
          <v-icon size="22px">mdi-heart</v-icon>
          {{ cLikeCount }}
        </div>
        <div v-if="cDislikeCount">
          <v-icon size="22px">mdi-thumb-down</v-icon>
          {{ cDislikeCount }}
        </div>
        <template v-if="cIsGroupQuest">
          <div>
            <v-icon>mdi-flag</v-icon>
          </div>
          <div>
            <eins-icon-group-member
                class="mx-auto"
                :state="cUserMemberState"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <eins-icon-friendship
                :state="cCreatorFriendshipState"
                class="mt-2 mx-auto"
                width="24"
            />
          </div>
        </template>
        <div style="max-width:60px;padding-left:5px;margin: 15px auto 5px auto">
          <eins-time-stars
              v-model="cTimeBonus"
              wrap
              stars="60"
              bonus-caption
              only-selected
              per-row="2"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsIconGroupMember from '@/components/icons/EinsIconGroupMember'
import EinsIconMedal from '@/components/icons/EinsIconMedal'
import EinsIconQuestType from '@/components/icons/EinsIconQuestType'
import EinsTimeStars from '@/components/time/EinsTimeStars'
import EinsLayoutImageOverlay from '@/components/layout/EinsLayoutImageOverlay'
import EinsApiImage from '@/components/EinsApiImage'
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'

export default {
  name: 'EinsTimelineListItem',
  components: {
    EinsIconFriendship,
    EinsIconGroupMember,
    EinsIconMedal,
    EinsTimeStars,
    EinsIconQuestType,
    EinsApiImage,
    EinsLayoutImageOverlay
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  props: {
    quest: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    like: null,
    isLoading: false
  }),
  computed: {
    cDateDone () {
      return this.getDate(this.quest.updated_at)
    },
    cTitle () {
      return this.quest.title
    },
    cUserMemberState () {
      return this.quest.group.membership_state
    },
    cIsGroupQuest () {
      return this.quest?.group
    },
    cHasLike () {
      return this.like !== null
        ? this.like
        : this.quest?.user_reaction?.value > 0
    },
    cLikeIcon () {
      return this.cHasLike ? 'mdi-heart' : 'mdi-heart-outline'
    },
    cCurrentPlayer () { return this.$store.getters['players/current/id'] },
    cLikeCount () { return this.getLike(this.quest?.positive_reactions_count) ? this.getLike(this.quest?.positive_reactions_count) : false },
    cDislikeCount () { return this.getDislike(this.quest?.negative_reactions_count) ? this.getDislike(this.quest?.negative_reactions_count) : false },
    cUserContributor () {
      return this.quest?.processing?.assignee
    },
    cUserCreator () {
      return this.quest?.player ?? ''
    },
    cGroupCreator () {
      return this.quest?.group ?? ''
    },
    cCreator () {
      const grpQuest = this.cIsGroupQuest
      const creator = grpQuest ? this.cGroupCreator : this.cUserCreator
      const result = { ...creator }

      if (result.title) {
        result.name = result.title
      }
      result.appUrl = `/${grpQuest ? 'groups' : 'players'}/${creator.id}`
      result.imageUrl = grpQuest ? this.cGroupCreator?.title_picture_full : this.cUserCreator?.title_picture_full

      return result
    },

    cCreatorFriendshipState () { return this.quest?.player?.friendship_state },

    cContributorFriendshipState () { return this.quest?.processing?.assignee?.friendship_state },

    cMedal () {
      return this.quest?.type
    },
    cGetPicture () {
      if (this.quest) {
        if (
          !this.quest.processing.title_picture_full
        ) {
          return this.quest.title_picture_full
        } else {
          return this.quest.processing.title_picture_full
        }
      } else {
        return null
      }
    },
    cPicture () { return this.cGetPicture ?? '' },
    cTimeBonus () { return this.quest?.value ?? 0 },
    cAllowReacting () {
      return this.quest?.processing?.user_id !== this.cCurrentPlayer &&
          this.quest?.player?.id !== this.cCurrentPlayer
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.clearCache()
      this.loadPlayer(to.params.id)
    }
    next()
  },
  methods: {
    onClickImage () {
      this.$router.push({
        name: 'quest-detail',
        params: {
          id: this.quest.id
        }
      })
    },
    onClickContributor () {
      if (
        !this.cUserContributor.has_blocked_authenticated_user &&
        !this.cUserContributor.is_blocked_by_authenticated_user
      ) {
        this.$router.push({
          name: 'player-detail',
          params: {
            id: this.cUserContributor.id
          }
        })
      }
    },
    onClickCreator () {
      this.$router.push(this.cCreator.appUrl)
    },
    /*  onClickToggle (e) {
      this.toggleLike
      // setTimeout(() => e.target.blur(), 500) // remove ripple effect
    },
    */
    getDate (timeline) {
      let date = new Date(timeline)
      let getMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      let day = date.getUTCDate()
      let month = date.getUTCMonth()
      let year = date.getUTCFullYear()
      return day + '.' + getMonth[month] + '.' + year
    },
    toggleLike () {
      this.like = this.cHasLike ? null : true
    },
    getLike (like) {
      if (like > 0) {
        return like
      }
    },
    getDislike (dislike) {
      if (dislike > 0) {
        return dislike
      }
    },
    async onClickLike () {
      if (this.quest !== null) {
        this.isLoading = true
        try {
          if (this.cHasLike) {
            await this.$http.$api.delete(
              `/quests/${this.quest?.id}/reactions/${this.$store.getters['players/current/id']}`,
              { validateStatus: (status) => status === 410 }
            )
          } else {
            await this.$http.$api.post(`/quests/${this.quest?.id}/reactions`, {
              value: true
            })
          }
          this.toggleLike()
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
.v-card {
  background-color: transparent !important;
  border:2px solid var(--v-quest-base);
}
::v-deep {
  .v-image__placeholder > .v-skeleton-loader > .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>
