import colors from 'vuetify/es5/util/colors'

import { dark } from '@/plugins/themes'

const light = {
  ...dark,
  secondary: colors.shades.white
}

export { light }
