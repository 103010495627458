import { RegUuidString } from '@/utils/regex'

const ViewDealList = () => import(/* webpackChunkName: "deal-list" */ '@/views/deal/ViewDealList')
const ViewDealListToolbarExtension = () => import (/* webpackChunkName: "deal-list" */ '@/views/deal/ViewDealListToolbarExtension')
const ViewDealDetail = () => import(/* webpackChunkName: "deal-detail" */ '@/views/deal/ViewDealDetail')
const ViewDealApplications = () => import(/* webpackChunkName: "deal-applications" */ '@/views/deal/ViewDealApplications')
const ViewDealCreate = () => import(/* webpackChunkName: "deal-create" */ '@/views/deal/ViewDealCreate')
const ViewDealEdit = () => import(/* webpackChunkName: "deal-edit" */ '@/views/deal/ViewDealEdit')

export const deals = {
  path: '/deals',
  name: 'deal-list',
  components: {
    default: ViewDealList,
    toolbarExtension: ViewDealListToolbarExtension
  },
  meta: {
    hasToolbarExtension: true,
    hasMapButton: false,
    starmenuButtons: [
      'EinsStarmenuDpbMyDeals',
      'EinsStarmenuAbCreateDealProduct',
      'EinsStarmenuAbCreateDealService'
    ]
  },
  children: [{
    path: `:id(${RegUuidString})`,
    name: 'deal-detail',
    component: ViewDealDetail,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuAbGetDeal',
        'EinsStarmenuAbCancelDeal',
        'EinsStarmenuAbExchangeDeal',
        'EinsStarmenuAbRejectDeal',
        'EinsStarmenuAbHonorDeal',
        'EinsStarmenuAbShare',
        'EinsStarmenuAbDenounce',
        'EinsStarmenuAbApply',
        'EinsStarmenuEbEdit',
        'EinsStarmenuAbCopy',
        'EinsStarmenuDpbDirectMessage',
        'EinsStarmenuDpbApplications',
        'EinsStarmenuAbQrCode'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/applications`,
    name: 'deal-applications',
    component: ViewDealApplications,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuEbEdit'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/edit`,
    name: 'deal-edit',
    component: ViewDealEdit,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuDpbDetails'
      ]
    }
  }, {
    path: 'create',
    name: 'deal-create',
    components: {
      default: ViewDealCreate
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
      ]
    }
  }]
}
