<template>
  <v-icon
      v-bind="$attrs"
  >{{ cIcon }}</v-icon>
</template>

<script>
export default {
  name: 'EinsIconQuestType',
  props: {
    type: {
      type: [String, Number],
      default: 'private'
    }
  },
  computed: {
    cIcon () {
      switch (this.type) {
        case 'common':
          return 'mdi-map-marker-star'
        case 'nature':
          return 'mdi-map-marker-up'
        case 'private':
        default:
          return 'mdi-map-marker-left'
      }
    }
  }
}
</script>

<style scoped>

</style>
