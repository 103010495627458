import { initializeApp } from 'firebase/app'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect
} from 'firebase/auth'
import { getMessaging } from 'firebase/messaging'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

const FIREBASE_AUTH = 'auth'
const FIREBASE_DATABASE = 'database'
const FIREBASE_FIRESTORE = 'firestore'
const FIREBASE_MESSAGING = 'messaging'
const FIREBASE_FUNCTIONS = 'functions'
const FIREBASE_FEATURES = [
  FIREBASE_AUTH,
  FIREBASE_DATABASE,
  FIREBASE_FIRESTORE,
  FIREBASE_MESSAGING,
  FIREBASE_FUNCTIONS
]

export class VueFirebase {
  static #installed = false;
  static #instances = [];

  #readyCb = [];
  #options;

  #firebaseApp;
  #messaging = null;
  #auth = null;

  #authProviders = new Map();

  constructor () {
    VueFirebase.#instances.push(this)
  }

  static get AUTH () {
    return FIREBASE_AUTH
  }

  static get DATABASE () {
    return FIREBASE_DATABASE
  }

  static get FIRESTORE () {
    return FIREBASE_FIRESTORE
  }

  static get MESSAGING () {
    return FIREBASE_MESSAGING
  }

  static get FUNCTIONS () {
    return FIREBASE_FUNCTIONS
  }

  static install (Vue, options = {}) {
    Vue.mixin({
      beforeCreate () {
        if (this.$options.firebase instanceof VueFirebase) {
          this._firebaseRoot = this
          this._firebase = this.$options.firebase
          this._firebase.init(this, options)
        } else {
          this._firebaseRoot =
            (this.$parent && this.$parent._firebaseRoot) || this
        }
      }
    })
    Object.defineProperty(Vue.prototype, '$firebase', {
      get () {
        return this._firebaseRoot._firebase
      }
    })

    const promises = []
    for (const module of FIREBASE_FEATURES) {
      if (options.features?.includes(module)) {
        switch (module) {
          case FIREBASE_MESSAGING:
            promises.push(require('firebase/messaging'))
            break
          case FIREBASE_AUTH:
            promises.push(require('firebase/auth'))
            break
        }
      }
    }
    Promise.all(promises)
      .then(() => {
        this.#installed = true
        for (const instance of this.#instances) {
          for (const cb of instance.#readyCb) {
            cb()
          }
        }
      })
      .catch((err) => {
        throw err
      })
  }

  init (vm, options) {
    this.#options = options
    const firebaseOptions = options?.firebaseOptions ?? {}
    this.#firebaseApp = initializeApp(firebaseOptions, `Vue-app${vm.uuid}`)
    this.setup(options?.setup ?? {})
  }

  setup (options) {
    this.setupAuth(options?.auth ?? {})
  }
  setupAuth (options) {
    for (const provider in options) {
      const providerSettings = options?.[provider] ?? {}
      let instance
      switch (provider) {
        case 'google':
          instance = this.setupAuthGoogle(providerSettings)
          break
        default:
          instance = this.setupAuthGeneric(provider, options)
      }

      this.#authProviders.set(provider, instance)
    }
  }
  setupAuthGoogle (options) {
    const provider = new GoogleAuthProvider()

    const scopes = options?.scopes ?? []
    for (const scope of scopes) {
      provider.addScope(scope)
    }
    const parameters = options?.parameters ?? {}
    provider.setCustomParameters(parameters)

    return provider
  }
  setupAuthGeneric (name, options) {
    const provider = new OAuthProvider(options?.id ?? `${name}.com`)

    const scopes = options?.scopes ?? []
    for (const scope of scopes) {
      provider.addScope(scope)
    }
    const parameters = options?.parameters ?? {}
    provider.setCustomParameters(parameters)

    return provider
  }

  onReady (cb) {
    if (typeof cb === 'function') {
      if (!this.#readyCb.includes(cb)) {
        this.#readyCb.push(cb)
      }

      if (VueFirebase.#installed) {
        cb()
      }
    }
  }

  async login (provider, popup = false) {
    const providerInstance = this.#authProviders.get(provider)

    if (popup) {
      return signInWithPopup(this.#auth, providerInstance)
    } else {
      return signInWithRedirect(this.#auth, providerInstance)
    }
  }
  getLoginProviderNameById (providerId) {
    for (const [name, provider] of this.#authProviders.entries()) {
      if (provider.providerId === providerId) {
        return name
      }
    }
  }

  get app () {
    return this.#firebaseApp ? this.#firebaseApp : null
  }

  get auth () {
    if (this.#auth) {
      return this.#auth
    } else if (
      this.app &&
      this.#options?.features?.includes(VueFirebase.AUTH)
    ) {
      const auth = getAuth(this.#firebaseApp)
      this.#auth = auth
      return auth
    } else {
      return null
    }
  }

  get database () {
    if (this.app && this.#options?.features?.includes(VueFirebase.DATABASE)) {
      return getDatabase(this.#firebaseApp)
    } else {
      return null
    }
  }

  get firestore () {
    if (this.app && this.#options?.features?.includes(VueFirebase.FIRESTORE)) {
      return getFirestore(this.#firebaseApp)
    } else {
      return null
    }
  }

  get messaging () {
    if (this.#messaging) {
      return this.#messaging
    } else if (
      this.app &&
      this.#options?.features?.includes(VueFirebase.MESSAGING)
    ) {
      const messaging = getMessaging(this.#firebaseApp)
      this.#messaging = messaging
      return messaging
    } else {
      return null
    }
  }

  get functions () {
    if (this.app && this.#options?.features?.includes(VueFirebase.FUNCTIONS)) {
      return getFunctions(this.#firebaseApp)
    } else {
      return null
    }
  }
}
