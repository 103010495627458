const ViewChatList = () => import(/* webpackChunkName: "conversations" */ '@/views/chat/ViewChatList')
const ViewChatListToolbarExtension = () => import(/* webpackChunkName: "conversations" */ '@/views/chat/ViewChatListToolbarExtension')
const ViewEmptyView = () => import('@/views/ViewEmptyView')
const ViewChatConversation = () => import(/* webpackChunkName: "conversations" */ '@/views/chat/ViewChatConversation')

export const conversations = {
  path: '/conversations',
  name: 'conversation-list',
  components: {
    default: ViewChatList,
    toolbarExtension: ViewChatListToolbarExtension
  },
  meta: {
    hasToolbarExtension: true,
    hasMapButton: false
  },
  children: [{
    path: ':id',
    name: 'conversation-details',
    component: ViewChatConversation,
    meta: {
      hasToolbarExtension: true,
      hasMapButton: false
    }
  }, {
    path: 'create',
    name: 'conversation-create',
    component: ViewEmptyView
  }]
}
