export const objectHasOwnProperty = (object, key) => Object.prototype.hasOwnProperty.call(object, key)

/**
 * Compares two arrays of **_primitives_** if they contain the same values, ignoring index.
 *  No deep-comparison of sub-arrays/objects!
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export const arrayContentIsIdentical = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  return arr1.every(el => arr2.includes(el)) &&
      arr2.every(el => arr1.includes(el))
}
