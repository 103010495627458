<template>
  <v-dialog
      :persistent="loading"
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-card class="edit-dialog-content">
      <v-card-title>
        {{ cDialogTitle }}
      </v-card-title>
      <v-expand-transition>
        <v-card-text v-if="!success">
          <v-form
              ref="form"
              v-model="form.valid"
          >
            <v-text-field
                v-model="form.data.name"
                :label="cLabels.name"
                :rules="cRules.name"
            />
            <v-textarea
                v-model="form.data.description"
                :counter="descriptionMaxLength"
                :label="cLabels.description"
                :placeholder="cLabels.descriptionPlaceholder"
                :rules="cRules.description"
            />
          </v-form>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          text
          tile
          type="success"
          :value="success"
      >
        <div><strong>{{ cSuccessMessage.title }}</strong></div>
        <div>
          {{ cSuccessMessage.text }}
        </div>
      </v-alert>
      <v-card-actions class="flex-column">
        <v-expand-transition
            v-for="action in cActions"
            :key="action.key"
        >
          <v-btn
              v-bind="action.btn"
              block
              @click="action.onClick"
          >{{ action.label }}</v-btn>
        </v-expand-transition>
        <eins-btn-timed
            v-if="success"
            block
            outlined
            @click="onClickClose"
        >
          <i18n path="player.update.talents.actions.close" />
        </eins-btn-timed>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

export default {
  name: 'EinsPlayerDataTalentDialog',
  components: { EinsBtnTimed },
  props: {
    talentId: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    descriptionMaxLength: 150,
    success: false,
    internalValue: false,
    loading: false,
    error: false,
    form: {
      valid: '',
      data: {
        name: '',
        description: ''
      }
    }
  }),
  computed: {
    cActions () {
      const result = []

      if (!this.success) {
        if (this.cCreateNewTalent) {
          result.push({
            key: 'create',
            onClick: () => this.onClickCreate(),
            label: this.$t('player.update.talents.actions.create'),
            btn: {
              color: 'primary',
              disabled: !this.form.valid,
              loading: this.loading
            }
          })
        } else {
          result.push({
            key: 'update',
            onClick: () => this.onClickUpdate(),
            label: this.$t('player.update.talents.actions.update'),
            btn: {
              color: 'primary',
              disabled: this.loading
            }
          })
        }

        result.push({
          key: 'cancel',
          onClick: () => this.onClickCancel(),
          label: this.$t('player.update.talents.actions.cancel'),
          btn: {
            outlined: true
          }
        })
      }

      return result
    },
    cLabels () {
      return {
        name: this.$t('player.update.talents.form.name.label'),
        description: this.$t('player.update.talents.form.description.label'),
        descriptionPlaceholder: this.$t('player.update.talents.form.description.placeholder')
      }
    },
    cRules () {
      return {
        name: [
          value => !!value ||
              this.$t('player.update.talents.form.name.required'),
          value => !value.trim() ||
              value.trim().length > 2 ||
              this.$t('player.update.talents.form.name.min')
        ],
        description: [
          value => value.trim().length <= this.descriptionMaxLength ||
              this.$t('player.update.talents.form.description.max')
        ]
      }
    },
    cDialogTitle () {
      return this.cCreateNewTalent
        ? this.$t('player.update.talents.dialogTitleCreate')
        : this.$t('player.update.talents.dialogTitleEdit', this.cSelectedTalent)
    },
    cCreateNewTalent () {
      return !this.cSelectedTalent?.id
    },
    cTalents () {
      return this.$store.getters['players/current/talents']
    },
    cSelectedTalent () {
      return this.cTalents.find(talent => this.talentId === talent.id) ?? null
    },
    cSuccessMessage () {
      return {
        title: this.$t('player.update.talents.successMessage.title'),
        text: this.cCreateNewTalent
          ? this.$t('player.update.talents.successMessage.textCreated', this.form.data)
          : this.$t('player.update.talents.successMessage.textUpdated', this.form.data)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    },
    internalValue: {
      immediate: true,
      handler: 'onChangeInternalValue'
    }
  },
  methods: {
    onChangeInternalValue (value, oldValue) {
      if (value !== oldValue) {
        this.reset()
      }
    },
    onChangeValue (value, oldValue) {
      if (value !== oldValue) {
        this.internalValue = value
        if (value) {
          this.onOpen()
        }
      }
    },
    onInputDialog (value) {
      this.updateModel(value)
    },
    onOpen () {
      this.reset()
    },
    onClickCreate () {
      this.createTalent()
    },
    onClickUpdate () {
      this.editTalent()
    },
    onClickCancel () {
      this.close()
    },
    onClickClose () {
      this.close()
    },
    reset () {
      this.loading = false
      this.resetData()
      this.resetFormValidation()
      this.success = false
      this.error = false
    },
    resetData () {
      this.form.data.name = this.cSelectedTalent?.name ?? ''
      this.form.data.description = this.cSelectedTalent?.description ?? ''
    },
    resetFormValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close () {
      this.updateModel(false)
    },
    updatePlayerData () {
      return this.$store.dispatch('players/current/update')
    },
    updateModel (value) {
      this.internalValue = value
      this.$emit('input', this.internalValue)
    },
    createTalent () {
      this.loading = true
      this.$http.$api.post(
        `/players/${this.$store.getters['players/current/id']}/talents`,
        { ...this.form.data }
      )
        .then(() => {
          this.success = true
        })
        .then(() => this.updatePlayerData())
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
    },
    editTalent () {
      this.loading = true
      this.$http.$api.put(
        `/user-talents/${this.cSelectedTalent.id}`,
        { ...this.form.data }
      )
        .then(() => {
          this.success = true
        })
        .then(() => this.updatePlayerData())
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
