export default {
  captions: {
    loading: 'Überprüfung Ihrer E-Mail',
    verified: 'E-Mail verifiziert',
    error: 'Error',
    resend: 'Bestätigungsmail erneut senden',
    again1: 'Wir haben Ihnen eine weitere Bestätigungs-E-Mail an gesendet',
    again2: '. Überprüfen Sie unbedingt Ihren Spam-Ordner!'
  },
  button: {
    send: 'senden',
    request: 'anfordern',
    back: 'zurück',
    close: 'Schließen'
  },
  errors: {
    conflict: 'Die E-Mail wurde bereits verifiziert!',
    badRequest: 'Ihre Validierungs-E-Mail ist abgelaufen!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!'
  }
}
