export const RegHtml = /<\/?[^>]*>/g

export const RegEMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const RegRouteDeal = /^deal(-(?:\w+))+$/
export const RegRouteGroup = /^group(-(?:\w+))+$/
export const RegRoutePlayer = /^player(-(?:\w+))+$/
export const RegRouteQuest = /^quest(-(?:\w+))+$/
export const RegRouteTimeline = /^timeline(-(?:\w+))+$/
export const RegRouteFeed = /^feed(-(?:\w+))+$/
export const RegRouteConversation = /^conversation(-(?:\w+))+$/

export const RegUuid = /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/
export const RegUuidString = RegUuid.toString().replace(/\//g, '')
