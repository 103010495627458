<template>
  <div
      class="detail-content"
      style="height:100%;"
  >
    <eins-layout-list
        #default="{ item }"
        :items="quests"
    >
      <eins-timeline-list-item
          :quest="item"
      />
    </eins-layout-list>
  </div>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsTimelineListItem from '@/components/timeline/EinsTimelineListItem'

export default {
  name: 'EinsTimelineList',
  components: {
    EinsTimelineListItem,
    EinsLayoutList
  },
  props: {
    quests: {
      type: [Array, Object],
      required: true,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
