<template>
  <v-row
      no-gutters
      class="flex-column fill-height"
  >
    <v-col
        class="text-truncate"
        style="max-height: 2em; overflow: hidden;"
    >
      {{ application.application_text }}
    </v-col>
    <v-col>
      <v-row
          dense
          class="flex-nowrap"
      >
        <v-col>
          <v-btn
              outlined
              block
              color="primary"
              @click.stop.prevent="onClickStartConversation"
          ><v-icon>mdi-message-text</v-icon></v-btn>
        </v-col>
        <v-col v-if="cHasAcceptAction">
          <v-btn
              outlined
              block
              color="primary"
              :disabled="cDisableAcceptAction"
              :loading="loadingAccept"
              @click.stop.prevent="onClickAccept"
          ><v-icon>mdi-lock-open-outline</v-icon></v-btn>
        </v-col>
        <v-col v-if="cHasRejectAction">
          <v-btn
              outlined
              block
              color="primary"
              :disabled="cDisableRejectAction"
              :loading="loadingReject"
              @click.stop.prevent="onClickReject"
          ><v-icon>mdi-minus-circle</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  startConversationWith
} from '@/utils/chat'
import {
  RegRouteDeal,
  RegRouteGroup,
  RegRoutePlayer,
  RegRouteQuest
} from '@/utils/regex'
import {
  ApplicationState,
  Entity
} from '@/constants'

export default {
  name: 'EinsApplicationListItemContent',
  components: {
  },
  props: {
    application: {
      type: Object,
      default: () => ({}),
      required: true
    },
    disableAcceptAction: {
      type: Boolean,
      default: false
    },
    disableRejectAction: {
      type: Boolean,
      default: false
    },
    hideAcceptAction: {
      type: Boolean,
      default: false
    },
    showRejectAction: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingAccept: false,
    loadingReject: false
  }),
  computed: {
    cIsAccepted () {
      return this.application?.state === ApplicationState.ACCEPTED ||
      this.application?.state === ApplicationState.CONFIRMED
    },
    cIsHandled () {
      return this.application?.state !== ApplicationState.UNHANDLED
    },
    cDisableAcceptAction () {
      return this.disableAcceptAction && !this.loadingReject
    },
    cDisableRejectAction () {
      return this.disableRejectAction && !this.loadingAccept
    },
    cHasAcceptAction () {
      return !this.hideAcceptAction && !this.cIsHandled
    },
    cHasRejectAction () {
      return this.showRejectAction && !this.cIsHandled
    },
    cIcon () {
      switch (this.application?.state) {
        case ApplicationState.REJECTED:
          return 'mdi-close-box-outline'
        case ApplicationState.ACCEPTED:
        case ApplicationState.CONFIRMED:
          return 'mdi-lock-open-outline'
        case ApplicationState.UNHANDLED:
        default:
          return 'mdi-human-greeting'
      }
    },
    cApplicant () {
      return this.application?.applicant ?? null
    },
    cApplicantGroup () {
      return this.application?.applicant_group ?? null
    },
    cArea () {
      const name = this.$route.name

      if (RegRoutePlayer.test(name)) {
        return Entity.PLAYER
      } else if (RegRouteDeal.test(name)) {
        return Entity.DEAL
      } else if (RegRouteQuest.test(name)) {
        return Entity.QUEST
      } else if (RegRouteGroup.test(name)) {
        return Entity.GROUP
      } else {
        return null
      }
    },
    cSubject () {
      switch (this.cArea) {
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal']
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest']
        case Entity.GROUP:
          return this.$store.getters['groups/selected/group']
        default:
          return null
      }
    }
  },
  watch: {
    loadingAccept (value, oldValue) {
      if (value !== oldValue) {
        this.$emit('load:accept', value)
      }
    }
  },
  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    onClickAccept () {
      this.acceptApplication()
    },
    onClickReject () {
      this.rejectApplication()
    },
    startConversation () {
      if (this.cApplicant) {
        startConversationWith(this.cApplicant.id)
      }
    },
    rejectApplication () {
      if (
        this.cApplicant &&
        this.cSubject
      ) {
        this.loadingReject = true
        let fragment
        switch (this.cArea) {
          case Entity.DEAL:
            fragment = 'deals'
            break
          case Entity.QUEST:
            fragment = 'quests'
            break
          case Entity.GROUP:
            fragment = 'groups'
            break
          default:
            return null
        }
        let route = `/${fragment}/${this.cSubject.id}/applications/${this.cApplicant.id}`

        if (this.cApplicantGroup) {
          route = `${route}/${this.cApplicantGroup.id}`
        }

        return this.$http.$api.patch(route, {
          rejected: true
        })
          .then(() => Promise.all([
            this.$store.dispatch(`${fragment}/selected/applications/update`),
            this.$store.dispatch(`${fragment}/selected/update`)
          ]))
          .finally(() => {
            this.loadingReject = false
          })
      } else {
        return Promise.reject(new Error('Missing requirements to accept application'))
      }
    },
    acceptApplication () {
      if (
        this.cApplicant &&
        this.cSubject
      ) {
        this.loadingAccept = true
        let fragment
        switch (this.cArea) {
          case Entity.DEAL:
            fragment = 'deals'
            break
          case Entity.QUEST:
            fragment = 'quests'
            break
          case Entity.GROUP:
            fragment = 'groups'
            break
          default:
            return null
        }
        let route = `/${fragment}/${this.cSubject.id}/applications/${this.cApplicant.id}`

        if (this.cApplicantGroup) {
          route = `${route}/${this.cApplicantGroup.id}`
        }

        return this.$http.$api.patch(route, {
          accepted: true
        })
          .then(() => Promise.all([
            this.$store.dispatch(`${fragment}/selected/update`),
            this.$store.dispatch(`${fragment}/selected/applications/loadAll`)
          ]))
          .finally(() => {
            this.loadingAccept = false
          })
      } else {
        return Promise.reject(new Error('Missing requirements to accept application'))
      }
    }
  }
}
</script>
