<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-play-circle"
      @click="onClick"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'
import {
  QuestState
} from '@/constants'

export default {
  name: 'EinsStarmenuAbStartQuest',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    },
    cShowBtn () {
      const currentPlayerId = this.$store.getters['players/current/id']

      return this.cBelongsToRoute &&
        this.cQuest.state === QuestState.ASSIGNED &&
        this.cQuest.assignee === currentPlayerId
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick () {
      this.$http.$api.patch(`/quests/${this.cQuest.id}/processing`, {
        started: true
      })
        .then(() => this.$store.dispatch('quests/selected/update'))
    }
  }
}
</script>

<style scoped>

</style>
