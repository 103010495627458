<template>
  <div
      ref="content"
      v-scroll:#main-content-scroll="onScroll"
      class="player-list"
  >
    <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
      <v-slide-y-transition>
        <v-progress-linear
            v-show="cLoading"
            height="5"
            indeterminate
        />
      </v-slide-y-transition>
    </div>
    <eins-player-list
        :players="cPlayers"
    />
    <v-alert
        outlined
        type="warning"
        dense
        text
        class="mx-2"
        :value="cListEnd"
    >
      End reached
    </v-alert>
    <router-view />
  </div>
</template>

<script>
import EinsPlayerList from '@/components/player/EinsPlayerList'
import { EventBus } from '@/eventBus.js'
import { RegRoutePlayer } from '@/utils/regex'

export default {
  name: 'ViewPlayerList',
  components: { EinsPlayerList },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (!RegRoutePlayer.test(to.name)) {
        vm.clearSearch()
      }
      vm.updateUserList()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!RegRoutePlayer.test(to.name)) {
      this.clearSearch()
    }
    next()
  },
  data: () => ({
    lastPosition: 0,
    textFilter: ''
  }),
  computed: {
    cListEnd () {
      return this.$store.getters['players/listEnd']
    },
    cLabel () {
      return this.$t('terms.player')
    },
    cPlayers () {
      let textFilterLc = this.cGetTextFilter.toLowerCase()
      const players = this.$store.getters['players/list']
        .filter((el) => this.textFilter?.length === 0 || el.name.toLowerCase().includes(textFilterLc) || el.about.toLowerCase().includes(textFilterLc))

      players.sort((a, b) => a.distance - b.distance)

      return players
    },
    cLoading () {
      return this.$store.getters['players/listLoading']
    },
    cGetTextFilter () { return this.textFilter }

  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
    EventBus.$on('changeTextFilter', (myVal) => {
      this.textFilter = myVal
    })
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.updateUserList()
    },
    onScroll (e) {
      if (!this.cLoading && this.$refs.content && !this.cListEnd) {
        const distanceLeft = this.$refs.content.clientHeight - (e.target.scrollTop + e.target.clientHeight)

        // only load more on scroll down
        if (distanceLeft < 250 && this.lastPosition < e.target.scrollTop) {
          this.$store.dispatch('players/loadMore')
        }
        this.lastPosition = e.target.scrollTop
      }
    },
    updateUserList () {
      this.$store.dispatch('players/update')
    },
    clearSearch () {
      this.$store.commit('players/clearSearch')
    }
  }
}
</script>

<style scoped>

</style>
