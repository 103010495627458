<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>
        <i18n path="group.delete.dialog.title" />
      </v-card-title>
      <v-expand-transition>
        <v-card-text v-if="!success">
          <i18n path="group.delete.dialog.text.main">
            <em>{{ cGroup.title }}</em>
          </i18n>
          <ul>
            <li
                v-for="n in 4"
                :key="n"
            >
              <i18n :path="`group.delete.dialog.text.note${n}`" />
            </li>
          </ul>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          type="warning"
          tile
          transition="expand-transition"
          :value="deleteWasClicked && !success"
      >
        <strong class="text-uppercase"><i18n path="group.delete.dialog.warning.title" /></strong><br />
        <i18n path="group.delete.dialog.warning.text" />
      </v-alert>
      <v-alert
          v-if="success"
          type="success"
          text
          tile
          transition="expand-transition"
      >
        <strong><i18n path="group.delete.dialog.success.title" /></strong><br />
        <i18n path="group.delete.dialog.success.text" />
      </v-alert>
      <v-card-actions>
        <v-expand-transition>
          <v-layout
              v-if="!success"
              column
          >
            <v-flex style="position: relative;">
              <v-btn
                  v-if="!deleteWasClicked"
                  color="primary"
                  block
                  :loading="loading"
                  @click="onClickSubmit"
              >
                <i18n path="group.delete.dialog.buttons.delete" />
              </v-btn>
              <template v-else>
                <v-btn
                    color="primary"
                    block
                    :disabled="finalSubmit.disabled"
                    :loading="loading"
                    @click="onClickSubmitFinally"
                >
                  <i18n path="group.delete.dialog.buttons.deleteFinally" />
                </v-btn>
                <v-progress-linear
                    v-if="finalSubmit.disabled"
                    style="position: absolute; bottom: 0; width: 100%;"
                    color="secondary"
                    :value="finalSubmit.progress"
                />
              </template>
            </v-flex>
            <v-flex class="mt-2">
              <v-btn
                  block
                  :disabled="loading"
                  @click="onClickCancel"
              >
                <i18n path="group.delete.dialog.buttons.cancel" />
              </v-btn>
            </v-flex>
          </v-layout>
        </v-expand-transition>
        <v-expand-transition>
          <eins-btn-timed
              v-if="success"
              color="primary"
              outlined
              block
              :run="open"
              @click="close"
          >
            <i18n path="group.delete.dialog.buttons.close" />
          </eins-btn-timed>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'
import { RegRouteGroup } from '@/utils/regex'

const DISABLE_TIMEOUT = 5000

export default {
  name: 'EinsStarmenuDlgDeleteGroup',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    success: false,
    error: false,
    loading: false,
    deleteWasClicked: false,
    finalSubmit: {
      disabled: true,
      progress: 0
    },
    $_mx_translatable: {
      paths: [
        'text',
        'btn.close',
        'btn.ok',
        'btn.submit',
        'success.title',
        'success.text'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogDeleteGroup']
      },
      set (value) {
        this.$store.commit('dialogDeleteGroup', value)
      }
    },
    cTextTransferOwnership () {
      return {
        selectLabel: this.$t('group.changeOwnership.select.label'),
        noData: this.$t('group.changeOwnership.select.noData'),
        cancelButton: this.$t('group.changeOwnership.buttons.cancel'),
        closeButton: this.$t('group.changeOwnership.buttons.close'),
        submitButton: this.$t('group.changeOwnership.buttons.submit')
      }
    },
    cGroup () {
      return this.cPayload ?? this.cEntity
    },
    cPayload () {
      return this.$store.getters['dialogPayload']('dialogDeleteGroup')
    },
    cTitle () {
      return this.$t(`components.${this.$options.name}.title`, [ this.cEntity?.title ])
    },
    cCurrentUserId () {
      return this.$store.getters['players/current/id']
    },
    cEntity () {
      return this.$store.getters['groups/selected/group']
    },
    cUserIsOwner () {
      return this.cEntity?.owner_id === this.cCurrentUserId
    },
    cUserIsMember () {
      const groups = this.$store.getters['players/current/groups'] ?? []

      return groups.includes(this.cEntity?.id)
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        this.success = false
        this.error = false
        this.loading = false
        this.deleteWasClicked = false
      }
    },
    onClickSubmit () {
      this.deleteWasClicked = true
      this.finalSubmit.disabled = true
      this.finalSubmit.progress = 0
      const start = Date.now()
      const interval = setInterval(
        () => {
          if (this.finalSubmit.progress === 100) {
            this.finalSubmit.disabled = false
            clearInterval(interval)
          }

          const diff = Date.now() - start
          if (diff >= DISABLE_TIMEOUT) {
            this.finalSubmit.progress = 100
          } else {
            this.finalSubmit.progress = Math.round(100 * (diff / DISABLE_TIMEOUT))
          }
        },
        DISABLE_TIMEOUT / 100
      )
    },
    onClickSubmitFinally () {
      this.submit()
    },
    onClickCancel () {
      this.close()
    },
    onClickClose () {
      this.close()
    },
    close () {
      this.open = false

      if (this.success) {
        const groupId = this.cGroup.id
        setTimeout(() => {
          this.$store.commit('groups/selected/removeFromRingBuffer', groupId)
        }, 250)

        const isGroupRoute = RegRouteGroup.test(this.$route.name)
        const isNotGroupListRoute = this.$route.name !== 'group-list'

        if (
          isGroupRoute &&
          isNotGroupListRoute &&
          this.$route.params.id === groupId
        ) {
          this.$router.push({
            name: 'group-list'
          })
        }
      }
    },
    submit () {
      const id = this.cPayload?.id ?? this.cEntity.id

      this.loading = true
      this.$http.$api.delete(`groups/${id}`)
        .then(() => {
          const groupId = this.cGroup.id

          this.success = true
          this.$store.dispatch('players/current/update')
          this.$store.commit('groups/removeFromCache', [groupId])
        })
        .catch((e) => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
::v-deep .v-progress-linear {
  transition: none !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
