export const EinsStarmenuDpbGroupMembers = {
  text: 'Member list'
}

export const EinsStarmenuDpbGroupDetails = {
  text: 'data'
}

export const EinsStarmenuDpbApplications = {
  text: 'Application list'
}

export const EinsStarmenuDpbPlayerGroups = {
  text: '@.lower:(terms.groups)'
}

export const EinsStarmenuDpbDeals = {
  text: '@.lower:(terms.deals)'
}

export const EinsStarmenuDpbDealsFinished = {
  text: 'Sold'
}

export const EinsStarmenuDpbDealsClosed = {
  text: 'Closed @.lower:(terms.deals)'
}

export const EinsStarmenuDpbDealsGroup = {
  text: '@.lower:(terms.deals) @.lower:(terms.group)'
}

export const EinsStarmenuDpbQuestsGroup = {
  text: '@.lower:(terms.quest) @.lower:(terms.group)'
}

export const EinsStarmenuDpbQuests = {
  text: '@.lower:(terms.quest)'
}

export const EinsStarmenuDpbQuestsSolved = {
  text: 'proofs'
}

export const EinsStarmenuDpbMyGroups = {
  text: 'My @.lower:(terms.groups)'
}

export const EinsStarmenuDpbGroupConversation = {
  text: 'Chat'
}

export const EinsStarmenuDpbMyDeals = {
  text: 'My @.lower:(terms.deals)'
}

export const EinsStarmenuDpbQuestTemplates = {
  text: '@.lower:(terms.quest) Templates'
}

export const EinsStarmenuDpbMyQuests = {
  text: 'My @.lower:(terms.quests)'
}

export const EinsStarmenuDpbPlayerBlockages = {
  text: 'Blockes @.lower:(terms.players)'
}

export const EinsStarmenuDpbPlayerContacts = {
  text: 'My Contacts'
}

export const EinsStarmenuDpbPlayerData = {
  text: 'data '
}
export const EinsStarmenuDpbDirectMessage = {
  text: 'Message'
}

export const EinsStarmenuDpbDealTemplates = {
  text: '@.lower:(terms.deals) Templates'
}

export const EinsStarmenuDpbDetails = {
  text: 'Details'
}

export const EinsDealListItemPlayerDeal = {
  btn: {
    application: 'Application',
    list: 'List',
    chat: 'chat'
  }
}
