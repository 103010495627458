<template>
  <v-input
      :rules="rules"
      :value="internalValue"
  >
    <v-row
        align="center"
        class="text-h6 flex-nowrap"
        justify="space-between"
    >
      <v-col>
        <v-row
            align="center"
            no-gutters
        >
          <v-col class="flex-grow-0">
            {{ currentValueLabel }}:&nbsp;
          </v-col>
          <template v-if="cSelectedGroup">
            <v-col class="flex-grow-0">
              <v-icon left>{{ cSelectedGroup.group_type.icon }}</v-icon>
            </v-col>
            <v-col>
              {{ cSelectedGroup.title }}
            </v-col>
          </template>
          <template v-else-if="voidOption">
            <v-col class="flex-grow-0">
              <v-icon
                  v-if="voidOption.icon"
                  left
              >{{ voidOption.icon }}</v-icon>
            </v-col>
            <v-col>
              {{ voidOption.label }}
            </v-col>
          </template>
          <template v-else>
            <v-col>
              &horbar;
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn
            icon
            @click="onClickChange"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text
            v-if="!success"
            class="overflow-y-auto"
            style="max-height: 50vh"
        >
          <eins-input-group-scope
              v-model="inputValue"
              :list-caption="listCaption"
              :void-option="voidOption"
          />
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn
              block
              color="primary"
              :disabled="inputValue === internalValue"
              @click="onClickSubmit"
          >{{ submitLabel }}</v-btn>
          <v-btn
              block
              class="mx-0 mt-2"
              outlined
              @click="onClickCancel"
          ><i18n path="msc.actions.cancel" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-input>
</template>

<script>
import EinsInputGroupScope from '@/components/input/EinsInputGroupScope'

export default {
  name: 'EinsInputGroupScopeDialog',
  components: {
    EinsInputGroupScope
  },
  props: {
    value: {
      validator: value => value === null || typeof value === 'string',
      required: true
    },
    currentValueLabel: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    listCaption: {
      type: String,
      default: null
    },
    membershipState: {
      type: Array,
      default: () => []
    },
    voidOption: {
      type: Object,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    internalValue: null,
    inputValue: null
  }),
  computed: {
    cValueIsUnchanged () {
      return this.value === this.form.value
    },
    cSelectedGroup () {
      return this.$store.getters['players/current/groups/items']
        .find(group => group.id === this.value) ?? null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.internalValue = value
    },
    onClickChange () {
      this.openDialog()
    },
    onClickSubmit () {
      this.submit()
      this.closeDialog()
    },
    onClickCancel () {
      this.closeDialog()
    },
    submit () {
      this.internalValue = this.inputValue
      this.emitInput()
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    openDialog () {
      this.reset()
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.inputValue = this.internalValue
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
