import { FriendshipState } from '../../../constants'

export const EinsStarmenuAbApply = {
  text: 'Apply'
}

export const EinsStarmenuAbChangeGroupOwner = {
  text: 'Transfer ownership'
}

export const EinsStarmenuAbCopy = {
  text: 'Copy data'
}

export const EinsStarmenuAbGetDeal = {
  text: 'Get @.lower:(terms.deal) '
}
export const EinsStarmenuAbHonorDeal = {
  text: 'use @.lower:(terms.deal) Link'
}

export const EinsStarmenuAbAddContent = {
  text: 'Content',
  addProduct: 'add @.lower:(terms.product)',
  addService: 'add @.lower:(terms.service)',
  addCommon: 'add @.lower:(terms.common) @.lower:(terms.quest)',
  addGreen: 'add @.lower:(terms.future) @.lower:(terms.quest)',
  addPrivate: 'add @.lower:(terms.private) @.lower:(terms.quest)'
}

export const EinsStarmenuAbJoinGroup = {
  text: 'Join @.lower:(terms.group) '
}

export const EinsStarmenuAbLeaveGroup = {
  text: 'End Connection '
}

export const EinsStarmenuAbDenounce = {
  text: 'Report'
}

export const EinsStarmenuAbCloseAccount = {
  text: 'Delete @.lower:(terms.player) Date'
}

export const EinsStarmenuAbFoundGroup = {
  text: 'create @.lower:(terms.group) '
}

export const EinsStarmenuAbFollow = {
  text: {
    [FriendshipState.NONE]: 'Add idol',
    [FriendshipState.IDOL]: 'Remove idol',
    [FriendshipState.FAN]: 'Become friends',
    [FriendshipState.FRIEND]: 'Demote to follwer',
    [FriendshipState.ME]: 'none'
  }
}

export const EinsStarmenuAbCreateDealProduct = {
  text: 'create @.lower:(terms.product)'
}

export const EinsStarmenuAbCreateDealService = {
  text: 'create @.lower:(terms.service)'
}

export const EinsStarmenuAbCreateQuestPrivate = {
  text: 'create @.lower:(terms.pirvate) @.lower:(terms.quest) '
}

export const EinsStarmenuAbCreateQuestCommon = {
  text: 'create @.lower:(terms.common) @.lower:(terms.quest) '
}

export const EinsStarmenuAbCreateQuestFuture = {
  text: 'create @.lower:(terms.future) @.lower:(terms.quest) '
}

export const EinsStarmenuAbShare = {
  text: 'share'
}

export const EinsStarmenuAbDislike = {
  text: 'Dislike'
}

export const EinsStarmenuAbStartQuest = {
  text: 'Start @.lower:(terms.quest) '
}

export const EinsStarmenuAbMainpage = {
  text: 'Main Page'
}

export const EinsStarmenuAbFinishQuest = {
  text: 'Finish @.lower:(terms.quest) '
}

export const EinsStarmenuAbApproveQuest = {
  text: 'Approve @.lower:(terms.quest) '
}

export const EinsStarmenuAbExchangeDeal = {
  text: 'Exchange @.lower:(terms.deal) '
}

export const EinsStarmenuAbRejectDeal = {
  text: 'Reject @.lower:(terms.deal) '
}

export const EinsStarmenuAbCancelDeal = {
  text: 'Cancel @.lower:(terms.deal) '
}

export const EinsStarmenuAbCancelQuest = {
  text: 'Cancel @.lower:(terms.quest) '
}

export const EinsStarmenuAbQrCode = {
  text: 'QR-Code'
}

export const EinsStarmenuAbDeleteGroup = {
  text: 'Close @.lower:(terms.group)'
}
