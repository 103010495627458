<template>
  <eins-api-image
      #default="{ src }"
      :src="cPicture.pictureThumb"
      class="ma-1"
  >
    <v-img
        :src="src"
        width="100%"
        @click="onPictureClick"
    >
      <template #placeholder>
        <v-skeleton-loader
            tile
            type="image"
        />
      </template>
      <div>
        <eins-gallery-picture-type-icon :picture-type="cPicture.type" />
      </div>
    </v-img>
  </eins-api-image>
</template>

<script>
import EinsApiImage from '../EinsApiImage'
import EinsGalleryPictureTypeIcon from './EinsGalleryPictureTypeIcon'

export default {
  name: 'EinsGalleryPicture',
  components: { EinsApiImage, EinsGalleryPictureTypeIcon },
  props: {
    picture: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    cPicture () {
      return this.picture || {}
    }
  },
  methods: {
    onPictureClick () {
      switch (this.cPicture?.type) {
        case 'quest-processing-picture':
          // this.$router.push(this.$router.push(`quests/${this.cPicture.object.id}`))
          this.$emit('on-click', this.index)
          break
        case 'title-picture':
          this.$emit('on-click', this.index)
          break
        case 'gallery-picture':
          this.$emit('on-click', this.index)
          break
      }
    }
  }
}
</script>

<style scoped>

</style>
