import { FriendshipState, Membership, QuestScoring } from '@/constants'

export const calculateQuestScore = (quest) => {
  const groupScore = calculateQuestGroupScore(quest) * QuestScoring.WEIGHT_MEMBERSHIP
  const friendshipScore = calculateQuestFriendshipScore(quest) * QuestScoring.WEIGHT_FRIENDSHIP
  const distanceScore = calculateDistanceScore(quest) * QuestScoring.WEIGHT_DISTANCE
  const ageScore = calculateAgeScore(quest) * QuestScoring.WEIGHT_AGE

  return groupScore + friendshipScore + distanceScore + ageScore
}

export const calculateQuestGroupScore = (quest) => {
  const group = quest.group ?? null

  let groupScore = QuestScoring.VALUE_MEMBERSHIP_NO_GROUP_QUEST
  if (group) {
    if (group.membership_state !== Membership.NONE) {
      groupScore = QuestScoring.VALUE_MEMBERSHIP_MEMBER
    } else {
      groupScore = QuestScoring.VALUE_MEMBERSHIP_NONE
    }
  }

  return groupScore
}

export const calculateQuestFriendshipScore = (quest) => {
  const player = quest.player

  switch (player.friendship_state) {
    case FriendshipState.FRIEND: return QuestScoring.VALUE_FRIENDSHIP_FRIEND
    case FriendshipState.IDOL: return QuestScoring.VALUE_FRIENDSHIP_IDOL
    case FriendshipState.FAN: return QuestScoring.VALUE_FRIENDSHIP_FOLLOWER
    default:
    case FriendshipState.NONE: return QuestScoring.VALUE_FRIENDSHIP_NONE
  }
}

export const calculateDistanceScore = (quest) => {
  const distance = quest.distance ?? Infinity

  return Math.round(distance / 10) / 100
}

export const calculateAgeScore = (quest) => {
  const updatedAt = (new Date(quest.updated_at)).valueOf()

  const age = isNaN(updatedAt) ? Infinity : (Date.now() - updatedAt) / 3600000 // (60min * 60s * 1000ms) / 1h -> 3600s/h

  return Math.round(age * 100) / 100
}
