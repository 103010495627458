<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-delete-forever</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbCloseAccount',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute &&
          (
            this.$store.getters['players/current/id'] === this.$route.params.id ||
            this.$route.name === 'my-data'
          )
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogCloseAccount')
        .then(() => {
        })
    }
  }
}
</script>

<style scoped>
</style>
