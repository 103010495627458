<template>
  <v-expansion-panel>
    <v-expansion-panel-header ripple>
      <i18n
          path="player.update.notifications.label"
          tag="strong"
      />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="text-body-1">
      <div>
        <div>
          {{ cNotificationWeight }}
        </div>
        <v-row>
          <v-spacer />
          <v-col class="flex-grow-0">
            <v-btn
                outlined
                small
                @click="onClickChangeWeight"
            ><i18n path="player.update.notifications.actions.change" /></v-btn>
          </v-col>
        </v-row>
        <eins-module-notification-weight-overlay
            v-model="dialog.weight"
            absolute
        />
      </div>
      <div class="mx-n3">
        <v-divider class="my-3" />
      </div>
      <div>
        <i18n path="player.update.notifications.labelTypes" />
        <v-chip-group v-model="selectedType">
          <v-chip
              v-for="type of cNotificationTypes"
              :key="type.key"
              small
              :value="type"
          >
            {{ type.title }}
          </v-chip>
        </v-chip-group>
        <v-expand-transition>
          <v-card v-if="selectedType && selectedType.description">
            <v-card-text>{{ selectedType.description }}</v-card-text>
          </v-card>
        </v-expand-transition>
        <v-row>
          <v-spacer />
          <v-col class="flex-grow-0">
            <v-btn
                block
                outlined
                small
                @click="onClickChangeTypes"
            ><i18n path="player.update.notifications.actions.change" /></v-btn>
          </v-col>
        </v-row>
        <eins-module-notification-types-overlay
            v-model="dialog.type"
            absolute
        />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EinsModuleNotificationTypesOverlay from '@/components/notifications/EinsModuleNotificationTypesOverlay'
import EinsModuleNotificationWeightOverlay from '@/components/notifications/EinsModuleNotificationWeightOverlay'
export default {
  name: 'EinsPlayerDataNotificationBlock',
  components: { EinsModuleNotificationWeightOverlay, EinsModuleNotificationTypesOverlay },
  data: () => ({
    dialog: {
      type: false,
      weight: false
    },
    selectedType: null
  }),
  computed: {
    cNotificationWeight () {
      const weight = this.$store.getters['players/current/notificationWeight']

      return this.$t(`notifications.weight.${weight}.description`)
    },
    cNotificationTypes () {
      return this.$store.getters['players/current/notificationTypes']
        .map(type => ({
          key: type,
          title: this.$t(`notifications.type.${type}.title`),
          description: this.$te(`notifications.type.${type}.description`)
            ? this.$t(`notifications.type.${type}.description`)
            : null
        }))
    }
  },
  methods: {
    onClickChangeTypes () {
      this.dialog.type = true
    },
    onClickChangeWeight () {
      this.dialog.weight = true
    },
    updateUserData () {
      this.update.loading = true
      this.$store.dispatch('players/current/update')
        .finally(() => {
          this.update.loading = false
        })
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
