<template>
  <eins-layout-view-detail>
    <v-slide-y-transition>
      <v-progress-linear
          v-show="cLoading"
          height="5"
          indeterminate
      />
    </v-slide-y-transition>
    <v-card
        class="pa-0 ma-0"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <v-stepper
          v-model="createStepper"
          class="d-flex flex-column fill-height"
      >
        <v-col class="flex-grow-0 flex-shrink-1">
          <!-- Stepper headers -->
          <v-stepper-header
              class="ma-0 pa-0"
              style="height:42px;"
          >
            <template v-for="(step,index) in stepper">
              <v-divider
                  v-if="index > 0"
                  :key="`divider_${index}`"
              />
              <v-stepper-step
                  :key="'step_' + index"
                  :complete="createStepper > step.id && step.active"
                  :complete-icon="step.icon"
                  :color="step.active ? 'primary' : 'gray'"
                  :step="step.id"
                  class="ma-0 pb-0 pt-0"
              >
                <v-icon>{{ step.icon }}</v-icon>
              </v-stepper-step>
            </template>
          </v-stepper-header>
        </v-col>
        <v-col style="position: relative;">
          <v-form
              v-model="formValid"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow-y: auto;"
          >
            <!-- Stepper Pages-->
            <v-stepper-items>
              <v-stepper-content
                  step="1"
                  class="pa-3 pt-0 ma-0"
              >
                <eins-group-create-form-step1
                    v-if="createStepper === 1"
                    v-model="formData"
                    :on-set-group-type="setGroupType"
                >
                </eins-group-create-form-step1>
                <v-row
                    no-gutters
                    justify="start"
                    class="pl-4 pr-4"
                >
                  <v-btn
                      depressed
                      :disabled="saving"
                      @click="onClickCancel"
                  >
                    <v-icon>mdi-cancel</v-icon>
                    {{ cText['buttons.cancel.label'] }}
                  </v-btn>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                  step="4"
                  class="pa-1 ma-0 pl-1"
              >
                <eins-group-create-form-step4
                    v-if="createStepper === 4"
                    v-model="formData"
                ></eins-group-create-form-step4>
                <v-alert
                    v-if="cEmailIsDuplicated"
                    type="error"
                >
                  EMAIL DOPPELT!!!!
                </v-alert>
                <v-row
                    no-gutters
                    justify="space-between"
                    class="pl-4 pr-4"
                >
                  <v-btn
                      left
                      @click="goBack"
                  >
                    <v-icon>mdi-arrow-left-circle</v-icon>
                    <span class="pa-2">{{ cText['buttons.back.label'] }}</span>
                  </v-btn>
                  <v-btn
                      v-if="cFieldsNeedsRequest"
                      color="primary"
                      @click="submit"
                  >
                    <v-icon>mdi-upload-lock</v-icon>
                    <span class="pa-2">{{ cText['buttons.request.label'] }}</span>
                  </v-btn>
                  <v-btn
                      v-else
                      color="primary"
                      @click="submit"
                  >
                    <v-icon>mdi-upload</v-icon>
                    <span
                        class="pa-2"
                    >{{ cText['buttons.submit.label'] }}</span>
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-form>
        </v-col>
      </v-stepper>
    </v-card>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsGroupCreateFormStep1 from '@/components/group/GroupCreate/EinsGroupCreateFormStep1'
import EinsGroupCreateFormStep4 from '@/components/group/GroupCreate/EinsGroupCreateFormStep3'

export default {
  name: 'ViewGroupCreate',
  components: {
    EinsGroupCreateFormStep4,
    EinsGroupCreateFormStep1,
    EinsLayoutViewDetail
  },
  mixins: [MxTranslatable],
  data: () => ({
    formData: null,
    formValid: false,
    saving: false,
    alert: {
      cancel: false
    },
    selectedGroupType: 0,
    createStepper: 1,
    stepper: [
      { id: 1, icon: 'mdi-google-circles-group', completed: false, active: true },
      { id: 2, icon: 'mdi-form-select', completed: false, active: true },
      { id: 3, icon: 'mdi-image-text', completed: false, active: true },
      { id: 4, icon: 'mdi-check-outline', completed: false, active: true }
    ],
    $_mx_translatable: {
      paths: [
        'buttons.back.label',
        'buttons.choose.label',
        'buttons.finish.label',
        'buttons.submit.label',
        'buttons.request.label',
        'buttons.cancel.label',
        'buttons.save.label'
      ],
      basePath: 'views'
    },
    error: null
  }),
  computed: {
    cEmailIsDuplicated () {
      return this.error &&
          !!this.error?.response.data?.errors?.business_email.find(error => error.code === 'unique_error')
    },
    cLicenseTypes () {
      return this.$store.getters['groupTypes/list'] || []
    },
    cLoading () {
      return false
    },
    cDisabledPage3 () {
      return (
          this.formData?.business_email === '' ||
          this.formData?.contact === '' ||
          this.formData?.invoiceAdressCompanyName === '' ||
          this.formData?.kindOfBusiness === null
      )
    }
  },
  watch: {
    'formData.paymentLicense' (val) {
      if (val !== null) {
        this.createStepper = 5
      }
    }
  },
  methods: {
    onClickNext3 () {
      this.createStepper = 4
    },
    onClickCancel () {
      this.$router.push('/groups/')
    },
    setGroupType (type) {
      this.selectedGroupType = type
      this.resetStepper()
      switch (type) {
        case 1:
          this.createStepper = 4
          this.stepper[1].active = false
          this.stepper[2].active = false
          this.stepper[3].active = false
          this.formData.license = this.cLicenseTypes.filter(function (license) {
            return license.key === 'priv'
          })[0]
          break
        case 4:
          this.createStepper = 2
          break
      }
    },
    setGroupLicense () {
      this.createStepper = 3
    },
    resetStepper () {
      for (let i = 0; i < this.stepper.length; i++) {
        this.stepper[i].active = true
      }
    },
    goBack () {
      let indx = 1
      for (let i = this.createStepper - 2; i >= 0; i--) {
        if (this.stepper[i].active) {
          indx = this.stepper[i].id
          break
        }
      }
      this.createStepper = indx
      // this.resetStepper()
    },
    submit () {
      this.saving = true
      const payload = {
        ...this.formData
      }
      delete payload.picture
      payload.player_group_type_id = this.formData.license.id
      payload.business_sector_id = this.formData.kindOfBusiness
      payload.payment_method = this.formData.paymentLicense
      payload.invoice_company = this.formData.invoiceAdressCompanyName
      payload.invoice_city = this.formData.invoiceAdressCity
      payload.invoice_country = this.formData.invoiceAdressCountry
      payload.invoice_house_number = this.formData.invoiceAdressHouseNumber
      payload.invoice_street = this.formData.invoiceAdressStreet
      payload.invoice_postal_code = this.formData.invoiceAdressZipCode
      payload.contact_information = this.formData.contact
      let groupId
      this.$http.$api.post('/groups', payload)
        .then((result) => {
          groupId = result.data.id
          // return: bild hochladen
          const picture = this.formData.picture
          if (picture) {
            return this.$http.$api.put('/groups/' + groupId + '/picture', picture, {
              headers: {
                'Content-Type': picture.type
              }
            })
          } else {
            return Promise.resolve()
          }
        })
        .then(() => {
          this.$router.push('/groups/' + groupId + '/edit?justcreated=true')
          return true
        })
        .catch((err) => {
          this.error = err
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
