export const MxVuetifyInputWrapper = {
  props: {
    /**
     * Label to display for the input-component.
     *  If omitted a localized default will be used.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * Hint to display for the input-component.
     *  If omitted a localized default will be used.
     *  If no default is found the value of `cHint` remains empty.
     */
    hint: {
      type: String,
      default: null
    }
  },
  computed: {
    cLabel () {
      if (typeof this.label === 'string') {
        return this.label
      } else if (this.multiple === true) {
        return this.$t(`components.${this.$options.name}.labelMultiple`)
      } else {
        return this.$t(`components.${this.$options.name}.label`)
      }
    },
    cHint () {
      if (typeof this.hint === 'string') {
        return this.hint
      } else if (this.$te(`components.${this.$options.name}.hint`)) {
        return this.$t(`components.${this.$options.name}.hint`, this.$i18n.locale)
      } else {
        return ''
      }
    }
  },
  methods: {
    /**
     * **VSelect method.**
     * The call is redirected to the Vuetify VSelect component.
     * @public
     */
    focus () {
      this.$refs.input.focus()
    },
    /**
     * **VSelect method.**
     * The call is redirected to the Vuetify VSelect component.
     * @public
     */
    validate () {
      this.$refs.input.validate()
    }
  }
}
