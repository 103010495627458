<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cTitle }}</v-card-title>
      <v-card-text>
        {{ cText['btn.info'] }}
      </v-card-text>
      <v-expand-transition>
        <v-alert
            :value="cShowError"
            dismissible
            tile
            dense
            type="error"
            @input="onClickHideErrorMessage"
        >
          <strong>
            {{ cText['btn.error'] }}
          </strong><br />
          {{ cErrorText }}
        </v-alert>
      </v-expand-transition>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            color="primary"
            outlined
            block
            @click="close"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <v-expand-transition>
          <v-layout
              v-if="!success"
              column
          >
            <v-flex>
              <v-btn
                  block
                  color="primary"
                  :loading="loading"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-flex>
            <v-flex class="mt-2">
              <v-btn
                  block
                  color="grey"
                  outlined
                  :disabled="loading"
                  @click="onClickCancel"
              >{{ cText['btn.cancel'] }}</v-btn>
            </v-flex>
          </v-layout>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgRejectDeal',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    error: null,
    success: false,
    $_mx_translatable: {
      paths: [
        'title',
        'error.409',
        'error.unknown',
        'btn.close',
        'btn.submit',
        'btn.cancel',
        'btn.info',
        'btn.error'
      ]
    }
  }),
  computed: {
    cTitle () {
      return this.$t(`components.${this.$options.name}.title`, [ this.cName ])
    },
    open: {
      get () {
        return this.$store.getters['dialogRejectDeal']
      },
      set (value) {
        this.$store.commit('dialogRejectDeal', value)
      }
    },
    cShowError () {
      return !!this.error
    },
    cRouteId () {
      return this.$route.params.id
    },
    cDeal () {
      return this.$store.getters.dialogPayload('dialogRejectDeal').deal
    },
    cName () {
      return this.cDeal?.title ?? ''
    },
    cApiUrl () {
      const currentPlayerId = this.$store?.getters['players/current/id']
      return this.cDeal ? `/deals/${this.cDeal.id}/applications/${currentPlayerId}` : null
    },
    cErrorText () {
      let result = ''

      if (this.error) {
        switch (this.error) {
          case 409:
            result = this.$t(`components.${this.$options.name}.error.409`, [ this.cDeal?.name ?? '' ])
            break
          default:
            result = this.$t(`components.${this.$options.name}.error.unknown`)
            break
        }
      }

      return result
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.success = false
          this.error = false
        }
      }
    },
    onClickClose () {
      this.close()
    },
    onClickHideErrorMessage () {
      this.error = null
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    close () {
      this.open = false
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.error = false

        if (this.cApiUrl) {
          this.$http.$api.delete(this.cApiUrl)
            .then(() => {
              this.success = true
            })
            .catch((e) => {
              this.error = e.response?.status ?? true
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          reject(new Error('No valid route'))
          this.loading = false
          this.error = true
        }
      })
    },
    cancel () {
      this.close()
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
