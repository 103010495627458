<template>
  <eins-layout-view-detail>
    <v-sheet
        height="100%"
        tile
        style="background: transparent !important"
    >
      <v-container style="height: 100%;">
        <v-row
            style="height: 100%;"
            class="flex-column"
        >
          <div
              class="header-background d-flex pa-2 "
          >
            <div>
              <eins-api-image
                  #default="{ src }"
                  :src="cGetProfilePicture"
              >
                <v-img
                    alt=""
                    max-height="40"
                    max-width="40"
                    :src="src"
                    @click="onClickPlayerAvatar"
                ></v-img>
              </eins-api-image>
            </div>
            <div class="d-flex-column">
              <div class="pl-2">
                {{ cProfileInfoTitle }}
              </div>
              <div
                  class="caption mb-n1 pl-2 pt-0 pb-0"
                  style="line-height: 1.1;"
              >
                {{ `#${cProfileInfoId}` }}
              </div>
            </div>
            <v-spacer />
            <!-- displays other information of the user -->
            <div
                class="d-flex-column"
            >
              <v-icon
                  v-if="cGroupIcon"
                  class="mt-n1"
                  color="primary"
              > {{ cGroupIcon }}</v-icon>
              <eins-icon-friendship
                  v-else
                  class="mt-n1"
                  style="width: 30px; height: 30px"
                  color="primary"
                  :state="cPlayerIcon"
              />
            </div>
          </div>
          <v-col>
            <v-sheet
                class="message-area"
                height="100%"
            >
              <v-card
                  ref="card"
                  v-scroll.self="onScroll"
                  :color="$vuetify.theme.dark ? 'black' : 'white'"
              >
                <v-card-text ref="text">
                  <ein-chat-message-list
                      ref="list"
                      :user="cProfileInfo"
                      :messages="cMessages"
                  />
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>
          <v-col class="flex-shrink-1 flex-grow-0">
            <v-text-field
                v-model="newMessage"
                :disabled="!cApiRoute"
                solo
                auto-grow
                rows="2"
                hide-details
                label="Message..."
                :append-outer-icon="newMessage ? 'mdi-send' : null"
                @click:append-outer="sendMessage"
                @keyup.enter="sendMessage"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </eins-layout-view-detail>
</template>

<script>
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinChatMessageList from '@/components/chat/EinChatMessageList'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsApiImage from '@/components/EinsApiImage'
import { EventBus } from '@/eventBus.js'
import { EventNames } from '@/constants'

export default {
  name: 'ViewChatConversation',
  components: {
    EinsApiImage,
    EinChatMessageList,
    EinsLayoutViewDetail,
    EinsIconFriendship
  },
  data: () => ({
    newMessage: '',
    loading: false,
    bottom: true,
    show: false,
    textFilter: '',
    profileInfo: [],
    messageCashe: []
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$root.$on(EventNames.FIREBASE_MESSAGE, vm.onFirebaseMessage())
      vm.checkNewMessages()
      vm.updateChatList()
      vm.sendMessage()
      vm.getProfileInfo()
    })
  },

  beforeRouteUpdate (to, from, next) {
    if (to.params.id !== from.params.id) {
      this.checkNewMessages()
      this.updateChatList()
      this.resetCache()
      this.sendMessage()
      this.getProfileInfo()
      this.newMessage = ''
    }
    next()
  },

  beforeRouteLeave  (to, from, next) {
    this.resetCache()
    this.newMessage = ''
    next()
  },

  computed: {

    cProfileInfo () { return this.profileInfo },

    cProfileInfoId () { return this.profileInfo ? this.cProfileInfo?.id : '' },

    cGroupIcon () { return this.cProfileInfo?.group_type?.icon ?? '' },

    cPlayerIcon () { return this.profileInfo ? this.profileInfo?.friendship_state : '' },

    cProfileInfoTitle () {
      if (this.profileInfo?.group_type) {
        return this.profileInfo?.title
      } else {
        if (!this.profileInfo) {
          return this.$t('components.chat.unknownPlayer')
        } else if (this.profileInfo.is_deleted) {
          return this.$t('player.deleted.name')
        } else {
          return this.profileInfo.name
        }
      }
    },

    cProfileInfoPicture () { return this.profileInfo?.group_type ? this.profileInfo?.title_picture_feed : this.profileInfo?.title_picture_feed },

    cGetProfilePicture () { return this.cProfileInfoPicture ?? '' },

    cConversation () { return { id: this.$route.params.id } },

    cGroupRoute () { return this.cConversation ? `/conversations/${this.cConversation.id}` : `/conversations/${this.cConversation.id}` },

    cGetTextFilter () { return this.textFilter },

    cMessages () {
      let textFilterLc = this.cGetTextFilter.toLowerCase()
      return [].concat(this.messageCashe)
        .filter((el) => this.textFilter?.length === 0 || el.body.toLowerCase().includes(textFilterLc) || el.sender.name.toLowerCase().includes(textFilterLc))
        .sort((a, b) => a.created_at - b.created_at)
    },

    cApiRoute () {
      return this.cConversation ? `/conversations/${this.cConversation.id}/messages` : null
    },

    cMessageReady () {
      return this.newMessage.trim().length > 0
    },
    cPlayer () { return this.$store.getters['players/selected/player'] },

    cDistance () { return this.cProfileInfo ? this.cProfileInfo?.distance : '' }
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
    EventBus.$on('changeTextFilter', (myVal) => {
      this.textFilter = myVal
    })
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },

  methods: {
    onRequestViewUpdate () {
      this.checkNewMessages()
    },
    onFirebaseMessage (msg) {
      this.checkNewMessages()
    },
    onClickPlayerAvatar () {
      if (this.profileInfo?.group_type) {
        this.$router.push({
          name: 'group-detail',
          params: {
            id: this.profileInfo?.id
          }
        })
      } else {
        this.$router.push({
          name: 'player-detail',
          params: {
            id: this.profileInfo?.id
          }
        })
      }
    },
    onScroll (e) {
      if (!this.cLoading && this.$refs.content && !this.cListEnd) {
        const distanceLeft = this.$refs.content.clientHeight - (e.target.scrollTop + e.target.clientHeight)
        // only load more on scroll down
        if (distanceLeft < 250 && this.lastPosition < e.target.scrollTop) {
          this.$store.dispatch('conversations/loadMore')
        }

        this.lastPosition = e.target.scrollTop
      }
    },
    resetCache () {
      this.messageCashe = []
    },
    updateChatList () {
      this.$store.dispatch('conversations/update')
    },
    getProfileInfo () {
      if (this.cGroupRoute) {
        this.loading = true
        this.$http.$api.get(`/conversations/${this.cConversation.id}`)
          .then(result => {
            this.profileInfo = result.data?.group?.group_type ? result.data?.group : result.data?.contacts[0] || result.data?.contacts[1]
            this.updateChatList()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    refreshConversation () {
      return this.$store.dispatch('conversations/refreshConversation', this.cConversation.id)
    },
    checkNewMessages () {
      if (this.cApiRoute && !this.loading) {
        this.loading = true
        this.$http.$api.get(`/conversations/${this.cConversation.id}/messages`)
          .then(result => {
            this.addMessageToCache(result.data?.messages || [])
            return this.$nextTick()
          })
          .then(() => {
            this.stickToBottom()
          })
          .finally(() => {
            this.loading = false
          })
          .then(() => Promise.all([
            this.markMessagesRead(),
            this.refreshConversation()
          ]))
      }
    },
    markMessagesRead () {
      if (this.cMessages.length > 0) {
        const lastMessageId = this.cMessages[this.cMessages.length - 1].id
        return this.$http.$api.patch(`/messages/${lastMessageId}`, { is_seen: true })
      } else {
        return Promise.resolve()
      }
    },
    addMessageToCache (messages) {
      for (const message of messages) {
        message.created_at = new Date(message.created_at)
        message.updated_at = new Date(message.updated_at)
        const idx = this.messageCashe.findIndex(el => el.id === message.id)
        if (idx > -1) {
          if (this.messageCashe[idx].updated_at - message.updated_at !== 0) {
            this.messageCashe.splice(idx, 1, message)
          }
        } else {
          this.messageCashe.push(message)
        }
      }
    },
    stickToBottom () {
      if (this.bottom && this.$refs.card && this.$refs.text) {
        this.$vuetify.goTo(this.$refs.text.clientHeight, {
          container: this.$refs.card
        })
      }
    },
    sendMessage () {
      if (this.cApiRoute && this.cMessageReady) {
        this.$http.$api.post(`${this.cApiRoute}?XDEBUG_SESSION_START`, this.newMessage.trim(), {
          headers: {
            'Content-Type': 'text/plain'
          }
        })
          .then(() => {
            this.newMessage = ''
            this.checkNewMessages()
          })
      }
    },
    ChangeTextFilter (value) {
      this.textFilter = value
    },
    insert (emoji) {
      this.newMessage += emoji
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.message-area {
  padding-top: 0;
  position: relative;
  .v-card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}

.container >.row {
  >.col:first-child {
    padding-top: 0;
  }
  > .col:last-child {
    padding-bottom: 0;
  }
}
.header-background{
  background: rgb(39, 37, 37)
}
.iconHeight{
  max-height: 40px;
  max-width: 40px;
}

</style>
