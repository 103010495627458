export default {
  picture: {
    label: 'Bild auswählen'
  },
  address: {
    street: {
      label: 'Strasse',
      required: 'Bitte gebe die Straße ein'
    },
    houseNumber: {
      label: 'Nummer',
      required: 'Bitte gebe die Hausnummer ein'
    },
    postalCode: {
      label: 'Postleitzahl',
      required: 'Bitte gebe die Postleitzahl ein'
    },
    city: {
      label: 'Stadt/Ort',
      required: 'Bitte gebe die Stadt/Ort ein'
    },
    country: {
      label: 'Land',
      required: 'Bitte wähle das Land aus'
    }
  },
  location: {
    position: 'Position',
    address: 'Addresse',
    noAddressSet: 'Keine Addresse angeeben',
    noGeolocationSet: 'Keine gültige Position angegeben',
    calculationHint: 'Du kannst entweder die Adresse oder den Standort festlegen.' +
      ' Beim Festlegen der Adresse wird der Standort berechnet und umgekehrt.'
  },
  timeValue: {
    availableBalance: 'Verfügbares Guthaben',
    maxValue: 'Ein Maximalwert von {0} ist zulässig.',
    rules: {
      balance: 'Der Wert darf Dein verfügbares Guthaben von {0} nicht überschreiten.',
      max: 'Der Wert darf {0} nicht überschreiten.',
      min: 'Der Wert muss größer als {0} sein.'
    }
  },
  groupScope: {
    default: {
      listCaption: '@.lower:(terms.group)',
      voidItemLabel: 'Keine'
    }
  }
}
