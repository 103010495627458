export default {
  'description': '@:(terms.description)',
  'details': {
    'membership': {
      'title': 'Membership',
      'sub-title-member': 'You are a member of this @.lower:(terms.group)',
      'sub-title-admin': 'You are an admin of this @.lower:(terms.group)',
      'sub-title-none': 'You are not a member of this @.lower:(terms.group)'
    },
    'group-id': {
      'title': 'ID #{0}',
      'sub-title': 'This is the identification number for this @.lower:(terms.group)'
    },
    'members': {
      'title': '{0} Members',
      'sub-title': 'Number of @.lower:(terms.player)s already active in this @.lower:(terms.group)'
    },
    'quests': {
      'title': '{0} open @:(terms.quests)',
      'sub-title': 'You can accomplish these @:(terms.quests) in the @.lower:(terms.group)'
    },
    'questsDone': {
      'title': '{0} accomplished @:(terms.quests)',
      'sub-title': 'These @:(terms.quests) have already been accomplished'
    },
    'deals': {
      'title': '{0} @:(terms.deals)',
      'sub-title': 'You can get these @:(terms.deals) in this @.lower:(terms.group)'
    }
  }
}
