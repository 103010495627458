import { http } from '@/plugins'
import { greatHaversineCircleDistance } from '@/utils/geo'

// 1000 ms * 60 s
const INTERVAL_DURATION = 1000 * 60
// meter
const SIGNIFICANT_DISTANCE = 250

export const position = {
  namespaced: true,
  state: () => ({
    current: {
      position: {
        latitude: null,
        longitude: null
      },
      timestamp: Infinity
    },
    persisted: {
      timestamp: 0,
      position: {
        latitude: null,
        longitude: null
      }
    },
    watch: {
      position: null,
      intervalCheck: null
    },
    loading: false
  }),
  getters: {
    loading: state => state.loading instanceof Promise,
    hasValidPosition: state => !isNaN(state.current.position.latitude) && !isNaN(state.current.position.longitude),
    timeSincePersisting: state => state.current.timestamp - state.persisted.timestamp
  },
  mutations: {
    setNewPosition (state, payload) {
      if (
        !isNaN(payload?.latitude) &&
        !isNaN(payload?.longitude)
      ) {
        state.current.position.latitude = payload.latitude
        state.current.position.longitude = payload.longitude
        state.current.timestamp = Date.now()
      }
    },
    clearPositionWatch (state) {
      if (state.watch.position) {
        navigator.geolocation.clearWatch(state.watch)
        state.watch.position = null
      }
    },
    setPositionWatch (state, watch) {
      state.watch.position = watch
    },
    clearStateWatch (state) {
      if (state.watch.intervalCheck) {
        state.watch.intervalCheck() // unwatch
        state.watch.intervalCheck = null
      }
    },
    setStateWatch (state, payload) {
      state.watch.intervalCheck = payload
    },
    setLoadingState (state, payload) {
      state.loading = !!payload
    },
    setPersisted (state, payload) {
      state.persisted = payload
    }
  },
  actions: {
    persistNewPosition: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        result => {
          commit('setNewPosition', result.coords)
          dispatch('persist')
            .then(() => resolve())
            .catch(err => reject(err))
        },
        err => reject(err)
      )
    }),
    persist ({ state, commit, getters, rootGetters }) {
      if (!getters.hasValidPosition) {
        return Promise.resolve()
      }

      const current = JSON.parse(JSON.stringify(state.current))

      if (state.loading === false) {
        const currentPlayerId = rootGetters['players/current/id']
        const promise = http.$api.put(`/players/${currentPlayerId}/position`, {
          ...state.current.position
        })
          .then(() => {
            commit('setPersisted', current)
          })
          .finally(() => {
            commit('setLoadingState', false)
          })
        commit('setLoadingState', promise)
      } else {
        return state.loading
      }
    },
    watchPosition ({ state, commit, dispatch }, payload = true) {
      commit('clearPositionWatch')
      commit('clearStateWatch')

      if (payload) {
        commit('setPositionWatch', navigator.geolocation.watchPosition(position => {
          commit('setNewPosition', position.coords)
        }))
        const unwatch = this.watch(
          (state, getters) => getters['players/current/position/timeSincePersisting'],
          timeSincePersisting => {
            if (timeSincePersisting > INTERVAL_DURATION) {
              const { latitude: lat1, longitude: lon1 } = state.current.position
              const { latitude: lat2, longitude: lon2 } = state.persisted.position
              const diff = greatHaversineCircleDistance(lat1, lon1, lat2, lon2)

              if (isNaN(diff) || diff > SIGNIFICANT_DISTANCE) {
                dispatch('persist')
              }
            }
          })
        commit('setStateWatch', unwatch)
      }
    }
  }
}
