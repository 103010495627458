export const MxTranslatable = {
  data: () => ({
    $_mx_translatable: {
      paths: [],
      params: {},
      basePath: 'components'
    }
  }),
  computed: {
    mx_translatable_c_name_cc () {
      return this.$options.name
        .split('-')
        .map(el => el.charAt(0).toUpperCase() + el.slice(1))
        .join('')
    },
    cText () {
      return this.mx_translatable_c_text
    },
    mx_translatable_c_text () {
      const result = {}
      const basePath = this.$data.$_mx_translatable.basePath
      const t = (path, params = []) => this.$te(`${basePath}.${this.$options.name}.${path}`)
        ? this.$t(`${basePath}.${this.$options.name}.${path}`, params)
        : this.$t(`${basePath}.${this.mx_translatable_c_name_cc}.${path}`, params)

      for (const path of this.$data.$_mx_translatable.paths) {
        if (this.$data.$_mx_translatable.params.hasOwnProperty(path)) {
          result[path] = t(path, this.$data.$_mx_translatable.params[path])
        } else {
          result[path] = t(path)
        }
      }

      return result
    }
  }
}
