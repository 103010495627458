export default {
  setNewPassword: {
    caption: 'Benötigst Du ein neues Passwort?',
    text: 'Bitte geben Sie das aktuelle Passwort ein, dann das neue Passwort und senden Sie das Passwort.',
    buttons: {
      cancel: 'Abbrechen',
      submit: 'Sende Passwort'
    }
  },
  fields: {
    currentPassword: {
      label: 'aktuelles Passwort'
    },
    password: {
      label: 'Neues Passwort'
    },
    password_confirmation: {
      label: 'Passwort bestätigen'
    }
  }
}
