<template>
  <v-dialog v-model="open">
    <widget-change-group-owner
        :group-id="$route.params.id"
        @close="close"
    />
  </v-dialog>
</template>

<script>
import WidgetChangeGroupOwner from '@/components/group/WidgetChangeGroupOwner'

export default {
  name: 'EinsStarmenuDlgChangeGroupOwner',
  components: {
    WidgetChangeGroupOwner
  },
  data: () => ({
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogChangeGroupOwner'] && this.cGroup
      },
      set (value) {
        this.$store.commit('dialogChangeGroupOwner', value)
      }
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    }
  },
  methods: {
    close () {
      this.open = false
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
