import { http } from '@/plugins'

export const groups = {
  namespaced: true,
  state: () => ({
    items: [],
    loading: false
  }),
  getters: {
    item: state => id => state.items.find(item => item.id === id),
    items: state => [].concat(state.items)
  },
  mutations: {
    items: (state, groups) => {
      groups = Array.isArray(groups) ? groups : [groups]

      for (const group of groups) {
        const index = state.items.findIndex(item => item.id === group.id)

        if (index > -1) {
          state.items.splice(index, 1, group)
        } else {
          state.items.push(group)
        }
      }
    },
    removeItems: (state, ids) => {
      for (let i = 0; i < state.items.length; i++) {
        if (ids.includes(state.items[i].id)) {
          state.items.splice(i, 1)
        }
      }
    },
    loading: (state, value) => {
      state.loading = value
    },
    clear: state => {
      state.items.splice(0, state.items.length)
    }
  },
  actions: {
    getData: ({ state, commit, rootGetters }) => new Promise((resolve, reject) => {
      commit('loading', true)
      let groupsToRemove = state.items.map(group => group.id)
      const getData = (page = 1) => http.$api.get(
        '/groups',
        {
          params: {
            player_id: rootGetters['players/current/id'],
            no_distance: true,
            page: page
          }
        }
      )
        .then(response => {
          const groups = response.data?.groups ?? []
          commit('items', groups)
          const newGroupIds = groups.map(group => group.id)
          groupsToRemove = groupsToRemove.filter(id => !newGroupIds.includes(id))

          const { page, limit, count_filtered: countFiltered } = response.data ?? {}
          if (
            !isNaN(page) &&
            !isNaN(limit) &&
            !isNaN(countFiltered) &&
            page * limit < countFiltered
          ) {
            return getData(page + 1)
          } else {
            return true
          }
        })

      getData()
        .then(() => {
          commit('removeItems', groupsToRemove)
          resolve()
        })
        .catch(e => {
          commit('clear')
          reject(e)
        })
        .finally(() => {
          commit('loading', false)
        })
    })
  }
}
