<template>
  <eins-input-toolbar-extension
      v-model="textFilter"
      class="mt-3"
      :label="cLabel"
      :filter-options="cFilterOptions"
      :filter-value="cCurrentQuestFilter"
      object-type="quest"
      @input="onChangeTextFilter"
      @update:filter="onUpdateFilter"
  >
    <template #icon>
      <eins-icon-quests
          active
          width="26"
          class="mr-1"
      />
    </template>
  </eins-input-toolbar-extension>
</template>

<script>
import EinsIconQuests from '@/components/icons/EinsIconQuests'
import EinsInputToolbarExtension from '@/components/input/EinsInputToolbarExtension'
import { arrayContentIsIdentical } from '@/utils/object'

import {
  Filter,
  QuestType
} from '@/constants'

const QUEST_TYPES = [
  QuestType.COMMON,
  QuestType.GREEN,
  QuestType.PRIVATE
]

export default {
  name: 'ViewQuestListToolbarExtension',
  components: {
    EinsInputToolbarExtension,
    EinsIconQuests
  },
  data: () => ({
    textFilter: ''
  }),
  computed: {
    cCurrentFilter () {
      return this.$store.getters['quests/filter']
    },
    cCurrentQuestFilter () {
      return []
        .concat((this.cCurrentFilter.scopes ?? []).map(scope => `scope.${scope}`))
        .concat((this.cCurrentFilter.types ?? []).map(type => `type.${type}`))
    },
    cLabel () {
      return this.$t('terms.quests')
    },
    cFilterOptions () {
      const result = []

      for (const type of QUEST_TYPES) {
        result.push({
          title: this.$t(`quest.type.${type}.title`),
          value: `type.${type}`
        })
      }
      for (const scope of Filter.SCOPES) {
        result.push({
          title: this.$t(`common.filter.scope.${scope}.title`),
          value: `scope.${scope}`,
          invertedAll: true
        })
      }

      for (const option of result) {
        switch (option.value) {
          case 'scope.contacts':
            option.icon = 'mdi-account-switch-outline'
            break
          case 'scope.groups':
            option.icon = 'mdi-google-circles-communities'
            break
          case 'type.green':
            option.icon = 'mdi-map-marker-up'
            break
          case 'type.common':
            option.icon = 'mdi-map-marker-star'
            break
          case 'type.private':
            option.icon = 'mdi-map-marker-left'
            break
        }
      }

      return result
    }
  },
  mounted () {
    this.textFilter = this.$store.getters['quests/search']
  },
  methods: {
    onChangeTextFilter () {
      this.$store.dispatch('quests/searchDelayed', this.textFilter)
    },
    onUpdateFilter (value) {
      if (!arrayContentIsIdentical(value, this.cCurrentQuestFilter)) {
        let scopes = value
          .filter(option => option.includes('scope.'))
          .map(option => option.replace('scope.', ''))
        scopes = scopes.length > 0 ? scopes : null

        let types = value
          .filter(option => option.includes('type.'))
          .map(option => option.replace('type.', ''))
        types = types.length > 0 ? types : null
        this.setFilter({ scopes, types })
      }
    },
    setFilter (filter) {
      this.$store.dispatch('quests/filter', filter)
    }
  }
}
</script>

<style scoped>
</style>
