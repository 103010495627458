export default {
  reset: {
    label: 'Zurücksetzen'
  },
  submit: {
    label: 'Anmelden'
  },
  error: {
    invalid: 'Dein Passwort ist ungültig!',
    rate: 'Zu viele Versuche. Bitte später erneut versuchen.',
    email: 'Deine @.lower:(terms.player) E-Mail-Adresse ist nicht registriert',
    unknown: 'Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später erneut.'
  },
  resetPassword: {
    label: 'Zurücksetzen des @.lower:(terms.player) Passworts'
  },
  Google: {
    label: 'Anmelden mit Google'
  },
  Apple: {
    label: 'Anmelden mit Apple'
  },
  Register: {
    label: 'Registrieren eines neuen @.lower:(terms.player)'
  }
}
