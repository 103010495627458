import {
  createListModule,
  createRelatedEntityListModule,
  createSelectedEntityModule
} from '@/plugins/store/factories'
import { DealState } from '@/constants'

const basePath = '/deals'

export const deals = createListModule({
  basePath,
  baseEntity: 'deals',
  getParams: () => ({ state: DealState.OPEN }),
  modules: {
    selected: createSelectedEntityModule({
      basePath,
      getterName: 'deal',
      modules: {
        applications: createRelatedEntityListModule({
          getParams: (getters) => ({
            no_distance: true
          }),
          getPath: (getters) => `/deals/${getters['deals/selected/deal'].id}/applications`,
          baseEntity: 'applications'
        })
      }
    })
  }
})
