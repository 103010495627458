<template>
  <div
      style="height: 100%; width:100%"
      class="mt-2 d-flex flex-column"
  >
    <v-btn
        fab
        absolute
        depressed
        elevation="0"
        style="right: -0.75em; top: -0.75em;"
        color="secondary"
        @click="$router.go(-1)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="flex-shrink-1">
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <slot
          name="information"
          style="padding-right: 3.25em;"
      />
      <v-sheet
          tile
          style="position: relative; overflow: hidden;"
      >
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <slot name="image" />
      </v-sheet>
      <v-tabs
          v-model="tab"
          background-color="background"
          height="36"
          fixed-tabs
          :slider-color="cTabClass.activeColor"
          :active-class="cTabClass.activeClass"
          onscroll="onScroll"
          show-arrows
      >
        <v-tab
            v-for="tab in tabs"
            :key="`tab_#${tab.value}`"
            :class="cTabClass.class"
            :href="`#${tab.value}`"
            @click="onClickTab"
        >
          <v-icon :class="tab.value">{{ tab.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <v-divider />
    <div
        style="position: relative;"
        class="flex-grow-1"
    >
      <v-tabs-items
          v-model="tab"
          style="position:absolute;left: 0; right: 0; top: 0; bottom: 0;"
          touchless
      >
        <v-tab-item
            v-for="tab in tabs"
            :key="`tab-item_#${tab.value}`"
            v-scroll.self="onScroll"
            :value="tab.value"
            style="position: relative;"
        >
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <slot :name="`tab-${tab.value}`" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EinsLayoutItemDetailContent',
  props: {
    objectType: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => [],
      validator: (val) => {
        let result = true

        for (const tab of val) {
          result = result &&
              tab.hasOwnProperty('value') &&
              tab.hasOwnProperty('icon')
        }

        return result
      }
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    tab: ''
  }),
  computed: {
    cKnownTabs () {
      return this.tabs.map(el => el.value)
    },
    cTabClass () {
      return {
        class: this.objectType === '' ? '' : `${this.objectType}-tab`,
        activeClass: this.objectType === '' ? 'primary--text' : `${this.objectType}--text`,
        color: this.objectType === '' ? 'secondary' : `${this.objectType}off`,
        activeColor: this.objectType === '' ? 'primary' : `${this.objectType}`
      }
    }
  },
  watch: {
    tab (value) {
      if ((this.$route.hash || '').replace(/^#/, '') !== value) {
        this.$router.replace({ hash: `#${value}` })
      }

      this.emitModel()
    },
    value: 'setValue'
  },
  created () {
    const hash = (this.$route.hash || '').replace(/^#/, '')
    if (this.cKnownTabs.indexOf(hash) > -1) {
      // prefer value from url-hash if present
      this.tab = hash
      this.emitModel()
    } else {
      if (
        typeof this.value === 'string' &&
          this.value.length > 0
      ) {
        // use model-value if present
        this.setValue(this.value)
      } else {
        if (this.cKnownTabs.length > 0) {
          // use first known tab if present
          this.tab = this.cKnownTabs[0]
        } else {
          // use empty string else
          this.tab = ''
        }
        this.emitModel()
      }
    }
  },
  methods: {
    setValue (value) {
      this.tab = value
    },
    emitModel () {
      this.$emit('input', this.tab)
    },
    onScroll (e) {
      const target = e.target
      const scrollTop = target.scrollTop
      const scrollArea = target.scrollHeight - target.clientHeight
      const factor = Math.round(100 - (scrollTop / scrollArea * 100))
      this.$emit('oncontainerscroll', factor)
    },
    onClickTab () {
      this.$emit('oncontainerscroll', 0)
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.v-tabs-items {
  ::v-deep {
    > .v-window__container {
      &, & > .v-window-item {
        height: 100%;
        max-height: 100%;
      }

      & > .v-window-item {
        overflow-y: auto;
      }
    }
  }
}
.v-tab--active {
  &.group-data-tab {
    & > .members {
      color: var(--v-player-base) !important;
    }
    & > .quests {
      color: var(--v-quest-base) !important;
    }
    & > .deals {
      color: var(--v-deal-base) !important;
    }
  }
  &.player-data-tab {
    & > .groups {
      color: var(--v-group-base) !important;
    }
    & > .quests {
      color: var(--v-quest-base) !important;
    }
    & > .deals {
      color: var(--v-deal-base) !important;
    }
  }
}

.v-tab:not(.v-tab--active) {
  &.quest-tab {
    & > .v-icon {
      color: var(--v-questoff-base) !important;
    }
  }
  &.deal-tab {
    & > .v-icon {
      color: var(--v-dealoff-base) !important;
    }
  }
  &.group-tab {
    & > .v-icon {
      color: var(--v-groupoff-base) !important;
    }
  }
  &.group-data-tab {
    & > .members {
      color: var(--v-playeroff-base) !important;
    }
    & > .quests {
      color: var(--v-questoff-base) !important;
    }
    & > .deals {
      color: var(--v-dealoff-base) !important;
    }
  }
  &.player-data-tab {
    & > .groups {
      color: var(--v-groupoff-base) !important;
    }
    & > .quests {
      color: var(--v-questoff-base) !important;
    }
    & > .deals {
      color: var(--v-dealoff-base) !important;
    }
  }
}
/*
.v-tab--active {
  & > .v-icon {
    color: var(--v-quest-base)
  }
}*/
</style>
