import Vue from 'vue'

import {
  http
} from '@/plugins/http'

export const conversations = {
  namespaced: true,
  state: {
    limit: 25,
    page: 1,
    userId: '',
    count: '',
    cache: [],
    result: null,
    loading: false,
    reachedEnd: false,
    textFilter: ''
  },
  getters: {
    list: state => state.cache,
    count: state => state.count,
    user: state => state.userId,
    listLoading: state => state.loading,
    listEnd: state => state.reachedEnd,
    textFilter: state => state.textFilter
  },
  mutations: {
    invalidate (state) {
      Vue.set(state, 'result', null)
    },
    addToCache (state, chats) {
      if (!Array.isArray(chats)) {
        chats = [ chats ]
      }

      for (const chat of chats) {
        chat.created_at = new Date(chat.created_at)
        chat.updated_at = new Date(chat.updated_at)
        const idx = state.cache.findIndex(el => el.id === chat.id)
        if (idx > -1) {
          const oldData = state.cache[idx]
          if (
            oldData.updated_at - chat?.updated_at !== 0 ||
            oldData.distance !== chat.distance
          ) {
            state.cache.splice(idx, 1, chat)
          }
        } else {
          state.cache.push(chat)
        }
      }

      state.cache.sort((a, b) => b.updated_at.getTime() - a.updated_at.getTime())
    },
    clearCache (state, payload) {
      Vue.set(state, 'cache', [])
    },
    textFilter (state, payload) {
      state.textFilter = payload
    },
    clearTextFilter (state) {
      state.textFilter = ''
    }
  },
  actions: {
    getData ({ state, commit }) {
      state.loading = true
      return http.$api.get('/conversations', { params: { limit: state.limit, page: state.page } })
        .then(response => {
          commit('addToCache', response.data?.conversations || [])
          state.limit = response.data?.limit || 10
          state.page = response.data?.page || 1
          state.count = response.data?.count || 1
          state.userId = response.data?.user || ''
          state.reachedEnd = response.data.count + (response.data.page - 1) * response.data.limit >= response.data.count_total
          return response
        })
        .catch(() => {
          commit('clearCache')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ state, dispatch }) {
      state.page = 1
      return dispatch('getData')
    },
    loadMore ({ state, dispatch }) {
      state.page = state.page + 1
      return dispatch('getData')
    },
    findConversation ({ state, commit, dispatch, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const conversation = getters.list.find(el =>
          el.contacts.length === 1 &&
          el.contacts.findIndex(el =>
            el.type === 'player' &&
            el.id === payload
          ) > -1
        )

        if (conversation) {
          resolve(conversation.id)
        } else {
          http.$api.get('/conversations', {
            params: {
              limit: 1,
              conversation_partner_id: payload
            }
          })
            .then(response => {
              commit('addToCache', response?.data?.conversations || [])

              if (response.data?.conversations?.length > 0) {
                resolve(response.data?.conversations[0].id)
              } else {
                reject(new Error(`No existing conversation with player '${payload}' found`))
              }

              return response
            })
        }
      })
    },
    refreshConversation ({ state, commit, dispatch, getters, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        http.$api.get(`/conversations/${payload}`)
          .then(response => {
            if (response?.data) {
              commit('addToCache', response.data)
            }
            return response
          })
      })
    }
  },
  modules: {
  }
}
