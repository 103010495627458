<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-map-marker"
      :text="cText.text"
  >
    <template #icon>
      <v-icon
          left
          color="primary"
      >mdi-crown</v-icon>
    </template>
  </eins-starmenu-btn>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbQuestsSolved',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute && (this.cPlayerIsCurrentPlayer || this.cGroupIsCurrentPlayer)
    },
    cPlayerRoute () {
      return this.$route.name === 'player-quests-closed'
    },
    cPlayerIsCurrentPlayer () {
      return this.$store.getters['players/selected/player']?.id === this.$store.getters['players/current/id']
    },
    cGroupIsCurrentPlayer () {
      return this.$store.getters['groups/selected/player']?.id === this.$store.getters['groups/current/id']
    },
    cTarget () {
      if (this.cShowBtn && this.cPlayerRoute) {
        return `${this.$route.matched[0].path}/${this.$route.params.id}/quests/closed`
      } else {
        return `${this.$route.matched[0].path}/${this.$route.params.id}/quests/closed`
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
