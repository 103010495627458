export default {
  priv: {
    title: 'Initiative',
    description: 'Freiraum für private Projekte, Initiativen, Freunde, Familien und Nachbarschaften. Keine Rechtsform',
    ServicePackage: 'keine zusätzlichen Anforderungen oder Verifikation erforderlich',
    price: ''
  },
  ngo: {
    title: '1NGO Association',
    description: '@.lower:(terms.group) für Vereine, Sportvereine, NGOs und Hilfsorganisationen kostenlos, wenn die juristische Person ist eine gemeinnützige Organisation ist (Nachweis kann angefordert werden, keine Verbände oder gGmbHs).',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1NGO) einschließlich des folgenden Service-Pakets',
    price: ''
  },
  edu: {
    title: '1EDU Bildung',
    description: '@.lower:(terms.group) für Schulen, Kindergärten, Bildungseinrichtungen, Universitäten und Hochschulen. Juristische Person ist eine registrierte Bildungseinrichtung.',
    ServicePackage: 'This kind of @.lower:(terms.group) (1EDU) including the following service-package',
    price: ''
  },
  gov: {
    title: '1GOV Verwaltung',
    description: '@.lower:(terms.group) für Einrichtungen der öffentlichen Verwaltung, Ministerien, Städte, Kreise, Bundesländer, Länder und Anstalten des öffentlichen Rechts. Gebührenpflichtig. Juristische Person ist eine Körperschaft des öffentlichen Rechts',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1GOV) einschließlich des folgenden Servicepakets',
    price: ''
  },
  com_a: {
    title: '1COM-A Startup',
    description: '@.lower:(terms.group) für Startups, kleine Unternehmen bis ~ 20 Mitarbeiter, Selbständige. Juristische Person ist ein Unternehmen.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1COM-A) enthält das folgende Service-Paket',
    price: ''
  },
  com_b: {
    title: '1COM-B Geschäft/Unternehmen',
    description: '@.lower:(terms.group) für mittelständische Unternehmen mit mehreren Mitarbeitern. Juristische Person ist ein Unternehmen.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1COM-B) enthält das folgende Service-Paket',
    price: ''
  },
  com_c: {
    title: '1COM-C Geschäft/Unternehmen PRO',
    description: '@.lower:(terms.group) für große Unternehmen. Juristische Person ist ein Unternehmen.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1COM-C) enthält das folgende Service-Paket',
    price: ''
  },
  cus: {
    title: '1CUS Kunden',
    description: '@.lower:(terms.group) für Unternehmen, die ihren Kunden ein separates Feld anbieten möchten, um Angebote des Unternehmens zu veröffentlichen. Juristische Person ist ein Unternehmen.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1CUS) einschließlich des folgenden Servicepakets',
    price: ''
  },
  inf: {
    title: '1INF Information',
    description: '@.lower:(terms.group) für Unternehmen, die ein extra Feld bereitstellen möchten, um Informationen aller Art an Kunden, Lieferanten, Mitarbeiter oder diverse andere Akteure zu versenden. Juristische Person ist ein Unternehmen.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1INF) einschließlich des folgenden Servicepakets',
    price: ''
  },
  b2b: {
    title: '1B2B Unternehmens',
    description: '@.lower:(terms.group) für Unternehmen, die ihren Geschäftspartnern ein zusätzliches Feld anbieten möchten, um geschäftliche 1APP-Aktivitäten abzuwickeln.',
    ServicePackage: 'Diese Art von @.lower:(terms.group) (1B2B) einschließlich des folgenden Servicepakets',
    price: ''
  },
  metaType: {
    NGO: {
      label: 'NGO'
    },
    COM: {
      label: 'COM'
    },
    FREE: {
      label: 'FREE'
    },
    PUBLIC: {
      label: 'PUBLIC'
    }
  }
}
