export default {
  changeOwnership: {
    title: 'Change ownership',
    text: 'You are about to change the ownership for your  @.lower:(terms.group) {0}.' +
        ' Please select one of your admins to take over your leadership.',
    textLeaveAndChange: 'You are about to leave your  @.lower:(terms.group) {0}.' +
        ' Since you are still owner of the  @.lower:(terms.group) you need to transfer ownership first.',
    select: {
      label: 'New owner',
      noData: 'No admins found'
    },
    success: 'Ownership of {0} was successfully transferred to {1}.',
    error: {
      forbidden: 'You dont have the permission to change the  @.lower:(terms.group) owner.',
      unknown: 'Unknown error. This should not have happened.'
    },
    buttons: {
      submit: 'Transfer ownership',
      cancel: 'Cancel',
      close: 'Close'
    }
  },
  delete: {
    dialog: {
      title: 'Delete @.lower:(terms.group)',
      text: {
        main: 'You are about to irreversibly delete your @.lower:(terms.group) \'{0}\'!',
        note1: 'All members will be removed',
        note2: 'Unfinished @.lower:(terms.quests) and @.lower:(terms.deals) will be deleted',
        note3: 'All links to the @.lower:(terms.group) will be unset',
        note4: 'The @.lower:(terms.group) can no longer be found'
      },
      warning: {
        title: 'Warning!',
        text: 'This action can not be revoked!' +
            ' Some @.lower:(terms.players) might loose their progress for ongoing @.lower:(terms.quests)!'
      },
      success: {
        title: '@.lower:(terms.group) deleted',
        text: 'The @.lower:(terms.group) has been deleted and will no longer be found!'
      },
      buttons: {
        delete: 'Delete @.lower:(terms.group)',
        deleteFinally: 'Yes im sure, delete the @.lower:(terms.group)!',
        cancel: 'Better not...',
        close: 'Close'
      }
    }
  },
  update: {
    title: {
      dialogTitle: 'Change @.lower:(terms.group) title',
      actions: {
        submit: 'Change title'
      },
      success: {
        title: 'Success!',
        text: 'The title of your @.lower:(terms.group) was changed from \'{0}\' to \'{1}\'.'
      },
      title: {
        label: 'New title',
        required: 'Please enter a new title',
        min: 'The title needs to have a length of at least 3',
        unchanged: 'The new title is not different from the current title.'
      }
    },
    picture: {
      dialogTitle: 'Upload picture',
      success: {
        title: 'Success!',
        text: 'Your @.lower:(terms.group) title picture was set.',
        bonus: 'Upload successfull! you got 2 points.'
      },
      actions: {
        submit: 'Upload',
        setPicture: 'Set picture'
      }
    },
    description: {
      dialogTitle: 'Change @.lower:(terms.group) @.lower:(terms.description)',
      actions: {
        submit: 'Change @.lower:(terms.description)'
      },
      success: {
        title: 'Success!',
        text: 'The @.lower:(terms.description) of your @.lower:(terms.group) has been updated.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'The length of the @.lower:(terms.description) must not exceed 500 letters.'
      }
    },
    contactInformation: {
      title: 'Contact information',
      explanation: 'Contact person of the @.lower:(terms.group).' +
          ' Changing only at your icigai1 @.lower:(terms.group) account',
      empty: 'No contact information found'
    },
    email: {
      title: 'Email Address',
      explanation: 'Your @.lower:(terms.group) eMail can only changed at your icigai1 @.lower:(terms.group) account',
      empty: 'No email found'
    },
    hiddenQuests: {
      label: 'Hide @.lower:(terms.quest) in chronicle',
      explanation: 'Changes to this option will not affect existing @.lower:(terms.quests)!' +
        ' Enable this option to hide all future @.lower:(terms.quests) from chronicle.' +
        ' You can still show specific @.lower:(terms.quests) in chronicle if you like.'
    },
    location: {
      address: {
        dialogTitle: 'Set address',
        success: {
          title: 'Address updated',
          text: 'The @.lower:(terms.group)-address has been updated, we will now recalculate the map position.'
        },
        actions: {
          submit: 'Update address'
        }
      },
      position: {
        success: {
          title: 'Position updated',
          text: 'The @.lower:(terms.group)-position has been updated, we will now recalculate the address.'
        }
      }
    }
  }
}
