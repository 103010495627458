import {
  current
} from '@/plugins/store/players/current'

import {
  createListModule,
  createRelatedEntityListModule,
  createSelectedEntityModule
} from '@/plugins/store/factories'
import { DealState, FriendshipState, QuestState } from '@/constants'

const basePath = '/players'

export const players = createListModule({
  basePath,
  baseEntity: 'players',
  modules: {
    current,
    selected: createSelectedEntityModule({
      basePath,
      getterName: 'player',
      modules: {
        blockages: createRelatedEntityListModule({
          getParams: (getters) => ({
          }),
          getPath: () => `/blockages`,
          baseEntity: 'blockages'
        }),
        contacts: createRelatedEntityListModule({
          getParams: (getters) => ({
            player_id: getters['players/selected/player'].id,
            friendship_state: FriendshipState.ANY,
            no_distance: true
          }),
          getPath: () => `/players`,
          baseEntity: 'players'
        }),
        // on every profile
        // 'Deals the player is providing'
        deals: createRelatedEntityListModule({
          getParams: (getters) => ({
            player_id: getters['players/selected/player'].id,
            state: [DealState.OPEN],
            no_distance: true
          }),
          getPath: () => `/deals`,
          baseEntity: 'deals'
        }),
        // only own profile
        // 'Deals I'm participating on - currently'
        dealsRelated: createRelatedEntityListModule({
          getParams: (getters) => {
            let isMyProfile = getters['players/selected/player']?.id === getters['players/current/id']
            let paramName = isMyProfile ? 'participant_id' : 'player_id'

            return {
              [paramName]: getters['players/selected/player']?.id,
              state: [DealState.OPEN, DealState.ASSIGNED, DealState.CONFIRMED],
              no_distance: true
            }
          },
          getPath: () => `/deals`,
          baseEntity: 'deals'
        }),
        // only for current player:
        // 'Deals I have participated on'
        dealsEnded: createRelatedEntityListModule({
          getParams: (getters) => {
            return {
              participant_id: getters['players/selected/player']?.id,
              state: [DealState.ENDED],
              hard_participation: true,
              no_distance: true
            }
          },
          getPath: () => `/deals`,
          baseEntity: 'deals'
        }),
        // on every profile
        // 'Quests the player is providing'
        quests: createRelatedEntityListModule({
          getParams: (getters) => ({
            player_id: getters['players/selected/player']?.id,
            state: QuestState.OPEN,
            no_distance: true
          }),
          getPath: () => `/quests`,
          baseEntity: 'quests'
        }),
        // only own profile
        // 'Quests I'm participating on - currently'
        questsRelated: createRelatedEntityListModule({
          getParams: (getters) => {
            let isMyProfile = getters['players/selected/player']?.id === getters['players/current/id']
            let paramName = isMyProfile ? 'participant_id' : 'player_id'

            return {
              [paramName]: getters['players/selected/player']?.id,
              state: [QuestState.OPEN, QuestState.ASSIGNED, QuestState.STARTED, QuestState.FINISHED],
              no_distance: true
            }
          },
          getPath: () => `/quests`,
          baseEntity: 'quests'
        }),
        // only for current player:
        // 'Quests I have participated on'
        questsFinished: createRelatedEntityListModule({
          getParams: (getters) => {
            let isMyProfile = getters['players/selected/player'] === getters['players/current/id']
            let paramName = isMyProfile ? 'player_id' : 'participant_id'

            return {
              [paramName]: getters['players/selected/player']?.id,
              state: QuestState.CLOSED,
              no_distance: true
            }
          },
          getPath: () => `/quests`,
          baseEntity: 'quests'
        }),
        groups: createRelatedEntityListModule({
          getParams: (getters) => ({
            player_id: getters['players/selected/player'].id,
            no_distance: true
          }),
          getPath: () => `/groups`,
          baseEntity: 'groups'
        })
      }
    })
  }
})
