<script>
export default {
  name: 'EinsLocaleName',
  props: {
    locale: {
      type: String,
      default: null
    },
    current: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cLocale () {
      return this.current ? this.$i18n.locale : this.locale
    },
    cLocaleNameLocalized () {
      return this.$t(`locales.${this.cLocale}`)
    },
    cLocaleNameNative () {
      return this.$t(`locales.${this.cLocale}`, this.cLocale)
    }
  },
  render () {
    return this.$scopedSlots.default({
      native: this.cLocaleNameNative,
      localized: this.cLocaleNameLocalized
    })
  }
}
</script>

<style scoped>
</style>
