<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-content-copy"
      :to="cTo"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuAbCopy',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    },
    cTo () {
      let res
      let object
      switch (this.$route.name) {
        case 'deal-detail' :
          object = this.$store.getters['deals/selected/deal']
          res = '/deals/create?type=' + object.type + '&template=' + object.id.toString()
          break
        case 'quest-detail':
          object = this.$store.getters['quests/selected/quest']
          res = '/quests/create?type=' + object.type + '&template=' + object.id.toString()
          break
        default:
          return null
      }
      return res
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
