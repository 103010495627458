<template>
  <eins-image-input-dialog
      ref="input"
      :hide-input="success"
      :persistent="loading"
      :value="internalValue"
      @input="onUpdateDialogModel"
  >
    <template #title>
      {{ dialogTitle }}
    </template>
    <template #default="{ ready }">
      <v-alert
          text
          tile
          type="success"
          transition="expand-transition"
          :value="success"
      >
        <div class="font-weight-bold">
          {{ successMessage.title }}
        </div>
        <div>
          {{ successMessage.text }}
        </div>
      </v-alert>
      <v-expand-transition>
        <v-card-actions
            v-if="!success"
            class="flex-column"
        >
          <v-btn
              block
              color="primary"
              :disabled="!ready"
              :loading="loading"
              @click="onClickSubmit"
          >{{ submitLabel }}</v-btn>
          <v-btn
              block
              class="mx-0 mt-2"
              outlined
              :disabled="loading"
              @click="onClickCancel"
          ><i18n path="msc.actions.cancel" /></v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <eins-btn-timed
              block
              color="primary"
              outlined
              :disabled="loading"
              @click="onClickClose"
          >
            <i18n path="msc.actions.close" />
          </eins-btn-timed>
        </v-card-actions>
      </v-expand-transition>
    </template>
  </eins-image-input-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import EinsImageInputDialog from '@/components/input/EinsImageInputDialog'

import { HttpHeader } from '@/constants'
import { objectHasOwnProperty } from '@/utils/object'

export default {
  name: 'EinsEditPictureDialog',
  components: {
    EinsImageInputDialog,
    EinsBtnTimed
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiPath: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    successMessage: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'title') &&
        typeof value.title === 'string' &&
        objectHasOwnProperty(value, 'text') &&
        typeof value.text === 'string'
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    internalValue: false,
    success: false,
    loading: false
  }),
  computed: {
    cUserId () {
      return this.$store.getters['players/current/id']
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.setInternalValue(value)
    },
    onUpdateDialogModel (value) {
      this.setInternalValue(value)
      this.updateModel(value)
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.close()
    },
    onClickClose () {
      this.close()
    },
    close () {
      this.setInternalValue(false)
      this.updateModel(false)
    },
    reset () {
      this.success = false
    },
    submit () {
      this.loading = true
      this.$refs.input.getCroppedPicture()
        .then(picture => this.$http.$api.patch(
          this.apiPath,
          picture,
          { headers: { [HttpHeader.CONTENT_TYPE]: picture.type } }
        ))
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    setInternalValue (value) {
      if (value !== this.internalValue) {
        this.reset()
      }
      this.internalValue = value
    },
    updateModel () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
