// FRIENDSHIP...
export const WEIGHT_FRIENDSHIP = 12
export const VALUE_FRIENDSHIP_NONE = 0
export const VALUE_FRIENDSHIP_FOLLOWER = 2
export const VALUE_FRIENDSHIP_IDOL = 3
export const VALUE_FRIENDSHIP_FRIEND = 7

// FRIENDSHIP
export const WEIGHT_MEMBERSHIP = 10
export const VALUE_MEMBERSHIP_NO_GROUP_QUEST = -0.1
export const VALUE_MEMBERSHIP_NONE = 3
export const VALUE_MEMBERSHIP_MEMBER = 5

export const WEIGHT_DISTANCE = -0.75
export const WEIGHT_AGE = -0.05
