<template>
  <v-container class="pa-0 fill-height">
    <!-- :options="{ zoomControl: false, dragging: false }" -->
    <l-map
        v-if="cCanShowMap"
        ref="map"
        :center="centerInternal"
        :zoom="zoomInternal"
        :options="{ zoomControl: true }"
        zoom-snap="0.25"
        class="map"
        style="width: 100%; height: 100%;"
        @ready="onMapReady"
        @update:zoom="onUpdateZoom"
        @update:center="onUpdateCenter"
        @update:bounds="onUpdateBounds"
    >
      <l-tile-layer
          :attribution="cSource.attribution"
          :url="cSource.url"
      />
      <l-marker
          :lat-lng="cPlayerMarker.position"
      >
        <l-icon>
          <img
              width="24px"
              height="24px"
              :src="cPlayerMarker.icon"
              alt=""
          />
        </l-icon>
      </l-marker>
      <l-marker
          v-for="marker in cMarkers"
          :key="`${marker.latitude}:${marker.longitude}`"
          :lat-lng="{ lat: marker.latitude, lon: marker.longitude }"
      >
        <l-icon>
          <img
              width="36px"
              height="36px"
              :src="cMarkerIcon"
              alt=""
          />
        </l-icon>
        <l-popup class="pa-0">
          <div
              v-for="object in marker.objects"
              :key="object.id"
              class="pa-0"
              @click="switchTo(object.id)"
          >
            <eins-api-image
                v-if="object.title_picture_feed"
                #default="{ src }"
                :src="object.title_picture_feed"
            >
              <v-img
                  :src="src"
                  aspect-ratio="1"
                  width="48px"
                  style="margin-left: auto;margin-right: auto"
              >
                <template #placeholder>
                  <v-skeleton-loader
                      type="image"
                  />
                </template>
              </v-img>
            </eins-api-image>
            <span class="text-black">{{ object.title }}</span>
          </div>
        </l-popup>
      </l-marker>
      <l-control position="topright">
        <slot name="buttons">
        </slot>
      </l-control>
    </l-map>

    <div style="position:absolute; z-index:2;bottom:95px;right:0;">
      <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="onClickCenterMapOnPlayer"
      >
        <img
            width="22px"
            height="22px"
            :src="cPlayerMarker.icon"
            alt=""
        />
      </v-btn>
    </div>
    <slot></slot>
  </v-container>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControl
} from 'vue2-leaflet'

import {
  Map, Entity
} from '@/constants'
import EinsApiImage from '../EinsApiImage'
import { isValidGeolocation } from '@/utils/geo'

export default {
  name: 'EinsMapMultiObjects',
  components: {
    EinsApiImage,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControl
  },
  props: {
    hideMarker: {
      type: Boolean,
      default: false
    },
    mapClass: {
      type: [ Array, Object, String ],
      default: ''
    },
    objects: {
      type: Array,
      required: true
    },
    objectType: {
      type: String,
      default: Entity.GROUP
    }
  },
  data: () => ({
    zoomInternal: 10,
    centerInternal: {
      lat: null,
      lng: null
    },
    playerLocation: {
      latitude: null,
      longitude: null
    },
    boundsInternal: null,
    options: {}
  }),
  computed: {
    cPlayerLocation () {
      if (isValidGeolocation(this.playerLocation)) {
        return this.playerLocation
      } else {
        return {
          latitude: Map.CENTER_DEFAULT_LAT,
          longitude: Map.CENTER_DEFAULT_LAT
        }
      }
    },
    cCanShowMap () {
      return this.centerInternal.lat !== null &&
        !isNaN(this.centerInternal.lat) &&
        this.centerInternal.lng !== null &&
        !isNaN(this.centerInternal.lng)
    },
    cMapClass () {
      return []
    },
    cMapCenter () {
      if (
        this.playerLocation.lat !== null && this.playerLocation.lng !== null &&
          !isNaN(this.centerInternal.lat) && !isNaN(this.centerInternal.lng)
      ) {
        return this.playerLocation
      } else {
        return {
          lat: Map.CENTER_DEFAULT_LAT,
          lng: Map.CENTER_DEFAULT_LNG
        }
      }
    },
    cPlayerMarker () {
      return {
        position: {
          lat: this.cPlayerLocation.latitude,
          lng: this.cPlayerLocation.longitude
        },
        icon: require('@/assets/img/icons/ui/account-box-only-map.png')
      }
    },
    cMarkers () {
      return this.objects.reduce((markers, obj) => {
        const marker = markers.find(marker => Math.abs(marker.latitude - obj.latitude) < 0.000001 &&
            Math.abs(marker.longitude - obj.longitude) < 0.000001)

        if (!marker) {
          markers.push({
            latitude: obj.latitude,
            longitude: obj.longitude,
            objects: [
              obj
            ]
          })
        } else {
          marker.objects.push(obj)
        }

        return markers
      }, [])
    },
    cMarkerIcon () {
      switch (this.objectType) {
        case Entity.GROUP:
          return require('@/assets/img/icons/ui/groups_active.png')
        case Entity.DEAL:
          return require('@/assets/img/icons/ui/deals_active.png')
        case Entity.QUEST:
          return require('@/assets/img/icons/ui/quests_active.png')
        default:
          return ''
      }
    },
    cSource () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy;<a class="copy" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      }
    },
    cPositionIsValid () {
      return !isNaN(this.centerInternal.lat) &&
          !isNaN(this.centerInternal.lng) &&
          this.centerInternal.lat !== null &&
          this.centerInternal.lng !== null
    }
  },
  created () {
    this.getPlayerLocation()
    this.centerMapOnPlayer()
  },
  methods: {
    onClickCenterMapOnPlayer () {
      this.centerMapOnPlayer()
    },
    centerMapOnPlayer () {
      this.setCenter(this.cPlayerLocation.latitude, this.cPlayerLocation.longitude)
    },
    getPlayerLocation () {
      this.playerLocation.latitude = this.$store.getters['players/current/latitude']
      this.playerLocation.longitude = this.$store.getters['players/current/longitude']

      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.'
        return
      }

      navigator.geolocation.getCurrentPosition(
        pos => {
          this.playerLocation.latitude = pos.coords.latitude
          this.playerLocation.longitude = pos.coords.longitude
        },
        err => {
          this.errorStr = err.message
        }
      )
    },
    switchTo (id) {
      switch (this.objectType) {
        case 'group':
          this.$router.push(`groups/${id}`)
          break
        case 'deal':
          this.$router.push(`deals/${id}`)
          break
        case 'quest':
          this.$router.push(`quests/${id}`)
          break
        default:
          break
      }
    },
    onMapReady (map) {
    },
    onUpdateBounds (bounds) {
      this.boundsInternal = bounds
      this.emitBounds()
    },
    onUpdateCenter (center) {
      this.setCenter(center.lat, center.lng)
      this.emitCenter()
    },
    onUpdateZoom (zoom) {
      this.zoomInternal = zoom
      this.emitZoom()
    },
    setCenter (lat, lon) {
      this.centerInternal = {
        lat: lat,
        lng: lon
      }
    },
    emitZoom () {
      this.$emit('update:zoom', this.zoomInternal)
    },
    emitCenter () {
      this.$emit('update:center', this.centerInternal)
    },
    emitBounds () {
      this.$emit('update:bounds', this.boundsInternal)
    }
  }
}
</script>

<style scoped>
.map {
  position: relative;
  top: 0;
  left: 0;
  height: 110px;
  z-index: 1
}
a.copy {
  font-size: 0.6em
}
</style>
