import Vue from 'vue'

import {
  http
} from '@/plugins/http'
import {
  i18n
} from '@/plugins/i18n'

const sectorIsTranslated = key => i18n.te(`businessSectors.${key}.title`)
const sectorTitleTranslated = key => i18n.t(`businessSectors.${key}.title`)
const sectorDescriptionTranslated = key => i18n.t(`businessSectors.${key}.description`)

export const businessSectors = {
  namespaced: true,
  state: {
    result: null,
    loading: false
  },
  getters: {
    list: state => (state.result?.business_sectors ?? [])
      .filter(el => sectorIsTranslated(el.key))
      .map(el => ({
        ...el,
        title: sectorTitleTranslated(el.key),
        description: sectorDescriptionTranslated(el.key)
      })),
    sector: (state, getters) => id => getters.list.find(el => el.id === id),
    istLoading: state => state.loading
  },
  mutations: {
    invalidate (state) {
      Vue.set(state, 'result', null)
    },
    setResult (state, result) {
      Vue.set(state, 'result', result)
    }
  },
  actions: {
    getData ({ state, commit }) {
      state.loading = true
      return http.$api.get('/business-sectors')
        .then(response => {
          commit('setResult', response.data)
          return response
        })
        .catch(() => {
          commit('invalidate')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ state, dispatch }) {
      return dispatch('getData')
    }
  }
}
