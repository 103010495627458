import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  store
} from '@/plugins/store'
import { groups } from '@/plugins/router/groups'
import { players } from '@/plugins/router/players'
import { quests } from '@/plugins/router/quests'
import { deals } from '@/plugins/router/deals'
import { conversations } from '@/plugins/router/chats'
import {
  confirmEmail,
  myProfile,
  myData,
  register,
  resetPassword
} from '@/plugins/router/msc'

import ViewTest from '@/views/ViewTest'
import ViewLink from '@/views/deal/ViewLink'
import ViewStart from '@/views/ViewStart'
import ViewInformations from '@/views/ViewInformations'
import ViewTimeBalances from '@/views/ViewTimeBalances'

const ViewTimeline = () => import(/* webpackChunkName: "timeline" */ '@/views/ViewTimeline')

Vue.use(VueRouter)

const routes = [
  groups,
  players,
  quests,
  deals,
  conversations,
  resetPassword,
  register,
  confirmEmail,
  myProfile,
  myData,
  {
    path: '/informations',
    name: 'informations',
    components: {
      default: ViewInformations
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false
    }
  },
  {
    path: '/balances',
    name: 'balances',
    components: {
      default: ViewTimeBalances
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false
    }
  },
  {
    path: '/test',
    name: 'test',
    components: {
      default: ViewTest
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false
    }
  },
  {
    path: '/link',
    name: 'link',
    components: {
      default: ViewLink
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuAbMainpage'
      ]
    }

  },
  {
    path: '/',
    name: 'start',
    components: {
      default: ViewStart
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    components: {
      default: ViewTimeline
    },
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuAbMainpage'
      ]
    }
  },
  {
    path: '/*',
    name: 'catchall',
    alias: '/__empty'
  }
]

// Routes where the current-user-check will be disabled
const publicRoutes = [
  'register'
]

export const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

let firstNavigation = true
router.beforeEach((to, from, next) => {
  store.commit('closeStarmenu') // close the starmenu on every navigation
  store.commit('closeNotificationsPane')

  if (
    !firstNavigation &&
    to.name !== from.name &&
    !publicRoutes.includes(to.name)
  ) {
    store.dispatch('players/current/update')
  }

  if (firstNavigation) {
    firstNavigation = false
  }

  // dont await the update, we dont want to waste that time!!
  next()
})
