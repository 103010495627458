<template>
  <eins-layout-list
      #default="{ item }"
      :items="quests"
  >
    <slot
        name="item"
        :item="item"
    >
      <eins-quest-list-item
          :quest="item"
      />
    </slot>
    <div style="position:fixed; bottom: 56px;right:4px;">
      <slot name="switcher"></slot>
    </div>
  </eins-layout-list>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsQuestListItem from '@/components/quest/EinsQuestListItem'

export default {
  name: 'EinsQuestList',
  components: {
    EinsQuestListItem,
    EinsLayoutList
  },
  props: {
    quests: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>

</style>
