<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="9"
    >
      <v-text-field
          v-model="internalValue.street"
          dense
          :label="cLabels.street"
          outlined
          :rules="cRules.street"
          @input="emitInput"
      />
    </v-col>
    <v-col
        cols="12"
        sm="3"
    >
      <v-text-field
          v-model="internalValue.houseNumber"
          dense
          :label="cLabels.houseNumber"
          outlined
          :rules="cRules.houseNumber"
          @input="emitInput"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
          v-model="internalValue.postalCode"
          dense
          :label="cLabels.postalCode"
          outlined
          :rules="cRules.postalCode"
          @input="emitInput"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
          v-model="internalValue.city"
          dense
          :label="cLabels.city"
          outlined
          :rules="cRules.city"
          @input="emitInput"
      />
    </v-col>
    <v-col cols="12">
      <v-autocomplete
          v-model="internalValue.country"
          dense
          :items="cCountryList"
          :label="cLabels.country"
          outlined
          :rules="cRules.country"
          @input="emitInput"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  objectHasOwnProperty
} from '@/utils/object'
import {
  Countries
} from '@/constants'

const emptyItem = {
  street: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  country: ''
}

export default {
  name: 'EinsFormAddress',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    internalValue: JSON.parse(JSON.stringify(emptyItem))
  }),
  computed: {
    cCountryList () {
      const result = []

      for (const countryCode of Countries.CODES) {
        result.push({
          value: countryCode,
          text: this.$t(`countries.names.${countryCode}`)
        })
      }

      result.sort((a, b) => a.text.localeCompare(b.text))

      return result
    },
    cLabels () {
      const result = {}

      for (const field in emptyItem) {
        result[field] = this.$t(`input.address.${field}.label`)
      }

      return result
    },
    cRules () {
      const t = path => this.$t(`input.address.${path}`)

      return {
        street: [
          value => !!value || t('street.required')
        ],
        houseNumber: [
          value => !!value || t('houseNumber.required')
        ],
        postalCode: [
          value => !!value || t('postalCode.required')
        ],
        city: [
          value => !!value || t('city.required')
        ],
        country: [
          value => !!value || t('country.required')
        ]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (value) {
      this.setValue(value)
    },
    setValue (value) {
      for (const field in emptyItem) {
        this.internalValue[field] = value && objectHasOwnProperty(value, field)
          ? value[field]
          : emptyItem[field]
      }
    },
    emitInput () {
      this.$emit('input', { ...this.internalValue })
    }
  }
}
</script>

<style scoped >
.v-list {
  background-color: #1e1e1e !important;
}
</style>
