<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }}</v-card-title>
      <v-card-text>
        <v-textarea
            v-model.trim="message"
            autofocus
            :label="cText['message.label']"
            :hint="cText['message.hint']"
            counter="75"
            rows="2"
        />
        <v-divider
            v-if="cCanApplyAsGroup"
            class="mt-2"
        />
        <v-expand-transition>
          <v-select
              v-if="cCanApplyAsGroup"
              v-model="applyAs"
              class="mt-5"
              dense
              persistent-hint
              :hint="cApplyAsHint"
              item-value="id"
              :label="cText['applyAs.label']"
              :items="cApplyAsOptions"
          >
            <template #selection="{ item }">
              <v-list-item-avatar class="ma-0 mr-1">
                <v-icon>{{ item.group_type.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template #item="{ item }">
              <v-list-item-avatar class="ma-0 mr-1">
                <v-icon>{{ item.group_type.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-expand-transition>
      </v-card-text>
      <v-expand-transition>
        <v-alert
            :value="cShowError"
            dismissible
            tile
            dense
            type="error"
            @input="onClickHideErrorMessage"
        >
          <strong>Error!</strong><br />
          {{ cErrorText }}
        </v-alert>
      </v-expand-transition>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            color="primary"
            outlined
            block
            @click="close"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <v-expand-transition>
          <v-layout
              v-if="!success"
              column
          >
            <v-flex>
              <v-btn
                  block
                  :disabled="cShowInsufficientBalanceError"
                  color="primary"
                  :loading="loading"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-flex>
            <v-flex class="mt-2">
              <v-btn
                  block
                  color="grey"
                  outlined
                  :disabled="loading"
                  @click="onClickCancel"
              >{{ cText['btn.cancel'] }}</v-btn>
            </v-flex>
          </v-layout>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import {
  Entity,
  Membership
} from '@/constants'
import { MxTranslatable } from '@/mixins'
import { isUuid } from '@/utils/api'

export default {
  name: 'EinsStarmenuDlgGetDeal',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    error: null,
    success: false,
    message: '',
    applyAs: -1,
    adminGroups: null,
    $_mx_translatable: {
      paths: [
        'title',
        'error.title',
        'btn.close',
        'btn.submit',
        'btn.cancel',
        'applyAs.label',
        'applyAs.myself',
        'message.label',
        'message.hint'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogGetDeal']
      },
      set (value) {
        this.$store.commit('dialogGetDeal', value)
      }
    },
    cApplyAsHint () {
      const type = this.applyAs === -1 ? 'Myself' : 'Group'
      return this.$t(`components.${this.$options.name}.applyAs.hint${type}`)
    },
    cCanApplyAsGroup () {
      return this.cType === Entity.DEAL &&
          Array.isArray(this.adminGroups) &&
          this.adminGroups.length > 0
    },
    cApplyAsOptions () {
      if (!this.adminGroups) {
        return []
      } else {
        const result = [].concat(this.adminGroups)

        result.unshift({
          id: -1,
          title: this.cText['applyAs.myself'],
          group_type: {
            icon: 'mdi-account-box'
          }
        })

        return result
      }
    },
    cIsApplyingAsPlayer () {
      return this.applyAs === -1
    },
    cShowInsufficientBalanceError () {
      return this.cIsApplyingAsPlayer && !this.cBalanceIsSufficient
    },
    cPlayerBalanceAvailable () {
      return this.$store.getters['players/current/timeAccount']?.balance_available
    },
    cBalanceAvailable () {
      return this.cDeal ? this.cDeal?.value : ''
    },
    cBalanceIsSufficient () {
      return this.cPlayerBalanceAvailable > this.cBalanceAvailable
    },
    cShowError () {
      return !!this.error || this.cShowInsufficientBalanceError
    },
    cRouteId () {
      return this.$route.params.id
    },
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        default:
          return null
      }
    },
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cEntity () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        default:
          return null
      }

      return result
    },
    cApiUrl () {
      let root
      switch (this.cType) {
        case Entity.DEAL:
          root = `deals`
          break
        default:
          return null
      }

      if (root) {
        return `/${root}/${this.cRouteId}/applications`
      } else {
        return null
      }
    },
    cErrorText () {
      let result = ''

      if (this.error) {
        switch (this.error) {
          case 409:
            result = this.$t(`components.${this.$options.name}.error.409`, [ this.cEntity?.name ?? '' ])
            break
          default:
            result = this.$t(`components.${this.$options.name}.error.unknown`)
            break
        }
      } else if (this.cShowInsufficientBalanceError) {
        result = this.$t(`components.${this.$options.name}.error.balance`)
      }

      return result
    }
  },
  watch: {
    '$route.params.id' (value, oldValue) {
      if (value !== oldValue) {
        this.message = ''
      }
    },
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.success = false
          this.error = false
          this.message = ''
          this.applyAs = null // reset selection
          if (this.cType === Entity.DEAL) {
            this.loading = true
            this.getAdminGroups()
              .finally(() => {
                this.applyAs = -1 // select myself
                this.loading = false
              })
          } // here I will add the condition for when there is a group
        } else {
          this.adminGroups = null
        }
      }
    },
    onClickApplyAsMyself () {
      this.applyAs = null
    },
    onClickClose () {
      this.close()
    },
    onClickHideErrorMessage () {
      this.error = null
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    getAdminGroups () {
      return this.$http.$api.get('/groups', { params: {
        player_id: this.$store.getters['players/current/id'],
        player_role: Membership.ADMIN,
        no_distance: true,
        can_buy: true
      } })
        .then((response) => {
          this.$set(this.$data, 'adminGroups', response.data?.groups ?? [])
          return this.adminGroups
        })
    },
    close () {
      this.open = false
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.error = false

        const payload = {
          message: this.message
        }

        if (this.cType === Entity.DEAL &&
            isUuid(this.applyAs)
        ) {
          payload.player_group_id = this.applyAs
        }

        if (this.cDeal) {
          this.$http.$api.post(this.cApiUrl, payload)
            .then(() => {
              this.success = true
              this.message = ''
            })
            .catch((e) => {
              this.error = e.response?.status ?? true
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          reject(new Error('No valid deal'))
          this.loading = false
          this.error = true
        }
      })
    },
    cancel () {
      this.close()
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>

.v-list {
  background-color:#080808 !important;
}
</style>
