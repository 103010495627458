import {
  createListModule
} from '@/plugins/store/factories'
import { QuestState } from '@/constants'

export const questsTimeline = createListModule({
  basePath: '/quests',
  baseEntity: 'quests',
  getParams: () => ({
    state: QuestState.CLOSED,
    with_reaction: true
  })
})
