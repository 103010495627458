<template>
  <eins-edit-time-value-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      :current-value="cDealValue"
      :current-value-label="cText.currentValueLabel"
      :max="480"
      :min="1"
      :dialog-title="cText.dialogTitle"
      :submit-label="cText.submitLabel"
      :success-message="cSuccessMessage"
  />
</template>

<script>
import EinsEditTimeValueBlock from '@/components/form/edit/EinsEditTimeValueBlock'
import {
  QuestType
} from '@/constants'

export default {
  name: 'EinsDealDataValueBlock',
  components: {
    EinsEditTimeValueBlock
  },
  data: () => ({
  }),
  computed: {
    cText () {
      return {
        dialogTitle: this.$t('deal.update.reward.dialogTitle'),
        currentValueLabel: this.$t('deal.update.reward.currentValueLabel'),
        submitLabel: this.$t('deal.update.reward.actions.submit')
      }
    },
    cApiPath () {
      return `deals/${this.cDealId}`
    },
    cDealId () {
      return this.$store.getters['deals/selected/deal']?.id
    },
    cDealValue () {
      return this.$store.getters['deals/selected/deal']?.value ?? 0
    },
    cIsPrivateQuest () {
      return this.$store.getters['deals/selected/deal']?.type === QuestType.PRIVATE
    },
    cSuccessMessage () {
      return {
        title: this.$t('deal.update.reward.success.title'),
        text: this.$t('deal.update.reward.success.text')
      }
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('deals/selected/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
