export const getMagnitude = (num) => {
  let magn = 0
  let divider = 1

  while (num / divider >= 1000) {
    magn += 3
    divider *= 1000
  }

  return magn
}

export const floorToMagnitude = (num) => {
  const magn = getMagnitude(num)
  const factor = Math.pow(10, magn)

  return Math.floor(num / factor) * factor
}
