<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-human-greeting</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbJoinGroup',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cEntity () {
      return this.$store.getters['groups/selected/group']
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
          !this.cUserIsApplicant &&
          this.cDirectInteraction &&
          !this.cUserIsMember
    },
    cDirectInteraction () {
      return !this.cEntity?.require_application
    },
    cUserIsApplicant () {
      return (this.cEntity?.applicants ?? []).includes(this.$store.getters['players/current/id'])
    },
    cUserIsMember () {
      const groups = this.$store.getters['players/current/groups'] ?? []

      return groups.includes(this.cEntity?.id)
    }
  },
  methods: {
    onClick () {
      this.$http.$api.post(`groups/${this.cEntity.id}/applications`)
        .then(() => {
          this.$store.dispatch('players/current/update')
          this.$store.dispatch('groups/selected/update')
          this.$store.dispatch('openDialogJoinedGroup')
        })
        .catch(() => {
          this.$store.dispatch('openDialogError')
        })
    }
  }
}
</script>

<style scoped>
</style>
