<template>
  <eins-layout-item-detail-alert
      :text="cText"
      :text-long="cTextLong"
      :icon="cIcon"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
import EinsLayoutItemDetailAlert from '@/components/layout/EinsLayoutItemDetailAlert'

const validTypes = [
  'stranger',
  'applicant',
  'member',
  'admin',
  'owner',
  'applicant_rejected',
  'quest',
  'deal',
  'addquest',
  'adddeal'
]

export default {
  name: 'EinsGroupDetailsAlert',
  components: {
    EinsLayoutItemDetailAlert
  },
  props: {
    type: {
      type: String,
      default: null,
      validator: val => validTypes.indexOf(val) > -1
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    cText () {
      const key = `components.${this.$options.name}.text.${this.type}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cTextLong () {
      const key = `components.${this.$options.name}.textLong.${this.type}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cIcon () {
      switch (this.type) {
        case 'stranger':
          return 'mdi-human-greeting'
        case 'applicant':
          return 'mdi-account-clock'
        case 'applicant_rejected':
          return 'mdi-minus-circle'
        case 'member':
          return 'mdi-wallet-membership'
        case 'admin':
          return 'mdi-account-supervisor'
        case 'quest':
          return 'mdi-map-marker'
        case 'deal':
          return 'mdi-cart'
        case 'addquest':
          return 'mdi-map-marker-plus '
        case 'adddeal':
          return 'mdi-cart-plus'
        case 'owner':
          return 'mdi-account-tie'
        default:
          return ''
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
