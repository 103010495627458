<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ blockage.blocked_user.name }}</v-list-item-title>
      <v-list-item-subtitle class="font-italic">
        {{ cDate }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-dialog v-model="dialog">
        <template #activator="{ on, attrs }">
          <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            <i18n path="player.block.list.unblockDialog.title" />
          </v-card-title>
          <v-card-text v-if="!success">
            <i18n path="player.block.list.unblockDialog.text">
              <span class="font-italic">'{{ blockage.blocked_user.name }}'</span>
            </i18n>
          </v-card-text>
          <v-expand-transition>
            <v-alert
                type="success"
                tile
                text
                :value="success"
            >
              <i18n path="player.block.list.unblockDialog.state.success">
                <span class="font-italic text-no-wrap">'{{ blockage.blocked_user.name }}'</span>
              </i18n>
            </v-alert>
          </v-expand-transition>
          <v-expand-transition>
            <v-alert
                type="error"
                tile
                text
                :value="cShowError"
            >
              {{ cError }}
            </v-alert>
          </v-expand-transition>
          <v-card-actions>
            <eins-btn-timed
                v-if="success"
                block
                color="primary"
                outlined
                @click="onClickClose"
            >
              <i18n path="player.block.list.unblockDialog.buttons.close" />
            </eins-btn-timed>
            <v-expand-transition>
              <v-row
                  v-if="!success"
                  class="flex-column"
                  dense
              >
                <v-col>
                  <v-btn
                      block
                      color="warning"
                      :disabled="loading"
                      outlined
                      @click="onClickCancel"
                  >
                    <i18n path="player.block.list.unblockDialog.buttons.cancel" />
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                      block
                      color="primary"
                      :disabled="loading"
                      outlined
                      @click="onClickUnblock"
                  >
                    <i18n path="player.block.list.unblockDialog.buttons.submit" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { HttpStatus } from '@/constants'

export default {
  name: 'EinsBlockageListElement',
  components: { EinsBtnTimed },
  props: {
    blockage: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    error: false,
    dialog: false,
    loading: false,
    success: false
  }),
  computed: {
    cDate () {
      return this.$t('player.block.list.listElement.createdAt', [
        this.$d(this.blockage.created_at, 'short')
      ])
    },
    cShowError () {
      return this.error !== false
    },
    cError () {
      if (this.error === false) {
        return false
      }
      switch (this.error) {
        case HttpStatus.FORBIDDEN:
          return this.$t(`player.block.list.unblockDialog.state.error.${HttpStatus.FORBIDDEN}`)
        case HttpStatus.CONFLICT:
          return this.$t(`player.block.list.unblockDialog.state.error.${HttpStatus.CONFLICT}`)
        default:
          return this.$t('player.block.list.unblockDialog.state.error.default')
      }
    }
  },
  watch: {
    dialog () {
      this.error = false
      this.success = false
      this.loading = false
    }
  },
  methods: {
    onClickClose () {
      this.closeDialog()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickUnblock () {
      this.unblock()
    },
    unblock () {
      this.loading = true
      this.success = false
      this.error = false

      return this.$http.$api.delete(`blockages/${this.blockage.id}`)
        .then(() => {
          this.$store.commit('players/selected/blockages/removeFromCache', [this.blockage.id])
          this.success = true
        })
        .catch(err => {
          this.success = err.response.status ?? true
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
