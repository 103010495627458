<template>
  <eins-starmenu-eb
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-pencil"
      :to="cTo"
  />
</template>

<script>
import EinsStarmenuEb from '@/components/starmenu/EinsStarmenuEb'
import {
  DealState,
  Entity,
  QuestState
} from '@/constants'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuEbEdit',
  components: {
    EinsStarmenuEb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        case 'quest-detail':
          return Entity.QUEST
        case 'group-detail':
          return Entity.GROUP
        case 'player-detail':
          return Entity.PLAYER
        default:
          return null
      }
    },
    cTo () {
      let part
      switch (this.cType) {
        case Entity.DEAL:
          part = 'deals/' + this.$store.getters['deals/selected/deal'].id.toString()
          break
        case Entity.QUEST:
          part = 'quests/' + this.$store.getters['quests/selected/quest'].id.toString()
          break
        case Entity.GROUP:
          part = 'groups/' + this.$store.getters['groups/selected/group'].id.toString()
          break
        case Entity.PLAYER:
          return '/my-data'
      }

      return `/${part}/edit`
    },
    cHasApplicants () {
      switch (this.cType) {
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal'].applicants.length > 0
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest'].applicants.length > 0
        case Entity.GROUP:
          return this.$store.getters['groups/selected/group'].applicants.length > 0
        default:
          return false
      }
    },
    cShowBtn () {
      const currentPlayerId = this.$store.getters['players/current/id']

      if (!this.cStateIsOpen || !this.cBelongsToRoute) return false
      if (this.cHasApplicants && this.cType !== Entity.GROUP) return false

      switch (this.cType) {
        case Entity.GROUP:
          // only owner can edit the group
          return this.$store.getters['groups/selected/group']?.owner_id === currentPlayerId
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest']?.player?.id === currentPlayerId
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal']?.player?.id === currentPlayerId
        case Entity.PLAYER:
          return this.$store.getters['players/selected/player']?.id === currentPlayerId
        default:
          return false
      }
    },
    cStateIsOpen () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal'].state === DealState.OPEN
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest'].state === QuestState.OPEN
          break
        case Entity.GROUP:
          result = true
          break
        case Entity.PLAYER:
          result = true
          break
        default:
          result = false
      }

      return result
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped>

</style>
