<template>
  <eins-layout-view-detail>
    <v-card
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;
        overflow:auto"
    >
      <v-card-title>
        <h4 style="width:100%">
          {{ $t('views.ViewTimeBalances.caption') }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-row
            no-gutters
            style="border-top:1px solid #FF5D5B;border-bottom:1px solid #FF5D5B"
        >
          <v-col
              cols="4"
              class="avatar"
          >
            <img
                src="../assets/img/Future Account.png"
                width="100px"
                height="134px"
            >
          </v-col>
          <v-col cols="8">
            <v-row
                align="center"
                justify="center"
                class="fill-height"
                no-gutters
                style="position: relative; font-size: 1.5em;"
            >
              <v-icon
                  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; font-size: 5em;"
                  color="#000"
              >mdi-star</v-icon>
              <v-col
                  class="flex-shrink-1 flex-grow-0"
                  style="position: relative; z-index: 2;"
              >
                <div>
                  10<sup>{{ cGreenBalanceExp }}</sup>
                  <br />
                  {{ $t('views.ViewTimeBalances.year') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row
            no-gutters
            style="margin-top:6px;border-top:1px solid #FF5D5B;border-bottom:1px solid #FF5D5B"
        >
          <v-col
              cols="4"
              class="avatar"
          >
            <img
                src="../assets/img/Community Account.png"
                width="100px"
                height="124px"
            >
          </v-col>
          <v-col cols="8">
            <v-row
                align="center"
                justify="center"
                class="fill-height"
                no-gutters
                style="position: relative; font-size: 1.5em;"
            >
              <v-icon
                  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; font-size: 5em;"
                  color="#000"
              >mdi-star</v-icon>
              <v-col
                  class="flex-shrink-1 flex-grow-0"
                  style="position: relative; z-index: 2;"
              >
                <div>
                  {{ cCommonBalance }}
                  <br />
                  {{ $t('views.ViewTimeBalances.year') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row
            no-gutters
            style="margin-top:6px;border-top:1px solid #FF5D5B;border-bottom:1px solid #FF5D5B"
        >
          <v-col
              cols="4"
              class="avatar pa-4"
          >
            <img
                src="../assets/img/icons/ui/players.png"
                width="60px"
            >
            <br />
            <span class="caption">
              {{ $t('views.ViewTimeBalances.player') }}
            </span>
          </v-col>
          <v-col cols="8">
            <v-row
                align="center"
                justify="center"
                class="fill-height"
                no-gutters
                style="position: relative; font-size: 1.5em;"
            >
              <v-icon
                  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; font-size: 5em;"
                  color="#000"
              >mdi-star</v-icon>
              <v-col
                  class="flex-shrink-1 flex-grow-0"
                  style="position: relative; z-index: 2;"
              >
                <div class="text-center">
                  {{ cGlobalBalance > 0 ? cGlobalBalance : '&lt; 1' }}
                  <br />
                  {{ $t('views.ViewTimeBalances.year') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
            no-gutters
            style="margin-top:6px;border-top:1px solid #FF5D5B;border-bottom:1px solid #FF5D5B"
        >
          <v-col
              cols="4"
              class="avatar pa-4"
          >
            <img
                src="../assets/img/icons/ui/account-box.png"
                width="60px"
            >
            <br />
            <span class="caption">{{ $t('views.ViewTimeBalances.my') }}</span>
          </v-col>
          <v-col
              cols="8"
              class="pa-2"
          >
            <v-row
                align="center"
                justify="center"
                no-gutters
                style="font-size: 1.5em; max-height:5.5em"
            >
              <v-col
                  cols="6"
                  class="text-center items-center"
                  style=""
              >
                <v-icon
                    style="font-size: 5em"
                    color="#000"
                >mdi-star</v-icon>
                <div
                    class="text-center "
                    style="transform: translate(0.1em, -3.5em);"
                >
                  <span style="font-size: 1em;">{{ $n(cBalance.years, 'magnitude') }}</span>
                  <br />
                  <span style="font-size: 1em;">{{ $t('views.ViewTimeBalances.year') }}</span>
                </div>
              </v-col>
              <v-col
                  cols="6"
                  color="#0000"
                  style=""
              >
                <v-icon
                    style="font-size: 5em;"
                    color="#fff"
                >mdi-star</v-icon>
                <div
                    class="text-center"
                    style="color:black;transform:translate(0.1em, -3.5em);"
                >
                  <span
                      style="font-size: 1em;"
                  >{{ $n(cBalance.days, 'magnitude') }}</span>
                  <br />
                  <span style="font-size: 1em;">{{ $t('views.ViewTimeBalances.day') }}</span>
                </div>
              </v-col>

              <!--<v-icon
                  style="position: absolute; top: 25%; left: 10%; transform: translate(-50%, -50%); z-index: 1; font-size: 5em;border:1px solid white"
                  color="#000"
              >mdi-star</v-icon>
              <v-col
                  style="position: absolute; top: 25%; left: 50%; transform: translate(-45%, -40%); z-index: 2;"
              >
                <div class="text-center">
                  <sup>{{ $n(cBalance.years, 'magnitude') }}</sup>
                  <br />
                  Years
                </div>
              </v-col>
              -->
              <!--</v-row>
            <v-row
                align="center"
                justify="center"
                class="fill-height"
                style="position: relative; font-size: 1.5em;"
            >
              <v-icon
                  style="position: absolute; top: 25%; left: 10%; transform: translate(-50%, -50%); z-index: 1; font-size: 5em;"
                  color="#000"
              >mdi-star</v-icon>
              <v-col
                  color="#0000"
                  style="position: absolute; top: 25%; left: 50%; transform: translate(-45%, -40%); z-index: 2;"
              >
                <div>
                  <sup>{{ $n(cBalance.days, 'magnitude') }}</sup>
                  <br />
                  days
                </div>
              </v-col>-->
            </v-row>
            <v-row no-gutters>
              <v-col cols="7">
                <eins-time-stars
                    per-row="8"
                    stars="24"
                    shrink
                    icon-color="primary"
                    style="font-size: 16px;"
                    :value="cBalance.hours * 60"
                    wrap
                />
              </v-col>
              <v-col cols="5">
                <strong
                    class="white--text mt-9 ml-6"
                >{{ $n(cBalance.hours, 'magnitude') }} {{ $t('views.ViewTimeBalances.star') }}</strong>
              </v-col>
            </v-row>
            <v-row
                no-gutters
                class="mt-5"
            >
              <v-col cols="8">
                <eins-time-points
                    per-row="12"
                    points="60"
                    shrink
                    icon-color="primary"
                    style="font-size: 9px;"
                    :value="cBalance.minutesCapped"
                    wrap
                />
              </v-col>
              <v-col cols="4">
                <strong
                    class="white--text mt-2"
                >{{ $n(cBalance.minutesCapped, 'magnitude') }} {{ $t('views.ViewTimeBalances.point') }}</strong>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </eins-layout-view-detail>
</template>

<script>
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsTimeStars from '@/components/time/EinsTimeStars'
import EinsTimePoints from '@/components/time/EinsTimePoints'
import {
  minutesPerYear,
  minutesPerDay
} from '../utils/time'

export default {
  name: 'ViewTimeBalances',
  components: { EinsTimeStars, EinsLayoutViewDetail, EinsTimePoints },
  data: () => ({
    loading: false
  }),
  computed: {
    cLoading () {
      return this.$store.getters['players/selected/loading']
    },
    cError () {
      return this.$store.getters['players/selected/error']
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    },
    cGreenBalance () {
      return this.$store.getters['stats/timeAccountGreenBalance'] / minutesPerYear
    },
    cGreenBalanceExp () {
      return Math.floor(Math.pow(this.cGreenBalance, 1 / 10))
    },
    cCommonBalance () {
      return Math.round(this.$store.getters['stats/timeAccountCommonBalance'] / minutesPerYear)
    },
    cGlobalBalance () {
      return Math.round(this.$store.getters['stats/timeAccountGlobalBalance'] / minutesPerYear)
    },
    cBalance () {
      const raw = this.$store.getters['players/current/timeAccount']?.balance_available ?? 0
      const years = Math.floor(raw / minutesPerYear)
      const days = Math.floor((raw - (years * minutesPerYear)) / minutesPerDay)
      const hoursCapped = Math.floor((raw - years - days) - (((days * minutesPerDay)) / 60))
      const hours = Math.floor((raw - (years * minutesPerYear) - (days * minutesPerDay)) / 60)
      const minutesCapped = Math.floor(raw % 60)

      return {
        raw,
        years,
        days,
        hours,
        hoursCapped,
        minutesCapped
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadStats()
    })
  },
  methods: {
    loadStats () {
      this.loading = true
      return Promise.all([this.$store.dispatch('stats/updateTimeAccounts')])
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
