export default {
  captions: {
    loading: 'Verifying your Email',
    verified: 'Email verified',
    error: 'Error',
    resend: 'Resend verification email',
    again1: 'We have send you another verification email to',
    again2: '. Be sure to check your spam folder!'
  },
  button: {
    send: 'Send',
    request: 'Request',
    back: 'Back',
    close: 'Close'
  },
  errors: {
    conflict: 'The email has already been verified!',
    badRequest: 'Your validation-email has expired!',
    unknown: 'An unknown error happened!'
  }
}
