<template>
  <v-card
      class="register-container"
      style="height:calc(100% + 32px);margin-top:-16px;"
  >
    <div style="height:21%;">
      <!--<img
          src="@/assets/img/register_bg.png"
          style="width:100%;height:100%"
          alt=""
      >-->
    </div>
    <v-card-text
        v-show="!success"
    >
      <v-form
          ref="form"
          v-model="formValid"
      >
        <app-register v-model="formData" />
      </v-form>
    </v-card-text>
    <v-expand-transition>
      <v-alert
          v-if="error"
          type="error"
          text
          tile
          class="ma-0"
      >
        {{ cError }}
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <v-alert
          v-if="loggingIn"
          type="success"
          text
          tile
          class="ma-0"
      >
        <template #prepend>
          <v-progress-circular
              indeterminate
          />
        </template>
        {{ cText['labels.logging'] }}
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <v-alert
          v-if="success"
          text
          tile
          class="ma-0"
      >
        <v-card class="fill-height">
          <v-card-title class="headline">
            <v-icon>mdi-save</v-icon>
            {{ cText['labels.success.title'] }}
          </v-card-title>
          <v-card-text>
            {{ cText['labels.success.text'] }}
          </v-card-text>
        </v-card>
      </v-alert>
    </v-expand-transition>
    <v-card-actions v-show="!success">
      <v-row
          class="pl-2 pr-2"
          no-gutters
      >
        <v-col cols="12">
          <v-btn
              color="primary"
              elevation="11"
              block
              :disabled="cDisabled"
              depressed
              @click="onClickSubmit"
          >{{ cText['buttons.register.label'] }}</v-btn>
        </v-col>
        <!--
        <v-col
            cols="12"
            class="mt-1 text text-center"
        >
          {{ cText['labels.orLabel'] }}
        </v-col>
        <v-col cols="12">
          <v-btn
              class="black--text"
              color="white"
              elevation="11"
              block
              depressed
              @click="onClickOauthBtn('apple')"
          >
            <v-icon>mdi-apple</v-icon>
            <v-spacer></v-spacer>
            <span>{{ cText['buttons.loginWithApple.label'] }}</span>
          </v-btn>
        </v-col>
        <v-col
            cols="12"
            class="mt-1"
        >
          <v-btn
              color="yellow accent-4"
              elevation="11"
              block
              class="mt-3 mb-3 black--text"
              depressed
              @click="onClickOauthBtn('google')"
          >
            <v-icon>mdi-google</v-icon>
            <v-spacer></v-spacer>
            <span>{{ cText['buttons.signInWithGoogle.label'] }}</span>
          </v-btn>
        </v-col>
        <v-col
            cols="12"
            class="mt-1"
        >
          <v-btn
              color="grey"
              elevation="11"
              block
              depressed
              @click="onClickLogin"
          >
            <v-icon>mdi-star</v-icon>
            <v-spacer></v-spacer>
            <span>{{ cText['buttons.BackToLogin.label'] }}</span>
          </v-btn>
        </v-col>
        -->
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import AppRegister from '@/components/app/AppRegister'
import { AuthModes, HttpStatus } from '@/constants'
import { isEmail } from '@/utils/inputRules'
export default {
  name: 'EinsModuleRegister',
  components: { AppRegister },
  mixins: [MxTranslatable],
  data: () => ({
    formData: {},
    formValid: false,
    saving: false,
    error: false,
    success: false,
    alert: {
      cancel: false
    },
    loggingIn: false,
    $_mx_translatable: {
      paths: [
        'labels.success.title',
        'labels.success.text',
        'buttons.register.label',
        'labels.orLabel',
        'buttons.loginWithApple.label',
        'buttons.signInWithGoogle.label',
        'buttons.BackToLogin.label',
        'buttons.gotoLogin.label',
        'labels.logging',
        'error.invalid',
        'error.rate',
        'error.email',
        'error.unknown'
      ]
    }
  }),
  computed: {
    cError () {
      if (this.error === false) {
        return false
      } else {
        switch (this.error) {
          case HttpStatus.UNAUTHORIZED:
            return this.cText['error.invalid']
          case HttpStatus.UNPROCESSABLE_ENTITY:
            return this.cText['error.email']
          case HttpStatus.TOO_MANY_REQUESTS:
            return this.cText['error.rate']
          default:
            return this.cText['error.unknown']
        }
      }
    },
    cDisabled () {
      return !this.formValid
    },
    cEMail () {
      return this.$route.query?.email || ''
    }
  },
  watch: {
    error: {
      immediate: true,
      handler: 'onError'
    }
  },
  created () {
    this.formData.email = this.$route.query?.email || ''
  },
  methods: {
    onError () {
      if (this.error === HttpStatus.UNPROCESSABLE_ENTITY) {
        this.goToLogin()
      }
    },
    onClickSubmit () {
      this.submit()
    },
    onClickOauthBtn (provider) {
      this.$firebase.onReady(async () => {
        await this.$firebase.login(provider)
      })
    },
    onClickLogin () {
      this.goToLogin()
    },
    goToLogin () {
      this.error = false
      this.success = false
      this.$router.push({
        name: this.$route.name,
        hash: this.$route.hash,
        query: {
          auth: AuthModes.LOGIN,
          email: isEmail(this.formData.email) === true ? this.formData.email : ''
        }
      })
    },
    submit () {
      this.saving = true
      this.error = false
      this.$http.$api.post('/players/', this.formData)
        .then(() => {
          this.success = true
          this.error = false
        })
        .catch(er => {
          if (!er.response) {
            this.error = true
          } else {
            this.error = er.response.status
          }
        })
        .finally(() => {
          this.saving = false
          if (!this.error) {
            this.loggingIn = true
            setTimeout(() => {
              this.$store.dispatch(
                'players/current/login',
                {
                  email: this.formData.email,
                  password: this.formData.password
                }
              )
                .finally(() => {
                  this.loggingIn = false
                })
            }, 1000)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
