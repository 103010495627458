export default {
  captions: {
    caption: 'Erstelle ein neues @.proper:(terms.group)',
    page3: 'Das @.proper:(terms.group) anfragen'
  },
  fields: {
    invoiceAdress: {
      label: 'Company name',
      placeholder: 'enter Company name'
    },
    name: {
      label: '@.lower:(terms.group) Name',
      placeholder: 'Trage einen @.proper:(terms.group) Namen ein'
    },
    eMail: {
      label: 'Add @.lower:(terms.group) eMail',
      placeholder: 'Your unique email for the@.lower:(terms.group)',
      hint: 'This @.lower:(terms.group) eMail should verified to start the@.lower:(terms.group). And is used to communicate with icigai1 GmbH if yuo need a license.'
    },
    contact: {
      label: 'Add @.lower:(terms.group) contact person',
      placeholder: 'The name of your contact person for the icigai1 GmbH',
      hint: 'The contact person for queries and for communication with icigai1 GmbH outside the App1'
    }
  },
  labels: {
    licenceName: {
      label: 'Art des @.proper:(terms.group)es'
    },
    description: {
      label: '@.lower:(terms.group) details'
    },
    BuyDeals: {
      label: 'no trading limits'
    },
    NoDeals: {
      label: 'keine @.proper:(terms.deals)'
    },
    GroupMembers: {
      label: '@.proper:(terms.group) Mitglieder erlaubt'
    },
    Leaders: {
      label: '@.lower:(terms.group) Admins erlaubt'
    },
    Closed: {
      label: 'Neue Mitglieder müssen sich bewerben'
    },
    Open: {
      label: 'Neue Mitglieder können beitreten'
    },
    ActiveContent: {
      label: 'Contentunterstützung durch die icigai1'
    },
    SelfContent: {
      label: 'eigenverantwortliches Contentmanagement durch die @.lower:(terms.group) Mitglieder '
    },
    Support: {
      label: 'beinhaltet Supporttickets in 12 Monate.'
    },
    Calls: {
      label: 'Support per Telefon für 12 Monate.'
    }
  },
  buttons: {
    groupType1: {
      label: 'Freies @.proper:(terms.group) / @.lower:(terms.private)',
      info: '@.proper:(terms.players) versammeln sich auf @.proper:(terms.group), um @.proper:(terms.quests) und @.proper:(terms.deals) füreinander zu posten. Als Ersteller der @.proper:(terms.group) Bist Du auch der Eigentümer der @.proper:(terms.group). Du kannst andere @.proper:(terms.players) zu Admins der @.proper:(terms.group) ernennen, nachdem Du diese erstellt hast.'
    }
  }
}
