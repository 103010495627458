<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon="mdi-credit-card-check-outline"
      @click="onClick"
  />
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuAbExchangeDeal',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cRequireApplication () {
      return this.cDeal?.require_application
    },
    cUserIsAssignee () {
      return (this.cDeal?.assignments ?? [])
        .some(assignment => assignment.user.id === this.$store.getters['players/current/id'])
    },
    cUserIsOwner () {
      return this.cDeal?.player.id === this.$store.getters['players/current/id']
    },
    cStateDealEnded () {
      return this.cDeal?.state === 'ended'
    },
    cShowBtn () {
      if (this.cDeal) {
        return this.cBelongsToRoute &&
          !this.cUserIsOwner &&
          this.cUserIsAssignee &&
          this.cRequireApplication &&
          !this.cStateDealEnded
      } else {
        return false
      }
    },
    cText () {
      return this.$t(`components.${this.$options.name}.text`)
    }
  },
  methods: {
    onClick () {
      return this.$store.dispatch('openDialogExchangeDeal', {
        deal: this.cDeal
      })
        .finally(() => this.$store.dispatch('deals/selected/update'))
    }
  }
}
</script>

<style scoped>

</style>
