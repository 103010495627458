export default {
  state: {
    friend: {
      title: 'Friend',
      titleFilter: 'Friends',
      text: 'The @.lower:(terms.player) is your friend'
    },
    idol: {
      title: 'Idol',
      titleFilter: 'Idols',
      text: 'The @.lower:(terms.player) is your idol'
    },
    fan: {
      title: 'Fan',
      titleFilter: 'Fans',
      text: 'The @.lower:(terms.player) is your fan'
    },
    none: {
      title: 'None',
      titleFilter: 'Unrelated',
      text: 'You dont have any relations to this @.lower:(terms.player)'
    }
  }
}
