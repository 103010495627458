<template>
  <v-btn
      v-bind="$attrs"
      class="v-btn-starmenu"
      @click="onClick"
  >
    <slot name="icon">
      <v-icon
          v-if="icon"
          left
          color="primary"
      >{{ icon }}</v-icon>
    </slot>
    <slot>
      {{ text }}
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'EinsStarmenuBtn',
  props: {
    icon: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped>

</style>
