<template>
  <v-dialog
      :value="internalValue"
      @input="onUpdateDialogModel"
  >
    <v-card class="edit-dialog-content">
      <v-card-title><slot name="title"></slot></v-card-title>
      <v-expand-transition>
        <v-card-text v-if="!hideInput">
          <v-form v-model="form.valid">
            <v-file-input
                v-model="form.file"
                show-size
                accept="image/*"
                :placeholder="cInputLabel"
                :rules="cRules"
                prepend-icon="mdi-camera-outline"
                persistent-hint
                @change="onSelectFile"
                @click:clear="onClickClearInput"
            />
          </v-form>
        </v-card-text>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="form.preview && !hideInput">
          <eins-image-cropper
              ref="cropper"
              max-height="calc((100vh * 0.9) - 260px)"
              :src="form.preview"
              @update="onUpdateImageCropper"
              @ready="onCropperReady"
          />
        </div>
      </v-expand-transition>
      <slot
          :ready="cReady"
      ></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsImageCropper from '@/components/input/EinsImageCropper'

import { fileExists } from '@/utils/inputRules'

export default {
  name: 'EinsImageInputDialog',
  components: {
    EinsImageCropper
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    hideInput: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    internalValue: false,
    success: false,
    loading: false,
    form: {
      valid: false,
      file: null,
      preview: null
    },
    cropper: {
      show: false,
      ready: false,
      result: null
    }
  }),
  computed: {
    cReady () {
      return this.form.valid && this.cropper.result
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cInputLabel () {
      return this.$t('input.picture.label')
    },
    cRules () {
      return [
        fileExists
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    onChangeValue (value) {
      this.setInternalValue(value)
    },
    onUpdateDialogModel (value) {
      this.setInternalValue(value)
      this.updateModel(value)
    },
    onUpdateImageCropper (objectUrl) {
      this.cropper.result = objectUrl
    },
    onCropperReady () {
      this.cropper.ready = true
    },
    onSelectFile (file) {
      setTimeout(() => this.$nextTick().then(() => {
        if (this.form.valid) {
          this.clearSelection()
          this.cropper.ready = false
          this.form.preview = URL.createObjectURL(file)
          this.form.file = file
        }
      }))
    },
    onClickClearInput () {
      this.clearSelection()
    },
    clearSelection () {
      if (this.form.preview) {
        URL.revokeObjectURL(this.form.preview)
        this.form.preview = null
      }
      this.form.file = null
    },
    reset () {
      this.clearSelection()
      this.success = false
    },
    getImageFile () {
      return this.form.file ?? null
    },
    getCroppedPicture () {
      if (!this.$refs.cropper) {
        return Promise.reject(new Error('cropper not found'))
      }

      return this.$refs.cropper.getBlob()
    },
    setInternalValue (value) {
      if (value !== this.internalValue) {
        this.reset()
      }
      this.internalValue = value
    },
    updateModel () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
