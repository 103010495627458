export default {
  fields: {
    name: {
      label: 'Player name',
      placeholder: 'Choose your @.lower:(terms.player) name',
      hint: 'Please choose your @.lower:(terms.player) name'
    },
    eMail: {
      label: 'eMail ',
      placeholder: '@.lower:(terms.player) e-Mail adress ',
      hint: 'Please enter a correct @.lower:(terms.player) e-mail address'
    },
    password: {
      label: 'Enter password',
      hint: 'password must be longer than 10'
    },
    passwordConfirmation: {
      label: 'Confirm password',
      hint: 'Passwords must match'
    },
    show: {
      label: 'hide password'
    }
  }
}
