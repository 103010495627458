export default {
  forbidden: {
    title: 'Nicht erlaubt!',
    description: 'Du bist nicht berechtigt, {0} mit der ID {1} anzuzeigen!'
  },
  notFound: {
    title: 'Nicht gefunden!',
    description: '{0} mit der ID {1} existiert nicht!'
  }
}
