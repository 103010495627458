<template>
  <v-dialog :value="cOpen">
    <v-card>
      <v-card-title>
        <i18n path="views.TheMessagingPermissionDialog.PushNotifications.title" />
      </v-card-title>
      <v-card-text>
        <p class="text-body-1">
          <i18n path="views.TheMessagingPermissionDialog.PushNotifications.text" />
        </p>
        <v-switch
            v-model="remember"
            label="Remember my choice"
            dense
            :disabled="loading"
            hide-details
        />
      </v-card-text>
      <v-alert
          v-model="error.request"
          class="ma-0"
          type="error"
          dense
          border="left"
          tile
          text
      >
        <i18n path="views.TheMessagingPermissionDialog.PushNotifications.request" />
      </v-alert>
      <v-alert
          v-model="error.permission"
          class="ma-0"
          type="error"
          dense
          border="left"
          tile
          text
      >
        <i18n path="views.TheMessagingPermissionDialog.PushNotifications.permission" />
      </v-alert>
      <v-alert
          v-model="error.store"
          class="ma-0"
          type="error"
          dense
          border="left"
          tile
          text
      >
        <i18n path="views.TheMessagingPermissionDialog.PushNotifications.store" />
      </v-alert>
      <v-divider />
      <v-card-actions>
        <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            text
            outlined
            @click="onClickYes"
        >
          <i18n path="views.TheMessagingPermissionDialog.PushNotifications.allow" />
        </v-btn>
        <v-spacer />
        <v-btn
            color="error"
            :disabled="loading"
            text
            outlined
            @click="onClickNo"
        >
          <i18n path="views.TheMessagingPermissionDialog.PushNotifications.notallow" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  FCM_DECISION,
  FCM_REMEMBER
} from '@/constants/StorageKeys'
import {
  useCordova
} from '@/Fcm'

class PermissionError extends Error {}
class TokenError extends Error {}
class StoreError extends Error {}

export default {
  name: 'TheMessagingPermissionDialog',
  data: () => ({
    loading: false,
    remember: false,
    madeDecision: false,
    useCordova: useCordova(),
    past: {
      remember: false,
      madeDecision: false
    },
    dirty: false,
    error: {
      request: false,
      permission: false,
      store: false
    },
    ready: true
  }),
  computed: {
    cOpen () {
      return !this.useCordova &&
        this.ready &&
        !this.madeDecision &&
        !(
          this.past.madeDecision &&
          this.past.remember
        )
    }
  },
  created () {
    this.readStorage()
    this.$fcm.hasPermission()
      .then(hasPermission => {
        this.ready = true

        if (hasPermission) {
          this.getToken()
            .then(this.storeToken.bind(this))
            .then(() => {
              this.listenToMessages()
              return true
            })
        } else if (this.useCordova) {
          this.requestPermission()
            .then(this.getToken.bind(this))
            .then(this.storeToken.bind(this))
            .then(() => {
              this.listenToMessages()
            })
        }
      })
  },
  methods: {
    onClickYes () {
      this.allow()
    },
    onClickNo () {
      this.decline()
    },
    readStorage () {
      this.past.remember = localStorage.getItem(FCM_REMEMBER) === 'true'
      this.past.madeDecision = localStorage.getItem(FCM_DECISION) === 'true'
    },
    makeDecision () {
      this.madeDecision = true
      localStorage.setItem(FCM_DECISION, 'true')
    },
    rememberDecision () {
      localStorage.setItem(FCM_REMEMBER, this.remember)
    },
    allow () {
      this.loading = true

      this.requestPermission()
        .then(this.getToken.bind(this))
        .then(this.storeToken.bind(this))
        .then(() => {
          this.makeDecision()
          this.rememberDecision()
          this.listenToMessages()
        })
        .catch(err => {
          this.error.permission = err instanceof PermissionError
          this.error.request = err instanceof TokenError
          this.error.store = err instanceof StoreError
        })
        .finally(() => {
          this.loading = false
        })
    },
    decline () {
      this.makeDecision()
      this.rememberDecision()
    },
    requestPermission () {
      return this.$fcm.requestPermission()
    },
    getToken () {
      return new Promise((resolve, reject) => {
        this.$fcm.getToken()
          .then((currentToken) => {
            if (currentToken) {
              resolve(currentToken)
            } else {
              reject(new TokenError('No Instance ID token available.'))
            }
          })
          .catch((err) => {
            reject(new TokenError(err.message))
          })
      })
    },
    listenToMessages () {
      // this.$fcm.addMessageListener(this.$root.onFirebaseMessage.bind(this.$root))
    },
    storeToken (token) {
      return new Promise((resolve, reject) => {
        this.$http.$api.put(`/device-tokens/${token}`)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject(new StoreError('Could not store token'))
          })
      })
    }
  }
}
</script>

<style scoped>
</style>
