import Vue from 'vue'

import {
  http
} from '@/plugins/http'
import {
  fcm
} from '@/plugins'
import {
  HttpHeader,
  StorageKeys
} from '@/constants'
import { notifications } from '@/plugins/store/players/current/notifications'
import { position } from '@/plugins/store/players/current/position'
import { groups } from '@/plugins/store/players/current/groups'
import { FCM_PERMISSION_GRANTED } from '@/constants/StorageKeys'

export const current = {
  namespaced: true,
  state: {
    result: null
  },
  getters: {
    userProp: (state) => prop => state.result?.data ? state.result.data[prop] : null,
    authenticated: (state, getters) => getters.id !== null,
    id: (state, getters) => getters.userProp('id'),
    name: (state, getters) => getters.userProp('name'),
    email: (state, getters) => getters.userProp('email'),
    newEmail: (state, getters) => getters.userProp('new_email'),
    hasVerifiedEmail: (state, getters) => getters.userProp('has_verified_email'),
    enableGeolocation: (state, getters) => getters.userProp('enable_geolocation'),
    longitude: (state, getters) => getters.userProp('longitude'),
    latitude: (state, getters) => getters.userProp('latitude'),
    long: (state, getters) => getters.userProp('longitude'),
    lat: (state, getters) => getters.userProp('latitude'),
    createdAt: (state, getters) => getters.userProp('created_at'),
    updatedAt: (state, getters) => getters.userProp('updated_at'),
    about: (state, getters) => getters.userProp('about'),
    groups: (state, getters) => getters.userProp('groups'),
    groupsAdmin: (state, getters) => getters.userProp('groups_admin'),
    friends: (state, getters) => getters.userProp('friends'),
    idols: (state, getters) => getters.userProp('idols'),
    fans: (state, getters) => getters.userProp('fans'),
    unreadNotifications: (state, getters) => getters.userProp('unread_notifications_count'),
    unreadConversations: (state, getters) => getters.userProp('unread_conversations_count'),
    notificationWeight: (state, getters) => getters.userProp('notification_weight'),
    notificationTypes: (state, getters) => getters.userProp('notification_types'),
    timeAccount: (state, getters) => getters.userProp('time_account'),
    talents: (state, getters) => getters.userProp('talents'),
    titlePictureFeed: (state, getters) => getters.userProp('title_picture_feed'),
    titlePictureFull: (state, getters) => getters.userProp('title_picture_full'),
    language: (state, getters) => getters.userProp('language') ?? 'en'
  },
  mutations: {
    setResult (state, payload) {
      Vue.set(state, 'result', payload)
    },
    invalidate (state) {
      Vue.set(state, 'result', null)
    }
  },
  actions: {
    update ({ commit }) {
      return new Promise((resolve, reject) => {
        http.$api.head('/players/current', { validateStatus: status => status < 500 })
          .then(response => http.$api.get(response.request.responseURL))
          .then(response => {
            if (response.status > 199 && response.status < 400) {
              commit('setResult', response)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((e) => {
            commit('invalidate')

            reject(e)
          })
      })
    },
    login ({ dispatch }, credentials) {
      return http.$api.post('/api-tokens', {
        ...credentials
      })
        .then((result) => {
          return result.data
        })
        .then((data) => {
          const plainTextToken = data.token
          localStorage.setItem(StorageKeys.API_TOKEN, plainTextToken)
          http.$api.axios.defaults.headers.common[HttpHeader.AUTHORISATION] = `Bearer ${plainTextToken}`

          return data
        })
        .then(() => {
          return dispatch('update')
        })
    },
    logout ({ dispatch, commit }) {
      return dispatch('deleteFcmToken')
        .then(() => dispatch('deleteApiToken'))
        .finally(() => {
          localStorage.removeItem(StorageKeys.API_TOKEN)
          delete http.$api.axios.defaults.headers.common[HttpHeader.AUTHORISATION]
          commit('invalidate')
        })
    },
    deleteFcmToken: () => new Promise((resolve, reject) => {
      if (Notification.permission === FCM_PERMISSION_GRANTED) {
        fcm.getToken()
          .then((currentToken) => http.$api.delete(`/device-tokens/${currentToken}`))
          .then(() => resolve())
          .catch(err => reject(err))
      } else {
        resolve()
      }
    }),
    deleteApiToken: () => http.$api.delete('/api-tokens/current')
  },
  modules: {
    notifications,
    position,
    groups
  }
}
