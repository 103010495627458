<template>
  <eins-layout-list-item
      :item-type="'group'"
      :to="cTo"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template #image>
      <eins-api-image
          #default="{ src }"
          :src="cPicture"
      >
        <v-img
            :src="src"
            aspect-ratio="1"
            max-height="100%"
        >
          <template #placeholder>
            <v-skeleton-loader
                type="image"
            />
          </template>
        </v-img>
      </eins-api-image>
    </template>

    <template #icons>
      <v-col class="flex-grow-0">
        <slot name="indicator1">
          <v-icon
              class="mt-n1"
              color="white"
          >{{ group.group_type.icon }}</v-icon>
        </slot>
      </v-col>
      <v-col class="flex-grow-0">
        <slot name="indicator2">
          <eins-icon-group-member
              class="mt-n1"
              color="white"
              :state="group.membership_state"
          />
        </slot>
      </v-col>
      <v-col class="flex-grow-0">
        <slot name="indicator3">
          <div
              class="caption mb-n1 pt-0 pb-0"
              style="line-height: 1.1;"
          >
            {{ cDistance.value }}<br />
            {{ cDistance.unit }}
          </div>
        </slot>
      </v-col>
    </template>

    <template #title>
      {{ group.title }}
    </template>

    <slot
        name="content"
        :item="group"
    >
      {{ cDescription }}
    </slot>
  </eins-layout-list-item>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsIconGroupMember from '@/components/icons/EinsIconGroupMember'
import EinsLayoutListItem from '@/components/layout/EinsLayoutListItem'

const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-group.gif`

const regHTML = /<\/?[^>]*>/g

const descriptionMaxLength = 70

export default {
  name: 'EinsGroupListItem',
  components: {
    EinsApiImage,
    EinsLayoutListItem,
    EinsIconGroupMember
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
      required: true
    },
    to: {
      type: [String, Boolean],
      default: null
    }
  },
  data: () => ({
  }),
  computed: {
    cTo () {
      if (this.to === false) {
        return null
      } else if (
        typeof this.to === 'string' &&
        this.to.trim().length > 0
      ) {
        return this.to
      } else {
        return `/groups/${this.group.id}`
      }
    },
    cPicture () {
      return this.group?.title_picture_feed ?? genericPicture1
    },
    cDistance () {
      let distance = this.group.distance
      let format = 'distance-meter'

      if (distance > 1000) {
        // decimal places: 1 => / Round(d/100)/10
        distance = Math.round(distance / 100) / 10
        format = 'distance-kilometer'
      }

      return {
        value: this.$n(distance, 'decimal'),
        unit: format === 'distance-kilometer' ? 'km' : 'm'
      }
    },
    cDescription () {
      let description = (this.group.description || '').replace(regHTML, '')
      if (description.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }
      return description
    },
    cHighlight () {
      let result = []

      if (this.group.quests_open_count > 0) {
        result.push(this.$vuetify.theme.parsedTheme.quest.base)
      }
      if (this.group.deals_open_count > 0) {
        result.push(this.$vuetify.theme.parsedTheme.deal.base)
      }

      // return result
      return 'var(--v-group-base)'
    }
  }
}
</script>

<style scoped lang="scss">
</style>
