<template>
  <div
      ref="content"
      v-scroll:#main-content-scroll="onScroll"
  >
    <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
      <v-slide-y-transition>
        <v-progress-linear
            v-show="cLoading"
            height="5"
            indeterminate
        />
      </v-slide-y-transition>
      <eins-form-password-reset></eins-form-password-reset>
    </div>
  </div>
</template>

<script>
import EinsFormPasswordReset from '../components/auth/EinsFormPasswordReset'

export default {
  name: 'ViewResetPassword',
  components: { EinsFormPasswordReset },
  computed: {
    cLoading () {
      return false
    }
  },
  methods: {
    onScroll (e) {
    }
  }
}
</script>

<style scoped>

</style>
