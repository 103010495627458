<template>
  <eins-edit-group-scope-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      api-param="player_group_id"
      :current-value="cDealScope"
      :current-value-label="cText.currentValueLabel"
      :current-value-hint="cText.currentValueHint"
      :dialog-title="cText.dialogTitle"
      :submit-label="cText.submitLabel"
      :success-message="cSuccessMessage"
      :list-caption="cText.listCaption"
      :void-option="cVoidOption"
  />
</template>

<script>
import EinsEditGroupScopeBlock from '@/components/form/edit/EinsEditGroupScopeBlock'

export default {
  name: 'EinsDealDataScopeBlock',
  components: {
    EinsEditGroupScopeBlock
  },
  data: () => ({
  }),
  computed: {
    cText () {
      return {
        dialogTitle: this.$t('deal.update.scope.dialogTitle'),
        listCaption: this.$t('deal.update.scope.listCaption'),
        currentValueLabel: this.$t('deal.update.scope.currentValueLabel'),
        currentValueHint: !this.cDealScope
          ? this.$t('deal.update.scope.currentValueHintEmpty')
          : this.cCanHaveGroupDeal
            ? this.$t('deal.update.scope.currentValueHintExtended')
            : this.$t('deal.update.scope.currentValueHintSimple'),
        submitLabel: this.$t('deal.update.scope.actions.submit')
      }
    },
    cCanHaveGroupDeal () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []

      return adminGroups.includes(this.cDealScope)
    },
    cVoidOption () {
      return {
        label: this.$t('deal.update.scope.voidOption')
      }
    },
    cApiPath () {
      return `deals/${this.cDealId}`
    },
    cDealId () {
      return this.$store.getters['deals/selected/deal']?.id
    },
    cDealScope () {
      return this.$store.getters['deals/selected/deal']?.player_group_id ?? null
    },
    cSuccessMessage () {
      return {
        title: this.$t('deal.update.scope.success.title'),
        text: this.$t('deal.update.scope.success.text')
      }
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('deals/selected/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
