<template>
  <v-row
      no-gutters
  >
    <!-- Orientation depends on the sender, current user is right, others reversed -->
    <!-- Max width 10/12 -->
    <v-col cols="12">
      <v-row
          v-if="!cMyMessage"
          no-gutters
      >
        <!-- Shrink message width to needed space, reverse depending on sender -->
        <ein-chat-message-list-bubble
            :message="message"
        />
        <v-spacer />
      </v-row>
      <v-row
          v-else
          no-gutters
      >
        <v-spacer />
        <!-- Shrink message width to needed space, reverse depending on sender -->
        <ein-chat-message-list-bubble-left
            :message="message"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import { MxMessage } from '@/components/chat/MxMessage'
import EinChatMessageListBubble from '@/components/chat/EinChatMessageListBubble'
import EinChatMessageListBubbleLeft from './EinChatMessageListBubbleLeft.vue'

export default {
  name: 'EinMessageListsRow',
  components: { EinChatMessageListBubble, EinChatMessageListBubbleLeft },
  props: {
    message: {
      type: Object,
      required: true
    },
    user: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    cOwnerId () { return this.$store.getters['players/current/id'] },
    cMyMessage () {
      return this.message?.sender?.id === this.cOwnerId
    }
  }
}
</script>

<style scoped>
.rowColor{
  color:rgb(53, 49, 49);
  background-color: white;
}
</style>
