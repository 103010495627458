<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-qrcode</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  Entity
} from '@/constants'
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbQrCode',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        case 'quest-detail':
          return Entity.QUEST
        case 'group-detail':
          return Entity.GROUP
        case 'player-detail':
          return Entity.PLAYER
        default:
          return null
      }
    },
    cEntity () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest']
          break
        case Entity.GROUP:
          result = this.$store.getters['groups/selected/group']
          break
        case Entity.PLAYER:
          result = this.$store.getters['players/selected/player']
          break
        default:
          return null
      }

      return result
    },
    cShowBtn () {
      return this.cBelongsToRoute
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogQrCode')
    }
  }
}
</script>

<style scoped>
</style>
