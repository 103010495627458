<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <i18n path="player.update.gps.title" />
      </v-list-item-title>
      <v-list-item-subtitle>
        <i18n
            v-if="cUserGpsEnabled"
            path="player.update.gps.subTitlePositive"
        />
        <i18n
            v-else
            path="player.update.gps.subTitleNegative"
        />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
          v-model="internalValue"
          :loading="loading"
          @change="onInput"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'EinsPlayerDataGpsBlock',
  components: {
  },
  data: () => ({
    internalValue: false,
    loading: false,
    gpsActive: false,
    permissionError: false
  }),
  computed: {
    cUserGpsEnabled () {
      return this.$store.getters['players/current/enableGeolocation']
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    }
  },
  watch: {
    cUserGpsEnabled: {
      immediate: true,
      handler: 'onChangeUserGpsSetting'
    }
  },
  methods: {
    onChangeUserGpsSetting () {
      this.setCurrentValue()
    },
    setCurrentValue () {
      this.internalValue = this.cUserGpsEnabled
    },
    onInput (value) {
      if (value !== this.cUserGpsEnabled) {
        this.$nextTick().then(() => this.setCurrentValue())
        this.updateApi()
      }
    },
    updateApi () {
      this.loading = true
      this.$http.$api.patch(`players/${this.cUserId}`, {
        enable_geolocation: !this.cUserGpsEnabled
      })
        .then(() => this.$store.dispatch('players/current/update'))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
