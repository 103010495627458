<template>
  <v-btn
      :href="`https://twitter.com/intent/tweet?text=${cShareText}&url=${cUrl}&via=${cTwitterProfile}`"
      target="_blank"
      color="#00aced"
      v-bind="$attrs"
  >
    <v-icon left>mdi-twitter</v-icon>{{ cText['text'] }}
  </v-btn>
</template>

<script>
import { Social } from '@/constants'
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsSocialShareTwitter',
  mixins: [
    MxTranslatable
  ],
  props: {
    shareUrl: {
      type: String,
      required: true
    },
    shareText: {
      type: String,
      required: true
    }
  },
  data: () => ({
    $_mx_translatable: {
      paths: [
        'text'
      ],
      params: {}
    }
  }),
  computed: {
    cTwitterProfile () {
      return encodeURIComponent(Social.TWITTER_PROFILE)
    },
    cUrl () {
      return encodeURIComponent(this.shareUrl)
    },
    cShareText () {
      return encodeURIComponent(this.shareText)
    }
  }
}
</script>

<style scoped>

</style>
