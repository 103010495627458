<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-delete-outline</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbDeleteGroup',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
        this.cUserIsOwner &&
        this.cGroupCanBeDeleted
    },
    cUserIsOwner () {
      const userId = this.$store.getters['players/current/id']
      return this.cGroup?.owner_id === userId
    },
    cGroupCanBeDeleted () {
      return this.cGroup?.group_type.can_get_deleted
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogDeleteGroup')
        .then(() => {
          return this.$store.dispatch('groups/selected/update')
        })
    }
  }
}
</script>

<style scoped>
</style>
