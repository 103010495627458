<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column"
        style="height: 100%;"
        no-gutters
    >
      <v-col
          v-if="cPlayer"
          class="flex-grow-0 flex-shrink-1"
      >
        <v-container>
          <v-row dense>
            <v-col class="flex-grow-0">
              <eins-api-image
                  #default="{ src }"
                  :src="cPicture"
              >
                <v-img
                    :src="src"
                    aspect-ratio="1"
                    width="75"
                >
                  <template #placeholder>
                    <v-skeleton-loader
                        type="image"
                    />
                  </template>
                </v-img>
              </eins-api-image>
            </v-col>
            <v-col class="text-h5">
              {{ cPlayer.name }}
            </v-col>
          </v-row>
          <v-text-field
              v-model="textFilter"
              solo
              hide-details
          >
            <template #label>
              <i18n path="views.ViewPlayerQuests.textFilter.label" />
            </template>
            <template #prepend-inner>
              <v-icon
                  v-if="cOwnProfile"
                  color="white"
              >mdi-account-box</v-icon>
              <v-icon
                  v-else
                  color="primary"
              >mdi-account</v-icon>
              <v-icon color="primary">mdi-map-marker</v-icon>
            </template>
          </v-text-field>
        </v-container>
      </v-col>
      <v-col style="overflow-y: auto; height: 100%;">
        <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
          <v-slide-y-transition>
            <v-progress-linear
                v-show="cLoading"
                height="5"
                indeterminate
            />
          </v-slide-y-transition>
        </div>
        <eins-quest-list
            #item="{ item }"
            :quests="cQuests"
        >
          <eins-quest-list-item-player-quests
              :quest="item"
              :quests="cQuests"
          />
        </eins-quest-list>
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsQuestList from '@/components/quest/EinsQuestList'
import EinsApiImage from '@/components/EinsApiImage'
import EinsQuestListItemPlayerQuests from '@/components/quest/EinsQuestListItemPlayerQuests'
import genericPicture1 from '@/assets/img/group/unknown-1.jpeg'

export default {
  name: 'ViewPlayerQuests',
  components: {
    EinsQuestListItemPlayerQuests,
    EinsApiImage,
    EinsQuestList,
    EinsLayoutViewDetail
  },
  data: () => ({
    lastPosition: 0,
    textFilter: '',
    isLoading: false
  }),
  computed: {
    cPicture () {
      return this.cPlayer && this.cPlayer.title_picture_feed ? this.cPlayer.title_picture_feed : genericPicture1
    },
    cPlayerRoute () {
      return this.$route.name === 'player-quests'
    },
    cPlayerIsOwner () {
      return this.$store.getters['players/current/id']
    },
    cQuests () {
      const key = this.cPlayerRoute ? 'questsRelated' : 'quests'
      const textFilterLc = this.textFilter.toLowerCase()
      let quests = this.$store.getters[`players/selected/${key}/list`] ?? []
      quests = quests.filter(el => {
        return this.textFilter?.length === 0 || el.title.toLowerCase().includes(textFilterLc)
      })
        .sort((a, b) => b.applications_count - a.applications_count || b.state.localeCompare(a.state))

      return quests
    },
    cRouteId () {
      return this.$route?.params?.id ?? 0
    },
    cLoading () {
      const key = this.cPlayerRoute ? 'questsRelated' : 'quests'
      return this.$store.getters['players/selected/loading'] ||
          this.$store.getters[`players/selected/${key}/listLoading`]
    },
    cError () {
      return this.$store.getters['players/selected/error']
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    },
    cPlayerIsAdmin () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []
      return adminGroups.includes(this.cPlayer.id)
    },
    cOwnProfile () {
      return this.cPlayer.id === this.$store.getters['players/current/id']
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.clearCache()
      vm.loadQuestList()
      vm.loadPlayer(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.clearCache()
      this.loadQuestList()
      this.loadPlayer(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadPlayer(this.$route.params.id)
    },
    clearCache () {
      const key = this.cPlayerRoute ? 'questsRelated' : 'quests'
      return this.$store.commit(`players/selected/${key}/clearCache`)
    },
    loadPlayer (id) {
      return this.$store.dispatch('players/selected/load', id)
        .then(this.loadQuestList)
    },
    loadQuestList () {
      const key = this.cPlayerRoute ? 'questsRelated' : 'quests'
      return this.$store.dispatch(`players/selected/${key}/loadAll`)
    }
  }
}
</script>

<style scoped>
</style>
