<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      to="/quests/create"
      icon="mdi-map-marker-multiple"
      :text="cText.text"
  />
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbQuestTemplates',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
