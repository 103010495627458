<template>
  <eins-layout-view-detail>
    <eins-quest-detail-content
        v-if="!cError"
        :quest="cQuest"
    />
    <eins-error-not-found
        v-else-if="cError === 404"
    >
      <i18n path="views.ViewQuestDetail.theQuest" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>
      </template>
    </eins-error-not-found>
    <eins-error-forbidden
        v-else-if="cError === 403"
    >
      <i18n path="views.ViewQuestDetail.theQuestLc" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>!
      </template>
    </eins-error-forbidden>
  </eins-layout-view-detail>
</template>

<script>
import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsQuestDetailContent from '@/components/quest/EinsQuestDetailContent'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'

export default {
  name: 'ViewQuestDetail',
  components: {
    EinsErrorForbidden,
    EinsErrorNotFound,
    EinsLayoutViewDetail,
    EinsQuestDetailContent
  },
  computed: {
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      return this.$store.getters['quests/selected/loading']
    },
    cError () {
      return this.$store.getters['quests/selected/error']
    },
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadQuest(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadQuest(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadQuest(this.$route.params.id)
    },
    loadQuest (id) {
      return this.$store.dispatch('quests/selected/load', id)
    }
  }
}
</script>

<style scoped>

</style>
