<template>
  <eins-layout-list-item
      :item-type="'deal'"
      :to="`/deals/${cPlayerId}`"
  >
    <template #image>
      <eins-api-image
          #default="{ src }"
          :src="cPicture"
      >
        <v-img
            :src="src"
            aspect-ratio="1"
            max-height="100%"
        >
          <template #placeholder>
            <v-skeleton-loader
                type="image"
            />
          </template>
        </v-img>
      </eins-api-image>
    </template>

    <template #icons>
      <v-col class="flex-grow-0">
        <v-icon
            v-if="cGroup !== false"
            color="white"
        >{{ cGroupIcon }}</v-icon>
        <v-icon
            v-else
            color="transparent"
        >mdi-stop</v-icon>
      </v-col>
      <v-spacer />
      <v-col>
        <eins-icon-friendship
            width="1.5em"
            height="1.5em"
            :state="cUserFriendshipState"
            class="d-inline-block"
        />
      </v-col>
      <v-spacer />
      <slot name="indicator3">
        <v-col>
          <div
              class="caption mb-n1 pt-0 pb-0"
              style="line-height: 1.1;"
          >
            {{ cDistance.value }}<br />
            {{ cDistance.unit }}
          </div>
        </v-col>
      </slot>

      <v-spacer />
    </template>

    <template #title>
      {{ deal.title }}
    </template>

    <slot
        name="content"
        :item="deal"
    >
      {{ cDescription }}
    </slot>
    <template #bonus>
      <eins-list-item-time-points
          :value="deal.value"
          :per-row="20"
          points="60"
          active-icon-color="var(--v-deal-base)"
          icon-color="var(--v-dealoff-base)"
      />
    </template>
  </eins-layout-list-item>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsLayoutListItem from '@/components/layout/EinsLayoutListItem'
import { FriendshipState } from '@/constants'

import {
  RegHtml
} from '@/utils/regex'
import EinsListItemTimePoints from '../time/EinsListItemTimePoints'

const regHTML = RegHtml

const descriptionMaxLength = 70

const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-deal.gif`

export default {
  name: 'EinsDealListItem',
  components: {
    EinsListItemTimePoints,
    EinsApiImage,
    EinsIconFriendship,
    EinsLayoutListItem
  },
  props: {
    deal: {
      type: Object,
      default: () => ({}),
      required: false
    }
  },
  data: () => ({
  }),
  computed: {
    cPicture () {
      return this.deal?.title_picture_feed ?? genericPicture1
    },
    cPlayerId () { return this.deal?.id ?? '' },
    cGroup () { return this.deal?.is_group_deal },
    cGroupIcon () { return this.deal?.group?.group_type.icon },

    cUserFriendshipState () {
      return this.deal?.player?.friendship_state ?? FriendshipState.NONE
    },
    cDistance () {
      let distance = this.deal?.distance ?? null
      let format = 'distance-meter'

      if (distance > 1000) {
        // decimal places: 1 => / Round(d/100)/10
        distance = Math.round(distance / 100) / 10
        format = 'distance-kilometer'
      }

      return {
        value: this.$n(distance, 'decimal'),
        unit: format === 'distance-kilometer' ? 'km' : 'm'
      }
    },
    cDescription () {
      let description = this.deal?.description.replace(regHTML, '')
      if (description?.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }

      return description ?? ''
    },
    cHighlight () {
      /*
      const type = this.deal?.type
      if (type === DealType.PRODUCT) {
        // service
        return colors.teal.base
      } else if (type === DealType.SERVICE) {
        // product
        return colors.lightBlue.base
      } else {
        return []
      }
       */
      return '#afaf19'
    }
  }
}
</script>
