export default {
  captions: {
    caption: 'Eigenes Profil aktualisieren',
    talents: 'Talente',
    saving: 'Deine @.lower:(terms.player) Profile Aktuallisierung wurde gespeichert'
  },
  fields: {
    name: {
      label: ' @.lower:(terms.player) Name',
      overlay: 'Bitte wähle Deinen neuen @.lower:(terms.player) Name'
    },
    gps: {
      label: 'GPS'
    },
    language: {
      label: 'Spracje',
      overlay: 'Bitte wähle Deine @.lower:(terms.player) Sprache'
    },
    notification: {
      weight: 'Level',
      type: 'Typ'
    },
    description: {
      label: 'Beschreibung',
      overlay: 'Bitte beschreibe deinen @.lower:(terms.player)'
    },
    newEMail: {
      label: 'Neue e-mail Adresse'
    },
    eMail: {
      label: 'e-mail Adresse',
      placeholder: 'e-mail Adresse',
      hint: 'Bitte geben Sie eine korrekte E-Mail-Adresse ein',
      overlay: {
        label: 'Bitte wähle Deine neue @.lower:(terms.player) E-Mail',
        text: 'Bitte geben Sie Ihre eMail ein und senden Sie die Anfrage ab. \n' +
          'Wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihrer E-Mail',
        buttons: {
          confirm: {
            label: 'E-Mail zurücksetzen senden'
          }
        }
      }
    }
  },
  buttons: {
    reset: {
      label: 'Zurücksetzen \n  Passwort'
    },
    setNewPassword: {
      label: 'Passwort festlegen'
    },
    addTalent: {
      label: 'Hinzufügen'
    },
    eMail: {
      label: 'zurückgesetzte \n Email'
    },
    image: {
      label: ''
    }
  },
  dialogs: {
    resetPassword: {
      caption: 'Du benötigst ein neues Passwort?',
      text: 'Bitte gebe Deine eMail ein und sende Deine Passwortanfrage. \n' +
        'Wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihrer E-Mail',
      buttons: {
        cancel: 'Abbrechen',
        reset: 'Passwort zurücksetzen senden'
      }
    },
    setPassword: {
      caption: 'Du benötigst ein neues Passwort?',
      text: 'Bitte gebe Dein aktuelles Passwort ein, das neue Passwort ein und bestätige dies.',
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Passwort senden'
      }
    },
    addTalent: {
      caption: 'Talent hinzufügen',
      subtitle: 'Was ist Dein Talent und weshalb setzt Du es gern ein?',
      fields: {
        name: {
          label: 'Talent Name',
          overlay: 'Bitte wähle einen Talent Name für Deinen @.lower:(terms.player)'
        },
        description: {
          label: 'Beschreibung'
        }
      },
      buttons: {
        cancel: {
          label: 'Abbrechen'
        },
        submit: {
          label: 'Zufügen'
        }
      }
    }
  },
  overlay: {
    buttons: {
      cancel: {
        label: 'Abbrechen'
      },
      delete: {
        label: 'Löschen'
      },
      confirm: {
        label: 'OK'
      }
    }
  },
  overlays: {
    image: {
      title: 'Bitte wählen Sie ein Bild aus',
      subtitle: '',
      preview: 'Vorschau',
      placeholder: 'Wähle Dein @.lower:(terms.player) Bild',
      buttons: {
        upload: {
          label: 'Bild hochladen'
        }
      }
    }
  }
}
