<template>
  <eins-starmenu-dpb
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-human-greeting"
      :text="cText.text"
  />
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuDpb from './EinsStarmenuDpb'
import {
  Entity
} from '@/constants'

export default {
  name: 'EinsStarmenuDpbApplications',
  components: {
    EinsStarmenuDpb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cItem () {
      switch (this.cArea) {
        case Entity.GROUP:
          return this.$store.getters['groups/selected/group']
        case Entity.DEAL:
          return this.$store.getters['deals/selected/deal']
        case Entity.QUEST:
          return this.$store.getters['quests/selected/quest']
        default:
          return null
      }
    },
    cShowBtn () {
      return this.cBelongsToRoute && (
        ( // group admins can accept applications
          this.cArea === Entity.GROUP &&
          this.$store.getters['players/current/groupsAdmin']
            .includes(this.cItem?.id)
        ) ||
        ( // owners of deals/quests can accept applications
          this.cArea !== Entity.GROUP &&
          this.cIsOwner
        )
      )
    },
    cIsOwner () {
      if ([Entity.QUEST, Entity.DEAL].includes(this.cArea)) {
        return this.cItem.player.id === this.$store.getters['players/current/id']
      } else if (this.cArea === Entity.GROUP) {
        return this.cItem.owner_id === this.$store.getters['players/current/id']
      } else {
        return false
      }
    },
    cTarget () {
      let part = null
      switch (this.cArea) {
        case Entity.GROUP:
          part = 'groups'
          break
        case Entity.DEAL:
          part = 'deals'
          break
        case Entity.QUEST:
          part = 'quests'
          break
      }

      if (this.cShowBtn && part !== null) {
        return `/${part}/${this.cItem.id}/applications`
      } else {
        return ''
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
