export default {
  general: {
    title: 'Allgemein',
    description: 'Allgemein'
  },
  imposter: {
    title: 'BetrügerIn',
    description: 'Die @:(terms.player) ist ein Betrüger'
  },
  scam: {
    title: 'Betrug',
    description: 'Das ist Betrug.'
  },
  name: {
    title: 'Name',
    description: 'Unpassender Name'
  },
  harassment: {
    title: 'Harassment',
    description: 'The person is harassing me'
  },
  'inappropriate-content': {
    title: 'Unangemessener Inhalt',
    description: 'Der bereitgestellte Inhalt ist nicht angemessen'
  }
}
