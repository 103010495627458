export default {
  labels: {
    title: '@.lower:(terms.points)',
    overlayTitle: 'Preis in @.lower:(terms.points)',
    overlaySubTitle: '1 @.lower:(terms.point) ~ 1,- €',
    point: '@.lower:(terms.point)',
    points: '@.lower:(terms.points) ',
    equal: '=',
    minute: 'Minute',
    minutes: 'Minuten',
    star: '@.lower:(terms.star)',
    stars: '@.lower:(terms.stars)',
    and: 'und',
    BalanceTitle: '@.lower:(terms.points) die Du für den @.lower:(terms.deal) erhältst'
  }
}
