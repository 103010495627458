import Vue from 'vue'
import * as constants from '@/constants'

Vue.use({
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install (VueInstance) {
    VueInstance.prototype.$const = Object.freeze(Object.seal({ ...constants }))
  }
})
