<template>
  <eins-player-list-item :player="contact">
    <template #content>
      <v-row
          dense
          class="fill-height"
          align-content="end"
      >
        <v-col>
          <v-btn
              block
              color="primary"
              outlined
              @click.stop.prevent="onClickStartConversation"
          >
            <v-icon>mdi-message-text</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              block
              color="primary"
              :loading="loading.friendship"
              outlined
              @click.stop.prevent="onClickUpdateState"
          >
            <eins-icon-friendship
                width="24"
                height="24"
                contain
                :state="cIconState"
            />
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #indicator3>
      <v-icon color="transparent">mdi-square</v-icon>
    </template>
  </eins-player-list-item>
</template>

<script>
import EinsPlayerListItem from '@/components/player/EinsPlayerListItem'
import {
  FriendshipState
} from '@/constants'
import {
  startConversationWith
} from '@/utils/chat'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'

export default {
  name: 'EinsPlayerListItemContact',
  components: {
    EinsIconFriendship,
    EinsPlayerListItem
  },
  props: {
    contact: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    loading: {
      friendship: false
    }
  }),
  computed: {
    cFriendshipState () {
      return this.contact?.friendship_state
    },
    cIsFollower () {
      return (this.$store.getters['players/current/idols'] ?? [])
        .concat(this.$store.getters['players/current/friends'] ?? [])
        .includes(this.contact.id)
    },
    cIconState () {
      switch (this.cFriendshipState) {
        case FriendshipState.NONE: return FriendshipState.IDOL
        case FriendshipState.IDOL: return FriendshipState.NONE
        case FriendshipState.FRIEND: return FriendshipState.FAN
        case FriendshipState.FAN: return FriendshipState.FRIEND
        default: return FriendshipState.ME
      }
    }
  },
  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    startConversation () {
      return startConversationWith(this.contact.id)
    },
    onClickUpdateState () {
      this.updateState()
    },
    updateState () {
      const method = this.cIsFollower ? 'delete' : 'put'

      this.loading.friendship = true
      return this.$http.$api[method](`contacts/${this.contact.id}`)
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('players/selected/contacts/loadAll')
          ])
        })
        .finally(() => {
          this.loading.friendship = false
        })
    }
  }
}
</script>

<style scoped>

</style>
