<template>
  <eins-edit-location-block
      :api-url="cApiUrl"
      :after-update="updateQuest"
      :aspect-ratio="aspectRatio"
      :dialog-title-address="cDialogTitle.address"
      :submit-label-address="cSubmitLabel.address"
      :success-message-address="cSuccessMessage.address"
      :success-message-position="cSuccessMessage.position"
      :entity="cDeal"
      :height="height"
  />
</template>

<script>
import EinsEditLocationBlock from '@/components/form/edit/EinsEditLocationBlock'

export default {
  name: 'EinsDealDataLocationBlock',
  components: {
    EinsEditLocationBlock
  },
  props: {
    aspectRatio: {
      type: String,
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
  }),
  computed: {
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cApiUrl () {
      return `deals/${this.cDealId}`
    },
    cDealId () {
      return this.cDeal?.id
    },
    cDialogTitle () {
      return {
        address: this.$t('deal.update.location.address.dialogTitle')
      }
    },
    cSubmitLabel () {
      return {
        address: this.$t('deal.update.location.address.actions.submit')
      }
    },
    cSuccessMessage () {
      return {
        address: {
          title: this.$t('deal.update.location.address.success.title'),
          text: this.$t('deal.update.location.address.success.text')
        },
        position: {
          title: this.$t('deal.update.location.position.success.title'),
          text: this.$t('deal.update.location.position.success.text')
        }
      }
    }
  },
  methods: {
    updateQuest () {
      return this.$store.dispatch('deals/selected/update')
    }
  }
}
</script>

<style scoped>

</style>
