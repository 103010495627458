<template>
  <v-overlay
      v-model="open"
      @click.native="onClickOverlay"
  >
    <v-row no-gutters>
      <v-spacer />
      <v-col>
        <v-row
            class="flex-column text-center"
            dense
        >
          <v-col>
            <eins-social-share-facebook
                :share-url="cShareUrl"
                share-text="Testtext"
            />
          </v-col>
          <v-col>
            <eins-social-share-twitter
                :share-url="cShareUrl"
                share-text="Testtext"
            />
          </v-col>
          <v-col>
            <eins-social-share-whatsapp
                :share-url="cShareUrl"
                share-text="unsere App"
            />
          </v-col>
          <v-col>
            <eins-social-share-pinterest
                :share-url="cShareUrl"
                share-text="Testtext"
            />
          </v-col>
          <v-col>
            <eins-social-share-linkedin
                :share-url="cShareUrl"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-overlay>
</template>

<script>
import EinsSocialShareFacebook from '@/components/social/EinsSocialShareFacebook'
import EinsSocialShareTwitter from '@/components/social/EinsSocialShareTwitter'
import EinsSocialShareWhatsapp from '@/components/social/EinsSocialShareWhatsapp'
import EinsSocialShareLinkedin from '@/components/social/EinsSocialShareLinkedin'
import EinsSocialSharePinterest from '@/components/social/EinsSocialSharePinterest'

import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import { Entity } from '@/constants'

export default {
  name: 'EinsStarmenuDlgShare',
  components: {
    EinsSocialSharePinterest,
    EinsSocialShareLinkedin,
    EinsSocialShareWhatsapp,
    EinsSocialShareTwitter,
    EinsSocialShareFacebook
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'title'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogShare']
      },
      set (value) {
        this.$store.commit('dialogShare', value)
      }
    },
    cEntity () {
      let result
      switch (this.cArea) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest']
          break
        case Entity.GROUP:
          result = this.$store.getters['groups/selected/group']
          break
        default:
          return null
      }

      return result
    },
    cShareUrl () {
      return this.cEntity && this.cEntity.share_link
        ? this.cEntity.share_link
        : 'https://share.1independent.app'
    }
  },
  methods: {
    onClickOverlay () {
      this.close()
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped>

</style>
