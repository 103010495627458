import { render, staticRenderFns } from "./ViewChatList.vue?vue&type=template&id=9474c8bc&scoped=true&"
import script from "./ViewChatList.vue?vue&type=script&lang=js&"
export * from "./ViewChatList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9474c8bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VProgressLinear,VSlideYTransition})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
