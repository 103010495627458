<template>
  <v-overlay
      :value="isOpen"
      opacity="1"
  >
    <v-row
        class="flex-column edit-dialog-content"
        dense
    >
      <v-col>
        <v-form
            ref="form"
            v-model="form.valid"
        >
          <eins-form-notification-type v-model="form.data" />
        </v-form>
      </v-col>
      <v-col v-if="error">
        <v-alert
            type="error"
        >
          Something went wrong!
        </v-alert>
      </v-col>
      <v-col>
        <v-btn
            block
            color="primary"
            :loading="loading"
            text
            @click="onClickSubmit"
        >{{ $t(`notifications.buttons.save`) }}</v-btn>
      </v-col>
      <v-col>
        <v-btn
            block
            :disabled="loading"
            text
            @click="onClickCancel"
        >{{ $t(`notifications.buttons.cancel`) }}</v-btn>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
import EinsFormNotificationType from '@/components/notifications/EinsFormNotificationType'

export default {
  name: 'EinsModuleNotificationTypesOverlay',
  components: {
    EinsFormNotificationType
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    error: false,
    isOpen: false,
    form: {
      data: {},
      valid: false
    },
    loading: false
  }),
  computed: {
    cOriginalTypes () {
      return this.$store.getters['players/current/notificationTypes'] ?? []
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler: 'onOpen'
    },
    value: {
      immediate: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onOpen () {
      this.reset()
    },
    onChangeValue () {
      this.value ? this.open() : this.close()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    submit () {
      this.loading = true
      this.error = false
      const currentPlayerId = this.$store.getters['players/current/id']
      this.$http.$api.patch(`/players/${currentPlayerId}`, this.form.data)
        .then(() => {
          this.$store.dispatch('players/current/update')
          this.close()
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel () {
      this.close()
    },
    open () {
      this.isOpen = true
      this.emitInput()
    },
    close () {
      this.isOpen = false
      this.emitInput()
    },
    emitInput () {
      this.$emit('input', this.isOpen)
    },
    reset () {
      this.error = false
      this.loading = false
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      this.form.data.notification_types = this.cOriginalTypes
    }
  }
}
</script>

<style scoped>
</style>
