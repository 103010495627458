<template>
  <eins-layout-item-detail-content
      v-model="tab"
      object-type="quest"
      :tabs="cTabs"
      @oncontainerscroll="onListScroll"
  >
    <template #information>
      <eins-quest-detail-states-header
          v-if="cAlert !== `mull`"
          :state="cAlert"
          :is-owner="cIsOwner"
      />
    </template>

    <template #image>
      <v-row
          no-gutters
          class="flex-nowrap image-overlay"
      >
        <v-col
            class="overflow-hidden flex-grow-1 flex-shrink-0"
            :style="cImageStyle"
        >
          <eins-api-image
              #default="{ src }"
              :src="cPicture"
          >
            <!-- aspect-ratio="1.875" --->
            <v-img
                :src="src"
                style="aspect-ratio:1;"
            >
              <template #placeholder>
                <v-skeleton-loader
                    tile
                    type="image"
                />
              </template>
              <template v-if="cClosedQuestion">
                <v-icon
                    v-if="cReaction && cQuestReactionClick"
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                    color="red"
                    large
                    :loading="isLoading"
                    @click.stop="toggleLike"
                >{{ cLikeIcon }}</v-icon>
                <v-icon
                    style="position: absolute; top: 5%; left: 5%; transform: translate(-10%, -10%);"
                    color="quest"
                    x-large
                >{{ 'mdi-crown' }}</v-icon>
                <eins-icon-medal
                    style="position: absolute; top: 60px; left: 5%; transform: translate(-10%, -10%); color: var(--v-quest-base)"
                    :type="cMedal"
                    width="42px"
                />
              </template>
            </v-img>
          </eins-api-image>
        </v-col>
        <v-col
            v-show="cShowImageOverlay"
            class="questoff text-center items-center flex-grow-0 flex-shrink-1"
            style="min-width:50px"
            @click="onClickImageOverlay"
        >
          <div
              v-if="cClosedQuestion"
              style="font-size: 12px"
          >
            <p class="mb-0">
              {{ cGetDate }}
            </p>
            <div v-if="cLikeCount">
              <v-icon size="15"> mdi-cards-heart</v-icon> {{ cLikeCount }}<br>
            </div>
            <div v-if="cDislikeCount">
              <v-icon size="15"> mdi-thumb-down</v-icon> {{ cDislikeCount }}
            </div>
          </div>
          <div
              v-if="cGroup !== null"
              class="pt-2"
          >
            <v-icon>{{ cGroup.group_type.icon }}</v-icon>
          </div>
          <div
              v-else
              class="pt-2 pl-2"
          >
            <eins-icon-friendship
                :state="cUserFriendshipState"
                width="34"
            />
          </div>
          <div class="text-truncate pt-2">
            <eins-icon-quest-type
                :type="cType"
                center
            />
          </div>
          <div style="max-width:60px;padding-left:5px;margin: 5px auto 5px auto">
            <eins-time-stars
                v-model="cTimeBonus"
                wrap
                stars="24"
                object-type="quest"
                per-row="2"
            />
          </div>
        </v-col>
        <v-col
            v-show="!cShowImageOverlay"
            class="flex-grow-1 pl-2 text-h6"
        >
          <v-row
              no-gutters
              class="justify-space-between"
          >
            <v-col cols="12">
              <div class="text-truncate questoff">
                <eins-icon-quest-type
                    :type="cType"
                    left
                />{{ cTitle }}
              </div>
            </v-col>
            <v-col
                v-if="cHasMedal && !cClosedQuestion"
                class="flex-grow-0 flex-shrink-1"
            >
              <eins-icon-medal
                  width="30px"
                  :type="cMedal"
                  style="display: inline-block;"
              />
            </v-col>
            <v-col
                v-if="cGroup !== null"
                class="flex-grow-0 flex-shrink-1 text-no-wrap"
            >
              <v-icon>{{ cGroup.group_type.icon }}</v-icon>
              <v-spacer />
            </v-col>
            <v-col
                v-else
                class="flex-grow-0 flex-shrink-1 mt-1"
            >
              <eins-icon-friendship
                  :state="cUserFriendshipState"
                  width="24"
              />
            </v-col>
            <v-col
                v-if="cClosedQuestion"
                style="font-size: 12px;max-height:35px;"
                class="flex-grow-1 flex-shrink-0 text-no-wrap d-inline-flex"
            >
              <p
                  class="ml-1"
              >
                {{ cGetDate }}
              </p>
              <eins-icon-medal
                  v-if="cClosedQuestion"
                  :type="cMedal"
                  class="mt-1 pa-0"
                  width="24px"
              />
              <div v-if="cLikeCount">
                <v-icon size="15"> mdi-cards-heart</v-icon> {{ cLikeCount }}
              </div>
              <div v-if="cDislikeCount">
                <v-icon size="15"> mdi-thumb-down</v-icon> {{ cDislikeCount }}
              </div>
            </v-col>
            <v-col
                v-else
                class="flex-grow-0 flex-shrink-1 text-no-wrap"
            >
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-0 mt-1 pl-2 pr-5 ">
              <eins-time-stars
                  v-model="cTimeBonus"
                  stars="24"
                  per-row="12"
                  reverse
                  star-color="var(--v-questoff-base)"
                  wrap
                  object-type="quest"
              />
            </v-col>
            <v-col class="flex-grow-0 flex-shrink-1 mt-1 text-subtitle-2 pr-1">
              + {{ cTimeBonus / 60 }} Stars
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-if="cClosedQuestion">
      <v-icon
          v-if="cReaction && cQuestReactionClick"
          style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
          color="red"
          large
          :loading="isLoading"
          @click.stop="toggleLike"
      >{{ cLikeIcon }}</v-icon>
      <v-icon
          style="position: absolute; top: 10%; left: 5%; transform: translate(-50%, -50%);"
          color="white"
          large
      >{{ 'mdi-crown' }}</v-icon>
    </template>

    <template #tab-description>
      <div class="row pa-3">
        <span class="ml-3 text-h6 col col-10">{{ quest.title }}</span>
        <div class="ml-4 pa-2 col-10 text-pre-line">
          {{ cDescription }}
        </div>
      </div>
    </template>

    <template #tab-map>
      <eins-map-detail-position
          #popup
          :position="cPosition"
          style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;"
      >
        <eins-map-address-popup v-bind="cAddress" />
      </eins-map-detail-position>
    </template>

    <template #tab-details>
      <div
          v-if="cClosedQuestion"
      >
        <v-list
            dense
            class="pt-0 pb-0 mt-n1 details-block"
        >
          <v-list-item>
            <v-list-item-icon>
              <eins-api-image
                  #default="{ src }"
                  class="ma-0"
                  :src="cAssigneePicture"
              >
                <v-img
                    :src="src"
                    width="2.2em"
                    @click="onClickAssigneeAvatar"
                >
                  <v-skeleton-loader
                      tile
                      type="image"
                      height="100%"
                  />
                </v-img>
              </eins-api-image>
            </v-list-item-icon>
            <template>
              <eins-icon-friendship
                  width="24"
                  height="24"
                  class="ml-n6 mt-1 mr-2"
                  :state="cParticipant.friendship_state"
              />
            </template>
            <v-list-item-content>
              <v-list-item-title>
                {{ cParticipant.name }} <i18n path="components.EinsQuestDetail.finished.quest" />
              </v-list-item-title>
              <v-list-item-subtitle>
                #{{ cParticipant.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <eins-layout-detail-list
          object-type="quest"
          :items="cDetailList"
          class="details-block"
      >
        <template #image="{ item }">
          <!-- eslint-disable-next-line-->
          <v-list-item-icon
              v-if="item.key === 'player' && item.avatar"
              class="ma-0"
              @click="onClickPlayerAvatar"
          >
            <eins-api-image
                #default="{ src }"
                :src="item.avatar"
            >
              <v-img
                  :src="src"
                  width="3em"
              >
                <v-skeleton-loader
                    tile
                    type="image"
                    height="100%"
                />
              </v-img>
            </eins-api-image>
          </v-list-item-icon>
          <v-list-item-icon
              v-if="item.key === 'group'"
              class="ma-0"
              @click="onClickGroupAvatar"
          >
            <!-- :src="cGroupPicture" -->
            <eins-api-image
                #default="{ src }"
                :src="item.avatar"
            >
              <v-img
                  :src="src"
                  width="3em"
              >
                <v-skeleton-loader
                    tile
                    type="image"
                    height="100%"
                />
              </v-img>
            </eins-api-image>
          </v-list-item-icon>
        </template>

        <template
            #icon="{ item }"
        >
          <eins-icon-friendship
              v-if="item.key === 'player'"
              class="ma-0 mt-1 mb-2 ml-2 mr-1"
              width="24px"
              heigh="24px"
              :state="cUserFriendshipState"
          />
          <eins-icon-medal
              v-else-if="item.key === 'medal'"
              :type="item.medal"
              width="36px"
          />
          <v-icon
              v-else
              width="24px"
              class="ml-2 center"
          >{{ item.icon }}</v-icon>
        </template>
        <template #subtitle="{ item }">
          <template v-if="item.key === 'rating'">
            <v-icon
                left
                small
            >mdi-heart</v-icon>{{ item.subtitle.up }}
            <v-icon
                left
                right
                small
            >mdi-thumb-down</v-icon>{{ item.subtitle.down }}
          </template>
        </template>
      </eins-layout-detail-list>
    </template>
  </eins-layout-item-detail-content>
</template>

<script>
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsIconMedal from '@/components/icons/EinsIconMedal'
import EinsIconQuestType from '@/components/icons/EinsIconQuestType'
import EinsLayoutDetailList from '@/components/layout/EinsLayoutDetailList'
import EinsLayoutItemDetailContent from '@/components/layout/EinsLayoutItemDetailContent'
import EinsMapAddressPopup from '@/components/map/EinsMapAddressPopup'
import EinsMapDetailPosition from '@/components/map/EinsMapDetailPosition'
import EinsTimeStars from '@/components/time/EinsTimeStars'
import { QuestState } from '@/constants'
import {
  MxTranslatable
} from '@/mixins'
import { googleMapsLinkUrl } from '@/utils/geo'
import EinsApiImage from '@/components/EinsApiImage'
import EinsQuestDetailStatesHeader from './EinsQuestDetailStatesHeader'/// <!-- cAlert is used for the EinsQuestDataStatesHeader.vue -->

const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-quest.gif`
const genericPicture2 = `${location.protocol}//${location.host}/img/placeholder/blind-group.gif`

export default {
  name: 'EinsQuestDetailContent',
  components: {
    EinsQuestDetailStatesHeader,
    EinsApiImage,
    EinsIconQuestType,
    EinsIconMedal,
    EinsLayoutDetailList,
    EinsTimeStars,
    EinsIconFriendship,
    EinsMapAddressPopup,
    EinsMapDetailPosition,
    EinsLayoutItemDetailContent
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    quest: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    like: true,
    isLoading: false,
    imageUrl: `https://picsum.photos/375/200`,
    tab: '',
    imageWidth: 100,
    detailDialog: false,
    $_mx_translatable: {
      paths: [
        'description'
      ]
    }
  }),
  computed: {
    cTabs () {
      const result = []

      result.push({
        value: 'description',
        icon: 'mdi-note-text'
      })

      if (this.cHasValidPosition) {
        result.push({
          value: 'map',
          icon: 'mdi-map'
        })
      }

      result.push({
        value: 'details',
        icon: 'mdi-map-marker'
      })

      return result
    },
    cHasValidPosition () {
      return this.cPosition.lat !== null &&
          !isNaN(this.cPosition.lat) &&
          this.cPosition.lon !== null &&
          !isNaN(this.cPosition.lon)
    },
    cAlert () {
      const state = this.quest?.state ?? QuestState.OPEN
      const applicants = this.quest?.applicants ?? []

      if (state === QuestState.OPEN) {
        if (this.cIsApplicant) {
          return 'applicant'
        }
        if (this.cIsOwner) {
          if (applicants.length > 0) {
            return 'allocate'
          } else {
            return 'wait'
          }
        } else {
          if (applicants.includes(this.$store.getters['players/current/id'])) {
            return null
          } else {
            return 'apply'
          }
        }
      } else if (state === QuestState.ASSIGNED) {
        if (this.cIsAssignee) {
          return 'start'
        }
        if (this.cIsOwner) {
          return 'start_owner'
        } else {
          return null
        }
      } else if (state === QuestState.STARTED) {
        if (this.cIsAssignee) {
          return 'running'
        }
        if (this.cIsOwner) {
          return 'running_owner'
        } else {
          return null
        }
      } else if (state === QuestState.FINISHED) {
        if (this.cIsOwner) {
          return 'completed'
        }
        if (this.cIsAssignee) {
          return 'completed_assignee'
        } else {
          return null
        }
      } else if (state === QuestState.CLOSED) {
        if (this.cIsOwner) {
          return 'closed'
        }
        if (this.cIsAssignee) {
          return 'closed'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    cGetPicture () {
      if (this.quest) {
        let questIsFinishedOrClosed = this.quest?.state !== QuestState.FINISHED || this.quest?.state !== QuestState.CLOSED

        if (
          !questIsFinishedOrClosed ||
            !this.quest?.processing ||
            !this.quest?.processing.title_picture_feed
        ) {
          return this.quest?.title_picture_feed ?? genericPicture1
        } else {
          return this.quest?.processing.title_picture_feed
        }
      } else {
        return null
      }
    },
    cPicture () { return this.cGetPicture ?? '' },
    cAssigneePicture () {
      return this.quest?.processing?.assignee?.title_picture_feed ?? ''
    },
    cParticipant () { return this.quest?.processing.assignee },
    cUserFriendshipState () {
      return this.quest?.player?.friendship_state ?? null
    },
    cGroup () {
      return this.quest?.group ?? null
    },
    cGroupPicture () {
      return this.quest?.group?.title_picture_feed ?? genericPicture2
    },
    cHasMedal () {
      return this.quest?.medals[0]?.type
    },
    cClosedQuestion () { return this.quest?.state === 'closed' ?? '' },
    cTitle () { return this.quest?.title ?? null },
    // cApplicantDetails () { return this.$store?.getters['players/2/quests'] },
    cDescription () { return this.quest?.description ?? null },
    cType () { return this.quest?.type },
    cIsAssignee () {
      return this.quest?.assignee === this.$store.getters['players/current/id']
    },
    cIsOwner () {
      return this.quest?.user_id === this.$store.getters['players/current/id']
    },
    cIsApplicant () {
      return (this.quest?.applicants ?? []).includes(this.$store.getters['players/current/id'])
    },
    cNumApplicants () {
      return (this.quest?.applicants ?? []).length
    },
    cStatus () {
      let result = {}

      switch (this.quest?.state ?? QuestState.OPEN) {
        case QuestState.OPEN:
          if (!this.cIsOwner) {
            if (this.cIsApplicant) {
              result.icon = 'mdi-account-clock'
              result.text = this.$t('quest.status.open-applied.applicant')
            } else {
              result.icon = 'mdi-human-greeting'
              result.text = this.$t('quest.status.open.foreigner')
            }
          } else {
            if (this.cNumApplicants > 0) {
              result.icon = 'mdi-human-greeting'
              result.text = this.$t('quest.status.open-applied.owner')
            } else {
              result.icon = 'mdi-map-marker-alert'
              result.text = this.$t('quest.status.open.owner')
            }
          }
          break
        case QuestState.ASSIGNED:
          result.icon = 'mdi-lock-open-variant'
          if (this.cIsAssignee) {
            result.text = this.$t('quest.status.assigned.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('quest.status.assigned.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('quest.status.assigned.foreigner')
          } else {
            result = null
          }
          break
        case QuestState.STARTED:
          result.icon = 'mdi-run'
          if (this.cIsAssignee) {
            result.text = this.$t('quest.status.processing.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('quest.status.processing.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('quest.status.processing.applicant')
          } else {
            result = null
          }
          break
        case QuestState.FINISHED:
          result.icon = 'mdi-camera'
          if (this.cIsAssignee) {
            result.text = this.$t('quest.status.done.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('quest.status.done.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('quest.status.done.applicant')
          } else {
            result = null
          }
          break
        case QuestState.CLOSED:
          result.icon = 'mdi-crown'
          if (this.cIsAssignee) {
            result.text = this.$t('quest.status.closed.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('quest.status.closed.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('quest.status.closed.applicant')
          } else {
            result = null
          }
          break
        case QuestState.CANCELED:
          result.icon = 'mdi-close-box-outline'
          if (this.cIsOwner) {
            result.text = this.$t('quest.status.canceled.owner')
          } else {
            result.text = this.$t('quest.status.canceled.foreigner')
          }
          break
      }

      return result
    },
    cPosition () {
      return {
        lat: this.quest?.latitude ?? this.$store.getters['players/current/lat'] ?? null,
        lon: this.quest?.longitude ?? this.$store.getters['players/current/lon'] ?? null
      }
    },
    cAddress () {
      const result = {
        street: (this.quest?.street || '').trim(),
        number: (this.quest?.house_number || '').trim(),
        zip: (this.quest?.postal_code || '').trim(),
        city: (this.quest?.city || '').trim(),
        country: (this.quest?.country || '').trim()
      }

      result.navLink = googleMapsLinkUrl(this.cPosition.lat, this.cPosition.lon)

      return result
    },
    cLikeIcon () {
      return this.like ? 'mdi-heart-outline' : 'mdi-heart'
    },
    cCurrentPlayer () { return this.$store.getters['players/current/id'] },
    cReaction () { return this.quest?.player_reaction === null },
    cQuestReactionClick () { return this.quest?.processing?.user_id !== this.cCurrentPlayer && this.quest?.player?.id !== this.cCurrentPlayer },
    cLikeCount () { return this.getLike(this.quest?.positive_reactions_count) ? this.getLike(this.quest?.positive_reactions_count) : false },
    cDislikeCount () { return this.getDislike(this.quest?.negative_reactions_count) ? this.getDislike(this.quest?.negative_reactions_count) : false },
    cTimeBonus () {
      return this.quest?.value ?? 0
    },
    cMedal () {
      return this.cHasMedal ? this.cHasMedal : 'common'
    },
    cGetDate () {
      let value = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let month = new Date(this.quest?.processing.approved_at).getMonth()
      let year = new Date(this.quest?.processing.approved_at).getFullYear()
      return value[month] + ' ' + year
    },
    cPlayerName () { return this.quest?.player?.name },
    cPlayerId () { return this.quest?.id },
    cPlayer () { return this.quest?.player ?? null },
    cDetailList () {
      const t = (path, params = []) => this.$t(`components.EinsQuestDetail.${path}`, params)
      const result = []

      if (this.cStatus !== null) {
        result.push({
          key: 'status',
          icon: this.cStatus.icon,
          title: t('details.status.title'),
          subtitle: this.cStatus.text
        })
      }

      const questType = this.quest?.type
      result.push({
        key: 'quest-type',
        icon: questType === 'private' ? 'mdi-map-marker-left' : questType === 'nature' ? 'mdi-map-marker-up' : 'mdi-map-marker-star',
        title: this.$t(`quest.type.${questType}.title`),
        subtitle: this.$t(`quest.type.${questType}.text`)
      })

      result.push({
        key: 'quest-id',
        icon: 'mdi-map-marker',
        title: t('details.quest-id.title', [ this.quest?.id ?? 0 ]),
        subtitle: t('details.quest-id.sub-title')
      })

      if (QuestState.CLOSED === this.quest?.state ?? QuestState.OPEN) {
        result.push({
          key: 'rating',
          icon: 'mdi-heart',
          title: t('details.rating.title'),
          subtitle: {
            up: t('details.rating.up-votes', [this.quest?.positive_reactions_count ?? 0]),
            down: t('details.rating.down-votes', [this.quest?.negative_reactions_count ?? 0])
          }
        })
      }

      if (this.cTimeBonus > 30) {
        const stars = Math.round(2 * (this.cTimeBonus / 60)) / 2
        result.push({
          key: 'time',
          icon: 'mdi-star',
          title: t('details.stars.title', [ stars ]),
          subtitle: t('details.stars.sub-title', [ stars ])
        })
      } else {
        result.push({
          key: 'time',
          icon: 'mdi-star',
          title: t('details.points.title', [ this.cTimeBonus ]),
          subtitle: t('details.points.sub-title', [ this.cTimeBonus ])
        })
      }

      if (this.cHasMedal) {
        result.push({
          key: 'medal',
          icon: 'mdi-star-circle',
          medal: this.quest?.medals[0]?.type,
          title: t(`details.medal.title`, [ this.$t(`medals.${this.quest?.medals[0]?.type}.name`) ]),
          subtitle: this.$t(`medals.${this.quest?.medals[0]?.type}.description`)
        })
      }

      let subtitle = t('details.player.sub-title')
      let affectedByBlockage = false
      if (this.cPlayer.has_blocked_authenticated_user) {
        subtitle = this.$t('player.block.attribution.hasBlocked')
        affectedByBlockage = true
      } else if (this.cPlayer.is_blocked_by_authenticated_user) {
        subtitle = this.$t('player.block.attribution.isBlocked')
        affectedByBlockage = true
      }
      result.push({
        key: 'player',
        icon: null,
        avatar: !affectedByBlockage ? this.cPlayer?.title_picture_feed : null,
        title: this.quest?.player.name ?? '',
        subtitle
      })

      if (this.cGroup !== null) {
        result.push({
          key: 'group',
          icon: this.cGroup?.group_type.icon,
          avatar: this.cGroupPicture,
          title: this.cGroup?.title,
          subtitle: t('details.group.sub-title')
        })
      }

      return result
    },
    cShowImageOverlay () {
      return this.imageWidth > 30
    },
    cImageStyle () {
      return 'max-width:' + this.imageWidth + '%'
    }
  },

  methods: {
    onClickImageOverlay () {
      this.goToDetailsTab()
    },
    goToDetailsTab () {
      this.tab = 'details'
    },
    toggleLike () {
      this.like = !this.like
      this.onClickLike()
    },
    getLike (like) {
      if (like > 0) {
        return like
      }
    },
    getDislike (dislike) {
      if (dislike > 0) {
        return dislike
      }
    },
    onListScroll (arg) {
      if (arg < this.imageWidth) {
        this.imageWidth = 20
      }
    },
    onClickPlayerAvatar () {
      if (
        !this.cPlayer.has_blocked_authenticated_user &&
        !this.cPlayer.is_blocked_by_authenticated_user
      ) {
        this.$router.push({
          name: 'player-detail',
          params: {
            id: this.cPlayer.id
          }
        })
      }
    },
    onClickGroupAvatar () {
      this.$router.push({
        name: 'group-detail',
        params: {
          id: this.cGroup.id
        }
      })
    },
    onClickAssigneeAvatar () {
      this.$router.push({
        name: 'player-detail',
        params: {
          id: this.cParticipant.id
        }
      })
    },
    onClickLike () {
      if (this.quest !== null) {
        this.isLoading = true
        this.$http.$api.post(`/quests/${this.quest?.id}/reactions`, {
          value: true
        })
          .then(() => {
            return Promise.all([
              this.$store.dispatch('quests/selected/update'),
              this.cLikeCount,
              this.cDislikeCount,
              this.cReaction
            ])
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    } // this.$store.dispatch('players/selected/questsFinished/loadAll'),
  }
}
</script>

<style scoped lang="scss">
.image-overlay {
  border:3px solid var(--v-quest-base);
}
.details-block {
  border:2px solid var(--v-quest-base) !important;
}
</style>
