import { render, staticRenderFns } from "./EinsPlayerDataTalentsBlock.vue?vue&type=template&id=13437130&scoped=true&"
import script from "./EinsPlayerDataTalentsBlock.vue?vue&type=script&lang=js&"
export * from "./EinsPlayerDataTalentsBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13437130",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VBtn,VChip,VChipGroup,VExpandTransition,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VProgressLinear})
