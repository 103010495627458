<template>
  <eins-layout-list-item
      :item-type="'quest'"
      :to="`/quests/${quest.id}`"
  >
    <template #image>
      <eins-api-image
          #default="{ src }"
          :src="cPicture"
      >
        <v-img
            :src="src"
            aspect-ratio="1"
            height="100%"
        >
          <template #placeholder>
            <v-skeleton-loader
                type="image"
            />
          </template>
        </v-img>
      </eins-api-image>
    </template>

    <template #icons>
      <v-spacer />
      <v-icon
          v-if="quest.type === 'common'"
          color="white"
      >mdi-map-marker-star</v-icon>
      <v-icon
          v-else-if="quest.type === 'private'"
          color="white"
      >mdi-map-marker-left</v-icon>
      <v-icon
          v-else-if="quest.type === 'green'"
          color="white"
      >mdi-map-marker-up</v-icon>
      <v-spacer />

      <v-col>
        <v-icon
            v-if="cOwnQuest"
            color="white"
        >
          mdi-account-box
        </v-icon>
        <v-icon
            v-else-if="cGroup !== null"
            color="white"
        >{{ cGroup.group_type.icon }}</v-icon>
        <eins-icon-friendship
            v-else
            :state="cUserFriendshipState"
            width="24"
            class="d-inline-block"
        />
      </v-col>

      <v-spacer />
      <!-- replace this with the icon from the eins-player-list-item-quest -->
      <slot name="indicator3">
        <v-col>
          <div
              class="caption mb-n1 pt-0 pb-0"
              style="line-height: 1.1;"
          >
            {{ cDistance.value }}<br />
            {{ cDistance.unit }}
          </div>
        </v-col>
      </slot>
      <!-- replace this with the icon from the eins-player-list-item-quest -->
      <v-spacer />
    </template>

    <template #title>
      {{ quest.title }}
    </template>
    <slot
        name="content"
        :item="quest"
    >
      {{ quest.description }}
    </slot>
    <template #bonus>
      <eins-list-item-time-stars
          :value="quest.value"
          object-type="quest"
          stars="24"
          star-color="var(--v-questoff-base)"
          icon-color="var(--v-quest-base)"
      />
    </template>
  </eins-layout-list-item>
</template>

<script>
import {
  RegHtml
} from '@/utils/regex'

import EinsLayoutListItem from '@/components/layout/EinsLayoutListItem'
import EinsApiImage from '@/components/EinsApiImage'
import EinsListItemTimeStars from '../time/EinsListItemTimeStars'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'

const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-quest.gif`

const regHTML = RegHtml

const descriptionMaxLength = 70

export default {
  name: 'EinsQuestListItem',
  components: {
    EinsListItemTimeStars,
    EinsApiImage,
    EinsLayoutListItem,
    EinsIconFriendship
  },
  props: {
    quest: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    imageUrl: `https://picsum.photos/200/200?rnd=${Math.random() * 1000000}`
  }),
  computed: {
    cPicture () {
      return this.quest.title_picture_feed ?? genericPicture1
    },
    cOwnQuest () {
      return this.quest.user_id === this.cUserId
    },
    cGroup () {
      return this.quest?.group ?? null
    },
    cUserFriendshipState () {
      return this.quest?.player?.friendship_state ?? null
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cTitle () {
      let titleData = this.quest.title || '{}'
      try {
        titleData = JSON.parse(titleData)
      } catch (e) {
        titleData = {}
      }

      const titleLocales = Object.keys(titleData)
      if (titleData.hasOwnProperty(this.$i18n.locale)) {
        // Try current locale
        return titleData[this.$i18n.locale]
      } else if (titleData.hasOwnProperty(this.$i18n.fallbackLocale)) {
        // If not present use fallback
        return titleData[this.$i18n.fallbackLocale]
      } else if (titleLocales.length > 0) {
        // No clue what could possibly be correct, just take the first translation available
        return titleData[titleLocales.pop()]
      } else {
        // Give up -> no valid title found
        return ''
      }
    },
    cDistance () {
      if (!this.quest || isNaN(this.quest.distance)) {
        return ''
      } else {
        let distance = this.quest.distance
        let format = 'distance-meter'

        if (distance > 1000) {
          // decimal places: 1 => / Round(d/100)/10
          distance = Math.round(distance / 100) / 10
          format = 'distance-kilometer'
        }

        return {
          value: this.$n(distance, 'decimal'),
          unit: format === 'distance-kilometer' ? 'km' : 'm'
        }
      }
    },
    cDescription () {
      let descData = this.quest.description || '{}'
      try {
        descData = JSON.parse(descData)
      } catch (e) {
        descData = {}
      }

      // Fallback if no valid translation found
      let description = ''
      const descLocales = Object.keys(descData)
      if (descData.hasOwnProperty(this.$i18n.locale)) {
        // Try current locale
        description = descData[this.$i18n.locale]
      } else if (descData.hasOwnProperty(this.$i18n.fallbackLocale)) {
        // If not present use fallback
        description = descData[this.$i18n.fallbackLocale]
      } else if (descLocales.length > 0) {
        // No clue what could possibly be correct, just take the first translation available
        description = descData[descLocales.pop()]
      }

      description = description.replace(regHTML, '')
      if (description.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }

      return description
    },
    cHighlight () {
      return 'var(--v-quest-base)'
    }
  },
  created () {
  }
}
</script>
