// @vue/component
/**
 * Provides basic model functionality for the component.
 *  Events and properties use the default names.
 * @mixin
 */
export const MxHasModel = {
  props: {
    /**
     * Value property for the `v-model`
     */
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null
    }
  },
  data () {
    return {
      internalValue: null
    }
  },
  watch: {
    value: 'setValue'
  },
  crated () {
    this.setValue(this.value)
  },
  methods: {
    onInput (value) {
      this.internalValue = value
      this.emit()
    },
    setValue (newValue, oldValue) {
      this.$_mx_has_model_set_value(newValue, oldValue)
    },
    $_mx_has_model_set_value (newValue, oldValue) {
      this.$set(this, 'internalValue', newValue)
    },
    emit () {
      this.$_mx_has_model_emit()
    },
    $_mx_has_model_emit () {
      this.emitInput()
    },
    emitInput () {
      this.$_mx_has_model_emit_input()
    },
    $_mx_has_model_emit_input () {
      /**
       * Input event for the `v-model`
       * @property {*} internalValue The current internal state of the component
       */
      this.$emit('input', this.internalValue)
    }
  }
}
