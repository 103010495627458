<template>
  <eins-starmenu-dpb
      v-show="cShowBtn"
      :to="cTarget"
      :icon="cIcon"
      :text="cText"
      exact
  />
</template>

<script>
import EinsStarmenuDpb from './EinsStarmenuDpb'
import {
  MxStarmenuBtn
} from '@/mixins'
import {
  Entity, Icon
} from '@/constants'

export default {
  name: 'EinsStarmenuDpbDetails',
  components: {
    EinsStarmenuDpb
  },
  mixins: [
    MxStarmenuBtn
  ],
  data: () => ({
  }),
  computed: {
    cText () {
      return this.$t(`components.${this.$options.name}.text`, [
        this.$t(`terms.${this.cArea}`)
      ])
    },
    cArea () {
      return this.$route.name === 'my-data' ? Entity.PLAYER : this.mx_starmenu_btn_c_area
    },
    cIcon () {
      switch (this.cArea) {
        case Entity.QUEST:
          return Icon.QUEST
        case Entity.DEAL:
          return Icon.DEAL
        case Entity.PLAYER:
          return Icon.PLAYER
        case Entity.GROUP:
          return Icon.GROUP
        default:
          return ''
      }
    },
    cShowBtn () {
      return this.cBelongsToRoute && !this.cIsObjectDetailsPage
    },
    cIsObjectDetailsPage () {
      return this.cIsGroupDetailsPage || this.cIsPlayerDetailsPage
    },
    cIsGroupDetailsPage () {
      return this.cArea === Entity.GROUP && this.$route.hash === '#members'
    },
    cIsPlayerDetailsPage () {
      return this.cArea === Entity.PLAYER && this.$route.hash === '#group'
    },
    cTarget () {
      if (this.$route.name === 'my-data') {
        return '/my-profile'
      }

      return '/' + this.$route.path
        .split('/')
        .filter(el => el.length > 0)
        .slice(0, 2) // remove all but 1st two elements
        .join('/')
        .concat(
          '?',
          Object.entries(this.$route.query) // preserve query
            .map(el => `${encodeURIComponent(el[0])}=${encodeURIComponent(el[1].toString())}`)
            .join('&')
        )
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
