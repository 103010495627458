<template>
  <div> this is an empty view</div>
</template>

<script>
export default {
  name: 'EinsEmptyView'
}
</script>

<style scoped>

</style>
