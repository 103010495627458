<template>
  <div
      ref="content"
      class="start-page"
      style="background-color:transparent !important;height:100%"
  >
  </div>
</template>

<script>
export default {
  name: 'ViewStart'
}
</script>

<style scoped>

</style>
