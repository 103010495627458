<template>
  <v-app-bar
      bottom
      app
      color="secondary"
      tag="footer"
      :class="cClass"
  >
    <v-btn
        v-ripple="false"
        to="/groups"
        icon
        large
    >
      <eins-icon-groups
          :active="cActive.groups"
          max-width="35"
      />
    </v-btn>
    <v-spacer />
    <v-btn
        v-ripple="false"
        to="/players"
        icon
        large
    >
      <eins-icon-players
          :active="cActive.players"
          max-width="35"
      />
    </v-btn>
    <v-spacer />

    <!-- EINS BUTTON WITH SPEED DIAL -->
    <v-btn
        v-ripple="false"
        icon
        large
        style="height: 100%; margin-top: -30px; position: relative;"
        @touchstart.prevent="onMouseDownStarmenuStar"
        @mousedown="onMouseDownStarmenuStar"
        @touchend="onMouseUpStarmenuStar"
        @mouseup="onMouseUpStarmenuStar"
        @contextmenu.prevent="() => false"
    >
      <eins-icon-star
          :active="cStarmenuIsOpen"
          style="position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)"
          max-width="50"
      />
    </v-btn>

    <v-spacer />
    <v-btn
        v-ripple="false"
        to="/quests"
        icon
        large
    >
      <eins-icon-quests
          :active="cActive.quests"
          max-width="35"
      />
    </v-btn>
    <v-spacer />
    <v-btn
        v-ripple="false"
        to="/deals"
        icon
        large
    >
      <eins-icon-deals
          :active="cActive.deals"
          max-width="35"
      />
    </v-btn>
  </v-app-bar>
</template>

<script>
import EinsIconDeals from '@/components/icons/EinsIconDeals'
import EinsIconGroups from '@/components/icons/EinsIconGroups'
import EinsIconPlayers from '@/components/icons/EinsIconPlayers'
import EinsIconQuests from '@/components/icons/EinsIconQuests'
import EinsIconStar from '@/components/icons/EinsIconStar'

import {
  Starmenu
} from '@/constants'

export default {
  name: 'TheAppBarBottom',
  components: {
    EinsIconDeals,
    EinsIconGroups,
    EinsIconPlayers,
    EinsIconQuests,
    EinsIconStar
  },
  data: () => ({
    starmenu: {
      clickTimeout: null,
      triggeredReload: false
    },
    speedDial: {
      active: false
    }
  }),
  computed: {
    cStarmenuIsOpen () {
      return this.$store.getters.starmenuIsOpen
    },
    cEinsActions () {
      return this.$route.meta.speedActions || []
    },
    cClass () {
      if (this.cActive.groups) {
        return 'shadow-top-group'
      } else if (this.cActive.players) {
        return 'shadow-top-player'
      } else if (this.cActive.quests) {
        return 'shadow-top-quest'
      } else if (this.cActive.deals) {
        return 'shadow-top-deal'
      } else {
        return 'four-color-gradient-shadow-top'
      }
    },
    cActive () {
      return {
        groups: this.$route.name === 'group-list' || this.$route.name === 'group-detail',
        players: this.$route.path !== this.cPlayerProfile && (this.$route.name === 'player-list' || this.$route.name === 'player-detail'),
        quests: this.$route.name === 'quest-list' || this.$route.name === 'quest-detail',
        deals: this.$route.name === 'deal-list' || this.$route.name === 'deal-detail'
      }
    },
    cPlayerProfile () {
      return `/players/${this.$store.getters['currentUser/id']}`
    }
  },
  methods: {
    onMouseDownStarmenuStar () {
      if (this.starmenu.clickTimeout !== null) {
        clearTimeout(this.starmenu.clickTimeout)
      }
      this.starmenu.triggeredReload = false
      this.starmenu.clickTimeout = setTimeout(() => {
        clearTimeout(this.starmenu.clickTimeout)
        this.starmenu.clickTimeout = null
        this.starmenu.triggeredReload = true

        this.showRefreshingSpinner()
        if (typeof navigator.vibrate === 'function') {
          navigator.vibrate(1)
        }

        this.requestViewUpdate()
        this.refreshData()
          .then(() => this.hideRefreshingSpinner())
      }, Starmenu.REFRESH_TIMEOUT)
    },
    onMouseUpStarmenuStar () {
      if (this.starmenu.clickTimeout !== null) {
        clearTimeout(this.starmenu.clickTimeout)
        this.starmenu.clickTimeout = null
      }

      if (!this.starmenu.triggeredReload) {
        this.toggleStarmenu()
      }
      this.starmenu.triggeredReload = false
    },
    onClickStarmenuStar () {
      this.toggleStarmenu()
    },
    requestViewUpdate () {
      this.$root.$emit('request-update')
    },
    toggleStarmenu () {
      this.$store.commit('toggleStarmenu')
    },
    showRefreshingSpinner () {
      this.$root.refreshing = true
    },
    hideRefreshingSpinner () {
      this.$root.refreshing = false
    },
    refreshData () {
      return Promise.all([
        this.$store.dispatch('players/update'),
        this.$store.dispatch('quests/update'),
        this.$store.dispatch('deals/update'),
        this.$store.dispatch('groups/update'),
        this.$store.dispatch('conversations/update'),
        this.$store.dispatch('players/current/notifications/loadLatest'),
        this.$store.dispatch('questsTimeline/update')
      ])
    }
  }
}
</script>

<style scoped lang="scss">
  .v-toolbar {
    > ::v-deep .v-toolbar__content {
      $color: var(--v-primary-base);

      // prevent the overflow setting from cutting off shadow
      position: relative;
      z-index: 2;
     // box-shadow: 0px -3px 10px 0px $color;
    }
  }
  .v-btn::before {
    background:transparent !important;
  }
</style>
