<template>
  <div
      class="notification-list detail-content"
      style="height:100%;width:100%;"
  >
    <h3 class="text-center">
      <v-icon
          size="30"
          color="primary"
          class="mt-n1"
      >mdi-bell</v-icon>
      {{ $t(`notifications.caption`) }}
    </h3>
    <eins-layout-list
        #default="{ item }"
        :items="cNotifications"
        :item-height="itemHeight"
        class="pa-0 ma-0 overflow-x-auto"
        style="position:absolute;left:0px;right:0px;top:40px;bottom:0px;"
    >
      <slot
          name="item"
          :item="item"
      >
        <eins-notification-list-item :notification="item" />
      </slot>
    </eins-layout-list>
  </div>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsNotificationListItem from '@/components/notification/EinsNotificationListItem'

export default {
  name: 'EinsNotificationList',
  components: {
    EinsLayoutList,
    EinsNotificationListItem
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    itemHeight: 10
  }),
  computed: {
    cNotifications () {
      let result = [].concat(this.notifications)
      result.sort(function (a, b) {
        if (a.read_at === null && b.read_at === null) {
          return b.created_at - a.created_at
        } else if (a.read_at === null && b.read_at !== null) {
          return -1
        } else if (a.read_at !== null && b.read_at === null) {
          return 1
        } else if (a.read_at !== null && b.read_at !== null) {
          return b.updated_at - a.updated_at
        }
      })
      return result
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      setTimeout(() => this.getItemHeight())
    })
  },
  methods: {
    onScroll (evt) {
    },
    getItemHeight () {
      const firstItem = this.$refs.item_0 ? this.$refs.item_0 : null
      if (firstItem) {
        this.itemHeight = firstItem instanceof HTMLElement
          ? firstItem.clientHeight : firstItem.$el.clientHeight
      } else {
        this.itemHeight = 13
      }
    }
  }
}
</script>

<style scoped>
</style>
