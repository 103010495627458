<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cTitle }}</v-card-title>
      <v-card-text>
        <v-chip-group
            v-model="type"
            active-class="primary--text"
            column
        >
          <v-chip
              v-for="(type, key) of cDenunciationTypes"
              :key="key"
              small
              :value="key"
          >
            {{ type.title }}
          </v-chip>
        </v-chip-group>
        <v-expand-transition>
          <div v-if="cSelectedDenunciationType !== null">
            {{ cSelectedDenunciationType.description }}
          </div>
        </v-expand-transition>
        <v-textarea
            v-model.trim="reason"
            autofocus
            label="Reason"
            hint="Optional"
            persistent-hint
            counter="90"
            rows="3"
        />
      </v-card-text>
      <v-expand-transition>
        <v-alert
            :value="cShowError"
            dismissible
            tile
            dense
            type="error"
            @input="onClickHideErrorMessage"
        >
          <strong>Error!</strong><br />
          {{ cErrorText }}
        </v-alert>
      </v-expand-transition>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            color="primary"
            outlined
            block
            @click="close"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <v-expand-transition>
          <v-layout
              v-if="!success"
              column
          >
            <v-flex>
              <v-btn
                  block
                  color="primary"
                  :loading="loading"
                  :disabled="type === null"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-flex>
            <v-flex class="mt-2">
              <v-btn
                  block
                  color="grey"
                  outlined
                  :disabled="loading"
                  @click="onClickCancel"
              >{{ cText['btn.cancel'] }}</v-btn>
            </v-flex>
          </v-layout>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import {
  Entity,
  DenunciationType
} from '@/constants'
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsStarmenuDlgDenunciation',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    error: null,
    success: false,
    reason: '',
    type: null,
    $_mx_translatable: {
      paths: [
        'title',
        'error.title',
        'btn.close',
        'btn.submit',
        'btn.cancel'
      ]
    }
  }),
  computed: {
    cTitle () {
      return this.$t(`components.${this.$options.name}.title`, [ this.cName ])
    },
    open: {
      get () {
        return this.$store.getters['dialogDenunciation']
      },
      set (value) {
        this.$store.commit('dialogDenunciation', value)
      }
    },
    cShowError () {
      return !!this.error
    },
    cRouteId () {
      return this.$route.params.id
    },
    cDenunciationTypes () {
      const result = {}

      const types = Object.values(DenunciationType)
      for (const type of types) {
        result[type] = {
          title: this.$t(`denunciationTypes.${type}.title`),
          description: this.$t(`denunciationTypes.${type}.description`)
        }
      }

      return result
    },
    cSelectedDenunciationType () {
      return this.cDenunciationTypes[this.type] ?? null
    },
    cType () {
      switch (this.$route.name) {
        case 'player-detail':
          return Entity.PLAYER
        case 'deal-detail':
          return Entity.DEAL
        case 'quest-detail':
          return Entity.QUEST
        case 'group-detail':
          return Entity.GROUP
        default:
          return null
      }
    },
    cEntity () {
      let result
      switch (this.cType) {
        case Entity.PLAYER:
          result = this.$store.getters['players/selected/player']
          break
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest']
          break
        case Entity.GROUP:
          result = this.$store.getters['groups/selected/group']
          break
        default:
          return null
      }

      return result
    },
    cName () {
      if (this.cType === Entity.PLAYER) {
        return this.cEntity?.name ?? ''
      } else {
        return this.cEntity?.title ?? ''
      }
    },
    cApiUrl () {
      let root
      switch (this.cType) {
        case Entity.PLAYER:
          root = `players`
          break
        case Entity.DEAL:
          root = `deals`
          break
        case Entity.QUEST:
          root = `quests`
          break
        case Entity.GROUP:
          root = `groups`
          break
        default:
          return null
      }

      if (root) {
        return `/${root}/${this.cRouteId}/denunciations`
      } else {
        return null
      }
    },
    cErrorText () {
      let result = ''

      if (this.error) {
        switch (this.error) {
          case 409:
            result = this.$t(`components.${this.$options.name}.error.409`, [ this.cEntity?.name ?? '' ])
            break
          default:
            result = this.$t(`components.${this.$options.name}.error.unknown`)
            break
        }
      }

      return result
    }
  },
  watch: {
    '$route.params.id' (value, oldValue) {
      if (value !== oldValue) {
        this.reason = ''
      }
    },
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          this.success = false
          this.error = false
        }
      }
    },
    onClickClose () {
      this.close()
    },
    onClickHideErrorMessage () {
      this.error = null
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    close () {
      this.open = false
    },
    submit () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.error = false

        const reason = this.reason
        const type = this.type

        if (this.cType) {
          this.$http.$api.post(this.cApiUrl, {
            type,
            reason
          })
            .then(() => {
              this.success = true
              this.reason = ''
              this.type = ''
            })
            .catch((e) => {
              this.error = e.response?.status ?? true
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          reject(new Error('No valid route'))
          this.loading = false
          this.error = true
        }
      })
    },
    cancel () {
      this.close()
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
