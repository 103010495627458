<template>
  <v-row>
    <v-col
        :style="cStyle.container"
        style="max-height: 100%; overflow: hidden; padding:0px;"
    >
      <v-row
          class="flex-column"
          no-gutters
      >
        <v-col :order="!top ? 2 : 1">
          <v-row
              v-if="cHasMeta"
              no-gutters
              flex-nowrap
          >
            <v-col
                :order="!left ? 2 : 1"
                class="flex-grow-1"
            />
            <v-col
                :order="left ? 2 : 1"
                class="flex-shrink-1 flex-grow-0"
            >
              <v-sheet
                  :class="cClass"
                  class="v-sheet--img-overlay subtitle-2"
                  @click="onClick"
              >
                <v-container
                    fluid
                    style="white-space: nowrap; padding:0px; margin:0px"
                >
                  <!-- eslint-disable-next-line vue/html-self-closing -->
                  <slot name="medal" />
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        :style="cStyle.container"
        style="max-width: 65%; max-height: 100%; overflow: hidden;"
    >
      <v-row
          class="flex-column"
          no-gutters
      >
        <v-col :order="top ? 2 : 1">
          <v-row
              v-if="cHasMeta"
              no-gutters
              flex-nowrap
          >
            <v-col
                :order="left ? 2 : 1"
                class="flex-grow-1"
            />
            <v-col
                :order="!left ? 2 : 1"
                class="flex-shrink-1 flex-grow-0"
            >
              <v-sheet
                  :class="cClass"
                  class="v-sheet--img-overlay subtitle-2"
                  @click="onClick"
              >
                <v-container
                    fluid
                    style="white-space: nowrap; padding:5px"
                >
                  <!-- eslint-disable-next-line vue/html-self-closing -->
                  <slot name="meta" />
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
        <v-col :order="!top ? 2 : 1">
          <v-row
              v-if="cHasTitle"
              no-gutters
              class="flex-nowrap"
          >
            <v-col
                :order="left ? 2 : 1"
                class="flex-grow-1"
            />
            <v-col
                :order="!left ? 2 : 1"
                class="flex-shrink-1 flex-grow-0"
            >
              <v-sheet
                  class="v-sheet--img-overlay title ml-n1"
                  :class="cClass"
                  @click="onClick"
              >
                <v-container
                    fluid
                    pt-1
                    pb-1
                    class="text-no-wrap"
                >
                  <!-- eslint-disable-next-line vue/html-self-closing -->
                  <slot name="title" />
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EinsLayoutImageOverlay',
  props: {
    top: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cHasTitle () {
      return !!this.$scopedSlots.title
    },
    cHasMeta () {
      return !!this.$scopedSlots.meta
    },
    cClass () {
      return {
        'no-border-radius-top-left': this.top || this.left,
        'no-border-radius-top-right': this.top || !this.left,
        'no-border-radius-bottom-left': !this.top || this.left,
        'no-border-radius-bottom-right': !this.top || !this.left
      }
    },
    cTextAlign () {
      return this.left ? 'left' : 'right'
    },
    cStyle () {
      const result = {
        container: { position: 'absolute' },
        meta: {}
      }

      if (this.top === true) {
        result.container.top = 0
      } else {
        result.container.bottom = 0
      }
      if (this.left) {
        result.container.left = 0
        result.container.textAlign = 'left'
      } else {
        result.container.right = 0
        result.container.textAlign = 'right'
      }

      return result
    }
  },
  methods: {
    onClick (e) {
      this.emitClick(e)
    },
    emitClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
  .no-border-radius {
    &-top {
      &-left {
        border-top-left-radius: 0 !important;
      }
      &-right {
        border-top-right-radius: 0 !important;
      }
    }
    &-bottom {
      &-left {
        border-bottom-left-radius: 0 !important;
      }
      &-right {
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .v-sheet--img-overlay {
    background-color: rgba(#424242, .35);

    ::v-deep .v-divider {
      border-color: rgba(#424242, .75);
    }
  }
</style>
