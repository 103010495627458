<template>
  <v-row
      class="flex-column flex-nowrap fill-height"
      style="overflow: hidden;"
      no-gutters
  >
    <v-col
        cols="12"
        class="headline ma-1 pl-3"
    >
      <v-icon>mdi-google-circles-group</v-icon>
      <span class="ml-2 block">{{ group.title }}</span>
    </v-col>
    <v-col
        cols="12"
        class="text-center headline pa-0 ma-1"
    >
      {{ cText['captions.page3'] }}
    </v-col>
    <v-col
        cols="12"
        class="gray subtitle-1 pa-2 ma-1"
    >
      <v-icon size="30">{{ group.license.icon }}</v-icon>
      <span class="ml-3">{{ cText['labels.licenceName.label'] }}: </span>
      <span class="ml-2">
        {{ group.license.title }}
      </span>
      <br>
      <div class="ml-11">
        {{ group.license.ServicePackage }}
      </div>
    </v-col>
    <v-col
        cols="12"
        class="text-center pa-2 pt-0"
    >
      <v-row
          no-gutters
          class="blue-grey pt-2"
      >
        <v-col
            cols="2"
        >
        </v-col>
        <v-col
            cols="8"
            class="text-left"
        >
          <div>
            <span v-if="group.license.can_buy_deals"><v-icon>mdi-cart</v-icon> {{ cText['labels.BuyDeals.label'] }}</span>
            <span v-else> <v-icon>mdi-close-circle-outline</v-icon> {{ cText['labels.NoDeals.label'] }}</span>
          </div>
          <div>
            <v-icon>mdi-account-multiple</v-icon>
            <span> {{ group.license.limit_members }} <span> {{ cText['labels.GroupMembers.label'] }}</span></span>
          </div>
          <div>
            <span v-if="group.license.require_application"> <v-icon>mdi-human-greeting</v-icon> {{ cText['labels.Closed.label'] }}</span>
            <span v-else> <v-icon>mdi-walk</v-icon> {{ cText['labels.Open.label'] }}</span>
          </div>
          <div>
            <v-icon>mdi-account-supervisor</v-icon>
            <span> {{ group.license.limit_admins }} {{ cText['labels.Leaders.label'] }}</span>
          </div>
          <div>
            <v-icon>mdi-ticket</v-icon>
            <span> {{ group.license.support_mails_per_month }} <span> {{ cText['labels.Support.label'] }}</span></span>
          </div>
          <div>
            <v-icon>mdi-lifebuoy</v-icon>
            <span> {{ group.license.support_calls_per_month }} <span> {{ cText['labels.Calls.label'] }}</span></span>
          </div>
          <div>
            <v-icon>mdi-content-duplicate</v-icon>
            <span v-if="group.license.includes_content_management"> {{ cText['labels.ActiveContent.label'] }}</span>
            <span v-else> {{ cText['labels.SelfContent.label'] }}</span>
          </div>
        </v-col>
        <v-col
            cols="2"
            class="ml-1"
        >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { MxTranslatable } from '@/mixins'

const getEmptyItem = () => ({
  title: '',
  business_email: '',
  type: null,
  license: null
})

export default {
  name: 'EinsGroupCreateFormStep1',
  mixins: [
    MxTranslatable
  ],
  props: {
    value: {
      type: Object,
      default: getEmptyItem
    },
    licenseTypes: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    group: getEmptyItem(),
    validation: {
      licenseConfirm: {
        agreement: false,
        conditions: false,
        dataProcessingRegulations: false
      }
    },
    $_mx_translatable: {
      paths: [
        'captions.page3',
        'labels.licenceName.label',
        'labels.payment.netPerYear',
        'labels.BuyDeals.label',
        'labels.GroupMembers.label',
        'labels.Leaders.label',
        'labels.Support.label',
        'labels.Closed.label',
        'labels.Open.label',
        'labels.ActiveContent.label',
        'labels.SelfContent.label',
        'labels.Calls.label',
        'labels.NoDeals.label'
      ]
    }
  }),
  watch: {
    value: 'setValue'
  },
  created () {
    this.setValue()
  },
  methods: {
    setValue () {
      const emptyItem = getEmptyItem()
      this.$set(this.$data, 'group', {
        ...emptyItem,
        ...this.value
      })
    },
    emitInput () {
      this.$emit('input', {
        ...this.group
      })
    }
  }
}
</script>

<style scoped>

</style>
