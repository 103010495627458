<template>
  <v-stepper
      v-model="internalValue.notification_weight"
      vertical
      class="elevation-0"
      style="background-color: transparent;"
  >
    <v-stepper-step
        step="1"
        style="cursor: pointer;"
        @click="onClickStep(1)"
    >
      {{ $t(`notifications.weight.1.title`) }}
    </v-stepper-step>

    <v-stepper-content step="1">
      {{ $t(`notifications.weight.1.description`) }}
    </v-stepper-content>

    <v-stepper-step
        step="2"
        style="cursor: pointer;"
        @click="onClickStep(2)"
    >
      {{ $t(`notifications.weight.2.title`) }}
    </v-stepper-step>

    <v-stepper-content step="2">
      {{ $t(`notifications.weight.2.description`) }}
    </v-stepper-content>

    <v-stepper-step
        step="3"
        style="cursor: pointer;"
        @click="onClickStep(3)"
    >
      {{ $t(`notifications.weight.3.title`) }}
    </v-stepper-step>

    <v-stepper-content step="3">
      {{ $t(`notifications.weight.3.description`) }}
    </v-stepper-content>
  </v-stepper>
</template>

<script>
const getEmptyItem = () => ({
  notification_weight: 0
})

export default {
  name: 'EinsFormNotificationWeight',
  props: {
    value: {
      type: Object,
      default: getEmptyItem()
    }
  },
  data () {
    return {
      internalValue: getEmptyItem()
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: 'onChangeValue'
    }
  },
  methods: {
    onChangeValue (val, oldVal) {
      this.setValue(val)
    },
    onClickStep (step) {
      this.internalValue.notification_weight = step
      this.emitInput()
    },
    setValue (val) {
      const keys = Object.keys(this.internalValue)
      for (const key of keys) {
        if (
          Object.prototype.hasOwnProperty.call(val, key) &&
          Object.prototype.hasOwnProperty.call(this.internalValue, key)
        ) {
          this.internalValue[key] = val[key]
        }
      }
    },
    emitInput () {
      this.$emit('input', { ...this.internalValue })
    }
  }
}
</script>

<style scoped>

</style>
