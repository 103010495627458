<template>
  <div>
    <v-row
        class="pa-0 ma-0"
        no-gutters
    >
      <v-col class="ma-0 d-none">
        <v-text-field
            v-model="player.name"
            outlined
            :label="cText['fields.name.label']"
            :prepend-icon="player.name && player.name.length >= 4 ? 'mdi-account-box' : 'mdi-checkbox-blank-circle-outline'"
            :rules="[ val => val.length >= 4 || cText['fields.name.hint'] ]"
            :placeholder="cText['fields.name.placeholder']"
            @input="emitInput"
        />
      </v-col>
    </v-row>
    <v-row
        class="pa-0 ma-0"
        no-gutters
    >
      <v-col class="pb-0">
        <v-text-field
            v-model="player.email"
            outlined
            background-color="grey darken-4"
            :label="cText['fields.eMail.label']"
            :placeholder="cText['fields.eMail.placeholder']"
            :rules="[ rules.eMailValid ]"
            :prepend-icon="cEMailValid ? 'mdi-at' : 'mdi-checkbox-blank-circle-outline'"
            @input="emitInput"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
          cols="10"
          class="text-right"
      >
        <span>{{ cText['fields.show.label'] }}</span>
      </v-col>
      <v-col cols="2">
        <v-switch
            v-model="enableGeneratePass"
            class="ml-2 mt-n1"
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        :class="enableGeneratePass ? 'd-none' : ''"
    >
      <v-col class="pb-0 mb-0">
        <v-text-field
            ref="fieldPassword"
            v-model="player.password"
            outlined
            background-color="grey darken-4"
            :label=" cText['fields.password.label']"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            append-outer-icon="mdi-information-outline"
            :prepend-icon="fieldIsValid.fieldPassword ? 'mdi-form-textbox-password' : 'mdi-checkbox-blank-circle-outline'"
            :type="passwordVisible ? 'password' : 'text'"
            :persistent-hint="formatInfo"
            :hint="cPasswordHint"
            :rules="rules.password"
            @click:append="() => (passwordVisible = !passwordVisible)"
            @click:append-outer="() => (formatInfo = !formatInfo)"
            @input="emitInput"
        ></v-text-field>
        <v-text-field
            ref="fieldPasswordConfirmation"
            v-model="player.password_confirmation"
            outlined
            background-color="grey darken-4"
            :label=" cText['fields.passwordConfirmation.label']"
            :append-icon="passwordConfirmationVisible ? 'mdi-eye' : 'mdi-eye-off'"
            append-outer-icon="mdi-information-outline"
            :prepend-icon="fieldIsValid.fieldPasswordConfirmation ? 'mdi-form-textbox-password' : 'mdi-checkbox-blank-circle-outline'"
            :type="passwordConfirmationVisible ? 'password' : 'text'"
            :persistent-hint="formatConfirmedInfo"
            :hint="cPasswordConfirmationHint"
            :rules="rules.password_confirmation"
            validate-on-blur
            @click:append="() => (passwordConfirmationVisible = !passwordConfirmationVisible)"
            @click:append-outer="() => (formatConfirmedInfo = !formatConfirmedInfo)"
            @input="emitInput"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- FormValidation -->
    <div style="display:none">
      <v-checkbox
          v-model="player.age_accepted"
          :rules="[ v => !!v ]"
      ></v-checkbox>
      <v-checkbox
          v-model="player.terms_and_conditions_accepted"
          :rules="[ v => !!v ]"
      ></v-checkbox>
    </div>

    <v-row
        no-gutters
        class="mt-1"
    >
      <v-col class="col-1">
        <v-icon
            v-if="cTermsAndConditionsAccepted"
            @click="onClickAgreeAll(false)"
        >
          mdi-checkbox-marked
        </v-icon>
        <v-icon
            v-else
            @click="onClickAgreeAll(true)"
        >
          mdi-checkbox-blank-outline
        </v-icon>
      </v-col>
      <v-col class="col-10 pl-1">
        <i18n path="views.ViewInformations.acceptAll.label" />
      </v-col>
      <v-col class="col-1">
      </v-col>
    </v-row>
    <v-dialog
        v-model="validation.dialogDataProtectionRegulations"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row
            no-gutters
            class="mt-1"
            v-bind="attrs"
        >
          <v-col class="col-1">
            <v-icon
                v-if="validation.readDataProtectionRegulations"
                @click="onClickReadTerms(false)"
            >
              <!--mdi-checkbox-marked -->
            </v-icon>
            <v-icon
                v-else
                @click="onClickReadTerms(true)"
            >
              <!--mdi-checkbox-blank-outline -->
            </v-icon>
          </v-col>
          <v-col class="col-10 pl-1">
            <i18n path="views.ViewInformations.checkDataProtectionRegulations.label" />
          </v-col>
          <v-col class="col-1">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <v-card class="detail-content">
        <v-card-title>
          <span class="headline">
            <i18n path="views.ViewInformations.checkDataProtectionRegulations.dialog.title" />
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="onClickReadTerms(false)"
          >
            <i18n path="views.ViewInformations.button.disagree" />
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="onClickReadTerms(true)"
          >
            <i18n path="views.ViewInformations.button.agree" />
          </v-btn>
        </v-card-actions>
        <v-card-text class="text-pre-line">
          <i18n path="views.ViewInformations.dataProtection.dialog.text" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="validation.dialogDataProcessingInformation"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row
            no-gutters
            class="mt-2"
            v-bind="attrs"
        >
          <v-col class="col-1">
            <v-icon
                v-if="validation.readDataProcessingInformation"
                @click="onClickReadConditions(false)"
            >
              <!--mdi-checkbox-marked -->
            </v-icon>
            <v-icon
                v-else
                @click="onClickReadConditions(true)"
            >
              <!--mdi-checkbox-blank-outline -->
            </v-icon>
          </v-col>
          <v-col class="col-10 pl-1">
            <i18n path="views.ViewInformations.dataProcessing.label" />
          </v-col>
          <v-col class="col-1">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <v-card class="detail-content">
        <v-card-title>
          <span class="headline">
            <i18n path="views.ViewInformations.dataProcessing.dialog.caption" />
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="onClickReadConditions(false)"
          >
            <i18n path="views.ViewInformations.button.disagree" />
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="onClickReadConditions(true)"
          >
            <i18n path="views.ViewInformations.button.agree" />
          </v-btn>
        </v-card-actions>
        <v-card-text class="text-pre-line">
          <i18n path="views.ViewInformations.dataProcessing.dialog.text" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="validation.dialogDataPlayerRules"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row
            no-gutters
            class="mt-2"
            v-bind="attrs"
        >
          <v-col class="col-1">
            <v-icon
                v-if="validation.readDataPlayerRules"
                @click="onClickReadPlayerRules(false)"
            >
              <!--mdi-checkbox-marked -->
            </v-icon>
            <v-icon
                v-else
                @click="onClickReadPlayerRules(true)"
            >
              <!--mdi-checkbox-blank-outline -->
            </v-icon>
          </v-col>
          <v-col class="col-10 pl-1">
            <i18n path="views.ViewInformations.gamePlayRules.checkDataPlayerRules.label" />
          </v-col>
          <v-col class="col-1">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <v-card class="detail-content">
        <v-card-title>
          <span class="headline">
            <i18n path="views.ViewInformations.gamePlayRules.dialog.caption" />
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="onClickReadPlayerRules(false)"
          >
            <i18n path="views.ViewInformations.button.disagree" />
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="onClickReadPlayerRules(true)"
          >
            <i18n path="views.ViewInformations.button.agree" />
          </v-btn>
        </v-card-actions>
        <v-card-text class="text-pre-line">
          <i18n path="views.ViewInformations.gamePlayRules.dialog.text" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import {
  eMailValid,
  isNotEmpty,
  hasMinLength
} from '@/utils/inputRules'
import {
  Password
} from '@/constants'

const getEmptyItem = () => ({
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  language: 'en',
  enable_geolocation: '1',
  age_accepted: true,
  terms_and_conditions_accepted: false
})

export default {
  name: 'AppRegister',
  mixins: [
    MxTranslatable
  ],
  props: {
    value: {
      type: Object,
      default: getEmptyItem
    }
  },
  data () {
    return {
      player: getEmptyItem(),
      enableNameGenerate: true,
      enableGeneratePass: true,
      valid: true,
      passwordVisible: true,
      passwordConfirmationVisible: true,
      formatInfo: false,
      formatConfirmedInfo: false,
      validation: {
        age: false,
        readDataProtectionRegulations: false,
        dialogDataProtectionRegulations: false,
        readDataProcessingInformation: false,
        dialogDataProcessingInformation: false,
        readDataPlayerRules: false,
        dialogDataPlayerRules: false
      },
      fieldIsValid: {
        fieldPassword: null,
        fieldPasswordConfirmation: null
      },
      rules: {
        eMailValid,
        password: [
          isNotEmpty,
          hasMinLength(Password.MIN_LENGTH)
        ],
        password_confirmation: [
          val => val === this.player.password || 'Passwords dont match!'
        ]
      },
      $_mx_translatable: {
        paths: [
          'fields.name.label',
          'fields.name.placeholder',
          'fields.name.hint',
          'fields.eMail.label',
          'fields.eMail.placeholder',
          'fields.eMail.hint',
          'fields.show.label',
          'fields.password.label',
          'fields.password.hint',
          'fields.passwordConfirmation.label',
          'fields.passwordConfirmation.hint'
        ]
      }
    }
  },
  computed: {
    cAgeAccepted () {
      return this.player.age_accepted
    },
    cTermsAndConditionsAccepted () {
      return this.validation.readDataProtectionRegulations && this.validation.readDataProcessingInformation && this.validation.readDataPlayerRules
    },
    cEMailValid () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(String(this.player.email).toLowerCase())
    },
    cEMailHint () {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (
        pattern.test(String(this.player.email).toLowerCase()) || this.cText['fields.eMail.hint']
      )
    },
    cPasswordEqual () {
      return this.player.password_confirmation === this.player.password
    },
    cPasswordHint () {
      return this.cText['fields.password.hint']
    },
    cPasswordConfirmationHint () {
      return this.cText['fields.passwordConfirmation.hint']
    }
  },
  watch: {
    value: 'setValue'
  },
  created () {
    this.setValue()
    if ((this.player.name === '') && (this.enableNameGenerate)) {
      this.player.name = this.generatePlayerName()
      if (this.enableGeneratePass) {
        this.player.password = this.player.password_confirmation = this.generateFirstPassword()
      }
    }
  },
  mounted () {
    this.watchInput()
  },
  methods: {
    setValue () {
      this.$set(this.$data, 'player', {
        ...getEmptyItem(),
        ...this.value
      })
    },
    onClickAgeValidation () {
      this.player.age_accepted = !this.player.age_accepted
      this.emitInput()
    },
    onClickAgreeAll (input) {
      this.validation.readDataProtectionRegulations = this.validation.readDataProcessingInformation = this.validation.readDataPlayerRules = input
      this.player.terms_and_conditions_accepted = this.cTermsAndConditionsAccepted
      this.emitInput()
    },
    onClickReadTerms (input) {
      this.validation.readDataProtectionRegulations = input
      this.player.terms_and_conditions_accepted = this.cTermsAndConditionsAccepted
      this.emitInput()
      this.validation.dialogDataProtectionRegulations = false
    },
    onClickReadConditions (input) {
      this.validation.readDataProcessingInformation = input
      this.player.terms_and_conditions_accepted = this.cTermsAndConditionsAccepted
      this.emitInput()
      this.validation.dialogDataProcessingInformation = false
    },
    onClickReadPlayerRules (input) {
      this.validation.readDataPlayerRules = input
      this.player.terms_and_conditions_accepted = this.cTermsAndConditionsAccepted
      this.emitInput()
      this.validation.dialogDataPlayerRules = false
    },
    emitInput () {
      this.$emit('input', {
        ...this.player
      })
    },
    generatePlayerName () {
      return 'Player' + Math.floor(Math.random() * (Math.floor(1000000) - Math.ceil(1))).toString()
    },
    generateFirstPassword () {
      return 'P' + Math.floor(Math.random() * (Math.floor(10000000000) - Math.ceil(1))).toString()
    },
    watchInput () {
      Object.keys(this.fieldIsValid).forEach(fieldName => {
        this.$refs[fieldName].$watch('hasError', (val) => {
          this.fieldIsValid[fieldName] = !val
        }, { immediate: true })
      })
    }
  }
}
</script>

<style scoped>

</style>
