import { addToCache, clearCache, removeFromCache } from '@/plugins/store/factories'
import { http } from '@/plugins'

export const notifications = {
  namespaced: true,
  state: {
    limit: 10,
    page: 1,
    cache: [],
    result: null,
    loading: false,
    reachedEnd: false
  },
  getters: {
    list: state => state.cache,
    listLoading: state => state.loading,
    listEnd: state => state.reachedEnd,
    latestTimestamp: state => {
      return state.cache
        .reduce((acc, el) => !acc || el.created_at.getTime() > acc.getTime() ? new Date(el.created_at) : acc, null)
    },
    oldestTimestamp: state => {
      return state.cache
        .reduce((acc, el) => !acc || el.created_at.getTime() < acc.getTime() ? new Date(el.created_at) : acc, null)
    },
    oldestTimestampUnread: state => {
      return state.cache
        .filter(el => el.read_at === null)
        .reduce((acc, el) => !acc || el.created_at.getTime() < acc.getTime() ? new Date(el.created_at) : acc, null)
    }
  },
  mutations: {
    addToCache,
    clearCache,
    removeFromCache
  },
  actions: {
    getData ({ state, commit, rootGetters }, params = {}) {
      state.loading = true
      return http.$api.get('/notifications', {
        params: {
          limit: state.limit,
          page: state.page,
          ...params
        }
      })
        .then(response => {
          commit('addToCache', response.data?.notifications ?? [])
          state.limit = response.data?.limit || 10
          state.page = response.data?.page || 1
          state.reachedEnd = response.data.count +
            (response.data.page - 1) * response.data.limit >=
            response.data.count_filtered
          return response.data
        })
        .catch(() => {
          commit('clearCache')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ dispatch }) {
      return dispatch('getData')
    },
    updateNew ({ state, dispatch, getters }) {
      state.page = 1

      const continuous = () => dispatch('getData', {
        'red-since': getters.oldestTimestampUnread !== Infinity
          ? getters.oldestTimestampUnread
          : new Date((Date.now() / 1000 - 24 * 3600) * 1000)
      })
        .then(data => {
          const page = data.page
          const limit = data.limit
          const count = data.count
          const countAvailable = data.count_filtered

          if (countAvailable > count + (limit - 1) * page) {
            state.page++
            return continuous()
          } else {
            return Promise.resolve()
          }
        })

      return continuous()
    },
    updateNotification ({ state, commit, rootGetters }, uuid) {
      state.loading = true
      return http.$api.get(`/notifications/${uuid}`)
        .then(response => {
          const data = response.data
          commit('addToCache', [data])
          return data
        })
        .catch(() => {
          commit('clearCache')
        })
        .finally(() => {
          state.loading = false
        })
    },
    loadMore ({ state, dispatch, getters }) {
      state.page = 1
      return dispatch('getData', {
        before: getters.oldestTimestamp === Infinity
          ? getters.oldestTimestamp
          : new Date()
      })
    },
    loadLatest ({ state, dispatch, getters }) {
      state.page = 1

      const continuous = () => dispatch('getData', {
        since: getters.latestTimestamp > 0
          ? getters.latestTimestamp
          : new Date(0)
      })
        .then(data => {
          const page = data.page
          const limit = data.limit
          const count = data.count
          const countAvailable = data.count_filtered

          if (countAvailable > count + (limit - 1) * page) {
            state.page++
            return continuous()
          } else {
            return Promise.resolve()
          }
        })

      return continuous()
    }
  }
}
