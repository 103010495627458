<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column fill-height"
        no-gutters
    >
      <v-col class="pa-3 flex-grow-0">
        <div class="text-h5 mb-0">
          <v-icon
              style="font-size: 1.25em;"
              left
          >{{ cDealTypeLabel.icon }}</v-icon>
          {{ cDealTypeLabel.caption }}
        </div>
      </v-col>
      <v-col class="overflow-y-auto pa-3">
        <eins-deal-data-name-block />
        <eins-deal-data-picture-block class="mt-3" />
        <eins-deal-data-description-block class="mt-3" />
        <eins-deal-data-value-block class="mt-3" />
        <eins-deal-data-require-application-block class="mt-3" />
        <eins-deal-data-scope-block class="mt-3" />
        <eins-deal-data-group-deal-block
            v-if="cCanBecomeGroupDeal"
            class="mt-3"
        />
        <eins-deal-data-public-block
            v-if="cIsGroupDeal"
            :disabled="!cIsGroupDeal"
            class="mt-3"
        />
        <eins-deal-data-location-block class="mt-3" />
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsDealDataNameBlock from '@/components/deal/edit/EinsDealDataNameBlock'
import EinsDealDataPictureBlock from '@/components/deal/edit/EinsDealDataPictureBlock'
import EinsDealDataDescriptionBlock from '@/components/deal/edit/EinsDealDataDescriptionBlock'
import EinsDealDataValueBlock from '@/components/deal/edit/EinsDealDataValueBlock'
import EinsDealDataLocationBlock from '@/components/deal/edit/EinsDealDataLocationBlock'
import EinsDealDataScopeBlock from '@/components/deal/edit/EinsDealDataScopeBlock'
import EinsDealDataPublicBlock from '@/components/deal/edit/EinsDealDataPublicBlock'
import EinsDealDataGroupDealBlock from '@/components/deal/edit/EinsDealDataGroupDealBlock'
import EinsDealDataRequireApplicationBlock from '@/components/deal/edit/EinsDealDataRequireApplicationBlock'

export default {
  name: 'ViewDealEdit',
  components: {
    EinsDealDataRequireApplicationBlock,
    EinsDealDataGroupDealBlock,
    EinsDealDataPublicBlock,
    EinsDealDataScopeBlock,
    EinsDealDataLocationBlock,
    EinsDealDataValueBlock,
    EinsDealDataDescriptionBlock,
    EinsDealDataPictureBlock,
    EinsDealDataNameBlock,
    EinsLayoutViewDetail
  },
  mixins: [MxTranslatable],
  data: () => ({
    dealId: 0,
    formData: null,
    originData: null,
    formValid: false,
    saving: false,
    $_mx_translatable: {
      paths: [
        'captions.service',
        'captions.product'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cLoading () {
      return this.$store.getters['deals/selected/loading']
    },
    cError () {
      return this.$store.getters['deals/selected/error']
    },
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cIsGroupDeal () {
      return this.cDeal?.is_group_deal
    },
    cCanBecomeGroupDeal () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []

      return adminGroups.includes(this.cDeal.player_group_id)
    },
    cDealTypeLabel () {
      let result = { icon: '', caption: '' }
      let type = ''
      if (this.cDeal?.id !== null) {
        type = this.cDeal?.type
      }
      switch (type) {
        case 'service':
          result = { icon: 'mdi-face-agent', caption: this.cText['captions.service'] }
          break
        case 'product':
          result = { icon: 'mdi-basket', caption: this.cText['captions.product'] }
          break
      }
      return result
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadDeal(to.params.id)
      vm.fetchPlayerGroups()
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadDeal(to.params.id)
      this.fetchPlayerGroups()
    }
    next()
  },
  watch: {
    'formData.title' (val) {
      this.saveData('title', val)
    },
    'formData.description' (val) {
      this.saveData('description', val)
    },
    'formData.picture' (val) {
      this.savePicture(val)
    },
    'formData.value' (val) {
      this.saveData('value', val)
    },
    'formData.require_application' (val) {
      this.saveData('require_application', val)
    },
    'formData.latitude' (val) {
      this.saveData('latitude', val, {
        'latitude': val,
        'longitude': this.formData.longitude
      })
    },
    'formData.street' (val) {
      this.saveData('street', val, {
        'street': val,
        'house_number': this.formData.house_number,
        'postal_code': this.formData.postal_code,
        'city': this.formData.city,
        'country': this.formData.country
      })
    },
    'formData.player_group_id' (val) {
      this.saveData('player_group_id', val)
    },
    'formData.public' (val) {
      if (val === true) {
        this.saveData('public', val)
      } else {
        this.saveData('public', val, {
          'public': val,
          'player_group_id': this.formData.player_group_id
        })
      }
    },
    'formData.is_group_deal' (val) {
      this.saveData('is_group_deal', val)
    }
  },
  created () {
  },
  methods: {
    saveData (attr, input, payload) {
      if (this.originData[attr] !== input) {
        this.saving = true
        let data = { [attr]: input }
        if (payload) {
          data = payload
        }
        this.$http.$api.patch('/deals/' + this.dealId, data)
          .then((result) => {
            return this.loadDeal(this.dealId)
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    savePicture (pic) {
      if (pic) {
        this.saving = true
        this.$http.$api.put('/deals/' + this.dealId + '/picture', pic, { headers: {
          'Content-Type': pic.type
        } })
          .then((result) => {
            return this.loadDeal(this.dealId)
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    loadDeal (id) {
      return this.$store.dispatch('deals/selected/load', id)
        .then(result => {
          // this.formData = null
          // this.originData = null
          this.$set(this, 'dealId', result.data.id)
          this.$set(this, 'formData', result.data)
          this.$set(this, 'originData', result.data)
          return result.data
        })
    },
    fetchPlayerGroups () {
      return this.$store.dispatch('players/current/groups/getData')
    }
  }
}
</script>

<style scoped>

</style>
