<template>
  <eins-layout-list
      #default="{ item }"
      :items="applications"
  >
    <slot
        name="item"
        :item="item"
    >
      <eins-application-details-dialog
          :application="item"
          :hide-accept-action="cHideAcceptAction"
          :disable-accept-action="loadingAccept"
          :show-reject-action="showRejectAction"
      >
        <template #activator="{ on }">
          <eins-application-list-item
              :application="item"
              :hide-accept-action="cHideAcceptAction"
              :disable-accept-action="loadingAccept"
              :show-reject-action="showRejectAction"
              v-on="on"
              @load:accept="onLoadAccept"
          />
        </template>
      </eins-application-details-dialog>
    </slot>
  </eins-layout-list>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsApplicationListItem from '@/components/application/EinsApplicationListItem'

import {
  ApplicationState
} from '@/constants'
import EinsApplicationDetailsDialog from '@/components/application/EinsApplicationDetailsDialog'

export default {
  name: 'EinsApplicationList',
  components: {
    EinsApplicationDetailsDialog,
    EinsApplicationListItem,
    EinsLayoutList
  },
  props: {
    applications: {
      type: Array,
      required: true,
      default: () => []
    },
    showRejectAction: {
      type: Boolean,
      default: false
    },
    allowMultipleAccepted: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingAccept: false
  }),
  computed: {
    cHideAcceptAction () {
      return (
        !this.allowMultipleAccepted && this.cHasAcceptedApplication
      ) || (
        this.allowMultipleAccepted && this.cHasConfirmedApplication
      )
    },
    cHasAcceptedApplication () {
      const states = [ApplicationState.ACCEPTED, ApplicationState.CONFIRMED]
      return this.applications.reduce((acc, el) => acc || states.includes(el.state), false)
    },
    cHasConfirmedApplication () {
      return this.applications.reduce((acc, el) => acc || el.state === ApplicationState.CONFIRMED, false)
    }
  },
  methods: {
    onLoadAccept (value) {
      this.loadingAccept = value
    }
  }
}
</script>

<style scoped>

</style>
