<template>
  <eins-layout-view-detail>
    <eins-object-image-collection
        object-type="group"
        use-upload
        :object="cGroup"
    >
    </eins-object-image-collection>
    <eins-error-not-found
        v-if="cError === 404"
    >
      <i18n path="views.ViewGroupDetail.theGroup" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>
      </template>
    </eins-error-not-found>
    <eins-error-forbidden
        v-else-if="error === 403"
    >
      <i18n path="views.ViewGroupDetail.theGroupLc" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>!
      </template>
    </eins-error-forbidden>
  </eins-layout-view-detail>
</template>

<script>
import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsObjectImageCollection from '@/components/images/EinsObjectImageCollection'

export default {
  name: 'ViewGroupImages',
  components: {
    EinsObjectImageCollection,
    EinsLayoutViewDetail,
    EinsErrorForbidden,
    EinsErrorNotFound
  },
  data: () => ({
    loading: false,
    group: null,
    error: false
  }),
  computed: {
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      return this.$store.getters['groups/selected/loading']
    },
    cError () {
      return this.$store.getters['groups/selected/error']
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    }
  }
}
</script>

<style scoped>

</style>
