import {
  router,
  store,
  http
} from '@/plugins'

export const startConversationWith = (playerId) => new Promise((resolve, reject) => {
  let navigated = false
  const navigate = conversationId => {
    // prevent duplicate navigation
    if (!navigated) {
      navigated = true
      resolve()

      router.push({
        name: 'conversation-details',
        params: {
          id: conversationId
        }
      })
    }
  }

  store.dispatch('conversations/findConversation', playerId)
    .then(navigate)
    .catch((e) => http.$api.post('conversations', { player_id: playerId }))
    .then((result) => {
      if (!navigated) {
        const data = result.data
        return data.id
      } else {
        return null
      }
    })
    .then(navigate)
    .catch(e => reject(e))
})
