<template>
  <eins-edit-group-scope-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      api-param="player_group_id"
      :current-value="cQuestScope"
      :current-value-label="cText.currentValueLabel"
      :current-value-hint="cText.currentValueHint"
      :dialog-title="cText.dialogTitle"
      :submit-label="cText.submitLabel"
      :success-message="cSuccessMessage"
      :list-caption="cText.listCaption"
      :void-option="cVoidOption"
  />
</template>

<script>
import EinsEditGroupScopeBlock from '@/components/form/edit/EinsEditGroupScopeBlock'
import {
  QuestType
} from '@/constants'

export default {
  name: 'EinsQuestDataScopeBlock',
  components: {
    EinsEditGroupScopeBlock
  },
  data: () => ({
  }),
  computed: {
    cText () {
      return {
        dialogTitle: this.$t('quest.update.scope.dialogTitle'),
        listCaption: this.$t('quest.update.scope.listCaption'),
        currentValueLabel: this.$t('quest.update.scope.currentValueLabel'),
        currentValueHint: this.$t('quest.update.scope.currentValueHint'),
        submitLabel: this.$t('quest.update.scope.actions.submit')
      }
    },
    cVoidOption () {
      return {
        label: this.$t('quest.update.scope.voidOption'),
        icon: 'mdi-account-multiple'
      }
    },
    cApiPath () {
      return `quests/${this.cQuestId}`
    },
    cQuestId () {
      return this.$store.getters['quests/selected/quest']?.id
    },
    cQuestScope () {
      return this.$store.getters['quests/selected/quest']?.player_group_id ?? null
    },
    cIsPrivateQuest () {
      return this.$store.getters['quests/selected/quest']?.type === QuestType.PRIVATE
    },
    cSuccessMessage () {
      return {
        title: this.$t('quest.update.scope.success.title'),
        text: this.$t('quest.update.scope.success.text')
      }
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('quests/selected/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
