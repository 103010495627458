<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-account-cancel</v-icon>
    {{ cText }}
  </eins-starmenu-ab>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbBlockPlayer',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  data: () => ({
  }),
  computed: {
    cText () {
      return this.$t(`player.block.button`)
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
        this.$store.getters['players/current/id'] !== this.$route.params.id
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogBlockPlayer')
        .then(() => {
        })
    }
  }
}
</script>

<style scoped>
</style>
