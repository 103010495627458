export default {
  captions: {
    caption: 'Wähle ein neues Passwort',
    confirm: 'Wiederhole dss Passwort',
    reset: 'Passwort zurücksetzen'
  },
  hints: {
    passwordFalse: 'Passworte sind unterschiedlich!'
  },
  label: {
    eMail: 'Schreibe Deine @.lower:(terms.player) eMail'
  },
  alert: {
    link: 'Wir haben Dir einen Link an Deine eMail gesendet',
    answer: 'Ihr Passwort wurde erfolgreich zurückgesetzt!'
  },
  error: {
    422: 'Die angegebene E-Mail ist uns nicht bekannt!',
    429: 'Zu viele Anfragen! - Warten Sie einige Minuten und versuchen Sie es erneut!',
    else: 'Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal!'
  },
  fields: {
    password: {
      label: 'Neues Passwort eingeben',
      hint: 'Das Passwort muss mindestens 10 Zeichen enthalten',
      rules: 'muss mindestens 10 Zeichen enthalten'
    },
    passwordConfirmation: {
      label: 'Passwort bestätigen',
      hint: 'Das Passwort muss den oben angegebenen Standards entsprechen',
      rules: [
        ' müssen die oben genannten Standards enthalten'
      ]
    }
  },
  buttons: {
    submit: {
      label: 'Neues Passwort festlegen'
    },
    login: {
      label: 'Zurück zur Anmeldung'
    },
    cancel: {
      label: 'Abbrechen'
    },
    set: {
      label: 'neues Passwort setzen'
    }
  },
  PasswordChange: {
    current: 'Aktuelles Passwort',
    new: 'Neues Passwort',
    confirm: 'Passwort Bestätigung',
    match: 'Passwörter stimmen nicht überein!'
  }
}
