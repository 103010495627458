<template>
  <div class="guider-cont">
    <h2 class="head">
      QR-Code Scanner
    </h2>

    <div class="box"></div>
    <v-spacer> </v-spacer>
    <p
        v-if="error"
        class="error-message"
    >
      {{ error }}
    </p>
    <v-spacer> </v-spacer>
    <v-btn
        id="close"
        v-bind="$attrs"
        @click="closeScanner"
    >
      <slot>
        Cancel
      </slot>
    </v-btn>
  </div>
</template>

<script>
import {
  MxTranslatable
} from '@/mixins'
import {
  Entity,
  HttpStatus
} from '@/constants'
import { RegUuid } from '@/utils/regex'

const validRoutes = ['players', 'groups', 'deals', 'quests']

export default {
  name: 'EinsDialogQrCodeScanner',
  mixins: [
    MxTranslatable
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'title',
        'text',
        'close'
      ]
    },
    error: '',
    camera: 'auto',
    lastScanned: '',
    qrScanner: false
  }),
  watch: {
    value: function (val) {
      this.camera = val ? 'auto' : 'off'
    },
    lastScanned: function (val, oldVal) {
      if (val !== oldVal) {
        if (this.isModernQrCode(val)) {
          this.handleModernQrCode(val)
            .then(() => this.closeScanner())
        } else {
          this.parseURL(val)
        }
      }
    }
  },
  mounted () {
    this.activateScanner()
  },
  methods: {
    scanResult (err, text) {
      if (err) {
        this.error = err
      } else {
        this.lastScanned = text
      }
    },
    scan () {
      window.QRScanner.show()
      window.QRScanner.scan(this.scanResult)
    },
    activateScanner () {
      this.scan()
    },
    isValidUrl (url) {
      const parts = url.split('/')
      const regexp = new RegExp(RegUuid)
      return parts.length === 3 && parts[0] === '' && validRoutes.includes(parts[1]) && regexp.test(parts[2])
    },
    parseURL (val) {
      const parts = val.split('#')
      let valid = parts.length > 1 && this.isValidUrl(parts[1])
      if (valid) {
        this.$router.push(parts[1])
        this.closeScanner()
      } else {
        this.lastScanned = ''
        this.error = 'Invalide URL'
        this.activateScanner()
      }
    },
    handleModernQrCode (text) {
      const payload = this.getModernQrCodePayload(text)
      if (payload) {
        switch (payload.entity) {
          case Entity.TEMPLATE:
            return this.navigateToLatestTemplateItem(payload.key)
          case Entity.QUEST:
            return this.navigateToQuest(payload.key)
          case Entity.DEAL:
            return this.navigateToDeal(payload.key)
          case Entity.GROUP:
            return this.navigateToGroup(payload.key)
        }
      }

      this.error = this.$t('qrCode.error.invalid')
    },
    isModernQrCode (text) {
      try {
        const url = new URL(text)
        return url.pathname.split('/').pop() === 'qr-code' &&
          url.searchParams.has('payload')
      } catch (e) {
        return false
      }
    },
    getModernQrCodePayload (text) {
      try {
        const url = new URL(text)
        return JSON.parse(url.searchParams.get('payload'))
      } catch (e) {
        return null
      }
    },
    closeScanner () {
      window.QRScanner.destroy()
      this.$emit('closeScanner', this.qrScanner)
    },
    navigateToLatestTemplateItem (id) {
      return this.$http.$api.get(`/templates/${id}/current-item`)
        .then(response => {
          if (response.status === HttpStatus.OK) {
            switch (response.data.type) {
              case Entity.QUEST:
                return this.navigateToQuest(response.data.id)
              case Entity.DEAL:
                return this.navigateToDeal(response.data.id)
            }
          }

          this.error = this.$t('qrCode.error.notFound')

          return false
        })
        .catch(err => {
          switch (err.response.status) {
            case HttpStatus.NOT_FOUND:
              this.error = this.$t('qrCode.error.notFound')
              break
            case HttpStatus.FORBIDDEN:
              this.error = this.$t('qrCode.error.forbidden')
              break
            default:
              this.error = this.$t('qrCode.error.invalid')
          }

          return false
        })
    },
    navigateToQuest (id) {
      return this.$router.push({
        name: 'quest-detail',
        params: { id }
      })
    },
    navigateToDeal (id) {
      return this.$router.push({
        name: 'deal-detail',
        params: { id }
      })
    },
    navigateToGroup (id) {
      return this.$router.push({
        name: 'group-detail',
        params: { id }
      })
    }
  }
}
</script>

<style scoped>

.box{
  width: 250px;
  height: 250px;
  background: #c1c1c138;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
}

.guider-cont {
  z-index: 10;
  background: #0000008f;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  opacity: 1;
  pointer-events: all;
}

.head{
  text-align: center;
  margin-top: 20%;
  color: #121212;
}

#close{
  margin-top: 20%;
  margin-left: 15%;
  margin-right: 15%;
}

.error-message{
  color: #900a0a;
  text-align: center;
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
}

</style>
