<template>
  <v-row
      align="baseline"
      class="text-h6 flex-nowrap"
      justify="space-between"
  >
    <v-col>
      {{ currentValueLabel }}: <strong>{{ cCurrentValueFormatted }}</strong>
    </v-col>
    <v-col class="flex-grow-0">
      <v-btn
          icon
          @click="onClickChange"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-col>
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text v-if="!success">
          <v-form
              ref="form"
              v-model="form.valid"
              :disabled="loading"
          >
            <eins-input-time-value
                v-model="form.value"
                :max="max"
                :min="min"
                :star-mode="starMode"
                :user-balance-unlock="currentValue"
                :cap-with-user-balance="capWithUserBalance"
            />
          </v-form>
        </v-card-text>
        <v-alert
            text
            tile
            type="success"
            transition="expand-transition"
            :value="success"
        >
          <div class="font-weight-bold">
            {{ successMessage.title }}
          </div>
          <div>{{ successMessage.text }}</div>
        </v-alert>
        <v-expand-transition>
          <v-card-actions
              v-if="!success"
              class="flex-column"
          >
            <v-btn
                block
                color="primary"
                :disabled="!form.valid || cValueIsUnchanged"
                :loading="loading"
                @click="onClickSubmit"
            >{{ submitLabel }}</v-btn>
            <v-btn
                block
                class="mx-0 mt-2"
                outlined
                :disabled="loading"
                @click="onClickCancel"
            ><i18n path="msc.actions.cancel" /></v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <eins-btn-timed
                block
                color="primary"
                outlined
                :disabled="loading"
                @click="onClickClose"
            >
              <i18n path="msc.actions.close" />
            </eins-btn-timed>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import EinsInputTimeValue from '@/components/input/EinsInputTimeValue'

export default {
  name: 'EinsEditTimeValueBlock',
  components: {
    EinsInputTimeValue,
    EinsBtnTimed
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiPath: {
      type: String,
      required: true
    },
    currentValue: {
      type: Number,
      required: true
    },
    currentValueLabel: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    successMessage: {
      type: Object,
      required: true
    },
    capWithUserBalance: {
      type: Boolean,
      default: false
    },
    starMode: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    form: {
      valid: false,
      value: 0
    }
  }),
  computed: {
    cValueIsUnchanged () {
      return this.currentValue === this.form.value
    },
    cCurrentValueFormatted () {
      return this.$tc(
        `timeValue.${this.starMode ? 'stars' : 'points'}Value`,
        this.starMode ? Math.round(this.currentValue / 60) : this.currentValue
      )
    }
  },
  methods: {
    onClickChange () {
      this.openDialog()
    },
    onInputName () {
      this.markFormDirty()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiPath, {
        value: this.form.value
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    openDialog () {
      this.dialog = true
      this.reset()
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.success = false
      this.form.value = this.currentValue
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
