import Vue from 'vue'

import { http } from '@/plugins'

export const stats = {
  namespaced: true,
  stats: {
    timeAccounts: null
  },
  getters: {
    timeAccountGreenBalance: state => state.timeAccounts?.green.balance,
    timeAccountCommonBalance: state => state.timeAccounts?.common.balance,
    timeAccountGlobalBalance: state => state.timeAccounts?.players.balance,
    timeAccountGlobalAmount: state => state.timeAccounts?.players.amount
  },
  mutations: {
    timeAccounts: (state, payload) => {
      Vue.set(state, 'timeAccounts', payload)
    }
  },
  actions: {
    updateTimeAccounts: ({ commit }) => http.$api.get('stats/time-accounts')
      .then(response => commit('timeAccounts', response.data))
      .catch(() => commit('timeAccounts', null))
  }
}
