export default {
  fields: {
    name: {
      label: '@.lower:(terms.player) Name',
      placeholder: 'Bestimme einen @.lower:(terms.player) Name',
      hint: 'Bitte wähle Deinen @.lower:(terms.player) Name'
    },
    eMail: {
      label: 'eMail ',
      placeholder: '@.lower:(terms.player) e-Mail Adresse ',
      hint: 'Bitte trage die aktuelle @.lower:(terms.player) e-mail Addresse ein'
    },
    password: {
      label: 'Passowort eingeben',
      hint: 'Passwort muss länger als 10 sein'
    },
    passwordConfirmation: {
      label: 'Passwort bestätigen',
      hint: 'Die Passwörter müssen übereinstimmen'
    },
    show: {
      label: 'Password ausgeblendet'
    }
  }
}
