export default {
  caption: 'Notification-Feed',
  type: {
    fcm: {
      title: 'Push-Notifications'
    },
    email: {
      title: 'Email-Notifications'
    }
  },
  labels: {
    group: {
      applied: ' has applied for the @.lower:(terms.group). Accept or reject their application',
      joined: ' has joined the @.lower:(terms.group)',
      member: 'You are now a member of this @.lower:(terms.group)',
      notMember: 'You are not a member of this @.lower:(terms.group)',
      admin: 'You are promote to a admin of this @.lower:(terms.group)',
      downMember: 'You are demote to a member of this @.lower:(terms.group)'
    },
    deal: {
      applied: ' has applied for your @.lower:(terms.deal)',
      assigned: 'You has been assigned for the @.lower:(terms.deal) and is now able to get it!',
      finished: ' finished the @.lower:(terms.deal). Congrats!',
      withdrawn: ' has rejected the @.lower:(terms.deal). Choose another player',
      friend: ' a friend has offer the @.lower:(terms.deal). Check it',
      group: ' a group has offer the @.lower:(terms.deal). Check it'
    },
    quest: {
      applied: ' has applied to your @.lower:(terms.quest). Assign or reject the application',
      assigned: 'You were assigned. You can start the @.lower:(terms.quest) now!',
      rejected: 'You are not assigned. Maybe check the next @.lower:(terms.quest)',
      started: ' has started the @.lower:(terms.quest)',
      finished: ' has completed the @.lower:(terms.quest) and is waiting for your confirmation.',
      approved: 'Congratulations! You finished the @.lower:(terms.quest)',
      friend: 'A friend need support for this @.lower:(terms.quest)',
      group: 'A @.lower:(terms.group) need support for this @.lower:(terms.quest)'
    },
    friendship: {
      newFriend: 'You are now friends.',
      newFollower: 'You have a new Follower.'
    }
  },
  actions: {
    start: 'Start',
    finish: 'Finish',
    applicants: 'Applicants',
    approve: 'Approve',
    accept: 'Exchange',
    reject: 'Reject'
  },
  weight: {
    1: {
      title: 'All',
      description: 'Receive all types of notifications'
    },
    2: {
      title: 'Important',
      description: 'Receive only notifications about you'
    },
    3: {
      title: 'None',
      description: 'Receive no notifications at all'
    }
  },
  buttons: {
    save: 'save',
    cancel: 'cancel'
  }
}
