<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column"
        style="height: 100%;"
        no-gutters
    >
      <v-col
          v-if="cGroup"
          class="flex-grow-0 flex-shrink-1"
      >
        <v-container>
          <v-row dense>
            <v-col class="flex-grow-0">
              <eins-api-image
                  #default="{ src }"
                  :src="cPicture"
              >
                <v-img
                    :src="src"
                    aspect-ratio="1"
                    width="75"
                >
                  <template #placeholder>
                    <v-skeleton-loader
                        type="image"
                    />
                  </template>
                </v-img>
              </eins-api-image>
            </v-col>
            <v-col class="text-h5">
              {{ cGroup.title }}
            </v-col>
          </v-row>
          <v-text-field
              v-model="textFilter"
              solo
              hide-details
          >
            <template #label>
              <i18n path="views.ViewGroupDeals.textFilter.label" />
            </template>
            <template #prepend-inner>
              <v-icon color="primary">mdi-google-circles-communities</v-icon>
              <v-icon color="primary">mdi-cart</v-icon>
            </template>
          </v-text-field>
        </v-container>
      </v-col>
      <v-col style="overflow-y: auto; height: 100%;">
        <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
          <v-slide-y-transition>
            <v-progress-linear
                v-show="cLoading"
                height="5"
                indeterminate
            />
          </v-slide-y-transition>
        </div>
        <eins-deal-list
            :deals="cDeals"
        />
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsDealList from '@/components/deal/EinsDealList'
import EinsApiImage from '@/components/EinsApiImage'

import genericPicture1 from '@/assets/img/group/unknown-1.jpeg'

export default {
  name: 'ViewGroupDeals',
  components: {
    EinsApiImage,
    EinsDealList,
    EinsLayoutViewDetail
  },
  data: () => ({
    lastPosition: 0,
    textFilter: ''
  }),
  computed: {
    cPicture () {
      return this.cGroup && this.cGroup?.title_picture_feed ? this.cGroup?.title_picture_feed : genericPicture1
    },
    cFinishedDeals () {
      return this.$route.name === 'group-deals'
    },
    cDeals () {
      const key = this.cFinishedDeals ? 'deals' : 'dealsFinished'
      const textFilterLc = this.textFilter.toLowerCase()
      return this.$store.getters[`groups/selected/${key}/list`]
        .filter(el => {
          return this.textFilter?.length === 0 || el.title.toLowerCase().includes(textFilterLc)
        })
        .sort((a, b) => b.state.localeCompare(a.state))
    },
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      const key = this.cFinishedDeals ? 'deals' : 'dealsFinished'
      return this.$store.getters['groups/selected/loading'] ||
          this.$store.getters[`groups/selected/${key}/listLoading`]
    },
    cError () {
      return this.$store.getters['groups/selected/error']
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.clearCache()
      vm.loadGroup(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.clearCache()
      this.loadGroup(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadGroup(this.$route.params.id)
    },
    clearCache () {
      const key = this.cFinishedDeals ? 'deals' : 'dealsFinished'
      return this.$store.commit(`groups/selected/${key}/clearCache`)
    },
    loadGroup (id) {
      return this.$store.dispatch('groups/selected/load', id)
        .then(this.loadDealList)
    },
    loadDealList () {
      const key = this.cFinishedDeals ? 'deals' : 'dealsFinished'
      return this.$store.dispatch(`groups/selected/${key}/loadAll`)
    }
  }
}
</script>

<style scoped>
</style>
