import { store } from '@/plugins/store'
import { isUuid } from '@/utils/api'

const ViewConfirmEmail = () => import(/* webpackChunkName: "confirm-email" */ '@/views/ViewConfirmEmail')
const ViewRegister = () => import(/* webpackChunkName: "register" */ '@/views/ViewRegister')
const ViewResetPassword = () => import(/* webpackChunkName: "reset-password" */ '@/views/ViewResetPassword')
const ViewMyProfile = () => import(/* webpackChunkName: "my-data" */ '@/views/ViewMyProfile')

export const resetPassword = {
  path: '/reset-password',
  name: 'reset-password',
  components: {
    default: ViewResetPassword
  },
  meta: {
    hasToolbarExtension: false,
    hasMapButton: false
  }
}

export const register = {
  path: '/register',
  name: 'register',
  components: {
    default: ViewRegister
  },
  meta: {
    hasToolbarExtension: false,
    hasMapButton: false
  }
}

export const confirmEmail = {
  path: '/confirm-email',
  name: 'confirm-email',
  components: {
    default: ViewConfirmEmail
  },
  meta: {
    hasToolbarExtension: false,
    hasMapButton: false
  }
}

export const myProfile = {
  path: '/my-profile',
  name: 'my-profile',
  beforeEnter (to, from, next) {
    const nav = () => next({
      name: 'player-detail',
      params: { id: store.getters['players/current/id'] }
    })

    if (
      typeof store.getters['players/current/id'] === 'number' &&
      isUuid(store.getters['players/current/id'])
    ) {
      nav()
    } else {
      store.dispatch('players/current/update')
        .then(nav)
        .catch(() => next('/'))
    }
  }
}
export const myData = {
  path: '/my-data',
  name: 'my-data',
  components: {
    default: ViewMyProfile
  },
  meta: {
    hasToolbarExtension: false,
    hasMapButton: false,
    starmenuButtons: [
      'EinsStarmenuAbCloseAccount',
      'EinsStarmenuDpbDetails'
    ]
  }
}
