<template>
  <v-dialog v-model="open">
    <widget-change-group-owner-provider
        #default="changeOwnershipContext"
        :group-id="cGroup.id"
    >
      <v-card class="detail-content">
        <v-card-title>
          {{ cTitle }}
        </v-card-title>
        <template v-if="!cUserIsOwner">
          <v-card-text v-if="!success">
            {{ cText.text }}
          </v-card-text>
          <v-alert
              v-if="!success && changeOwnershipContext.success"
              type="success"
              text
              tile
              transition="expand-transition"
          >
            <i18n path="group.changeOwnership.success">
              <em>{{ changeOwnershipContext.groupTitle }}</em>
              <em>{{ changeOwnershipContext.selectedPlayerName }}</em>
            </i18n>
          </v-alert>
          <v-alert
              v-if="success"
              type="success"
              text
              tile
              transition="expand-transition"
          >
            <strong>{{ cText['success.title'] }}</strong><br />
            {{ cText['success.text'] }}
          </v-alert>
        </template>
        <template v-else>
          <template v-if="cGroupOwnershipIsChangeable">
            <v-card-text>
              <v-skeleton-loader
                  :loading="changeOwnershipContext.loading.admins"
                  type="list-item-avatar"
              >
                <i18n path="group.changeOwnership.textLeaveAndChange">
                  <em>{{ changeOwnershipContext.groupTitle }}</em>
                </i18n>
                <eins-select-user
                    avatar-icon="mdi-shield-star-outline"
                    :disabled="changeOwnershipContext.disableSelection"
                    hide-details
                    :users="changeOwnershipContext.availableAdmins"
                    :label="cTextTransferOwnership.selectLabel"
                    :no-data-text="cTextTransferOwnership.noData"
                    solo
                    flat
                    :value="changeOwnershipContext.selection"
                    @input="changeOwnershipContext.onChangeSelection"
                />
              </v-skeleton-loader>
            </v-card-text>
            <v-alert
                v-if="changeOwnershipContext.error"
                type="error"
                text
                tile
                transition="expand-transition"
            >
              {{ changeOwnershipContext.errorText }}
            </v-alert>
          </template>
          <v-alert
              v-else
              type="warning"
              tile
          >
            <strong>{{ cText['cantLeave.title'] }}</strong><br />
            {{ cText['cantLeave.text'] }}
          </v-alert>
        </template>
        <v-card-actions>
          <template v-if="!cUserIsOwner">
            <v-btn
                v-if="!success"
                color="primary"
                block
                :loading="loading"
                @click="onClickSubmit"
            >
              {{ cText['btn.submit' ] }}
            </v-btn>
            <eins-btn-timed
                v-else
                color="primary"
                outlined
                block
                :run="open"
                @click="close"
            >
              {{ cText['btn.close'] }}
            </eins-btn-timed>
          </template>
          <template v-else-if="cGroupOwnershipIsChangeable">
            <v-row
                v-if="!changeOwnershipContext.success"
                dense
                class="flex-column"
            >
              <v-col>
                <v-btn
                    block
                    color="primary"
                    :disabled="changeOwnershipContext.disableSubmit"
                    :loading="changeOwnershipContext.loading.submit"
                    outlined
                    text
                    @click="changeOwnershipContext.onClickSubmit().then(() => $store.dispatch('groups/selected/update'))"
                >
                  {{ cTextTransferOwnership.submitButton }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                    block
                    :disabled="changeOwnershipContext.loading.submit"
                    outlined
                    text
                    @click="onClickClose"
                >
                  {{ cTextTransferOwnership.cancelButton }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-btn
                block
                color="primary"
                @click="onClickClose"
            >
              {{ cText['btn.ok'] }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </widget-change-group-owner-provider>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'
import WidgetChangeGroupOwnerProvider from '@/components/group/WidgetChangeGroupOwnerProvider'
import EinsSelectUser from '@/components/input/EinsSelectUser'

export default {
  name: 'EinsStarmenuDlgLeaveGroup',
  components: { EinsSelectUser, WidgetChangeGroupOwnerProvider, EinsBtnTimed },
  mixins: [ MxTranslatable ],
  data: () => ({
    success: false,
    error: false,
    loading: false,
    $_mx_translatable: {
      paths: [
        'text',
        'btn.close',
        'btn.ok',
        'btn.submit',
        'cantLeave.title',
        'cantLeave.text',
        'success.title',
        'success.text'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogLeaveGroup']
      },
      set (value) {
        this.$store.commit('dialogLeaveGroup', value)
      }
    },
    cTextTransferOwnership () {
      return {
        selectLabel: this.$t('group.changeOwnership.select.label'),
        noData: this.$t('group.changeOwnership.select.noData'),
        cancelButton: this.$t('group.changeOwnership.buttons.cancel'),
        closeButton: this.$t('group.changeOwnership.buttons.close'),
        submitButton: this.$t('group.changeOwnership.buttons.submit')
      }
    },
    cGroup () {
      return this.cPayload ?? this.cEntity
    },
    cGroupOwnershipIsChangeable () {
      return this.cGroup?.group_type.can_change_owner ?? false
    },
    cPayload () {
      return this.$store.getters['dialogPayload']('dialogLeaveGroup')
    },
    cTitle () {
      return this.$t(`components.${this.$options.name}.title`, [ this.cEntity?.title ])
    },
    cCurrentUserId () {
      return this.$store.getters['players/current/id']
    },
    cEntity () {
      return this.$store.getters['groups/selected/group']
    },
    cUserIsOwner () {
      return this.cEntity?.owner_id === this.cCurrentUserId
    },
    cUserIsMember () {
      const groups = this.$store.getters['players/current/groups'] ?? []

      return groups.includes(this.cEntity?.id)
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen (value, oldValue) {
      if (value !== oldValue) {
        if (value) {
          // dlg just opened
          this.success = false
          this.error = false
          this.loading = false
        }
      }
    },
    onClickSubmit () {
      this.submit()
    },
    onClickClose () {
      this.close()
    },
    close () {
      this.open = false
    },
    submit () {
      const id = this.cPayload?.id ?? this.cEntity.id

      this.loading = true
      this.$http.$api.delete(`groups/${id}/memberships/${this.cCurrentUserId}`)
        .then(() => {
          this.success = true
          this.$store.dispatch('players/current/update')
          this.$store.dispatch('groups/selected/update')
        })
        .catch((e) => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
