<template>
  <eins-edit-picture-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      :current-value="cTitlePictureFeed"
      :dialog-title="cText.dialogTitle"
      :submit-label="cText.submitLabel"
      :success-message="cSuccessMessage"
  />
</template>

<script>
import EinsEditPictureBlock from '@/components/form/edit/EinsEditPictureBlock'

export default {
  name: 'EinsDealDataPictureBlock',
  components: {
    EinsEditPictureBlock
  },
  data: () => ({
    success: false,
    loading: false,
    dialog: false,
    form: {
      valid: false,
      file: null,
      preview: null,
      aspectRatio: 1
    },
    cropper: {
      show: false,
      ready: false,
      result: null
    }
  }),
  computed: {
    cText () {
      return {
        submitLabel: this.$t('deal.update.picture.actions.submit'),
        dialogTitle: this.$t('deal.update.picture.dialogTitle')
      }
    },
    cSuccessMessage () {
      return {
        title: this.$t('deal.update.picture.success.title'),
        text: this.$t('deal.update.picture.success.text')
      }
    },
    cTitlePictureFeed () {
      return this.$store.getters['deals/selected/deal']?.title_picture_full
    },
    cDealId () {
      return this.$store.getters['deals/selected/deal']?.id
    },
    cApiPath () {
      return `deals/${this.cDealId}/picture`
    }
  },
  methods: {
    onClickSetPicture () {
      this.showDialog()
    },
    showDialog () {
      this.dialog = true
    },
    afterUpdate () {
      return this.$store.dispatch('deals/selected/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
