import { http } from '@/plugins'
import Vue from 'vue'
import { isUuid } from '@/utils/api'
import { objectHasOwnProperty } from '@/utils/object'

export const addToRingBuffer = (state, result) => {
  const {
    ringBuffer,
    ringBufferMaxLength
  } = state

  // if element already exists, remove it rto avoid duplicates
  const idx = ringBuffer.findIndex(el => el.id === result.id)
  if (idx > -1) {
    ringBuffer.splice(idx, 1)
  }

  // remove elements from the end to keep ring buffer in size
  while (ringBuffer.length >= ringBufferMaxLength) {
    const el = ringBuffer.pop()
    state.error.delete(el.id)
  }

  ringBuffer.unshift(result)
}
export const removeFromRingBuffer = (state, id) => {
  const {
    ringBuffer
  } = state

  // if element already exists, remove it rto avoid duplicates
  const idx = ringBuffer.findIndex(el => el.id === id)
  if (idx > -1) {
    ringBuffer.splice(idx, 1)
  }
}

export const addToCache = (state, items) => {
  if (!Array.isArray(items)) {
    items = [ items ]
  }

  for (const item of items) {
    if (Object.prototype.hasOwnProperty.call(item, 'created_at')) {
      item.created_at = new Date(item.created_at)
    }
    if (Object.prototype.hasOwnProperty.call(item, 'updated_at')) {
      item.updated_at = new Date(item.updated_at)
    }

    const idx = state.cache.findIndex(el => el.id === item.id)
    if (idx > -1) {
      const oldData = state.cache[idx]
      if (
        oldData.updated_at - item.updated_at !== 0 ||
        (
          (
            Object.prototype.hasOwnProperty.call(item, 'distance') ||
            Object.prototype.hasOwnProperty.call(oldData, 'distance')
          ) &&
          oldData.distance !== item.distance
        )
      ) {
        state.cache.splice(idx, 1, item)
      }
    } else {
      state.cache.push(item)
    }
  }

  state.cache.sort((a, b) => {
    if (!a.has_distance && !b.has_distance) {
      // both without distance, sort by created at
      return a.updated_at - b.updated_at
    } else if (!a.has_distance) {
      // a has no distance, sot it to end
      return 1
    } else if (!b.has_distance) {
      // b has no distance, sot it to end
      return -1
    } else {
      const result = a.distance - b.distance
      return result !== 0 ? result : a.updated_at - b.updated_at
    }
  })
}

export const clearCache = (state) => {
  Vue.set(state, 'cache', [])
}

export const removeFromCache = (state, idList) => {
  if (!Array.isArray(idList)) {
    idList = [ idList ]
  }

  idList = idList.filter((el, idx, arr) => arr.indexOf(el) === idx)

  for (const id of idList) {
    const idx = state.cache.findIndex(el => el.id === id)
    if (idx > -1) {
      state.cache.splice(idx, 1)
    }
  }
}

export const createListModule = ({
  modules = {},
  basePath = '/',
  baseEntity = 'items',
  getParams = () => ({})
} = {}) => ({
  namespaced: true,
  state: {
    search: {
      text: null,
      timeout: null
    },
    filter: {},
    limit: 10,
    page: 1,
    cache: [],
    result: null,
    loading: false,
    reachedEnd: false
  },
  getters: {
    search: state => state.search.text,
    filter: state => state.filter,
    list: state => state.cache,
    listLoading: state => state.loading,
    listEnd: state => state.reachedEnd,
    item: state => id => state.cache.find(el => el.id === id)
  },
  mutations: {
    invalidate (state) {
      Vue.set(state, 'result', null)
    },
    search (state, payload) {
      state.search.text = payload || null
      state.page = 1
    },
    filter (state, payload) {
      state.filter = {}
      if (payload) {
        for (const key in payload) {
          const value = payload[key]
          if (value === null) {
            if (objectHasOwnProperty(state.filter, key)) {
              delete state.filter[key]
            }
          } else {
            state.filter[key] = value
          }
        }
      }
      state.page = 1
    },
    searchTimeout (state, payload) {
      state.search.timeout = payload
    },
    clearSearch (state) {
      if (state.search.timeout) {
        clearTimeout(state.search.timeout)
      }
      state.search.timeout = null
      state.search.text = null
      state.page = 1
    },
    clearSearchTimeout (state) {
      if (state.search.timeout) {
        clearTimeout(state.search.timeout)
      }
      state.search.timeout = null
    },
    addToCache,
    clearCache,
    removeFromCache
  },
  actions: {
    searchDelayed ({ state, commit, dispatch }, payload) {
      commit('clearSearchTimeout')
      commit('searchTimeout', setTimeout(() => {
        commit('clearSearchTimeout')
        dispatch('search', payload)
      }, 1500))
    },
    search ({ commit, dispatch }, payload) {
      commit('clearSearchTimeout')
      commit('search', payload)
      commit('clearCache')

      return dispatch('getData')
    },
    filter ({ commit, dispatch }, payload) {
      commit('clearSearchTimeout')
      commit('filter', payload)
      commit('clearCache')

      return dispatch('getData')
    },
    getData ({ state, commit }) {
      state.loading = true
      const params = getParams()
      for (const key in state.filter) {
        params[key] = state.filter[key]
      }
      if (state.search.text !== null) {
        params.search = state.search.text
      }

      return http.$api.get(`${basePath}`, { params: { limit: state.limit, page: state.page, ...params } })
        .then(response => {
          commit('addToCache', response.data?.[baseEntity] ?? [])
          state.limit = response.data?.limit || 10
          state.page = response.data?.page || 1
          state.reachedEnd = response.data.count + (response.data.page - 1) * response.data.limit >= response.data.count_filtered
          return response
        })
        .catch(() => {
          commit('clearCache')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ state, dispatch, commit }) {
      const oldPage = state.page > 0 ? state.page : 1
      state.page = 0 // not 1 since `loadMore` increments...
      const knownItemsIds = state.cache.map(item => item.id)
      const receivedItemsIds = []
      let results = []
      while (state.page < oldPage) {
        results.push(
          dispatch('loadMore')
            .then(response => {
              Array.prototype.push.apply(receivedItemsIds, (response.data?.[baseEntity] ?? []).map(item => item.id))
            })
        )
      }

      if (results.length === 0) {
        results.push(Promise.resolve())
      }

      return Promise.all(results)
        .then(() => {
          knownItemsIds.forEach(id => {
            if (!receivedItemsIds.includes(id)) {
              commit('removeFromCache', id)
            }
          })
        })
    },
    loadMore ({ state, dispatch }) {
      state.page = state.page + 1
      return dispatch('getData')
    }
  },
  modules: {
    ...modules
  }
})

export const createSelectedEntityModule = ({
  modules = {},
  basePath = '/',
  getterName = 'item',
  ringBufferMaxLength = 10
} = {}) => ({
  namespaced: true,
  state: {
    selectedId: null,
    loading: false,
    error: new Map(),
    ringBuffer: [],
    ringBufferMaxLength
  },
  getters: {
    [getterName]: state => state.ringBuffer.find(el => el.id === state.selectedId) || null,
    loading: state => state.loading,
    error: state => state.error.get(state.selectedId)
  },
  mutations: {
    result: addToRingBuffer,
    removeFromRingBuffer,
    error (state, error) {
      if (!isNaN(error.status) && error.status) {
        state.error.set(state.selectedId, error)
      }
    }
  },
  actions: {
    load ({ state, commit }, id) {
      state.loading = true
      state.selectedId = id
      return http.$api.get(`${basePath}/${id}`)
        .then(response => {
          if (response.status > 199 && response.status < 300) {
            commit('result', response.data)

            return response
          } else {
            throw response
          }
        })
        .catch((e) => {
          commit('error', e)
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ state, dispatch }) {
      if (isUuid(state.selectedId)) {
        return dispatch('load', state.selectedId)
      }
    }
  },
  modules: {
    ...modules
  }
})

export const createRelatedEntityListModule = ({
  getParams = () => ({}),
  getPath = () => '/',
  baseEntity = 'items'
} = {}) => ({
  namespaced: true,
  state: {
    limit: 10,
    page: 1,
    cache: [],
    result: null,
    loading: false,
    reachedEnd: false
  },
  getters: {
    list: state => state.cache,
    listLoading: state => state.loading,
    listEnd: state => state.reachedEnd
  },
  mutations: {
    addToCache,
    clearCache,
    removeFromCache
  },
  actions: {
    getData ({ state, commit, rootGetters }) {
      state.loading = true
      return http.$api.get(getPath(rootGetters), { params: {
        limit: state.limit,
        page: state.page,
        ...getParams(rootGetters)
      } })
        .then(response => {
          commit('addToCache', response.data?.[baseEntity] ?? [])
          state.limit = response.data?.limit || 10
          state.page = response.data?.page || 1
          state.reachedEnd = response.data.count +
            (response.data.page - 1) * response.data.limit >=
            response.data.count_filtered
          return response.data
        })
        .catch(() => {
          commit('clearCache')
        })
        .finally(() => {
          state.loading = false
        })
    },
    update ({ dispatch }) {
      return dispatch('getData')
    },
    updateAll ({ dispatch }) {
      return dispatch('loadAll')
    },
    loadMore ({ state, dispatch }) {
      state.page = state.page + 1
      return dispatch('getData')
    },
    loadAll ({ state, getters, dispatch, commit }) {
      const oldPage = state.page
      state.page = 0

      const toBeRemoved = state.cache.map(el => el.id) // all known members
      const loadAllPages = () => dispatch('loadMore')
        .then((response) => {
          // remove updated members from list
          (response[baseEntity] ?? []).forEach(el => {
            const idx = toBeRemoved.indexOf(el.id)
            if (idx > -1) {
              toBeRemoved.splice(idx, 1)
            }
          })

          if (state.error) {
            return Promise.reject(state.error)
          } else if (getters.listEnd) {
            return Promise.resolve()
          } else {
            return loadAllPages()
          }
        })
        .catch(err => {
          return Promise.reject(err)
        })

      return new Promise((resolve, reject) => {
        loadAllPages()
          .then(() => resolve())
          .then(() => {
            commit('removeFromCache', toBeRemoved)
            return true
          })
          .catch(err => reject(err))
          .finally(() => {
            state.page = oldPage
          })
      })
    }
  }
})
