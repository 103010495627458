<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }} {{ cQuestTitle }}</v-card-title>
      <v-card-text v-if="!success">
        {{ cText.text }}
      </v-card-text>
      <v-alert
          type="error"
          tile
          text
          :value="!!cError"
      >
        {{ cError }}
      </v-alert>
      <v-alert
          type="success"
          tile
          text
          :value="success"
      >
        {{ cText.success }}
      </v-alert>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            outlined
            block
            @click="onClickClose"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <template v-else>
          <v-row
              dense
              class="flex-column"
          >
            <v-col>
              <v-btn
                  color="primary"
                  outlined
                  block
                  @click="onClickCancel"
              >
                {{ cText['btn.cancel'] }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  outlined
                  block
                  :loading="loading"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'
export default {
  name: 'EinsStarmenuDlgCancelQuest',
  components: {
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    success: false,
    error: false,
    $_mx_translatable: {
      paths: [
        'title',
        'text',
        'success',
        'error.404',
        'error.403',
        'error.default',
        'btn.submit',
        'btn.cancel',
        'btn.close'
      ]
    }
  }),
  computed: {
    cError () {
      switch (this.error) {
        case 404: return this.cText['error.404']
        case 403: return this.cText['error.403']
        case false: return false
        default: return this.cText['error.default']
      }
    },
    open: {
      get () {
        return this.$store.getters['dialogCancelQuest']
      },
      set (value) {
        this.$store.commit('dialogCancelQuest', value)
      }
    },
    cQuestTitle () {
      return this.cQuest ? `'${this.cQuest.title}'` : ''
    },
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen () {
      this.success = false
      this.error = false
      this.loading = false
    },
    onClickClose () {
      this.close()
    },
    onClickCancel () {
      this.close()
    },
    onClickSubmit () {
      this.deleteQuest()
    },
    close () {
      this.open = false
    },
    deleteQuest () {
      this.loading = true
      return this.$http.$api.delete(`quests/${this.cQuest.id}`)
        .then(() => {
          this.success = true
        })
        .catch((err) => {
          this.error = err?.status ?? true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
