import { store } from '@/plugins'
import {
  RegRoutePlayer,
  RegUuidString
} from '@/utils/regex'

const ViewPlayerList = () => import(/* webpackChunkName: "player-list" */ '@/views/player/ViewPlayerList')
const ViewPlayerListToolbarExtension = () => import(/* webpackChunkName: "player-list" */ '@/views/player/ViewPlayerListToolbarExtension')
const ViewPlayerDetail = () => import(/* webpackChunkName: "player-list" */ '@/views/player/ViewPlayerDetail')
const ViewPlayerGroups = () => import(/* webpackChunkName: "player-groups" */ '@/views/player/ViewPlayerGroups')
const ViewPlayerDeals = () => import(/* webpackChunkName: "player-deals" */ '@/views/player/ViewPlayerDeals')
const ViewPlayerDealsClosed = () => import(/* webpackChunkName: "player-deals" */ '@/views/player/ViewPlayerDealsClosed')
const ViewPlayerQuests = () => import(/* webpackChunkName: "player-quests" */ '@/views/player/ViewPlayerQuests')
const ViewPlayerQuestClosed = () => import(/* webpackChunkName: "player-quests" */ '@/views/player/ViewPlayerQuestClosed')
const ViewPlayerContacts = () => import(/* webpackChunkName: "player-contacts" */ '@/views/player/ViewPlayerContacts')
const ViewPlayerBlockages = () => import(/* webpackChunkName: "player-blockages" */ '@/views/player/ViewPlayerBlockages')

export const players = {
  path: '/players',
  name: 'player-list',
  components: {
    default: ViewPlayerList,
    toolbarExtension: ViewPlayerListToolbarExtension
  },
  meta: {
    hasToolbarExtension: true,
    hasMapButton: false,
    starmenuButtons: [
      'EinsStarmenuDpbPlayerContacts'
    ]
  },
  children: [{
    path: `:id(${RegUuidString})`,
    name: 'player-detail',
    component: ViewPlayerDetail,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuAbBlockPlayer',
        'EinsStarmenuDpbDetails',
        'EinsStarmenuAbDenounce',
        'EinsStarmenuAbFollow',
        'EinsStarmenuDpbPlayerData',
        'EinsStarmenuDpbDealsFinished',
        'EinsStarmenuDpbDirectMessage',
        'EinsStarmenuDpbPlayerContacts',
        'EinsStarmenuDpbPlayerBlockages',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuEbEdit',
        'EinsStarmenuAbQrCode'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/groups`,
    name: 'player-groups',
    component: ViewPlayerGroups,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/contacts`,
    name: 'player-contacts',
    component: ViewPlayerContacts,
    beforeEnter: (to, from, next) => {
      store.dispatch('players/current/update')
        .then(() => {
          // only the contact list of the current player can be accessed
          if (to.params.id === store.getters['players/current/id']) {
            next()
          } else if (RegRoutePlayer.test(to.name)) {
            // if this is a player route go to the associated profile
            next({
              name: 'player-detail',
              params: {
                id: to.params.id
              }
            })
          } else {
            // otherwise go to root
            next('/')
          }
        })
        .catch(() => next(false))
    },
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/blockages`,
    name: 'player-blockages',
    component: ViewPlayerBlockages,
    beforeEnter: (to, from, next) => {
      store.dispatch('players/current/update')
        .then(() => {
          // only the contact list of the current player can be accessed
          if (to.params.id === store.getters['players/current/id']) {
            next()
          } else if (RegRoutePlayer.test(to.name)) {
            // if this is a player route go to the associated profile
            next({
              name: 'player-detail',
              params: {
                id: to.params.id
              }
            })
          } else {
            // otherwise go to root
            next('/')
          }
        })
        .catch(() => next(false))
    },
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/deals`,
    name: 'player-deals',
    component: ViewPlayerDeals,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/deals/closed`,
    name: 'player-deals-closed',
    component: ViewPlayerDealsClosed,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/quests`,
    name: 'player-quests',
    component: ViewPlayerQuests,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData'
      ]
    }
  },
  /* {
    path: `:id(${RegUuidString})/quests/finished`,
    name: 'player-quests-finished',
    component: ViewPlayerQuests,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerContacts',
        'EinsStarmenuDpbDealsFinished',
        'EinsStarmenuDpbPlayerData'
      ]
    }
  }, */
  {
    path: `:id(${RegUuidString})/quests/closed`,
    name: 'player-quests-closed',
    component: ViewPlayerQuestClosed,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbPlayerData',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }]
}
