<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      :text="cText"
      icon=""
      @click="onClick"
  >
    <template #icon>
      <eins-icon-friendship
          width="18"
          class="mr-2"
          :state="cIconState"
      />
    </template>
  </eins-starmenu-ab>
</template>

<script>
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import { FriendshipState } from '@/constants'
import { MxStarmenuBtn } from '@/mixins'

export default {
  name: 'EinsStarmenuAbFollow',
  components: {
    EinsIconFriendship,
    EinsStarmenuAb
  },
  mixins: [
    MxStarmenuBtn
  ],
  computed: {
    cEntity () {
      return this.$store.getters['players/selected/player']
    },
    cFriendshipState () {
      return this.cEntity?.friendship_state
    },
    cRouteId () {
      return this.$route.params.id ?? null
    },
    cIsFollower () {
      return (this.$store.getters['players/current/idols'] ?? [])
        .concat(this.$store.getters['players/current/friends'] ?? [])
        .includes(this.cRouteId)
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
        this.$route.params.id !== this.$store.getters['players/current/id']
    },
    cText () {
      return this.cFriendshipState ? this.$t(`components.${this.$options.name}.text.${this.cFriendshipState}`) : ''
    },
    cIconState () {
      switch (this.cFriendshipState) {
        case FriendshipState.NONE: return FriendshipState.IDOL
        case FriendshipState.IDOL: return FriendshipState.NONE
        case FriendshipState.FRIEND: return FriendshipState.FAN
        case FriendshipState.FAN: return FriendshipState.FRIEND
        default: return FriendshipState.ME
      }
    }
  },
  methods: {
    onClick () {
      const method = this.cIsFollower ? 'delete' : 'put'

      return this.$http.$api[method](`contacts/${this.cEntity.id}`)
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('players/selected/update')
          ])
        })
    }
  }
}
</script>

<style scoped>

</style>
