<template>
  <eins-layout-view-detail>
    <v-slide-y-transition>
      <v-progress-linear
          v-show="cLoading"
          height="5"
          indeterminate
      />
    </v-slide-y-transition>
    <v-card
        class="ma-0 pa-0"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <v-row
          class=" flex-column flex-nowrap fill-height"
          style="overflow: hidden;"
          no-gutters
      >
        <v-col style="overflow-y: auto;">
          <v-card-text class="pa-4 pb-0 pt-4 ma-0">
            <v-form v-model="formValid">
              <app-register v-model="formData"></app-register>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import AppRegister from '../components/app/AppRegister'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
export default {
  name: 'ViewRegister',
  components: { EinsLayoutViewDetail, AppRegister },
  mixins: [MxTranslatable],
  data: () => ({
    formData: null,
    formValid: false,
    saving: false,
    alert: {
      cancel: false
    },
    $_mx_translatable: {
      paths: [
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cLoading () {
      return false
    },
    cDisabled () {
      return !this.formValid
    }
  },
  methods: {
    onScroll (e) {
    },
    onClickSubmit () {
      this.submit()
    },
    submit () {
      this.saving = true
      this.$http.$api.post('/players/', this.formData)
        .then((result) => {
          this.$router.push('/players/' + result.data.id)
          return true
        })
        .catch(() => {
          // console.log(er)
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
