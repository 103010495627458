<template>
  <v-dialog v-model="open">
    <v-card class="detail-content">
      <v-card-title>{{ cText.title }} {{ cQuestTitle }}</v-card-title>
      <v-card-text v-if="!success">
        {{ cTextCustom.text }}
        <eins-image-input
            v-if="open"
            class="mt-2"
            @select="onSelectImage"
        />
      </v-card-text>
      <v-expand-transition>
        <v-alert
            type="error"
            tile
            text
            :value="!!error.picture"
        >
          {{ cText['error.picture'] }}
        </v-alert>
      </v-expand-transition>
      <v-expand-transition>
        <v-alert
            type="error"
            tile
            text
            :value="!!cError"
        >
          {{ cError }}
        </v-alert>
      </v-expand-transition>
      <v-expand-transition>
        <v-alert
            type="success"
            tile
            text
            :value="success"
        >
          {{ cTextCustom.success }}
        </v-alert>
      </v-expand-transition>
      <v-expand-transition>
        <v-alert
            type="warning"
            tile
            text
            :value="cWarnAboutMissingPicture"
        >
          {{ cText['noPicture.text'] }}
          <v-btn
              text
              small
              @click="onClickDismissPictureWarning"
          >{{ cText['noPicture.btn'] }}</v-btn>
        </v-alert>
      </v-expand-transition>
      <v-card-actions>
        <eins-btn-timed
            v-if="success"
            outlined
            block
            @click="onClickClose"
        >
          {{ cText['btn.close'] }}
        </eins-btn-timed>
        <template v-else>
          <v-row
              dense
              class="flex-column"
          >
            <v-col>
              <v-btn
                  color="primary"
                  outlined
                  block
                  :loading="loading"
                  :disabled="cWarnAboutMissingPicture"
                  @click="onClickSubmit"
              >
                {{ cText['btn.submit'] }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="grey"
                  outlined
                  block
                  @click="onClickCancel"
              >
                {{ cText['btn.cancel'] }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { MxTranslatable } from '@/mixins'
import EinsImageInput from '@/components/input/EinsImageInput'

export default {
  name: 'EinsStarmenuDlgFinishQuest',
  components: {
    EinsImageInput,
    EinsBtnTimed
  },
  mixins: [ MxTranslatable ],
  data: () => ({
    loading: false,
    success: false,
    error: { finish: false, picture: false },
    noPictureWarning: {
      dismissed: false,
      active: true
    },
    file: null,
    $_mx_translatable: {
      paths: [
        'title',
        'error.404',
        'error.403',
        'error.default',
        'error.picture',
        'noPicture.text',
        'noPicture.btn',
        'btn.submit',
        'btn.cancel',
        'btn.close'
      ]
    }
  }),
  computed: {
    cError () {
      switch (this.error.picture) {
        case 404: return this.cText['error.404']
        case 403: return this.cText['error.403']
        case false: return false
        default: return this.cText['error.default']
      }
    },
    cErrorFinish () {
      switch (this.error.finish) {
        case 404: return this.cText['error.404']
        case 403: return this.cText['error.403']
        case false: return false
        default: return this.cText['error.default']
      }
    },
    open: {
      get () {
        return this.$store.getters['dialogFinishQuest']
      },
      set (value) {
        this.$store.commit('dialogFinishQuest', value)
      }
    },
    cTextCustom () {
      const t = (path, params) => this.$t(`components.${this.$options.name}.${path}`, params)

      return {
        text: t('text', [this.cQuestOwnerName]),
        success: t('success', [this.cQuestOwnerName])
      }
    },
    cQuestTitle () {
      return this.cQuest ? `'${this.cQuest.title}'` : ''
    },
    cQuestOwnerName () {
      return this.cQuest?.player?.name
    },
    cQuest () {
      return this.$store.getters.dialogPayload('dialogFinishQuest').quest
    },
    cWarnAboutMissingPicture () {
      return !this.noPictureWarning.dismissed &&
          this.noPictureWarning.active &&
          !(
            this.file instanceof File ||
            this.file instanceof Blob
          )
    }
  },
  watch: {
    open: 'onOpen'
  },
  methods: {
    onOpen () {
      this.success = false
      this.error.finish = false
      this.error.picture = false
      this.noPictureWarning.active = false
      this.noPictureWarning.dismissed = false
      this.loading = false
      this.file = null
    },
    onClickDismissPictureWarning () {
      this.noPictureWarning.dismissed = true
    },
    onClickClose () {
      this.close()
    },
    onClickCancel () {
      this.close()
    },
    onClickSubmit () {
      this.submit()
    },
    onSelectImage (file) {
      this.file = file
    },
    close () {
      this.open = false
    },
    submit () {
      if (!(this.file instanceof File || this.file instanceof Blob) && !this.noPictureWarning.active) {
        this.noPictureWarning.active = true
      } else {
        this.loading = true
        this.noPictureWarning.active = false
        this.uploadImage()
          // eslint-disable-next-line prefer-promise-reject-errors
          .then(() => !this.error.picture ? this.finishQuest() : Promise.reject())
          .then(() => {
            if (!this.error.finish) {
              this.success = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    finishQuest () {
      return this.$http.$api.patch(`quests/${this.cQuest.id}/processing`, {
        finished: true
      })
        .catch((err) => {
          this.error.finish = err?.status ?? true
          return false
        })
    },
    uploadImage () {
      if (this.file instanceof Blob) {
        return this.$http.$api.put(`quests/${this.cQuest.id}/processing/picture`, this.file, { headers: {
          'Content-Type': this.file.type
        } })
          .catch((err) => {
            this.error.picture = err?.status ?? true
            return false
          })
      } else {
        return Promise.resolve()
      }
    }
  }
}
</script>

<style scoped>

</style>
