import {
  isSafari
} from '@/utils/browser'
import {
  objectHasOwnProperty
} from '@/utils/object'

const R = 6371 // Radius of the earth in km

export const greatHaversineCircleDistance = (lat1, lon1, lat2, lon2) => {
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d * 1000
}

const deg2rad = deg => deg * (Math.PI / 180)

export const navLinkUrl = (lat, lon) => isSafari()
  ? googleMapsLinkUrl(lat, lon)
  : `geo:0,0?q=${this.cPosition.lat},${this.cPosition.lon}`

export const googleMapsLinkUrl = (lat, lon) =>
  `https://www.google.de/maps/place/${lat},${lon}`

const isValidLatitude = latitude => latitude >= -90 &&
  latitude <= 90 &&
  latitude &&
  !isNaN(latitude)

const isValidLongitude = longitude => longitude >= -180 &&
  longitude <= 180 &&
  longitude &&
  !isNaN(longitude)

export const isValidGeolocation = location => location &&
  typeof location === 'object' &&
  (
    (
      objectHasOwnProperty(location, 'lat') &&
      isValidLatitude(location.lat) &&
      objectHasOwnProperty(location, 'lon') &&
      isValidLongitude(location.lon)
    ) || (
      objectHasOwnProperty(location, 'latitude') &&
      isValidLatitude(location.latitude) &&
      objectHasOwnProperty(location, 'longitude') &&
      isValidLongitude(location.longitude)
    )
  )
