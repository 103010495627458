<template>
  <v-dialog
      v-model="open"
      persistent
      fullscreen
      opacity="0.75"
  >
    <v-card class="text-center detail-content">
      <v-card-title>
        {{ cText.title }}
        <v-spacer />
        <v-btn
            icon
            dark
            @click="onClickClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-h6">
          {{ cEntity.title }}
        </div>
        {{ cText.text1 }}
      </v-card-text>
      <v-card-text>
        <div style="background: white;padding:20px">
          <qrcode-vue
              background="#fff"
              foreground="#000"
              :value="cEntityQrCode"
              size="270"
          />
        </div>
      </v-card-text>
      <v-card-text>
        {{ cText.text2 }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            v-bind="$attrs"
            class="v-btn-starmenu"
            @click="onClickClose"
        >
          <slot>
            {{ cText.close }}
          </slot>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Entity } from '@/constants'
import QrcodeVue from 'qrcode.vue'

import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'

export default {
  name: 'EinsStarmenuDlgQrCode',
  components: { QrcodeVue },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'title',
        'text1',
        'text2',
        'close'
      ]
    }
  }),
  computed: {
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        case 'quest-detail':
          return Entity.QUEST
        case 'group-detail':
          return Entity.GROUP
        case 'player-detail':
          return Entity.PLAYER
        default:
          return null
      }
    },
    cEntity () {
      let result
      const cloneJson = object => JSON.parse(JSON.stringify(object))
      const baseUrl = `${location.protocol}//${location.host}/#`
      switch (this.cType) {
        case Entity.DEAL:
          result = cloneJson(this.$store.getters['deals/selected/deal'] || {})
          result.link = `${baseUrl}/deals/${result.id}`
          break
        case Entity.QUEST:
          result = cloneJson(this.$store.getters['quests/selected/quest'] || {})
          result.link = `${baseUrl}/quests/${result.id}`
          break
        case Entity.GROUP:
          result = cloneJson(this.$store.getters['groups/selected/group'] || {})
          result.link = `${baseUrl}/groups/${result.id}`
          break
        case Entity.PLAYER:
          result = cloneJson(this.$store.getters['players/selected/player'] || {})
          result.title = result ? result.name : ''
          result.link = `${baseUrl}/players/${result.id}`
          break
        default:
          return { }
      }

      return result
    },
    cEntityQrCode () {
      return this.cEntity?.link
    },
    cQrCode () {
      return '../assets/img/qr-muster.png'
    },
    open: {
      get () {
        return this.$store.getters['dialogQrCode']
      },
      set (value) {
        this.$store.commit('dialogQrCode', value)
      }
    }
  },
  methods: {
    onClickClose () {
      this.close()
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped>
.v-btn-starmenu {
  min-width: 300px;
  border: 1px solid #b23219;
}
</style>
