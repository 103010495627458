<template>
  <eins-layout-view-detail>
    <eins-deal-detail-content
        v-if="!cError && cDeal"
        :deal="cDeal"
    />
    <eins-error-not-found
        v-else-if="cError === 404"
    >
      <i18n path="views.ViewDealDetail.theDeal" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>
      </template>
    </eins-error-not-found>
    <eins-error-forbidden
        v-else-if="cError === 403"
    >
      <i18n path="views.ViewDealDetail.theDealLc" />
      <template #id>
        <strong>#{{ cRouteId }}</strong>!
      </template>
    </eins-error-forbidden>
  </eins-layout-view-detail>
</template>

<script>
import EinsErrorForbidden from '@/components/error/EinsErrorForbidden'
import EinsErrorNotFound from '@/components/error/EinsErrorNotFound'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsDealDetailContent from '@/components/deal/EinsDealDetailContent'

export default {
  name: 'ViewDealDetail',
  components: {
    EinsDealDetailContent,
    EinsErrorForbidden,
    EinsErrorNotFound,
    EinsLayoutViewDetail
  },
  computed: {
    cRouteId () {
      return this.$route.params.id ?? 0
    },
    cLoading () {
      return this.$store.getters['deals/selected/loading']
    },
    cError () {
      return this.$store.getters['deals/selected/error']
    },
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadDeal(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadDeal(to.params.id)
    }
    next()
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    onRequestViewUpdate () {
      this.loadDeal(this.$route.params.id)
    },
    loadDeal (id) {
      return this.$store.dispatch('deals/selected/load', id)
    }
  }
}
</script>

<style scoped>

</style>
