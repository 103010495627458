<template>
  <eins-layout-list
      #default="{ item }"
      :items="players"
  >
    <slot
        name="item"
        :item="item"
    >
      <eins-player-list-item
          :player="item"
      />
    </slot>
  </eins-layout-list>
</template>

<script>
import EinsLayoutList from '@/components/layout/EinsLayoutList'
import EinsPlayerListItem from '@/components/player/EinsPlayerListItem'

export default {
  name: 'EinsPlayerList',
  components: {
    EinsPlayerListItem,
    EinsLayoutList
  },
  props: {
    players: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
  })
}
</script>

<style scoped>

</style>
