<template>
  <v-overlay
      v-model="open"
      opacity="0.75"
      @click.native="onClickOverlay"
  >
    <v-row no-gutters>
      <v-spacer />
      <v-col>
        <v-row
            class="flex-column text-center"
            dense
        >
          <v-col>
            <eins-starmenu-ab
                :text="$t('components.EinsStarmenuAbAddContent.addProduct')"
                icon="mdi-basket"
                color="orange"
                block
                :to="'/deals/create?type=product&player_group_id=' + cGroup"
            />
          </v-col>
          <v-col>
            <eins-starmenu-ab
                :text="$t('components.EinsStarmenuAbAddContent.addService')"
                icon="mdi-face-agent"
                block
                color="orange"
                :to="'/deals/create?type=service&player_group_id=' + cGroup"
            />
          </v-col>
          <v-col>
            <eins-starmenu-ab
                :text="$t('components.EinsStarmenuAbAddContent.addCommon')"
                icon="mdi-map-marker-star"
                color="blue"
                block
                :to="'/quests/create?type=common&player_group_id=' + cGroup"
            />
          </v-col>
          <v-col>
            <eins-starmenu-ab
                :text="$t('components.EinsStarmenuAbAddContent.addGreen')"
                icon="mdi-map-marker-up"
                color="green"
                block
                :to="'/quests/create?type=green&player_group_id=' + cGroup"
            />
          </v-col>
          <v-col>
            <eins-starmenu-ab
                :text="$t('components.EinsStarmenuAbAddContent.addPrivate')"
                icon="mdi-map-marker-left"
                color="red"
                block
                :to="'/quests/create?type=private&player_group_id=' + cGroup"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-overlay>
</template>

<script>
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'

import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuDlgAddContent',
  components: { EinsStarmenuAb },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'title'
      ]
    }
  }),
  computed: {
    open: {
      get () {
        return this.$store.getters['dialogAddContent']
      },
      set (value) {
        this.$store.commit('dialogAddContent', value)
      }
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']?.id
    }
  },
  methods: {
    onClickOverlay () {
      this.close()
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped>

</style>
