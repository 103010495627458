<template>
  <v-img
      :src="cSrc"
      v-bind="$attrs"
      v-on="$listeners"
  ></v-img>
</template>

<script>
import {
  MxMenuIcon
} from '@/mixins'

export default {
  name: 'EinsIconChat',
  mixins: [ MxMenuIcon ],
  computed: {
    cSrc () {
      if (this.active) {
        return require('@/assets/img/icons/ui/chat-bubble_active.png')
      } else if (this.active && this.notification) {
        return require('@/assets/img/icons/ui/chat-bubble_active.png')
      } else {
        return require('@/assets/img/icons/ui/chat-bubble.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
