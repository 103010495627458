<template>
  <eins-layout-map>
    <eins-map-multi-objects
        :objects="cObjects"
        :object-type="type"
    />
    <div style="position:absolute; z-index:2;bottom:0;right:0;">
      <slot name="switcher"></slot>
    </div>
  </eins-layout-map>
</template>

<script>
import EinsLayoutMap from '../layout/EinsLayoutMap'
import EinsMapMultiObjects from './EinsMapMultiObjects'
export default {
  name: 'EinsObjectListMap',
  components: {
    EinsMapMultiObjects,
    EinsLayoutMap
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'groups'
    }
  },
  computed: {
    cObjects () {
      return this.items || []
    }
  },
  beforeRouteEnter (to, from, next) {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
