<template>
  <v-dialog
      :persistent="loading"
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-card class="edit-dialog-content">
      <v-card-title>
        <i18n path="player.update.password.dialogTitle" />
      </v-card-title>
      <v-expand-transition>
        <v-card-text v-if="!success">
          <v-form
              ref="form"
              v-model="form.valid"
          >
            <eins-text-field-password
                v-model="form.data.current_password"
                :error-messages="cCurrentPasswordApiErrors"
                :error="cCurrentPasswordApiErrors.length > 0"
                :label="cLabels.currentPassword"
                allow-plain
                :rules="cRules.currentPassword"
            />
            <eins-text-field-password
                v-model="form.data.password"
                :label="cLabels.password"
                allow-plain
                :rules="cRules.password"
            />
            <eins-text-field-password
                v-model="form.data.password_confirmation"
                :label="cLabels.passwordConfirmation"
                allow-plain
                :rules="cRules.passwordConfirmation"
            />
          </v-form>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          v-if="success"
          text
          tile
          type="success"
      >
        <i18n path="player.update.password.success" />
      </v-alert>
      <v-card-actions class="flex-column">
        <v-expand-transition
            v-for="action in cActions"
            :key="action.key"
        >
          <v-btn
              v-bind="action.btn"
              block
              @click="action.onClick"
          >{{ action.label }}</v-btn>
        </v-expand-transition>
        <v-expand-transition>
          <eins-btn-timed
              v-if="success"
              block
              outlined
              @click="onClickClose"
          >
            <i18n path="player.update.password.actions.close" />
          </eins-btn-timed>
        </v-expand-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsTextFieldPassword from '@/components/input/EinsTextFieldPassword'
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

export default {
  name: 'EinsPlayerDataPasswordDialog',
  components: { EinsBtnTimed, EinsTextFieldPassword },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    internalValue: false,
    loading: false,
    error: false,
    success: false,
    form: {
      valid: '',
      touches: {
        password: false,
        currentPassword: false,
        passwordConfirmation: false
      },
      data: {
        password: '',
        current_password: '',
        password_confirmation: ''
      }
    }
  }),
  computed: {
    cApiValidationErrors () {
      return this.error?.response?.data?.errors ?? {}
    },
    cCurrentPasswordApiErrors () {
      const result = []

      if (
        !this.form.touches.currentPassword &&
        Array.isArray(this.cApiValidationErrors.current_password)
      ) {
        const errors = this.cApiValidationErrors.current_password
          .map(error => error.code)
          .filter((code, idx, arr) => idx === arr.indexOf(code)) // unique
        for (const errorCode of errors) {
          switch (errorCode) {
            case 'password_error':
              result.push(this.$t('player.update.password.form.currentPassword.mismatch'))
              break
          }
        }
      }

      return result
    },
    cActions () {
      const result = []

      if (!this.success) {
        result.push({
          key: 'change',
          onClick: () => this.onClickSubmit(),
          label: this.$t('player.update.password.actions.submit'),
          btn: {
            color: 'primary',
            disabled: !this.form.valid,
            loading: this.loading
          }
        })

        result.push({
          key: 'cancel',
          onClick: () => this.onClickCancel(),
          label: this.$t('player.update.password.actions.cancel'),
          btn: {
            outlined: true
          }
        })
      }

      return result
    },
    cLabels () {
      return {
        currentPassword: this.$t('player.update.password.form.currentPassword.label'),
        password: this.$t('player.update.password.form.password.label'),
        passwordConfirmation: this.$t('player.update.password.form.passwordConfirmation.label')
      }
    },
    cRules () {
      return {
        currentPassword: [
          value => !!value ||
              this.$t('player.update.password.form.currentPassword.required')
        ],
        password: [
          value => !!value ||
              this.$t('player.update.password.form.password.required'),
          value => !value ||
              value.length > 9 ||
              this.$t('player.update.password.form.password.min')
        ],
        passwordConfirmation: [
          value => !this.form.data.password ||
              !!value ||
              this.$t('player.update.password.form.passwordConfirmation.required'),
          value => !this.form.data.password ||
              value === this.form.data.password ||
              this.$t('player.update.password.form.passwordConfirmation.mismatch')
        ]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    },
    internalValue: {
      immediate: true,
      handler: 'onChangeInternalValue'
    }
  },
  methods: {
    onChangeInternalValue (value, oldValue) {
      if (value !== oldValue) {
        this.reset()
      }
    },
    onChangeValue (value, oldValue) {
      if (value !== oldValue) {
        this.internalValue = value
        if (value) {
          this.onOpen()
        }
      }
    },
    onInputDialog (value) {
      this.updateModel(value)
    },
    onOpen () {
      this.reset()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.close()
    },
    onClickClose () {
      this.close()
    },
    reset () {
      this.loading = false
      this.resetData()
      this.resetTouches()
      this.resetFormValidation()
      this.success = false
      this.error = false
    },
    resetData () {
      this.form.data.password = ''
      this.form.data.current_password = ''
      this.form.data.password_confirmation = ''
    },
    resetTouches () {
      this.form.touches.password = false
      this.form.touches.currentPassword = false
      this.form.touches.passwordConfirmation = false
    },
    resetFormValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close () {
      this.updateModel(false)
    },
    updateModel (value) {
      this.internalValue = value
      this.$emit('input', this.internalValue)
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(
        `players/${this.$store.getters['players/current/id']}`,
        { ...this.form.data }
      )
        .then(() => {
          this.success = true
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.resetTouches()
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
