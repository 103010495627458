<template>
  <eins-input-toolbar-extension
      v-model="textFilter"
      class="mt-3"
      object-type="player"
      :label="cLabel"
      :filter-options="cFilterOptions"
      :filter-value="cCurrentFriendshipFilter"
      @input="onChangeTextFilter"
      @update:filter="onUpdateFilter"
  >
    <template #icon>
      <eins-icon-players
          active
          width="26"
          class="mr-1"
      />
    </template>
    <template #filter-icon="option">
      <v-list-item-icon v-if="option.value !== '__all__'">
        <eins-icon-friendship
            class="mr-2"
            :state="option.value"
            width="24"
        />
      </v-list-item-icon>
    </template>
  </eins-input-toolbar-extension>
</template>

<script>
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsIconPlayers from '@/components/icons/EinsIconPlayers'
import EinsInputToolbarExtension from '@/components/input/EinsInputToolbarExtension'
import {
  arrayContentIsIdentical
} from '@/utils/object'

export default {
  name: 'ViewPlayerListToolbarExtension',
  components: {
    EinsIconFriendship,
    EinsInputToolbarExtension,
    EinsIconPlayers
  },
  data: () => ({
    textFilter: ''
  }),
  computed: {
    cCurrentFilter () {
      return this.$store.getters['players/filter']
    },
    cCurrentFriendshipFilter () {
      return this.cCurrentFilter.friendship_states ?? []
    },
    cLabel () {
      return this.$t('terms.players')
    },
    cFilterOptions () {
      return [{
        title: this.$t('friendship.state.friend.titleFilter'),
        value: 'friend'
      }, {
        title: this.$t('friendship.state.idol.titleFilter'),
        value: 'idol'
      }, {
        title: this.$t('friendship.state.fan.titleFilter'),
        value: 'fan'
      }, {
        title: this.$t('friendship.state.none.titleFilter'),
        value: 'none'
      }]
    }
  },
  mounted () {
    this.textFilter = this.$store.getters['players/search']
  },
  methods: {
    onChangeTextFilter () {
      this.$store.dispatch('players/searchDelayed', this.textFilter)
    },
    onUpdateFilter (value) {
      if (!arrayContentIsIdentical(value, this.cCurrentFriendshipFilter)) {
        this.setFilter('friendship_states', value)
      }
    },
    setFilter (key, value) {
      if (Array.isArray(value) && value.length > 0) {
        this.$store.dispatch('players/filter', { [key]: [...value] })
      } else {
        this.$store.dispatch('players/filter', { [key]: null })
      }
    }
  }
}
</script>

<style scoped>

</style>
