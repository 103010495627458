<template>
  <eins-layout-list-item
      :item-type="'player'"
      :to="cTo"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template #image>
      <eins-api-image
          #default="{ src }"
          :src="cPicture"
      >
        <v-img
            :src="src"
            aspect-ratio="1"
            height="100%"
        >
          <template #placeholder>
            <v-skeleton-loader
                type="image"
            />
          </template>
        </v-img>
      </eins-api-image>
    </template>

    <template #icons>
      <v-col class="flex-grow-0">
        <slot name="indicator1">
          <v-icon
              color="rgba(0,0,0,0)"
          >mdi-square</v-icon>
        </slot>
      </v-col>
      <v-col class="flex-grow-0">
        <slot name="indicator2">
          <eins-icon-friendship
              width="1.5em"
              height="1.5em"
              :state="player.friendship_state"
              style="margin: 0 auto !important"
          />
        </slot>
      </v-col>
      <v-col class="flex-grow-0">
        <slot name="indicator3">
          <!--<div class="caption mb-n1 pt-0 pb-0">
            {{ cDistance }}
          </div>-->
        </slot>
      </v-col>
    </template>

    <template #title>
      {{ player.name || player.title }}
    </template>
    <slot
        name="content"
        :item="player"
    >
      {{ cDescription }}
    </slot>
  </eins-layout-list-item>
</template>

<script>
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsLayoutListItem from '@/components/layout/EinsLayoutListItem'
import EinsApiImage from '@/components/EinsApiImage'

const regHTML = /<\/?[^>]*>/g

const descriptionMaxLength = 70

const genericPicture2 = `${location.protocol}//${location.host}/img/placeholder/blind-player.gif`

export default {
  name: 'EinsPlayerListItem',
  components: {
    EinsApiImage,
    EinsIconFriendship,
    EinsLayoutListItem
  },
  props: {
    player: {
      type: Object,
      default: () => ({}),
      required: true
    },
    to: {
      type: [String, Boolean],
      default: null
    }
  },
  data: () => ({
  }),
  computed: {
    cTo () {
      if (this.to === false) {
        return null
      } else if (
        typeof this.to === 'string' &&
        this.to.trim().length > 0
      ) {
        return this.to
      } else {
        return `/players/${this.player.id}`
      }
    },
    cUserFriendshipState () {
      let result

      const rand = Math.random()
      if (rand > 0.75) {
        result = 'friend'
      } else if (rand > 0.5) {
        result = 'idol'
      } else if (rand > 0.25) {
        result = 'fan'
      } else if (rand > 0) {
        result = 'none'
      }

      return result
    },
    cPicture () {
      return this.player && this.player.title_picture_feed ? this.player.title_picture_feed : genericPicture2
    },
    cDistance () {
      if (!this.player || isNaN(this.player.distance)) {
        return ''
      } else {
        let distance = this.player.distance
        let format = 'distance-meter'

        if (distance > 1000) {
          // decimal places: 1 => / Round(d/100)/10
          distance = Math.round(distance / 100) / 10
          format = 'distance-kilometer'
        }

        return `${this.$n(distance, 'decimal')} ${format === 'distance-kilometer' ? 'k' : ''}m`
      }
    },
    cDescription () {
      let description = (this.player.about || '').replace(regHTML, '')
      if (description.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }
      return description
    },
    cHighlight () {
      let result = []

      if (this.player.quests_open_count > 0) {
        result.push(this.$vuetify.theme.parsedTheme.quest.base)
      }
      if (this.player.deals_open_count > 0) {
        result.push(this.$vuetify.theme.parsedTheme.deal.base)
      }

      // return result
      return '#ae17b0'
    }
  }
}
</script>
