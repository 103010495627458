<template>
  <eins-group-list-item
      :group="group"
  >
    <template #content>
      <v-row
          align-content="end"
          justify="space-around"
          class="fill-height pb-2"
          dense
      >
        <v-col class="flex-grow-0">
          <v-badge
              bottom
              overlap
              :content="group.members_count"
          >
            <v-icon>mdi-account</v-icon>
          </v-badge>
        </v-col>
        <v-col class="flex-grow-0">
          <v-badge
              bottom
              overlap
              :content="group.quests_open_count"
          >
            <v-icon>mdi-map-marker</v-icon>
          </v-badge>
        </v-col>
        <v-col class="flex-grow-0">
          <v-badge
              bottom
              overlap
              :content="group.deals_open_count"
          >
            <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
        </v-col>
        <v-col class="flex-grow-0">
          <v-btn
              icon
              class="mt-n1"
              :disabled="cPlayerCantLeave"
              @click.stop.prevent="onClickLeave"
          ><v-icon>mdi-logout-variant</v-icon></v-btn>
        </v-col>
      </v-row>
    </template>
    <template #indicator3>
      <v-icon>
        <template v-if="cIsGroupOwner">mdi-shield-star-outline</template>
        <template v-else-if="cIsGroupAdmin">mdi-account-supervisor</template>
        <template v-else>mdi-wallet-membership</template>
      </v-icon>
    </template>
  </eins-group-list-item>
</template>

<script>
import EinsGroupListItem from '@/components/group/EinsGroupListItem'
import { Membership } from '@/constants'

export default {
  name: 'EinsGroupListItemPlayerGroup',
  components: {
    EinsGroupListItem
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    cIsOwnProfile () {
      return this.$route.params.id === this.$store.getters['players/current/id']
    },
    cIsGroupOwner () {
      return this.group.owner_id === this.$store.getters['players/current/id']
    },
    cIsGroupAdmin () {
      return this.group.membership_state === Membership.ADMIN
    },
    cPlayerCantLeave () {
      return !(this.cIsOwnProfile && !this.cIsGroupOwner)
    }
  },
  methods: {
    onClickLeave () {
      this.$store.dispatch('openDialogLeaveGroup', { id: this.group.id })
    }
  }
}
</script>

<style scoped>

</style>
