export default {
  status: {
    open: {
      owner: '@.lower:(terms.players) can apply for this @.lower:(terms.quest)',
      foreigner: 'You can apply for this @.lower:(terms.quest)'
    },
    processing: {
      owner: 'The @.lower:(terms.quest) is processing',
      applicant: 'The @.lower:(terms.quest) is processed by another applicant',
      assignee: 'You are currently processing this @.lower:(terms.quest)'
    },
    assigned: {
      owner: 'The @.lower:(terms.quest) can be started by the applicant',
      assignee: 'You can start this @.lower:(terms.quest)',
      applicant: 'Another @.lower:(terms.player) has been assigned for this @.lower:(terms.quest)'
    },
    canceled: {
      owner: 'You canceled the @.lower(terms.quest).',
      foreigner: 'The @.lower(terms.quest) has been canceled.'
    },
    done: {
      owner: 'The @.lower:(terms.quest) is ready to be confirmed.',
      assignee: '@:(quest.status.done.owner)',
      applicant: 'The  @.lower:(terms.quest) has been finished by someone else but is yet to be confirmed.'
    },
    closed: {
      owner: 'The @.lower:(terms.quest) has been completed.',
      assignee: '@:(quest.status.closed.owner)',
      applicant: 'The @.lower:(terms.quest) has been completed by someone else.'
    },
    'open-applied': {
      owner: 'There are new applicants for this @.lower:(terms.quest)',
      applicant: 'You are an applicant of this @.lower:(terms.quest)'
    }
  },
  type: {
    common: {
      title: '@.lower:(terms.common)',
      text: 'This @.lower:(terms.quest) is a @.lower:(terms.common) @.lower:(terms.quest) for all of us'
    },
    private: {
      title: '@.lower:(terms.private)',
      text: 'This is a @.lower:(terms.private) @.lower:(terms.quest) for the @.lower:(terms.player)'
    },
    green: {
      title: '@.lower:(terms.future)',
      text: 'This @.lower:(terms.quest) is for the @.lower:(terms.future)'
    },
    nature: {
      title: '@.lower:(terms.future)',
      text: 'This @.lower:(terms.quest) is for the @.lower:(terms.future)'
    },
    future: {
      title: '@.lower:(terms.future)',
      text: 'This @.lower:(terms.quest) is for the @.lower:(terms.future)'
    },
    undefined: {
      title: '@.lower:(terms.future)',
      text: 'This @.lower:(terms.quest) is for the @.lower:(terms.future)'
    }
  },
  create: {
    title: {
      label: '@:(terms.quest) title',
      required: 'Please a title for your @.lower:(terms.quest)',
      min: 'The title needs to have a length of at least {0} characters'
    },
    titlePicture: {
      title: '@:(terms.quest) title picture',
      cancel: 'Cancel',
      submit: 'Set title picture',
      required: 'Please provide a title picture for your @.lower:(terms.quest)'
    },
    description: {
      label: '@:(terms.quest) details',
      required: 'Please provide a @.lower:(terms.description) for your @.lower:(terms.quest)',
      min: 'The @.lower:(terms.description) must have a length of at least {0} words.'
    },
    value: {
      label: 'Reward'
    },
    hideWhenFinished: {
      label: 'Hide when finished',
      hint: 'Hide when finished'
    },
    location: {
      addressTitle: 'Set @.lower:(terms.quest) address',
      addressSubmit: 'Set',
      required: 'Please select a position on the map or provide an address.'
    },
    scope: {
      dialogTitle: 'Who can apply for the @:(terms.quest)?',
      listCaption: 'Only @:(terms.group) members',
      voidOption: 'Everyone',
      currentValueLabel: 'Scope',
      currentValueHint: 'Only members of the selected @:(terms.group) can apply.' +
          ' If no @:(terms.group) is selected, everyone can apply.',
      submit: 'Change scope'
    }
  },
  update: {
    title: {
      dialogTitle: 'Change @:(terms.quest) title',
      actions: {
        submit: 'Change title'
      },
      success: {
        title: 'Success!',
        text: 'The title of your @.lower:(terms.quest) was changed from \'{0}\' to \'{1}\'.'
      },
      title: {
        label: 'New title',
        required: 'Please enter a new title',
        min: 'The title needs to have a length of at least 3',
        unchanged: 'The new title is not different from the current title.'
      }
    },
    reward: {
      dialogTitle: 'Change @:(terms.quest) reward',
      currentValueLabel: 'Reward',
      actions: {
        submit: 'Change reward'
      },
      success: {
        title: 'Success!',
        text: 'The reward for your @:(terms.quest) has been updated.'
      }
    },
    scope: {
      dialogTitle: 'Who can apply for the @:(terms.quest)?',
      listCaption: 'Only @:(terms.group) members',
      voidOption: 'Everyone',
      currentValueLabel: 'Scope',
      currentValueHint: 'Only members of the selected @:(terms.group) can apply.' +
          ' If no @:(terms.group) is selected, everyone can apply.',
      actions: {
        submit: 'Change scope'
      },
      success: {
        title: 'Success!',
        text: 'The scope for your @:(terms.quest) has been updated.'
      }
    },
    description: {
      dialogTitle: 'Change @.lower:(terms.quest) description',
      actions: {
        submit: 'Change description'
      },
      success: {
        title: 'Success!',
        text: 'The description of your @.lower:(terms.quest) has been updated.'
      },
      description: {
        label: 'Description',
        max: 'The length of the description must not exceed 500.'
      }
    },
    picture: {
      dialogTitle: 'Upload picture',
      success: {
        title: 'Success!',
        text: 'Your @.lower:(terms.quests) title picture was set.'
      },
      actions: {
        submit: 'Upload',
        setPicture: 'Set picture'
      }
    },
    location: {
      address: {
        dialogTitle: 'Set address',
        success: {
          title: 'Address updated',
          text: 'The @.lower:(terms.quest)-address has been updated, we will now recalculate the map position.'
        },
        actions: {
          submit: 'Update address'
        }
      },
      position: {
        success: {
          title: 'Position updated',
          text: 'The @.lower:(terms.quest)-position has been updated, we will now recalculate the address.'
        }
      }
    }
  }
}
