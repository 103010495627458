<template>
  <v-img
      :src="cImage"
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
const validStates = [
  'friend',
  'idol',
  'follower',
  'me',
  'none',
  'fan'
]

const imageFan = require('@/assets/img/icons/friendship/fan.png')
const imageFriend = require('@/assets/img/icons/friendship/friend.png')
const imageIdol = require('@/assets/img/icons/friendship/idol.png')
const imageMe = require('@/assets/img/icons/friendship/me.png')
const imageNone = require('@/assets/img/icons/friendship/none.png')

export default {
  name: 'EinsIconFriendship',
  props: {
    state: {
      type: String,
      default: 'none',
      validate: (value) => validStates.indexOf(value) > -1
    }
  },
  computed: {
    cImage () {
      switch (this.state) {
        case 'fan':
          return imageFan
        case 'friend':
          return imageFriend
        case 'idol':
          return imageIdol
        case 'me':
          return imageMe
        case 'none':
        default:
          return imageNone
      }
    }
  }
}
</script>

<style scoped>
.v-responsive{
  flex: none !important
}
</style>
