<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-human-greeting</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  DealState,
  Entity, QuestState
} from '@/constants'
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbApply',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cType () {
      switch (this.$route.name) {
        case 'deal-detail':
          return Entity.DEAL
        case 'quest-detail':
          return Entity.QUEST
        case 'group-detail':
          return Entity.GROUP
        default:
          return null
      }
    },
    cEntity () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal']
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest']
          break
        case Entity.GROUP:
          result = this.$store.getters['groups/selected/group']
          break
        default:
          return null
      }

      return result
    },
    cShowBtn () {
      return this.cBelongsToRoute &&
        this.cStateIsOpen &&
        !this.cUserIsApplicant &&
        !this.cDirectInteraction &&
        !this.cUserIsOwner
    },
    cDirectInteraction () {
      return [Entity.DEAL, Entity.GROUP].includes(this.cType) && !this.cEntity?.require_application
    },
    cStateIsOpen () {
      let result
      switch (this.cType) {
        case Entity.DEAL:
          result = this.$store.getters['deals/selected/deal'].state === DealState.OPEN
          break
        case Entity.QUEST:
          result = this.$store.getters['quests/selected/quest'].state === QuestState.OPEN
          break
        case Entity.GROUP:
          result = true
          break
        default:
          result = false
      }

      return result
    },
    cUserIsApplicant () {
      const userId = this.$store.getters['players/current/id']
      const applicants = (this.cEntity?.applicants ?? [])
      return applicants.includes(userId)
    },
    cUserIsOwner () {
      const userId = this.$store.getters['players/current/id']
      return this.cEntity?.user_id === userId || (
          this.cEntity?.owner_id === userId
      )
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogApplication')
        .then(() => {
          switch (this.cType) {
            case Entity.DEAL:
              return this.$store.dispatch('deals/selected/update')
            case Entity.QUEST:
              return this.$store.dispatch('quests/selected/update')
            case Entity.GROUP:
              return this.$store.dispatch('groups/selected/update')
            default:
              return Promise.reject(new Error('Invalid type'))
          }
        })
    }
  }
}
</script>

<style scoped>
</style>
