import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { i18n } from '@/plugins/i18n'
import * as themes from '@/plugins/themes'

Vue.use(Vuetify)

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  // connect to the vue-i18n plugin
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes,
    dark: true,
    options: {
      customProperties: true
    }
  }
})
