<template>
  <cropper
      class="mx-auto"
      style="width: auto;"
      :style="`aspect-ratio: ${cSrcAspectRatio}; max-height: ${maxHeight}`"
      :src="src"
      :stencil-size="cStencilSize"
      :stencil-props="cStencilProps"
      :canvas="cCanvas"
      @change="onChange"
      @ready="onReady"
  />
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'

export default {
  name: 'EinsImageCropper',
  components: {
    Cropper
  },
  props: {
    src: {
      type: String,
      default: null
    },
    maxHeight: {
      type: String,
      default: '100vh'
    }
  },
  data: () => ({
    result: null,
    showCropper: false,
    srcDimensions: {
      width: null,
      height: null
    }
  }),
  computed: {
    cStencilSize () {
      return {
        width: 500,
        height: 500
      }
    },
    cStencilProps () {
      return {
        handlers: {},
        movable: true,
        resizable: false,
        aspectRatio: 1
      }
    },
    cCanvas () {
      return {
        minHeight: 550,
        minWidth: 550,
        maxHeight: 1024,
        maxWidth: 1024
      }
    },
    cSrcAspectRatio () {
      if (!this.srcDimensions.height || !this.srcDimensions.width) {
        return 1
      }

      return this.srcDimensions.width / this.srcDimensions.height
    }
  },
  watch: {
    src: {
      immediate: true,
      handler: 'onChangeSrc'
    }
  },
  beforeDestroy () {
    this.resetResult()
    this.emitUpdate()
  },
  methods: {
    onChangeSrc () {
      this.showCropper = false
      this.resetResult()
      this.fetchSrcDimensions()
        .then(() => {
          this.showCropper = true
        })
    },
    onChange (e) {
      this.resetResult()
      this.result = e.canvas.toDataURL()
      this.emitUpdate()
    },
    onReady () {
      this.$emit('ready')
    },
    fetchSrcDimensions () {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          this.srcDimensions.width = img.width
          this.srcDimensions.height = img.height
          resolve()
        }
        img.onerror = err => {
          this.srcDimensions.width = null
          this.srcDimensions.height = null
          reject(err)
        }
        img.src = this.src
      })
    },
    resetResult () {
      if (this.result) {
        URL.revokeObjectURL(this.result)
        this.result = null
      }
    },
    getBlob () {
      if (!this.result) {
        return Promise.resolve(null)
      }

      return fetch(this.result)
        .then(response => response.blob())
        .catch(() => null)
    },
    emitUpdate () {
      this.$emit('update', this.result)
    }
  }
}
</script>

<style scoped>

</style>
