<template>
  <div
      style="height: 100%; overflow: hidden;"
      class="mt-2 d-flex flex-column"
  >
    <v-btn
        fab
        absolute
        depressed
        elevation="0"
        style="right: -0.75em; top: -0.75em;"
        color="secondary"
        @click="$router.go(-1)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <!-- eslint-disable-next-line vue/html-self-closing -->
    <div class="flex-shrink-1">
      <slot
          name="information"
          style="padding-right: 3.25em;"
      ></slot>
      <v-sheet
          tile
          style="position: relative; overflow: hidden;"
      >
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <slot name="image" />
      </v-sheet>
    </div>
    <v-divider />
    <div
        v-scroll.self="onScroll"
        style="position: relative;overflow-y: auto;"
        class="flex-grow-1"
    >
      <div
          v-for="block in blocks"
          :key="`block-item_#${block.value}`"
          :value="block.value"
          style="position: relative;height:auto"
      >
        <div class="pa-2">
          <v-icon>{{ block.icon }}</v-icon>
          <span class="pa-2">{{ block.value }}</span>
        </div>
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <slot :name="`block-${block.value}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EinsLayoutItemDetailSimple',
  props: {
    blocks: {
      type: Array,
      default: () => [],
      validator: (val) => {
        let result = true

        for (const block of val) {
          result = result &&
              block.hasOwnProperty('value') &&
              block.hasOwnProperty('icon')
        }

        return result
      }
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    block: ''
  }),
  methods: {
    onScroll (e) {
      const target = e.target
      const scrollTop = target.scrollTop
      const scrollArea = target.scrollHeight - target.clientHeight
      const factor = Math.round(100 - (scrollTop / scrollArea * 100))
      this.$emit('oncontainerscroll', factor)
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
</style>
