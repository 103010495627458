<template>
  <v-btn
      :href="`http://www.facebook.com/share.php?u=${cUrl}`"
      target="_blank"
      color="#3b5998"
      v-bind="$attrs"
  >
    <v-icon left>mdi-facebook</v-icon>{{ cText['text'] }}
  </v-btn>
</template>

<script>
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsSocialShareFacebook',
  mixins: [
    MxTranslatable
  ],
  props: {
    shareUrl: {
      type: String,
      required: true
    }
  },
  data: () => ({
    $_mx_translatable: {
      paths: [
        'text'
      ],
      params: {}
    }
  }),
  computed: {
    cUrl () {
      return encodeURIComponent(this.shareUrl)
    }
  }
}
</script>

<style scoped>

</style>
