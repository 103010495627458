// @vue/component
import {
  RegRouteDeal,
  RegRouteGroup,
  RegRoutePlayer,
  RegRouteQuest
} from '@/utils/regex'
import { Entity } from '@/constants'

export const MxStarmenuBtn = {
  computed: {
    mx_starmenu_btn_c_area () {
      // which area is the app in, groups, deals, quests or players?
      const name = this.$route.name

      if (RegRoutePlayer.test(name)) {
        return Entity.PLAYER
      } else if (RegRouteDeal.test(name)) {
        return Entity.DEAL
      } else if (RegRouteQuest.test(name)) {
        return Entity.QUEST
      } else if (RegRouteGroup.test(name)) {
        return Entity.GROUP
      } else {
        return null
      }
    },
    cArea () {
      return this.mx_starmenu_btn_c_area
    },
    mx_starmenu_btn_c_belongs_to_route () {
      const btnNames = this.$route.meta.starmenuButtons ?? []

      return btnNames.includes(this.$options.name)
    },
    cBelongsToRoute () {
      return this.mx_starmenu_btn_c_belongs_to_route
    }
  }
}
