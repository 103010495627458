<template>
  <v-app-bar
      app
      clipped-left
      color="secondary"
  >
    <div
        class="d-flex fluid"
        :class="cClass"
        style="width:100%;z-index:5"
    >
      <v-btn
          ref="profileBtn"
          v-ripple="false"
          icon
          large
          to="/my-profile"
      >
        <eins-icon-profile
            max-width="25"
            :active="cProfileActive"
        />
      </v-btn>
      <v-spacer />
      <v-btn
          v-ripple="false"
          icon
          large
          to="/conversations"
      >
        <v-badge
            color="none"
            overlap
            bottom
            :value="cUnreadConversations > 0"
        >
          <template
              v-if="cUnreadConversations < 10"
              #badge
          >{{ cUnreadConversations }}</template>
          <template
              v-else
              #badge
          >9+</template>
          <eins-icon-chat
              width="25"
              :active="cActive.conversations"
          />
        </v-badge>
      </v-btn>
      <v-spacer />
      <v-btn
          v-ripple="false"
          icon
          large
          @click="onClickNotifications"
      >
        <v-badge
            color="none"
            overlap
            top
            :value="cUnreadNotifications > 0"
        >
          <template
              v-if="cUnreadNotifications < 10"
              #badge
          >{{ cUnreadNotifications }}</template>
          <template
              v-else
              #badge
          >9+</template>
          <eins-icon-feed
              max-width="50"
              max-height="50"
              :active="cActive.feed"
          />
        </v-badge>
      </v-btn>
      <v-spacer />
      <v-btn
          v-ripple="false"
          icon
          large
          to="/timeline"
      >
        <v-badge
            color="none"
            overlap
            bottom
        >
          <template #badge>{{ cClosedPlayerQuest }}</template>
          <eins-icon-timeline
              width="25"
              :active="cActive.timeline"
          />
        </v-badge>
      </v-btn>
      <v-spacer />
      <v-btn
          v-ripple="false"
          icon
          large
          @click="onClickMenuButton"
      >
        <img
            width="22"
            height="22"
            src="../../assets/img/icons/ui/menu-button-new_colorful.png"
            alt="menue"
        />
      </v-btn>
    </div>
    <!--
    <v-app-bar-nav-icon
        @click="onClickMenuButton"
    >
      <
    </v-app-bar-nav-icon>
    -->

    <!-- extension -> extension1  -->
    <template
        v-if="cHasExtension"
        #extension
    >
      <v-expand-transition>
        <router-view name="toolbarExtension" />
      </v-expand-transition>
    </template>
  </v-app-bar>
</template>

<script>
import {
  EinsIconChat,
  EinsIconFeed,
  EinsIconProfile,
  EinsIconTimeline
} from '@/components'

export default {
  name: 'TheAppBarTop',
  components: {
    EinsIconChat,
    EinsIconFeed,
    EinsIconProfile,
    EinsIconTimeline
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    internalValue: false
  }),
  computed: {
    cNotificationPaneOpen () {
      return this.$store.getters.notificationsPaneOpen
    },
    cHasExtension () {
      return this.$route.meta.hasToolbarExtension === true && !this.cNotificationPaneOpen
    },
    cActive () {
      return {
        conversations: this.$route.name === 'conversation-list' || this.$route.name === 'conversation-details',
        feed: this.cNotificationPaneOpen,
        timeline: this.$route.name === 'timeline',
        groups: this.$route.name === 'group-list' || this.$route.name === 'group-detail',
        players: this.$route.path !== this.cPlayerProfile && (this.$route.name === 'player-list' || this.$route.name === 'player-detail'),
        quests: this.$route.name === 'quest-list' || this.$route.name === 'quest-detail',
        deals: this.$route.name === 'deal-list' || this.$route.name === 'deal-detail'
      }
    },
    cClass () {
      if (this.cActive.groups) {
        return 'shadow-bottom-group'
      } else if (this.cActive.players) {
        return 'shadow-bottom-player'
      } else if (this.cActive.quests) {
        return 'shadow-bottom-quest'
      } else if (this.cActive.deals) {
        return 'shadow-bottom-deal'
      } else {
        // return 'four-color-gradient-shadow-bottom'
        return 'shadow-bottom-group'
      }
    },
    cPlayerProfile () {
      return `/players/${this.$store.getters['players/current/id']}`
    },
    cProfileActive () {
      return this.$route.path === this.cPlayerProfile
    },
    cUnreadNotifications () {
      return this.$store.getters['players/current/unreadNotifications'] ?? 0
    },
    cUnreadConversations () {
      return this.$store.getters['players/current/unreadConversations'] ?? 0
    },
    cClosedPlayerQuest () {
      return this.$store.getters[`players/selected/questsFinished/list`].length
    }
  },
  created () {
    this.setValue(this.value)
  },
  methods: {
    onClickNotifications () {
      this.toggleNotifications()
    },
    emitModel () {
      this.$emit('input', this.internalValue)
    },
    setValue (newValue) {
      this.internalValue = !!newValue
    },
    toggleModel () {
      this.internalValue = !this.internalValue
      this.emitModel()
    },
    onClickMenuButton () {
      this.toggleModel()
    },
    toggleNotifications () {
      this.$store.commit('toggleNotificationsPane')
      if (this.$store.getters.notificationsPaneOpen) {
        this.$store.dispatch('players/current/notifications/loadLatest')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-toolbar {
  > ::v-deep .v-toolbar__content {
    $color: var(--v-primary-base);

    // prevent the overflow setting from cutting off shadow
    position: relative;
    z-index: 2;

    //box-shadow: 0px 3px 10px 0px $color;
  }

  > ::v-deep .v-toolbar__extension {
    $paddingSide: 0.25em ;

    background-color: var(--v-secondary-base) !important;
    padding-left: $paddingSide ;
    padding-right: $paddingSide ;
  }
  .v-btn::before {
    background:transparent !important
  }
}
</style>
