<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-check-decagram-outline"
      :text="cText.text"
  />
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuEbVerify',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute && this.cUserIsOwner && this.cGroupTypeTitle
    },
    cUserIsOwner () {
      const userId = this.$store.getters['players/current/id']
      return this.cGroup?.owner_id === userId
    },
    cTarget () {
      return this.cShowBtn ? `/groups/${this.$store.getters['groups/selected/group'].id}/verify` : ''
    },
    cGroup () {
      return this.$store.getters['groups/selected/group']
    },
    cGroupTypeTitle () {
      return this.cGroup?.group_type?.key === 'priv'
    }
  }
}
</script>

<style scoped>

</style>
