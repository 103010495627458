<template>
  <v-app>
    <!-- QR Code Scanner dialog -->
    <eins-dialog-qr-code-scanner
        v-if="isActive"
        @closeScanner="closeQrScanner"
    />
    <!-- TOOLBAR-TOP -->
    <the-app-bar-top v-model="navigationDrawer.active" />

    <!-- MAIN-CONTENT-AREA -->
    <v-main
        v-if="!isActive"
        id="main-content"
    >
      <v-sheet
          id="main-content-scroll"
          color="grey darken-3"
          style="position: relative;"
          max-height="100%"
          max-width="100%"
          height="100%"
          width="100%"
          tile
      >
        <router-view />
        <v-fade-transition>
          <v-btn
              v-show="cShowMapButton"
              fixed
              fab
              bottom
              right
              style="bottom: 65px;"
              color="primary"
          >
            <v-icon large></v-icon>
          </v-btn>
        </v-fade-transition>
      </v-sheet>
      <eins-notification-pane
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 5;"
          @scroll.prevent.stop
      />

      <eins-starmenu />
      <the-refreshing-overlay v-model="cRefreshing" />
    </v-main>

    <!-- POP-OUT-MENU -->
    <v-navigation-drawer
        v-model="navigationDrawer.active"
        app
        right
        temporary
        touchless
    >
      <!-- MENU-TITLE -->

      <!--
      <v-list-item>
        <v-list-item-avatar
            tile
            left
        >
          <v-img
              contain
              src="@/assets/logo.png"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title">
            Eins
          </v-list-item-title>
          <v-list-item-subtitle>
            subtitle....
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      -->

      <!--
      <v-list-item
          :to="'/profile'"
      >
        <v-list-item-avatar>
          <v-icon large>mdi-account-box-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            My Profile
          </v-list-item-title>
          <v-list-item-subtitle>
            Show your user-profile...
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      -->
      <!--
      <v-divider />
      <v-list-item
          @click="toggleDarkTheme"
      >
        <v-list-item-avatar>
          <v-icon large>mdi-theme-light-dark</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="darkTheme">Dark</span>
            <span v-else>Light</span>
            Theme
          </v-list-item-title>
          <v-list-item-subtitle>
            Click to change
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
      <v-list-item :to="`/`">
        <v-list-item-avatar>
          <v-icon
              color="primary"
              size="48px"
          >mdi-page-first</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.main-page.label') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="'/my-profile'">
        <v-list-item-avatar>
          <eins-icon-profile
              active="true"
              max-width="36px"
              max-height="36px"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.my-profile.label') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="'/balances'">
        <v-list-item-avatar>
          <eins-icon-star active="true" />
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.available-stars.label') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="toggleScanner()">
        <v-list-item-avatar>
          <v-icon
              color="primary"
              size="32px"
          >mdi-qrcode-scan</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.scanner.label') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="'/informations'">
        <v-list-item-avatar>
          <v-icon
              color="primary"
              size="32px"
          >mdi-monitor-cellphone-star</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.impress.label') }}
        </v-list-item-content>
      </v-list-item>
      <!-- Blend out till appstore apple is at the homepage -->
      <!--
      <v-list-item @click="goTo('https://www.1independent.de')">
        <v-list-item-avatar>
          <v-icon
              color="primary"
              size="40px"
          >mdi-home-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.1-indepedent.label') }}
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item>
        <v-list-item-content>
          <eins-locale-select :label="$t('mainmenu.eins-locale-select.label')" />
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item @click="$store.dispatch('players/current/logout')">
        <v-list-item-avatar>
          <v-icon large>mdi-logout-variant</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('mainmenu.logout.label') }}
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <!-- TOOLBAR-BOTTOM -->
    <the-app-bar-bottom />

    <!-- DIALOGS -->
    <the-messaging-permission-dialog />
    <eins-starmenu-dlg-application />
    <eins-starmenu-dlg-qr-code />
    <eins-starmenu-dlg-block-player />
    <eins-starmenu-dlg-cancel-deal />
    <eins-starmenu-dlg-link-deal />
    <eins-starmenu-dlg-change-group-owner />
    <eins-starmenu-dlg-cancel-quest />
    <eins-starmenu-dlg-finish-quest />
    <eins-starmenu-dlg-denunciation />
    <eins-starmenu-dlg-joined />
    <eins-starmenu-dlg-leave-group />
    <eins-starmenu-dlg-error />
    <eins-starmenu-dlg-reject-deal />
    <eins-starmenu-dlg-share />
    <eins-starmenu-dlg-add-content />
    <eins-starmenu-dlg-close-account />
    <eins-starmenu-dlg-get-deal />
    <eins-starmenu-dlg-exchange-deal />
    <eins-starmenu-dlg-delete-group />

    <v-dialog
        v-model="emailVerification"
        :persistent="loadingEmailVerification"
    >
      <auth-module-verify-email
          finish-action="close"
          hide-request-button
          @click:close="onClickCloseEmailVerification"
          @update:loading="onUpdateEmailVerificationLoadingState"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import {
  TheAppBarTop,
  TheAppBarBottom
} from '@/components'

import EinsIconProfile from '@/components/icons/EinsIconProfile'
import EinsIconStar from '@/components/icons/EinsIconStar'
import EinsLocaleSelect from '@/components/locale/EinsLocaleSelect'
import EinsNotificationPane from '@/components/notification/EinsNotificationPane'
import EinsStarmenu from '@/components/starmenu/EinsStarmenu'
import EinsStarmenuDlgAddContent from '@/components/starmenu/EinsStarmenuDlgAddContent'
import EinsStarmenuDlgApplication from '@/components/starmenu/EinsStarmenuDlgApplication'
import EinsStarmenuDlgBlockPlayer from '@/components/starmenu/EinsStarmenuDlgBlockPlayer'
import EinsStarmenuDlgCancelDeal from '@/components/starmenu/EinsStarmenuDlgCancelDeal'
import EinsStarmenuDlgLinkDeal from '@/components/starmenu/EinsStarmenuDlgLinkDeal'
import EinsStarmenuDlgCancelQuest from '@/components/starmenu/EinsStarmenuDlgCancelQuest'
import EinsStarmenuDlgChangeGroupOwner from '@/components/starmenu/EinsStarmenuDlgChangeGroupOwner'
import EinsStarmenuDlgCloseAccount from '@/components/starmenu/EinsStarmenuDlgCloseAccount'
import EinsStarmenuDlgDenunciation from '@/components/starmenu/EinsStarmenuDlgDenunciation'
import EinsStarmenuDlgError from '@/components/starmenu/EinsStarmenuDlgError'
import EinsStarmenuDlgExchangeDeal from '@/components/starmenu/EinsStarmenuDlgExchangeDeal'
import EinsStarmenuDlgFinishQuest from '@/components/starmenu/EinsStarmenuDlgFinishQuest'
import EinsStarmenuDlgGetDeal from '@/components/starmenu/EinsStarmenuDlgGetDeal'
import EinsStarmenuDlgJoined from '@/components/starmenu/EinsStarmenuDlgJoined'
import EinsStarmenuDlgLeaveGroup from '@/components/starmenu/EinsStarmenuDlgLeaveGroup'
import EinsStarmenuDlgRejectDeal from '@/components/starmenu/EinsStarmenuDlgRejectDeal'
import EinsStarmenuDlgShare from '@/components/starmenu/EinsStarmenuDlgShare'
import TheMessagingPermissionDialog from '@/components/app/TheMessagingPermissionDialog'
import EinsStarmenuDlgQrCode from '@/components/starmenu/EinsStarmenuDlgQrCode'
import TheRefreshingOverlay from '@/components/app/TheRefreshingOverlay'
import EinsDialogQrCodeScanner from '@/components/dialogs/EinsDialogQrCodeScanner'
import EinsStarmenuDlgDeleteGroup from '@/components/starmenu/EinsStarmenuDlgDeleteGroup'
import AuthModuleVerifyEmail from '@/components/auth/EinsModuleVerifyEmail'

export default {
  name: 'App',
  components: {
    EinsStarmenuDlgDeleteGroup,
    AuthModuleVerifyEmail,
    EinsIconProfile,
    EinsIconStar,
    EinsLocaleSelect,
    EinsNotificationPane,
    EinsStarmenu,
    EinsStarmenuDlgAddContent,
    EinsStarmenuDlgApplication,
    EinsStarmenuDlgBlockPlayer,
    EinsStarmenuDlgCancelDeal,
    EinsStarmenuDlgLinkDeal,
    EinsStarmenuDlgCancelQuest,
    EinsStarmenuDlgChangeGroupOwner,
    EinsStarmenuDlgCloseAccount,
    EinsStarmenuDlgDenunciation,
    EinsStarmenuDlgError,
    EinsStarmenuDlgExchangeDeal,
    EinsStarmenuDlgFinishQuest,
    EinsStarmenuDlgGetDeal,
    EinsStarmenuDlgJoined,
    EinsStarmenuDlgLeaveGroup,
    EinsStarmenuDlgRejectDeal,
    EinsStarmenuDlgShare,
    EinsStarmenuDlgQrCode,
    TheAppBarBottom,
    TheAppBarTop,
    TheMessagingPermissionDialog,
    TheRefreshingOverlay,
    EinsDialogQrCodeScanner
  },
  data: () => ({
    navigationDrawer: {
      active: false
    },
    speedDial: {
      active: false
    },
    darkTheme: true,
    qrScanner: false,
    emailVerification: false,
    loadingEmailVerification: false
  }),
  computed: {
    cRefreshing () {
      return !!this.$root.refreshing
    },
    cToolbarHasExtension () {
      return this.$route.meta.hasToolbarExtension === true
    },
    cEinsActions () {
      return this.$route.meta.speedActions || []
    },
    cImageSize () {
      let result = 32

      if (this.speedDial.active) {
        result = 64
      }

      return result
    },
    cImageStyle () {
      const result = {}

      result.position = 'absolute'

      const size = this.cImageSize
      result.width = `${size}px`
      result.height = `${size}px`

      result.top = `calc(50% - ${size / 2}px)`
      result.left = `calc(50% - ${size / 2}px)`

      return result
    },
    cRouteName () {
      return this.$route.name
    },
    cShowMapButton () {
      return this.$route.meta.hasMapButton === true
    },
    cEnableGeolocation () {
      return this.$store.getters['players/current/enableGeolocation']
    },
    cAppIsVisible () {
      return this.$store.getters['appIsVisible']
    },
    cHasEmailVerificationQuery () {
      return this.$route.query.auth === 'email-verification'
    },
    cUserLanguage () {
      return this.$store.getters['players/current/language']
    },
    isActive () {
      return this.qrScanner
    }
  },
  watch: {
    qrScanner () {
      if (this.qrScanner) {
        this.toggleNavigationDrawer()
        document.querySelector('#app').setAttribute('style', 'background: transparent')
      } else {
        document.querySelector('#app').setAttribute('style', 'opacity: 1')
      }
    },
    darkTheme (newValue) {
      this.$vuetify.theme.dark = newValue
    },
    cAppIsVisible (value) {
      if (value && this.$store.getters['players/current/enableGeolocation']) {
        this.$store.dispatch('players/current/position/persistNewPosition')
      }
    },
    cEnableGeolocation: {
      handler: 'onUpdateGeolocationSettings',
      immediate: true
    },
    cHasEmailVerificationQuery: {
      immediate: true,
      handler (value) {
        if (value) {
          this.emailVerification = true
        }
      }
    },
    emailVerification (value, oldValue) {
      if (!value && value !== oldValue) {
        const query = { ...this.$route.query }
        delete query.link
        delete query.auth

        this.$router.replace({ query })
      }
    },
    cUserLanguage: {
      immediate: true,
      handler: 'onChangeUserLanguage'
    }
  },
  created () {
    this.$store.dispatch('businessSectors/update')
    this.$store.dispatch('groupTypes/update')
    this.darkTheme = this.$vuetify.theme.dark
  },
  methods: {
    closeQrScanner (variable) {
      this.qrScanner = variable
    },
    onChangeUserLanguage (value) {
      this.$i18n.locale = this.$i18n.availableLocales.includes(value) ? value : this.$i18n.fallbackLocale
    },
    onUpdateEmailVerificationLoadingState (value) {
      this.loadingEmailVerification = value
    },
    onClickCloseEmailVerification () {
      this.closeEmailVerification()
    },
    closeEmailVerification () {
      this.emailVerification = false
    },
    goTo (page) {
      window.open(page, '_blank')
    },
    toggleDarkTheme () {
      this.darkTheme = !this.darkTheme
    },
    toggleNavigationDrawer () {
      this.navigationDrawer.active = !this.navigationDrawer.active
    },
    toggleBottomSheet () {
      this.speedDial.active = !this.speedDial.active
    },

    toggleScanner () {
      this.qrScanner = !this.qrScanner
    },
    onUpdateGeolocationSettings (value) {
      if (value) {
        this.$store.dispatch('players/current/position/watchPosition')
      } else {
        this.$store.dispatch('players/current/position/watchPosition', false)
      }
    },
    onClickAppBarNavIcon () {
      this.toggleNavigationDrawer()
    },
    onClickBottomNavigationEinsIcon () {
      this.toggleBottomSheet()
    }
  }
}
</script>

<style lang="scss">
html, body {
  overflow: hidden !important;
}

#main-content {
  position: absolute ;
  top: 0;
  left: 0;
  padding-left:5px !important;
  padding-right:5px !important;
  width: 100% ;
  height: 100% ;
  overflow: hidden ;
  // background: none;

    #main-content-scroll {
      overflow-y: auto ;
      position: relative;
    }
  }

  .v-btn.v-btn--speed-dial {
    .v-image {
      display: inline-block;
      transition: width 0.2s ease ;
    }
  }

  .v-btn.v-btn--router.v-btn--active .v-btn__content {
    color: var(--v-primary-base) ;
  }

.v-toolbar {
  & > .v-toolbar__extension {
    background:  #616161 ;
  }

  &.theme--light {
    & > .v-toolbar__extension {
      background: #eeeeee ;
    }
  }
}
</style>
