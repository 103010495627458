<template>
  <v-input
      ref="input"
      :rules="rules"
      :value="cInputValue"
  >
    <v-card
        style="width: 100%;"
        @click="onClickCard"
    >
      <v-row
          align="center"
          class="fill-height mb-2"
          justify="center"
          no-gutters
      >
        <v-btn
            class="player"
        >
          <v-icon size="24">
            mdi-camera
          </v-icon>
          upload photo
        </v-btn>
      </v-row>
      <v-img
          v-if="preview"
          :src="preview"
          :aspect-ratio="aspectRatio"
      />
      <eins-api-image
          v-else-if="orSrc"
          #default="{ src }"
          :src="orSrc"
      >
        <v-img
            :src="src"
            :aspect-ratio="aspectRatio"
        />
      </eins-api-image>
    </v-card>
    <eins-image-input-dialog
        ref="inputDialog"
        v-model="overlay"
        :persistent="loading"
    >
      <template #title>
        {{ title }}
      </template>
      <template #default="{ ready }">
        <v-card-actions class="flex-column">
          <v-btn
              block
              color="primary"
              :disabled="!ready"
              @click="onClickSubmit"
          >
            {{ sendLabel }}
          </v-btn>
          <v-btn
              block
              class="mx-0 mt-2"
              outlined
              @click="onClickCancel"
          >
            {{ cancelLabel }}
          </v-btn>
        </v-card-actions>
      </template>
    </eins-image-input-dialog>
  </v-input>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsImageInputDialog from '@/components/input/EinsImageInputDialog'

export default {
  name: 'EinsImageUploadInput',
  components: {
    EinsImageInputDialog,
    EinsApiImage
  },
  props: {
    aspectRatio: {
      type: [ String, Number ],
      default: 1
    },
    orSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    cancelLabel: {
      type: String,
      default: 'cancel'
    },
    sendLabel: {
      type: String,
      default: 'upload'
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    picture: null,
    preview: null,
    internalValue: null,
    overlay: false,
    loading: false
  }),
  computed: {
    cInputValue () {
      return this.picture ?? this.orSrc
    },
    cShowImage () {
      if (this.internalValue !== null && this.internalValue !== '') {
        return this.internalValue
      } else if (this.orSrc !== '') {
        return this.orSrc
      } else {
        return ''
      }
    },
    cImage () {
      if (this.cShowImage === '') {
        return require('@/assets/img/player/unknown-1.png')
      } else {
        return this.cShowImage
      }
    }
  },
  beforeDestroy () {
    this.releasePreview()
  },
  methods: {
    onClickCard () {
      this.openDialog()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.cancel()
    },
    submit () {
      this.loading = true
      const file = this.$refs.inputDialog.getImageFile()
      if (file) {
        this.$emit('upload', file)
      }
      this.$nextTick().then(() => {
        this.closeDialog()
        this.loading = false
      })
    },
    cancel () {
      this.closeDialog()
      if (!this.picture) {
        this.picture = 0
        this.$nextTick().then(() => {
          this.picture = null
        })
      }
    },
    createPreview () {
      this.releasePreview()
      this.preview = URL.createObjectURL(this.picture)
    },
    releasePreview () {
      if (this.preview) {
        URL.revokeObjectURL(this.preview)
        this.preview = null
      }
    },
    openDialog () {
      this.overlay = true
    },
    closeDialog () {
      this.overlay = false
    }
  }
}
</script>

<style scoped>
</style>
