<template>
  <eins-layout-list-items
      :to="`/conversations/${chat.id}`"
      style="background: transparent !important; border: 1px solid grey"
  >
    <template #image>
      <eins-api-image
          #default="{ src }"
          :src="cPicture "
      >
        <v-img
            :src="src"
            aspect-ratio="1"
            height="100%"
        >
          <template #placeholder>
            <v-skeleton-loader
                type="image"
            />
          </template>
        </v-img>
      </eins-api-image>
    </template>

    <template #icons>
      <v-spacer />
      <v-col>
        <v-icon
            v-if="cGroup"
            class="mt-n1"
            color="primary"
        > {{ cGroup.group_type.icon }}</v-icon>

        <eins-icon-friendship
            v-else
            class="mt-n1"
            color="primary"
            :state="cPlayer ? cPlayer.friendship_state : null"
        />
      </v-col>
      <v-spacer />
      <v-spacer />
      <v-col>
        <v-icon
            v-if="!cIconDirections"
            :class="{ 'mt-1 iconColorRed': cIcon }"
        > mdi-chevron-left</v-icon>
        <v-icon
            v-if="cIconDirections"
            :class="{ 'mt-1 iconColorRed': cIcon }"
        > mdi-chevron-right</v-icon>
      </v-col>
      <v-spacer />
    </template>

    <template #title>
      {{ cPlayer ? cPlayer.name : cGroupTitle }}
    </template>
    <template>
      <div>
        <p class="subtitle-2">
          {{ cLatestMessage }}
        </p>
        <p class="xsmall">
          {{ cLatestMessage ? $d(chat.updated_at, 'time') : '' }}
        </p>
      </div>
    </template>
  </eins-layout-list-items>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsLayoutListItems from '@/components/layout/EinsLayoutListItems' // just a call, no further nesting

const regHTML = /<\/?[^>]*>/g
const descriptionMaxLength = 45

export default {
  name: 'EinsChatListItem',
  components: {
    EinsApiImage,
    EinsLayoutListItems,
    EinsIconFriendship
  },
  props: {
    chat: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    getTime: ''
  }),
  computed: {
    cPlayer () { return this.chat.group ? '' : this.chat.contacts[0] },

    cUSer () { return this.$store.getters['conversations/user'] !== this.chat.id },

    cGroup () { return this.chat.group },

    cShow () { return this.chat?.latest_message !== 0 },

    cGroupTitle () { return this.cGroup ? this.cGroup.title : null },

    cGroupOwnerId () { return this.cGroup ? this.cGroup.id : null },

    cPicture () { return this.cGroup ? this.chat?.group?.title_picture_feed : this.chat?.contacts[0]?.title_picture_feed },

    cIcon () { return this.chat?.unread_messages_count },

    cIconDirections () { return this.$store.getters['players/current/id'] !== this.chat?.latest_message?.sender.id },

    cDistance () {
      let distance = this.cPlayer ? this.chat?.contacts[0].distance : this.cGroup?.distance
      let format = 'distance-meter'

      if (distance > 1000) {
        // decimal places: 1 => / Round(d/100)/10
        distance = Math.round(distance / 100) / 10
        format = 'distance-kilometer'
      }
      return {
        value: this.$n(distance, 'decimal'),
        unit: format === 'distance-kilometer' ? 'km' : 'm'
      }
    },
    cLatestMessage () {
      let description = (this.chat?.latest_message?.body || '').replace(regHTML, '')
      if (description && description.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }
      return description
    }
  },

  methods: {
    pad (num) {
      return ('0' + num).slice(-2)
    }
  }
}

</script>
<style lang="scss" scoped>
.v-card__subtitle {
  .v-card__text {
display: flex;
}}
.iconColor{
  color : white
}
.iconColorRed{
  color : rgb(231, 94, 94)
}
.iconShow{
  display:none
}
.xsmall{
  font-size: 11px
}
</style>
