import auth from '@/locales/en/auth'
import notifications from '@/locales/en/notifications'
import businessSectors from '@/locales/en/businessSectors'
import common from '@/locales/en/common'
import countries from '@/locales/en/countries'
import components from '@/locales/en/components'
import deal from '@/locales/en/deal'
import denunciationTypes from '@/locales/en/denunciationTypes'
import error from '@/locales/en/error'
import friendship from '@/locales/en/friendship'
import group from './group'
import groupTypes from '@/locales/en/groupTypes'
import input from '@/locales/en/input'
import inputRules from '@/locales/en/inputRules'
import locales from '@/locales/en/locales'
import medals from '@/locales/en/medals'
import msc from '@/locales/en/msc'
import player from '@/locales/en/player'
import qrCode from '@/locales/en/qrCode'
import quest from '@/locales/en/quest'
import searchBar from '@/locales/en/searchBar'
import timeValue from '@/locales/en/timeValue'
import views from '@/locales/en/views'

export const en = {
  auth,
  notifications,
  businessSectors,
  common,
  countries,
  components,
  deal,
  denunciationTypes,
  error,
  friendship,
  group,
  groupTypes,
  input,
  inputRules,
  locales,
  medals,
  msc,
  player,
  qrCode,
  quest,
  searchBar,
  timeValue,
  views,
  'terms': {
    'chat': 'Chat',
    'group': 'Field',
    'groups': 'Fields',
    'player': 'Player',
    'players': 'Players',
    'quest': 'Challenge',
    'quests': 'Challenges',
    'deal': 'Deal',
    'deals': 'Deals',
    'medal': 'Medal',
    'medals': 'Medals',
    'description': 'Description',
    'private': 'private',
    'common': 'common',
    'nature': 'future',
    'green': 'future',
    'future': 'future',
    'stars': 'stars',
    'star': 'star',
    'point': 'point',
    'points': 'points',
    'service': 'service',
    'product': 'product'
  },
  'time': {
    'day': '{n} day | {n} days',
    'hour': '{n} hour | {n} hours',
    'minute': '{n} minute | {n} minutes'
  },
  'mainmenu': {
    'eins-locale-select': {
      'label': 'Select language'
    },
    'logout': {
      'label': 'logout'
    },
    'main-page': {
      'label': 'Main Page'
    },
    'my-profile': {
      'label': 'My Profile'
    },
    'available-stars': {
      'label': 'Available Stars'
    },
    'impress': {
      'label': 'Impress'
    },
    'scanner': {
      'label': '1APP QR-code scanner'
    },
    '1-indepedent': {
      'label': '1INDEPENDENT'
    }
  }
}
