<template>
  <v-img
      :src="cSrc"
      v-bind="$attrs"
      v-on="$listeners"
  ></v-img>
</template>

<script>
import {
  MxMenuIcon
} from '@/mixins'

export default {
  name: 'EinsIconMessage',
  mixins: [ MxMenuIcon ],
  computed: {
    cSrc () {
      if (this.active) {
        // return require('@/assets/img/icons/ui/action_active.png')
        return require('@/assets/img/icons/ui/star_active.png')
      } else if (this.notification) {
        // return require('@/assets/img/icons/ui/action_notification.png')
        return require('@/assets/img/icons/ui/star_active.png')
      } else {
        // return require('@/assets/img/icons/ui/action.png')
        return require('@/assets/img/icons/ui/star.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
