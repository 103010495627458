<template>
  <v-btn
      :href="`whatsapp://send?text=${cQueryText}`"
      target="_blank"
      color="#1DC143"
      v-bind="$attrs"
  >
    <v-icon left>mdi-whatsapp</v-icon>{{ cText['text'] }}
  </v-btn>
</template>

<script>
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsSocialShareWhatsapp',
  mixins: [
    MxTranslatable
  ],
  props: {
    shareUrl: {
      type: String,
      required: true
    },
    shareText: {
      type: String,
      required: true
    }
  },
  data: () => ({
    $_mx_translatable: {
      paths: [
        'text'
      ],
      params: {}
    }
  }),
  computed: {
    cQueryText () {
      let text = encodeURIComponent(this.shareText)

      if (this.shareUrl) {
        text += '%20%0A' + encodeURIComponent(this.shareUrl)
      }

      return text
    }
  }
}
</script>

<style scoped>

</style>
