<template>
  <eins-quest-list-item
      :quest="quest"
  >
    <template #indicator3>
      <v-icon color="quest">mdi-crown</v-icon>
    </template>
  </eins-quest-list-item>
</template>

<script>
import EinsQuestListItem from '@/components/quest/EinsQuestListItem'
export default {
  name: 'EinsQuestListItemPlayerQuestClosed',
  components: {
    EinsQuestListItem
  },
  props: {
    quest: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
