import { dialog } from '@/plugins/store/app/dialog'

export const app = {
  state: {
    starmenu: {
      open: false
    },
    notifications: {
      open: false
    },
    appVisible: false
  },
  getters: {
    appIsVisible: state => state.appVisible,
    starmenuIsOpen: state => state.starmenu.open,
    notificationsPaneOpen: state => state.notifications.open
  },
  mutations: {
    openStarmenu: state => {
      state.starmenu.open = true
    },
    closeStarmenu: state => {
      state.starmenu.open = false
    },
    toggleStarmenu: state => {
      state.starmenu.open = !state.starmenu.open
    },
    openNotificationsPane: state => {
      state.notifications.open = true
    },
    closeNotificationsPane: state => {
      state.notifications.open = false
    },
    toggleNotificationsPane: state => {
      state.notifications.open = !state.notifications.open
    },
    setNotificationsPaneState: (state, payload) => {
      state.notifications.open = !!payload
    },
    setVisibilityState: (state, payload) => {
      state.appVisible = !!payload
    }
  },
  actions: {
  },
  modules: {
    dialog
  }
}
