<template>
  <v-row class="no-gutters mb-0">
    <v-col
        cols="3"
        :style="`color:${textColor} !important;`"
    >
      <div class="text-body-2 ma-0 mt-0">
        - {{ cBonus }}
      </div>
      <div class="pa-0 mt-n2">
        Points
      </div>
    </v-col>
    <v-col
        cols="9"
        class="ma-0 pa-1 no-gutters"
    >
      <div
          v-for="(point, idx) in cPoints"
          :key="idx"
          class="point"
      >
        <v-icon
            v-if="value >= point"
            style="font-size: 7px; margin: 0;"
            :color="activeIconColor"
        >mdi-square</v-icon>
        <v-icon
            v-else
            style="font-size: 7px;"
            :color="iconColor"
        >mdi-square-outline</v-icon>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { MxHasModel } from '@/mixins'

export default {
  name: 'EinsListItemTimePoints',
  mixins: [
    MxHasModel
  ],
  props: {
    points: {
      type: [ String, Number ],
      default: 60
    },
    perRow: {
      type: [ String, Number ],
      default: null
    },
    shrink: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ],
      default: 0
    },
    wrap: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: undefined
    },
    activeIconColor: {
      type: String,
      default: undefined
    },
    textColor: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    cBonus () {
      return this.value
    },
    cColStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow)) {
        result['width'] = `${100 / perRow}%`
        result['flexBasis'] = 'unset'
        result['textAlign'] = 'left'
      }

      return result
    },
    cContainerStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow) && this.shrink) {
        result['width'] = `${perRow}em`
      }

      return result
    },
    cOverflow () {
      return this.value >= this.points
    },
    cPoints () {
      const result = []
      for (let i = 1; i <= this.points; i++) {
        result.push(i)
      }
      return result
    }
  }
}
</script>

<style scoped>
.point {
  display: flex;
  float: left;
  margin: 0;
  padding: 0;
  width: 7px;
  height: 7px;
}
</style>
