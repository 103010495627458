export default {
  'flow': {
    'application': {
      'name': 'Application',
      'description': 'You need to apply for the @.lower:(terms.deal)'
    },
    'direct': {
      'name': 'Direct',
      'description': 'You dont need to apply for the @.lower:(terms.deal)'
    }
  },
  'status': {
    'open': {
      'owner': '@.lower:(terms.players) can apply for this @.lower:(terms.deal)',
      'foreigner': 'You can apply for this @.lower:(terms.deal)',
      'applicant': 'Please wait till the owner answers your apply'
    },
    'open-direct': {
      'owner': '@.lower:(terms.players) can get this @.lower:(terms.deal)',
      'foreigner': 'You can immediately get this @.lower:(terms.deal)'
    },
    'assigned': {
      'owner': 'The @.lower:(terms.deal) can be accepted from your assignee',
      'assignee': 'You can get this @.lower:(terms.deal)',
      'foreigner': 'You can apply for this @.lower:(terms.deal)',
      'applicant': 'Another player was assigned for the @.lower:(terms.deal)'
    },
    'canceled': {
      'owner': 'You canceled the @.lower(terms.deal).',
      'foreigner': 'The @.lower(terms.deal) has been canceled.'
    },
    'bought': {
      'owner': 'The @.lower:(terms.deal) has been sold.',
      'assignee': '@:(deal.status.done.owner)',
      'applicant': 'The @.lower:(terms.deal) was sold to someone else.'
    }
  },
  'type': {
    'product': '@.lower:(terms.product)',
    'service': '@.lower:(terms.service)'
  },
  'description': '@:(terms.description)',
  'details': {
    'time': {
      'title': 'Time: {0} h / @:(terms.stars) (hours) & @:(terms.points) (minutes)',
      'sub-title': 'Stars for this @.lower:(terms.deal) equal to approximately this time.'
    },
    'flow': {
      'title': 'Handling: {0}'
    },
    'id': {
      'title': 'ID #{0}',
      'sub-title': 'This is the identification number for this @.lower:(terms.deal)'
    },
    'stars': {
      'title': 'You will pay {0} @:(terms.stars)',
      'sub-title': 'This is equal to {0} hours.'
    },
    'points': {
      'title': 'You will pay {0} @:(terms.points)',
      'sub-title': 'This is equal to {0} minutes.'
    },
    'status': {
      'title': '@:(terms.deal) status'
    },
    'group': {
      'sub-title': 'The @.lower:(terms.deal) is offered by this@.lower:(terms.group)'
    },
    'player': {
      'sub-title': 'The @.lower:(terms.deal) is offered by this @.lower:(terms.player)'
    },
    'type': {
      'sub-title': 'This @.lower:(terms.deal) is a {0}'
    }
  },
  'finished': {
    'player': 'This @.lower:(terms.player) has got the @.lower:(terms.deal).',
    'group': 'This @.lower:(terms.group) has got the @.lower:(terms.deal).'
  },
  create: {
    title: {
      label: 'Title',
      required: 'Please enter a title for your @.lower:(terms.deal)',
      min: 'The title must have a length of 3 letters or longer.'
    },
    titlePicture: {
      title: '@:(terms.deal) title picture',
      cancel: 'Cancel',
      submit: 'Set title picture',
      required: 'Please provide a title picture for your @.lower:(terms.deal)'
    },
    description: {
      label: '@.lower:(terms.deal) details',
      required: 'Please provide a description for your @.lower:(terms.deal) ',
      min: 'The description must have a length of at least {0} words.',
      max: 'The description must not exceed a length of {0} characters.'
    },
    value: {
      label: 'Price'
    },
    scope: {
      dialogTitle: 'Does the @:(terms.deal) belong to a @:(terms.group)?',
      listCaption: 'Only @:(terms.group) members',
      voidOption: 'none',
      currentValueLabel: '@:(terms.group)',
      currentValueHintEmpty: 'Everyone can see your @.lower:(terms.deal).',
      currentValueHintSimple: 'The @.lower:(terms.deal) belongs to the selected @.lower:(terms.group). Only members can access it.',
      currentValueHintExtended: 'The @.lower:(terms.deal) belongs to this @.lower:(terms.group). See more options below.',
      submit: 'Change scope'
    },
    public: {
      label: 'Public available',
      explanationTrue: 'Everyone can see the @.lower:(terms.deal)',
      explanationFalse: 'Only members can see the @.lower:(terms.deal)'
    },
    isGroupDeal: {
      label: 'Provide as the @.lower:(terms.group)',
      explanationTrue: 'Provide the @.lower:(terms.deal) as the selected @.lower:(terms.group), every member gets a share.',
      explanationFalse: 'Provide the @.lower:(terms.deal) yourself, only group members can see it.'
    },
    location: {
      addressTitle: 'Set @.lower:(terms.deal) address',
      addressSubmit: 'Set',
      required: 'Please select a position on the map or provide an address.'
    },
    requireApplication: {
      label: 'Require application',
      explanation: 'If set, @.lower:(terms.players) need to apply for the @.lower:(terms.deal)'
    }
  },
  update: {
    title: {
      dialogTitle: 'Change @.lower:(terms.deal) title',
      actions: {
        submit: 'Change title'
      },
      success: {
        title: 'Success!',
        text: 'The title of your @.lower:(terms.deal) was changed from \'{0}\' to \'{1}\'.'
      },
      title: {
        label: 'New title',
        required: 'Please enter a new title',
        min: 'The title needs to have a length of at least 3',
        unchanged: 'The new title is not different from the current title.'
      }
    },
    description: {
      dialogTitle: 'Change @.lower:(terms.deal) description',
      actions: {
        submit: 'Change @.lower:(terms.description)'
      },
      success: {
        title: 'Success!',
        text: 'The @.lower:(terms.description) of your @.lower:(terms.deal) has been updated.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'The length of the @.lower:(terms.description) must not exceed 500.'
      }
    },
    picture: {
      dialogTitle: 'Upload picture',
      success: {
        title: 'Success!',
        text: 'Your @.lower:(terms.quests) title picture was set.'
      },
      actions: {
        submit: 'Upload',
        setPicture: 'Set picture'
      }
    },
    reward: {
      dialogTitle: 'Change @:(terms.deal) price',
      currentValueLabel: 'Price',
      actions: {
        submit: 'Change price'
      },
      success: {
        title: 'Success!',
        text: 'The price for your @:(terms.deal) has been updated.'
      }
    },
    location: {
      address: {
        dialogTitle: 'Set address',
        success: {
          title: 'Address updated',
          text: 'The @.lower:(terms.deal)-address has been updated, we will now recalculate the map position.'
        },
        actions: {
          submit: 'Update address'
        }
      },
      position: {
        success: {
          title: 'Position updated',
          text: 'The @.lower:(terms.deal)-position has been updated, we will now recalculate the address.'
        }
      }
    },
    scope: {
      dialogTitle: 'Does the @:(terms.deal) belong to a @:(terms.group)?',
      listCaption: 'Only @:(terms.group) members',
      voidOption: 'none',
      currentValueLabel: '@:(terms.group)',
      currentValueHintEmpty: 'Everyone can see your @.lower:(terms.deal).',
      currentValueHintSimple: 'The deal belongs to the selected @.lower:(terms.group). Only members can access it.',
      currentValueHintExtended: 'The deal belongs to this @.lower:(terms.group). See more options below.',
      actions: {
        submit: 'Change scope'
      },
      success: {
        title: 'Success!',
        text: 'The group assignment for your @:(terms.deal) has been updated.'
      }
    },
    isGroupDeal: {
      label: 'Provide as the @.lower:(terms.group)',
      explanationTrue: 'Provide the @.lower:(terms.deal) as the selected @.lower:(terms.group), every member gets a share.',
      explanationFalse: 'Provide the @.lower:(terms.deal) yourself, only group members can see it.'
    },
    public: {
      label: 'Public available',
      explanationTrue: 'Everyone can see the @.lower:(terms.deal)',
      explanationFalse: 'Only members can see the @.lower:(terms.deal)',
      explanationDisabled: 'This option is only available when \'@:(deal.update.isGroupDeal.label)\' is active.'
    },
    requireApplication: {
      label: 'Require application',
      explanation: 'If set, @.lower:(terms.players) need to apply for the @.lower:(terms.deal)'
    }
  }
}
