export default {
  captions: {
    caption: 'Choose you new Password',
    confirm: 'Repeat the Password',
    reset: 'Reset your Password'
  },
  hints: {
    passwordFalse: 'Passwords dont match!'
  },
  label: {
    eMail: 'insert your @.lower:(terms.player) email'
  },
  alert: {
    link: 'An email with a link was send to',
    answer: 'Your password has been reset successfully!'
  },
  error: {
    422: 'The given email is unknown to us!',
    429: 'Too many requests! - Wait for a few minutes and try again!',
    else: 'Something went wrong!'
  },
  fields: {
    password: {
      label: 'Enter new password',
      hint: 'Password must contain at least 10 characters',
      rules: 'must contain at least 10 characters'
    },
    passwordConfirmation: {
      label: 'Confirm password',
      hint: 'Password must suit the standards given above',
      rules: [
        ' must contain the standards above'
      ]
    }
  },
  buttons: {
    submit: {
      label: 'Set new Password'
    },
    login: {
      label: 'Back to Login'
    },
    cancel: {
      label: 'cancel'
    },
    set: {
      label: 'set new password'
    }
  },
  PasswordChange: {
    current: 'Current Password',
    new: 'new Password',
    confirm: 'Password Confirmation',
    match: 'Passwords dont match!'
  }
}
