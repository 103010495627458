import {
  RegUuidString
} from '@/utils/regex'

const ViewQuestList = () => import(/* webpackChunkName: "quest-list" */ '@/views/quest/ViewQuestList')
const ViewQuestEdit = () => import(/* webpackChunkName: "quest-edit" */ '@/views/quest/ViewQuestEdit')
const ViewQuestApplications = () => import(/* webpackChunkName: "quest-applications" */ '@/views/quest/ViewQuestApplications')
const ViewQuestListToolbarExtension = () => import(/* webpackChunkName: "quest-list" */ '@/views/quest/ViewQuestListToolbarExtension')
const ViewQuestDetail = () => import(/* webpackChunkName: "quest-detail" */ '@/views/quest/ViewQuestDetail')
const ViewQuestCreate = () => import(/* webpackChunkName: "quest-create" */ '@/views/quest/ViewQuestCreate')

export const quests = {
  path: '/quests',
  name: 'quest-list',
  components: {
    default: ViewQuestList,
    toolbarExtension: ViewQuestListToolbarExtension
  },
  meta: {
    hasToolbarExtension: true,
    hasMapButton: false,
    starmenuButtons: [
      'EinsStarmenuAbMainpage',
      'EinsStarmenuDpbMyQuests',
      'EinsStarmenuAbCreateQuestPrivate',
      'EinsStarmenuAbCreateQuestFuture',
      'EinsStarmenuAbCreateQuestCommon'
    ]
  },
  children: [{
    path: `:id(${RegUuidString})`,
    name: 'quest-detail',
    component: ViewQuestDetail,
    meta: {
      starmenuButtons: [
        'EinsStarmenuAbApproveQuest',
        'EinsStarmenuAbFinishQuest',
        'EinsStarmenuAbStartQuest',
        'EinsStarmenuAbDislike',
        'EinsStarmenuAbShare',
        'EinsStarmenuAbCancelQuest',
        'EinsStarmenuAbDenounce',
        'EinsStarmenuAbApply',
        'EinsStarmenuEbEdit',
        'EinsStarmenuAbCopy',
        'EinsStarmenuDpbApplications',
        'EinsStarmenuDpbDirectMessage',
        'EinsStarmenuAbQrCode'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/applications`,
    name: 'quest-applications',
    component: ViewQuestApplications,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuDpbDetails'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/edit`,
    name: 'quest-edit',
    component: ViewQuestEdit,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
        'EinsStarmenuDpbDetails'
      ]
    }
  }, {
    path: 'create',
    name: 'quest-create',
    component: ViewQuestCreate,
    meta: {
      hasToolbarExtension: false,
      hasMapButton: false,
      starmenuButtons: [
      ]
    }
  }]
}
