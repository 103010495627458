<template>
  <v-btn
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
    <v-progress-circular
        v-if="interval"
        size="16"
        width="1"
        class="ml-2"
        :value="progress"
    />
    <template #loader>
      <slot name="loader"></slot>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'EinsBtnTimed',
  props: {
    duration: {
      type: [ String, Number ],
      default: 2500
    },
    immediately: {
      type: Boolean,
      default: true
    },
    run: {
      type: Boolean,
      default: null
    }
  },
  data: () => ({
    progress: 0,
    interval: null
  }),
  computed: {
    cRun () {
      return this.run !== null ? this.run : this.immediately
    },
    cClickListener () {
      return this.$listeners.click ?? (() => null)
    }
  },
  watch: {
    cRun (value, oldValue) {
      if (value && !oldValue) {
        this.start()
      }
    }
  },
  created () {
    if (this.cRun) {
      this.start()
    }
  },
  methods: {
    start () {
      const start = Date.now()
      this.stop()
      this.interval = setInterval(() => {
        const diff = Date.now() - start
        const progress = (100 * diff) / this.duration
        if (progress > 100) {
          this.progress = 100
          this.cClickListener()
          this.clearInterval()
        } else {
          this.progress = progress
        }
      }, 50)
    },
    clearInterval () {
      window.clearInterval(this.interval)
      this.interval = null
    },
    stop () {
      this.clearInterval()
      this.progress = 0
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
::v-deep .v-progress-circular__overlay {
  transition: none !important
}
</style>
