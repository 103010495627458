import * as EinsStarmenuAb from '@/locales/de/components/EinsStarmenuAb'
import * as EinsStarmenuEb from '@/locales/de/components/EinsStarmenuEb'
import * as EinsStarmenuDpb from '@/locales/de/components/EinsStarmenuDpb'
import * as EinsStarmenuDlg from '@/locales/de/components/EinsStarmenuDlg'
import * as EinsSocialShare from '@/locales/de/components/EinsSocialShare'

import EinsGroupDetail from '@/locales/de/components/EinsGroupDetail'
import EinsFormLogin from '@/locales/de/components/EinsFormLogin'
import EinsPlayerDetail from '@/locales/de/components/EinsPlayerDetail'
import EinsQuestDetail from '@/locales/de/components/EinsQuestDetail'
import EinsMyProfile from '@/locales/de/components/EinsMyProfile'
import EinsModuleLogin from '@/locales/de/components/EinsModuleLogin'
import EinsGroupCreateFormStep1 from '@/locales/de/components/EinsGroupCreateForm/EinsGroupCreateFormStep1'
import EinsLocationInput from '@/locales/de/components/EinsLocationInput'
import EinsPointsInput from '@/locales/de/components/EinsPointsInput'
import AppRegister from '@/locales/de/components/AppRegister'
import EinsModuleRegister from '@/locales/de/components/EinsModuleRegister'
import AppConfirmPassword from '@/locales/de/components/AppConfirmPassword'
import AuthModuleVerifyEmail from '@/locales/de/components/AuthModuleVerifyEmail'

export default {
  AppRegister,
  AuthModuleVerifyEmail,
  EinsModuleRegister,
  AppConfirmPassword,
  ...EinsStarmenuAb,
  ...EinsStarmenuEb,
  ...EinsStarmenuDlg,
  ...EinsStarmenuDpb,
  ...EinsSocialShare,
  EinsFormLogin,
  EinsGroupDetail,
  EinsGroupCreateFormStep1,
  EinsModuleLogin,
  EinsPlayerDetail,
  EinsQuestDetail,
  EinsMyProfile,
  EinsLocationInput,
  EinsPointsInput,

  'EinsGroupDetailsAlert': {
    'text': {
      'stranger': 'Du kannst dem @.lower:(terms.group) beitreten.',
      'applicant': 'Du bist ein Bewerber',
      'applicant_rejected': 'Deine Bewerbung wurde abgelehnt',
      'member': 'Du bist ein Mitglied auf dem @.lower:(terms.group).',
      'admin': 'Nun bist Du Admin',
      'quest': 'Neue @:(terms.quests) warten auf Dich',
      'deal': 'Neue @:(terms.deals) warten auf Dich.',
      'addquest': 'Hast Du Zeit, dann mache die @:(terms.quest)!',
      'adddeal': 'Freue Dich auf neue @:(terms.deals).'
    },
    'textLong': {
      'stranger': 'Um dieser @.lower:(terms.group) beizutreten, gehe zur Maske und klicke auf beitreten. Dann wird eine Bewerbung an den Admin gesendet.',
      'applicant': 'Du hast Dich für diese @.lower:(terms.group) beworben. Bitte warte auf die Antwort des Administrators.',
      'applicant_rejected': 'Deine Bewerbung für das @.lower:(terms.group) wurde abgelehnt. Bei Fragen wende Dich bitte an den Admin.',
      'member': 'Du bist jetzt Mitglied dieser @.lower:(terms.group). Starte Deine @.lower:(terms.quest)!',
      'admin': 'Du bist jetzt ein Administrator dieser @.lower:(terms.group).',
      'quest': 'Es gibt neue @.lower:(terms.quests) für Dich. Schaue sie Dir unbedingt an!',
      'deal': 'Beeil Dich um den @.lower:(terms.deal) zu bekommen',
      'addquest': 'Fange an, eine neue @.lower:(terms.quest) zu erstellen, das dauert nur einen Augenblick!',
      'adddeal': 'Erstelle einen neuen @.lower:(terms.deal), damit Mitglieder belohnt werden.'
    }
  },
  'EinsDealDetailStatesHeader': {
    'caption': {
      'open': 'Bereit',
      'available': 'Erhältlich',
      'completed': 'Getauscht',
      'apply': 'Bewerben',
      'assign': 'Zuweisen',
      'applied': 'Beworben',
      'assigned': 'Zugeordnet',
      'accepted': 'Akzeptiert',
      'closed': 'Getauscht',
      'Abort': 'Zurück'
    },
    'text': {
      'open': 'Noch keine @.lower:(terms.player) hat sich für den @.lower:(terms.deal) beworben.',
      'available': 'Du kannst den @.lower:(terms.deal) anfragen.',
      'completed': 'Der @.lower:(terms.deal) wurde getauscht.',
      'apply': 'Du kannst Dich für den @.lower:(terms.deal) bewerben.',
      'assign': 'Du kannst den @.lower:(terms.deal) für die Bewerber freigeben.',
      'applied': 'Du hast Bewerber für den @.lower:(terms.deal).',
      'assigned': 'Du hast dem Bewerber den @.lower:(terms.deal) zugeordnet.',
      'accepted': 'Tausche jetzt den @.lower:(terms.deal).',
      'closed': 'Der @.lower:(terms.deal) wurde getauscht..'
    },
    'textLong': {
      'open': 'Du kannst diesen @.lower:(terms.deal) bearbeiten, um Bewerber zu erhalten, oder zurückziehen.',
      'available': 'Du kannst diesen @.lower:(terms.deal) sofort tauschen.',
      'completed': 'Du hast den @.lower:(terms.deal) getauscht.',
      'apply': 'Beantrage den @.lower:(terms.deal) und warte, bis der @.lower:(terms.player) Deine Anfrage akzeptiert oder sich bei Dir meldet.',
      'assign': 'Durch die Freigabe des @.lower:(terms.deal) können Bewerber diesen tauschen.',
      'applied': 'Du hast Dich bereits für diesen @.lower:(terms.deal) beworben. Warte, bis der @.lower:(terms.player) Deine Anfrage akzeptiert oder sich bei Dir meldet.',
      'assigned': 'Der @.lower:(terms.deal) wurde zugewiesen. Bitte warte die Antwort des Bewerbers ab oder weise de n@.lower:(terms.deal) weiteren Bewerbern zu.',
      'accepted': 'Deine Bewerbung für den @.lower:(terms.deal) wurde angenommen. Entscheide Dich schnell, ob Sie den @.lower:(terms.deal) wiilst oder nicht. Bei Ablehnung wird Deine Bewerbung gelöscht.',
      'closed': 'Der @.lower:(terms.deal) wurde getauscht.'
    }
  },
  'EinsQuestDetailStatesHeader': {
    'caption': {
      'apply': 'Bewerben',
      'wait': 'Warten',
      'allocate': 'Check Deine neuen Bewerber',
      'applicant': 'Du hast Dich beworben',
      'start': 'Starte die @.lower:(terms.quest)',
      'start_owner': '@.lower:(terms.quest) wurde begonnen',
      'running': '@.lower:(terms.quest) läuft',
      'running_owner': '@.lower:(terms.quest) wird gerade gemacht',
      'completed': 'gemeistert',
      'completed_assignee': 'Bestätigung abwarten',
      'closed': 'Geprüft',
      'Abort': 'Zurück'
    },
    'text': {
      'apply': 'Du kannst Dich für die @.lower:(terms.quest) bewerben.',
      'wait': 'Du hast noch keine Bewerber für die @.lower:(terms.quest).',
      'allocate': 'Du hast neue Bewerber für die @.lower:(terms.quest)',
      'applicant': 'Du hast Dich für die @.lower:(terms.quest) beworben',
      'start': 'Die @.lower:(terms.quest) warte auf Dich. Du kannst loslegen.',
      'start_owner': 'The @.lower:(terms.quest) kann starten und vom @.lower:(terms.player) gemeistert werden.',
      'running': 'Die @.lower:(terms.quest) wurde gestartet',
      'running_owner': 'Die @.lower:(terms.quest) lüuft. Frage den @.lower:(terms.player) für weitere Informationen über den Chat',
      'completed': 'Die @.lower:(terms.quest) wurde abgeschlossen.',
      'completed_assignee': 'Die @.lower:(terms.quest) wartet auf Bestätigung vom Ersteller',
      'closed': 'Die @.lower:(terms.quest) ist abgeschlossen. Du findest in der Liste der gemeisterten @.lower:(terms.quests)'
    },
    'textLong': {
      'apply': ' Gehe dazu zur Maske und klicke auf "Bewerber". Für weitere Informationen bitte den Ersteller über den Chat kontaktieren',
      'wait': 'Es gibt keinen Bewerber für diese @.lower:(terms.quest). Bitte überprüfe die @.lower:(terms.quest) auf Fehler oder zusätzliche Informationen',
      'applicant': 'Bitte warte, bis Deine Bewerbung akzeptiert wird. Wenn Du möchtest, kannst Du auch mit dem @.lower:(terms.player) chatten. Du findest die @.lower:(terms.quest) auf der Liste „Meine @.lower:(terms.quest)',
      'allocate': 'Bitte überprüfe die neuen Bewerber für Deine @.lower:(terms.quest). Es steht Dir frei, diese anzunehmen oder abzulehnen.',
      'start': 'Du kannst die @.lower:(terms.quest) starten!',
      'start_owner': 'Die @.lower:(terms.quest) wartet bis der @.lower:(terms.player) diese gestartet hat.',
      'running': 'Du hast die @.lower:(terms.quest) gestartet. Mache Dich bereit, sie zu meistern!',
      'running_owner': 'Die @.lower:(terms.quest) ist am Laufen. Du kannst warten, bis der @.lower:(terms.player) die @.lower:(terms.quest) beendet oder mit Dir chattet. Sonst schreibe den @.lower:(terms.player) an',
      'completed': 'Großartig. Der @.lower:(terms.player) hat die @.lower:(terms.quest) abgeschlossen. Bitte bestätige diese.',
      'completed_assignee': 'Die @.lower:(terms.quest) wurde von Dir gemeistert. Bitte warte, bis die @.lower:(terms.quest) vom Eigentümer bestätigt wird',
      'closed': 'Glückwunsch!'
    }
  },
  'EinsLayoutExpandableText': {
    'more': 'Mehr...'
  },
  chat: {
    unknownPlayer: 'unbekannter @.lower:(terms.player)'
  }
}
