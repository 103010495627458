<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-account-cancel"
      :text="cText.text"
  />
</template>

<script>
import { RegRoutePlayer } from '@/utils/regex'
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbPlayerBlockages',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute &&
        // only on player routes
        RegRoutePlayer.test(this.$route.name) &&
        (
          this.cOnListRoute ||
          // never on foreign profiles
          this.$route.params.id === this.$store.getters['players/current/id']
        )
    },
    cOnListRoute () {
      // Current route is the player list
      return this.$route.name === 'player-list'
    },
    cTarget () {
      const playerId = this.$route.params.id ?? this.$store.getters['players/current/id']

      return this.cShowBtn ? `/players/${playerId}/blockages` : ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
