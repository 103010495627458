export default {
  labels: {
    orLabel: 'oder',
    success: {
      title: 'Bitte bestätige Deine E-Mail',
      text: '....um alle Funktionen des Spiels zu nutzen. Sie haben eine E-Mail mit einem Bestätigungslink erhalten '
    },
    logging: 'Dein @.lower:(terms.player) wird erstellt....'
  },
  error: {
    invalid: 'Dein Passwort ist ungültig!',
    rate: 'Zu viele Versuche. Bitte später erneut versuchen.',
    email: 'Deine @.lower:(terms.player) E-Mail-Adresse ist bereits registriert',
    unknown: 'Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später erneut.'
  },
  buttons: {
    register: {
      label: 'Erstelle @.lower:(terms.player) und starte die 1APP'
    },
    loginWithApple: {
      label: 'Anmelden mit Apple'
    },
    signInWithGoogle: {
      label: 'Anmelden mit  Google'
    },
    BackToLogin: {
      label: 'Anmelden eines bestehenden @.lower:(terms.players)'
    }
  }
}
