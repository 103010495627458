<template>
  <v-row
      :class="wrap ? '' : 'flex-nowrap'"
      no-gutters
      style="line-height: 1; text-align: center;"
      :style="cContainerStyle"
      :justify="reverse ? 'end' : 'start'"
  >
    <v-col
        v-for="(star, idx) in cStars"
        :key="idx"
        style="position: relative; max-height: 1em;"
        :style="cColStyle"
        class="flex-grow-0"
    >
      <v-icon
          small
          :style="star === 'mdi-star-half-full' && reverse ? 'transform: scaleX(-1)' : ''"
          style="font-size: 18px;"
          :color="star === 'mdi-star-outline' ? cStarColor : cIconColor"
      >{{ star }}</v-icon>
      <v-icon
          v-if="cOverflow && ((idx === 0 && reverse) || (idx + 1 === cStars.length && !reverse))"
          :style="`position: absolute; top: 50%; left: 50%; transform: translate(calc(-50% - ${reverse ? '' : '-'}0.2em), -50%);`"
          style="font-size: inherit;"
      >mdi-star-outline</v-icon>
    </v-col>
    <v-row
        v-if="bonusCaption"
        class="pt-2 text-center"
    >
      <v-col>
        + {{ cBonus }} Stars
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { MxHasModel } from '@/mixins'

export default {
  name: 'EinsTimeStars',
  mixins: [
    MxHasModel
  ],
  props: {
    objectType: {
      type: String,
      default: ''
    },
    bonusCaption: {
      type: Boolean,
      default: false
    },
    stars: {
      type: [ String, Number ],
      default: 8
    },
    perRow: {
      type: [ String, Number ],
      default: null
    },
    shrink: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ],
      default: 0
    },
    wrap: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: undefined
    },
    starColor: {
      type: String,
      default: undefined
    },
    onlySelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cBonus () {
      return this.value / 60
    },
    cColStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow)) {
        result['width'] = `${100 / perRow}%`
        result['flexBasis'] = 'unset'
        result['textAlign'] = 'left'
      }

      return result
    },
    cContainerStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow) && this.shrink) {
        result['width'] = `${perRow}em`
      }

      return result
    },
    cIconColor () {
      if (this.objectType === 'quest') {
        return 'var(--v-quest-base)'
      } else {
        return this.iconColor
      }
    },
    cStarColor () {
      return this.starColor || 'black'
    },
    cOverflow () {
      // return this.value >= this.stars * 60
      return false
    },
    cStars () {
      const result = []

      const stars = Math.round(2 * (this.value / 60)) / 2

      for (let i = 0; i < this.stars; i++) {
        if ((i + 1) <= stars) {
          result.push('mdi-star')
        } else if ((i + 0.5) <= stars) {
          result.push('mdi-star-half-full')
        } else if (!this.onlySelected) {
          result.push('mdi-star-outline')
        }
      }

      if (this.reverse) return result.reverse()
      else return result
    }
  }
}
</script>

<style scoped>
</style>
