export default {
  labels: {
    title: 'Set location',
    subtitle: ''
  },
  fields: {
    street: {
      label: 'Street',
      placeholder: 'enter Street'
    },
    houseNumber: {
      label: 'House Number',
      placeholder: 'enter House Number'
    },
    zipCode: {
      label: 'ZIP - Code',
      placeholder: 'enter ZIP - Code'
    },
    city: {
      label: 'City',
      placeholder: 'enter City'
    },
    country: {
      label: 'Country',
      placeholder: 'enter Country'
    }
  },
  buttons: {
    confirm: {
      label: 'Set adress'
    },
    viaGps: {
      label: 'via GPS'
    },
    manually: {
      label: 'manually'
    },
    cancel: {
      label: 'Cancel'
    }
  },
  messages: {
    success: {
      label: 'Geolocation was successfully determined'
    }
  }
}
