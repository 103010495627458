import Vue from 'vue'
import VueI18n from 'vue-i18n'

// vuetify locales
import {
  en as vuetifyEn,
  de as vuetifyDe
} from 'vuetify/lib/locale'

// project locales
import {
  en,
  de
} from '@/locales'
import {
  numberFormats,
  dateTimeFormats
} from '@/formats'

Vue.use(VueI18n)

en.$vuetify = vuetifyEn
de.$vuetify = vuetifyDe

const defaultLocale = 'en'

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    en, de
  },
  numberFormats,
  dateTimeFormats
})
