<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-map-marker"
      :text="cText.text"
  >
    <template #icon>
      <v-icon
          left
          color="primary"
      >mdi-map-marker</v-icon>
      <v-icon
          left
          color="primary"
      >mdi-google-circles-communities</v-icon>
    </template>
  </eins-starmenu-btn>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'

export default {
  name: 'EinsStarmenuDpbQuestsGroup',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute
    },
    cTarget () {
      if (this.cShowBtn) {
        return `${this.$route.matched[0].path}/${this.$route.params.id}/quests`
      } else {
        return ''
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
