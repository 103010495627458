<template>
  <div style="max-height: 4.5em; overflow: hidden;">
    <div class="text-h6">
      {{ title }}
    </div>
    <v-btn
        icon
        class="float-right"
        @click="onClickChangeDescription"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    {{ currentValue }}
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text v-if="!success">
          <v-form
              ref="form"
              v-model="form.valid"
              :disabled="loading"
          >
            <v-textarea
                v-model="form.description"
                autofocus
                :counter="500"
                :label="inputLabel"
                :rules="cDescriptionRules"
                @input="onInputName"
            />
          </v-form>
        </v-card-text>
        <v-alert
            text
            tile
            type="success"
            transition="expand-transition"
            :value="success"
        >
          <div class="font-weight-bold">
            {{ successMessage.title }}
          </div>
          <div>
            {{ successMessage.text }}
          </div>
        </v-alert>
        <v-expand-transition>
          <v-card-actions
              v-if="!success"
              class="flex-column"
          >
            <v-btn
                block
                color="primary"
                :disabled="!form.valid"
                :loading="loading"
                @click="onClickSubmit"
            >{{ submitLabel }}</v-btn>
            <v-btn
                block
                class="mx-0 mt-2"
                outlined
                :disabled="loading"
                @click="onClickCancel"
            ><i18n path="msc.actions.cancel" /></v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <eins-btn-timed
                block
                color="primary"
                outlined
                :disabled="loading"
                @click="onClickClose"
            >
              <i18n path="msc.actions.close" />
            </eins-btn-timed>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import { objectHasOwnProperty } from '@/utils/object'

export default {
  name: 'EinsEditDescriptionBlock',
  components: {
    EinsBtnTimed
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiParamName: {
      type: String,
      default: 'about'
    },
    apiPath: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    dialogTitle: {
      type: String,
      default: null
    },
    inputLabel: {
      type: String,
      required: true
    },
    ruleMessage: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'max') &&
          typeof value.max === 'string'
    },
    successMessage: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'title') &&
          typeof value.title === 'string' &&
          objectHasOwnProperty(value, 'text') &&
          typeof value.text === 'string'
    },
    submitLabel: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    form: {
      valid: false,
      description: ''
    }
  }),
  computed: {
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cUserDescription () {
      return this.$store.getters['players/current/about']
    },
    cDescriptionLabel () {
      return this.inputLabel
    },
    cDescriptionRules () {
      return [
        value => value.trim().length < 500 || this.ruleMessage.max
      ]
    }
  },
  methods: {
    onClickChangeDescription () {
      this.openDialog()
    },
    onInputName () {
      this.markFormDirty()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiPath, {
        [this.apiParamName]: this.form.description
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    markFormDirty () {
      this.form.dirty = true
    },
    openDialog () {
      this.dialog = true
      this.reset()
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.success = false
      this.form.description = this.currentValue
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
