<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
      v-if="cIcon !== null"
      v-bind="$attrs"
      :style="cStyle"
      v-on="$listeners"
      v-html="cIcon"
  ></div>
  <v-icon
      v-else
      v-bind="$attrs"
      v-on="$listeners"
  >help_outline</v-icon>
</template>

<script>
/* eslint-disable import/no-webpack-loader-syntax */
import medal1 from '!!raw-loader!@/assets/img/icons/medals/1.svg'
import medal2 from '!!raw-loader!@/assets/img/icons/medals/2.svg'
import medal3 from '!!raw-loader!@/assets/img/icons/medals/3.svg'
import medal4 from '!!raw-loader!@/assets/img/icons/medals/4.svg'
import medal5 from '!!raw-loader!@/assets/img/icons/medals/5.svg'
import medal6 from '!!raw-loader!@/assets/img/icons/medals/6.svg'
import medal7 from '!!raw-loader!@/assets/img/icons/medals/7.svg'
import medal8 from '!!raw-loader!@/assets/img/icons/medals/8.svg'
import medal9 from '!!raw-loader!@/assets/img/icons/medals/9.svg'
import medal10 from '!!raw-loader!@/assets/img/icons/medals/10.svg'
import medal11 from '!!raw-loader!@/assets/img/icons/medals/11.svg'
import medal12 from '!!raw-loader!@/assets/img/icons/medals/12.svg'
import medal13 from '!!raw-loader!@/assets/img/icons/medals/13.svg'
import medal14 from '!!raw-loader!@/assets/img/icons/medals/14.svg'
import medal15 from '!!raw-loader!@/assets/img/icons/medals/15.svg'

export default {
  name: 'EinsIconMedal',
  props: {
    type: {
      type: String,
      required: true,
      default: ''
    },
    width: {
      type: [ String, Number ],
      default: 24
    }
  },
  computed: {
    cStyle () {
      let width = this.width
      if (!isNaN(width)) {
        width = `${width}px`
      }

      return {
        width: this.width
      }
    },
    cIcon () {
      const type = this.type
      let medal
      switch (type) {
        case 'private':
          medal = medal1
          break
        case 'common':
          medal = medal2
          break
        case 'green':
          medal = medal3
          break
        case '4':
          medal = medal4
          break
        case '5':
          medal = medal5
          break
        case '6':
          medal = medal6
          break
        case '7':
          medal = medal7
          break
        case '8':
          medal = medal8
          break
        case '9':
          medal = medal9
          break
        case '10':
          medal = medal10
          break
        case '11':
          medal = medal11
          break
        case '12':
          medal = medal12
          break
        case '13':
          medal = medal13
          break
        case '14':
          medal = medal14
          break
        case '15':
          medal = medal15
          break
        default:
          medal = null
      }

      return medal
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep svg {
  color: inherit;
  g path, polygon {
    display:inline;
    fill:currentColor;
  }
}
</style>
