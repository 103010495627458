<template>
  <eins-layout-item-detail-content
      v-model="tab"
      object-type="group-data"
      :tabs="cTabs"
      @oncontainerscroll="onListScroll"
  >
    <template #information>
      <eins-group-details-alert
          v-if="group"
          class="mb-0"
          style="padding-right: 3.25em;"
          dense
          outlined
          :type="cAlertType"
          :value="true"
      />
    </template>

    <template #image>
      <v-row
          no-gutters
          class="flex-nowrap image-overlay"
      >
        <v-col
            class="overflow-hidden flex-grow-1 flex-shrink-0"
            :style="cImageStyle"
        >
          <eins-api-image
              #default="{ src }"
              :src="cPicture"
          >
            <!-- aspect-ratio="1.875"-->
            <v-img
                style="aspect-ratio:1"
                :src="src"
                @click="onClickImage"
            >
              <template #placeholder>
                <v-skeleton-loader
                    tile
                    type="image"
                />
              </template>
            </v-img>
          </eins-api-image>
          <eins-layout-image-overlay
              v-show="cShowImageOverlay"
              :left="true"
              :bottom="true"
          >
            <template #title>
              <!-- <div
                  v-if="group"
                  class="group text-truncate pa-1"
              >
                {{ group.title }}
              </div>
              -->
            </template>
          </eins-layout-image-overlay>
        </v-col>
        <v-col
            v-show="cShowImageOverlay"
            class="groupoff text-center items-center flex-grow-0 flex-shrink-1"
            style="min-width:50px"
        >
          <div>
            <v-icon
                v-if="cGroupType"
            >
              {{ cGroupType.icon }}
            </v-icon>
          </div>

          <div class="mt-2">
            {{ cMeta.members }}
            <v-icon>mdi-account-multiple
            </v-icon>
          </div>
          <div>
            {{ cMeta.quests }}
            <v-icon>
              mdi-map-marker
            </v-icon>
          </div>

          <div>
            {{ cMeta.deals }}
            <v-icon>
              mdi-cart
            </v-icon>
          </div>
          <div
              class="text-h6 text-no-wrap text-left"
              style="margin-top:20px;height:110px;"
          >
            <div style="transform: rotate(90deg);">
              <span class="text-truncate">
                {{ group.title }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
            v-show="!cShowImageOverlay"
            class="flex-grow-1"
        >
          <template>
            <v-row class="pa-2 no-gutters">
              <v-col
                  v-if="group"
                  cols="12"
                  class="groupoff text-truncate pa-1"
              >
                <v-icon
                    v-if="cGroupType"
                    left
                >{{ cGroupType.icon }}</v-icon>{{ group.title }}
              </v-col>
              <v-col
                  cols="4"
                  @click="onClickMembers"
              >
                <v-icon
                    left
                >mdi-account-multiple</v-icon>{{ cMeta.members }}
              </v-col>
              <v-col
                  cols="4"
                  @click="onClickQuests"
              >
                <v-icon
                    left
                >mdi-map-marker</v-icon>{{ cMeta.quests }}
              </v-col>
              <v-col
                  cols="4"
                  @click="onClickDeals"
              >
                <v-icon
                    left
                >mdi-cart</v-icon>{{ cMeta.deals }}
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </template>
    <!-- Member list -->
    <template #tab-members>
      <v-sheet>
        <v-text-field
            v-model="textFilter"
            solo
            hide-details
        >
          <template #label>
            <v-icon
                left
                color="player"
            >
              mdi-magnify
            </v-icon>
            <span class="label-player">
              <i18n path="views.ViewGroupMembers.textFilter.label" />
            </span>
          </template>
          <template #prepend-inner>
          </template>
        </v-text-field>
        <div style="overflow-y: auto; height: 100%;">
          <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
            <v-slide-y-transition>
              <v-progress-linear
                  v-show="cMembersLoading"
                  height="5"
                  indeterminate
              />
            </v-slide-y-transition>
          </div>
          <eins-player-list
              #item="{ item }"
              :players="cMembers"
          >
            <eins-player-list-item-group-member
                :member="item"
                :player-is-owner="cPlayerIsOwner"
                :player-is-admin="cPlayerIsAdmin"
                :group="group"
            />
          </eins-player-list>
        </div>
      </v-sheet>
    </template>
    <!-- Quest list -->
    <template #tab-quests>
      <v-text-field
          v-model="textFilter"
          solo
          hide-details
      >
        <template #label>
          <v-icon
              left
              color="quest"
          >
            mdi-magnify
          </v-icon>
          <span class="label-quest">
            <i18n path="views.ViewGroupQuests.textFilter.label" />
          </span>
        </template>
        <template #prepend-inner></template>
      </v-text-field>
      <div style="overflow-y: auto; height: 100%;padding:0">
        <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
          <v-slide-y-transition>
            <v-progress-linear
                v-show="cQuestsLoading"
                height="5"
                indeterminate
            />
          </v-slide-y-transition>
        </div>
        <eins-quest-list
            :quests="cQuests"
        />
      </div>
    </template>
    <!-- Deals -->
    <template #tab-deals>
      <v-text-field
          v-model="textFilter"
          solo
          hide-details
      >
        <template #label>
          <v-icon
              left
              color="deal"
          >
            mdi-magnify
          </v-icon>
          <span class="label-deal">
            <i18n path="views.ViewGroupDeals.textFilter.label" />
          </span>
        </template>
        <template #prepend-inner>
        </template>
      </v-text-field>
      <div style="overflow-y: auto; height: 100%;padding:0">
        <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
          <v-slide-y-transition>
            <v-progress-linear
                v-show="cDealsLoading"
                height="5"
                indeterminate
            />
          </v-slide-y-transition>
        </div>
        <eins-deal-list
            :deals="cDeals"
        />
      </div>
    </template>
  </eins-layout-item-detail-content>
</template>

<script>
import EinsGroupDetailsAlert from '@/components/group/EinsGroupDetailsAlert'
import EinsLayoutImageOverlay from '@/components/layout/EinsLayoutImageOverlay'
import EinsLayoutItemDetailContent from '@/components/layout/EinsLayoutItemDetailContent'
import EinsApiImage from '@/components/EinsApiImage'
import EinsPlayerList from '@/components/player/EinsPlayerList'
import EinsPlayerListItemGroupMember from '@/components/player/EinsPlayerListItemGroupMember'

import {
  MxTranslatable
} from '@/mixins'
import {
  floorToMagnitude
} from '@/utils/number'
import {
  Membership,
  Icon
} from '@/constants'
import EinsQuestList from '@/components/quest/EinsQuestList'
import EinsDealList from '@/components/deal/EinsDealList'

// import genericPicture1 from '@/assets/img/group/unknown-1.jpeg'
const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-group.gif`

export default {
  name: 'EinsGroupDetailMemberData',
  components: {
    EinsDealList,
    EinsQuestList,
    EinsApiImage,
    EinsLayoutImageOverlay,
    EinsLayoutItemDetailContent,
    EinsPlayerListItemGroupMember,
    EinsPlayerList,
    EinsGroupDetailsAlert
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    group: {
      type: Object,
      default: null
    },
    groupId: {
      type: [ Number, String ],
      default: 0
    }
  },
  data: () => ({
    imageUrl: `https://picsum.photos/375/200`,
    tab: null,
    textFilter: '',
    imageWidth: 100,
    detailDialog: false,
    $_mx_translatable: {
      paths: [
      ],
      params: {
      }
    }
  }),
  computed: {
    cShowImageOverlay () {
      return this.imageWidth > 30
    },
    cTabs () {
      const result = []

      result.push({
        value: 'members',
        icon: Icon.PLAYER
      })

      result.push({
        value: 'quests',
        icon: Icon.QUEST
      })

      result.push({
        value: 'deals',
        icon: Icon.DEAL
      })

      return result
    },
    cAlertType () {
      const membership = this.group?.membership_state

      if (membership === Membership.NONE) {
        const applicants = this.group?.applicants ?? []
        const applicantsRejected = this.group?.applicants_rejected ?? []
        if (applicants.includes(this.$store.getters['players/current/id'])) {
          return applicantsRejected.includes(this.$store.getters['players/current/id'])
            ? 'applicant_rejected'
            : 'applicant'
        } else {
          return 'stranger'
        }
      } else if (membership === Membership.MEMBER) {
        if (this.cMeta.quests > 0) {
          return 'quest'
        } else if (this.cMeta.deals > 0) {
          return 'deal'
        } else {
          return 'member'
        }
      } else if (membership === Membership.ADMIN) {
        if (this.cMeta.quests === 0) {
          return 'addquest'
        } else if (this.cMeta.deals === 0) {
          return 'adddeal'
        } else {
          return 'admin'
        }
      } else {
        return ''
      }
    },
    cPicture () {
      if (this.group) {
        return this.group.title_picture_full ?? genericPicture1
      } else {
        return null
      }
    },
    cImageStyle () {
      return 'max-width:' + this.imageWidth + '%'
    },
    cMeta () {
      const result = {
        members: floorToMagnitude(this.group?.members?.length ?? 0),
        quests: floorToMagnitude(this.group?.quests_open?.length ?? 0),
        questsDone: floorToMagnitude(this.group?.quests_solved?.length ?? 0),
        deals: floorToMagnitude(this.group?.deals_open?.length ?? 0)
      }

      for (const prop in result) {
        if (Object.prototype.hasOwnProperty.call(result, prop)) {
          const value = result[prop]
          result[prop] = this.$n(value, 'compact')

          if (value > 1000) {
            result[prop] = result[prop] + '+'
          }
        }
      }

      return result
    },
    cGroupId () {
      return this.group?.id ?? null
    },
    cPlayerIsAdmin () {
      const adminGroups = this.$store.getters['players/current/groupsAdmin'] ?? []
      return adminGroups.includes(this.group.id)
    },
    cPlayerIsOwner () {
      return this.$store.getters['players/current/id'] === this.group?.owner_id
    },
    cMembers () {
      const textFilterLc = this.textFilter.toLowerCase()
      return this.$store.getters['groups/selected/members/list']
        .filter(el => el.name.toLowerCase().includes(textFilterLc))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    cMembersLoading () {
      return this.$store.getters['groups/selected/members/listLoading']
    },
    cQuests () {
      const textFilterLc = this.textFilter.toLowerCase()
      let quests = this.$store.getters[`groups/selected/quests/list`] ?? []
      quests = quests.filter(el => {
        return this.textFilter?.length === 0 || el.title.toLowerCase().includes(textFilterLc)
      }).sort((a, b) => b.state.localeCompare(a.state))

      return quests
    },
    cQuestsLoading () {
      return this.$store.getters[`groups/selected/quests/listLoading`]
    },
    cDeals () {
      const textFilterLc = this.textFilter.toLowerCase()
      return this.$store.getters[`groups/selected/deals/list`]
        .filter(el => {
          return this.textFilter?.length === 0 || el.title.toLowerCase().includes(textFilterLc)
        })
        .sort((a, b) => b.state.localeCompare(a.state))
    },
    cDealsLoading () {
      return this.$store.getters[`groups/selected/deals/listLoading`]
    },
    cGroupType () {
      const data = this.group?.group_type ?? null
      const key = data.key
      return data
        ? {
          ...data,
          title: this.$t(`groupTypes.${key}.title`),
          description: this.$t(`groupTypes.${key}.description`)
        }
        : null
    }
  },
  watch: {
    immediate: true,
    tab: 'fetchContent'
  },
  methods: {
    fetchContent () {
      switch (this.tab) {
        case 'members': this.loadMemberList()
          break
        case 'quests': this.loadQuestList()
          break
        case 'deals': this.loadDealList()
      }
    },
    loadMemberList () {
      return this.$store.dispatch('groups/selected/members/loadAll')
    },
    loadQuestList () {
      return this.$store.dispatch(`groups/selected/quests/loadAll`)
    },
    loadDealList () {
      return this.$store.dispatch(`groups/selected/deals/loadAll`)
    },
    onClickMembers () {
      this.tab = 'members'
    },
    onClickQuests () {
      this.tab = 'quests'
    },
    onClickDeals () {
      this.tab = 'deals'
    },
    onListScroll (arg) {
      if (arg < this.imageWidth) {
        // this.imageWidth = arg < 20 || isNaN(arg) ? 20 : arg
        this.imageWidth = 20
      }
    },
    onClickImage () {
      this.$router.push(`/groups/${this.group.id}/images`)
    }
  }
}
</script>

<style scoped lang="scss">
.image-overlay {
  border:3px solid var(--v-group-base);
}
.label-player{
  color:var(--v-playeroff-base);
}
.label-quest{
  color:var(--v-questoff-base);
}
.label-deal{
  color:var(--v-dealoff-base);
}
</style>
