<template>
  <v-row class="no-gutters mb-0">
    <v-col
        cols="3"
        class="mt-n1"
        :style="`color:${cIconColor} !important;`"
    >
      <div class="text-subtitle-2 ma-0 mt-1">
        + {{ cBonus }}
      </div>
      <div class="pa-0 mt-n2">
        Stars
      </div>
    </v-col>
    <v-col class="ma-0 pa-0">
      <v-row class="no-gutters text-wrap pr-2 mt-n1">
        <v-col
            v-for="(star, idx) in cStars"
            :key="idx"
            cols="1"
            style="position: relative; max-height: 1em; padding:0 !important;"
            class="flex-grow-0"
        >
          <v-icon
              small
              :style="star === 'mdi-star-half-full' && reverse ? 'transform: scaleX(-1)' : ''"
              style="font-size: 14px;"
              :color="star === 'mdi-star-outline' ? cStarColor : cIconColor"
          >{{ star }}</v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { MxHasModel } from '@/mixins'

export default {
  name: 'EinsListItemTimeStars',
  mixins: [
    MxHasModel
  ],
  props: {
    objectType: {
      type: String,
      default: ''
    },
    bonusCaption: {
      type: Boolean,
      default: true
    },
    bonusCaptionPosition: {
      type: String,
      default: 'left'
    },
    stars: {
      type: [ String, Number ],
      default: 24
    },
    perRow: {
      type: [ String, Number ],
      default: 12
    },
    shrink: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ],
      default: 0
    },
    wrap: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: undefined
    },
    starColor: {
      type: String,
      default: undefined
    }
  },
  computed: {
    cBonus () {
      return this.value / 60
    },
    cColStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow)) {
        result['width'] = `${100 / perRow}%`
        result['flexBasis'] = 'unset'
        result['textAlign'] = 'left'
      }

      return result
    },
    cContainerStyle () {
      const perRow = parseInt(this.perRow)
      const result = {}

      if (!isNaN(perRow) && this.shrink) {
        result['width'] = `${perRow}em`
      }

      return result
    },
    cIconColor () {
      if (this.objectType === 'quest') {
        return 'var(--v-quest-base)'
      } else {
        return this.iconColor
      }
    },
    cStarColor () {
      return this.starColor || 'black'
    },
    cOverflow () {
      // return this.value >= this.stars * 60
      return false
    },
    cStars () {
      const result = []

      const stars = Math.round(2 * (this.value / 60)) / 2

      for (let i = 0; i < this.stars; i++) {
        if ((i + 1) <= stars) {
          result.push('mdi-star')
        } else if ((i + 0.5) <= stars) {
          result.push('mdi-star-half-full')
        } else {
          result.push('mdi-star-outline')
        }
      }

      if (this.reverse) return result.reverse()
      else return result
    }
  }
}
</script>

<style scoped>
</style>
