<template>
  <l-map
      ref="map"
      :center="cMapCenter"
      :zoom="18"
      zoom-snap="0.25"
      :style="cStyle"
      @ready="onMapReady"
      @update:center="onUpdateCenter"
  >
    <l-tile-layer
        :attribution="cSource.attribution"
        :url="cSource.url"
    />
    <slot>
    </slot>
    <l-marker
        :lat-lng="cMapCenter"
    />
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker
} from 'vue2-leaflet'
import {
  Map
} from '@/constants'
import {
  isValidGeolocation
} from '@/utils/geo'
import {
  objectHasOwnProperty
} from '@/utils/object'

export default {
  name: 'EinsInputPosition',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props: {
    value: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'lat') && objectHasOwnProperty(value, 'lon')
    },
    height: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showMap: false,
    mapObject: null,
    internalValue: {
      lat: null,
      lon: null
    }
  }),
  computed: {
    cStyle () {
      const result = {}

      if (this.height) {
        result.height = typeof this.height === 'string' ? this.height : `${this.height}px`
      } else {
        result.height = '350px'
      }

      return result
    },
    cSource () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy;&nbsp;<a class="copy" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      }
    },
    cUserPosition () {
      if (
        !isNaN(this.$store.getters['players/current/latitude']) &&
        !isNaN(this.$store.getters['players/current/longitude'])
      ) {
        return {
          lat: this.$store.getters['players/current/latitude'],
          lon: this.$store.getters['players/current/longitude']
        }
      }

      return null
    },
    cMapCenter () {
      if (isValidGeolocation(this.internalValue)) {
        return [
          this.internalValue.lat,
          this.internalValue.lon
        ]
      } else {
        return [
          Map.CENTER_DEFAULT_LAT,
          Map.CENTER_DEFAULT_LNG
        ]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onUpdateValue'
    }
  },
  methods: {
    onMapReady (map) {
      this.mapObject = map

      map.on('move', (evt) => {
        const mapCenter = map.getCenter()
        this.setInternalValue(mapCenter)
      })

      const disableDragging = disabled => {
        if (disabled) {
          map.dragging.disable()
        } else {
          map.dragging.enable()
        }
      }

      disableDragging(this.disabled)
      this.$watch('disabled', disableDragging)
    },
    onUpdateCenter (center) {
      this.setInternalValue(center)
    },
    onUpdateValue (value) {
      this.applyValue(value)
    },
    setInternalValue (center) {
      this.internalValue = {
        lat: center.lat,
        lon: center.lng
      }
      this.emitInput()
    },
    applyValue (value) {
      this.internalValue = {
        lat: value.lat,
        lon: value.lon
      }
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style scoped>
</style>
