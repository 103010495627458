<template>
  <eins-layout-view-detail>
    <v-card
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;
        overflow:auto"
    >
      <v-card-title class="text-center">
        <h4 style="width:100%">
          {{ $t('views.ViewInformations.caption') }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-dialog
            v-model="dialog1"
            fullscreen
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                tile
                block
                color="primary"
                justify="space-around"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  left
                  class="mr-2"
              >mdi-database-lock</v-icon>
              {{ $t('views.ViewInformations.dataProtection.activator.label') }}
            </v-btn>
          </template>
          <v-card
              class="detail-content"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;overflow:auto"
          >
            <v-card-title class="justify-center">
              <span class="headline">
                {{ $t('views.ViewInformations.dataProtection.dialog.title') }}
              </span>
            </v-card-title>
            <v-card-actions>
              <v-btn
                  color="grey"
                  @click="dialog1 = false"
              >
                {{ $t('views.ViewInformations.dataProtection.dialog.buttons') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              {{ $t('views.ViewInformations.dataProtection.dialog.text') }}
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog2"
            fullscreen
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                tile
                block
                color="primary"
                justify="space-around"
                class="mt-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  left
                  class="mr-2"
              >mdi-help-network-outline</v-icon>
              {{ $t('views.ViewInformations.dataProcessing.activator.label') }}
            </v-btn>
          </template>
          <v-card
              class="detail-content"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;overflow:auto"
          >
            <v-card-title class="justify-center">
              <span class="headline">
                {{ $t('views.ViewInformations.dataProcessing.dialog.title') }}
              </span>
            </v-card-title>
            <v-card-actions>
              <v-btn
                  color="grey"
                  @click="dialog2 = false"
              >
                {{ $t('views.ViewInformations.dataProcessing.dialog.button') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              {{ $t('views.ViewInformations.dataProcessing.dialog.text') }}
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog3"
            fullscreen
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                tile
                block
                color="primary"
                justify="space-around"
                class="mt-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  left
                  class="mr-2"
              >mdi-presentation-play</v-icon>
              {{ $t('views.ViewInformations.gamePlayRules.activator.label') }}
            </v-btn>
          </template>
          <v-card
              class="detail-content"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;overflow:auto"
          >
            <v-card-title class="justify-center">
              <span class="headline">
                {{ $t('views.ViewInformations.gamePlayRules.dialog.title') }}
              </span>
            </v-card-title>
            <v-card-actions>
              <v-btn
                  color="grey"
                  @click="dialog3 = false"
              >
                {{ $t('views.ViewInformations.gamePlayRules.dialog.button') }}
              </v-btn>
            </v-card-actions>
            <v-card-text>
              {{ $t('views.ViewInformations.gamePlayRules.dialog.text') }}
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog4"
            fullscreen
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                tile
                block
                color="primary"
                justify="space-around"
                class="mt-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  left
                  class="mr-2"
              >mdi-image-text</v-icon>
              {{ $t('views.ViewInformations.impress.activator.label') }}
            </v-btn>
          </template>
          <v-card
              class="detail-content"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;overflow:auto"
          >
            <v-card-title class="justify-center">
              <div
                  class="headline"
              >
                {{ $t('views.ViewInformations.impress.dialog.title') }}
              </div>
            </v-card-title>
            <v-card-text>
              {{ $t('views.ViewInformations.impress.dialog.text') }}
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="grey"
                  @click="dialog4 = false"
              >
                {{ $t('views.ViewInformations.impress.dialog.button') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions class="pl-4">
        <v-btn
            color="grey"
            :to="'/'"
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog></v-dialog>
  </eins-layout-view-detail>
</template>

<script>
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
export default {
  name: 'ViewInformations',
  components: { EinsLayoutViewDetail },
  data: () => ({
    dialog1: false,
    dialog2: false,
    dialog3: false,
    dialog4: false
  })
}
</script>

<style scoped>

</style>
