<template>
  <v-text-field
      v-model="internalValue"
      :type="cType"
      v-bind="$attrs"
      @input="onInput"
  >
    <template
        v-if="showIcon"
        #prepend
    >
      <v-icon>{{ cIcon }}</v-icon>
    </template>
    <template
        v-if="allowPlain"
        #append
    >
      <v-icon @click="onClickToggle">{{ cSwitchIcon }}</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'EinsTextFieldPassword',
  props: {
    value: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    allowPlain: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    internalValue: '',
    show: false
  }),
  computed: {
    cType () {
      return this.show ? 'text' : 'password'
    },
    cIcon () {
      return this.show ? 'mdi-form-textbox' : 'mdi-form-textbox-password'
    },
    cSwitchIcon () {
      return !this.show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'setValue'
    }
  },
  methods: {
    onInput () {
      this.emitInput()
    },
    onClickToggle () {
      this.toggle()
    },
    setValue () {
      this.internalValue = this.value
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    toggle () {
      this.show = this.allowPlain ? !this.show : false
    }
  }
}
</script>

<style scoped>

</style>
