<template>
  <v-overlay
      absolute
      opacity="0.75"
      :value="cIsOpen"
      style="width:100%;"
      @click.native="onClickOverlay"
  >
    <div
        class="d-flex flex-column align-center overflow-y-auto"
        style="max-height: 90%;"
    >
      <component
          :is="component"
          v-for="component in components"
          :key="component.name"
      />
    </div>
  </v-overlay>
</template>

<script>
import EinsStarmenuAbAddContent from '@/components/starmenu/EinsStarmenuAbAddContent'
import EinsStarmenuAbApply from '@/components/starmenu/EinsStarmenuAbApply'
import EinsStarmenuAbApproveQuest from '@/components/starmenu/EinsStarmenuAbApproveQuest'
import EinsStarmenuAbBlockPlayer from '@/components/starmenu/EinsStarmenuAbBlockPlayer'
import EinsStarmenuAbCancelDeal from '@/components/starmenu/EinsStarmenuAbCancelDeal'
import EinsStarmenuAbHonorDeal from '@/components/starmenu/EinsStarmenuAbHonorDeal'
import EinsStarmenuAbCancelQuest from '@/components/starmenu/EinsStarmenuAbCancelQuest'
import EinsStarmenuAbChangeGroupOwner from '@/components/starmenu/EinsStarmenuAbChangeGroupOwner'
import EinsStarmenuAbCloseAccount from '@/components/starmenu/EinsStarmenuAbCloseAccount'
import EinsStarmenuAbCopy from '@/components/starmenu/EinsStarmenuAbCopy'
import EinsStarmenuAbCreateDealProduct from '@/components/starmenu/EinsStarmenuAbCreateDealProduct'
import EinsStarmenuAbCreateDealService from '@/components/starmenu/EinsStarmenuAbCreateDealService'
import EinsStarmenuAbCreateGroup from '@/components/starmenu/EinsStarmenuAbCreateGroup'
import EinsStarmenuAbCreateQuestCommon from '@/components/starmenu/EinsStarmenuAbCreateQuestCommon'
import EinsStarmenuAbCreateQuestFuture from '@/components/starmenu/EinsStarmenuAbCreateQuestFuture'
import EinsStarmenuAbCreateQuestPrivate from '@/components/starmenu/EinsStarmenuAbCreateQuestPrivate'
import EinsStarmenuAbDeleteGroup from '@/components/starmenu/EinsStarmenuAbDeleteGroup'
import EinsStarmenuAbDenounce from '@/components/starmenu/EinsStarmenuAbDenounce'
import EinsStarmenuAbDislike from '@/components/starmenu/EinsStarmenuAbDislike'
import EinsStarmenuAbExchangeDeal from '@/components/starmenu/EinsStarmenuAbExchangeDeal'
import EinsStarmenuAbFinishQuest from '@/components/starmenu/EinsStarmenuAbFinishQuest'
import EinsStarmenuAbFollow from '@/components/starmenu/EinsStarmenuAbFollow'
import EinsStarmenuAbGetDeal from '@/components/starmenu/EinsStarmenuAbGetDeal'
import EinsStarmenuAbJoinGroup from '@/components/starmenu/EinsStarmenuAbJoinGroup'
import EinsStarmenuAbLeaveGroup from '@/components/starmenu/EinsStarmenuAbLeaveGroup'
import EinsStarmenuAbMainpage from '@/components/starmenu/EinsStarmenuAbMainpage'
import EinsStarmenuAbQrCode from '@/components/starmenu/EinsStarmenuAbQrCode'
import EinsStarmenuAbRejectDeal from '@/components/starmenu/EinsStarmenuAbRejectDeal'
import EinsStarmenuAbShare from '@/components/starmenu/EinsStarmenuAbShare'
import EinsStarmenuAbStartQuest from '@/components/starmenu/EinsStarmenuAbStartQuest'
import EinsStarmenuDpbApplications from '@/components/starmenu/EinsStarmenuDpbApplications'
import EinsStarmenuDpbDealTemplates from '@/components/starmenu/EinsStarmenuDpbDealTemplates'
import EinsStarmenuDpbDeals from '@/components/starmenu/EinsStarmenuDpbDeals'
import EinsStarmenuDpbDealsClosed from '@/components/starmenu/EinsStarmenuDpbDealsClosed'
import EinsStarmenuDpbDealsFinished from '@/components/starmenu/EinsStarmenuDpbDealsFinished'
import EinsStarmenuDpbDealsGroup from '@/components/starmenu/EinsStarmenuDpbDealsGroup'
import EinsStarmenuDpbDetails from '@/components/starmenu/EinsStarmenuDpbDetails'
import EinsStarmenuDpbDirectMessage from '@/components/starmenu/EinsStarmenuDpbDirectMessage'
import EinsStarmenuDpbGroupConversation from '@/components/starmenu/EinsStarmenuDpbGroupConversation'
import EinsStarmenuDpbGroupDetails from '@/components/starmenu/EinsStarmenuDpbGroupDetails'
import EinsStarmenuDpbGroupMembers from '@/components/starmenu/EinsStarmenuDpbGroupMembers'
import EinsStarmenuDpbMyDeals from '@/components/starmenu/EinsStarmenuDpbMyDeals'
import EinsStarmenuDpbMyGroups from '@/components/starmenu/EinsStarmenuDpbMyGroups'
import EinsStarmenuDpbMyQuests from '@/components/starmenu/EinsStarmenuDpbMyQuests'
import EinsStarmenuDpbPlayerBlockages from '@/components/starmenu/EinsStarmenuDpbPlayerBlockages'
import EinsStarmenuDpbPlayerContacts from '@/components/starmenu/EinsStarmenuDpbPlayerContacts'
import EinsStarmenuDpbPlayerData from '@/components/starmenu/EinsStarmenuDpbPlayerData'
import EinsStarmenuDpbPlayerGroups from '@/components/starmenu/EinsStarmenuDpbPlayerGroups'
import EinsStarmenuDpbQuestTemplates from '@/components/starmenu/EinsStarmenuDpbQuestTemplates'
import EinsStarmenuDpbQuests from '@/components/starmenu/EinsStarmenuDpbQuests'
import EinsStarmenuDpbQuestsGroup from '@/components/starmenu/EinsStarmenuDpbQuestsGroup'
import EinsStarmenuDpbQuestsSolved from '@/components/starmenu/EinsStarmenuDpbQuestsSolved'
import EinsStarmenuEbEdit from '@/components/starmenu/EinsStarmenuEbEdit'
import EinsStarmenuEbVerify from '@/components/starmenu/EinsStarmenuEbVerify'

export default {
  name: 'EinsStarmenu',
  data: () => ({
    hasOpened: false,
    components: {
      EinsStarmenuAbAddContent,
      EinsStarmenuAbApply,
      EinsStarmenuAbApproveQuest,
      EinsStarmenuAbBlockPlayer,
      EinsStarmenuAbCancelDeal,
      EinsStarmenuAbHonorDeal,
      EinsStarmenuAbCancelQuest,
      EinsStarmenuAbChangeGroupOwner,
      EinsStarmenuAbCloseAccount,
      EinsStarmenuAbCopy,
      EinsStarmenuAbCreateDealProduct,
      EinsStarmenuAbCreateDealService,
      EinsStarmenuAbCreateGroup,
      EinsStarmenuAbCreateQuestCommon,
      EinsStarmenuAbCreateQuestFuture,
      EinsStarmenuAbCreateQuestPrivate,
      EinsStarmenuAbDeleteGroup,
      EinsStarmenuAbDenounce,
      EinsStarmenuAbDislike,
      EinsStarmenuAbExchangeDeal,
      EinsStarmenuAbFinishQuest,
      EinsStarmenuAbFollow,
      EinsStarmenuAbGetDeal,
      EinsStarmenuAbJoinGroup,
      EinsStarmenuAbLeaveGroup,
      EinsStarmenuAbMainpage,
      EinsStarmenuAbQrCode,
      EinsStarmenuAbRejectDeal,
      EinsStarmenuAbShare,
      EinsStarmenuAbStartQuest,
      EinsStarmenuDpbApplications,
      EinsStarmenuDpbDealTemplates,
      EinsStarmenuDpbDeals,
      EinsStarmenuDpbDealsClosed,
      EinsStarmenuDpbDealsFinished,
      EinsStarmenuDpbDealsGroup,
      EinsStarmenuDpbDetails,
      EinsStarmenuDpbDirectMessage,
      EinsStarmenuDpbGroupConversation,
      EinsStarmenuDpbGroupDetails,
      EinsStarmenuDpbGroupMembers,
      EinsStarmenuDpbMyDeals,
      EinsStarmenuDpbMyGroups,
      EinsStarmenuDpbMyQuests,
      EinsStarmenuDpbPlayerBlockages,
      EinsStarmenuDpbPlayerContacts,
      EinsStarmenuDpbPlayerData,
      EinsStarmenuDpbPlayerGroups,
      EinsStarmenuDpbQuestTemplates,
      EinsStarmenuDpbQuests,
      EinsStarmenuDpbQuestsGroup,
      EinsStarmenuDpbQuestsSolved,
      EinsStarmenuEbEdit,
      EinsStarmenuEbVerify
    }
  }),
  computed: {
    cIsOpen () {
      return this.$store.getters.starmenuIsOpen
    }
  },
  methods: {
    onClickOverlay () {
      this.closeOverlay()
    },
    closeOverlay () {
      this.$store.commit('closeStarmenu')
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.v-overlay::v-deep {
  & > .v-overlay__content {
    position: absolute;
    bottom: 2.5em;
  }
}

::v-deep {
  .v-btn-starmenu {
    & + .v-btn-starmenu {
      margin-top: 0.5em;
      min-width:300px;
      border:1px solid #b23219;
    }
  }
}
</style>
