export default {
  setNewPassword: {
    caption: 'Need a new Password?',
    text: 'Please enter the current Password, then the new Password and send password.',
    buttons: {
      cancel: 'Cancel',
      submit: 'Send password'
    }
  },
  fields: {
    currentPassword: {
      label: 'current password'
    },
    password: {
      label: 'new password'
    },
    password_confirmation: {
      label: 'confirm new password'
    }
  }
}
