<template>
  <v-img
      v-if="flagExists[cLocale]"
      :src="cUrl"
      contain
      v-bind="$attrs"
  />
  <v-img
      v-else
      :src="require(`@/assets/img/no-flag.png`)"
      contain
      v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'EinsLocaleFlag',
  props: {
    locale: {
      type: String,
      default: null
    },
    current: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      flagExists: {
      }
    }
  },
  computed: {
    cLocale () {
      return this.current ? this.$i18n.locale : this.locale
    },
    cUrl () {
      return `${window.location.origin}${process.env.BASE_URL}locales/flags/${this.cLocale}.png`
    }
  },
  watch: {
    cUrl () {
      this.testFile()
        // eslint-disable-next-line handle-callback-err
        .catch(err => {})
    }
  },
  created () {
    this.testFile()
      // eslint-disable-next-line handle-callback-err
      .catch(err => {})
  },
  methods: {
    testFile (locale) {
      return new Promise((resolve, reject) => {
        if (this.flagExists.hasOwnProperty(this.cLocale)) {
          if (this.flagExists[this.cLocale]) {
            resolve()
          } else {
            reject(new Error(`The flag for ${this.cLocale} was not found`))
          }
        } else {
          this.$set(this.flagExists, this.cLocale || '__null__', false)

          const req = new XMLHttpRequest()

          req.onreadystatechange = () => {
            if (req.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
              if (req.status === 200) {
                this.flagExists[this.cLocale] = true
                resolve()
              } else {
                reject(new Error(`The flag for ${this.cLocale} was not found`))
              }
            }
          }

          req.open('HEAD', this.cUrl)
          req.send()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
