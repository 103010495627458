<template>
  <div class="ma-2">
    <v-icon
        v-if="cPictureType === 'gallery-picture'"
        size="32px"
        color="var(--v-player-base)"
        left
    >mdi-image-plus</v-icon>
    <v-icon
        v-else-if="cPictureType === 'quest-processing-picture'"
        size="32px"
        color="var(--v-quest-base)"
        left
    >mdi-crown</v-icon>
    <v-icon
        v-else-if="cPictureType === 'title-picture'"
        size="32px"
        color="var(--v-group-base)"
        left
    >mdi-google-circles-communities</v-icon>
  </div>
</template>

<script>
export default {
  name: 'EinsGalleryPictureTypeIcon',
  props: {
    pictureType: {
      type: String,
      default: ''
    }
  },
  computed: {
    cPictureType () {
      return this.pictureType || ''
    }
  }
}
</script>

<style scoped>

</style>
