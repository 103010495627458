<template>
  <v-dialog
      :persistent="loading"
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-card class="edit-dialog-content">
      <v-card-title><i18n path="player.update.email.dialogTitle" /></v-card-title>
      <v-expand-transition>
        <v-card-text v-if="!success">
          <i18n path="player.update.email.dialogText" />
          <v-form
              ref="form"
              v-model="form.valid"
          >
            <v-text-field
                v-model="form.data.email"
                class="required"
                :disabled="loading"
                :error-messages="cEmailApiErrors"
                :error="cEmailApiErrors.length > 0"
                :label="cText.emailLabel"
                :rules="cEmailRules"
                required
                :hint="cText.emailHint"
                @input="onInputEmail"
            />
            <eins-text-field-password
                v-model="form.data.password"
                allow-plain
                class="required"
                :disabled="loading"
                :error-messages="cPasswordApiErrors"
                :error="cPasswordApiErrors.length > 0"
                :label="cText.passwordLabel"
                :rules="cPasswordRules"
                @input="onInputPassword"
            />
          </v-form>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          text
          tile
          type="success"
          :value="success"
      >
        <div class="font-weight-bold">
          <i18n path="player.update.email.success.title" />
        </div>
        <div>
          <i18n path="player.update.email.success.text" />
        </div>
      </v-alert>
      <v-expand-transition>
        <v-card-actions
            v-if="!success"
            class="flex-column"
        >
          <v-btn
              block
              color="primary"
              :disabled="!form.valid"
              :loading="loading"
              @click="onClickSubmit"
          ><i18n path="player.update.email.buttons.submit" /></v-btn>
          <v-btn
              block
              class="mx-0 mt-2"
              outlined
              :disabled="loading"
              @click="onClickCancel"
          ><i18n path="player.update.email.buttons.cancel" /></v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <eins-btn-timed
              block
              color="primary"
              outlined
              :disabled="loading"
              @click="onClickCancel"
          >
            <i18n path="player.update.email.buttons.close" />
          </eins-btn-timed>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import { RegEMail } from '@/utils/regex'
import EinsTextFieldPassword from '@/components/input/EinsTextFieldPassword'
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

export default {
  name: 'EinsPlayerEmailChangeDialog',
  components: { EinsBtnTimed, EinsTextFieldPassword },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    internalValue: false,
    loading: false,
    success: false,
    error: false,
    form: {
      valid: true,
      touches: {
        email: false,
        password: false
      },
      data: {
        email: '',
        password: ''
      }
    }
  }),
  computed: {
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cApiValidationErrors () {
      return this.error?.response?.data?.errors ?? {}
    },
    cText () {
      return {
        emailLabel: this.$t('player.update.email.newEmail.label'),
        emailHint: this.$t('player.update.email.newEmail.hint'),
        passwordLabel: this.$t('player.update.email.password.label')
      }
    },
    cEmailRules () {
      return [
        value => !value || RegEMail.test(value) || this.$t('player.update.email.newEmail.errorValid'),
        value => !!value || this.$t('player.update.email.newEmail.errorRequired')
      ]
    },
    cEmailApiErrors () {
      const result = []

      if (!this.form.touches.email && Array.isArray(this.cApiValidationErrors.email)) {
        const errors = this.cApiValidationErrors.email
          .map(error => error.code)
          .filter((code, idx, arr) => idx === arr.indexOf(code)) // unique
        for (const errorCode of errors) {
          switch (errorCode) {
            case 'unique_error':
              result.push(this.$t('player.update.email.newEmail.errorUnique'))
              break
          }
        }
      }

      return result
    },
    cPasswordApiErrors () {
      const result = []

      if (!this.form.touches.password && Array.isArray(this.cApiValidationErrors.password)) {
        const errors = this.cApiValidationErrors.password
          .map(error => error.code)
          .filter((code, idx, arr) => idx === arr.indexOf(code)) // unique
        for (const errorCode of errors) {
          switch (errorCode) {
            case 'password_error':
              result.push(this.$t('player.update.email.password.errorPassword'))
              break
          }
        }
      }

      return result
    },
    cPasswordRules () {
      return [
        value => !!value || this.$t('player.update.email.password.errorRequired')
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onChangeValue'
    },
    internalValue: {
      immediate: true,
      handler: 'onChangeInternalValue'
    }
  },
  methods: {
    onChangeInternalValue (value, oldValue) {
      if (value !== oldValue) {
        this.reset()
      }
    },
    onChangeValue (value, oldValue) {
      if (value !== oldValue) {
        this.internalValue = value
      }
    },
    onInputDialog (value) {
      this.updateModel(value)
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.close()
    },
    onInputEmail () {
      this.form.touches.email = true
    },
    onInputPassword () {
      this.form.touches.password = true
    },
    reset () {
      this.loading = false
      this.resetData()
      this.resetTouches()
      this.resetFormValidation()
      this.success = false
      this.error = false
    },
    resetData () {
      this.form.data.email = ''
      this.form.data.password = ''
    },
    resetTouches () {
      this.form.touches.email = false
      this.form.touches.password = false
    },
    updateUserData () {
      return this.$store.dispatch('players/current/update')
    },
    submit () {
      this.loading = true
      this.error = false
      this.$http.$api.put(`/players/${this.cUserId}/email`, this.form.data)
        .catch(error => {
          this.error = error
        })
        .then(() => this.updateUserData())
        .then(() => {
          this.success = true
        })
        .finally(() => {
          this.resetTouches()
          this.loading = false
        })
    },
    close () {
      this.updateModel(false)
    },
    updateModel (value) {
      this.internalValue = value
      this.$emit('input', this.internalValue)
    },
    validateForm () {
      if (this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    resetFormValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style scoped>

</style>
