<template>
  <eins-layout-item-detail-content
      v-model="tab"
      object-type="deal"
      :tabs="cTabs"
      @oncontainerscroll="onListScroll"
  >
    <template #information>
      <eins-deal-detail-states-header
          v-if="cAlert"
          :state="cAlert"
          :is-owner="cIsOwner"
          :require-application="cRequireApplication"
      />
    </template>

    <template #image>
      <v-row
          no-gutters
          class="flex-nowrap image-overlay"
      >
        <v-col
            class="overflow-hidden flex-grow-1 flex-shrink-0"
            :style="cImageStyle"
        >
          <eins-api-image
              #default="{ src }"
              :src="cPicture"
          >
            <v-img
                :src="src"
                style="aspect-ratio:1;"
            >
              <template #placeholder>
                <v-skeleton-loader
                    tile
                    type="image"
                />
              </template>
            </v-img>
          </eins-api-image>
        </v-col>
        <v-col
            v-show="cShowImageOverlay"
            class="dealoff text-center items-center flex-grow-0 flex-shrink-1"
            style="min-width:50px"
            @click="onClickImageOverlay"
        >
          <div>
            <v-icon v-if="cRequireApplication">mdi-clipboard-check</v-icon>
            <v-icon v-else>mdi-swap-horizontal-bold</v-icon>
          </div>
          <div v-if="cIsGroupDeal">
            <v-icon>{{ cGroup.group_type.icon }}</v-icon>
          </div>
          <div
              v-else
              class="pt-2 pl-2"
          >
            <eins-icon-friendship
                :state="cUserFriendshipState"
                width="34"
            />
          </div>
          <div class="text-truncate pt-2">
            <eins-icon-deal-type
                :type="deal.type"
                center
            />
          </div>
          <div style="max-width:50px;padding:5px;">
            <eins-time-points
                v-model="cTimeBonus"
                icon-color="var(--v-deal-base)"
                bonus-caption
                :per-row="3"
            />
          </div>
        </v-col>
        <v-col
            v-show="!cShowImageOverlay"
            class="flex-grow-1 pl-2 text-h6"
        >
          <v-row
              no-gutters
              class="justify-space-between"
          >
            <v-col cols="12">
              <div class="text-truncate dealoff pa-none">
                <eins-icon-deal-type
                    :type="deal.type"
                    left
                />
                <span class="pt-2">{{ deal.title }}</span>
              </div>
            </v-col>
            <v-col
                class="flex-grow-0 flex-shrink-1"
            >
              <v-icon v-if="cRequireApplication">mdi-clipboard-check</v-icon>
              <v-icon v-else>mdi-swap-horizontal-bold</v-icon>
            </v-col>
            <v-col
                v-if="cIsGroupDeal"
                class="flex-grow-0 flex-shrink-1 text-no-wrap"
            >
              <v-icon>{{ cGroup.group_type.icon }}</v-icon>
            </v-col>
            <v-col
                v-else
                class="flex-grow-0 flex-shrink-1 mt-1"
            >
              <eins-icon-friendship
                  :state="cUserFriendshipState"
                  width="24"
              />
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-0 mt-1 pl-2 pr-5 ">
              <eins-time-points
                  v-model="cTimeBonus"
                  icon-color="var(--v-deal-base)"
                  text-color="white"
                  bonus-caption
                  :per-row="3"
                  wrap
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template #tab-description>
      <div class="row pa-3">
        <span class="ml-3 text-h6 col col-10">{{ deal.title }}</span>
        <div class="ml-4 pa-2 col-11 text-pre-line">
          {{ cDescription }}
        </div>
      </div>
    </template>

    <template #tab-map>
      <eins-map-detail-position
          #popup
          :position="cPosition"
          style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;"
      >
        <eins-map-address-popup v-bind="cAddress" />
      </eins-map-detail-position>
    </template>

    <template #tab-details>
      <!-- Endet?  -->
      <template v-if="cBoughtCustomer">
        <v-list
            dense
            class="pa-0 ma-0"
        >
          <v-list-item class="pa-0 ma-0 list-item">
            <v-list-item-icon class="list-icon deal ma-0">
              <eins-api-image
                  #default="{ src }"
                  class="ml-n2"
                  :src="cBoughtCustomer.title_picture_feed"
              >
                <v-img
                    :src="src"
                    width="3em"
                    @click="onClickCustomerPlayerAvatar"
                >
                  <v-skeleton-loader
                      tile
                      type="image"
                      height="100%"
                  />
                </v-img>
              </eins-api-image>
            </v-list-item-icon>
            <v-list-item-icon class="list-icon deal ma-0 pa-2">
              <eins-icon-friendship
                  width="24"
                  height="24"
                  class="ml-1 mt-1 mr-3"
                  :state="cBoughtCustomerFriendshipState"
              />
            </v-list-item-icon>
            <v-list-item-content class="pl-2">
              <v-list-item-title>
                {{ cBoughtCustomer.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <i18n path="deal.finished.player" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-if="cBoughtCustomerGroup">
        <v-list
            dense
            class="pt-0 pb-0 ml-n2"
        >
          <v-list-item class="list-item pa-0 ma-0">
            <v-list-item-icon class="list-icon deal ma-0">
              <eins-api-image
                  #default="{ src }"
                  :src="cBoughtCustomerGroup.title_picture_feed"
              >
                <v-img
                    :src="src"
                    width="3em"
                    @click="onClickCustomerGroupAvatar"
                >
                  <v-skeleton-loader
                      tile
                      type="image"
                      height="100%"
                  />
                </v-img>
              </eins-api-image>
            </v-list-item-icon>
            <template>
              <v-list-item-icon class="list-icon deal ma-0 ml-2 pa-3">
                <v-icon class="pa-1">
                  {{ cBoughtGroupIcon }} </v-icon>
              </v-list-item-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title>
                {{ cBoughtCustomerGroup.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <i18n path="deal.finished.group" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <eins-layout-detail-list
          object-type="deal"
          :items="cDetailList"
          class="details-block"
      >
        <template #image="{ item }">
          <v-list-item-icon
              v-if="item.key === 'player' && item.avatar"
              class="ma-0"
              @click="onClickPlayerAvatar"
          >
            <eins-api-image
                #default="{ src }"
                :src="item.avatar"
            >
              <v-img
                  :src="src"
                  width="3.0em"
              >
                <v-skeleton-loader
                    tile
                    type="image"
                    height="100%"
                />
              </v-img>
            </eins-api-image>
          </v-list-item-icon>
          <v-list-item-icon
              v-if="item.key === 'group'"
              class="ma-0"
              @click="onClickGroupAvatar"
          >
            <eins-api-image
                v-if="item.avatar"
                #default="{ src }"
                :src="item.avatar"
            >
              <v-img
                  :src="src"
                  width="3.0em"
              >
                <v-skeleton-loader
                    tile
                    type="image"
                    height="100%"
                />
              </v-img>
            </eins-api-image>
          </v-list-item-icon>
        </template>

        <template
            #icon="{ item }"
        >
          <div
              v-if="item.key === 'player'"
          >
            <eins-icon-friendship
                class="ma-0 mt-2"
                width="24px"
                height="24px"
                :state="cUserFriendshipState"
            />
          </div>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </template>
      </eins-layout-detail-list>
    </template>

    <template #tab-new-4>
    </template>
  </eins-layout-item-detail-content>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsIconDealType from '@/components/icons/EinsIconDealType'
import EinsIconFriendship from '@/components/icons/EinsIconFriendship'
import EinsLayoutDetailList from '@/components/layout/EinsLayoutDetailList'
import EinsMapAddressPopup from '@/components/map/EinsMapAddressPopup'
import EinsMapDetailPosition from '@/components/map/EinsMapDetailPosition'

import { DealState } from '@/constants'
import { MxTranslatable } from '@/mixins'
import { googleMapsLinkUrl } from '@/utils/geo'
import EinsLayoutItemDetailContent from '@/components/layout/EinsLayoutItemDetailContent'
import EinsDealDetailStatesHeader from './EinsDealDetailStatesHeader'
import EinsTimePoints from '../time/EinsTimePoints'
const genericPicture1 = `${location.protocol}//${location.host}/img/placeholder/blind-deal.gif`
const genericPicture2 = `${location.protocol}//${location.host}/img/placeholder/blind-group.gif`

export default {
  name: 'EinsDealDetailContent',
  components: {
    EinsTimePoints,
    EinsLayoutItemDetailContent,
    EinsApiImage,
    EinsIconDealType,
    EinsIconFriendship,
    EinsLayoutDetailList,
    EinsMapAddressPopup,
    EinsMapDetailPosition,
    EinsDealDetailStatesHeader
  },
  mixins: [
    MxTranslatable
  ],
  props: {
    deal: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    tab: '',
    detailDialog: false,
    imageWidth: 100,
    $_mx_translatable: {
      paths: [
        'description'
      ]
    }
  }),
  computed: {
    cTabs () {
      const result = []

      result.push({
        value: 'description',
        icon: 'mdi-note-text'
      })

      if (this.cHasValidPosition) {
        result.push({
          value: 'map',
          icon: 'mdi-map'
        })
      }

      result.push({
        value: 'details',
        icon: 'mdi-cart'
      })

      return result
    },
    cHasValidPosition () {
      return this.cPosition.lat !== null &&
          !isNaN(this.cPosition.lat) &&
          this.cPosition.lon !== null &&
          !isNaN(this.cPosition.lon)
    },
    cAlert () {
      const state = this.deal?.state ?? DealState.OPEN
      const applicants = this.deal?.applicants ?? []

      if (state === DealState.OPEN) {
        if (this.cIsOwner) {
          if (
            this.cRequireApplication &&
              applicants.length > 0
          ) {
            return 'assign'
          } else {
            return 'open'
          }
        } else {
          if (applicants.includes(this.$store.getters['players/current/id'])) {
            return 'applied'
          } else {
            return this.cRequireApplication ? 'apply' : 'available'
          }
        }
      } else if (state === DealState.ASSIGNED) {
        if (this.cIsOwner) {
          return 'assigned'
        } else if (this.cIsAssignee) {
          return 'accepted'
        } else {
          return null
        }
      } else if (state === DealState.ENDED) {
        return 'closed'
      } else {
        return null
      }
    },
    cRequireApplication () {
      return this.deal?.require_application ?? true
    },
    cPicture () {
      if (this.deal) {
        return this.deal.title_picture_feed ?? genericPicture1
      } else {
        return null
      }
    },
    cShowImageOverlay () {
      return this.imageWidth > 30
    },
    cImageStyle () {
      return 'max-width:' + this.imageWidth + '%'
    },
    cDescription () { return this.deal?.description ?? null },
    cUserFriendshipState () {
      return this.deal?.player.friendship_state
    },
    cIsGroupDeal () {
      return this.deal?.is_group_deal ?? true
    },
    cGroup () {
      return this.deal?.group ?? null
    },
    cGroupPicture () {
      return this.deal?.group?.title_picture_feed ?? genericPicture2
    },
    cPlayer () {
      return this.deal?.player ?? null
    },
    cTimeBonus () {
      return this.deal?.value ?? 0
    },
    cIsOwner () {
      return this.deal?.user_id === this.$store.getters['players/current/id']
    },
    cIsApplicant () {
      return !this.cIsOwner && (this.deal?.applicants ?? []).includes(this.$store.getters['players/current/id'])
    },
    cIsAssignee () {
      return !this.cIsOwner &&
        (this.deal?.assignments ?? [])
          .some(assignment => assignment.user.id === this.$store.getters['players/current/id'])
    },
    cNumApplicants () {
      return (this.deal?.applicants ?? []).length
    },
    cRequiresApplication () {
      return this.deal?.require_application ?? true
    },
    cStatus () {
      let result = {}

      switch (this.deal?.state) {
        case 'open':
          if (this.cRequiresApplication) {
            if (!this.cIsOwner) {
              if (this.cIsApplicant) {
                result.icon = 'mdi-account-clock'
                result.text = this.$t('deal.status.open.applicant')
              } else {
                result.icon = 'mdi-cart'
                result.text = this.$t('deal.status.open.foreigner')
              }
            } else {
              if (this.cNumApplicants > 0) {
                result.icon = 'mdi-human-greeting'
                result.text = this.$t('deal.status.open-applied.owner')
              } else {
                result.icon = 'mdi-cart'
                result.text = this.$t('deal.status.open.owner')
              }
            }
          } else {
            result.icon = 'mdi-cart'
            if (this.cIsOwner) {
              result.text = this.$t('deal.status.open-direct.owner')
            } else {
              result.text = this.$t('deal.status.open-direct.foreigner')
            }
          }
          break
        case 'assigned':
          result.icon = 'mdi-lock-open-outline'
          if (this.cIsAssignee) {
            result.text = this.$t('deal.status.assigned.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('deal.status.assigned.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('deal.status.assigned.applicant')
          } else {
            result.text = this.$t('deal.status.assigned.foreigner')
          }
          break
        case 'bought':
          result.icon = 'mdi-cart-check'
          if (this.cIsAssignee) {
            result.text = this.$t('deal.status.bought.assignee')
          } else if (this.cIsOwner) {
            result.text = this.$t('deal.status.bought.owner')
          } else if (this.cIsApplicant) {
            result.text = this.$t('deal.status.bought.applicant')
          } else {
            result = null
          }
          break
        case 'canceled':
          result.icon = 'mdi-close-box-outline'
          if (this.cIsOwner) {
            result.text = this.$t('deal.status.canceled.owner')
          } else {
            result.text = this.$t('deal.status.canceled.foreigner')
          }
          break
        default:
          result = null
      }

      return result
    },
    cPosition () {
      return {
        lat: this.deal.latitude ?? this.$store.getters['players/current/lat'] ?? null,
        lon: this.deal.longitude ?? this.$store.getters['players/current/lon'] ?? null
      }
    },
    cAddress () {
      const result = {
        street: (this.deal?.street ?? '').trim(),
        number: (this.deal?.house_number ?? '').trim(),
        zip: (this.deal?.postal_code ?? '').trim(),
        city: (this.deal?.city ?? '').trim(),
        country: (this.deal?.country ?? '').trim()
      }
      result.navLink = googleMapsLinkUrl(this.cPosition.lat, this.cPosition.lon)

      return result
    },
    cBoughtDeal () { return this.deal?.state === 'ended' ?? '' },
    cBoughtCustomerGroup () {
      if (this.cBoughtDeal) {
        return this.deal?.deal_transactions[0].customer_group ?? null
      } else {
        return null
      }
    },
    cBoughtCustomer () {
      if (this.cBoughtDeal) {
        return this.deal?.deal_transactions[0].customer ?? null
      } else {
        return null
      }
    },
    cBoughtCustomerFriendshipState () {
      return this.deal?.deal_transactions[0].customer.friendship_state
    },
    cBoughtGroupIcon () {
      return this.deal?.deal_transactions[0].customer_group.group_type.icon
    },

    cDetailList () {
      const result = []
      const t = (path, params = []) => this.$t(`deal.${path}`, params)

      if (this.cStatus !== null) {
        result.push({
          key: 'status',
          icon: this.cStatus.icon,
          title: this.cText['details.status.title'],
          subtitle: this.cStatus.text
        })
      }

      const flow = this.cRequireApplication ? 'application' : 'direct'
      result.push({
        key: 'handling',
        icon: this.cRequireApplication ? 'mdi-clipboard-check' : 'mdi-swap-horizontal-bold',
        title: t('details.flow.title', [
          this.$t(`deal.flow.${flow}.name`)
        ]),
        subtitle: this.$t(`deal.flow.${flow}.description`)
      })

      const dealTypeName = this.$t(`deal.type.${this.deal?.type ?? 'product'}`)
      result.push({
        key: 'type',
        icon: this.deal?.type === 'product' ? 'mdi-basket' : 'mdi-face-agent',
        title: dealTypeName,
        subtitle: t('details.type.sub-title', [ dealTypeName ])
      })

      if (this.cTimeBonus > 30) {
        const stars = Math.round((this.cTimeBonus / 60) * 10) / 10
        result.push({
          key: 'time',
          icon: 'mdi-star',
          title: t('details.stars.title', [ stars ]),
          subtitle: t('details.stars.sub-title', [ stars ])
        })
      } else {
        result.push({
          key: 'time',
          icon: 'mdi-star',
          title: t('details.points.title', [ this.cTimeBonus ]),
          subtitle: t('details.points.sub-title', [ this.cTimeBonus ])
        })
      }
      result.push({
        key: 'id',
        icon: 'mdi-cart',
        title: t('details.id.title', [ this.deal?.id ?? 0 ]),
        subtitle: t('details.id.sub-title')
      })
      if (this.cIsGroupDeal) {
        result.push({
          key: 'group',
          icon: this.cGroup?.group_type.icon,
          avatar: this.cGroupPicture,
          title: this.cGroup.title,
          subtitle: t('details.group.sub-title')
        })
      } else {
        let subtitle = t('details.player.sub-title')
        let affectedByBlockage = false
        if (this.cPlayer.has_blocked_authenticated_user) {
          subtitle = this.$t('player.block.attribution.hasBlocked')
          affectedByBlockage = true
        } else if (this.cPlayer.is_blocked_by_authenticated_user) {
          subtitle = this.$t('player.block.attribution.isBlocked')
          affectedByBlockage = true
        }

        result.push({
          key: 'player',
          icon: null,
          avatar: !affectedByBlockage ? this.cPlayer?.title_picture_feed : null,
          title: this.cPlayer.name,
          subtitle
        })
      }
      return result
    }
  },
  methods: {
    onClickImageOverlay () {
      this.goToDetailsTab()
    },
    onClickPlayerAvatar () {
      if (!this.cPlayer.has_blocked_authenticated_user && !this.cPlayer.is_blocked_by_authenticated_user) {
        this.$router.push({
          name: 'player-detail',
          params: {
            id: this.cPlayer.id
          }
        })
      }
    },
    onClickGroupAvatar () {
      this.$router.push({
        name: 'group-detail',
        params: {
          id: this.cGroup.id
        }
      })
    },
    onClickCustomerPlayerAvatar () {
      this.$router.push({
        name: 'player-detail',
        params: {
          id: this.cBoughtCustomer.id
        }
      })
    },
    onClickCustomerGroupAvatar () {
      this.$router.push({
        name: 'group-detail',
        params: {
          id: this.cBoughtCustomerGroup.id
        }
      })
    },
    goToDetailsTab () {
      this.tab = 'details'
    },
    onListScroll (arg) {
      if (arg < this.imageWidth) {
        // this.imageWidth = arg < 20 || isNaN(arg) ? 20 : arg
        this.imageWidth = 20
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-overlay {
  border:3px solid var(--v-deal-base);
}
.details-block {
}
.list-item {
  border:1px solid var(--v-deal-base) !important;
  border-bottom:0 !important;
}
.list-icon{
  &.deal {
    background: var(--v-dealoff-base) !important;
    height:48px;width:48px;
    padding-left:10px !important;
    text-align: center !important;
  }
}
</style>
