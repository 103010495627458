<template>
  <div>
    <v-row
        align="center"
        class="text-h6 flex-nowrap"
        justify="space-between"
    >
      <v-col>
        <v-row
            align="center"
            no-gutters
        >
          <v-col class="flex-grow-0">
            {{ currentValueLabel }}:&nbsp;
          </v-col>
          <template v-if="cSelectedGroup">
            <v-col class="flex-grow-0">
              <v-icon left>{{ cSelectedGroup.group_type.icon }}</v-icon>
            </v-col>
            <v-col>
              {{ cSelectedGroup.title }}
            </v-col>
          </template>
          <template v-else-if="voidOption">
            <v-col class="flex-grow-0">
              <v-icon
                  v-if="voidOption.icon"
                  left
              >{{ voidOption.icon }}</v-icon>
            </v-col>
            <v-col>
              {{ voidOption.label }}
            </v-col>
          </template>
          <template v-else>
            <v-col>
              &horbar;
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn
            icon
            @click="onClickChange"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div
        v-if="currentValueHint"
        class="text-caption grey--text text--darken-1"
    >
      {{ currentValueHint }}
    </div>
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text
            v-if="!success"
            class="overflow-y-auto"
            style="max-height: 50vh"
        >
          <v-form
              ref="form"
              v-model="form.valid"
              :disabled="loading"
          >
            <eins-input-group-scope
                v-model="form.value"
                :list-caption="listCaption"
                :void-option="voidOption"
            />
          </v-form>
        </v-card-text>
        <v-alert
            text
            tile
            type="success"
            transition="expand-transition"
            :value="success"
        >
          <div class="font-weight-bold">
            {{ successMessage.title }}
          </div>
          <div>{{ successMessage.text }}</div>
        </v-alert>
        <v-expand-transition>
          <v-card-actions
              v-if="!success"
              class="flex-column"
          >
            <v-btn
                block
                color="primary"
                :disabled="!form.valid || cValueIsUnchanged"
                :loading="loading"
                @click="onClickSubmit"
            >{{ submitLabel }}</v-btn>
            <v-btn
                block
                class="mx-0 mt-2"
                outlined
                :disabled="loading"
                @click="onClickCancel"
            ><i18n path="msc.actions.cancel" /></v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <eins-btn-timed
                block
                color="primary"
                outlined
                :disabled="loading"
                @click="onClickClose"
            >
              <i18n path="msc.actions.close" />
            </eins-btn-timed>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import EinsInputGroupScope from '@/components/input/EinsInputGroupScope'

export default {
  name: 'EinsEditGroupScopeBlock',
  components: {
    EinsInputGroupScope,
    EinsBtnTimed
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiPath: {
      type: String,
      required: true
    },
    apiParam: {
      type: String,
      required: true
    },
    currentValue: {
      validator: value => value === null || typeof value === 'string',
      required: true
    },
    currentValueLabel: {
      type: String,
      required: true
    },
    currentValueHint: {
      type: String,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    successMessage: {
      type: Object,
      required: true
    },
    listCaption: {
      type: String,
      default: null
    },
    membershipState: {
      type: Array,
      default: () => []
    },
    voidOption: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    form: {
      valid: false,
      value: 0
    }
  }),
  computed: {
    cValueIsUnchanged () {
      return this.currentValue === this.form.value
    },
    cSelectedGroup () {
      return this.$store.getters['players/current/groups/items']
        .find(group => group.id === this.currentValue) ?? null
    }
  },
  methods: {
    onClickChange () {
      this.openDialog()
    },
    onInputName () {
      this.markFormDirty()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiPath, {
        [this.apiParam]: this.form.value
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    openDialog () {
      this.dialog = true
      this.reset()
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.success = false
      this.form.value = this.currentValue
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
