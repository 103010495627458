export const EinsStarmenuDlgApplication = {
  title: 'Bewerben',
  message: {
    label: 'Nachricht',
    hint: 'Optional'
  },
  applyAs: {
    label: 'Bewerbe Dich als',
    hintGroup: 'Du bewirbst Dich im Namen des ausgewählten @.lower:(terms.groups) ',
    hintMyself: 'Du bewirbst Dich selbst als @.lower:(terms.player) und nicht im Namen einer @.lower:(terms.group) ',
    myself: 'Ich selbst'
  },
  error: {
    title: 'Etwas ging schief!',
    '409': 'Du hast Dich bereits beworben \'{0}\'!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!',
    balance: 'Dein Guthaben auf Deinem @.lower:(terms.players)-Konto reicht nicht aus, um diesen @.lower:(terms.deal) zu bekommen'

  },
  btn: {
    submit: 'Bewerben',
    cancel: 'Ich habe meine Meingung geändert',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgJoined = {
  text: 'Eefolg!',
  btn: {
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgLeft = {
  text: 'Wir sind traurig, dass Du gehst. Auf Wiedersehen!',
  btn: {
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgLeaveGroup = {
  title: 'Verlassen des @.lower:(terms.group)s  \'{0}\'',
  text: 'Bist du sicher dass du gehen willst? ' +
      'Du verlierst wahrscheinlich den Zugriff auf @.lower:(terms.quests) und @.lower:(terms.deals) in der @.lower:(terms.group) .',
  cantLeave: {
    title: 'Du kannst die @.lower:(terms.group) nicht velassen !',
    text: 'Du bist der Eigentümer der @.lower:(terms.group) . Bitte gebe diese Verantwortung an einen anderen Admin ab, bevor Du gehst!'
  },
  success: {
    title: 'Aus Wiedersehen!',
    text: 'Wir sind traurig, dass Du gehst. Auf Wiedersehen!'
  },
  btn: {
    close: 'Schließen',
    submit: 'Verlassen',
    ok: 'Ok'
  }
}

export const EinsStarmenuDlgError = {
  text: 'Etwas ging schief!',
  btn: {
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgDenunciation = {
  title: 'Melden \'{0}\'',
  error: {
    title: 'Etwas ging schief!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!'
  },
  btn: {
    submit: 'Melden',
    cancel: 'Egal!',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgCloseAccount = {
  title: '@.lower:(terms.player) Konto dauerhaft löschen',
  text: 'Ihr Konto wird gelöscht.' +
    'Deinen @.lower:(terms.player) wirst Du nicht mehr sehen oder finden können.' +
    ' Egal, Deine abgeschlossenen @.lower:(terms.quests) und @.lower:(terms.deals) bleiben',
  error: {
    title: 'Etwas ging schief!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!'
  },
  cantLeave: {
    title: 'Du kannst nicht gehen',
    text: 'Du bist der Eigentümer der @.lower:(terms.group) . Bitte gebe diese Verantwortung an einen anderen Admin ab, bevor Du gehst!'
  },
  btn: {
    submit: 'Lösche Deinen Account',
    cancel: 'Ich habe meine Meinung geändert',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgShare = {
  title: 'Teilen...'
}

export const EinsStarmenuDlgRejectDeal = {
  title: 'Zurücktreten vom @.lower:(terms.deal) ',
  btn: {
    submit: 'Zurücktreten',
    cancel: 'Ich habe meine Meinung geändert',
    close: 'Schließen',
    info: 'Bist Du Dir sicher, den @.lower:(terms.deal) abzulehen ',
    error: 'Fehler!'
  },
  error: {
    '404': 'Der @.lower:(terms.deal)  wurde nicht gefunden',
    '403': 'Es ist Dir nicht erlaubt. den @.lower:(terms.deal) zu löschen ',
    409: 'Den @.lower:(terms.deal) hast du bereits erhalten',
    title: 'Das sit ein Fehler',
    default: 'Etwas ging schief!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!'
  }
}

export const EinsStarmenuDlgCancelDeal = {
  title: 'Abbrechen des @.lower:(terms.deals) ',
  text: 'Du kannst diese Aktion nicht rückgängig machen!',
  success: 'Der @.lower:(terms.deal) wurde gelöscht',
  error: {
    '404': 'Der @.lower:(terms.deal) wurde nicht gefunden',
    '403': 'Es ist Dir nicht erlaubt, den @.lower:(terms.deal) zu löschen',
    default: 'Etwas ging schief!'
  },
  btn: {
    submit: 'Abbrechen des @.lower:(terms.deals) ',
    cancel: 'Ich habe meine Meinung geändert',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgLinkDeal = {
  title: '@.upper:(terms.deal) einlösen',
  text: 'Click auf den @.upper:(terms.deal)-Code kopiert diesen in die Zwischenablage.',
  success: 'Der @.upper:(terms.deal)-Code wurde in die Zwischenablage kopiert.',
  btn: {
    submit: '@.upper:(terms.deal)-Code jetzt einlösen',
    cancel: '@.upper:(terms.deal)-Code später einlösen',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgCancelQuest = {
  title: 'Abbrechen der @.lower:(terms.quest) ',
  text: 'Du kannst diese Aktion nicht rückgängig machen!',
  success: 'Die @.lower:(terms.quest) wurde gelöscht',
  error: {
    '404': 'Die @.lower:(terms.quest) wurde nicht gefunden',
    '403': 'Es ist Dir nicht erlaubt, den @.lower:(terms.quest) zu löschen',
    default: 'Etwas ging schief!'
  },
  btn: {
    submit: 'Abbrechen der @.lower:(terms.quest) ',
    cancel: 'Ich habe meine Meinung geändert',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgFinishQuest = {
  title: 'Schließe die @.lower:(terms.quest) ab',
  text: 'Informiere \'{0}\', dass Du die @.lower:(terms.quest) gemeistert hast! Bitte mache ein Foto für  \'{0}\'',
  success: 'Die @.lower:(terms.quest)  ist nun gemeistert, \'{0}\' wurde informiert!',
  error: {
    '404': 'Die @.lower:(terms.quest)  wurde nicht gefunden',
    '403': 'Du darfst die @.lower:(terms.quest) nicht beenden.',
    default: 'Etwas ist schief gelaufen!',
    picture: 'Das Bild konnte nicht gespeichert werden.'
  },
  noPicture: {
    text: 'Möchtest Du wirklich ohne Bild fortfahren? @.lower:(terms.player) wird nicht sehen, was Du erreicht hast!',
    btn: 'Ich bin mir sicher!'
  },
  btn: {
    submit: 'Meistere die @.lower:(terms.quest) ',
    cancel: 'Nein noch nicht',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgGetDeal = {
  title: 'Erhalten',
  message: {
    label: 'Nachricht',
    hint: 'Optional'
  },
  applyAs: {
    label: 'Erhalten als',
    hintGroup: 'Du tauschst den @.lower:(terms.deal) im Auftrag des @.lower:(terms.group) ',
    hintMyself: 'Du tauschst den @.lower:(terms.deal) für Dich. ',
    myself: 'Myself'
  },
  error: {
    title: 'Etwas ist schief gelaufen!',
    '409': 'Du hast den \'{0}\' @.lower:(terms.deal) bereits erhalten!',
    unknown: 'Ein unbekannter Fehler ist aufgetreten!',
    balance: 'Das Guthaben auf Deinem @.lower:(terms.players)-Konto reicht nicht aus, um den @.lower:(terms.deal) zu tauschen.'
  },
  btn: {
    submit: 'Tauschen',
    cancel: 'Nein jetzt nicht',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgExchangeDeal = {
  title: 'Tauschen',
  message: {
    label: 'Nachricht',
    hint: 'Optional'
  },
  error: {
    title: 'Etwas ist schief gelaufen!',
    '409': 'Du hast den \'{0}\' @.lower:(terms.deal) bereits erhalten!',
    unknown: 'Etwas ist schief gelaufen',
    balance: 'Das Guthaben auf Deinem @.lower:(terms.players)-Konto reicht nicht aus, um den @.lower:(terms.deal) zu tauschen.'
  },
  btn: {
    submit: 'Tausche @.lower:(terms.deal)',
    cancel: 'Nein nicht jetz',
    close: 'Schließen'
  }
}

export const EinsStarmenuDlgQrCode = {
  title: 'QR-Code',
  text1: 'Scanne den QR-Code mit dem 1APP-QR-Reader',
  text2: 'Scanne den QR-Code mit Deinem Handy, um direkt dorthin zu gelangen',
  close: 'Schließen'
}
