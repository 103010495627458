import { RegUuidString } from '@/utils/regex'

const ViewGroupEdit = () => import(/* webpackChunkName: "group-edit" */ '@/views/group/ViewGroupEdit')
const ViewGroupList = () => import(/* webpackChunkName: "group-list" */ '@/views/group/ViewGroupList')
const ViewGroupListToolbarExtension = () => import(/* webpackChunkName: "group-list" */ '@/views/group/ViewGroupListToolbarExtension')
const ViewGroupDetail = () => import(/* webpackChunkName: "group-detail" */ '@/views/group/ViewGroupDetail')
const ViewGroupMembers = () => import(/* webpackChunkName: "group-members" */ '@/views/group/ViewGroupMembers')
const ViewGroupApplications = () => import(/* webpackChunkName: "group-applications" */'@/views/group/ViewGroupApplications')
const ViewGroupDeals = () => import(/* webpackChunkName: "group-deals" */'@/views/group/ViewGroupDeals')
const ViewGroupDealsClosed = () => import(/* webpackChunkName: "group-deals" */'@/views/group/ViewGroupDealsClosed')
const ViewGroupQuests = () => import(/* webpackChunkName: "group-quests" */'@/views/group/ViewGroupQuests')
const ViewGroupQuestsClosed = () => import(/* webpackChunkName: "group-quests" */'@/views/group/ViewGroupQuestsClosed')
const ViewGroupCreate = () => import(/* webpackChunkName: "group-applications" */'@/views/group/ViewGroupCreate')
const ViewGroupVerify = () => import(/* webpackChunkName: "group-applications" */'@/views/group/ViewGroupVerify')
const ViewGroupImages = () => import(/* webpackChunkName: "group-images" */'@/views/group/ViewGroupImages')

export const groups = {
  path: '/groups',
  name: 'group-list',
  components: {
    default: ViewGroupList,
    toolbarExtension: ViewGroupListToolbarExtension
  },
  meta: {
    hasToolbarExtension: true,
    hasMapButton: false,
    starmenuButtons: [
      'EinsStarmenuDpbMyGroups',
      'EinsStarmenuAbMainpage',
      'EinsStarmenuAbFoundGroup'
    ]
  },
  children: [{
    // ViewGroupDetail will be rendered inside ViewGroupList's <router-view>
    // when /groups/:id/description is matched
    path: `:id(${RegUuidString})`,
    name: 'group-detail',
    component: ViewGroupDetail,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbApplications',
        'EinsStarmenuDpbDealsFinished',
        'EinsStarmenuAbShare',
        'EinsStarmenuAbAddContent',
        'EinsStarmenuAbLeaveGroup',
        'EinsStarmenuAbJoinGroup',
        'EinsStarmenuAbDenounce',
        'EinsStarmenuAbApply',
        'EinsStarmenuAbQrCode',
        'EinsStarmenuEbEdit'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/edit`,
    name: 'group-edit',
    component: ViewGroupEdit,
    meta: {
      starmenuButtons: [
        'EinsStarmenuAbChangeGroupOwner',
        'EinsStarmenuEbVerify',
        'EinsStarmenuAbDeleteGroup',
        'EinsStarmenuDpbDetails'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/verify`,
    name: 'group-verify',
    component: ViewGroupVerify,
    meta: {
      starmenuButtons: [
      ]
    }
  }, {
    path: `:id(${RegUuidString})/members`,
    name: 'group-members',
    component: ViewGroupMembers,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbGroupApplications',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/applications`,
    name: 'group-applications',
    component: ViewGroupApplications,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/deals`,
    name: 'group-deals',
    component: ViewGroupDeals,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbGroupApplications',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/deals/closed`,
    name: 'group-deals-finished',
    component: ViewGroupDealsClosed,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbGroupConversation'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/quests`,
    name: 'group-quests',
    component: ViewGroupQuests,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbQuestsSolved',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbGroupApplications',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/quests/closed`,
    name: 'group-quests-finished',
    component: ViewGroupQuestsClosed,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails',
        'EinsStarmenuDpbGroupConversation',
        'EinsStarmenuDpbGroupApplications',
        'EinsStarmenuDpbDealsFinished'
      ]
    }
  }, {
    path: `:id(${RegUuidString})/images`,
    name: 'group-images',
    component: ViewGroupImages,
    meta: {
      starmenuButtons: [
        'EinsStarmenuDpbDetails',
        'EinsStarmenuDpbGroupDetails'
      ]
    }
  }, {
    path: 'create',
    name: 'group-create',
    component: ViewGroupCreate,
    meta: {
      starmenuButtons: [
      ]
    }
  }]
}
