<template>
  <eins-starmenu-btn
      v-show="cShowBtn"
      :to="cTarget"
      icon="mdi-cart"
      :text="cText.text"
  >
    <template #icon>
      <v-icon
          left
          color="primary"
      >mdi-cart-check</v-icon>
    </template>
  </eins-starmenu-btn>
</template>

<script>
import { MxStarmenuBtn, MxTranslatable } from '@/mixins'
import EinsStarmenuBtn from '@/components/starmenu/EinsStarmenuBtn'
import { Entity } from '@/constants'

export default {
  name: 'EinsStarmenuDpbDealsFinished',
  components: {
    EinsStarmenuBtn
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      if (this.cArea === Entity.PLAYER) {
        return this.cBelongsToRoute && (this.cPlayerIsCurrentPlayer)
      } else if (this.cArea === Entity.GROUP) {
        return this.cBelongsToRoute && (this.cCurrentPlayerIsGroupMember)
      } else {
        return false
      }
    },
    cPlayerRoute () {
      return this.$route.name === 'player-deals-closed'
    },
    cPlayerIsCurrentPlayer () {
      return this.$store.getters['players/selected/player']?.id === this.$store.getters['players/current/id']
    },
    cCurrentPlayerIsGroupMember () {
      return this.$store.getters['groups/selected/group']?.membership_state !== 'none'
    },
    cTarget () {
      if (this.cShowBtn && this.cPlayerRoute) {
        return `${this.$route.matched[0].path}/${this.$route.params.id}/deals/closed`
      } else {
        return `${this.$route.matched[0].path}/${this.$route.params.id}/deals/closed`
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
