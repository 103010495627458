<template>
  <v-list-item
      ripple
      @click="onClickItem"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ cCurrentLanguage.label }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <i18n path="player.update.language.label" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar
        right
        rounded="0"
    >
      <eins-locale-flag :locale="cCurrentLanguage.locale" />
    </v-list-item-avatar>
    <v-dialog
        v-model="dialog"
        :persistent="loading"
    >
      <v-card class="edit-dialog-content">
        <v-card-title><i18n path="player.update.language.dialogTitle" /></v-card-title>
        <v-list v-if="!success">
          <v-list-item-group
              v-model="selection"
              color="primary"
          >
            <v-list-item
                v-for="language in cAvailableLanguages"
                :key="language.locale"
                :value="language.locale"
            >
              <v-list-item-avatar
                  left
                  rounded="0"
              >
                <eins-locale-flag :locale="language.locale" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ language.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ language.labelOriginal }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-alert
            v-if="success"
            text
            tile
            type="success"
            transition="expand-transition"
        >
          <div class="font-weight-bold">
            <i18n path="player.update.language.success.title" />
          </div>
          <div>
            <i18n path="player.update.language.success.text">
              {{ cSelectedLanguage.label }}
            </i18n>
          </div>
        </v-alert>
        <v-expand-transition>
          <v-card-actions
              v-if="!success"
              class="flex-column"
          >
            <v-btn
                block
                color="primary"
                :loading="loading"
                @click="onClickSubmit"
            ><i18n path="player.update.language.actions.submit" /></v-btn>
            <v-btn
                block
                class="mx-0 mt-2"
                outlined
                :disabled="loading"
                @click="onClickCancel"
            ><i18n path="player.update.language.actions.cancel" /></v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <eins-btn-timed
                block
                color="primary"
                outlined
                :disabled="loading"
                @click="onClickClose"
            >
              <i18n path="player.update.language.actions.close" />
            </eins-btn-timed>
          </v-card-actions>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import EinsLocaleFlag from '@/components/locale/EinsLocaleFlag'

export default {
  name: 'EinsPlayerDataLanguageBlock',
  components: {
    EinsLocaleFlag,
    EinsBtnTimed
  },
  data: () => ({
    dialog: false,
    success: false,
    loading: false,
    selection: ''
  }),
  computed: {
    cCurrentLanguage () {
      return this.cAvailableLanguages
        .find(language => language.locale === this.$store.getters['players/current/language'])
    },
    cSelectedLanguage () {
      return this.cAvailableLanguages
        .find(language => language.locale === this.selection)
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cAvailableLanguages () {
      return this.$i18n.availableLocales
        .map(locale => ({
          locale,
          label: this.$t(`locales.${locale}`),
          labelOriginal: this.$t(`locales.${locale}`, locale)
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  methods: {
    onClickItem () {
      this.openDialog()
    },
    onFocusTextField (evt) {
      if (this.form.nameFirstFocus) {
        this.form.nameFirstFocus = false
        evt.target.select()
      }
    },
    onInputName () {
      this.markFormDirty()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(`players/${this.cUserId}`, {
        language: this.selection
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.$store.dispatch('players/current/update'))
        .finally(() => {
          this.loading = false
        })
    },
    markFormDirty () {
      this.form.dirty = true
    },
    openDialog () {
      this.reset()
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.reset()
    },
    reset () {
      this.success = false
      this.selection = this.cCurrentLanguage.locale
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
