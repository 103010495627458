export const dialog = {
  state: {
    application: {
      open: false,
      payload: null
    },
    qrCode: {
      open: false,
      payload: null
    },
    blockPlayer: {
      open: false,
      payload: null
    },
    closeAccount: {
      open: false,
      payload: null
    },
    cancelDeal: {
      open: false,
      payload: null
    },
    linkDeal: {
      open: false,
      payload: null
    },
    changeGroupOwner: {
      open: false,
      payload: null
    },
    deleteGroup: {
      open: false,
      payload: null
    },
    cancelQuest: {
      open: false,
      payload: null
    },
    finishQuest: {
      open: false,
      payload: null
    },
    denunciation: {
      open: false,
      payload: null
    },
    joinedGroup: {
      open: false,
      payload: null
    },
    leftGroup: {
      open: false,
      payload: null
    },
    leaveGroup: {
      open: false,
      payload: null
    },
    rejectDeal: {
      open: false,
      payload: null
    },
    share: {
      open: false,
      payload: null
    },
    addContent: {
      open: false,
      payload: null
    },
    error: {
      open: false,
      payload: null
    },
    getDeal: {
      open: false,
      payload: null
    },
    exchangeDeal: {
      open: false,
      payload: null
    }
  },
  getters: {
    dialogAddContent: state => state.addContent.open,
    dialogApplication: state => state.application.open,
    dialogQrCode: state => state.qrCode.open,
    dialogBlockPlayer: state => state.blockPlayer.open,
    dialogCancelDeal: state => state.cancelDeal.open,
    dialogLinkDeal: state => state.linkDeal.open,
    dialogCancelQuest: state => state.cancelQuest.open,
    dialogChangeGroupOwner: state => state.changeGroupOwner.open,
    dialogCloseAccount: state => state.closeAccount.open,
    dialogDeleteGroup: state => state.deleteGroup.open,
    dialogDenunciation: state => state.denunciation.open,
    dialogError: state => state.error.open,
    dialogExchangeDeal: state => state.exchangeDeal.open,
    dialogFinishQuest: state => state.finishQuest.open,
    dialogGetDeal: state => state.getDeal.open,
    dialogJoinedGroup: state => state.joinedGroup.open,
    dialogLeaveGroup: state => state.leaveGroup.open,
    dialogLeftGroup: state => state.leftGroup.open,
    dialogPayload: state => name => state?.[name.replace(/dialog([A-Z])(\w+)/, (m, p1, p2) => p1.toLowerCase() + p2)]?.payload ?? null,
    dialogRejectDeal: state => state.rejectDeal.open,
    dialogShare: state => state.share.open
  },
  mutations: {
    dialogApplication: (state, payload) => {
      state.application.open = payload
    },
    dialogQrCode: (state, payload) => {
      state.qrCode.open = payload
    },
    dialogBlockPlayer: (state, payload) => {
      state.blockPlayer.open = payload
    },
    dialogCancelDeal: (state, payload) => {
      state.cancelDeal.open = payload
    },
    dialogLinkDeal: (state, payload) => {
      state.linkDeal.open = payload
    },
    dialogChangeGroupOwner: (state, payload) => {
      state.changeGroupOwner.open = payload
    },
    dialogCancelQuest: (state, payload) => {
      state.cancelQuest.open = payload
    },
    dialogDeleteGroup: (state, payload) => {
      state.deleteGroup.open = payload
    },
    dialogFinishQuest: (state, payload) => {
      state.finishQuest.open = payload
    },
    dialogCloseAccount: (state, payload) => {
      state.closeAccount.open = payload
    },
    dialogDenunciation: (state, payload) => {
      state.denunciation.open = payload
    },
    dialogJoinedGroup: (state, payload) => {
      state.joinedGroup.open = payload
    },
    dialogLeftGroup: (state, payload) => {
      state.leftGroup.open = payload
    },
    dialogLeaveGroup: (state, payload) => {
      state.leaveGroup.open = payload
    },
    dialogRejectDeal: (state, payload) => {
      state.rejectDeal.open = payload
    },
    dialogShare: (state, payload) => {
      state.share.open = payload
    },
    dialogAddContent: (state, payload) => {
      state.addContent.open = payload
    },
    dialogError: (state, payload) => {
      state.error.open = payload
    },
    dialogGetDeal: (state, payload) => {
      state.getDeal.open = payload
    },
    dialogExchangeDeal: (state, payload) => {
      state.exchangeDeal.open = payload
    }

  },
  actions: {
    openDialogApplication: ({ dispatch }, payload) => dispatch('openDialog', { name: 'application', payload }),
    openDialogQrCode: ({ dispatch }, payload) => dispatch('openDialog', { name: 'qrCode', payload }),
    openDialogBlockPlayer: ({ dispatch }, payload) => dispatch('openDialog', { name: 'blockPlayer', payload }),
    openDialogGetDeal: ({ dispatch }, payload) => dispatch('openDialog', { name: 'getDeal', payload }),
    openDialogExchangeDeal: ({ dispatch }, payload) => dispatch('openDialog', { name: 'exchangeDeal', payload }),
    openDialogDeleteGroup: ({ dispatch }, payload) => dispatch('openDialog', { name: 'deleteGroup', payload }),
    openDialogCancelDeal: ({ dispatch }, payload) => dispatch('openDialog', { name: 'cancelDeal', payload }),
    openDialogLinkDeal: ({ dispatch }, payload) => dispatch('openDialog', { name: 'linkDeal', payload }),
    openDialogChangeGroupOwner: ({ dispatch }, payload) => dispatch('openDialog', { name: 'changeGroupOwner', payload }),
    openDialogCancelQuest: ({ dispatch }, payload) => dispatch('openDialog', { name: 'cancelQuest', payload }),
    openDialogFinishQuest: ({ dispatch }, payload) => dispatch('openDialog', { name: 'finishQuest', payload }),
    openDialogCloseAccount: ({ dispatch }, payload) => dispatch('openDialog', { name: 'closeAccount', payload }),
    openDialogDenunciation: ({ dispatch }, payload) => dispatch('openDialog', { name: 'denunciation', payload }),
    openDialogJoinedGroup: ({ dispatch }, payload) => dispatch('openDialog', { name: 'joinedGroup', payload }),
    openDialogLeftGroup: ({ dispatch }, payload) => dispatch('openDialog', { name: 'leftGroup', payload }),
    openDialogLeaveGroup: ({ dispatch }, payload) => dispatch('openDialog', { name: 'leaveGroup', payload }),
    openDialogError: ({ dispatch }, payload) => dispatch('openDialog', { name: 'error', payload }),
    openDialogRejectDeal: ({ dispatch }, payload) => dispatch('openDialog', { name: 'rejectDeal', payload }),
    openDialogShare: ({ dispatch }, payload) => dispatch('openDialog', { name: 'share', payload }),
    openDialogAddContent: ({ dispatch }, payload) => dispatch('openDialog', { name: 'addContent', payload }),
    openDialog ({ state, rootGetters }, { name, payload }) {
      return new Promise((resolve, reject) => {
        if (Object.prototype.hasOwnProperty.call(state, name)) {
          state[name].open = true
          state[name].payload = payload
          const unwatch = this.watch(
            (state) => {
              return state.app.dialog[name].open
            },
            (value, oldValue) => {
              if (!value && oldValue) {
                // value has changed from true to false
                unwatch()
                resolve()

                state[name].payload = null
              }
            }
          )
        } else {
          reject(new Error('Unknown dialog'))
        }
      })
    }
  },
  modules: {
  }
}
