<template>
  <v-container
      fluid
      mt-2
      pa-0
      py-2
  >
    <v-row
        dense
        align="start"
    >
      <v-col
          v-for="item in items"
          :key="item[keyProperty]"
          cols="12"
      >
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <slot
            :item="item"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EinsLayoutList',
  props: {
    keyProperty: {
      type: String,
      default: 'id'
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  }
}
</script>

<style scoped>

</style>
