<template>
  <eins-edit-name-block
      :after-update="afterUpdate"
      :api-path="cApiPath"
      :current-value="cUserName"
      :dialog-title="cText.dialogTitle"
      :input-label="cText.inputLabel"
      :rule-message="cRuleMessage"
      :submit-label="cText.submitLabel"
  >
    <template #success-message="{ newValue, oldValue }">
      <div class="font-weight-bold">
        <i18n path="player.update.name.success.title" />
      </div>
      <div>
        <i18n path="player.update.name.success.text">
          <span>{{ oldValue }}</span>
          <span>{{ newValue }}</span>
        </i18n>
      </div>
    </template>
    <template #unchanged-message>
      <i18n path="player.update.name.name.unchanged" />
    </template>
  </eins-edit-name-block>
</template>

<script>
import EinsEditNameBlock from '@/components/form/edit/EinsEditNameBlock'

export default {
  name: 'EinsPlayerDataNameBlock',
  components: {
    EinsEditNameBlock
  },
  data: () => ({
  }),
  computed: {
    cText () {
      return {
        dialogTitle: this.$t('player.update.name.dialogTitle'),
        inputLabel: this.$t('player.update.name.name.label'),
        submitLabel: this.$t('player.update.name.actions.submit')
      }
    },
    cRuleMessage () {
      return {
        required: this.$t('player.update.name.name.required'),
        min: this.$t('player.update.name.name.min')
      }
    },
    cApiPath () {
      return `players/${this.cUserId}`
    },
    cUserId () {
      return this.$store.getters['players/current/id']
    },
    cUserName () {
      return this.$store.getters['players/current/name']
    }
  },
  methods: {
    afterUpdate () {
      return this.$store.dispatch('players/current/update')
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
