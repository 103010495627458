<template>
  <v-dialog
      fullscreen
      :persistent="loading"
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-row
        class="fill-height flex-column edit-dialog-content"
        style="position: relative;"
        no-gutters
    >
      <v-col>
        <eins-input-position
            v-if="showMap"
            v-model="newValue"
            :zoom="18"
            :disabled="cDisableDragging"
            style="width: 100%;"
            height="100%"
        >
          <l-marker
              v-if="cInitialPositionMarker"
              :lat-lng="cInitialPositionMarker"
              :opacity="0.5"
          />
        </eins-input-position>
      </v-col>
      <v-expand-transition>
        <v-col
            v-if="success"
            class="flex-grow-0"
        >
          <v-sheet style="position: relative; z-index: 400;">
            <v-alert
                class="mb-0"
                type="success"
                text
                tile
                outlined
            >
              <div class="font-weight-bold">
                {{ successMessage.title }}
              </div>
              <div>{{ successMessage.text }}</div>
              <eins-btn-timed
                  block
                  outlined
                  color="primary"
                  @click="onClickClose"
              >
                <i18n path="msc.actions.close" />
              </eins-btn-timed>
            </v-alert>
          </v-sheet>
        </v-col>
      </v-expand-transition>
      <v-btn
          fab
          small
          color="secondary"
          :disabled="loading"
          style="position: absolute; top: 0.5em; right: 0.5em; z-index: 400;"
          @click="onClickCancel"
      ><v-icon large>mdi-close</v-icon></v-btn>
      <v-btn
          v-if="!success"
          fab
          color="primary"
          :loading="loading"
          style="position: absolute; bottom: 1.5em; left: 50%; z-index: 400; transform: translateX(-50%);"
          @click="onClickSave"
      ><v-icon large>mdi-content-save-outline</v-icon></v-btn>
    </v-row>
  </v-dialog>
</template>

<script>
import {
  LMarker
} from 'vue2-leaflet'
import {
  Map
} from '@/constants'
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'
import {
  isValidGeolocation
} from '@/utils/geo'
import EinsInputPosition from '@/components/input/EinsInputPosition'

export default {
  name: 'EinsEditLocationPositionDialog',
  components: {
    EinsInputPosition,
    EinsBtnTimed,
    LMarker
  },
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    afterUpdate: {
      type: Function,
      required: true
    },
    successMessage: {
      type: Object,
      required: true
    },
    currentValue: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    showMap: false,
    mapObject: null,
    internalValue: false,
    loading: false,
    success: false,
    newValue: {
      lat: null,
      lon: null
    }
  }),
  computed: {
    cSource () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy;&nbsp;<a class="copy" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      }
    },
    cGroupId () {
      return this.$store.getters['groups/selected/group']?.id
    },
    cUserPosition () {
      return {
        lat: this.$store.getters['players/current/latitude'],
        lon: this.$store.getters['players/current/longitude']
      }
    },
    cInitialPosition () {
      if (isValidGeolocation(this.currentValue)) {
        return this.currentValue
      } else if (isValidGeolocation(this.cUserPosition)) {
        return this.cUserPosition
      } else {
        return {
          lat: Map.CENTER_DEFAULT_LAT,
          lon: Map.CENTER_DEFAULT_LNG
        }
      }
    },
    cInitialPositionMarker () {
      return this.currentValue
        ? [this.currentValue.lat, this.currentValue.lon]
        : null
    },
    cNewPosition () {
      return [this.newValue.lat, this.newValue.lon]
    },
    cDisableDragging () {
      return this.success || this.loading
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onUpdateValue'
    },
    cDisableDragging: {
      immediate: true,
      handler (value) {
        if (value && this.mapObject) {
          this.mapObject.dragging.disable()
        }
      }
    }
  },
  methods: {
    onMapReady (map) {
      this.mapObject = map

      map.on('move', (evt) => {
        const mapCenter = map.getCenter()
        this.setNewValue(mapCenter)
      })
    },
    onUpdateCenter (center) {
      this.setNewValue(center)
    },
    onUpdateValue (value) {
      this.setValue(value)
      this.reset()
    },
    onInputDialog (value) {
      this.setValue(value)
      this.emitInput()
    },
    onClickSave () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    setNewValue (center) {
      this.newValue = {
        lat: center.lat,
        lon: center.lng
      }
    },
    setValue (value) {
      this.internalValue = value
      this.$nextTick().then(() => {
        this.showMap = value
      })
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiUrl, {
        latitude: this.newValue.lat,
        longitude: this.newValue.lon
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    closeDialog () {
      this.internalValue = false
      this.emitInput()
      this.reset()
    },
    reset () {
      this.success = false
      this.newValue = JSON.parse(JSON.stringify(this.cInitialPosition))
    }
  }
}
</script>

<style scoped>

</style>
