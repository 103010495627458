import Vue from 'vue'

import { VueFirebase } from '@/Firebase'

let credentials
try {
  credentials = require('@/../firebaseCredentials.json')
} catch (e) {
  credentials = {}
}

const firebaseOptions = Object.seal(credentials)

Vue.use(VueFirebase, {
  firebaseOptions,
  features: [
    VueFirebase.MESSAGING,
    VueFirebase.AUTH
  ],
  setup: {
    auth: {
      google: {
        scopes: [
          'profile',
          'email'
        ]
      },
      apple: {
        scopes: [
          'name',
          'email'
        ]
      },
      microsoft: {
        scopes: [
          'profile',
          'email'
        ]
      }
    }
  }
})

export const firebase = new VueFirebase()
