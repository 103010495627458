<template>
  <v-icon
      v-opacity.toggle="cShowIcon"
      v-bind="$attrs"
      v-on="$listeners"
  >{{ cIcon }}</v-icon>
</template>

<script>
import { DiOpacity } from '@/directives'

const validStates = ['none', 'member', 'admin']

export default {
  name: 'EinsIconGroupMember',
  directives: {
    opacity: DiOpacity
  },
  props: {
    state: {
      type: String,
      default: 'none',
      validate: (value) => validStates.indexOf(value) > -1
    }
  },
  computed: {
    cShowIcon () {
      return this.state !== 'none'
    },
    cIcon () {
      let icon
      switch (this.state) {
        case 'member':
          icon = 'mdi-wallet-membership'
          break
        case 'admin':
          icon = 'mdi-account-supervisor'
          break
        case 'none':
        default:
          icon = 'mdi-stop'
      }

      return icon
    }
  }
}
</script>

<style scoped>

</style>
