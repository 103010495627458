export default {
  picture: {
    label: 'Select picture'
  },
  address: {
    street: {
      label: 'Street',
      required: 'Please enter the street'
    },
    houseNumber: {
      label: 'Number',
      required: 'Please enter the house number'
    },
    postalCode: {
      label: 'Postal code',
      required: 'Please enter the postal code'
    },
    city: {
      label: 'City',
      required: 'Please enter the city'
    },
    country: {
      label: 'Country',
      required: 'Please select the country'
    }
  },
  location: {
    position: 'Position',
    address: 'Address',
    noAddressSet: 'No address set',
    noGeolocationSet: 'No valid position set',
    calculationHint: 'You can either set address or location.' +
      ' Setting the address will calculate the location and vice versa.'
  },
  timeValue: {
    availableBalance: 'Available balance',
    maxValue: 'A maximum value of {0} is allowed.',
    rules: {
      balance: 'The value must not exceed your available balance of {0}.',
      max: 'The value must not exceed {0}.',
      min: 'The value must be greater than {0}.'
    }
  },
  groupScope: {
    default: {
      listCaption: '@.lower:(terms.group)',
      voidItemLabel: 'None'
    }
  }
}
