export default {
  changeOwnership: {
    title: 'Eigentümer ändern',
    text: 'Du bist dabei, die Eigentümerschaft für Dein  @.lower:(terms.group) {0} zu ändern.' +
        ' Bitte wähle einen der Administratoren aus, um die Führung zu übergeben.',
    textLeaveAndChange: 'Du bist dabei, Dein @.lower:(terms.group) {0} zu verlassen.' +
        ' Da du weiterhin noch Eigentümer des @.lower:(terms.group)s bist, musst Du zuerst die Eigentümerschaft übertragen.',
    select: {
      label: 'Neuer Eigentümer',
      noData: 'Keine Admins gefunden'
    },
    success: 'Das Eigentum an {0} wurde erfolgreich auf {1} übertragen.',
    error: {
      forbidden: 'Du bist nicht berechtigt, den Eigentümer von @.lower:(terms.group) zu ändern.',
      unknown: 'Unbekannter Fehler. Das hätte nicht passieren dürfen.'
    },
    buttons: {
      submit: 'Eigentum übertragen',
      cancel: 'Abbrechen',
      close: 'Schließen'
    }
  },
  delete: {
    dialog: {
      title: 'Löschen des@.lower:(terms.group)es',
      text: {
        main: 'Du bist dabei, Dein @.lower:(terms.group) \'{0}\' unwiderruflich zu löschen!',
        note1: 'Alle Mitglieder werden entfernt',
        note2: 'Unvollendete @.lower:(terms.quests) und @.lower:(terms.deals) werden gelöscht',
        note3: 'Alle Links zu @.lower:(terms.group) werden deaktiviert',
        note4: 'Die @.lower:(terms.group) kann nicht mehr gefunden werden'
      },
      warning: {
        title: 'Warnung!',
        text: 'Diese Aktion kann nicht widerrufen werden!' +
            ' Einige @.lower:(terms.players) könnten ihren Fortschritt für laufende @.lower:(terms.quests) verlieren!'
      },
      success: {
        title: '@.lower:(terms.group) gelöscht',
        text: 'Das @.lower:(terms.group) wurde gelöscht und wird nicht mehr gefunden!'
      },
      buttons: {
        delete: 'Löschen des @.lower:(terms.group)es',
        deleteFinally: 'Ja, ich bin sicher, lösche die @.lower:(terms.group)!',
        cancel: 'Besser nicht.....',
        close: 'Schließen'
      }
    }
  },
  update: {
    title: {
      dialogTitle: '@.lower:(terms.group)-Titel ändern',
      actions: {
        submit: 'Titel ändern'
      },
      success: {
        title: 'Erfolg!',
        text: 'Der Titel Ihrer @.lower:(terms.group) wurde von „{0}“ in „{1}“ geändert.'
      },
      title: {
        label: 'Neuer Titel',
        required: 'Bitte gib einen neuen Titel ein',
        min: 'Der Titel muss eine Länge von mindestens 3 Zeichen haben',
        unchanged: 'Der neue Titel unterscheidet sich nicht vom aktuellen Titel.'
      }
    },
    picture: {
      dialogTitle: 'Bild hochladen',
      success: {
        title: 'Erfolg!',
        text: 'Dein @.lower:(terms.group) Titelbild wurde gesetzt.',
        bonus: 'Erfolgreich hochladen ! Du hast 2 @.lower:(terms.points) bekommen.'
      },
      actions: {
        submit: 'Hochladen',
        setPicture: 'Bild einstellen'
      }
    },
    description: {
      dialogTitle: '@.lower:(terms.group)-@.lower:(terms.description) ändern',
      actions: {
        submit: '@.lower:(terms.description) ändern'
      },
      success: {
        title: 'Erfolg!',
        text: 'Die @.lower:(terms.description) Deines @.lower:(terms.group)es wurde aktualisiert.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'Die Länge von @.lower:(terms.description) darf 500 Zeichen nicht überschreiten.'
      }
    },
    contactInformation: {
      title: 'Kontakt Information',
      explanation: 'Ansprechpartner der @.lower:(terms.group).' +
          ' Änderung nur in Ihrem icigai1 @.lower:(terms.group)-Konto',
      empty: 'Keine Kontaktinformationen gefunden'
    },
    email: {
      title: 'Email Addresse',
      explanation: 'Dein @.lower:(terms.group) eMail kann nur in Deinem icigai1 @.lower:(terms.group) Account geändert werden',
      empty: 'Keine eMail gefunden'
    },
    hiddenQuests: {
      label: '@.lower:(terms.quest) in der Chronik verstecken',
      explanation: 'Änderungen an dieser Option wirken sich nicht auf bestehende @.lower:(terms.quests) aus!' +
        ' Aktiviere diese Option, um alle zukünftigen @.lower:(terms.quests) vor der Chronik zu verbergen.' +
        ' Du kannst immer noch bestimmte @.lower:(terms.quests) in der Chronik anzeigen, wenn Du möchtest.'
    },
    location: {
      address: {
        dialogTitle: 'Adresse einstellen',
        success: {
          title: 'Addresse aktuallisieren',
          text: 'Die @.lower:(terms.group)-Adresse wurde aktualisiert, wir werden nun die Kartenposition neu berechnen.'
        },
        actions: {
          submit: 'Adresse aktualisieren'
        }
      },
      position: {
        success: {
          title: 'Position aktualisieren',
          text: 'Die @.lower:(terms.group)-Position wurde aktualisiert, wir berechnen nun die Adresse neu.'
        }
      }
    }
  }
}
