export default {
  'description': '@:(terms.description)',
  'details': {
    'medal': {
      'title': '@.lower:(terms.player) hat diese \'{0}\' Medalien erhalten'
    },
    'player': {
      'sub-title': 'Der @.lower:(terms.player) erstellte diese @.lower:(terms.quest).'
    },
    'group': {
      'sub-title': 'Das @.lower:(terms.group), auf dem die @.lower:(terms.quest) stattfindet.'
    },
    'stars': {
      'title': 'Du erhältst {0} @.lower:(terms.stars)',
      'sub-title': '@.lower:(terms.stars) entsprechen ungefähr {0} hours.'
    },
    'points': {
      'title': 'YDu erhältst {0} @.lower:(terms.points)',
      'sub-title': 'Das entspricht ungefähr {0} minutes.'
    },
    'time': {
      'title': 'Zeit: {0} h / @.lower:(terms.stars) (hours) & @.lower:(terms.points) (minutes)',
      'sub-title': '@.lower:(terms.stars) für diese @.lower:(terms.quest) entspricht ungefähr dieser Zeit.'
    },
    'quest-id': {
      'title': 'ID #{0}',
      'sub-title': 'Dies ist die Identifikationsnummer dafür @.lower:(terms.quest)'
    },
    'rating': {
      'title': 'Bewertung',
      'up-votes': 'Likes: {0}',
      'down-votes': 'Ablehnung: {0}'
    },
    'status': {
      'title': '@:(terms.quest) Status'
    }
  },
  'finished': {
    'quest': 'meiterte die @.lower:(terms.quest)'
  }
}
