export default {
  'description': '@:(terms.description)',
  'details': {
    'membership': {
      'title': 'Mitgliedschaft',
      'sub-title-member': 'Du bist ein Mitglied des @.lower:(terms.group)',
      'sub-title-admin': 'Du bist ein Admin auf dem @.lower:(terms.group)',
      'sub-title-none': 'Du bist kein Mitglied auf dem @.lower:(terms.group)'
    },
    'group-id': {
      'title': 'ID #{0}',
      'sub-title': 'Das ist die Identifikationsnummer für das @.lower:(terms.group)'
    },
    'members': {
      'title': '{0} Mitglieder',
      'sub-title': 'Anzahl der @.lower:(terms.player) auf diesem @.lower:(terms.group)'
    },
    'quests': {
      'title': '{0} offen @:(terms.quests)',
      'sub-title': 'du kannst die @:(terms.quests) erfüllen auf dem @.lower:(terms.group)'
    },
    'questsDone': {
      'title': '{0} erfüllte @:(terms.quests)',
      'sub-title': 'Diese @:(terms.quests) wurden bereits erfüllt'
    },
    'deals': {
      'title': '{0} @:(terms.deals)',
      'sub-title': 'Du kannst diese @:(terms.deals) auf dem @.lower:(terms.group) bekommen'
    }
  }
}
