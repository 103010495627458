export default {
  labels: {
    orLabel: 'or',
    success: {
      title: 'Please verify your email soon',
      text: '....to use all the features of the game. You have received an email with a verification link '
    },
    logging: 'Your @.lower:(terms.player) is being created....'
  },
  error: {
    invalid: 'Your password is invalid!',
    rate: 'Too many attempts, please try again later.',
    email: 'Your @.lower:(terms.player) email is already registered',
    unknown: 'An unknown error happened, please try again later.'
  },
  buttons: {
    register: {
      label: 'Create @.lower:(terms.player) and Start 1APP'
    },
    loginWithApple: {
      label: 'Login with Apple'
    },
    signInWithGoogle: {
      label: 'Sign in with Google'
    },
    BackToLogin: {
      label: 'Login existing @.lower:(terms.player)'
    }
  }
}
