import { RegEMail } from '@/utils/regex'
import { i18n } from '@/plugins'
import { Input } from '@/constants'

export const eMailValid = (val) => {
  return RegEMail.test(val.toLowerCase()) || i18n.t('inputRules.eMailValid')
}
export const questTitleValid = (val) => {
  return val.length > 3
}
export const questPictureValid = (val) => {
  return val !== null
}
export const questDescriptionMinLengthValid = (val) => {
  return val?.length > 5 || 'Min 5 characters'
}
export const questDescriptionMaxLengthValid = (val) => {
  return val?.length <= 500 || 'Max 500 characters'
}
export const dealTitleValid = (val) => {
  return val.length > 3
}
export const dealPictureValid = (val) => {
  return val !== null
}
export const dealLocationValid = (val) => {
  return val !== null
}
export const dealPictureMaxSize = (val) => {
  return val?.size < Input.IMAGE_MAX_SIZE || `Avatar size should be less than ${Input.IMAGE_MAX_SIZE_HR} MB!`
}
export const dealDescriptionMinLengthValid = (val) => {
  return val?.length > 5 || 'Min 5 characters'
}
export const dealDescriptionMaxLengthValid = (val) => {
  return val?.length <= 500 || 'Max 500 characters'
}

export const groupTitleValid = (val) => {
  return (val.length > 3 && val.length <= 100) || 'The title must be at longer than 3 and shorter than 70 characters'
}
export const groupPictureValid = (val) => {
  return val !== null
}
export const groupPictureMaxSize = (val) => {
  return val?.size < Input.IMAGE_MAX_SIZE || `Avatar size should be less than ${Input.IMAGE_MAX_SIZE_HR} MB!`
}
export const groupDescriptionMinLengthValid = (val) => {
  return val?.length > 5 || 'Min 5 characters'
}
export const groupDescriptionMaxLengthValid = (val) => {
  return val?.length <= 500 || 'Max 500 characters'
}

export const fileMaxSize = (max, maxHr) => val => (val === null || val.size < max) || `The maximum file Size is ${maxHr}MB.`
export const fileExists = val => val instanceof File || 'Please select a file!'

const regDigit = /\d/
const regLetter = /[a-zA-Z]/
const regLetterLc = /[a-z]/
const regLetterUc = /[A-Z]/
const regSpecialChar = /[^\w\d_]/
export const includesDigit = val => val.match(regDigit) !== null || 'Must contain a digit!!'
export const includesLetter = val => val.match(regLetter) !== null || 'Must contain a lower-case letter'
export const includesLetterLc = val => val.match(regLetterLc) !== null || 'Must contain a lower-case letter'
export const includesLetterUc = val => val.match(regLetterUc) !== null || 'Must contain a upper-case letter'
export const includesSpecialChar = val => val.match(regSpecialChar) !== null || 'Must contain a special char'
export const hasMinLength = min => val => val.length >= min || `Must be longer than ${min}`
export const hasMaxLength = max => val => val.length <= max || `Must be shorter than ${max}`
export const isNotEmpty = val => !!val || `Must not be empty!`

export const isEmail = val => RegEMail.test(val) || `Must be a valid email!`
