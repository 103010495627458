<template>
  <v-app>
    <v-container
        class="fill-height"
    >
      <v-row
          class="fill-height column"
          align="center"
          justify="center"
      >
        <v-col class="flex-grow-0">
          <v-progress-circular
              indeterminate
              size="120"
              width="1"
              color="primary"
              class="text-center"
          >
            <!--<span class="grey--text font-weight-thin text-h5">Loading...</span>-->
            <span class="grey--text font-weight-thin text-h5">
              <img
                  width="70px"
                  height="70px"
                  src="./assets/img/icons/ui/loading.png"
              >
            </span>
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Splash'
}
</script>

<style scoped>

</style>
