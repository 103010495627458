<template>
  <div
      ref="content"
      v-scroll:#main-content-scroll="onScroll"
      class="group-list"
  >
    <div style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1;">
      <v-slide-y-transition>
        <v-progress-linear
            v-show="cLoading"
            height="5"
            indeterminate
        />
      </v-slide-y-transition>
    </div>
    <eins-group-list
        v-if="cIsList"
        :groups="cGroups"
    >
      <template #switcher>
        <v-btn
            style="position:absolute; z-index:2;bottom:25px;right:0;"
            class="mx-2"
            fab
            dark
            small
            color="var(--v-group-base)"
            @click="switchTo('map')"
        >
          <v-icon dark>
            mdi-map-search
          </v-icon>
        </v-btn>
      </template>
    </eins-group-list>
    <eins-object-list-map
        v-if="cIsMap"
        type="group"
        :items="cGroups"
    >
      <template #switcher>
        <v-btn
            style="position:absolute; z-index:2;bottom:35px;right:0;"
            class="mx-2"
            fab
            dark
            small
            color="var(--v-group-base)"
            @click="switchTo('list')"
        >
          <v-icon dark>
            mdi-view-list
          </v-icon>
        </v-btn>
      </template>
    </eins-object-list-map>
    <v-alert
        outlined
        type="warning"
        dense
        text
        class="mx-2"
        :value="cListEnd"
    >
      End reached
    </v-alert>
    <router-view />
  </div>
</template>

<script>
import EinsGroupList from '@/components/group/EinsGroupList'
import EinsObjectListMap from '@/components/map/EinsObjectListMap'
import { EventBus } from '@/eventBus.js'
import { RegRouteGroup } from '@/utils/regex'

export default {
  name: 'ViewGroupList',
  components: { EinsGroupList, EinsObjectListMap },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (!RegRouteGroup.test(to.name)) {
        vm.clearSearch()
      }
      vm.updateGroupList()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!RegRouteGroup.test(to.name)) {
      this.clearSearch()
    }
    next()
  },
  data: () => ({
    lastPosition: 0,
    textFilter: '',
    mode: 'list'
  }),
  computed: {
    cIsList () {
      return this.mode === 'list'
    },
    cIsMap () {
      return this.mode === 'map'
    },
    cGroups () {
      let textFilterLc = this.cGetTextFilter.toLowerCase()
      return this.$store.getters['groups/list']
        .filter((el) => this.textFilter?.length === 0 || el.description.toLowerCase().includes(textFilterLc) || el.title.toLowerCase().includes(textFilterLc))
    },
    /*
    cPlayers () {
      let textFilterLc = this.cGetTextFilter.toLowerCase()
      return this.$store.getters['players/list']
          .filter((el) => this.textFilter?.length === 0 || el.name.toLowerCase().includes(textFilterLc) || el.about.toLowerCase().includes(textFilterLc))
    }, */
    cListEnd () {
      return this.$store.getters['groups/listEnd']
    },
    cLoading () {
      return this.$store.getters['groups/listLoading']
    },
    cGetTextFilter () { return this.textFilter }
  },
  created () {
    this.$root.$on('request-update', this.onRequestViewUpdate.bind(this))
    EventBus.$on('changeTextFilter', (myVal) => {
      this.textFilter = myVal
    })
  },
  beforeDestroy () {
    this.$root.$off('request-update', this.onRequestViewUpdate.bind(this))
  },
  methods: {
    switchTo (mode) {
      this.mode = mode
    },
    ChangeTextFilter (value) {
      this.textFilter = value
    },
    onRequestViewUpdate () {
      this.updateGroupList()
    },
    onScroll (e) {
      if (!this.cLoading && this.$refs.content && !this.cListEnd) {
        const distanceLeft = this.$refs.content.clientHeight - (e.target.scrollTop + e.target.clientHeight)
        // only load more on scroll down
        if (distanceLeft < 250 && this.lastPosition < e.target.scrollTop) {
          this.$store.dispatch('groups/loadMore')
        }

        this.lastPosition = e.target.scrollTop
      }
    },
    updateGroupList () {
      this.$store.dispatch('groups/update')
    },
    clearSearch () {
      this.$store.commit('quests/clearSearch')
    }
  }
}
</script>

<style scoped>
</style>
