<template>
  <eins-layout-view-detail>
    <v-slide-y-transition>
      <v-progress-linear
          v-show="cLoading"
          height="5"
          indeterminate
          style="position: absolute; top: 0; left: 0; width: 100%;"
      />
    </v-slide-y-transition>
    <v-row
        class="flex-column fill-height"
        no-gutters
    >
      <v-col class="flex-grow-0 headline pa-3">
        <v-icon size="36">mdi-account-box</v-icon>
        <span class="ml-4">
          {{ cText['caption'] }}
        </span>
      </v-col>
      <v-col class="overflow-y-auto">
        <eins-my-profile />
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'

import EinsMyProfile from '@/components/player/EinsMyProfile'
import EinsLayoutViewDetail from '../components/layout/EinsLayoutViewDetail'

export default {
  name: 'ViewMyProfile',
  components: {
    EinsLayoutViewDetail,
    EinsMyProfile
  },
  mixins: [MxTranslatable],
  data: () => ({
    playerId: 0,
    formData: null,
    formValid: false,
    originData: null,
    saving: false,
    $_mx_translatable: {
      paths: [
        'caption',
        'saving'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cLoading () {
      return this.$store.getters['players/selected/loading']
    },
    cError () {
      return this.$store.getters['players/selected/error']
    },
    cPlayer () {
      return this.$store.getters['players/selected/player']
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>
<style scoped>
</style>
