<template>
  <eins-layout-view-detail>
    <v-btn
        fab
        absolute
        depressed
        elevation="0"
        style="right: -0.75em; top: -0.2em;"
        color="secondary"
        @click="$router.go(-1)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <iframe
        style="position:absolute;top:0px;bottom:0px;width:100%;height:100%;border:0px;"
        :src="cSource"
    >
    </iframe>
  </eins-layout-view-detail>
</template>

<script>
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
export default {
  name: 'ViewLink',
  components: { EinsLayoutViewDetail },
  data: () => ({
  }),
  computed: {
    cSource () {
      return this.$route.params?.source
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
