<template>
  <eins-starmenu-ab
      v-show="cShowBtn"
      @click="onClick"
  >
    <v-icon
        left
        color="primary"
    >mdi-cash</v-icon>
    {{ cText.text }}
  </eins-starmenu-ab>
</template>

<script>
import {
  DealState,
  HttpStatus
} from '@/constants'
import {
  MxStarmenuBtn,
  MxTranslatable
} from '@/mixins'
import EinsStarmenuAb from '@/components/starmenu/EinsStarmenuAb'

export default {
  name: 'EinsStarmenuAbHonorDeal',
  components: {
    EinsStarmenuAb
  },
  mixins: [
    MxTranslatable,
    MxStarmenuBtn
  ],
  data: () => ({
    $_mx_translatable: {
      paths: [ 'text' ]
    }
  }),
  computed: {
    cShowBtn () {
      return this.cBelongsToRoute && this.cStateIsEnded && this.cHasLink && !this.cPlayerIsOwner
    },
    cDeal () {
      return this.$store.getters['deals/selected/deal']
    },
    cPlayerIsOwner () {
      return this.cDeal?.user_id === this.$store.getters['players/current/id']
    },
    cStateIsEnded () {
      return this.cDeal?.state === DealState.ENDED
    },
    cHasLink () {
      return this.cDeal?.link !== null
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('openDialogLinkDeal')
        .then(() => this.$http.$api.head(`deals/${this.cDeal?.id}`))
        .catch(() => {
          this.$router.replace('/deals')
        })
        .then(response => {
          if (response.status >= HttpStatus.BAD_REQUEST) {
            this.$router.replace('/deals')
          } else {
            this.$store.dispatch('deals/selected/update')
          }
        })
    }
  }
}
</script>

<style scoped>
</style>
