export default {
  'description': '@:(terms.description)',
  'talents': 'Talents',
  'details': {
    'id': {
      'title': '#{0}',
      'sub-title': '@:(terms.player) ID'
    },
    'likes': {
      'title': '{0} likes received the @.lower:(terms.player)',
      'sub-title': 'Likes are granted for completed @.lower:(terms.quests) .'
    },
    'quest': {
      'title': '@:(terms.quests) {0}',
      'sub-title': '@:(terms.quests) offered / completed by this @.lower:(terms.player)'
    },
    'quest-own': {
      'title': '@:(terms.quests): {0}',
      'sub-title': '@:(terms.quests) that are assigned / running / finished'
    },
    'quest-done-own': {
      'title': 'Completed @.lower:(terms.quests): {0}',
      'sub-title': 'You already completed this many @:(terms.quests)'
    },
    'medal': {
      'title': '{0} @.lower:(terms.medals)',
      'sub-title': 'The number of @.lower:(terms.medals) the @.lower:(terms.player) received from @.lower:(terms.quests) .'
    },
    'medal-own': {
      'sub-title': 'The number of @.lower:(terms.medals) you received from @.lower:(terms.quests) .'
    },
    'deal': {
      'title': '{0} offered @.lower:(terms.deals)',
      'sub-title': 'The number of @.lower:(terms.deals) the @.lower:(terms.player) is offering.'
    },
    'deal-own': {
      'title': '{0} offered @.lower:(terms.deals)',
      'sub-title': 'The number of @.lower:(terms.deals) you are offering.'
    },
    'group': {
      'title': '@:(terms.groups): {1} in common with you / ({0} all )',
      'sub-title': 'Number of @.lower:(terms.groups) where the @.lower:(terms.player) is member.'
    },
    'friend': {
      'title': 'Friends: {1} in common with you ({0} all )',
      'sub-title': 'Number of @.lower:(terms.players) that are friends with this @.lower:(terms.player).'
    },
    'group-own': {
      'title': '@:(terms.groups): {0} ({1} as admin / {2} as member)',
      'sub-title': 'Number of @.lower:(terms.groups) you are in.'
    },
    'friend-own': {
      'title': 'Friends: {0}',
      'sub-title': 'Number of @.lower:(terms.players) that are friends with you.'
    },
    'idol-own': {
      'title': 'Idols: {0}',
      'sub-title': 'Number of @.lower:(terms.players) that are idols to you.'
    },
    'follower-own': {
      'title': 'Followers: {0}',
      'sub-title': 'Number of @.lower:(terms.players) that you follow.'
    },
    'applied-own': {
      'title': 'Applied @.lower:(terms.quests): {0} @.lower:(terms.deals) {0}',
      'sub-title': 'Number of @.lower:(terms.quests) / @.lower:(terms.deals) you applied for.'
    },
    'offered-own': {
      'title': 'Offered: {0}',
      'sub-title': 'Number of @:(terms.quests) / @.lower:(terms.deals) you are offering.'
    }
  }
}
