<template>
  <eins-layout-view-detail>
    <v-row
        class="flex-column fill-height"
        no-gutters
    >
      <v-col class="pa-3 flex-grow-0">
        <v-icon size="36">{{ cQuestType.icon }}</v-icon>
        <span class="ml-4">
          {{ cQuestType.caption }}
        </span>
      </v-col>
      <v-col class="overflow-y-auto pa-3">
        <eins-quest-data-name-block />
        <eins-quest-data-picture-block class="mt-3" />
        <eins-quest-data-value-block class="mt-3" />
        <eins-quest-data-scope-block class="mt-3" />
        <eins-quest-data-description-block class="mt-3" />
        <eins-quest-data-location-block class="mt-3" />
      </v-col>
    </v-row>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import EinsQuestDataNameBlock from '@/components/quest/edit/EinsQuestDataNameBlock'
import EinsQuestDataPictureBlock from '@/components/quest/edit/EinsQuestDataPictureBlock'
import EinsQuestDataDescriptionBlock from '@/components/quest/edit/EinsQuestDataDescriptionBlock'
import EinsQuestDataLocationBlock from '@/components/quest/edit/EinsQuestDataLocationBlock'
import EinsQuestDataValueBlock from '@/components/quest/edit/EinsQuestDataValueBlock'
import EinsQuestDataScopeBlock from '@/components/quest/edit/EinsQuestDataScopeBlock'

export default {
  name: 'ViewQuestEdit',
  components: {
    EinsQuestDataScopeBlock,
    EinsQuestDataValueBlock,
    EinsQuestDataLocationBlock,
    EinsQuestDataDescriptionBlock,
    EinsQuestDataPictureBlock,
    EinsQuestDataNameBlock,
    EinsLayoutViewDetail
  },
  mixins: [MxTranslatable],
  data: () => ({
    $_mx_translatable: {
      paths: [
        'captions.private',
        'captions.common',
        'captions.nature'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cQuestType () {
      let result
      let type = this.cQuest?.type
      if (type === 'nature' || type === 'green' || type === 'future') {
        type = 'green'
      }
      switch (type) {
        case 'private':
          result = { icon: 'mdi-map-marker-left', caption: this.cText['captions.private'] }
          break
        case 'common':
          result = { icon: 'mdi-map-marker-star', caption: this.cText['captions.common'] }
          break
        case 'green':
          result = { icon: 'mdi-map-marker-up', caption: this.cText['captions.nature'] }
          break
      }
      return result || {}
    },
    cLoading () {
      return this.$store.getters['quests/selected/loading']
    },
    cError () {
      return this.$store.getters['quests/selected/error']
    },
    cQuest () {
      return this.$store.getters['quests/selected/quest']
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadQuest(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path !== from.path) {
      this.loadQuest(to.params.id)
    }
    next()
  },
  methods: {
    loadQuest (id) {
      return this.$store.dispatch('quests/selected/load', id)
        .then(result => {
          return result.data
        })
    }
  }
}
</script>

<style scoped>

</style>
