<template>
  <v-main
      style="position: fixed; z-index:3; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden;"
      class="detail-content"
  >
    <v-sheet
        :class="{ 'mt-n2 pa-3': padding }"
        max-height="100%"
        max-width="100%"
        height="100%"
        width="100%"
        tile
        style="position: relative;"
    >
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <slot />
    </v-sheet>
  </v-main>
</template>

<script>
export default {
  name: 'EinsLayoutViewDetail',
  props: {
    padding: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>
