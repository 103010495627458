<template>
  <v-select
      :label="cLabel"
      :items="cLocales"
      :value="internalValue"
      v-bind="cAttributes"
      @change="onChange"
      v-on="cListeners"
  >
    <template #item="{ item }">
      <v-list-item-avatar
          tile
          height="auto"
      >
        <eins-locale-flag :locale="item" />
      </v-list-item-avatar>
      <eins-locale-name
          #default="{ native, localized }"
          :locale="item"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ localized }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ native }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </eins-locale-name>
    </template>
    <template #selection="{ item }">
      <v-list-item-avatar
          tile
          height="auto"
      >
        <eins-locale-flag :locale="item" />
      </v-list-item-avatar>
      <eins-locale-name
          #default="{ localized }"
          :locale="item"
      >
        {{ localized }}
      </eins-locale-name>
    </template>
  </v-select>
</template>

<script>
import {
  MxHasModel,
  MxPropRedirection,
  MxVuetifyInputWrapper
} from '@/mixins'

import EinsLocaleFlag from '@/components/locale/EinsLocaleFlag'
import EinsLocaleName from '@/components/locale/EinsLocaleName'

export default {
  name: 'EinsLocaleSelect',
  components: {
    EinsLocaleFlag,
    EinsLocaleName
  },
  mixins: [
    MxHasModel,
    MxPropRedirection,
    MxVuetifyInputWrapper
  ],
  props: {
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    cLocales () {
      return this.$i18n.availableLocales
    },
    cLabel () {
      if (typeof this.label === 'string') {
        return this.label
      } else {
        return this.$t(`components.${this.$options.name}.label`)
      }
    }
  },
  watch: {
    '$i18n.locale': 'onChangeLocale'
  },
  created () {
    this.onChangeLocale()
  },
  methods: {
    onChange (value) {
      this.$i18n.locale = value
      this.internalValue = value

      this.emitInput()
    },
    onChangeLocale () {
      this.internalValue = this.$i18n.locale
      this.emit()
    }
  }
}
</script>

<style scoped>
.v-list {
  margin-top:40px;
  background-color: #1e1e1e !important;
}
</style>
