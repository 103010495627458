<template>
  <v-btn
      :href="`https://www.linkedin.com/sharing/share-offsite/?url=${cUrl}`"
      target="_blank"
      color="#0e76a8"
      v-bind="$attrs"
  >
    <v-icon left>mdi-linkedin</v-icon>{{ cText['text'] }}
  </v-btn>
</template>

<script>
import { MxTranslatable } from '@/mixins'

export default {
  name: 'EinsSocialShareLinkedin',
  mixins: [
    MxTranslatable
  ],
  props: {
    shareUrl: {
      type: String,
      required: true
    }
  },
  data: () => ({
    $_mx_translatable: {
      paths: [
        'text'
      ],
      params: {}
    }
  }),
  computed: {
    cUrl () {
      return encodeURIComponent(this.shareUrl)
    }
  }
}
</script>

<style scoped>

</style>
