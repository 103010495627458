<template>
  <eins-layout-view-detail>
    <v-slide-y-transition>
      <v-progress-linear
          v-show="cLoading"
          height="5"
          indeterminate
      />
    </v-slide-y-transition>
    <v-card
        ma-0
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    >
      <v-row
          class="flex-column flex-nowrap fill-height"
          style="overflow: hidden;"
          no-gutters
      >
        <v-col class="flex-grow-0 flex-shrink-1 px-0">
          <v-card-title class="pb-0">
            <template v-if="cDealType === 'service'">
              <v-icon size="24">mdi-face-agent</v-icon>
              <span class="ml-4">
                {{ cText['captions.captionService'] }}
              </span>
            </template>
            <template v-else>
              <v-icon size="24">mdi-basket</v-icon>
              <span class="ml-4">
                {{ cText['captions.captionProduct'] }}
              </span>
            </template>
          </v-card-title>
        </v-col>
        <v-col style="overflow-y: auto;">
          <v-card-text>
            <v-form
                ref="form"
                v-model="formValid"
            >
              <eins-deal-create-form
                  v-model="formData"
                  :deal-type="cDealType"
              />
            </v-form>
          </v-card-text>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-card-actions>
            <v-btn
                depressed
                :disabled="saving"
                @click="onClickCancel"
            >
              <v-icon>mdi-delete</v-icon>
              {{ cText['buttons.cancel.label'] }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                depressed
                :loading="saving"
                @click="onClickSubmit"
            >
              <v-icon>mdi-upload</v-icon>
              {{ cText['buttons.submit.label'] }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <!-- Cancel-Alert -->
    <v-dialog
        :value="alert.cancel"
    >
      <v-card class="grey darken-1">
        <v-card-title class="headline grey darken-2">
          <v-icon>mdi-alert-box</v-icon>
          <span class="ml-2">
            {{ cText['alerts.cancel.caption'] }}
          </span>
        </v-card-title>
        <v-card-text class="pa-3">
          {{ cText['alerts.cancel.text'] }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              @click="onClickCancelConfirmed"
          >
            <v-icon>mdi-delete</v-icon>
            {{ cText['buttons.cancel.label'] }}
          </v-btn>
          <v-btn
              color="primary"
              depressed
              @click="onClickBack"
          >
            <v-icon>mdi-backup-restore</v-icon>
            {{ cText['buttons.back.label'] }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </eins-layout-view-detail>
</template>

<script>
import { MxTranslatable } from '@/mixins'
import EinsDealCreateForm from '@/components/deal/EinsDealCreateForm'
import EinsLayoutViewDetail from '@/components/layout/EinsLayoutViewDetail'
import { DealType } from '@/constants'

const validTypes = [
  DealType.PRODUCT,
  DealType.SERVICE
]

export default {
  name: 'ViewDealCreate',
  components: {
    EinsLayoutViewDetail,
    EinsDealCreateForm
  },
  mixins: [MxTranslatable],
  data: () => ({
    formData: {},
    formValid: false,
    saving: false,
    alert: {
      cancel: false
    },
    $_mx_translatable: {
      paths: [
        'captions.caption',
        'captions.captionService',
        'captions.captionProduct',
        'alerts.cancel.caption',
        'alerts.cancel.text',
        'buttons.submit.label',
        'buttons.cancel.label',
        'buttons.back.label'
      ],
      basePath: 'views'
    }
  }),
  computed: {
    cDealType () {
      return this.$route.query.type ?? 'product'
    }
  },
  beforeRouteEnter (to, from, next) {
    if (validTypes.includes(to.query.type)) {
      next(vm => {
        if (to.query.player_group_id) {
          vm.setGroupId(to.query.player_group_id)
        } else {
          vm.loadTemplate(to.query.template)
        }
      })
    } else {
      next(`${to.path}?type=${validTypes[0]}`)
    }
  },
  created () {
  },
  methods: {
    setGroupId (groupId) {
      return this.$store.dispatch('groups/selected/load', groupId)
        .then(result => {
          this.$set(this.formData, 'player_group_id', result.data.id)
          this.$set(this.formData, 'latitude', result.data.latitude)
          this.$set(this.formData, 'longitude', result.data.longitude)
          this.$set(this.formData, 'public', false)
          return result.data
        })
    },
    loadTemplate (id) {
      return this.$store.dispatch('deals/selected/load', id)
        .then(result => {
          this.$set(this, 'formData', this.prepareData(result.data))
          return result.data
        })
    },
    prepareData (data) {
      let payload = { ...data }
      delete payload.id
      delete payload.title_picture_feed
      delete payload.title_picture_full
      delete payload.created_at
      delete payload.updated_at
      payload.picture = null

      return payload
    },
    onScroll (e) {
    },
    cLoading () {
    },
    onClickSubmit () {
      if (this.$refs.form?.validate()) {
        this.submit()
      }
    },
    submit () {
      this.saving = true
      const payload = {
        ...this.formData
      }
      delete payload.picture
      let dealId
      this.$http.$api.post('/deals', payload)
        .then((result) => {
          // return: picture upload
          const picture = this.formData.picture
          dealId = result.data.id
          return this.$http.$api.put('/deals/' + dealId + '/picture', picture, { headers: {
            'Content-Type': picture.type
          } })
        })
        .then(() => {
          this.$router.push('/deals/' + dealId)
          return true
        })
        .finally(() => {
          this.saving = false
        })
    },
    onClickCancel () {
      this.alert.cancel = true
    },
    onClickBack () {
      this.alert.cancel = false
    },
    onClickCancelConfirmed () {
      this.$router.push('/deals')
    }
  }
}
</script>

<style scoped>

</style>
