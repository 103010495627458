import { HttpStatus } from '@/constants'

export default {
  deleted: {
    name: '@.lower:(terms.player) und Daten löschen'
  },
  block: {
    button: '@.lower:(terms.player) blocken',
    dialog: {
      title: 'Blocke@.lower:(terms.player) \'{0}\'',
      text: 'Das Blockieren des @.lower:(terms.player) bedeutet, dass Sie nichts von diesem @.lower:(terms.player) sehen können. Kein @.lower:(terms.players) Profil, kein @.lower:(terms.deal), kein @.lower:(terms.quest) und du kannst nicht mit diesem @.lower:(terms.player) chatten. Du kannst nur die @.lower:(terms.group)-Aktivitäten sehen.',
      buttons: {
        cancel: 'Abbrechen',
        submit: '@.lower:(terms.player) blocken',
        close: 'Schließen'
      },
      state: {
        success: 'Der @.lower:(terms.player) wurde gesperrt. Die Sperrung kansnt Du in Deinen Profileinstellungen aufheben.',
        error: {
          default: 'Etwas ging schief...'
        }
      }
    },
    list: {
      listElement: {
        createdAt: 'geblockt am: {0}'
      },
      unblockDialog: {
        title: 'Blockade zurücknehmen',
        text: 'Wenn Du die Blockierung aufheben willst, kannst Du mit dem @.lower:(terms.player) {0} genauso interagieren wie der @.lower:(terms.player) mit Dir. Du kannst diesen @.lower:(terms.player) jederzeit wieder blockieren.',
        buttons: {
          cancel: 'Abbrechen',
          submit: '@.lower:(terms.player) blocken',
          close: 'Schließen'
        },
        state: {
          success: 'Der Block {0} von @.lower:(terms.player) wurde erfolgreich abgebrochen. Du kannst jetzt wieder vollständig interagieren.',
          error: {
            [HttpStatus.FORBIDDEN]: 'Du darst diese Sperrung nicht aufheben.',
            [HttpStatus.CONFLICT]: 'Die Sperrung wurde bereits aufgehoben.',
            default: 'Ein unbekannter Fehler ist aufgetreten.'
          }
        }
      }
    },
    attribution: {
      isBlocked: 'Du hast diesen @.lower:(terms.player) blockiert',
      hasBlocked: 'Der @.lower:(terms.player) hat Dich blockiert'
    }
  },
  select: {
    label: '@.lower:(terms.player',
    noData: 'Keine Bilder gefunden'
  },
  update: {
    picture: {
      dialogTitle: 'Bild hochladen',
      success: {
        title: 'Erfolg!',
        text: 'Dein Profilbild wurde eingestellt.'
      },
      actions: {
        submit: 'Hochladen',
        setPicture: 'Bild speichern'
      }
    },
    gps: {
      title: 'aktuelle Position',
      subTitlePositive: 'aktuelle Position wird übermittelt',
      subTitleNegative: 'Position nicht übermitteln!'
    },
    language: {
      label: 'APP Sprache',
      dialogTitle: 'APP Sprache ändern',
      actions: {
        submit: 'Sprache ändern',
        cancel: 'Abbrechen',
        close: 'Schließen'
      },
      success: {
        title: 'Erfolg!',
        text: 'Deine APP Sprache wurde geändert in \'{0}\'.'
      },
      name: {
        label: 'Neuer Name',
        required: 'Bitte gebe Deinen neuen Namen ein',
        min: 'Dein Name muss eine Länge von mindestens 3 Zeichen haben',
        unchanged: 'Dein neuer Name unterscheidet sich nicht von Deinem aktuellen Namen.'
      },
      labelTypes: 'Erhalte Benachrichtigungen per:'
    },
    name: {
      dialogTitle: 'Meinen Namen ändern',
      actions: {
        submit: 'Namen ändern'
      },
      success: {
        title: 'Eefolg!',
        text: 'Dein @.lower:(terms.player)-Name wurde von „{0}“ in „{1}“ geändert. Sage es unbedingt Deinen Freunden.'
      },
      name: {
        label: 'Neuer Name',
        required: 'Bitte gebe Deinen neuen Namen ein',
        min: 'Dein Name muss eine Länge von mindestens 3 Zeichen haben',
        unchanged: 'Dein neuer Name unterscheidet sich nicht von Deinem aktuellen Namen.'
      }
    },
    description: {
      dialogTitle: 'Meine @.lower:(terms.description) ändern',
      actions: {
        submit: '@.lower:(terms.description) ändern'
      },
      success: {
        title: 'Eefolg!',
        text: 'Deine @.lower:(terms.description) wurde geändert.'
      },
      description: {
        label: '@.lower:(terms.description)',
        max: 'Die Länge Deiner @.lower:(terms.description) darf 500 Zeichen nicht überschreiten.'
      }
    },
    notifications: {
      label: 'Meine Benachrichtigungseinstellungen',
      actions: {
        change: 'Ändern'
      },
      labelTypes: 'Erhalte Benachrichtigungen per:'
    },
    password: {
      dialogTitle: 'Passwort ändern',
      actions: {
        update: 'Passwort ändern',
        submit: 'Passwort ändern',
        reset: 'Neues Passwort anfordern',
        cancel: 'Abbruch',
        close: 'Schließen'
      },
      success: 'Dein Kennwort wurde erfolgreich geändert.',
      form: {
        currentPassword: {
          label: 'Aktuelles Passwort',
          required: 'Bitte gebe Dein aktuelles Passwort ein',
          mismatch: 'Das eingegebene Passwort stimmt nicht mit Ihrem aktuellen Passwort überein'
        },
        password: {
          label: 'Neues Passwort',
          required: 'Bitte gib Dein Neues Passwort ein',
          min: 'Deines neues Passwort muss mindestens 10 Zeichen haben.'
        },
        passwordConfirmation: {
          label: 'Neues Passwort bestätigen)',
          required: 'Bitte bestätige Dein neues Passwort',
          mismatch: 'Die bestätige Passwort stimmt nicht mit dem neuen Passwort überein'
        }
      }
    },
    talents: {
      label: 'Meine Talente',
      dialogTitleCreate: 'Neues Talent anlegen',
      dialogTitleEdit: 'Talent \'{name}\' aktuallisieren',
      actions: {
        new: 'Talent anlegen',
        edit: 'Talent \'{name}\' bearbeiten',
        delete: 'Talent \'{name}\' löschen',
        update: 'Aktuallisieren',
        create: 'Anlegen',
        cancel: 'Abbrechen',
        close: 'Schließen'
      },
      form: {
        name: {
          label: 'Name',
          required: 'Bitte wähle einen Namen',
          min: 'Der Name sollte mindestens eine Länge von 3 Zeichen haben'
        },
        description: {
          label: '@.lower:(terms.description)',
          placeholder: 'Was ist das Besondere an Deinem Talent und wie kannst Du es einsetzen?',
          max: 'Bitte gebe maximal 150 Zeichen ein'
        }
      },
      noTalents: 'Du hast bisher keine Talente eingegeben.',
      deletedMessage: {
        text: 'Das Talent „{name}“ wurde entfernt.'
      },
      successMessage: {
        title: 'Gespeichert',
        textCreated: 'Das Talent „{name}“ wurde erstellt.',
        textUpdated: 'Das Talent „{name}“ wurde aktualisiert.'
      }
    },
    email: {
      label: 'Meine Email',
      dialogTitle: 'Email ändern',
      dialogText: 'Nachdem Du Deine E-Mail-Adresse geändert hast, validieren sie bitte, um aktiviert zu werden.',
      newEmail: {
        label: 'neu Email',
        hint: 'Bitte gebe Deine neue E-Mail-Adresse ein.',
        errorValid: 'Der eingegebene Wert ist keine gültige E-Mail-Adresse',
        errorRequired: 'Bitte geben die neue E-Mail-Adresse ein',
        errorUnique: 'Die eingegebene E-Mail-Adresse wird bereits verwendet.',
        verificationNeededHint: 'Bestätige diese Adresse, um sie zu verwenden.'
      },
      currentEmail: {
        label: 'aktuelle eMail',
        unverifiedHint: 'Deine E-Mail ist noch nicht verifiziert!'
      },
      password: {
        label: 'aktuelles Passwort',
        errorPassword: 'Du hast ein ungültiges Passwort eingegeben.',
        errorRequired: 'Bitte gebe Dein Passwort ein'
      },
      success: {
        title: 'Gespeichert!',
        text: 'Bitte bestätige Deine neue Adresse, indem Du auf den Link in der E-Mail klicken, die wir Dir gesendet haben.' +
          ' Es wird nur zur Überprüfung verwendet!'
      },
      verificationLinkSend: {
        title: 'Der Link wurde gesendet!',
        text: 'Die E-Mail mit dem Bestätigungslink wurde erfolgreich versendet.' +
          ' Bitte warte ein paar Minuten, wenn es nicht sofort ankommt und überprüfe Deinen Spam-Ordner!'
      },
      buttons: {
        change: 'Aktualisiere Email Adresse',
        checkStatus: 'Überprüfen den Verifizierungsstatus',
        resendVerification: 'Neuen Bestätigungslink senden',
        submit: 'eMail ändern',
        cancel: 'Abbrechen',
        close: 'Schließen'
      }
    }
  }
}
