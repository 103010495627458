<template>
  <eins-deal-list-item
      :deal="deal"
  >
    <template #content>
      <v-row class="mt-1">
        <v-col
            cols="7"
            class="pb-0 pr-2 pt-1"
        >
          <template>
            <eins-time-stars
                :value="cTimeBonus"
                class="pb-2"
                reverse
            />
          </template>
          <p
              class="pb-0 pl-2 mb-0"
              :style=" cPageWidth ? 'font-weight:700; font-size:10px' : 'font-weight:700; font-size:12px'"
          >
            {{ cDate }}
          </p>
        </v-col>
        <v-col
            cols="4"
            class="pa-1 pl-1 pt-n2"
            style="border: 1px solid red; border-radius: 4px"
        >
          <router-link
              :to="{ name: 'player-detail', params: { id: `${cRouteId}` } }"
              style="text-decoration: none"
          >
            <div>
              <div :class="cPageWidth ? 'pl-3' : 'pl-4'">
                <v-icon size="20">mdi-account</v-icon>
              </div>
            </div>
            <div
                style="font-size:10px; color: white"
                :style="cPageWidth ? 'margin-top: -5px' : '0px'"
                :class="cPageWidth ? 'pl-1' : 'pl-2'"
            >
              Get Deal
            </div>
          </router-link>
        </v-col>
      </v-row>
    </template>
    <template #indicator3>
      <v-col>
      </v-col>
    </template>
  </eins-deal-list-item>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import EinsDealListItem from '@/components/deal/EinsDealListItem'
import EinsTimeStars from '@/components/time/EinsTimeStars'
import { DealType } from '@/constants'

export default {
  name: 'EinsDealListItemPlayerClosed',
  components: {
    EinsDealListItem,
    EinsTimeStars
  },
  props: {
    deal: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    getWidth: window.innerWidth
  }),

  computed: {
    cTimeBonus () {
      return this.deal?.value ?? 0
    },
    cDate () {
      let date = new Date(this.deal?.updated_at)
      let getMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      let day = date.getUTCDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return day + '. ' + getMonth[month] + '. ' + year
    },
    cPageWidth () { return this.getWidth < 375 },

    cAssigneeId () { return this.deal?.player[0]?.id },

    cPlayerId () { return this.$store.getters['players/current/id'] },

    cRouteId () { return this.cPlayerId === this.cAssigneeId ? this.deal?.user_id : this.cAssigneeId },

    cHighlight () {
      const type = this.deal?.type
      if (type === DealType.PRODUCT) {
        // service
        return colors.teal.base
      } else if (type === DealType.SERVICE) {
        // product
        return colors.lightBlue.base
      } else {
        return []
      }
    }
  }
}
</script>

<style  scoped>
</style>
