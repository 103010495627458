<template>
  <v-row
      class="flex-nowrap"
      dense
  >
    <v-col v-if="cHasContent">
      <span
          v-if="cUseStreet"
          class="text-no-wrap"
      >
        <span v-show="cUseStreet">
          {{ street }}&nbsp;
        </span>
        <span v-show="cUseNumber">
          {{ number }}
        </span>
      </span>
      <span v-else-if="cUseAltText">
        {{ altText }}
      </span>
      <br v-if="(cUseCity || cUseAltTextSecondary) && (cUseStreet || cUseAltText)" />
      <span
          v-if="cUseCity"
          class="text-no-wrap"
      >
        <span v-show="cUseZip">
          {{ zip }}&nbsp;
        </span>
        <span v-show="cUseCity">
          {{ city }}&nbsp;
        </span>
        <span v-show="cUseCountry">
          ({{ country }})
        </span>
      </span>
      <span v-else-if="cUseAltTextSecondary">
        {{ altTextSecondary }}
      </span>
    </v-col>
    <v-spacer v-else />
    <v-btn
        v-show="cUseNavLink"
        icon
        :href="navLink"
        target="_blank"
    >
      <v-icon
          large
          color="primary"
          class="mt-1"
      >mdi-directions</v-icon>
    </v-btn>
    <v-spacer v-if="!cHasContent" />
  </v-row>
</template>

<script>
export default {
  name: 'EinsMapAddressPopup',
  props: {
    altText: {
      type: String,
      default: null
    },
    altTextSecondary: {
      type: String,
      default: null
    },
    street: {
      type: String,
      default: null
    },
    number: {
      type: String,
      default: null
    },
    zip: {
      type: String,
      default: null
    },
    city: {
      type: String,
      default: null
    },
    country: {
      type: String,
      default: null
    },
    navLink: {
      type: String,
      default: null
    }
  },
  computed: {
    cHasContent () {
      return this.cUseCity ||
        this.cUseStreet ||
        this.cUseAltText ||
        this.cUseAltTextSecondary
    },
    cUseStreet () { return this.street !== null && this.street.length > 0 },
    cUseNumber () { return this.number !== null && this.number.length > 0 },
    cUseZip () { return this.zip !== null && this.zip.length > 0 },
    cUseCity () { return this.city !== null && this.city.length > 0 },
    cUseCountry () { return this.country !== null && this.country.length > 0 },
    cUseNavLink () { return this.navLink !== null && this.navLink.length > 0 },
    cUseAltText () {
      return !this.cUseStreet &&
        typeof this.altText === 'string' &&
        this.altText.length > 0
    },
    cUseAltTextSecondary () {
      return !this.cUseCity &&
          typeof this.altTextSecondary === 'string' &&
          this.altTextSecondary.length > 0
    }
  }
}
</script>

<style scoped>

</style>
