<template>
  <v-dialog
      v-model="dialog"
      scrollable
  >
    <template #activator="{ on }">
      <div
          class="row pa-2  mr-4 "
          v-on="on"
      >
        <template
            v-for="(stat, index) in cStates"
            class="text-no-wrap text-center"
        >
          <div
              v-if="index > 0 && stat.active"
              :key="`str${index}`"
              class="col-1"
          >
            <v-icon
                size="32px"
                :color="stat.active ? stat.reached ? 'deal' : 'dealoff' : '#474747'"
            >
              mdi-minus
            </v-icon>
          </div>
          <div
              v-if="stat.active"
              :key="`state${index}`"
              class="col-1"
          >
            <v-icon
                size="32px"
                :color="stat.active ? stat.reached ? 'deal' : 'dealoff' : '#474747'"
            >
              {{ stat.icon }}
            </v-icon>
          </div>
        </template>
      </div>
    </template>
    <v-card
        class="text-center action-dialog detail-content "
        style="position: relative;"
    >
      <v-btn
          icon
          style="position: absolute; top: 0; right: 0;"
          @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-2 text-h5 primary--text">
        {{ cCaption }}
      </v-card-text>
      <v-card-text class="pa-2">
        {{ cText }}
      </v-card-text>
      <v-card-text class="pa-2">
        {{ cTextLong }}
      </v-card-text>
      <v-card-actions>
        <template v-if="state !== 'accepted'">
          <v-btn
              v-show="!cClosed"
              class="actions-btn"
              @click="closeDialog"
          ><i18n path="components.EinsDealDetailStatesHeader.caption.Abort" /></v-btn>
          <v-spacer />
        </template>
        <!-- state open + direct + player -->
        <eins-starmenu-ab-get-deal
            v-if="state === 'available' && !requireApplication && !isOwner"
            class="actions-btn"
        />
        <eins-starmenu-ab-apply
            v-if="state === 'apply'"
            class="actions-btn"
        />
        <eins-starmenu-dpb-applications
            v-if="state === 'assign'"
            class="actions-btn"
        />
        <template v-if="state === 'accepted'">
          <eins-starmenu-ab-reject-deal class="actions-btn no-gutters" />
          <v-spacer />
          <eins-starmenu-ab-exchange-deal class="actions-btn no-gutters" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsStarmenuAbApply from '@/components/starmenu/EinsStarmenuAbApply'
import EinsStarmenuDpbApplications from '../starmenu/EinsStarmenuDpbApplications'
import EinsStarmenuAbRejectDeal from '../starmenu/EinsStarmenuAbRejectDeal'
import EinsStarmenuAbExchangeDeal from '../starmenu/EinsStarmenuAbExchangeDeal'
import EinsStarmenuAbGetDeal from '../starmenu/EinsStarmenuAbGetDeal'

const validTypes = [
  'open',
  'available',
  'completed',
  'apply',
  'assign',
  'applied',
  'assigned',
  'accepted',
  'closed'
]

export default {
  name: 'EinsDealDetailStatesHeader',
  components: {
    EinsStarmenuAbGetDeal,
    EinsStarmenuAbRejectDeal,
    EinsStarmenuDpbApplications,
    EinsStarmenuAbExchangeDeal,
    EinsStarmenuAbApply
  },
  props: {
    state: {
      type: String,
      default: null,
      validator: val => validTypes.indexOf(val) > -1
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    requireApplication: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    cClosed () {
      return this.cCaption === 'closed'
    },
    cStates () {
      return [
        {
          icon: this.isOwner ? 'mdi-cart' : this.requireApplication ? 'mdi-human-greeting' : 'mdi-offer',
          reached: this.cStateReached >= 1,
          active: true
        },
        {
          icon: this.isOwner ? 'mdi-human-greeting' : 'mdi-account-clock',
          reached: this.cStateReached >= 2,
          active: this.requireApplication
        },
        {
          icon: this.isOwner ? 'mdi-account-clock' : 'mdi-lock-open-outline',
          reached: this.cStateReached >= 3,
          active: this.requireApplication
        },
        {
          icon: 'mdi-credit-card-check-outline',
          reached: this.cStateReached >= 4,
          active: this.requireApplication
        },
        {
          icon: 'mdi-cart-check',
          reached: this.cStateReached >= 5,
          active: true
        }
      ]
    },
    cStateReached () {
      switch (this.state) {
        case 'available':
          return 1
        case 'apply':
          return 1
        case 'open':
          return 1
        case 'assign':
          return 2
        case 'applied':
          return 2
        case 'accepted':
          return 3
        case 'assigned':
          return 3
        case 'closed':
          return 5
        default:
          return 0
      }
    },
    cCaption () {
      const key = `components.${this.$options.name}.caption.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cText () {
      const key = `components.${this.$options.name}.text.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    },
    cTextLong () {
      const key = `components.${this.$options.name}.textLong.${this.state}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return ''
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.actions-btn {
  border: 1px solid red;
  margin: 0px;
}
.action-dialog{
}
</style>
