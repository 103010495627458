<template>
  <div
      class="d-flex"
  >
    <v-card
        outlined
    >
      <v-card-text style="width:210px">
        <strong>{{ cSenderName }}</strong><br />
        <div
            v-if="cBlockage"
            class="font-italic grey--text text--lighten-1"
        >
          {{ cBlockage }}
        </div>
        {{ message.body }}
      </v-card-text>
    </v-card>
    <v-icon
        class="mdi"
    > mdi-chevron-left</v-icon>
    <v-list-item-avatar color="grey darken">
      <eins-api-image
          v-if="cImage"
          #default="{ src }"
          :src="cImage"
      >
        <v-img
            alt=""
            :src="src"
            width="40px"
            height="auto"
        />
      </eins-api-image>
      <v-img
          v-else
          src="./../../assets/img/blind-pic.jpg"
          width="40px"
          height="auto"
      />
    </v-list-item-avatar>
  </div>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'

export default {
  name: 'EinChatMessageListBubble',
  components: {
    EinsApiImage
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    userConversation: ''
  }),
  computed: {
    cSenderName () {
      if (!this.message.sender) {
        return this.$t('components.chat.unknownPlayer')
      } else if (this.message.sender.is_deleted) {
        return this.$t('player.deleted.name')
      } else {
        return this.message.sender.name
      }
    },
    cBlockage () {
      if (this.message.sender.has_blocked_authenticated_user) {
        return this.$t('player.block.attribution.hasBlocked')
      } else if (this.message.sender.is_blocked_by_authenticated_user) {
        return this.$t('player.block.attribution.isBlocked')
      } else {
        return false
      }
    },
    cImage () {
      return this.message?.sender ? this.message.sender.title_picture_feed : null
    }
  },
  created () {
    this.$http.$api.get('conversations?page=1&limit=50')
      .then((result) => {
        this.userConversation = result.data.conversations
      })
  }
}
</script>

<style lang="scss" scoped>
.mdi{
  font-size:15px;
}

div.col{

div.v-card__text {
padding: 6px;
}
div.col{
  padding:5px;
}

}

</style>
