<template>
  <eins-player-list-item :player="member">
    <template #content>
      <v-row
          align="end"
          dense
          class="flex-nowrap fill-height"
      >
        <v-col>
          <v-btn
              block
              @click.stop.prevent="onClickStartConversation"
          ><v-icon>mdi-message-text</v-icon></v-btn>
        </v-col>
        <v-col
            v-if="playerIsOwner"
        >
          <v-btn
              block
              :loading="loading.role"
              @click.stop.prevent="onClickChangeMembershipState"
          >
            <v-icon v-if="cMemberIsAdmin">mdi-wallet-membership</v-icon>
            <v-icon v-else>mdi-account-supervisor</v-icon>
          </v-btn>
        </v-col>
        <v-col
            v-if="cPlayerCanKickMember"
        >
          <v-btn
              block
              :disabled="cMemberIsOwner"
              :loading="loading.delete"
              @click.stop.prevent="onClickRemoveMember"
          >
            <v-icon>mdi-account-multiple-minus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template
        v-if="cMemberIsOwner"
        #indicator1
    >
      <v-icon color="primary">mdi-shield-star-outline</v-icon>
    </template>
    <template #indicator3>
      <v-icon v-if="cMemberIsAdmin">mdi-account-supervisor</v-icon>
      <v-icon v-else>mdi-wallet-membership</v-icon>
    </template>
  </eins-player-list-item>
</template>

<script>
import EinsPlayerListItem from '@/components/player/EinsPlayerListItem'
import {
  Membership
} from '@/constants'
import {
  startConversationWith
} from '@/utils/chat'

export default {
  name: 'EinsPlayerListItemGroupMember',
  components: {
    EinsPlayerListItem
  },
  props: {
    playerIsAdmin: {
      type: Boolean,
      default: false
    },
    playerIsOwner: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      default: null
    },
    member: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    loading: {
      role: false,
      delete: false
    }
  }),
  computed: {
    cMemberIsAdmin () {
      return this.member?.membership_state_player === Membership.ADMIN
    },
    cMemberIsOwner () {
      return this.group?.owner_id === this.member.id
    },
    cPlayerCanKickMember () {
      return this.playerIsAdmin && (!this.cMemberIsAdmin || this.playerIsOwner)
    },
    cMembershipPath () {
      return `/groups/${this.group.id}/memberships/${this.member.id}`
    }
  },
  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    onClickChangeMembershipState () {
      return this.setMemberAdminRole(!this.cMemberIsAdmin)
    },
    onClickRemoveMember () {
      return this.removeMember()
    },
    startConversation () {
      return startConversationWith(this.member.id)
    },
    setMemberAdminRole (isAdmin = false) {
      this.loading.role = true
      return this.$http.$api.patch(this.cMembershipPath, {
        is_admin: !!isAdmin
      })
        .then(() => this.$store.dispatch('groups/selected/members/updateAll'))
        .finally(() => {
          this.loading.role = false
        })
    },
    removeMember () {
      this.loading.delete = true
      return this.$http.$api.delete(this.cMembershipPath)
        .finally(() => {
          this.$store.commit('groups/selected/members/removeFromCache', this.member.id)
          this.loading.delete = false
        })
        .then(() => this.$store.dispatch('groups/selected/members/updateAll'))
    }
  }
}
</script>

<style scoped>

</style>
