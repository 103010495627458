<template>
  <eins-deal-list-item
      :deal="deal"
  >
    <template #indicator3>
      <v-spacer />
      <v-col v-if="cApplicationLength">
        <v-icon
            color="white"
        >{{ cActionIndicatorIcon }}
        </v-icon>
      </v-col>
      <v-spacer />
    </template>

    <template #content>
      <v-row
          v-if="cApplicationLength"
          dense
          class="fill-height pt-3"
          align-content="end"
      >
        <v-col v-if="cIsDealOpened">
          <v-btn
              v-if="cUserIsOwner"
              color="primary"
              outlined
              block
              height="60px"
          >
            <router-link
                :to=" `/deals/${deal.id}/applications`"
                class="mt-2 text-capitalize"
            >
              <v-icon>{{ 'mdi-human-greeting' }}</v-icon>
              <p
                  class="mb-1"
                  style="font-size:10px"
              > {{ cText['btn.application'] }} </p>
              <p
                  class="mt-n1"
                  style="font-size:10px"
              >{{ cText['btn.list'] }}</p>
            </router-link>
          </v-btn>
          <v-col v-if="cIsDealOpened && !cUserIsApplicant ">
            <div v-if="!cUserIsOwner">
              {{ cDescription }}
            </div>
          </v-col>
        </v-col>
        <v-col v-if="cIsDealOpened && cUserIsApplicant">
          <v-btn
              color="primary"
              outlined
              block
              height="60px"
              width="50px"
              @click.stop.prevent="onClickStartConversation"
          >
            <div class="mt-2 text-capitalize">
              <v-icon>mdi-forum</v-icon>
              <p
                  class="mt-1"
                  style="font-size:12px"
              > {{ cText['btn.chat'] }}</p>
            </div>
          </v-btn>
          <div v-if="!cIsDealOpened">
            {{ cDescription }}
          </div>
        </v-col>
        <v-col v-if="cIsDealAssigned">
          <v-row
              no-gutters
          >
            <v-col class="mr-1">
              <v-btn
                  color="primary"
                  outlined
                  block
                  height="60px"
                  width="50px"
                  @click.stop.prevent="onClickStartConversation"
              >
                <div class="mt-2 text-capitalize">
                  <v-icon>mdi-forum</v-icon>
                  <p
                      class="mt-1"
                      style="font-size:12px"
                  > {{ cText['btn.chat'] }}</p>
                </div>
              </v-btn>
            </v-col>
            <v-col
                v-for="item in cShowBtn"
                :key="item.text"
                class="mr-1"
            >
              <v-btn
                  color="primary"
                  outlined
                  block
                  height="60px"
                  width="50px"
                  @click.stop.prevent="item.getAction"
              >
                <div class="mt-2 text-capitalize">
                  <v-icon>{{ item.icon }}</v-icon> <br />
                  <span style="font-size:10px">{{ item.text }}</span>
                  <p
                      class="mt-0 mb-2"
                      style="font-size:10px"
                  >{{ item.sub_text }}</p>
                </div>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="deal.application_state === 'none' ">
            <v-col>
              {{ cDescription }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </eins-deal-list-item>
</template>

<script>
import EinsDealListItem from '@/components/deal/EinsDealListItem'
import { startConversationWith } from '@/utils/chat'
import { DealState } from '@/constants'
import { MxTranslatable } from '@/mixins'
import {
  RegHtml
} from '@/utils/regex'

const regHTML = RegHtml

const descriptionMaxLength = 70

export default {
  name: 'EinsDealListItemPlayerDeal',
  components: {
    EinsDealListItem
  },
  mixins: [ MxTranslatable ],
  props: {
    deal: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    $_mx_translatable: {
      paths: [
        'btn.application',
        'btn.list',
        'btn.chat'
      ]
    }
  }),

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.onClickStarted()
      vm.onClickApproved()
      vm.onClickFinished()
      vm.loadPlayer(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.onClickStarted()
    this.onClickApproved()
    this.onClickFinished()
    this.loadPlayer(to.params.id)
    next()
  },
  computed: {
    cDealState () {
      return this.deal?.state
    },
    cUserIsOwner () {
      return this.deal?.user_id === this.$store.getters['players/current/id']
    },
    cIsDealAssigned () {
      return this.cDealState === DealState.ASSIGNED
    },
    cIsDealOpened () {
      return this.cDealState === DealState.OPEN
    },
    cIsDealConfirmed () {
      return this.cDealState === DealState.CONFIRMED
    },
    cRequireApplication () {
      return this.cDeal?.require_application
    },
    cUserIsAssignee () {
      return this.cDeal?.assignments
        .some(assignment => assignment.user.id === this.$store.getters['players/current/id'])
    },
    cUserIsApplicant () {
      return this.deal?.application_state === 'unhandled'
    },
    cApplicationLength () {
      return this.deal?.applications_count
    },
    cIcon () {
      switch (this.cDealState) {
        case DealState.OPEN:
          return { icon: 'mdi-account-clock' }
        case DealState.ASSIGNED:
          return { icon: 'mdi-lock-open-outline' }
        case DealState.CONFIRMED:
          return { icon: 'mdi- credit-card-check-outline' }
        default:
          return { icon: 'mdi-account-clock' }
      }
    },
    cActionIndicatorIcon () {
      if (this.cIsDealOpened) {
        if (this.deal?.application_state === 'none') {
          return 'mdi-human-greeting'
        } else if (this.deal?.application_state === 'unhandled') {
          return this.cIcon.icon
        }
      }
      if (this.cIsDealAssigned) {
        if (this.deal?.application_state === 'none') {
          return 'mdi-lock-open-outline'
        } else if (this.deal?.application_state === 'accepted') {
          return 'mdi-credit-card-check-outline'
        }
      }
      if (this.cIsDealConfirmed) {
        return this.cIcon.icon
      } else {
        return 'mdi-account-clock'
      }
    },
    cUserIsParticipantId () {
      let result = 0
      if (this.cIsDealOpened) {
        result = this.deal?.user_id
      }
      if (this.cIsDealAssigned) {
        if (this.deal?.application_state === 'none') {
          result = this.deal?.assignees[0]?.id
        } else {
          result = this.deal?.user_id
        }
      }
      return result
    },
    cOpponentId () {
      return this.deal?.assignees[0]?.id
    },
    cDescription () {
      let description = this.deal?.description.replace(regHTML, '')
      if (description?.length > descriptionMaxLength) {
        description = description.substring(0, descriptionMaxLength) + ' ...'
      }

      return description ?? ''
    },
    cShowBtn () {
      let result = []
      if (this.cIsDealAssigned && this.cUserIsAssignee) {
        if (this.deal?.application_state === 'accepted') {
          result.push(
            { 'icon': 'mdi-close-box-outline',
              'text': 'Reject',
              'sub_text': 'Deal',
              'getAction': this.onClickRejectDeal
            },
            { 'icon': 'mdi-credit-card-check-outline',
              'text': 'Get',
              'sub_text': 'Deal',
              'getAction': this.onClickExchangeDeal
            }
          )
        }
      }
      if (this.cIsDealConfirmed && this.cOpponentId) {
        if (this.deal?.application_state === 'confirmed') {
          result.push(
            { 'icon': 'mdi-close-box-outline',
              'text': 'Reject',
              'getAction': this.onClickRejectDeal
            },
            { 'icon': 'mdi-credit-card-check-outline',
              'text': 'Get',
              'getAction': this.onClickExchangeDeal
            }
          )
        }
      }
      return result
    }
  },
  methods: {
    onClickStartConversation () {
      this.startConversation()
    },
    startConversation () {
      return startConversationWith(this.deal.player.id)
    },
    onClickRejectDeal () {
      this.$store.dispatch('openDialogRejectDeal', {
        deal: this.cDeal
      })
        .then(() => {
          return Promise.all([
            this.$store.dispatch('players/current/update'),
            this.$store.dispatch('players/selected/dealsRelated/loadAll')
          ])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClickExchangeDeal () {
      this.$store.dispatch('openDialogExchangeDeal', {
        deal: this.cDeal
      })
        .finally(() => {
          this.$store.dispatch('players/selected/dealsRelated/loadAll')
          this.isLoading = false
        })
    }
  }
}
</script>
