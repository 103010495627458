<template>
  <div style="aspect-ratio: 1;">
    <eins-api-image
        v-if="currentValue"
        #default="{ src }"
        class="fill-height"
        :src="currentValue"
    >
      <v-img
          v-ripple
          aspect-ratio="1"
          :src="src"
          @click="onClickSetPicture"
      />
    </eins-api-image>
    <v-card
        v-else
        class="d-flex flex-column align-center justify-center fill-height"
        @click="onClickSetPicture"
    >
      <v-icon x-large>mdi-camera-plus-outline</v-icon>
      <span class="text-uppercase">{{ addPictureHint }}</span>
    </v-card>
    <eins-edit-picture-dialog
        v-model="dialog"
        :after-update="afterUpdate"
        :api-path="apiPath"
        :dialog-title="dialogTitle"
        :submit-label="submitLabel"
        :success-message="successMessage"
    />
  </div>
</template>

<script>
import EinsApiImage from '@/components/EinsApiImage'
import EinsEditPictureDialog from '@/components/form/edit/EinsEditPictureDialog'
import { objectHasOwnProperty } from '@/utils/object'

export default {
  name: 'EinsEditPictureBlock',
  components: {
    EinsEditPictureDialog,
    EinsApiImage
  },
  props: {
    afterUpdate: {
      type: Function,
      default: () => Promise.resolve()
    },
    apiPath: {
      type: String,
      required: true
    },
    currentValue: {
      type: String,
      default: null
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    successMessage: {
      type: Object,
      required: true,
      validator: value => objectHasOwnProperty(value, 'title') &&
          typeof value.title === 'string' &&
          objectHasOwnProperty(value, 'text') &&
          typeof value.text === 'string'
    },
    addPictureHint: {
      type: String,
      default: null
    }
  },
  data: () => ({
    success: false,
    loading: false,
    dialog: false,
    form: {
      valid: false,
      file: null,
      preview: null,
      aspectRatio: 1
    },
    cropper: {
      show: false,
      ready: false,
      result: null
    }
  }),
  computed: {
  },
  methods: {
    onClickSetPicture () {
      this.showDialog()
    },
    showDialog () {
      this.dialog = true
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
</style>
